<template>
    <div v-if="productList.length" class="products">
        <div v-for="(item, itemIndex) in productList" :key="`item-${itemIndex}`" class="order-item">
            <div class="order-info">
                <p v-if="item.product" class="order-title">
                    {{ titleProduct(item.product) }}
                </p>
                <div class="order-list">
                    <div
                        v-for="(product, productIndex) in licenseList(item.product)"
                        :key="`product-${productIndex}`"
                        class="order-product"
                    >
                        <p v-if="product.workload">
                            {{ translation('Workload:') }} <b>{{ titleWorkload(product.workload) }}</b>
                        </p>
                        <p v-if="product.period">
                            {{ translation('License period:') }} <b> {{ titlePeriod(product.period, 'short') }}</b>
                        </p>
                        <p v-if="product.quantity">
                            {{ textQuantity(product) }}  <b>{{ product.quantity * product.multiplier }}</b>
                        </p>

                        <p v-if="cloudStorageIncluded(product)" v-html="cloudStorageIncluded(product)" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import currency from '@core/mixins/currency.js';
import mixin from '../mixin.js';

export default {
    name: 'SOrderEdition',

    mixins: [mixin, currency],

    props: {
        settings: {
            type: Object,
            default: null,
        },
    },

    computed: {
        productList() {
            return this.settings.products
                .map((edition) => this.addedLicenses.filter((item) => item.workload).find((item) => item.product === edition.product))
                .filter(Boolean);
        },
    },

    methods: {
        licenseList(name) {
            return this.addedLicenses.filter((item) => item.product === name);
        },

        titleProduct(name) {
            return this.settings.products.find((item) => item.product === name).title || '';
        },

        titleWorkload(name) {
            return this.settings.workloads.find((item) => item.name === name).title || '';
        },

        textQuantity(product) {
            return this.translation(`${product.isSeats ? 'Seats:' : 'Quantity:'}`);
        },
    },
};
</script>

<style lang="postcss" scoped>
.products {
    &:deep(~ .order-addons) {
        margin-top: 24px;
    }
}

.order-list {
    display: flex;
    flex-wrap: wrap;
}

.order-item {
    &:not(:first-child) {
        margin-top: 24px;
    }
}

.order-title {
    @mixin lead-accent;
    color: var(--av-nav-primary);
}

.order-product {
    @mixin paragraph;
    margin-top: 16px;
    width: 100%;
    color: var(--av-nav-primary);
    padding-inline-end: 16px;

    @media (--viewport-tablet) {
        width: 50%;

        &:nth-child(even) {
            padding-inline-start: 16px;
            border-inline-start: 1px solid var(--av-brand-secondary-light);
        }
    }
}
</style>
