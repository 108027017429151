<template>
    <div class="topnav-sticky">
        <div class="wrapper a-container">
            <div class="left">
                <div class="logo-wrapper">
                    <a-dynamic-link
                        :to="logo.link"
                        :target="logo.target"
                        :rel="logo.rel"
                        :event="{ action: 'Acronis' }"
                    >
                        <a-logo class="logo" type="full" fill="white" />
                    </a-dynamic-link>
                </div>

                <nav class="menu-wrapper-desktop">
                    <ul class="menu">
                        <li v-for="link of menuLinks" :key="link.link" class="menu-item">
                            <a-dynamic-link
                                class="link"
                                :class="{ active: isActiveLink(link.link) }"
                                :to="link.link"
                                :event="{ action: link.link }"
                            >
                                {{ link.text }}
                            </a-dynamic-link>
                        </li>
                    </ul>
                </nav>

                <div class="menu-wrapper-mobile">
                    <a-dynamic-link
                        class="login-link"
                        :to="login.link"
                        :target="login.target"
                        :event="{ action: login.link }"
                    >
                        <a-glyph
                            class="glyph"
                            name="group"
                            fill="white"
                            size="s"
                        />
                    </a-dynamic-link>

                    <div class="vertical-separator">
                        &nbsp;
                    </div>

                    <div class="menu-burger" @click.stop="$emit('menu-toggle')">
                        <a-glyph
                            v-if="!menuIsOpened"
                            class="glyph"
                            name="burger-menu"
                            fill="white"
                            size="l"
                        />
                        <a-glyph
                            v-else
                            class="glyph"
                            name="close"
                            fill="white"
                            size="l"
                        />
                    </div>
                </div>
            </div>

            <div class="right">
                <a-button
                    v-for="cta of stickyCTA"
                    :key="cta.to"
                    class="cta"
                    v-bind="cta"
                />
            </div>
        </div>
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import ADynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALogo from '@core/components/logo/logo.vue';

export default {
    name: 'DeveloperTopnavSticky',

    components: {
        AButton,
        ADynamicLink,
        AGlyph,
        ALogo,
    },

    props: {
        logo: {
            type: Object,
            required: true,
        },

        menuLinks: {
            type: Array,
            required: true,
        },

        login: {
            type: Object,
            required: true,
        },

        mobileMenuTitle: {
            type: String,
            required: true,
        },

        contactUs: {
            type: Object,
            required: false,
            default: null,
        },

        stickyCTA: {
            type: Array,
            required: false,
            default: () => ([]),
        },

        menuIsOpened: {
            type: Boolean,
            default: false,
        },
    },

    emits: ['menu-toggle'],

    methods: {
        isActiveLink(link) {
            const path = this.$route?.params?.pathMatch;

            if (!path && link === '/') return true;

            return path && link.includes(path);
        },
    },
};
</script>

<style lang="postcss" scoped>
.topnav-sticky {
    position: fixed;

    /* WEB-43924 it should be above anchor-menu width z-index 120 */
    z-index: 121;
    top: 0;
    inset-inline-start: 0;
    inset-inline-end: 0;
    padding-block: 24px;
    background: var(--av-nav-primary);

    @media (--viewport-desktop) {
        padding-block: 28px;
    }
}

.wrapper {
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.left {
    display: flex;
    width: 100%;
    align-items: center;
}

.right {
    display: none;

    @media (--viewport-desktop) {
        display: block;
    }
}

.logo-wrapper {
    @media (--viewport-desktop) {
        margin-inline-end: 48px;
    }
}

.logo {
    height: 27px;
    width: 133px;

    @media (--viewport-desktop) {
        height: 32px;
        width: 158px;
    }
}

.menu-wrapper-desktop {
    display: none;
}

.menu-wrapper-mobile {
    display: flex;
    margin-inline-start: auto;
}

@media (--viewport-desktop) {
    .menu-wrapper-desktop {
        display: flex;
    }

    .menu-wrapper-mobile {
        display: none;
    }
}

.link {
    @mixin body-accent;
    color: var(--av-fixed-white);

    &.active {
        color: var(--av-brandless-icon-solid-light);
        pointer-events: none;
    }

    &:hover {
        color: var(--av-branded-icon-solid-lightest);
    }

    @media (--viewport-desktop-wide) {
        @mixin paragraph-accent;
    }
}

.a-button.cta {
    @mixin body;

    @media (--viewport-desktop-wide) {
        @mixin paragraph;
    }
}

.login-link {
    line-height: 16px;
}

.menu-wrapper-desktop .menu {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;

    .menu-item + .menu-item {
        margin-inline-start: 20px;
    }
}

.menu-wrapper-mobile {
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
}

.menu-burger {
    color: #fff;
    cursor: pointer;
}

.vertical-separator {
    height: 24px;
    width: 1px;
    background: var( --av-fixed-info-dark);
    margin: 0 8px 0 12px;
}
</style>
