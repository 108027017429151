<template>
    <section class="a-requirement">
        <template v-if="title">
            <h3 v-if="title.tooltip" class="a-requirement__title a-requirement__title_with-tooltip">
                <a-link
                    v-if="title.to"
                    v-bind="title"
                    :size="title.size || 'lead'"
                    :glyph="title.glyph || 'arrow'"
                />
                <template v-else>
                    {{ title.text }}
                </template>
                <a-tooltip class="a-requirement__tooltip" :text="title.tooltip">
                    <a-glyph name="info-circle" />
                </a-tooltip>
            </h3>
            <h3 v-else class="a-requirement__title">
                <a-link
                    v-if="title.to"
                    v-bind="title"
                    :size="title.size || 'lead'"
                    :glyph="title.glyph || 'arrow'"
                />
                <template v-else>
                    {{ title }}
                </template>
            </h3>
        </template>
        <section v-for="(item, i) in sections" :key="i" class="a-requirement__section">
            <template v-if="item.subtitle">
                <h4 v-if="item.subtitle.tooltip" class="a-requirement__subtitle a-requirement__subtitle_with-tooltip">
                    {{ item.subtitle.text }}
                    <a-tooltip class="a-requirement__tooltip" :text="item.subtitle.tooltip">
                        <a-glyph name="info-circle" />
                    </a-tooltip>
                </h4>
                <h4 v-else class="a-requirement__subtitle">
                    {{ item.subtitle }}
                </h4>
            </template>
            <ul class="a-list a-list_type_no-bullet a-requirement__list">
                <li
                    v-for="(sub, j) in item.subsection"
                    :key="j"
                    class="a-list__item a-requirement__item"
                    :class="{ 'a-requirement__item_with-tooltip': sub.tooltip }"
                >
                    <a-link
                        v-if="sub.to"
                        v-bind="sub"
                        :size="sub.size || 'paragraph'"
                        :event="setEvent(sub)"
                    />
                    <template v-else>
                        <template v-if="sub.tooltip">
                            {{ sub.text }}
                            <a-tooltip class="a-requirement__tooltip" :text="sub.tooltip">
                                <a-glyph name="info-circle" />
                            </a-tooltip>
                        </template>
                        <template v-else>
                            {{ sub }}
                        </template>
                    </template>
                </li>
            </ul>
            <ul class="bullet-list">
                <a-dangerous-html
                    v-for="(listItem, j) in item.list"
                    :key="`list-${j}`"
                    :content="listItem"
                    tag="li"
                />
            </ul>
        </section>
        <section v-if="title && title.to" class="a-requirement__more">
            <a-link
                :text="learnMoreText"
                v-bind="title"
                :size="title.size || 'paragraph'"
                :type="title.type || 'regular'"
                :glyph="title.glyph || 'arrow'"
                accent
            />
        </section>
    </section>
</template>

<script>
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALink from '@core/components/link/link.vue';
import ATooltip from '@core/components/tooltip/tooltip.vue';
import contentMixin from '@core/mixins/content.js';
import form from '@core/mixins/form.js';
import styleMixin from '@core/mixins/style.js';

export default {
    name: 'ARequirement',

    components: {
        ADangerousHtml,
        AGlyph,
        ALink,
        ATooltip,
    },

    mixins: [contentMixin, styleMixin, form],

    props: {
        title: {
            type: [String, Object],
            required: false,
            default: '',
        },

        learnMoreText: {
            type: String,
            required: false,
            default: 'Learn more',
        },

        sections: {
            type: [Array, Object],
            required: true,
        },
    },
};
</script>

<style lang="postcss" scoped>
.a-requirement {
    padding-bottom: 40px;

    @media (--viewport-mobile-wide) {
        padding-inline-end: 16px;
    }

    &__title {
        margin-bottom: 24px;
        color: var(--av-nav-primary);
        display: none;

        @mixin lead-strong;

        @media (--viewport-tablet) {
            display: block;
            padding-inline-start: 32px;
        }
    }

    &__section:not(:last-of-type) {
        margin-bottom: 40px;
    }

    &__subtitle {
        @mixin paragraph-accent;

        margin-bottom: 16px;
        color: var(--av-nav-primary);
    }

    &__section {
        padding-inline-start: 16px;

        @media(--viewport-tablet) {
            padding-inline-start: 32px;
        }
    }

    .bullet-list {
        list-style: disc;
        list-style-position: inside;

        li {
            margin: 8px 0;

            &::marker {
                content: '';
            }

            &::before {
                content: '\2981';
                padding-inline-start: 10px;
                padding-inline-end: 14px;
            }
        }
    }

    &__more {
        margin-top: 40px;
        padding-inline-start: 46px;

        @media(--viewport-tablet) {
            display: none;
        }
    }

    &__tooltip {
        margin-inline-start: 8px;
        position: relative;
        bottom: 5px;
        vertical-align: middle;
        .a-glyph {
            fill: var(--av-fixed-neutral-dark);
        }
    }
}
</style>
