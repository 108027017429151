<template>
    <div class="s-dropdown-workload-edition__wrapper" :class="className">
        <div class="s-dropdown-workload-edition__selector" @click="selectorOpen()">
            <div class="s-dropdown-workload-edition__period">
                {{ period(currentLicense) }}
            </div>
            <div class="s-dropdown-workload-edition__info">
                <div class="s-dropdown-workload-edition__label" v-html="label(currentLicense)" />
                <div class="s-dropdown-workload-edition__price">
                    <span class="s-dropdown-workload-edition__price-full" v-html="priceCurrencyFull(currentLicense)" />
                    <span class="s-dropdown-workload-edition__price-current" v-html="priceCurrency(currentLicense)" />
                    <span class="s-dropdown-workload-edition__suffix">
                        <a-glyph name="chevron-down" />
                    </span>
                </div>
            </div>
        </div>
        <div v-if="selectedOptionLabel" class="s-dropdown-workload-edition__selector-label">
            {{ selectedOptionLabel }}
        </div>
        <div class="s-dropdown-workload-edition__options">
            <div class="s-dropdown-workload-edition__option-description">
                {{ setTranslate('subscriptionDescription') }}
            </div>
            <div
                v-for="(license, licenseIndex) in listLicense"
                :key="`license-${licenseIndex}`"
                class="s-dropdown-workload-edition__option"
            >
                <label class="s-dropdown-workload-edition__option-label">
                    <input
                        v-model="selectedOption"
                        class="s-dropdown-workload-edition__option-input"
                        type="radio"
                        :value="licenseIndex"
                        @change="setEditionPeriod({ productID: product.id, editionName: edition.name, period: selectedOption })"
                    />
                    <div class="s-dropdown-workload-edition__period">{{ period(license) }}</div>
                    <div class="s-dropdown-workload-edition__info">
                        <div class="s-dropdown-workload-edition__label" v-html="label(license, licenseIndex)" />
                        <div class="s-dropdown-workload-edition__price">
                            <span class="s-dropdown-workload-edition__price-full" v-html="priceCurrencyFull(license, licenseIndex)" />
                            <span class="s-dropdown-workload-edition__price-current" v-html="priceCurrency(license, licenseIndex)" />
                        </div>
                    </div>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
import AGlyph from '@core/components/glyph/glyph.vue';
import mixin from '../mixin.js';

export default {
    name: 'SDropdownWorkloadEdition',

    components: {
        AGlyph,
    },

    mixins: [mixin],

    props: {
        /**
         * Settings
         */
        settings: {
            type: Object,
            default: null,
        },

        /**
         * Product
         */
        product: {
            type: Object,
            default: null,
        },

        /**
         * Edition
         */
        edition: {
            type: Object,
            default: null,
        },
    },

    data: () => ({
        isOpened: false,
        selectedOption: 0,
        selectedOptionLabel: '',
    }),

    computed: {
        className() {
            return {
                's-dropdown-workload-edition__opened': this.isOpened,
                's-dropdown-workload-edition__hasLabel': this.selectedOptionLabel,
            };
        },

        listLicense() {
            return this.edition.subscriptions[this.editionType(this.edition)];
        },

        currentLicense() {
            return this.editionLicense(this.edition, this.selectedOption);
        },

        quantityLicense() {
            return this.editionQuantity(this.edition) || 1;
        },

        hasMailboxes() {
            return this.isSubscriptionCustom(this.edition);
        },
    },

    watch: {
        currentLicense: {
            handler() {
                this.setSelectedOptionLabel(this.currentLicense);
            },
        },
    },

    mounted() {
        this.setSelectedOptionLabel(this.currentLicense);
        this.selectedOption = this.edition.selectedPeriod;
    },

    updated() {
        this.selectedOption = this.edition.selectedPeriod;
    },

    methods: {
        isActivePromo(license) {
            if (license.mailboxes) {
                const price = this.editionPrice(this.product, this.edition, 'price');
                const priceFull = this.editionPrice(this.product, this.edition, 'full');
                return priceFull > price;
            }
            return this.hasLicensePromo({ cleverBridgeCartID: license.cleverBridgeCartID, promo: this.settings.promo });
        },

        setSelectedOptionLabel(license) {
            if (this.isActivePromo(license)) {
                return;
            }
            const comparator = (el) => el.period === license.period;
            this.selectedOptionLabel = this.dictionary.subscriptionTitle.long.find(comparator).label;
        },

        selectorOpen() {
            this.isOpened = true;
            window.addEventListener('click', this.selectorClose, true);
        },

        selectorClose() {
            this.isOpened = false;
            window.removeEventListener('click', this.selectorClose, true);
        },

        period(license) {
            const comparator = (el) => el.period === license.period;
            return this.dictionary.subscriptionTitle.short.find(comparator)?.title || '';
        },

        label(license, i) {
            if (license.period === 1 && !this.isActivePromo(license)) {
                return '';
            }
            const textDefault = `${this.setTranslate('Save')} XX%`;
            const text = this.settings?.promo?.label || textDefault;
            const priceFull = this.priceLicenseFull(license, i);
            const priceCurrent = this.priceLicense(license, i);
            const result = Math.round((100 * (priceFull - priceCurrent)) / priceFull);
            return result ? text.replace('XX', result) : '';
        },

        priceLicense(product, i) {
            if (this.hasMailboxes) {
                return this.editionMailboxes(this.product, this.edition, 'price', i);
            }

            const current = product || this.listLicense[0];
            const cleverBridgeCartID = current.cleverBridgeCartID;
            const license = this.licenseData({ cleverBridgeCartID, promo: this.settings.promo });

            return license.price * this.quantityLicense;
        },

        priceLicenseFull(license, i) {
            const hasPromo = this.isActivePromo(license);
            const quantity = hasPromo ? 1 : license.period;

            if (this.hasMailboxes) {
                const current = hasPromo ? i : 0;
                const type = hasPromo ? 'full' : 'price';
                return this.editionMailboxes(this.product, this.edition, type, current) * quantity;
            }

            const edition = hasPromo ? license : this.editionLicense(this.edition, 0);
            const cleverBridgeCartID = edition.cleverBridgeCartID;
            const product = this.licenseData({ cleverBridgeCartID, promo: this.settings.promo });

            const price = product.price;
            const discount = product.discount_absolute || 0;

            return (price + discount) * quantity * this.quantityLicense;
        },

        priceCurrency(license, i) {
            return this.setCurrency(this.priceLicense(license, i));
        },

        priceCurrencyFull(license, i) {
            if (license.period === 1 && !this.isActivePromo(license)) {
                return '';
            }
            return this.setCurrency(this.priceLicenseFull(license, i));
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-dropdown-workload-edition {
    &__wrapper {
        height: 40px;
        background: var(--av-fixed-white);
        border-radius: 4px;
        position: relative;
        border: 1px solid var(--av-brand-light);
    }

    &__selector {
        display: flex;
        padding: 7px 0;
        cursor: pointer;
        flex-wrap: nowrap;
        align-items: center;
        color: var(--av-fixed-primary);
        justify-content: space-between;

        &-label {
            @mixin paragraph-accent;
            color: var(--av-fixed-success);
            overflow: hidden;
            max-width: 364px;
            white-space: nowrap;
            text-overflow: ellipsis;

            @media (--viewport-desktop-large) {
                max-width: 440px;
            }
        }
    }

    &__period {
        @mixin paragraph-accent;
        white-space: nowrap;
        padding-inline-end: 8px;
        padding-inline-start: 16px;
    }

    &__info {
        width: 100%;
        height: 24px;
        display: flex;
        justify-content: space-between;
    }

    &__price {
        display: flex;
        white-space: nowrap;

        &-full {
            font-weight: 400;
            margin-inline-end: 8px;
            margin-inline-start: 8px;
            color: var(--av-fixed-lighter);
            text-decoration: line-through;
        }
    }

    &__label {
        @mixin paragraph-accent;
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--av-fixed-success);

        @media (--viewport-desktop-large) {
            max-width: 132px;
        }

        @media (--viewport-desktop-large) {
            max-width: 152px;
        }
    }

    &__suffix {
        @mixin paragraph;
        padding-inline-end: 16px;
        padding-inline-start: 8px;

        .a-glyph {
            margin: 0 0 2px;
            transition: none;
            vertical-align: middle;
            fill: var(--av-brand-primary);
        }
    }

    &__options {
        z-index: 2;
        display: none;
        padding: 8px 0;
        margin: 0 -1px;
        width: calc(100% + 2px);
        border-radius: 0 0 4px 4px;
        border: 1px solid var(--av-brand-primary);
        border-top: 1px solid var(--av-brand-primary);
    }

    &__option {
        position: relative;

        &:hover {
            background: var(--av-brand-secondary-bright-hover);
        }

        &-description {
            @mixin descriptor;
            line-height: 18px;
            padding: 8px 16px 4px;
            padding-inline-end: 32px;
        }

        &-price {
            width: 100%;
            text-align: end;
        }

        &-label {
            width: 100%;
            display: flex;
            cursor: pointer;
            padding: 8px 0;
            padding-inline-end: 34px;
            justify-content: space-between;
        }

        &-input {
            top: 0;
            inset-inline-start: 0;
            opacity: 0;
            width: 1px;
            height: 1px;
            border: none;
            position: absolute;
        }
    }

    &__opened {
        border-radius: 4px 4px 0 0;
        border-color: var(--av-brand-primary);

        &:before {
            top: 0;
            inset-inline-start: 0;
            z-index: 1;
            content: '';
            width: 100%;
            height: 100%;
            cursor: default;
            position: absolute;
        }

        .s-dropdown-workload-edition {
            &__selector {
                cursor: default;
            }

            &__suffix {
                .a-glyph {
                    transform: rotate(180deg);
                }
            }

            &__options {
                inset-inline-start: 0;
                top: 100%;
                display: block;
                position: absolute;
                background: var(--av-fixed-white);
            }
        }
    }

    &__hasLabel {
        margin-bottom: 24px;
    }
}

.s-purchasing-acp-it,
.s-purchasing-acp-es,
.s-purchasing-acp-de,
.s-purchasing-acp-fr {
    .s-dropdown-workload-edition {
        &__label {
            font-size: 14px;
        }
    }
}
</style>
