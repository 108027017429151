<template>
    <el-form
        ref="form"
        autocomplete="off"
        name="register"
        class="form step-form a-container"
        :model="form"
        :rules="rules"
        @submit="submit"
    >
        <div class="section-title">
            {{ uiStrings.sectionTitleGeneral }}
        </div>

        <el-row :gutter="16">
            <el-col :span="12">
                <el-form-item prop="firstName">
                    <el-input
                        v-model="form.firstName"
                        :label="uiStrings.formLabelFirstName"
                        name="firstName"
                        type="text"
                    />
                </el-form-item>
            </el-col>

            <el-col :span="12">
                <el-form-item prop="lastName">
                    <el-input
                        v-model="form.lastName"
                        :label="uiStrings.formLabelLastName"
                        name="lastName"
                        type="text"
                    />
                </el-form-item>
            </el-col>
        </el-row>

        <el-row :gutter="16">
            <el-col :span="12">
                <el-form-item prop="email">
                    <el-input
                        ref="email"
                        v-model="form.email"
                        :label="uiStrings.formLabelEmail"
                        name="email"
                        type="email"
                    />
                </el-form-item>
            </el-col>

            <el-col :span="12">
                <el-form-item prop="phone">
                    <el-input
                        v-model="form.phone"
                        :label="uiStrings.formLabelPhone"
                        name="phone"
                        type="phone"
                    />
                </el-form-item>
            </el-col>
        </el-row>

        <el-row :gutter="16">
            <el-col :span="12">
                <el-form-item prop="company">
                    <el-input
                        v-model="form.company"
                        :label="uiStrings.formLabelCompanyName"
                        name="company"
                        type="text"
                    />
                </el-form-item>
            </el-col>

            <el-col :span="12">
                <el-form-item prop="country">
                    <el-select
                        v-model="form.country"
                        v-bind="labelSelect"
                        :label="uiStrings.formLabelCountry"
                        name="country"
                        class="select"
                        filterable
                        :hide-on-resize="isDesktop"
                    >
                        <el-option
                            v-for="item in countries"
                            :key="item.iso_code"
                            :label="item.name"
                            :value="item.iso_code"
                        />
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>

        <div class="section-title">
            {{ uiStrings.sectionTitleIssueDetails }}
        </div>

        <el-form-item prop="subject">
            <el-input
                v-model="form.subject"
                :label="uiStrings.formLabelSubject"
                name="subject"
                type="text"
            />
        </el-form-item>

        <el-form-item prop="description">
            <el-input
                v-model="form.description"
                :label="uiStrings.formLabelDescription"
                type="textarea"
                name="description"
                :autosize="false"
                :rows="8"
            />
        </el-form-item>

        <div class="privacy-disclaimer">
            <a-dangerous-html :content="uiStrings.formMessagePrivacyDisclaimer" />
        </div>

        <el-form-item prop="conditions" class="conditions">
            <el-checkbox
                id="conditions-checkbox-required"
                v-model="form.conditions"
                name="conditionscheckbox-required"
                class="required"
                data-required="true"
                type="checkbox"
                tabindex="-1"
                autocomplete="off"
            >
                <span class="text">
                    I agree with provided conditions
                </span>
            </el-checkbox>
        </el-form-item>

        <el-form-item prop="accept">
            <el-checkbox
                v-model="form.accept"
                name="accept"
            >
                <span class="text">
                    {{ uiStrings.formLabelAcceptTerms }}
                </span>
            </el-checkbox>
        </el-form-item>

        <div class="actions">
            <el-form-error
                v-if="error"
                size="large"
                class="error-message"
                show-icon
            >
                {{ error }}
            </el-form-error>

            <a-button
                :text="uiStrings.formSubmitButtonText"
                type="main"
                size="m"
                native-type="submit"
                class="submit"
                :event="{ doNotSendGA: true }"
            />

            <div class="recaptcha-text">
                <a-dangerous-html :content="uiStrings.formMessageRecaptchaProtected" />
            </div>

            <div ref="recaptcha" class="g-recaptcha" />
        </div>

        <div
            v-if="xhrProgressCounter > 0"
            v-loading="true"
            class="loading"
            el-loading-size="48"
            el-loading-background="fixed-primary"
            el-loading-opacity="0.2"
        />
    </el-form>
</template>

<script lang="ts">
// TODO: probably can be done with a form constructor?
// TODO: can be better aligned with `form` and `recaptcha` mixins – to not reimplement what's already done there

import Loading from '@uikit/ui-kit/packages/loading/src/directive.js';
import Vue from 'vue';
import AButton from '@core/components/button/button.vue';
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import form from '@core/mixins/form.js';
import recaptcha from '@core/mixins/recaptcha.js';

export default Vue.extend({
    name: 'DeveloperSupportFormStepForm',

    components: {
        AButton,
        ADangerousHtml,
        ElForm: () => import('@uikit/ui-kit/packages/form'),
        ElFormItem: () => import('@uikit/ui-kit/packages/form-item'),
        ElFormError: () => import('@uikit/ui-kit/packages/form-error'),
        ElInput: () => import('@uikit/ui-kit/packages/input'),
        ElSelect: () => import('@uikit/ui-kit/packages/select'),
        ElOption: () => import('@uikit/ui-kit/packages/option'),
        ElCheckbox: () => import('@uikit/ui-kit/packages/checkbox'),
        ElRow: () => import('@uikit/ui-kit/packages/row'),
        ElCol: () => import('@uikit/ui-kit/packages/col'),
    },

    directives: {
        Loading,
    },

    mixins: [form, recaptcha, breakpoint],

    props: {
        uiStrings: {
            type: Object,
            required: true,
        },
    },

    emits: ['success'],

    data() {
        return {
            // UI
            xhrProgressCounter: 0,
            isVisibleOptIn: false,
            recaptchaResponse: '',
            error: '',

            // Form
            form: {
                firstName: null,
                lastName: null,
                email: '',
                phone: '',
                country: '',
                company: '',
                subject: '',
                description: '',
                accept: false,
                conditions: false,
            },
        };
    },

    computed: {
        countries() {
            return Array
                .from(this.$store.state.countries.items || [])
                .sort((a, b) => String(a.name).localeCompare(b.name));
        },
        rules() {
            return {
                firstName: {
                    required: true,
                    whitespace: true,
                    trigger: 'debounce-input blur',
                    message: this.setErrorMessage('notFilledFirstName'),
                },
                lastName: {
                    required: true,
                    whitespace: true,
                    trigger: 'debounce-input blur',
                    message: this.setErrorMessage('notFilledLastName'),
                },
                email: {
                    required: true,
                    whitespace: true,
                    trigger: 'debounce-input blur',
                    message: this.setErrorMessage('notFilledEmail'),
                    validator: this.validateEmail.bind(this),
                },
                phone: {
                    required: true,
                    whitespace: true,
                    trigger: 'debounce-input blur',
                    message: this.setErrorMessage('notFilledPhone'),
                    validator: this.validatePhone.bind(this),
                },
                country: {
                    required: true,
                    trigger: 'debounce-input blur',
                    message: this.setErrorMessage('notFilledCountry'),
                },
                company: {
                    required: true,
                    whitespace: true,
                    trigger: 'debounce-input blur',
                    message: this.setErrorMessage('notFilledCompany'),
                },
                subject: {
                    required: true,
                    whitespace: true,
                    trigger: 'debounce-input blur',
                    message: this.setErrorMessage('notFilledField'),
                },
                description: {
                    required: true,
                    whitespace: true,
                    trigger: 'debounce-input blur',
                    message: this.setErrorMessage('notFilledField'),
                },
                accept: {
                    // TODO: `enum` validator doesn't work because async-validator peered with @uikit is dramatically outdated (v1.8.5)
                    // and has a bug with validatation of the `false` value, as it does `if (value)` internally.
                    // There is a chance that fresh @uikit v23 has this peer dep updated and this rule works there.
                    // Meanwhile we'll have to mimic this behavior with a custom validator function.
                    // See also: this commit https://github.com/yiminghe/async-validator/commit/756f5e1023d5305104a6758517b592f52657cf70
                    // type: 'enum',
                    // enum: [true],
                    trigger: 'debounce-input blur',
                    validator: (rule, val, cb) => {
                        const errors = [
                            val ? null : new Error(this.setErrorMessage('platformTerms')),
                        ];
                        return cb(errors.filter(Boolean));
                    },
                },
            };
        },
    },

    async mounted() {
        this.recaptchaInject();

        await Promise.all([
            this.acronisAccountAutofill(),
            this.geolocationAutofill(),
        ]);
    },

    methods: {
        assemblePayload() {
            return {
                ...this.form,
                _recaptchaResponse: this.recaptchaResponse,
            };
        },

        async acronisAccountAutofill() {
            this.xhrProgressCounter++;
            await this.$store.dispatch('user/get');
            this.xhrProgressCounter--;

            const account = this.$store.state.user.data;

            if (!account) return;

            this.form.email = account.email || '';
            this.form.firstName = account.first || '';
            this.form.lastName = account.last || '';
            this.form.company = account.company_name || '';
            this.form.phone = account.phone || '';

            this.$refs.form.clearValidate();
        },

        async geolocationAutofill() {
            this.xhrProgressCounter++;
            await this.$store.dispatch('geolocation/getGeoLocation');
            this.xhrProgressCounter--;

            const loc = this.$store.state.geolocation.location;

            if (!loc) return;

            this.form.country = loc.data.country?.code;
        },

        async validateRecaptcha() {
            const res = await this.recaptchaActive().execute(this.recaptchaWidgetId);
            return res;
        },

        validateForm() {
            // TODO: `await this.$refs.form.validate();` throws a very obscure error in storybook
            return new Promise((resolve) => {
                this.$refs.form.validate(resolve);
            });
        },

        async submit() {
            this.error = '';

            this.recaptchaResponse = await this.validateRecaptcha();
            if (!this.recaptchaResponse) {
                this.error = this.setErrorMessage('notFilledCaptcha');
                return;
            }

            const isFormValid = await this.validateForm();
            if (!isFormValid) {
                return;
            }

            const payload = this.assemblePayload();

            try {
                this.xhrProgressCounter++;
                await this.$store.dispatch('trial/sendLead', {
                    ...payload,
                    actionURL: '/svc/v1/devportal/support/case',
                });
                this.$emit('success');
            } catch (e) {
                this.error = this.getSAPIErrorMessage(e);
            } finally {
                this.xhrProgressCounter--;
            }
        },

        getSAPIErrorMessage(error) {
            const isEmailError = error?.ex && error?.code === 'invalid_email';

            if (isEmailError) {
                const isTypo = error.ex.reason === 'failed custom grammar check' || error.ex.didYouMean;
                const errorKey = isTypo ? 'invalidMailTypo' : 'invalidMailGeneral';
                return this.formElementsDefault.errors?.eventsMailGunErrors?.[errorKey] || 'Error';
            }

            const specificError = this.formElementsDefault.errors?.apiErrorCodes?.[error.code] || 'Error';
            const defaultError = this.formElementsDefault.errors?.apiErrorCodes?.default || 'Error';
            return specificError || defaultError;
        },
    },
});
</script>

<style lang="postcss" scoped>
.step-form {
    position: relative;
    text-align: start;
}

.loading {
    position: absolute !important;
    inset-inline-start: 0;
    inset-inline-end: 0;
    top: 0;
    bottom: 0;
}

.section-title {
    font-size: 16px;
    line-height: 24px;
    margin: 32px 0 16px;
}

.privacy-disclaimer {
    font-size: 14px;
    color: gray;
    margin: 12px 0;
}

.conditions {
    transform: scale(0);
    margin-top: -48px;
}

.submit {
    width: 100%;
    margin-top: 24px;
}

.actions {
    font-size: 14px;
    line-height: 23px;
}

.recaptcha-text {
    margin-top: 24px;
}
</style>
