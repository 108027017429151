<template>
    <div class="blog-resources">
        <div class="title-wrap">
            <div class="title">
                {{ title }}
            </div>
            <a-link
                v-if="link"
                class="link"
                :to="link.to"
                :text="link.title"
                glyph="arrow"
                size="lead"
            />
        </div>
        <div class="items">
            <dynamic-link
                v-for="(item, i) in items"
                :key="i"
                class="item"
                :to="item.link"
                native-type="a"
            >
                <div class="item-content">
                    <div class="item-title">
                        {{ item.title }}
                    </div>
                    <div class="item-type">
                        {{ item.type }}
                    </div>
                </div>
                <div class="download">
                    <a-glyph name="download-arrow" />
                    <span class="download-label">{{ downloadTitle }}</span>
                    <span class="download-type">{{ item.downloadType }}</span>
                </div>
            </dynamic-link>
        </div>
    </div>
</template>
<script>
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALink from '@core/components/link/link.vue';

export default {
    name: 'BlogResources',
    components: {
        DynamicLink,
        AGlyph,
        ALink,
    },
    props: {
        title: {
            type: String,
            required: true,
        },

        link: {
            type: Object,
            required: true,
        },

        items: {
            type: Array,
            required: true,
        },

        downloadTitle: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="postcss" scoped>
.blog-resources {
    @mixin basic-slice-paddings;

    .title-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 40px;

        @media (--viewport-tablet) {
            align-items: flex-start;
        }

        @media (--viewport-desktop) {
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
        }
    }

    .title {
        @mixin display;
        font-weight: 500;
        color: var(--av-nav-primary);
        text-align: center;
        margin-bottom: 16px;

        @media (--viewport-tablet) {
            @mixin hero;
            text-align: start;
            margin-bottom: 0;
        }

        @media (--viewport-desktop) {
            @mixin hero;
        }
    }

    .link {
        margin-inline-start: 6px;

        &:deep(.a-link__content) {
            @mixin lead-accent;
        }
    }

    .items {
        @media (--viewport-tablet) {
            display: grid;
            column-gap: 16px;
            grid-template-columns: 1fr 1fr 1fr;
        }

        @media (--viewport-desktop-wide) {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    }

    .item {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        min-height: 189px;
        background: var(--av-fixed-white);
        border: 1px solid var(--av-brand-light);
        border-radius: 4px;
        margin-bottom: 16px;
        text-decoration: none;
        cursor: pointer;

        &:nth-child(4) {
            display: none;
        }

        @media (--viewport-tablet) {
            margin-bottom: 0;
        }

        @media (--viewport-desktop-wide) {
            &:nth-child(4) {
                display: flex;
            }
        }

        &:hover {
            .item-title {
                color: var(--av-brand-secondary);
            }
        }
    }

    .item-content {
        padding: 24px 24px 16px;
        text-decoration: none;
    }

    .item-title {
        @mixin paragraph-accent;
        color: var(--av-nav-primary);
        margin-bottom: 8px;
    }

    .item-type {
        @mixin paragraph;

        color: var(--av-fixed-light);
    }

    .download {
        text-decoration: none;
        border-top: 1px solid var(--av-brand-accent);
        font-size: 16px;
        line-height: 16px;
        padding: 24px;
        margin-top: auto;
    }

    .download-label {
        display: inline-block;
        font-weight: bold;
        margin: 0 8px;
    }

    .download-type {
        display: inline-block;
        white-space: nowrap;
        color: var(--av-fixed-light);

        @media (--viewport-tablet) {
            margin-top: 4px;
        }

        @media (--viewport-desktop) {
            margin-top: 0;
        }
    }
}
</style>
