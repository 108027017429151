<template>
    <section v-if="content" class="main-page-new__products">
        <div class="a-container">
            <div class="wrapper">
                <div
                    v-for="(item, i) in content"
                    :key="i"
                    class="item"
                    :class="{ accent: item.accented, 'has-labels': hasLabels }"
                >
                    <a-label
                        v-if="item.label"
                        class="label"
                        v-bind="getLabelProps(item.label)"
                    >
                        {{ item.label.text }}
                    </a-label>
                    <template v-if="item.title && item.title.text">
                        <h3 class="title">
                            <a-link
                                v-if="item.title.to"
                                v-bind="item.title"
                                :type="item.title.type || 'light'"
                                :size="item.title.size || 'title'"
                            />
                            <span v-else>{{ item.title.text }}</span>
                        </h3>
                    </template>
                    <p v-if="item.description" class="description" v-html="item.description" />
                    <div v-if="item.button || item.link" class="buttons">
                        <a-link
                            v-if="item.link"
                            class="link"
                            v-bind="item.link"
                            :size="item.link.size || 'paragraph'"
                            :event="setGaEvent(item.link)"
                        />
                        <a-button
                            v-if="item.button"
                            class="button"
                            v-bind="item.button"
                            :size="item.button.size || 'm'"
                            :type="item.button.type || 'main'"
                            :event="setGaEvent(item.button)"
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import ALabel from '@core/components/label/label.vue';
import ALink from '@core/components/link/link.vue';

export default {
    name: 'SMainPageProducts',
    components: {
        AButton,
        ALabel,
        ALink,
    },
    props: {
        content: {
            type: Array,
            default: null,
        },
    },
    computed: {
        hasLabels() {
            return this.content.some((item) => item.label);
        },
    },
    methods: {
        setGaEvent(item) {
            return {
                category: 'Conversions',
                action: item.text,
                label: item.to,
            };
        },

        getLabelProps(item) {
            return {
                size: item.size || 's',
                variant: item.variant || 'info',
                theme: item.theme || 'dark',
                layout: item.layout || 'solid',
            };
        },

    },
};
</script>

<style lang="postcss" scoped>
.main-page-new__products {
    position: relative;
    z-index: 2;
    padding-bottom: 64px;
    color: var(--av-fixed-white);
    @media (--viewport-tablet) {
        padding-bottom: 80px;
    }
    .wrapper {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        .item {
            @mixin colls 12;
            position: relative;
            display: flex;
            flex-flow: column;
            text-align: center;
            padding: 16px 8px;
            color: var(--av-fixed-white);
            background: linear-gradient(
                180deg,
                rgba(0, 20, 46, 0.15) 32.55%,
                rgba(5, 99, 220, 0.063) 120.31%
            );
            border: 2px solid var(--av-brand-secondary-accent);
            box-shadow: 0px 10px 20px var(--av-fixed-lightest);
            backdrop-filter: blur(5px);
            border-radius: 8px;
            order: 1;
            text-decoration: none;
            @media (--viewport-tablet) {
                @mixin colls 4;
                order: unset;
            }
            @media (--viewport-desktop) {
                padding: 16px;
            }
            @media (--viewport-desktop-large) {
                padding: 16px 24px;
            }
            &:before {
                position: absolute;
                content: '';
                top: 0;
                inset-inline-end: 0;
                bottom: 0;
                inset-inline-start: 0;
                z-index: -3;
                border-radius: 6px;
                opacity: 0;
                transition: opacity 0.3s;
                background: linear-gradient(
                    180deg,
                    rgba(0, 20, 46, 0.5) 32.55%,
                    rgba(5, 99, 220, 0.21) 120.31%
                );
            }
            .label {
                position: absolute;
                inset-inline-start: 50%;
                transform: translateX(-50%);
                top: -12px;
                white-space: nowrap;
                overflow: hidden;
            }
            &.accent {
                order: 0;
                margin-bottom: 16px;
                margin-top: 0 !important;
                transition: all 0.3s;
                border: 2px solid var(--av-brand-secondary-light);
                @media (--viewport-tablet) {
                    order: unset;
                    margin-bottom: 0;
                }
                &:before {
                    background: linear-gradient(
                        180deg,
                        #002c65 34.27%,
                        rgba(0, 112, 255, 0.42) 100%
                    );
                }
                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    inset-inline-end: 0;
                    bottom: 0;
                    inset-inline-start: 0;
                    z-index: -4;
                    border-radius: 6px;
                    opacity: 1;
                    transition: opacity 0.3s;
                    background: linear-gradient(
                        180deg,
                        rgba(0, 32, 72, 0.8) 34.27%,
                        rgba(0, 112, 255, 0.336) 100%
                    );
                }
            }
            &:hover {
                &:before {
                    opacity: 1;
                }
            }
            &:not(:first-child) {
                margin-top: 16px;
                @media (--viewport-tablet) {
                    margin-top: 0;
                }
            }

            .title {
                @mixin title;
                font-weight: 700;
                .a-link {
                    z-index: 2;
                    position: relative;
                    &__content {
                        @mixin title;
                        font-weight: 700;
                    }
                }
            }

            .description {
                @mixin paragraph;
                margin: 16px 0 24px;
                @media (--viewport-tablet) {
                    @mixin body;
                    margin: 16px 0;
                }
                @media (--viewport-desktop) {
                    @mixin paragraph;
                    margin: 16px 0 8px;
                }
                @media (--viewport-desktop-large) {
                    margin: 16px 0 32px;
                }

                &:deep(b),
                &:deep(strong) {
                    font-weight: 700;
                }
                &:deep(a) {
                    z-index: 2;
                    position: relative;
                    text-decoration: none;
                    color: var(--av-branded-icon-solid-lightest);
                    &:hover {
                        color: var(--av-brandless-icon-solid-light);
                    }
                }
            }

            .buttons {
                display:flex;
                flex-flow: column;
                margin-top: auto;
                position:relative;
                .button {
                    @mixin paragraph;
                    z-index: 2;
                    position: relative;
                    max-width: 200px;
                    width: 100%;
                    padding: 8px 0;
                    margin: 0px auto;
                    font-weight: 700;
                }
                .link {
                    @mixin paragraph;
                    z-index: 2;
                    max-width: 200px;
                    width:100%;
                    padding: 8px 0;
                    font-weight: 500;
                    border: 1px solid var(--av-branded-icon-solid-lightest);
                    border-radius: 4px;
                    margin: 0 auto 16px;
                    transition: all linear 0.12s;
                    &:deep(.a-link__content) {
                        color: var(--av-branded-icon-solid-lightest);
                    }
                    &:hover {
                        background: var(--av-brand-light);
                    }
                    &:focus, &:active {
                        background: rgba(38, 104, 197, 0.6);
                        &:deep(.a-link__content) {
                            background: none;
                        }
                    }
                }
            }
            &.has-labels {
                padding: 24px 16px 16px;
                @media (--viewport-desktop-large) {
                    padding: 24px 24px 16px;
                }
                &.accent {
                    margin-bottom: 24px;
                    @media (--viewport-tablet) {
                        margin-bottom: 0;
                    }
                }
                &:not(:first-child) {
                    margin-top: 24px;
                    @media (--viewport-tablet) {
                        margin-top: 0;
                    }
                }
            }
        }
    }
}
</style>
