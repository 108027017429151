<template>
    <div
        :id="id"
        class="a-modal"
        :class="className"
        aria-hidden="true"
    >
        <div
            class="wrapper"
            role="dialog"
            aria-modal="true"
            :class="{'is-visible': showElement}"
        >
            <div class="overlay" :data-close="isOverlayClose" @click="toggleElements" />
            <div class="content">
                <div
                    v-if="isScreenshot"
                    v-loading="true"
                    class="loader"
                    el-loading-size="48"
                    el-loading-color="fixed-white"
                    el-loading-text="Image is loading"
                />
                <div class="inner">
                    <slot />
                    <a-button
                        data-close
                        class="close"
                        glyph="close"
                        aria-label="Close modal"
                    />
                </div>
            </div>
            <div v-if="$slots.playlist" class="playlist">
                <slot name="playlist" />
            </div>
            <div v-if="$slots.gallery" class="gallery">
                <slot name="gallery" />
            </div>
            <a-button
                data-close
                class="close-mobile"
                :class="{'show': showElement}"
                glyph="close"
                aria-label="Close modal"
            />
        </div>
    </div>
</template>

<script>
import Loading from '@uikit/ui-kit/packages/loading/src/directive';
import AButton from '@core/components/button/button.vue';
import Modal from '@core/directives/modal.js';

export default {
    name: 'AModal',

    components: {
        AButton,
    },

    directives: {
        Modal,
        Loading,
    },

    props: {
        type: {
            type: String,
            default: 'modal',
        },
        id: {
            type: String,
            required: true,
        },
        isOverlayClose: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            showElement: true,
        };
    },
    computed: {
        isScreenshot() {
            return ['screenshot', 'gallery'].includes(this.type);
        },
        className() {
            const { type } = this;

            return {
                [`type_${type}`]: type,
            };
        },
    },
    methods: {
        toggleElements() {
            if (this.isOverlayClose || this.type !== 'gallery') {
                this.showElement = true;
                return;
            }
            this.showElement = !this.showElement;
        },
    },
};
</script>

<style lang="postcss" scoped>
.a-modal {
    display: none;
    position: fixed;
    z-index: 2147483650; /* this must be on top of geoIP popup which has z-index: 2147483647 because cookie popup has z-index: 2147483645 */
    &.is-modal-open {
        display: block;
    }
    .wrapper {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        min-width: 100vw;
        min-height: 100vh;
        overflow: auto;
        user-select: none;
        background: var(--av-fixed-secondary);
        padding: 48px 16px;
        @media (--viewport-mobile-wide) {
            padding: 48px 32px;
        }
        @media (--viewport-desktop) {
            padding: 48px;
        }
        .gallery {
            display: none;
            @media (--viewport-tablet) {
                display: block;
            }
        }
    }
    .overlay {
        position:absolute;
        top: 0;
        inset-inline-start: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        cursor: default;
    }
    .content {
        display: flex;
        flex-direction: column;
        height: 100%;
        align-items: center;
        justify-content: center;
        @media (--viewport-desktop) {
            @mixin colls 10;
            margin: 0 auto;
        }
        :deep(> video, > picture) {
            position: absolute;
            max-width: 100%;
            max-height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .inner {
            position: relative;
            max-width: 100%;
            max-height: 100%;
            > picture {
                height: 100%;
            }
            > video {
                max-height: 100%;
                max-width: 100%;
            }
        }
    }
    .close, .close-mobile {
        border: 1px solid #fff;
        border-radius: 50%;
        padding: 8px;
        transition: all 0.3s;
        &:hover {
            background: var(--av-fixed-secondary);
        }
        :deep(.a-glyph) {
            margin: 0;
        }
    }
    .close-mobile {
        display: flex;
        position: absolute;
        top: 8px;
        inset-inline-end: 8px;
        @media (--viewport-desktop) {
            display: none;
        }
    }
    .close {
        display: none;
        position: absolute;
        top: 0;
        inset-inline-end: -48px;
        @media (--viewport-desktop) {
            display: flex;
        }
    }
    &.type_modal {
        .wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .content {
            margin: auto 0;
            height: auto;
        }
        .inner {
            width: 100%;
        }
    }
    &.type_playlist, &.type_youtube {
        :deep(.a-youtube--modal) {
            aspect-ratio: 16 / 9;
            width: 100%;
            height: auto;
            max-height: 100%;
            max-width: 100%;
            top: 50%;
            inset-inline-start: 50%;
            transform: translate(-50%, -50%);
            @media (--viewport-desktop) {
                height: 100%;
                width: auto;
            }
        }
        .inner {
            width: 100%;
            height: 100%;
        }
    }
    &.type_playlist {
        .content {
            :deep(.a-youtube--modal) {
                @media (--viewport-tablet) {
                    height: 100%;
                }
            }
            height: calc(100% - 230px);
            @media (--viewport-mobile-wide) {
                height: calc(100% - 216px);
            }
            @media (--viewport-tablet) {
                height: calc(100% - 168px);
            }
            @media (--viewport-desktop) {
                height: calc(100% - 144px);
            }
            @media (--viewport-desktop-wide) {
                height: calc(100% - 120px);
            }
        }
        .playlist {
            width: 100%;
            margin-top: 32px;
            @media (--viewport-mobile-wide) {
                margin-top: 40px;
            }
            @media (--viewport-desktop) {
                @mixin colls 10;
                margin: 40px auto 0;
            }
        }
    }
    &.type_screenshot, &.type_gallery {
        .content {
            @mixin colls 12;
            .loader {
                position: absolute !important;
                z-index: 0;
                pointer-events: none;
                :deep(.el-text) {
                    display: block;
                    padding-top:16px;
                    width: 250px;
                    color: var(--av-fixed-white);
                    opacity: 0.7;
                }
            }
        }
        :deep(.a-picture) {
            box-shadow: var(--av-shadow-modal);
        }
        .wrapper {
            padding: 16px;
            @media (--viewport-mobile-wide) {
                padding: 16px;
            }
            @media (--viewport-tablet) {
                padding: 48px 32px;
            }
            @media (--viewport-desktop-wide) {
                padding: 48px;
            }
        }
        .close-mobile {
            background: var(--av-fixed-secondary);
            @media (--viewport-tablet) {
                background: transparent;
                &:hover {
                    background: var(--av-fixed-secondary);
                }
            }
            @media (--viewport-desktop) {
                display: flex;
            }
            @media (--viewport-desktop-wide) {
                top: 16px;
                inset-inline-end: 16px;
            }
        }
        .close {
            display: none;
        }
    }
    &.type_gallery {
        .wrapper {
            @media (--viewport-tablet) {
                padding-bottom: 0px;
            }
            .close-mobile {
                display: none;
                @media (--viewport-tablet) {
                    display: flex;
                }
            }
            &.is-visible {
                .close-mobile {
                    display: flex;
                }
                .gallery {
                    display: block;
                }
            }
        }
        .content {
            @media (--viewport-tablet) {
                height: calc(100% - 144px);
            }
        }
    }
}
[dir='rtl'] {
    .a-modal {
        &.type_playlist, &.type_youtube {
            :deep(.a-youtube--modal) {
                inset-inline-start: unset;
                inset-inline-end: 50%;
            }
        }
    }
}
</style>
