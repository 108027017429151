import { render, staticRenderFns } from "./component.vue?vue&type=template&id=4fd1b901&scoped=true"
import script from "./component.vue?vue&type=script&lang=ts"
export * from "./component.vue?vue&type=script&lang=ts"
import style0 from "./component.vue?vue&type=style&index=0&id=4fd1b901&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fd1b901",
  null
  
)

export default component.exports