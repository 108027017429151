<template>
    <div v-if="isVisibleEdition" class="s-purchasing-acp-editions">
        <div v-for="(edition, editionIndex) in editionList" :key="`edition-${editionIndex}`" class="edition-wrapper">
            <div class="recommended">
                <div v-if="isRecommended(edition)" class="recommended-text">
                    <div class="recommended-title">
                        {{ translation('Recommended') }}
                        <a-glyph name="add-action" />
                    </div>
                    <div v-if="edition.label?.text" class="recommended-label">
                        <a-label v-bind="{...edition.label, size: 's', text: ''}">
                            {{ edition.label.text }}
                        </a-label>
                    </div>
                </div>
                <div v-else-if="edition.label?.text" class="edition-label">
                    <a-label v-bind="{...edition.label, size: 'l', text: ''}">
                        {{ edition.label.text }}
                    </a-label>
                </div>
            </div>

            <div :id="`edition-${edition.product}`" :class="classEdition(edition)">
                <p class="edition-title">
                    {{ edition.title }}
                </p>
                <div v-for="(item, itemIndex) in edition.content" :key="`edition-${itemIndex}`" :class="`edition-${item.name || ''}`">
                    <template v-if="item.name === 'description'">
                        {{ item.text }}
                    </template>

                    <template v-else-if="item.name === 'features'">
                        <p v-if="item.text" class="feature-description" v-html="item.text" />
                        <div v-for="(feature, featureIndex) in item.list" :key="`feature-${featureIndex}`" class="feature-item">
                            <div class="edition-feature" v-html="feature.category" />
                            <a-tooltip
                                v-if="feature.tooltip"
                                :text="feature.tooltip"
                                :class="['edition-tooltip', `edition-tooltip-type-${feature.type}`]"
                                popper-class="el-tooltip__purchasing-edition"
                            >
                                <a-glyph name="success" />
                                <span>{{ feature.name }}</span>
                            </a-tooltip>
                        </div>
                    </template>

                    <template v-else-if="item.name === 'price'">
                        <p v-if="hasDiscount(edition)" class="edition-price-full" v-html="priceEdition(edition.product, 'full')" />
                        <p class="edition-price-current" v-html="priceEdition(edition.product, 'current')" />
                    </template>

                    <template v-else-if="item.name === 'notifacation'">
                        <a-glyph name="info-circle-o" /> {{ item.text }}
                    </template>

                    <template v-else-if="item.name === 'dropdowns'">
                        <div class="edition-selector-workload">
                            <s-dropdown
                                :is-selector-active="edition.product === errorEdition"
                                :placeholder="dropdownPlaceholderWorkload(edition.product)"
                                :description="translation('Workload selection')"
                                :list="dropdownWorkloads(edition.product)"
                                @clickOpen="errorEdition = ''"
                                @changeItem="$emit('changeQuantity', $event)"
                                @changeQuantity="$emit('changeQuantity', $event)"
                            />
                        </div>
                        <div class="edition-selector-period">
                            <s-dropdown
                                :placeholder="titlePeriod(edition.selected)"
                                :description="translation('License period')"
                                :list="dropdownWorkloadsPeriod(edition.product)"
                                @changeItem="$emit('changeItem', $event)"
                            />
                        </div>
                    </template>

                    <template v-else-if="item.name === 'button'">
                        <a-button
                            size="s"
                            type="action"
                            glyph="shopping-cart-o"
                            :text="translation('Add to cart')"
                            :event="{ doNotSendGA: true }"
                            @click="toggleCart(edition)"
                        />
                    </template>
                </div>
                <div v-if="settings?.page?.isVisibleComparisonLink" class="edition-compare">
                    <a-button
                        glyph="arrow"
                        :text="translation('Compare editions')"
                        :event="{ doNotSendGA: true }"
                        @click="scrollTo('comparison-acp')"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALabel from '@core/components/label/label.vue';
import ATooltip from '@core/components/tooltip/tooltip.vue';
import analytics from '@core/mixins/analytics.js';
import currency from '@core/mixins/currency.js';
import mixin from '../mixin.js';
import SDropdown from './dropdown.vue';

export default {
    name: 'SEditions',

    components: {
        AButton,
        AGlyph,
        ALabel,
        ATooltip,
        SDropdown,
    },

    mixins: [mixin, analytics, currency],

    props: {
        settings: {
            type: Object,
            default: null,
        },
    },

    emits: ['toggleCart', 'changeItem', 'changeQuantity'],

    data: () => ({
        errorEdition: '',
    }),

    computed: {
        isVisibleEdition() {
            return Boolean(this.settings.licenses?.length);
        },
    },

    methods: {
        classEdition(edition) {
            return ['edition', { 'edition-active': this.priceEditionTotal(edition.product, 'price') }];
        },

        isRecommended(edition) {
            const activeWorkload = this.settings.workloads.find((item) => item.selected) || {};
            return edition.product === activeWorkload.recommended;
        },

        hasDiscount(edition) {
            const full = this.priceEdition(edition.product, 'full');
            const current = this.priceEdition(edition.product, 'current');
            return this.settings.promo.isPromoActive && full !== current;
        },

        currentworkloads(name) {
            const period = this.currentPeriod(name);
            const comparison = (item) => item.quantity && item.product === name && item.period === period;
            return this.settings.licenses.filter(comparison);
        },

        dropdownPlaceholderWorkload(name) {
            const list = this.currentworkloads(name);
            const quantity = list.length;
            const license = list?.[0] || {};
            const workloadTitle = this.currentWorkload(license.workload)?.title || '';
            const selected = `${workloadTitle} (${license.quantity * license.multiplier})`;
            const placeholder = quantity > 1 ? this.translation('@quantity Item(s) selected') : selected;
            return quantity ? placeholder.replace('@quantity', quantity) : this.translation('Select workload');
        },

        dropdownWorkloads(name) {
            const period = this.currentPeriod(name);
            return this.settings.licenses
                .filter((item) => item.product === name && item.period === period)
                .map((item) => ({
                    ...item,
                    hasCheckbox: true,
                    hasNumberPicker: true,
                    isChecked: item.isChecked,
                    text: this.currentWorkload(item.workload).title || '',
                }));
        },

        dropdownWorkloadsPeriod(name) {
            let promo = '';
            let pricePerYear = 0;
            const list = this.settings.licenses.filter((item) => item.product === name).map((item) => item.period);

            return [...new Set(list)].map((period) => {
                const price = this.priceEditionPeriod(name, period);
                if (period === 1) {
                    pricePerYear = price;
                }
                if (price && period > 1) {
                    const percent = Math.round(100 - (100 * price) / (pricePerYear * period));
                    const promoText = this.translation('Save @percent');
                    promo = promoText.replace('@percent', `${percent}%`);
                }
                return {
                    period,
                    product: name,
                    text: `${this.titlePeriod(period)}`,
                    price: price ? this.setCurrency(price) : '',
                    promo,
                };
            });
        },

        priceEditionPeriod(name, period) {
            return this.currentEdition(name)
                .filter((item) => item.quantity && item.period === this.currentPeriod(name))
                .map((item) => {
                    const comparison = (i) => i.workload === item.workload && i.period === period && i.product === name;
                    const license = this.settings.licenses.find(comparison);
                    return license.store.price * item.quantity;
                })
                .reduce((a, b) => a + b, 0);
        },

        errorEditionClear() {
            this.errorEdition = '';
            window.removeEventListener('click', this.errorEditionClear, true);
        },

        toggleCart(edition) {
            if (this.priceEditionTotal(edition.product, 'price')) {
                this.$emit('toggleCart', edition.product);
                return;
            }
            this.errorEdition = edition.product;
            window.addEventListener('click', this.errorEditionClear, true);
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-purchasing-acp-editions {
    display: flex;
    margin-top: 40px;
    flex-direction: column;

    @media (--viewport-tablet) {
        align-items: center;
    }

    @media (--viewport-desktop) {
        gap: 16px;
        margin-top: 16px;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: stretch;
    }
}

.edition-wrapper {
    user-select: none;

    &:not(:first-child) {
        margin-top: 16px;

        @media (--viewport-desktop) {
            margin-top: 0;
        }
    }

    @media (--viewport-tablet) {
        max-width: 464px;
    }

    @media (--viewport-desktop) {
        display: flex;
        width: 33%;
        max-width: unset;
        flex-direction: column;
        justify-content: space-between;
    }
}

.edition {
    height: 100%;
    padding: 16px 24px;
    border-radius: 8px;
    background: var(--av-fixed-white);
    border: 2px solid var(--av-brand-light);

    @media (--viewport-desktop) {
        display: flex;
        flex-direction: column;
    }

    &:deep(.dropdown) {
        .title {
            color: var(--av-fixed-light);
        }
    }
}

.edition-active {
    background: #eaf0f9;

    &:deep(.dropdown) {
        .title {
            color: var(--av-fixed-primary);
        }
    }
}

.edition-label {
    top: 12px;
    display: flex;
    position: relative;
    justify-content: center;

    .a-label {
        margin: 0 24px;
        max-height: 24px;
    }
}

.edition-title {
    @mixin lead-strong;
    padding: 0 16px;
    text-align: center;
    color: var(--av-nav-primary);

    @media (--viewport-desktop) {
        text-align: start;
        padding-inline-start: 0;
    }

    @media (--viewport-desktop-wide) {
        padding-inline-end: 128px;
    }
}

.edition-description {
    @mixin body;
    margin: 8px 0 0;
    text-align: center;
    color: var(--av-fixed-secondary);

    @media (--viewport-desktop) {
        text-align: start;
    }
}

.edition-features {
    padding-top: 32px;

    @media (--viewport-tablet) {
        padding-top: 24px;
    }

    @media (--viewport-desktop) {
        margin-top: auto;
        padding-top: 8px;
    }
}

.feature-description {
    @mixin body-accent;
    color: var(--av-nav-primary);
}

.feature-item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    &:not(:first-child) {
        margin-top: 8px;
    }
}

.edition-tooltip {
    cursor: pointer;
    padding: 4px 8px;
    min-width: 118px;
    user-select: none;
    border-radius: 8px;
    background: var(--av-brand-secondary-accent);
    border: 1px solid var(--av-brand-secondary);

    &:deep(.tooltip-wrapper) {
        display: flex;
        align-items: center;
    }

    &:deep(.a-glyph) {
        margin-inline-end: 8px;
        fill: var(--av-brand-primary);
    }
}

.edition-tooltip-type-1 {
    background: var(--av-fixed-success-accent);
    border-color: var(--av-fixed-success);

    &:deep(.a-glyph) {
        fill: var(--av-fixed-success);
    }
}

.edition-feature {
    @mixin body;
    color: var(--av-fixed-primary);
}

.edition-price {
    margin: 24px 0 0;
}

.edition-price-full {
    @mixin paragraph;
    color: var(--av-fixed-light);
    text-decoration: line-through;
}

.edition-price-current {
    @mixin lead-accent;
    color: var(--av-nav-primary);
}

.edition-notifacation {
    @mixin body-accent;

    display: flex;
    padding: 8px;
    margin: 8px 0 0;
    border-radius: 4px;
    align-items: flex-start;
    color: var(--av-fixed-success-dark);
    background: var(--av-fixed-success-accent);

    .a-glyph {
        margin-top: 4px;
        margin-inline-end: 8px;
        fill: var(--av-fixed-success-dark);
    }
}

.edition-selector-workload {
    margin: 24px 0 0;
}

.edition-selector-period {
    margin: 8px 0 0;
}

.edition-button {
    margin: 24px 0 0;

    .a-button {
        @mixin paragraph-accent;
        width: 100%;
        display: flex;
        justify-content: center;
        background-color: #7c9b1e;
        flex-direction: row-reverse;

        &:hover {
            background: var(--av-fixed-success-darker);
        }
    }

    &:deep(.a-glyph) {
        margin-inline-start: 0;
        margin-inline-end: 8px;
    }
}

.recommended {
    min-height: 32px;
    margin-bottom: -8px;

    .a-label {
        overflow: hidden;
        text-align: center;
    }
}

.recommended-text {
    @mixin note-heading;
    gap: 24px;
    display: flex;
    height: 32px;
    text-align: start;
    padding: 4px 24px 0;
    justify-content: flex-start;
    border-radius: 8px 8px 0 0;
    color: var(--av-fixed-white);
    background: var(--av-nav-primary);

    .a-glyph {
        fill: var(--av-fixed-white);
        margin-inline-start: 4px;
    }
}

.recommended-title {
    white-space: nowrap;
}

.recommended-label {
    margin-inline-start: auto;

    .a-label {
        max-height: 16px;
    }
}

.edition-compare {
    margin: 16px 0 0;
    text-align: center;

    .a-button {
        @mixin paragraph-accent;
        padding: 0;
        border: none;
        box-shadow: none;
        display: inline-block;
        color: var(--av-brand-secondary);

        &:hover {
            background: transparent;
        }
    }

    &:deep(.a-glyph) {
        margin-inline-start: 4px;
        vertical-align: middle;
        fill: var(--av-brand-primary);
    }

    &:deep(.a-button__content) {
        @mixin paragraph;
        font-weight: 400;
        color: var(--av-brand-primary);
    }
}
</style>

<style lang="postcss">
.el-tooltip__purchasing-edition {
    padding: 16px;
    max-width: 300px;

    ol {
        margin: 16px 0 0;

        li {
            position: relative;
            padding-inline-start: 16px;

            &:before {
                left: 0;
                top: 6px;
                width: 4px;
                height: 4px;
                content: '';
                position: absolute;
                border-radius: 50%;
                background: var(--av-fixed-white);
            }
        }
    }
}
</style>
