<template>
    <section v-if="content" class="main-page-new__interface">
        <div class="a-container">
            <div class="wrapper">
                <div class="content">
                    <div v-if="content.title" class="title" v-html="content.title" />
                    <p
                        v-if="content.description"
                        class="description"
                        v-html="content.description"
                    />
                    <a-button
                        v-if="content.button"
                        class="button"
                        v-bind="content.button"
                        :size="content.button.size || 'm'"
                        :type="content.button.type || 'main'"
                    />
                </div>
                <div v-if="content.media" class="picture">
                    <a-media v-bind="content.media" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import AMedia from '@core/components/media/media.vue';

export default {
    name: 'SMainPageInterface',
    components: {
        AButton,
        AMedia,
    },
    props: {
        content: {
            type: Object,
            default: null,
        },
    },
};
</script>

<style lang="postcss" scoped>
.main-page-new__interface {
    padding: 0 0 80px;
    color: var(--av-fixed-white);
    @media (--viewport-tablet) {
        padding: 24px 0 80px;
    }
    .wrapper {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;
        .content {
            @mixin colls 12;
            text-align: center;
            @media (--viewport-desktop) {
                @mixin colls 6;
                text-align: start;
            }

            .title {
                @mixin colls 12;
                @mixin large ;
                margin: 0 auto 24px;
                font-weight:700;
                background: linear-gradient(90deg, rgb(148, 206, 241) 0%, rgb(0, 101, 227) 100%);
                -webkit-background-clip: text;
                background-clip: text;
                -webkit-text-fill-color: transparent;
                @media (--viewport-tablet) {
                    @mixin colls 10;
                }
                @media (--viewport-desktop) {
                    @mixin colls 12;
                    margin: 0 0 24px;
                }
            }
            .description {
                @mixin colls 12;
                @mixin title;
                margin-bottom: 40px;
                @media (--viewport-tablet) {
                    @mixin colls 10;
                    margin: 0 auto 40px;
                }
                @media (--viewport-desktop) {
                    @mixin colls 12;
                    margin: 0 0 48px;
                }
            }
            .button {
                @mixin paragraph;
                font-weight: 700;
                min-width: 180px;
                width: auto;
            }
        }
        .picture {
            max-width: 463px;
            width: 100%;
            cursor: pointer;
            margin: 24px auto 0;
            @media (--viewport-desktop) {
                margin: 0;
            }
        }
    }
}
</style>
