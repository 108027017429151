import { Schema } from '@core/mixins/structuredData';
import OfferHandler from '@core/mixins/structuredData/handlers/offerHandler';
import SchemaHandler from '@model/seo/schemaHandler';
import { OfferSchema, ProductSchema, ReviewSchema, SchemaConfig } from '@model/seo/schemas';
import { getOgImage } from '@utils/html-meta';

const REVIEWS_SLICE_NAME = 's-product-reviews';
const G2_REVIEWS_SLICE_NAME = 's-g2-reviews';

const DEFAULT_REVIEWS_COUNT = '599';

const AGGREGATE_RATING_BY_ID = {
    64: {
        ratingValue: '4.4',
        reviewCount: '62',
    },
    other: {
        ratingValue: '4.7',
        reviewCount: DEFAULT_REVIEWS_COUNT,
    },
};

export default class ProductHandler implements SchemaHandler {
    ctx: any;

    schemasConfig: SchemaConfig[];

    constructor(ctx: any, schemasConfig: SchemaConfig[]) {
        this.ctx = ctx;
        this.schemasConfig = schemasConfig;
    }

    getSchemaData(staticConfig: ProductSchema): Promise<ProductSchema> {
        return this.getRequiredFields(staticConfig);
    }

    async getRequiredFields(defaultConfig: ProductSchema): Promise<ProductSchema> {
        const page = this.ctx.$store.state.pages.page;
        const slices = this.ctx.$store.state.pages.page.body || [];
        const reviews = slices.find((slice) => slice.name === REVIEWS_SLICE_NAME);
        const productId = this.ctx.$store.state.pages.page?.product_id;

        const data = await this.fillProductData(defaultConfig, page, productId);

        if (!data) return null;

        // skip if there are already g2 reviews
        if (!data.review && reviews?.data?.reviews?.length) {
            data.review = this.getReviews(reviews.data.reviews);
        }

        return data;
    }

    async fillProductData(data: ProductSchema, page: any, productId: number): Promise<ProductSchema> {
        const store = this.ctx.$store;
        const productData = store.state.products.items[productId];

        if (!productData) return null;

        const result = { ...data };
        const storageUrl = store.getters['config/$config'].env.HEAD_SITE_MAIN_PUBLIC_BASE_URL_STORAGE;

        result.name = productData?.name || '';
        result.description = productData?.parameters?.common?.title || '';
        result.image = getOgImage(page.meta.image, storageUrl);

        let ratingConfig: any;
        const g2SliceData = store.state.pages?.page?.body?.find((slice: any) => slice.name === G2_REVIEWS_SLICE_NAME);

        if (g2SliceData) {
            let ratingData = g2SliceData.data;

            if (!ratingData && g2SliceData.dataFrom) {
                await store.dispatch('slices/getSyncedData', {
                    slice: g2SliceData.dataFrom, locale: this.ctx.$route.params.locale,
                });
                ratingData = store.state.slices.items[g2SliceData.dataFrom];
            } else {
                ratingConfig = this.getHardcodedRating(productId);
            }

            if (ratingData) {
                const promises = [
                    store.dispatch('products/getG2ProductRating', ratingData.g2ProductId),
                    store.dispatch('products/getG2ProductReviews', ratingData.g2ProductId),
                ];
                const [ratingValue, g2reviews] = await Promise.all(promises);

                ratingConfig = {
                    ratingValue,
                    reviewCount: DEFAULT_REVIEWS_COUNT,
                };

                if (g2reviews?.length) {
                    result.review = g2reviews.map((review: any) => ({
                        author: review.author?.name || '',
                        reviewBody: review.contents,
                        name: review.author?.name || '',
                        reviewRating: {
                            '@type': 'Rating',
                            bestRating: '5',
                            ratingValue: review.rating,
                            worstRating: '1',
                        },
                    }));
                }
            } else {
                ratingConfig = this.getHardcodedRating(productId);
            }
        } else {
            ratingConfig = this.getHardcodedRating(productId);
        }

        result.aggregateRating = {
            '@type': 'AggregateRating',
            ratingValue: ratingConfig.ratingValue,
            reviewCount: ratingConfig.reviewCount,
        };

        result.offers = await this.getOfferData(productData);

        return result;
    }

    getHardcodedRating(productId: any) {
        return AGGREGATE_RATING_BY_ID[productId] || AGGREGATE_RATING_BY_ID.other;
    }

    getReviews(reviews: any): ReviewSchema[] {
        return reviews.map((review) => ({
            author: review.author,
            reviewBody: review.text,
            name: `${review.role}${review.companyName ? ` - ${review.companyName}` : ''}`,
            reviewRating: {
                '@type': 'Rating',
                bestRating: '5',
                ratingValue: review.rate,
                worstRating: '1',
            },
        }));
    }

    getOfferData(productData: any): Promise<OfferSchema> {
        const staticConfig: any = this.schemasConfig.find((schema) => schema['@type'] === Schema.Offer);
        const handler = new OfferHandler(this.ctx, this.schemasConfig);
        return handler.getOfferData(staticConfig, productData);
    }
}
