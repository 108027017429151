/* eslint-disable max-len */
const ARG_DEFAULTS = {
    translations: {
        title: 'Learn how to provide hundreds of systems fast',
        lead: 'Only one solution combines backup and antimalware together to protect you from today’s threats – from accidents to failures to attacks',
        'video.text': 'Real-time demo of ransomware protection',
    },
    isReversed: false,
    withSmallLead: false,
    theme: 'light',
    background: 'light-blue',
    title: 'title',
    lead: 'lead',
    media: {
        type: 'video',
        ytId: 'kSLEGX-j9Jg',
        time: '1:20:34',
        text: 'video.text',
        image: 'images/video_car/video_car',
    },
};

export const Figma = 'https://www.figma.com/design/eM4W6JS2MjG1gp5YhKoMp7Dd/Slices?node-id=44747-69703&t=JMJ2fbzoLIVoGwai-4';

export const Dark = {
    args: {
        ...ARG_DEFAULTS,
        theme: 'dark',
        background: 'dark-blue',
        backgroundImage: {
            desktop: '/images/video-featured/video-featured-desktop',
            tablet: '/images/video-featured/video-featured-tablet',
            mobile: '/images/video-featured/video-featured-mobile',
        },
    },
};

export const Light = {
    args: {
        ...ARG_DEFAULTS,
        theme: 'light',
        background: 'light-blue',
        backgroundImage: {
            desktop: '/images/video-featured/video-featured-light-desktop',
            tablet: '/images/video-featured/video-featured-light-tablet',
            mobile: '/images/video-featured/video-featured-light-mobile',
        },
    },
};
