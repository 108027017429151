<template>
    <div v-if="addonList.length" :class="['order-addons', `is-page-${settings.mode}`]">
        <p v-if="settings.mode === 'products'" class="title">
            {{ translation('Add-ons') }}
        </p>
        <div class="list">
            <div v-for="(addon, addonIndex) in addonList" :key="`addon-${addonIndex}`" class="addon">
                <div class="info">
                    <p class="name">
                        <b>{{ titleAddon(addon) }}</b>
                    </p>

                    <template v-if="addon.product === 'cloud-storage'">
                        <p v-if="addon.period">
                            {{ translation('License period:') }} <b> {{ titlePeriod(addon.period, 'short') }}</b>
                        </p>
                        <p v-if="addon.cloud">
                            {{ translation('Cloud:') }} <b>{{ titleCloud(addon.cloud) }}</b>
                        </p>
                        <p v-if="valueExtension">
                            {{ translation('Physical data shipping') }}
                        </p>
                    </template>

                    <template v-else-if="addon.product === 'disaster-recovery'">
                        <p v-if="addon.size">
                            {{ translation('Instance:') }} <b>{{ titleInstance(addon.size) }}</b>
                        </p>
                    </template>

                    <template v-else-if="addon.product === 'onboarding-service'">
                        <p v-if="addon.quantity">
                            {{ translation('Quantity:') }} <b>{{ addon.quantity }}</b>
                        </p>
                    </template>
                </div>
                <div v-if="settings.mode === 'addons'" class="prices">
                    <p v-if="addon.store.discount_absolute" class="price" v-html="setCurrency(priceFull(addon))" />
                    <p class="price-current" v-html="setCurrency(priceCurrent(addon))" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import currency from '@core/mixins/currency.js';
import mixin from '../mixin.js';

export default {
    name: 'SOrderAddon',

    mixins: [mixin, currency],

    props: {
        settings: {
            type: Object,
            default: null,
        },
    },

    computed: {
        addonList() {
            return this.settings.products
                .map((edition) => this.addedLicenses.filter((item) => !item.workload).find((item) => item.product === edition.product))
                .filter(Boolean);
        },
    },

    methods: {
        titleAddon(addon) {
            return this.settings.products.find((item) => item.product === addon.product)?.title || '';
        },

        titleInstance(name) {
            const result = name.charAt(0).toUpperCase() + name.slice(1);
            return result.replace('-', ' ');
        },

        priceFull(addon) {
            const price = addon.store?.price || 0;
            const discount = addon.store?.discount_absolute || 0;
            return (price + discount) * addon.quantity;
        },

        priceCurrent(addon) {
            const price = addon.store?.price || 0;
            return price * addon.quantity;
        },
    },
};
</script>

<style lang="postcss" scoped>
.title {
    @mixin lead-accent;
    color: var(--av-nav-primary);
}

.list {
    display: flex;
    flex-wrap: wrap;
}

.addon {
    @mixin paragraph;
    width: 100%;
    margin-top: 16px;
    color: var(--av-nav-primary);

    @media (--viewport-tablet) {
        width: 50%;

        &:nth-child(even) {
            padding-inline-start: 16px;
            border-inline-start: 1px solid var(--av-brand-secondary-light);
        }
    }
}

.is-page-addons {
    .list {
        display: flex;
        flex-direction: column;
    }

    .addon {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        margin: 24px 0 0;
        padding: 24px 0 0;
        justify-content: space-between;
        border-top: 1px solid var(--av-brand-secondary-light);

        @media (--viewport-tablet) {
            flex-direction: column;
        }

        @media (--viewport-desktop) {
            flex-direction: row;
        }

        &:nth-child(even) {
            padding-inline-start: 0;
            border-inline-start: none;
        }

        .name {
            margin-bottom: 8px;
        }

        .prices {
            text-align: end;

            @media (--viewport-tablet) {
                margin-top: 8px;
            }

            @media (--viewport-desktop) {
                margin-top: 0;
            }
        }

        .price {
            @mixin paragraph;
            color: var(--av-fixed-light);
            text-decoration: line-through;
        }

        .price-current {
            @mixin lead-accent;
            color: var(--av-nav-primary);
        }
    }
}
</style>
