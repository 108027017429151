<template>
    <div v-if="features" class="event-head-features" :class="className">
        <div v-for="feature in features" :key="feature.title" class="feature">
            <div class="caption">
                {{ feature.title }}
            </div>
            <div class="value" :class="valueClass(feature)">
                {{ feature.value }}
                <a-tooltip v-if="feature.tooltip" :text="feature.tooltip" />
            </div>
        </div>
    </div>
</template>

<script>
import ATooltip from '@core/components/tooltip/tooltip.vue';

export default {
    name: 'EventHeadFeatures',

    components: {
        ATooltip,
    },
    props: {
        features: {
            type: Array,
            default: null,
        },
        theme: {
            type: String,
            default: 'dark',
        },
    },
    computed: {
        className() {
            return this.theme === 'light' ? 'light' : null;
        },
    },
    methods: {
        valueClass(feature) {
            return feature.tooltip ? 'value_with-tooltip' : null;
        },
    },
};
</script>

<style lang="postcss" scoped>
.event-head-features {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    z-index: 1;

    @media (--viewport-tablet) {
        justify-content: flex-start;
        flex-wrap: nowrap;
    }

    .feature {
        @mixin paragraph;
        margin-inline-start: 16px;
        margin-inline-end: 16px;
        text-align: center;

        &:first-child {
            width: 100%;
            text-align: center;
            margin-bottom: 16px;

            @media (--viewport-tablet) {
                width: auto;
            }

            @media (--viewport-desktop) {
                text-align: start;
                margin-bottom: 0;
            }
        }

        &:last-child {
            @media (--viewport-tablet) {
                margin-inline-end: 0;
            }
        }

        @media (--viewport-tablet) {
            margin-inline-end: 32px;
            margin-inline-start: 0;
            text-align: start;
        }
    }

    .caption {
        color: var(--av-fixed-white-secondary);
        margin-bottom: 8px;
    }

    .value {
        @mixin paragraph-accent;
        color: var(--av-fixed-white);

        &_with-tooltip {
            display: flex;
            align-items: center;
            justify-content: center;

            @media (--viewport-tablet) {
                justify-content: flex-start;
            }
        }
    }

    .a-tooltip {
        background: transparent;
        margin-inline-start: 8px;

        &:deep(.a-glyph) {
            fill: var(--av-solid-brand-secondary-light);
        }

        &:deep(.tooltip-wrapper) {
            display: flex;
        }

    }

    &.light {
        .caption,
        .value {
            color: var(--av-nav-primary);
        }
    }
}
</style>
