<template>
    <div class="product-split">
        <s-header-lp-two-products v-bind="header" :products="products" />
        <div class="a-container">
            <div class="product-split__table">
                <a-table-new v-bind="table" />
            </div>
            <div v-if="buyblock" class="buyblock">
                <div class="buyblock__row">
                    <div class="buyblock__cell buyblock__cell_empty" />
                    <div v-for="(item, i) in buyblock.items" :key="i" class="buyblock__cell">
                        <div class="buyblock__cell-inner">
                            <div v-if="item.text" class="buyblock__text">
                                {{ item.text }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="buyblock__row">
                    <div class="buyblock__cell buyblock__cell_empty" />
                    <div v-for="(item, i) in buyblock.items" :key="i" class="buyblock__cell">
                        <div class="buyblock__cell-inner">
                            <a-product-cta :settings="{ hasDescription: true, ...item.productPageCTA }" />
                            <a-button
                                v-if="item.button"
                                class="buyblock__button"
                                v-bind="item.button"
                            />
                        </div>
                    </div>
                </div>
                <div class="buyblock__row">
                    <div class="buyblock__cell buyblock__cell_empty" />
                    <div v-for="(item, i) in buyblock.items" :key="i" class="buyblock__cell">
                        <div v-for="(link, j) in item.links" :key="j" class="buyblock__link-wrap">
                            <a-link class="buyblock__link" v-bind="link" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import ALink from '@core/components/link/link.vue';
import AProductCta from '@core/components/product-cta/product-cta.vue';
import ATableNew from '@core/components/table-new/table-new.vue';
import analytics from '@core/mixins/analytics.js';
import breakpoint from '@core/mixins/breakpoint.js';
import cleverbridge from '@core/mixins/cleverbridge.js';
import purchasing from '@core/mixins/purchasing.js';
import SHeaderLpTwoProducts from '@core/slices/pages/header-lp-two-products/header-lp-two-products.vue';

export default {
    name: 'SProductSplit',

    components: {
        AButton,
        ALink,
        AProductCta,
        ATableNew,
        SHeaderLpTwoProducts,
    },

    mixins: [breakpoint, purchasing, analytics, cleverbridge],

    props: {
        header: {
            type: Object,
            required: true,
        },
        products: {
            type: Array,
            required: true,
        },
        table: {
            type: Object,
            required: true,
        },
        buyblock: {
            type: [Object, Boolean],
            default: false,
        },
    },

    data() {
        return {
            EVENT_TIMEOUT: 10 * 1000,
        };
    },

    mounted() {
        setTimeout(() => {
            this.sendDataLayer({
                eventCategory: 'Timing',
                eventContext: 'timing',
                eventAction: this.$route.path,
                eventLabel: '10sec',
            });
        }, this.EVENT_TIMEOUT);
    },

    methods: {
        sendDataLayer(data) {
            window.dataLayer = window.dataLayer || [];

            window.dataLayer.push({
                event: 'Acronis',
                ...data,
            });
        },
    },

    async serverPrefetch() {
        await this.initSettingsCTA(this.buyblock.items);
    },
};
</script>

<style lang="postcss">
.product-split {
    padding-top: 0;
    padding-bottom: 80px;
    border-bottom: 1px solid rgba(64, 139, 234, 0.15);

    &__table {
        margin-bottom: 40px;
    }

    .product-card {
        box-shadow: 0 0 1px rgba(0, 32, 77, 0.1763), 0 2px 6px rgba(0, 32, 77, 0.1);
        border-radius: 4px;
        overflow: hidden;
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;

        &__link {
            display: block;
            position: absolute;
            inset-inline-start: 0;
            height: 100%;
            width: 100%;
            top: 0;
        }

        &__image-wrap {
            display: block;
            position: relative;
            overflow: hidden;
            min-height: 232px;
        }

        &__image {
            position: absolute;
            width: 100%;
            height: 100%;
            inset-inline-start: 0;
            top: 0;

            .a-picture__img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }

        &__type {
            @mixin body-accent;

            color: var(--av-fixed-white);
            position: absolute;
            inset-inline-start: 24px;
            top: 24px;
            text-transform: uppercase;
        }

        &__content {
            padding: 24px;
            color: var(--av-nav-primary);
            background: var(--av-fixed-white);
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
        }

        &__text {
            font-size: 14px;
            line-height: 20px;
            color: rgba(56, 74, 99, 1);
            margin-bottom: 16px;
        }

        &__info-items {
            display: flex;
            justify-content: space-between;
            flex-wrap: nowrap;
            margin-top: auto;
            margin-bottom: 24px;
        }

        &__info-item {
            font-size: 14px;
            line-height: 20px;
        }

        &__info-label {
            color: var(--av-fixed-success);
            height: 16px;
            line-height: 16px;
            font-weight: bold;
        }

        &__info-s {
            display: inline-block;
            text-decoration: line-through;
            margin-inline-end: 8px;
        }

        &__info-bold {
            display: inline-block;
            font-weight: bold;
        }

        &__info {
            padding-top: 16px;
            color: rgba(56, 74, 99, 1);
        }

        &__offer {
            position: relative;
            display: flex;
            justify-content: space-between;
        }

        &__button {
            &:not(.a-button_type) {
                color: rgba(56, 74, 99, 1);
                border: 1px solid rgba(244, 244, 244, 1);
            }
        }
    }

    .buyblock {
        &__row {
            display: flex;
            justify-content: space-between;
            flex-wrap: nowrap;
        }

        &__cell {
            width: calc(50% - 8px);
            display: flex;
            flex-flow: row wrap;

            @media (--viewport-tablet) {
                flex: 1;
                padding-inline-start: 8px;
                padding-inline-end: 8px;
                width: auto;
            }

            &_empty {
                display: none;

                @media (--viewport-tablet) {
                    display: block;
                }
            }
        }

        &__cell-inner {
            max-width: 276px;
            width: 100%;
            display: flex;
            flex-flow: row wrap;
        }

        &__text {
            @mixin body;

            margin-bottom: 24px;
        }

        &__button {
            width: 100%;
            display: flex;
            margin-bottom: 24px;
            margin-top: auto;
        }

        &__link-wrap {
            margin-bottom: 4px;
            width: 100%;
        }

        &__link {
            @mixin body;
        }
    }
}
</style>

<style lang="postcss" scoped>
.product-split {
    &:deep(.cta) {
        font-size: 20px;
        line-height: 24px;
        margin: auto 0 16px;

        @media (--viewport-desktop) {
            @mixin title;
        }

        del {
            font-weight: 400;
        }
    }
}
</style>
