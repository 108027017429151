<template>
    <div class="s-slice data-centers-page">
        <div class="a-container">
            <a-tabs>
                <a-tab :label="dataCenters.label">
                    <s-data-centers class="no-container" v-bind="{ ...dataCenters, showExtra: false }" />
                </a-tab>
                <a-tab v-if="table" :label="table.label">
                    <a-table-new v-bind="table" />
                </a-tab>
            </a-tabs>
        </div>
    </div>
</template>

<script>
import { get } from 'lodash';
import ATableNew from '@core/components/table-new/table-new.vue';
import ATab from '@core/components/tabs/tab.vue';
import ATabs from '@core/components/tabs/tabs.vue';
import SDataCenters from '@core/slices/pages/data-centers/data-centers.vue';

export default {
    name: 'SDataCentersPage',
    components: {
        ATabs,
        ATab,
        SDataCenters,
        ATableNew,
    },
    props: {
        dataCenters: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            STORE_SLICE_NAME: 's-data-centers',
        };
    },
    computed: {
        dataCentersData() {
            return get(this.$store.state.slices.items, this.STORE_SLICE_NAME, null);
        },
        table() {
            if (!this.dataCentersData) {
                return null;
            }
            const table = {};
            const regions = this.dataCentersData.regions;
            const platforms = this.dataCentersData.platforms;
            const points = this.dataCentersData.points;
            table.label = this.dataCentersData.tableTitle;
            table.settings = this.dataCentersData.tableSettings;
            table.head = [{ title: null }, ...regions.map((region) => ({ val: region.title }))];
            table.rows = platforms.map((platform) => {
                const titleCell = {
                    val: platform.title,
                    type: 'category',
                    glyph: 'data-center',
                };
                const cells = regions.map((region) => {
                    const list = points
                        .filter((point) => point.platform === platform.id && point.region === region.id)
                        .map((point) => (point.city ? `${point.city}, ${point.country}` : point.country))
                        .sort((a, b) => a.localeCompare(b));
                    return { list, type: 'list' };
                });
                const content = [titleCell, ...cells];
                return { content };
            });

            return table;
        },
    },
    methods: {},
};
</script>

<style lang="postcss" scoped>
.data-centers-page {
    padding-bottom: 0;
}
</style>
