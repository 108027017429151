<template>
    <s-basic-slice class="s-sapas" v-bind="$attrs">
        <div class="s-sapas__content">
            <a-tabs v-if="tabs">
                <a-tab
                    v-for="(tab, index) in tabs"
                    :key="generateTabKey(tab.label, index)"
                    :label="tab.label"
                    :translated-label="tab.translatedLabel"
                >
                    <div class="sapas-item">
                        <div class="sapas-item__leftside">
                            <div class="sapas-item__title">
                                {{ tab.content.title }}
                            </div>
                            <a-dangerous-html v-if="tab.content.text" class="sapas-item__text" :content="tab.content.text" />
                            <div class="sapas-item__link-container">
                                <a-link
                                    v-if="tab.content.link"
                                    class="sapas-item__link"
                                    v-bind="tab.content.link"
                                    :glyph="tab.content.link?.glyph || 'arrow'"
                                    :size="tab.content.link?.size || 'paragraph'"
                                />
                            </div>
                        </div>
                        <div class="sapas-item__rightside">
                            <a-picture class="sapas-item__img" :link="tab.content.imagePath" :alt="tab.content.imageAlt" />
                        </div>
                    </div>
                </a-tab>
            </a-tabs>
        </div>
    </s-basic-slice>
</template>

<script>
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';
import ATab from '@core/components/tabs/tab.vue';
import ATabs from '@core/components/tabs/tabs.vue';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';

export default {
    name: 'SSapas',

    components: {
        SBasicSlice,
        ATabs,
        ATab,
        ADangerousHtml,
        ALink,
        APicture,
    },

    inheritAttrs: false,

    props: {
        link: {
            type: Object,
            required: false,
            default: undefined,
        },

        tabs: {
            type: Array,
            required: true, // TODO: why it's optional in the template?
        },
    },

    methods: {
        generateTabKey(label, index) {
            return label.replace(' ', '_') + index;
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-sapas {
    &__content {
        margin: 0 -16px;
        padding: 32px 16px 40px;
        background-color: var(--av-fixed-white);
        box-shadow: 0 8px 24px rgba(56, 74, 99, 0.15);

        @media (--viewport-mobile-wide) {
            margin: 0 -32px;
            padding: 32px 32px 48px;
            border-radius: 4px;
        }

        @media (--viewport-desktop) {
            margin: 0;
        }

        @media (--viewport-desktop-wide) {
            padding: 32px 96px 72px;
        }

        @media (--viewport-desktop-large) {
            padding: 32px 112px 72px;
        }
    }

    &:deep(.a-tabs__header) {
        margin: 0 -16px 32px;

        @media (--viewport-mobile-wide) {
            margin: 0 -32px 32px;
        }

        @media (--viewport-desktop) {
            margin: 0 -32px 40px;
        }

        @media (--viewport-desktop-wide) {
            margin: 0 -96px 40px;
        }

        @media (--viewport-desktop-large) {
            margin: 0 -112px 40px;
        }
    }

    &:deep(.a-tabs__list-wrapper) {
        padding: 0 16px;

        @media (--viewport-mobile-wide) {
            padding: 0 32px;
        }

        @media (--viewport-desktop) {
            padding: 0 8%;
        }

        &::before {
            inset-inline-start: 16px;
            inset-inline-end: 16px;
            width: calc(100% - 32px);

            @media (--viewport-mobile-wide) {
                inset-inline-start: 32px;
                inset-inline-end: 32px;
                width: calc(100% - 64px);
            }

            @media (--viewport-desktop) {
                inset-inline-start: 0;
                inset-inline-end: 0;
                width: 100%;
            }
        }
        &__list {
            justify-content: space-between;
        }
    }

    &:deep(.a-tabs-item) {
        &__label {
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            @media (--viewport-mobile-wide) {
                font-size: 24px;
                line-height: 32px;
            }
        }

        &__translated-label {
            font-size: 12px;
            line-height: 16px;
            color: var(--av-fixed-light);

            @media (--viewport-mobile-wide) {
                font-size: 14px;
                line-height: 20px;
            }
        }
    }

    .sapas-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        @media (--viewport-mobile-wide) {
            flex-direction: row;
        }

        &__leftside {
            margin-bottom: 32px;
            width: 100%;

            @media (--viewport-mobile-wide) {
                margin-bottom: 0;
                width: 58.33%;
                padding-inline-end: 64px;
            }

            @media (--viewport-desktop-wide) {
                width: 60%;
            }

            @media (--viewport-desktop-wide) {
                padding-inline-end: 128px;
            }
        }

        &__rightside {
            width: 100%;

            @media (--viewport-mobile-wide) {
                width: 41.66%;
                padding-inline-end: 64px;
            }

            @media (--viewport-desktop) {
                padding-inline-end: 48px;
            }

            @media (--viewport-desktop-wide) {
                width: 40%;
                padding-inline-end: 0;
            }
        }

        &__title {
            margin-bottom: 16px;
            text-align: center;
            font-size: 18px;
            font-weight: 600;
            line-height: 24px;
            color: var(--av-nav-primary);

            @media (--viewport-mobile-wide) {
                margin-bottom: 24px;
                text-align: start;
                font-size: 24px;
                line-height: 32px;
            }
        }

        &__text {
            font-size: 14px;
            line-height: 20px;
            color: rgba(36, 49, 67, 0.9);

            @media (--viewport-mobile-wide) {
                @mixin paragraph;

                &:deep(p:not(:first-of-type)) {
                    margin-top: 24px;
                }
            }
        }

        &__link-container {
            margin-top: 16px;
            text-align: center;

            @media (--viewport-mobile-wide) {
                margin-top: 24px;
                text-align: start;
            }
        }

        &__link {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: var(--av-brand-secondary);

            @media (--viewport-mobile-wide) {
                @mixin paragraph;
            }
        }

        &__img {
            max-width: 100%;
        }
    }
}
</style>
