<template>
    <div class="blog-insights">
        <div class="title-wrap">
            <div class="title-text">
                <div class="title">
                    {{ title }}
                </div>
                <div v-if="lead" class="lead">
                    {{ lead }}
                </div>
            </div>
            <client-only>
                <a-link
                    v-if="link"
                    class="link"
                    v-bind="link"
                    :text="link.title"
                    :glyph="link.glyph || 'arrow'"
                    :type="link.type || 'light'"
                />
            </client-only>
        </div>
        <div v-if="hasMainContent" class="main">
            <div v-if="spotlightedCard" class="spotlight">
                <dynamic-link class="spotlight-item" :to="spotlightedCard.link" native-type="a">
                    <a-picture class="spotlight-image" :link="spotlightedCard.image_id" fit="cover" />
                    <div class="spotlight-title">
                        {{ spotlightedCard.title }}
                    </div>
                </dynamic-link>
            </div>
            <div class="cards">
                <blog-card
                    v-for="(item, i) in cards"
                    :key="i"
                    class="card"
                    v-bind="{ ...item, layout: 'default', theme: 'dark' }"
                />
            </div>
        </div>
        <div v-if="news" class="news">
            <div v-if="newsTitle" class="news-title">
                {{ newsTitle }}
            </div>
            <div class="news-items">
                <blog-news-item
                    v-for="(item, i) in news"
                    :key="i"
                    class="news-item"
                    v-bind="item"
                    :video-label="newsVideoLabel"
                />
            </div>
        </div>
    </div>
</template>

<script>
import ClientOnly from '@core/components/client-only/client-only.vue';
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';
import BlogCard from '../shared-components/blog-card/blog-card.vue';
import BlogNewsItem from '../shared-components/blog-news-item/blog-news-item.vue';

export default {
    name: 'BlogInsights',
    components: {
        ClientOnly,
        DynamicLink,
        ALink,
        APicture,
        BlogCard,
        BlogNewsItem,
    },
    props: {
        title: {
            type: String,
            required: true,
        },

        lead: {
            type: String,
            default: null,
        },

        link: {
            type: Object,
            default: null,
        },

        spotlightedCard: {
            type: Object,
            default: null,
        },

        news: {
            type: Array,
            default: null,
        },

        newsTitle: {
            type: String,
            default: '',
        },

        newsVideoLabel: {
            type: String,
            required: true,
        },

        cards: {
            type: Array,
            default: () => ([]),
        },
    },
    computed: {
        hasMainContent() {
            return Boolean(this.spotlightedCard || this.cards.length);
        },
    },
};
</script>

<style lang="postcss" scoped>
.blog-insights {
    @mixin basic-slice-paddings;
    color: var(--av-fixed-white);

    .title-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 40px;

        @media (--viewport-tablet) {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    .title {
        @mixin display;

        font-weight: 500;
        text-align: center;
        margin-bottom: 8px;

        @media (--viewport-tablet) {
            @mixin hero;

            text-align: start;
            margin-bottom: 0;
        }
    }

    .lead {
        @mixin paragraph;

        text-align: center;
        margin-bottom: 16px;

        @media (--viewport-tablet) {
            @mixin lead;

            text-align: start;
            margin-bottom: 0;
        }
    }

    .link {
        @mixin lead-accent;

        margin-inline-start: 16px;
    }

    .main {
        margin-bottom: 16px;

        @media (--viewport-tablet) {
            display: grid;
            column-gap: 16px;
            grid-template-columns: 1fr 1fr 1fr;
            margin-bottom: 32px;
        }

        @media (--viewport-desktop-wide) {
            grid-template-columns: 1fr 1fr;
            margin-bottom: 40px;
        }
    }

    .spotlight {
        margin-bottom: 16px;

        @media (--viewport-tablet) {
            grid-column-start: 1;
            grid-column-end: 3;
            margin-bottom: 0;
        }

        @media (--viewport-desktop-wide) {
            grid-column-end: 2;
        }
    }

    .spotlight-item {
        display: block;
        text-decoration: none;
        position: relative;
        height: 190px;
        width: 100%;

        @media (--viewport-tablet) {
            height: 269px;
        }

        @media (--viewport-desktop) {
            height: 369px;
        }

        @media (--viewport-desktop-wide) {
            height: 330px;
        }

        @media (--viewport-desktop-large) {
            height: 374px;
        }
    }

    .spotlight-image {
        position: relative;
        height: 100%;
        width: 100%;

        &::after {
            content: '';
            position: absolute;
            width: 100%;
            bottom: 0;
            height: 72.5%;
            background: linear-gradient(0deg, #04142c 25.25%, rgba(5, 24, 50, 0) 80.81%);
        }

        .a-picture__img {
            width: 100%;
            max-width: none;
        }
    }

    .spotlight-title {
        @mixin paragraph;

        position: absolute;
        inset-inline-start: 16px;
        inset-inline-end: 16px;
        bottom: 16px;
        color: var(--av-fixed-white);

        @media (--viewport-tablet) {
            @mixin title;

            inset-inline-end: 24px;
            inset-inline-start: 24px;
            bottom: 24px;
        }
    }

    .cards {
        @media (--viewport-desktop-wide) {
            display: grid;
            column-gap: 16px;
            grid-template-columns: 1fr 1fr;
        }
    }

    .card {
        &:nth-child(2) {
            display: none;
        }

        @media (--viewport-desktop-wide) {
            &:nth-child(2) {
                display: block;
            }
        }
    }

    .news-title {
        @mixin title-accent;
        margin-bottom: 8px;
    }

    .news-items {
        border-top: 2px solid var(--av-brand-secondary-light);
        padding-top: 38px;
        margin-bottom: -24px;

        @media (--viewport-tablet) {
            padding-top: 24px;
            display: grid;
            column-gap: 16px;
            grid-template-columns: 1fr 1fr 1fr;
        }

        @media (--viewport-desktop-wide) {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    }

    .news-item {
        margin-bottom: 24px;

        &:nth-child(4) {
            display: none;
        }

        @media (--viewport-desktop-wide) {
            &:nth-child(4) {
                display: block;
            }
        }
    }
}
</style>

<style lang="postcss">
.blog-insights {
    .spotlight-image {
        .a-picture__img {
            width: 100%;
            height: 100%;
            max-width: none;
        }
    }
}
</style>
