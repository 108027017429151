<template>
    <div class="a-resource-highlight-card" :class="className">
        <div class="image">
            <div class="image-inner">
                <a-picture :link="image" :alt="imageAlt" />
            </div>
        </div>
        <div class="content">
            <div v-if="category" class="category">
                {{ category }}
            </div>
            <div v-if="tags" class="tags">
                <div v-for="(tag, i) in tags" :key="`card_${i}`" class="tags-item">
                    <a-label v-bind="tag">
                        {{ tag.text }}
                    </a-label>
                </div>
            </div>
            <component :is="titleTag" class="title">
                {{ title }}
            </component>
            <p class="text">
                {{ text }}
            </p>
            <div class="footer">
                <div v-if="signature" class="signature">
                    <div v-if="signature.title" class="signature-title">
                        {{ signature.title }}
                    </div>
                    <p v-if="signature.text" class="signature-text" v-html="signature.text" />
                    <a-link
                        v-if="signature.phone"
                        class="signature-phone"
                        v-bind="signature.phone"
                    />
                </div>
                <a-link
                    v-if="link && link.to"
                    class="cta"
                    v-bind="link"
                    size="paragraph"
                    accent
                />
            </div>
        </div>
    </div>
</template>

<script>
import ALabel from '../label/label.vue';
import ALink from '../link/link.vue';
import APicture from '../picture/picture.vue';

export default {
    name: 'AResourceHighlightCard',
    components: {
        ALink,
        ALabel,
        APicture,
    },
    props: {
        category: {
            type: String,
            default: undefined,
        },
        tags: {
            type: Array,
            default: undefined,
        },
        title: {
            type: String,
            default: undefined,
        },
        titleTag: {
            type: String,
            default: 'div',
        },
        text: {
            type: String,
            default: undefined,
        },
        signature: {
            type: Object,
            default: undefined,
        },
        link: {
            type: Object,
            default: undefined,
        },
        image: {
            type: String,
            default: undefined,
        },
        imageAlt: {
            type: String,
            default: undefined,
        },
        imageFitsContainer: {
            type: Boolean,
            default: true,
        },
        /** Hides text and signature on mobile */
        compactMobile: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        className() {
            return {
                withLogo: this.logo,
                withImage: this.image,
                'fullsize-image': this.imageFitsContainer,
                'compact-mobile': this.compactMobile,
            };
        },
    },
};
</script>

<style lang="postcss" scoped>
.a-resource-highlight-card {
    background: var(--av-fixed-white);
    border: 1px solid var(--av-brand-secondary-light);
    box-shadow: var(--av-shadow-small);
    border-radius: 4px;
    display: flex;
    flex-flow: row wrap;

    &.withImage {
        .image-wrapper {
            height: 176px;

            @media (--viewport-tablet) {
                height: 100%;
            }
        }

        .image-wrapper-img {
            height: 100%;
        }

        .image-wrapper-img:deep(img) {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

    &.compactMobile {
        .text,
        .signature {
            display: none;

            @media (--viewport-desktop) {
                display: block;
            }
        }
    }

    &.fullsize-image {
        .image-inner {
            height: 176px;
            padding: 0;
            display: block;

            @media (--viewport-tablet) {
                height: 100%;
            }
        }

        .a-picture {
            height: 100%;
            margin: 0;
            padding: 0;
        }

        .a-picture:deep(img) {
            max-height: none;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
}

.image {
    @mixin colls 12;
    overflow: hidden;

    @media (--viewport-tablet) {
        @mixin colls 5;
    }

    @media (--viewport-desktop) {
        @mixin colls 4;
    }
}

.image-inner {
    align-items: center;
    display: flex;
    height: 100%;
    padding: 24px 24px 0 24px;

    @media (--viewport-tablet) {
        padding: 24px 0px 24px 24px;
    }
}

.a-picture {
    width: 100%;

    @media (--viewport-tablet) {
        padding: 0 24px;
    }

    @media (--viewport-desktop-wide) {
        padding: 0 40px;
    }
}

.a-picture:deep(img) {
    margin: 0 auto;
    max-height: 80px;
}

.content {
    @mixin colls 12;
    display: flex;
    flex-flow: column;
    padding: 24px 24px 32px;

    @media (--viewport-tablet) {
        @mixin colls 7;
        padding: 24px;
    }

    @media (--viewport-desktop) {
        @mixin colls 8;
    }
}

.category {
    @mixin body;
    color: var(--av-fixed-light);
    margin-bottom: 8px;
}

.tags {
    margin-bottom: 8px;
    display: flex;
    gap: 16px;
}

.title {
    @mixin title-accent;
    color: var(--av-nav-primary);
}

.text {
    @mixin paragraph;
    margin-top: 16px;
    color: var(--av-fixed-secondary);
}

.footer {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-end;
    margin-top: auto;

    @media (--viewport-desktop) {
        flex-flow: row nowrap;
    }
}

.signature {
    @mixin body;
    margin-top: 24px;
    flex-basis: 100%;
    color: var(--av-fixed-secondary);

    @media (--viewport-desktop) {
        flex-basis: auto;
        flex-grow: 1;
    }
}

.signature-title {
    font-weight: 600;
}

.signature-phone {
    color: var(--av-brand-secondary);
}

.cta {
    flex-basis: 100%;
    margin-top: 24px;

    @media (--viewport-desktop) {
        margin-inline-start: auto;
        flex-basis: auto;
    }

    &:deep(.a-link__glyph_position_right) {
        margin-inline-start: 8px;
    }
}

</style>
