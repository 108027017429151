export const PER_WORKLOAD_ID = 1;
export const PER_GB_ID = 2;

export const maps = {
    globalsCtaLink: 'Cta Link Preparation',
    globalsGreaterThan900: 'Greater than 900 flag OU1014',
    globalsLessThan250: 'Less than 250 flag OU1013',
    globalsCurrency: 'Select Currency - vi2',
    globalsHigherCommit: 'HigherCommit - IN526',
    globalsPerWorkloadRecommended: 'Monthly spend comparison per GB per workload OU1104',
    globalsStorageRequirement: 'Sum of all data amounts less than 200GB OU1107',
    globalsWorkloadsRequirement: 'Sum of any Acronis Cyber Protect Cloud workloads less than 20 OU1105',
    globalsDataCenter: 'Select Data Center - IN513',
    globalsPricingMethod: 'Select Pricing Method dropdown – vi5',
    globalsTotalStorage: 'Sum of all data amounts OU1106',
    globalsTotalWorkloads: 'AM - Total Workloads - Full Potential - OU477',
    cloudPhysicalServers: 'Number of Servers - Full Potential - vi7',
    cloudVirtualMachines: 'Number of VMs - Full Potential - vi8',
    cloudVmDirector: 'Number of vCloud Director Virtual Machines on Acronis - vi15',
    cloudWorkstations: 'Number of Workstations - Full Potential - vi5',
    cloudHostingServers: 'Number of hosting servers IN18',
    cloudMsSeats: 'MS 365 seats - Full Potential - vi10',
    cloudGoogleWorspaceSeats: 'Number of google workspace seats IN19',
    cloudMobileDevices: 'BU Number of Mobile devices IN8',
    cloudWebsites: 'BU Number of Websites IN9',
    cloudStorageOption: 'Choose your storage Options – vi1',
    cloudStorageOptionDataCenter: 'BU - Future Per GB model - Percent Acronis Hosted Storage IN324',
    cloudStorageOptionCloud: 'BU - Future Per GB model - Percent Azure Google Hosted Storage (managed by Acronis) IN342',
    cloudStorageOptionSpHosted: 'BU - Future Per GB model - Percent SP Hosted Storage (own DC) IN325',
    cloudStorageOptionLocal: 'Local or on premise storage IN27',
    cloudMsStorage: 'MS 365 seats on Acronis (Backup Only) - Full Potential - vi11',
    backupPhysicalServers: 'Number of Advanced backup physical servers IN20',
    backupVirtualMachines: 'Number of Advanced backup virtual machines IN21',
    backupWorkstations: 'Number of Advanced backup workstations IN22',
    backupHostingServers: 'Number of Advanced backup hosting servers IN23',
    securityWorkloads: 'Number of Advanced security workloads IN25',
    emailSecurityMailboxes: 'Number of Advanced email security mailboxes IN26',
    managementWorkloads: 'Number of Advanced management workloads IN24',
    orderLine: {
        cloudTotal: 'RF - MONTHLY - Backup - Spend with Acronis - OU500',
        cloudPhysicalServers: 'BU - Servers - Spend with Acronis - MORE SERVICES - OU751',
        cloudVirtualMachines: 'BU - VMs - Spend with Acronis - MORE SERVICES - OU766',
        cloudWorkstations: 'BU - Workstations - Spend with Acronis - MORE SERVICES - OU781',
        cloudVmDirector: 'Monthly Cost - Curr - vCloud Director Virtual Machines - vo251',
        cloudHostingServers: 'Monthly Cost - Curr - Hosting Servers - vo253',
        cloudMsSeats: 'BU- MS 365 Seats (per Workload pricing) - Spend with Acronis - MORE SERVICES - OU792',
        cloudGoogleWorspaceSeats: 'Monthly Cost - Curr - Google Workspace Seats - vo256',
        cloudMobileDevices: 'Monthly Cost - Curr - Mobile Devices - vo257',
        cloudWebsites: 'Monthly Cost - Curr - Websites - vo258',
        cloudStorageOptionDataCenter: 'Acronis Cloud data center locations - OU818',
        cloudStorageOptionCloud: 'SP storage on per Workload model MS  GCP - OU819',
        cloudStorageOptionSpHosted: 'SP storage on per GB model Own DC - OU820',
        cloudStorageOptionLocal: 'Monthly Cost - Curr - Customer Local Storage - vo264',
        cloudMsStorage: 'Monthly Cost - Curr - Microsoft 365 storage on Acronis Cloud data center locations - OU437',
        backupTotal: 'Advanced backup total monthly spend OU436',
        backupPhysicalServers: 'Monthly Cost - Curr - Advanced Backup Physical Servers - vo265',
        backupVirtualMachines: 'Monthly Cost - Curr - Advanced Backup Virtual Machines - vo266',
        backupWorkstations: 'Monthly Cost - Curr - Advanced Backup Workstations - vo267',
        backupHostingServers: 'Monthly Cost - Curr - Advanced Backup Hosting Servers OU413',
        securityTotal: 'Advanced security total monthly spend OU437',
        securityWorkloads: 'Monthly Cost - Curr - Advanced Security Workloads - vo269',
        emailSecurityTotal: 'Advanced email security total monthly spend OU438',
        emailSecurityMailboxes: 'Monthly Cost - Curr - Advanced Email Security Mailboxes - vo270',
        managementTotal: 'Monthly Cost - Curr - Advanced Management Workloads - vo268',
        managementWorkloads: 'Monthly Cost - Curr - Advanced Management Workloads - vo268',
        totalMonthly: 'Total monthly spend OU1012',
    },
};

/*
*   Used to collect and pass necessary keys to workato as readable labels
*   Please don't add new values to this key unless instructed so because they will
*   also be added to the payload sent to workato.
*/
export const fieldLabels = {
    cloudPhysicalServers: 'Physical servers',
    cloudVirtualMachines: 'Virtual machines',
    cloudVmDirector: 'VMware Cloud Director',
    cloudWorkstations: 'Workstations',
    cloudHostingServers: 'Hosting Servers',
    cloudMsSeats: 'Microsoft 365 seats',
    cloudGoogleWorspaceSeats: 'Google Workplace seats',
    cloudMobileDevices: 'Mobile devices',
    cloudWebsites: 'Websites',
    cloudStorageOptionDataCenter: 'Acronis Cloud data center locations (GB)',
    cloudStorageOptionCloud: 'Acronis Instances in Azure or Google data centers (GB)',
    cloudStorageOptionSpHosted: '3rd-party cloud data center managed by you (GB)',
    cloudStorageOptionLocal: 'Local or on-premise storage (GB)',
    cloudMsStorage: 'Microsoft 365 storage on Acronis Cloud data center locations (GB)',
    backupPhysicalServers: 'Advanced Backup physical servers',
    backupVirtualMachines: 'Advanced Backup virtual machines',
    backupWorkstations: 'Advanced Backup workstations',
    backupHostingServers: 'Advanced Backup hosting servers',
    securityWorkloads: 'Advanced Security workloads',
    emailSecurityMailboxes: 'Advanced Email Security Mailboxes',
    managementWorkloads: 'Advanced Management Workloads',
    disasterRecovery: 'Advanced Disaster Recovery',
    dlp: 'Advanced Data Loss Prevention (DLP)',
    edr: 'Advanced Security + Endpoint Detection and Response (EDR)',
};
