<template>
    <el-num-picker
        v-if="isVisible"
        v-model="quantity"
        integer-only
        step-strictly
        :debounce="0"
        :step="multiplier"
        :min="valueMin * multiplier"
        :max="valueMax * multiplier"
        @change="onChange($event)"
    />
</template>

<script>
export default {
    name: 'SNumPicker',

    components: {
        ElNumPicker: () => import('@uikit/ui-kit/packages/num-picker'),
    },

    props: {
        value: {
            type: Number,
            default: 0,
        },

        valueMin: {
            type: Number,
            default: 0,
        },

        valueMax: {
            type: Number,
            default: 1,
        },

        multiplier: {
            type: Number,
            default: 1,
        },
    },

    emits: ['changeQuantity'],

    data: () => ({
        quantity: 0,
        isVisible: true,
    }),

    watch: {
        value(value) {
            if (this.quantity / this.multiplier !== value) {
                this.quantity = this.multiplier * value;
            }
        },

        quantity(value) {
            this.onChange(value);
        },
    },

    created() {
        this.quantity = this.value * this.multiplier;
    },

    mounted() {
        window.addEventListener('pageshow', this.pageShow);
    },

    beforeDestroy() {
        window.removeEventListener('pageshow', this.pageShow);
    },

    methods: {
        pageShow(event) {
            // The page is loading from a cache
            if (event?.persisted) {
                this.isVisible = false;
                this.$nextTick(() => {
                    this.isVisible = true;
                });
            }
        },

        onChange(value) {
            this.$emit('changeQuantity', value / this.multiplier);
        },
    },
};
</script>

<style lang="postcss" scoped>
.el-num-picker {
    background: var(--av-fixed-white);

    &:deep(.el-num-picker__container) {
        display: flex;
        height: 40px;
        width: 100px;
        border-radius: 3px;
        align-items: center;
        border: 1px solid var(--av-brand-light);

        @media (--viewport-desktop-wide) {
             width: 112px;
        }

        .svg {
            margin: 0 7px;
            fill: var(--av-brand-primary);
        }

        .is-disabled {
            .svg {
                fill: var(--av-brand-light);
            }
        }

        .el-num-picker__editor {
            height: 100%;
        }

        .el-input__container {
            height: 100%;
            border: none;
        }

        .el-input__wrapper {
            padding: 0;
        }

        .el-input__editor {
            height: 100%;
            text-align: center;
            border-inline-end: 1px solid var(--av-brand-light);
            border-inline-start: 1px solid var(--av-brand-light);
        }

        .el-num-picker__decrease,
        .el-num-picker__increase {
            height: 100%;
            display: flex;
            align-items: center;

            &:hover {
                cursor: pointer;
                background: var(--av-brand-accent);
            }

            &.is-disabled {
                cursor: default;
            }
        }
    }
}
</style>
