<template>
    <div class="s-card-action" :class="className">
        <div v-if="isAvailable()" class="change">
            <div class="button" @click="open()">
                <a-glyph class="button-glyph" name="pencil-o" />
                {{ setTranslate('Change') }}
            </div>
            <div v-if="edition" class="popup">
                <p class="popup-title">
                    {{ setTranslate('Editions') }}
                </p>
                <p v-for="(item, editionIndex) in listEdition" :key="`edition-${editionIndex}`" class="popup-item">
                    <span @click="changeEdition(item)" v-html="item.name" />
                </p>
            </div>
            <div v-else class="popup">
                <p class="popup-title">
                    {{ setTranslate('Workloads') }}
                </p>
                <p v-for="(item, productIndex) in listProduct" :key="`workload-${productIndex}`" class="popup-item">
                    <span @click="changeWorkload(item)" v-html="item.name" />
                </p>
            </div>
        </div>
        <div v-if="edition" class="remove">
            <div class="button" @click="editionRemove(product, edition)">
                <a-glyph name="times" />
                {{ setTranslate('Remove') }}
            </div>
        </div>
    </div>
</template>

<script>
import { isEmpty, merge } from 'lodash';
import AGlyph from '@core/components/glyph/glyph.vue';
import mixin from '../mixin.js';

export default {
    name: 'SCardAction',

    components: {
        AGlyph,
    },

    mixins: [mixin],

    props: {
        /**
         * Settings
         */
        settings: {
            type: Object,
            default: null,
        },

        /**
         * Product
         */
        product: {
            type: Object,
            default: null,
        },

        /**
         * Edition
         */
        edition: {
            type: Object,
            default: null,
        },
    },

    data: () => ({
        isActive: false,
    }),

    computed: {
        className() {
            return {
                active: this.isActive,
            };
        },

        listEdition() {
            return this.product.editions.filter((e) => !e.added && e.subscriptions);
        },

        listProduct() {
            const result = this.listWorkloads.map(({ editions, ...product }) => ({
                ...product,
                ...{ editions: editions.filter((e) => e.added) },
            }));
            return result.filter((p) => !p.selected && isEmpty(p.editions));
        },
    },

    methods: {
        isAvailable() {
            if (this.edition) {
                return this.listEdition.length;
            }
            return this.listProduct.length;
        },

        open() {
            this.isActive = true;
            window.addEventListener('click', this.close, true);
        },

        close() {
            this.isActive = false;
            window.removeEventListener('click', this.close, true);
        },

        changeEdition(edition) {
            this.editionAdd(this.product, edition);
            this.editionRemove(this.product, this.edition);
        },

        changeWorkload(workload) {
            const current = this.currentProduct(this.product.id);
            const selected = this.currentProduct(workload.id);

            this.productOpened('workloads').opened = false;

            merge(
                current.editions.filter((e) => e.subscriptions),
                current.editions
                    .filter((e) => e.subscriptions)
                    .map(({ ...sections }) => ({
                        ...sections,
                        ...this.editionClear,
                    })),
            );

            merge(current, this.productClear);
            merge(selected, { opened: true, selected: true, time: this.time() });

            this.setProductPrice(this.product.id, 0);
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-card-action {
    @mixin caption;
    z-index: 1;
    display: flex;
    color: var(--av-brand-secondary);

    .a-glyph {
        margin-inline-end: 8px;
    }

    .change {
        position: relative;
        white-space: nowrap;
    }

    .remove {
        z-index: 2;
        position: relative;
        white-space: nowrap;
    }

    .button {
        padding: 8px;
        cursor: pointer;
        border-radius: 4px;
        position: relative;

        &:hover {
            background: var(--av-brand-accent);
        }

        .a-glyph {
            margin-inline-end: 8px;
        }
    }

    .popup {
        @mixin body;
        top: 100%;
        z-index: 2;
        inset-inline-end: -8px;
        display: none;
        padding: 24px 0;
        margin: 8px 0 0;
        min-width: 214px;
        position: absolute;
        border-radius: 4px;
        white-space: nowrap;
        background: var(--av-fixed-white);
        border: 1px solid var(--av-brand-primary);
        font-weight: 400;

        &:before {
            content: '';
            top: -4px;
            inset-inline-end: 30px;
            width: 6px;
            height: 6px;
            background: var(--av-fixed-white);
            margin-inline-end: 16px;
            position: absolute;
            transform: rotate(45deg);
            border-top: 1px solid var(--av-brand-primary);
            border-inline-start: 1px solid var(--av-brand-primary);
        }

        &-title {
            margin: 0 16px;
            color: var(--av-fixed-light);
        }

        &-item {
            color: #243143;
            padding: 8px 16px 0;
            padding-inline-end: 20px;

            span {
                cursor: pointer;
            }
        }
    }

    &.active {
        z-index: 2;

        &:before {
            top: 0;
            inset-inline-start: 0;
            z-index: 1;
            content: '';
            width: 100%;
            height: 100%;
            cursor: default;
            position: absolute;
        }

        .popup {
            display: block;
        }

        .change {
            .s-card-action {
                .button {
                    background: var(--av-brand-accent);
                }
            }
        }
    }
}
</style>
