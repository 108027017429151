<template>
    <div class="event-form" :class="className">
        <div ref="recaptcha" />
        <el-form
            ref="form"
            :rules="rules"
            class="form"
            :model="form"
        >
            <div class="title-wrap">
                <div class="title">
                    {{ title }}
                </div>
                <div v-if="dateTitle" class="nextEvent">
                    {{ dateTitle.title }}
                    <a-tooltip v-if="dateTitle.timezoneTitle" :text="dateTitle.timezoneTitle" />
                </div>
            </div>

            <el-form-item v-if="occurrences.length > 0" class="form-item" prop="event_time">
                <el-select
                    v-model="form.event_time"
                    class="select"
                    name="event_time"
                    :placeholder="labels.selectClassDates"
                    :hide-on-resize="isDesktop"
                    popper-class="dropdown event-dropdown z-index-2005"
                    tabindex="0"
                >
                    <el-option
                        v-for="(occurrence, i) in occurrences"
                        :key="i"
                        :label="occurrence.text"
                        :value="occurrence.value"
                    />
                </el-select>
            </el-form-item>

            <el-row :gutter="24">
                <el-col :span="collSpan" :xs="24" :sm="collSpan">
                    <el-form-item class="form-item" :prop="firstNameField">
                        <el-input
                            v-model="form[firstNameField]"
                            class="text-input"
                            :name="firstNameField"
                            :label="labels[firstNameField]"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="collSpan" :xs="24" :sm="collSpan">
                    <el-form-item class="form-item" :prop="secondNameField">
                        <el-input
                            v-model="form[secondNameField]"
                            class="text-input"
                            :name="secondNameField"
                            :label="labels[secondNameField]"
                        />
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row :gutter="24">
                <el-col :span="24" :xs="24" :sm="24">
                    <el-form-item class="form-item" prop="email" :error="emailError">
                        <el-input
                            v-model="form.email"
                            class="text-input"
                            name="email"
                            :label="emailLabel"
                            :disabled="isLoading"
                            @blur="onEmailBlur"
                            @mouseleave.native="checkEmailOptIn('email')"
                            @input="onEmailInput"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="24" :xs="24" :sm="24">
                    <a-phone-input
                        v-if="isReady"
                        prop="phone"
                        :settings="{labels: labels}"
                        :default-country="form.country"
                        popper-class="z-index-2005"
                        @change="form.phone = $event.number"
                        @update="$emit('update')"
                    />
                </el-col>
            </el-row>

            <el-form-item class="form-item" prop="company">
                <el-input
                    v-model="form.company"
                    class="text-input"
                    name="company"
                    :label="labels.company"
                />
            </el-form-item>

            <el-form-item v-if="audiencesCount > 1" class="form-item" prop="company_type">
                <el-select
                    v-model="form.company_type"
                    class="select"
                    name="company_type"
                    :placeholder="labels.companyType"
                    :hide-on-resize="isDesktop"
                    popper-class="dropdown event-dropdown z-index-2005"
                >
                    <el-option
                        v-for="(item, i) in companyTypeOptions"
                        :key="`company-type-${i}`"
                        :label="item.label"
                        :value="item.value"
                    />
                </el-select>
            </el-form-item>

            <el-form-item v-if="showJobTitleField" class="form-item" prop="job_title">
                <el-input
                    v-model="form.job_title"
                    class="text-input"
                    name="job_title"
                    :label="labels.jobTitle"
                />
            </el-form-item>

            <el-form-item v-if="numberOfEmployeesIsShown" class="form-item" prop="of_Employees__c">
                <el-select
                    v-model="form.of_Employees__c"
                    class="select"
                    name="of_Employees__c"
                    :placeholder="labels.of_Employees__c"
                    :hide-on-resize="isDesktop"
                    popper-class="dropdown event-dropdown z-index-2005"
                >
                    <el-option
                        v-for="(item, i) in EMPLOYEES"
                        :key="i"
                        :label="item.name"
                        :value="item.code"
                    />
                </el-select>
            </el-form-item>

            <template v-if="trainings.length">
                <el-form-item class="form-item" prop="attend_training">
                    <el-select
                        v-model="form.attend_training"
                        class="select"
                        name="attend_training"
                        :placeholder="labels.attend_training"
                        :hide-on-resize="isDesktop"
                        popper-class="dropdown z-index-2005"
                    >
                        <el-option
                            :label="labels.yes"
                            value="yes"
                        />
                        <el-option
                            :label="labels.no"
                            value="no"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item v-show="isTrainingAttendPlanned" class="form-item" prop="training">
                    <el-select
                        v-model="form.training"
                        class="select"
                        name="training"
                        :placeholder="labels.training"
                        :hide-on-resize="isDesktop"
                        popper-class="dropdown z-index-2005"
                        multiple
                    >
                        <el-option
                            v-for="(training, index) in trainings"
                            :key="index"
                            :value="training.training_session_number"
                            :label="training.textValue"
                        />
                    </el-select>
                </el-form-item>
            </template>

            <el-form-item class="form-item" prop="country">
                <el-select
                    v-model="form.country"
                    v-bind="labelSelect"
                    class="select"
                    name="country"
                    filterable
                    :hide-on-resize="isDesktop"
                    :placeholder="labels.country"
                    popper-class="dropdown z-index-2005"
                    @change="checkEmailOptIn('country')"
                >
                    <el-option
                        v-for="item in countries"
                        :key="item.iso_code"
                        :label="item.name"
                        :value="item.iso_code"
                    />
                </el-select>
            </el-form-item>

            <el-form-item v-if="isHybrid" class="form-item" prop="attend_event">
                <el-select
                    v-model="form.attend_event"
                    v-bind="labelSelect"
                    class="select"
                    name="attend_event"
                    :placeholder="labels.attendEventPlaceholder"
                    :hide-on-resize="isDesktop"
                    popper-class="dropdown z-index-2005"
                >
                    <el-option
                        v-for="item in attendOptions"
                        :key="item.value"
                        :label="item.text"
                        :value="item.value"
                    />
                </el-select>
            </el-form-item>

            <el-form-item v-if="showStatesField" class="form-item" prop="state">
                <el-select
                    v-model="form.state"
                    v-bind="labelSelect"
                    class="select"
                    name="state"
                    :placeholder="labels.state"
                    popper-class="dropdown z-index-2005"
                    filterable
                    :hide-on-resize="isDesktop"
                >
                    <el-option
                        v-for="state in states"
                        :key="state.code"
                        :label="state.name"
                        :value="state.code"
                    />
                </el-select>
            </el-form-item>

            <el-form-item v-if="showPostalCodeField" class="form-item" prop="postal_code">
                <el-input
                    v-model="form.postal_code"
                    class="text-input"
                    name="postal_code"
                    :label="labels.postal_code"
                />
            </el-form-item>

            <template v-if="showAppointmentFields">
                <el-form-item class="form-item" prop="meeting_requested">
                    <el-select
                        v-model="form.meeting_requested"
                        class="select"
                        name="meeting_requested"
                        :placeholder="labels.meeting_requested"
                        :hide-on-resize="isDesktop"
                        popper-class="dropdown z-index-2005"
                    >
                        <el-option
                            :label="labels.yes"
                            value="yes"
                        />
                        <el-option
                            :label="labels.no"
                            value="no"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item v-show="isMeetingRequested" class="form-item" prop="meeting_date">
                    <el-select
                        v-model="form.meeting_date"
                        class="select"
                        name="meeting_date"
                        :placeholder="labels.meeting_date"
                        :hide-on-resize="isDesktop"
                        popper-class="dropdown z-index-2005"
                    >
                        <el-option
                            v-for="date in meetingDates"
                            :key="date"
                            :value="date"
                            :label="date"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item v-show="isMeetingRequested" class="form-item" prop="meeting_topic">
                    <el-input
                        v-model="form.meeting_topic"
                        class="text-input"
                        name="meeting_topic"
                        :label="labels.meeting_topic"
                        :hide-on-resize="isDesktop"
                        type="textarea"
                        :autosize="{ minRows: TEXT_MIN_ROWS, maxRows: TEXT_MAX_ROWS }"
                        :maxlength="TEXT_MAX_LENGTH"
                    />
                </el-form-item>
            </template>

            <template v-if="hasJPSummitAdditionalFields">
                <el-form-item class="form-item" prop="jp_hyb_nw_dinner">
                    <el-select
                        v-model="form.jp_hyb_nw_dinner"
                        v-bind="labelSelect"
                        class="select"
                        name="jp_hyb_nw_dinner"
                        :placeholder="labels.jp_hyb_nw_dinner"
                        :hide-on-resize="isDesktop"
                        popper-class="dropdown z-index-2005"
                    >
                        <el-option
                            :label="labels.yes"
                            value="yes"
                        />
                        <el-option
                            :label="labels.no"
                            value="no"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item class="form-item" prop="jp_hyb_learned_from">
                    <el-select
                        v-model="form.jp_hyb_learned_from"
                        v-bind="labelSelect"
                        class="select"
                        name="jp_hyb_learned_from"
                        :placeholder="labels.jp_hyb_learned_from"
                        :hide-on-resize="isDesktop"
                        popper-class="dropdown z-index-2005"
                    >
                        <el-option
                            v-for="item in labels.learnedFromOptions"
                            :key="item"
                            :label="item"
                            :value="item"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item class="form-item" prop="jp_hyb_invited_by">
                    <el-input
                        v-model="form.jp_hyb_invited_by"
                        class="text-input"
                        name="jp_hyb_invited_by"
                        :label="labels.jp_hyb_invited_by"
                    />
                </el-form-item>
            </template>

            <div v-if="isCountryGDPR || isVisibleOptIn" class="agreements" :class="{ 'has-grid': isVisibleOptIn }">
                <el-checkbox
                    v-if="isCountryGDPR"
                    v-model="form.terms"
                    class="agreement"
                    name="terms"
                >
                    <a-dangerous-html :content="terms" />
                </el-checkbox>
                <el-checkbox
                    v-if="isVisibleOptIn"
                    v-model="form.receiveNews"
                    class="agreement"
                    name="receiveNews"
                >
                    <a-dangerous-html :content="receiveNews" />
                </el-checkbox>
            </div>

            <div
                v-if="isLoading"
                v-loading="true"
                class="loading"
                el-loading-size="48"
                :el-loading-text="loadingText"
            />

            <a-form-honeypot ref="honeypot" />

            <div v-if="agreementError" class="form-error">
                {{ setErrorMessage('acronisTermsOfService') }}
            </div>
            <button class="event-button submit" @click="submit">
                {{ buttonTitle }}
            </button>
            <div class="bottomText">
                <a-dangerous-html :content="recaptchaText" :refinements="{ injectHrefLocale: false }" />
            </div>
            <div v-if="!isCountryGDPR" class="bottomText">
                <a-dangerous-html :content="termsNonGDPR" />
            </div>
        </el-form>

        <error-form
            v-if="mode === 'error'"
            :title="error.title"
            :code="error.code"
            :back-to-form-title="backToFormTitle"
            class="error"
            @click="returnToForm"
        />

        <div v-else-if="mode === 'success'" class="success">
            <a-picture v-if="showSuccessImage" class="success-image" :link="successImage" />
            <a-glyph
                v-else
                name="success"
                size="m"
                class="success-glyph"
            />
            <div class="success-title">
                {{ successTitle }}
            </div>
            <div class="success-text">
                {{ successText }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Loading from '@uikit/ui-kit/packages/loading/src/directive.js';
import { mapActions, mapState } from 'vuex';
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import AFormHoneypot from '@core/components/form-honeypot/form-honeypot.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import APhoneInput from '@core/components/phone-input/phone-input.vue';
import APicture from '@core/components/picture/picture.vue';
import ATooltip from '@core/components/tooltip/tooltip.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import form from '@core/mixins/form.js';
import recaptcha from '@core/mixins/recaptcha.js';
import ErrorForm from '@core/slices/pages/error-form/error-form.vue';
import { EMPLOYEES } from '@core/slices/pages/trial-form/constants.js';
import freeEmailDomains from '@model/const/emails-services-free';
import { DEFAULT_LANGUAGE } from '@model/const/events';
import states from '@model/const/states';
import commonUtils from '@utils/common';
import formHelper from '@utils/form.js';
import { Logger } from '@utils/logger';

const COUNTRIES_WITH_BUSINESS_EMAIL = ['in', 'bd', 'bt', 'io', 'lk', 'mv', 'np'];
const HIGHLIGHT_DURATION = 1500;
const LANGS_WITH_ALTER_NAME_ORDER = ['Japanese'];
const TEXT_MIN_ROWS = 2;
const TEXT_MAX_ROWS = 6;
const TEXT_MAX_LENGTH = 450;

export default {
    name: 'EventForm',

    components: {
        ElInput: () => import('@uikit/ui-kit/packages/input'),
        ElSelect: () => import('@uikit/ui-kit/packages/select'),
        ElOption: () => import('@uikit/ui-kit/packages/option'),
        ElCheckbox: () => import('@uikit/ui-kit/packages/checkbox'),
        ElForm: () => import('@uikit/ui-kit/packages/form'),
        ElFormItem: () => import('@uikit/ui-kit/packages/form-item'),
        ElRow: () => import('@uikit/ui-kit/packages/row'),
        ElCol: () => import('@uikit/ui-kit/packages/col'),
        APicture,
        ATooltip,
        AGlyph,
        ADangerousHtml,
        ErrorForm,
        AFormHoneypot,
        APhoneInput,
    },

    directives: {
        Loading,
    },

    mixins: [breakpoint, form, recaptcha],

    props: {
        eventId: {
            type: Number,
            required: true,
        },
        eventName: {
            type: String,
            required: true,
        },
        eventType: {
            type: String,
            required: true,
        },
        eventCategory: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        startDate: {
            type: String,
            default: null,
        },
        dateTitle: {
            type: Object as any,
            default: null,
        },
        labels: {
            type: Object as any,
            required: true,
        },
        countries: {
            type: Array,
            required: true,
        },
        terms: {
            type: String,
            required: true,
        },
        termsNonGDPR: {
            type: String,
            required: true,
        },
        receiveNews: {
            type: String,
            required: true,
        },
        buttonTitle: {
            type: String,
            required: true,
        },
        layout: {
            type: String,
            default: 'default',
        },
        loadingText: {
            type: String,
            default: 'Loading',
        },
        successTitle: {
            type: String,
            required: true,
        },
        successText: {
            type: String,
            required: true,
        },
        showSuccessImage: {
            type: Boolean,
            default: true,
        },
        isThirdParty: {
            type: Boolean,
            default: false,
        },
        backToFormTitle: {
            type: String,
            required: true,
        },
        recaptchaText: {
            type: String,
            required: true,
        },
        zoomMeetingId: {
            type: String,
            default: null,
        },
        marketoCampaignId: {
            type: String,
            default: null,
        },
        sfdcId: {
            type: String,
            default: null,
        },
        lmsExamUrl: {
            type: String,
            default: null,
        },
        languageName: {
            type: String,
            default: DEFAULT_LANGUAGE,
        },
        occurrences: {
            type: Array,
            default: () => [],
        },
        meetingDates: {
            type: Array,
            default: () => [],
        },
        trainings: {
            type: Array,
            default: () => [],
        },
        subscribers: {
            type: Array,
            default: () => [],
        },
    },

    emits: ['unlock', 'leadRequest', 'update'],

    data() {
        return {
            EMPLOYEES,
            states,
            successImage: '@bd3dfcd380dc3871562cfefa506bdd9b',
            mode: 'form',
            form: {
                form_id: 'events_hub_main',
                first_name: '',
                last_name: '',
                email: '',
                company: '',
                phone: '',
                country: '',
                terms: false,
                receiveNews: false,
                business_address: '', // WEB-46059
                company_type: '',
                of_Employees__c: '',
                state: '',
                postal_code: '',
                attend_event: '', // WEB-43713
                opt_in: false,
                gdpr_opt_in: false,
                event_time: '', // WEB-44002
                job_title: '', // WEB-45222
                jp_hyb_nw_dinner: '',
                jp_hyb_learned_from: '',
                jp_hyb_invited_by: '',
                meeting_requested: '', // WEB-45292
                meeting_date: '', // WEB-45292
                meeting_topic: '', // WEB-45292
                attend_training: false, // WEB-45603
                training: [], // WEB-45603
            },
            agreementError: false,
            isLoading: true,
            isReady: false,
            hasBusinessEmailCheck: false,
            hasReceiveNews: true,
            error: null,
            emailError: '',
            recaptchaResponse: null,
            attendOptions: [
                { text: this.labels.attendEventOptionOnline, value: 'online' },
                { text: this.labels.attendEventOptionInPerson, value: 'in-person' },
            ],
            isHighlighted: false,
            TEXT_MIN_ROWS,
            TEXT_MAX_ROWS,
            TEXT_MAX_LENGTH,
        };
    },

    computed: {
        ...mapState({
            location: (state) => state.geolocation?.location?.data || {},
        }),

        isHybrid() {
            return this.eventType === 'hybrid';
        },

        companyTypeOptions() {
            return [
                { label: this.labels.companyTypeProvider, value: 'Service Provider' },
                { label: this.labels.companyTypeReseller, value: 'Reseller' },
                { label: this.labels.companyTypeDistributor, value: 'Distribution' },
                { label: this.labels.companyTypeSeekingCorpSolution, value: 'Corporate End-Customer' },
                { label: this.labels.companyTypeSeekingHomeSolution, value: 'Prosumer End-Customer' },
            ];
        },

        className() {
            return {
                'columns-layout': this.layout === 'columns',
                'is-highlighted': this.isHighlighted,
            };
        },

        rules() {
            const rules: any = {
                first_name: {
                    required: true,
                    trigger: 'change',
                    fieldType: 'input',
                    validator: this.validateFieldValue,
                    errorMessage: this.setErrorMessage('notFilledFirstName'),
                },
                last_name: {
                    required: true,
                    trigger: 'change',
                    fieldType: 'input',
                    validator: this.validateFieldValue,
                    errorMessage: this.setErrorMessage('notFilledLastName'),
                },
                email: {
                    required: true,
                    trigger: 'blur',
                    validator: this.validateEmail,
                    errorMessage: this.setErrorMessage('notFilledEmail'),
                },
                phone: {
                    required: true,
                    trigger: 'change',
                    validator: this.validatePhone,
                    errorMessage: this.setErrorMessage('notFilledPhone'),
                },
                country: {
                    required: true,
                    trigger: 'change',
                    message: this.setErrorMessage('notFilledCountry'),
                },
                state: {
                    required: true,
                    trigger: 'change',
                    message: this.setErrorMessage('notFilledState'),
                },
                postal_code: {
                    required: true,
                    trigger: 'change',
                    fieldType: 'input',
                    validator: this.validateFieldValue,
                    errorMessage: this.setErrorMessage('notFilledPostalCode'),
                },
                receiveNews: false,
                terms: false,
                company: {
                    required: true,
                    trigger: 'change',
                    fieldType: 'input',
                    validator: this.validateFieldValue,
                    errorMessage: this.setErrorMessage('notFilledCompany'),
                },
            };

            rules.of_Employees__c = {
                required: true,
                trigger: 'change',
                message: this.setErrorMessage('notFilledField'),
            };

            // WEB-45222, WEB-45616
            if (this.showJobTitleField) {
                rules.job_title = {
                    required: true,
                    trigger: 'change',
                    fieldType: 'input',
                    validator: this.validateFieldValue,
                    errorMessage: this.setErrorMessage('notFilledPosition'),
                };
            }

            // WEB-42459
            if (this.audiencesCount > 1) {
                rules.company_type = {
                    required: true,
                    trigger: 'change',
                    message: this.setErrorMessage('notFilledField'),
                };
            }

            // WEB-43713
            if (this.isHybrid) {
                rules.attend_event = {
                    required: true,
                    trigger: 'change',
                    message: this.setErrorMessage('notFilledField'),
                };
            }

            // WEB-44002
            if (this.occurrences.length) {
                rules.event_time = {
                    required: true,
                    trigger: 'change',
                    message: this.setErrorMessage('notFilledField'),
                };
            }

            // WEB-45292
            if (this.showAppointmentFields) {
                rules.meeting_requested = {
                    required: true,
                    trigger: 'change',
                    message: this.setErrorMessage('notFilledField'),
                };
                rules.meeting_date = {
                    required: true,
                    trigger: 'change',
                    validator: (rule, value, callback) => {
                        if (!this.isMeetingRequested) return callback();
                        return this.validateFieldValue(rule, value, callback);
                    },
                    message: this.setErrorMessage('notFilledField'),
                };
            }

            // WEB-45627
            if (this.hasJPSummitAdditionalFields) {
                rules.jp_hyb_nw_dinner = {
                    required: true,
                    trigger: 'change',
                    message: this.setErrorMessage('notFilledField'),
                };
                rules.jp_hyb_learned_from = {
                    required: true,
                    trigger: 'change',
                    message: this.setErrorMessage('notFilledField'),
                };
                rules.jp_hyb_invited_by = {
                    required: true,
                    trigger: 'change',
                    message: this.setErrorMessage('notFilledField'),
                };
            }

            // WEB-45603
            if (this.trainings.length) {
                rules.attend_training = {
                    required: true,
                    trigger: 'change',
                    message: this.setErrorMessage('notFilledField'),
                };
                rules.training = {
                    required: true,
                    trigger: 'change',
                    validator: (rule, value, callback) => {
                        if (!this.isTrainingAttendPlanned) return callback();
                        return this.validateFieldValue(rule, value, callback);
                    },
                    message: this.setErrorMessage('notFilledField'),
                };
            }

            return rules;
        },

        collSpan() {
            return this.isDesktop && this.layout === 'columns' ? 12 : 24;
        },

        emailLabel() {
            return this.hasBusinessEmailCheck ? this.labels.business_email : this.labels.email;
        },

        audiencesCount() {
            const event = this.$store.state.events.event?.items?.[0];
            return event?.audiences?.length || 0;
        },

        numberOfEmployeesIsShown() {
            const audiences = this.$store.state.events.event?.items?.[0]?.audiences;
            const targetAudienceIsBusiness = audiences?.length === 1 && audiences?.[0]?.title === 'business';
            const companyTypeIsCorpEndCustomer = this.form.company_type === 'Corporate End-Customer';
            return targetAudienceIsBusiness || companyTypeIsCorpEndCustomer;
        },

        showJobTitleField() {
            return (this.eventType === 'in-person' && !this.isThirdParty) || this.eventType === 'hybrid';
        },

        showAppointmentFields() {
            return this.eventType === 'in-person' && this.isThirdParty;
        },

        isMeetingRequested() {
            return this.form.meeting_requested === 'yes';
        },

        isTrainingAttendPlanned() {
            return this.form.attend_training === 'yes';
        },

        hasAlterNameOrder() {
            return LANGS_WITH_ALTER_NAME_ORDER.includes(this.languageName);
        },

        firstNameField() {
            return this.hasAlterNameOrder ? 'last_name' : 'first_name';
        },

        secondNameField() {
            return this.hasAlterNameOrder ? 'first_name' : 'last_name';
        },

        // WEB-45627
        hasJPSummitAdditionalFields() {
            return this.languageName === 'Japanese' && this.eventType === 'hybrid';
        },
    },

    async mounted() {
        await this.handleGeoData();
        await this.$store.dispatch('user/get');

        this.setAccountData();

        await this.$nextTick();
        this.recaptchaInject();
        this.setFocus();
        this.isLoading = false;
        this.isReady = true;
    },

    updated() {
        this.$emit('update');
    },

    methods: {
        ...mapActions({
            loadGeoLocation: 'geolocation/getGeoLocation',
            sendLead: 'trial/sendLead',
        }),

        async handleGeoData() {
            await this.loadGeoLocation();
            this.form.country = this.location.country?.code || '';
            if (this.form.country === 'US') {
                this.form.state = this.location.region?.code || '';
            }
            this.hasBusinessEmailCheck = COUNTRIES_WITH_BUSINESS_EMAIL.includes(this.form.country?.toLowerCase());
        },

        async submit() {
            if (this.isLoading) return;

            this.isLoading = true;

            await window.grecaptcha?.enterprise.execute(this.recaptchaWidgetId);

            const formValidated = await this.validateForm();

            if (!formValidated) {
                this.mode = 'form';
                this.isLoading = false;
                return;
            }

            this.makeSubscribeRequest();
        },

        returnToForm() {
            this.mode = 'form';
        },

        validateEmail(rule, value, callback) {
            let notFilledField = this.setErrorMessage('notFilledEmail');
            let invalidEmail = this.setErrorMessage('invalidEmail');

            if (this.hasBusinessEmailCheck) {
                notFilledField = this.setErrorMessage('notFilledEmailBusiness');
                invalidEmail = this.setErrorMessage('invalidEmailBusiness');
            }

            if (!value) {
                return callback(new Error(notFilledField));
            }
            if (!formHelper.checkIfFieldLengthValid('input', value)) {
                return callback(new Error(this.setErrorMessage('invalidInputLength')));
            }
            if (!formHelper.checkIfEmailHasAddressSign(value)) {
                return callback(new Error(this.setErrorMessage('missingEmailSymbol')));
            }
            if (this.hasBusinessEmailCheck && this.isFreeEmail(value)) {
                return callback(new Error(invalidEmail));
            }
            if (!formHelper.checkIfEmailValid(value)) {
                return callback(new Error(invalidEmail));
            }
            return callback();
        },

        validateAgreement() {
            this.agreementError = !this.form.terms && this.isCountryGDPR;
            return !this.agreementError;
        },

        onEmailInput() {
            this.emailError = '';

            if (this.form.email?.length && this.isFreeEmail(this.form.email)) {
                this.$nextTick(() => {
                    this.emailError = this.labels.businessEmailNote;
                });
            }
        },

        onEmailBlur() {
            this.onEmailInput();
            this.checkEmailOptIn('email');
        },

        async validateForm() {
            let error = false;

            try {
                this.prepareFormToValidate();
                await this.$refs.form.validate();
            } catch (err) {
                this.setFocusErrorField();
                error = true;
            }

            if (!this.validateAgreement()) {
                error = true;
            }

            return !error;
        },

        async makeSubscribeRequest() {
            if (this.$refs.honeypot.isCaptured()) {
                this.$refs.honeypot.sendGAEvent();
            }

            const munchkinId = formHelper.getCookies(formHelper.MRKTO_COOKIE_NAME) || formHelper.createMarketoToken();
            const payload: any = {
                ...this.form,
                event_id: this.eventId,
                event_name: this.eventName,
                event_type: this.eventType,
                event_time: this.form.event_time || this.startDate,
                event_category: this.eventCategory,
                munchkinId,
                zoomMeetingId: this.zoomMeetingId,
                marketoCampaignId: this.marketoCampaignId,
                sfdc_id: this.sfdcId,
                lms_exam_url: this.lmsExamUrl,
                google_analytics_trackingid: formHelper.GA_TRACKING_ID,
                google_analytics_userid: formHelper.getCookies(formHelper.UID_COOKIE_NAME) || '',
                google_analytics_clientid: formHelper.getCIDval() || '[ERROR] Client ID not generated',
                ...formHelper.labelsUTM(),
                _recaptchaResponse: this.recaptchaResponse,
                SP_validation: null,
                jp_hyb_nw_dinner: null,
                meeting_requested: null,
                eventPreviewToken: null,
                mkto_notification_emails: this.subscribers.join(', ') || null,
            };

            if (this.$refs.honeypot.isCaptured()) {
                payload.business_address = this.$refs.honeypot.getValue();
            }

            let sendNotSpEvent;
            try {
                sendNotSpEvent = await this.checkIfSuitableForNotSpEvent(); // WEB-43432
            } catch (error) {
                sendNotSpEvent = false;
            }
            if (sendNotSpEvent) this.sendNotSpEvent();

            const term = this.$route.query?.utm_term;
            if (['form', 'ccform'].includes(term) || window.sessionStorage?.getItem('utm_term')) {
                payload.SP_validation = !sendNotSpEvent;
            }

            if (this.hasJPSummitAdditionalFields) {
                payload.jp_hyb_nw_dinner = this.form.jp_hyb_nw_dinner === 'yes';
            }

            if (this.showAppointmentFields) {
                payload.meeting_requested = this.isMeetingRequested;
            }

            if (this.$route.params?.key) {
                payload.eventPreviewToken = this.$route.params.key;
            }

            // WEB-46528
            payload.attend_training = this.form.attend_training === 'yes';

            if (this.form.training?.length) {
                payload.sessions = this.form.training.map((trainingNumber) => {
                    const training = JSON.parse(JSON.stringify(this.trainings.find((t) => t.training_session_number === trainingNumber)));
                    delete training.textValue;
                    return training;
                });
            }
            delete payload.training;

            try {
                const response = await this.sendLead(payload);
                this.$emit('leadRequest', {
                    email: this.form.email,
                    osauid: response.data.osauid,
                });

                this.sendEventMarketingSubscription(this.form.receiveNews);

                this.$emit('unlock');
                this.showSuccess();
                this.sendDataLayer({
                    eventAction: 'submit_form_success',
                    eventCategory: 'mkto form',
                    eventLabel: 'success form events',
                    formTimer: formHelper.getSecondsOnPage(), // WEB-44946
                    emailAddress: commonUtils.toSHA256String(this.form.email), // WEB-45304
                });
            } catch (error) {
                const isEmailError = error?.ex && error?.code === 'invalid_email';

                if (isEmailError) {
                    this.checkMailgunError(error);
                } else {
                    this.handleError(error);
                }
            } finally {
                this.isLoading = false;
                window.grecaptcha?.enterprise.reset(this.recaptchaWidgetId);
            }
        },

        handleError(error) {
            const defaultError = this.formElementsDefault.errors?.apiErrorCodes?.default || 'Error';
            this.error = {
                title: this.formElementsDefault.errors?.apiErrorCodes?.[error.code] || defaultError,
                code: error.code,
            };
            this.mode = 'error';
            Logger.error({ error });
        },

        showSuccess() {
            this.mode = 'success';
        },

        checkMailgunError(error) {
            const isTypo = error.ex.reason === 'failed custom grammar check' || error.ex.didYouMean;
            const errorKey = isTypo ? 'invalidMailTypo' : 'invalidMailGeneral';

            this.emailError = '';
            this.$nextTick(() => {
                this.emailError = this.formElementsDefault.errors?.eventsMailGunErrors?.[errorKey] || 'Error';
            });
        },

        async checkIfSuitableForNotSpEvent() {
            // this works only for utm_term = "form" or "ccform"
            const term = this.$route.query?.utm_term;
            if (!['form', 'ccform'].includes(term)) {
                return false;
            }

            const emailDomain = this.form.email.split('@')[1];

            // Email domain is listed in the "free email domains" list
            if (freeEmailDomains.includes(emailDomain)) {
                return true;
            }

            // Email domain is listed in the blacklisted array of domains stored in slice-settings
            const isInBlacklist = this.formGlobalSettings.blacklistedEmailDomains.some((domainPart) => emailDomain.includes(domainPart));
            if (isInBlacklist) {
                return true;
            }

            // Email domain is NOT listed in the SFDC database of verified SP companies
            if (term === 'form') {
                const { data } = await this.$store.dispatch('events/checkEmailInSpDomain', this.form.email);
                return !data.isSp;
            }

            // Email domain is NOT listed in the list of 6sense validated domains
            if (term === 'ccform') {
                const { data } = await this.$store.dispatch('events/checkEmailIn6senseList', this.form.email);
                return !data.isValidated;
            }

            return false;
        },

        sendNotSpEvent() {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'NotSP',
                notSP: 'true',
            });
        },

        triggerHighlightEffect() {
            this.isHighlighted = true;
            setTimeout(() => { this.isHighlighted = false; }, HIGHLIGHT_DURATION);
        },
    },
};
</script>

<style lang="postcss" scoped>
.event-form {
    position: relative;
    background: var(--av-fixed-white);
    padding: 40px 32px 48px;
    border: 1px solid var(--av-brand-light);
    box-shadow: var(--av-shadow-small);
    border-radius: 4px;
    max-width: 540px;
    margin: 0 auto;
    outline: 3px solid transparent;
    transition: outline 0.4s ease-out, box-shadow 0.4s ease-out;

    &.columns-layout {
        max-width: 727px;

        @media (--viewport-tablet) {
            .title {
                text-align: start;
            }

            .agreements.has-grid {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 24px;
            }
        }
    }

    &.is-highlighted {
        outline: 3px solid var(--av-nav-secondary);
        box-shadow: var(--av-shadow-regular);
    },

    @media (--viewport-tablet) {
        padding-inline-start: 40px;
        padding-inline-end: 40px;
    }

    @media (--viewport-desktop) {
        padding-inline-start: 48px;
        padding-inline-end: 48px;
    }

    .title-wrap {
        margin-bottom: 16px;
    }

    .title {
        @mixin title-accent;
        text-align: center;
        color: var(--av-nav-primary);
        margin-bottom: 8px;
    }

    .nextEvent {
        @mixin paragraph;
        text-align: center;
        color: var(--av-fixed-primary);
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .a-tooltip {
        position: relative;
        top: 2px;
        background: transparent;
        margin-inline-start: 8px;
    }

    .form {
        position: relative;
        z-index: 1;
        text-align: start;
    }

    .form-item {
        margin-bottom: 9px;
    }

    .agreements {
        padding: 24px;
        background: var(--av-brand-accent);
        border-radius: 4px;
        color: var(--av-fixed-secondary);
        margin-bottom: 24px;
    }

    .agreement {
        @mixin body;
        display: block;
        margin-bottom: 8px;
    }

    .submit {
        width: 100%;
        font-weight: 700;
        color: var(--av-fixed-white);
    }

    .success,
    .error {
        position: absolute;
        background: var(--av-fixed-white);
        top: 0;
        inset-inline-start: 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 40px;
        z-index: 2;
    }

    .success-image {
        margin-bottom: 32px;
    }

    .success-glyph {
        fill: var(--av-fixed-success);
        margin-bottom: 20px;
    }

    .success-title {
        @mixin title-accent;
        color: var(--av-nav-primary);
        margin-bottom: 24px;
        text-align: center;
    }

    .success-text {
        @mixin paragraph;
        text-align: center;
        margin-bottom: 24px;
    }

    .form-error {
        @mixin body;

        display: flex;
        justify-content: center;
        padding: 24px;
        text-align: center;
        border-radius: 4px;
        background: var(--av-fixed-danger-accent);
        color: var(--av-fixed-secondary);
        margin-bottom: 24px;
    }

    .loading {
        top: 0;
        inset-inline-start: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: flex;
        position: absolute;
        border-radius: 4px;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        background: var(--av-inversed-secondary);
    }

    .bottomText {
        @mixin caption;

        margin-top: 8px;
        color: var(--av-fixed-secondary);
    }

    &:deep(.el-form-item.is-error.is-focus .form-error) {
        color: var(--av-fixed-danger);
    }

    &:deep(.phone-number-input) {
        margin-bottom: 9px;
    }
}
</style>

<style lang="postcss">
.event-form {
    input,
    select {
        color: var(--av-fixed-primary);
    }

    .el-form-item {
        padding-bottom: initial;
    }

    .el-select {
        .el-input .el-select__caret {
            color: var(--av-brand-primary);
        }
    }

    .el-select-dropdown__item span:first-letter {
        text-transform: capitalize;
    }

    .el-checkbox {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 8px;

        @media (--viewport-tablet) {
            @mixin paragraph;
        }

        &.is-checked {
            .el-checkbox__control {
                background: var(--av-brand-primary);
            }
        }

        &__content {
            align-items: flex-start;
            text-align: start;
        }

        &__control {
            margin-top: 4px;
        }
    }

    .loading {
        .el-loading {
            .el-text {
                display: block;
                margin-top: 20px;
                font-size: 16px;
            }
        }
    }

    .grecaptcha-badge {
        visibility: hidden;
    }
}

.event-dropdown {
    .el-select-dropdown__item {
        padding: 8px 16px !important;
        min-height: 40px;
        height: auto;

        span {
            white-space: break-spaces;
        }
    }
}

.z-index-2005 {
    z-index: 2147483655 !important;
}
</style>
