<template>
    <div class="s-form-error">
        <a-glyph
            v-if="content.glyph"
            :name="content.glyph || 'attention'"
            :fill="content.glyphFill || 'orange-bright'"
            :size="content.glyphSize || 'l'"
        />
        <div v-if="content.title" class="title" v-html="content.title" />
        <div v-if="content.description" class="description" v-html="content.description" />
        <div v-if="content.code" class="code">
            [{{ content.code }}]
        </div>
        <a-button
            v-if="content.button"
            v-bind="content.button"
            :event="{ doNotSendGA: true }"
            @click="$emit('submit')"
        />
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import AGlyph from '@core/components/glyph/glyph.vue';

export default {
    name: 'TrialFormError',

    components: {
        AButton,
        AGlyph,
    },

    props: {
        content: {
            type: Object,
            default: () => ({
                title: 'Something went wrong',
                description: 'Try reloading the page',
                button: {
                    text: 'Try again',
                    type: 'main',
                },
                code: null,
            }),
        },
    },

    emits: ['submit'],
};
</script>

<style lang="postcss" scoped>
.s-form-error {
    width: 100%;
    display: flex;
    min-height: 352px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 48px 16px 40px;
    background: var(--av-fixed-white);

    @media (--viewport-mobile-wide) {
        border-radius: 4px;
        padding: 48px 48px 42px;
        box-shadow: var(--av-shadow-small);
        border: 1px solid var(--av-brand-light);
    }

    .title {
        @mixin title-accent;
        text-align: center;
        color: var(--av-nav-primary);
    }

    .description {
        @mixin paragraph;
        margin: 16px 0 0;
        text-align: center;
    }

    .code {
        @mixin paragraph;
        margin: 16px 0 0;
        text-align: center;
        color: var(--av-fixed-light);
    }

    .a-button {
        margin: 40px auto 0;
        padding: 8px 16px;
        min-width: 200px;
    }

    .a-glyph {
        margin: 0 0 16px;
    }
}
</style>
