<template>
    <div class="resource-home-analyst-reports" :class="`resource-home-analyst-reports--${backgroundColor}`">
        <div class="container">
            <div class="texts-wrapper">
                <h2 class="title">
                    {{ blockTitle }}
                </h2>
                <a-link
                    :text="commonTranslations['browseAllReports']"
                    glyph="arrow"
                    :to="toCategoryUrl"
                />
            </div>
            <div class="list">
                <dynamic-link
                    v-for="item in list"
                    :key="item.id"
                    class="list-item"
                    :to="getResourceUrl(item)"
                >
                    <div class="list-item-type">
                        {{ item.type.title }}
                    </div>
                    <div class="list-item-title">
                        {{ item.title }}
                    </div>
                </dynamic-link>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import ALink from '@core/components/link/link.vue';
import { RC_ROOT_URL } from '@model/const/resource-center';

export default {
    name: 'ResourceHomeSpecificTypeList',

    components: {
        DynamicLink,
        ALink,
    },

    props: {
        list: {
            type: Array,
            required: true,
        },
        backgroundColor: {
            type: String,
            default: 'white',
        },
        commonTranslations: {
            type: Object,
            required: true,
        },
    },

    computed: {
        blockTitle() {
            return this.list[0].type.title;
        },

        toCategoryUrl() {
            return this.list[0].type.to;
        },
    },

    methods: {
        getResourceUrl(item) {
            return `${RC_ROOT_URL}/resource/${item.slug}`;
        },
    },
};
</script>

<style lang="postcss" scoped>
.resource-home-analyst-reports {
    width: 100%;
    background: rgba(64, 139, 234, 0.05);
    border-top: 1px solid var(--av-brand-secondary-light);
    border-bottom: 1px solid var(--av-brand-secondary-light);

    &--brand-secondary {
        background: rgba(64, 139, 234, 0.05);
    }

    .container {
        max-width: 1440px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 40px;
        padding: 64px 16px;

        @media (--viewport-tablet) {
            padding: 64px 32px;
        }

        @media (--viewport-desktop-wide) {
            padding: 64px;
        }
    }
}

.texts-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;

    @media (--viewport-tablet) {
        flex-flow: row wrap;
        justify-content: space-between;
    }

    &:deep(.a-link__content) {
        @mixin lead-accent;
    }
}

.title {
    @mixin hero;
    color: var(--av-nav-primary);
}

.list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    width: 100%;

    @media (--viewport-mobile-wide) {
        flex-direction: row;
        align-items: flex-start;
        flex-wrap: wrap;
    }

    @media (--viewport-tablet) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: stretch;
    }

    @media (--viewport-desktop) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    @media (--viewport-desktop-large) {
        gap: 60px;
    }
}

.list-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 328px;
    height: auto;
    padding: 24px;
    background: var(--av-fixed-white);
    border: 1px solid var(--av-brand-secondary);
    box-shadow: 0 4px 8px rgba(36, 49, 67, 0.1);
    border-radius: 4px;
    cursor: pointer;

    @media (--viewport-mobile-wide) {
        width: 256px;
    }

    @media (--viewport-tablet) {
        width: initial;
    }

    &:hover {
        .list-item-title {
            color: var(--av-brand-primary);
        }
    }
}

.list-item-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--av-fixed-primary);
}

.list-item-type {
    border-radius: 4px;
    padding: 0 8px;
    font-weight: 700;
    font-size: 11px;
    line-height: 16px;
    margin-bottom: 16px;
    text-transform: uppercase;
    background: var(--av-brand-secondary-light);
    color: var(--av-nav-secondary);

    @media (--viewport-desktop) {
        margin-bottom: 32px;
    }
}
</style>
