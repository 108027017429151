import { mapActions, mapState } from 'vuex';
import commonUtils from '@utils/common';
import formHelper from '@utils/form.js';
import { fieldLabels } from './maps.js';

export default {
    data() {
        return {
            userData: {
                osauid: null,
                first_name: null,
                last_name: null,
                phone: null,
                email: null,
                company: null,
                country: null,
                location: null,
                tenantId: null,
                aan: null,
                requestedContact: false,
            },
            greetModalShown: false,
            gated: false,
            isPlatformUser: false,
            contactModalShown: false,
            searchParams: null,
        };
    },
    async beforeMount() {
        this.searchParams = new URL(document.location).searchParams;
        await this.$store.dispatch('geolocation/getGeoLocation');
        this.userData.location = this.$store.state.geolocation.location?.data?.country?.name;
        await this.verifyUser();
    },
    computed: {
        ...mapState({
            pageAudiences: (state) => state.pages.page.audiences,
            pageCategory: (state) => state.pages.page.types,
            queryStringData: (state) => state.trial?.queryStringData || {},
        }),
    },
    methods: {
        ...mapActions({
            createLeadRequest: 'vroi/createLeadRequest',
            updateLeadRequest: 'vroi/updateLeadRequest',
        }),
        async verifyUser() {
            const osauid = localStorage.getItem('prospect_calculator_osauid');
            const tenantId = localStorage.getItem('prospect_calculator_tenantId') || this.queryStringData.tenantId;

            const hasId = osauid && osauid.length;
            const hasTenantId = tenantId && tenantId.length;

            if (!hasId && !hasTenantId) {
                this.gated = true;
                return;
            }

            if (hasTenantId && !hasId) this.greetModalShown = true;

            this.isPlatformUser = hasTenantId;
            this.gated = false;
            const expectedKeys = new Set([
                'osauid',
                'first_name',
                'last_name',
                'phone',
                'email',
                'company',
                'country',
                'tenantId',
                'aan',
            ]);
            Object.keys(this.userData)
                .filter((x) => expectedKeys.has(x))
                .forEach(this.prefillUserData);

            if (this.userData.osauid) return;
            await this.leadRequest();
        },
        async updateOrCreateUser() {
            try {
                await this.leadRequest('update');
            } catch (error) {
                const status = error.response?.status;
                if (!status || ![409, 404].includes(status)) return;
                await this.leadRequest('create');
            }
        },
        prefillUserData(key) {
            const value = this.queryStringData[key] || localStorage.getItem(`prospect_calculator_${key}`);
            if (!value || value === 'undefined') return;

            this.userData[key] = value;
            localStorage.setItem(`prospect_calculator_${key}`, value);
        },
        async leadRequest(action = 'create') {
            const params = {
                ...this.userData,
                dataCenterLocation: this.findLabelFromSelect('globalsDataCenter'),
                selectedStorageOption: this.findLabelFromSelect('cloudStorageOption'),
                licensingModel: this.form.globalsPricingMethod,
                userFromPlatform: this.isPlatformUser,
                fieldsFormatted: this.formatFormFields(),
                form_id: 'PROSPECT_CALCULATOR',
                lead_source: 'Website',
                new_lead_source_detail: 'SP Pricing Calculator',
                product_title: 'Acronis Cyber Protect Cloud',
                calculated_monthly_cost: this.orderTotal.monthly?.value || 0,
                isOver900: this.isOver900,
                isLessThan250: this.isLessThan250,
                locale: this.locale,
                language: this.language,
                currency: this.productCurrency,
                audiences: this.pageAudiences,
                category: this.pageCategory,
                ck: formHelper.getCookies(formHelper.MRKTO_COOKIE_NAME) || formHelper.createMarketoToken(),
                google_analytics_trackingid: formHelper.GA_TRACKING_ID,
                google_analytics_userid: formHelper.getCookies(formHelper.UID_COOKIE_NAME) || '',
                google_analytics_clientid:
                    formHelper.getCIDval() || '[ERROR] Client ID not generated',
                sfdc_campaign_status: this.searchParams.get('sfdc_campaign_status') || '',
                sfdc_campaign_id: '701Hs000001qTA9IAM',
                ...formHelper.labelsUTM(),
            };

            const payload = { ...params, ...formHelper.sfdcCampaign() };

            const updateOrCreate = action === 'update' ? this.updateLeadRequest : this.createLeadRequest;
            const response = await updateOrCreate(payload);
            this.userData.osauid = response.data.data.osauid;
            localStorage.setItem('prospect_calculator_osauid', this.userData.osauid);
        },
        formatFormFields() {
            return Object.keys(fieldLabels).map((key) => ({
                key,
                value: this.form[key] || this.form.additional[key],
                label: fieldLabels[key],
            }));
        },
        gatedFormSubmit(payload) {
            Object.keys(payload.data).forEach((key) => {
                this.userData[key] = payload.data[key];
                localStorage.setItem(`prospect_calculator_${key}`, payload.data[key]);
            });
            this.userData.osauid = payload.osauid;
            localStorage.setItem('prospect_calculator_osauid', this.userData.osauid);

            this.gated = payload.gated;
            this.changeLanguage();
            this.checkForHigherCommit();

            formHelper.sendDataLayer({
                eventCategory: 'form',
                eventAction: 'submit_form_success',
                eventLabel: 'vroi calculator gated form',
                // WEB-44946
                formTimer: formHelper.getSecondsOnPage(),
                emailAddress: commonUtils.toSHA256String(this.userData.email), // WEB-45304
            });
        },
        contactAdvisor(e) {
            e.preventDefault();
            this.userData.requestedContact = true;
            this.contactModalShown = true;
            formHelper.sendDataLayer({
                eventCategory: 'Conversions',
                eventAction: 'Contact Acronis Cloud Advisor',
                eventLabel: window.location.href,
            });
        },
        async greetModalClosed() {
            this.greetModalShown = false;
            await this.leadRequest();
            formHelper.sendDataLayer({
                eventCategory: 'Conversions',
                eventAction: this.greetModal.button.text,
                eventLabel: '',
            });
        },
        errorModalClosed() {
            this.isError = false;
            this.gated = true;
        },
    },
};
