<template>
    <section class="options-section" :class="id">
        <div v-if="section.requirements?.workloads" class="requirement">
            <span class="message" :class="{'has-reminder': hasWorkloadsReminder }">
                <span v-if="section.requirements.workloads.title" class="title">
                    {{ section.requirements.workloads.title }}
                </span>
                <span
                    v-if="!requirements.workloads && section.requirements.workloads.reminder"
                    class="reminder"
                >
                    {{ section.requirements.workloads.reminder }}
                </span>
                <span v-if="!requirements.workloads" class="indicator">*</span>
            </span>
            <a-tooltip
                v-if="section.requirements.workloads.tooltip"
                class="tooltip"
                :text="section.requirements.workloads.tooltip"
                placement="top"
            >
                <a-glyph name="tooltip-o" class="tooltip-icon" />
            </a-tooltip>
        </div>
        <div class="options">
            <div v-if="$slots.numberOptions" class="number-options">
                <slot name="numberOptions" />
            </div>
            <div v-if="$slots.selectOptions" class="select-options">
                <div v-if="section.requirements?.storage" class="requirement storage">
                    <span class="message" :class="{'has-reminder': hasStorageReminder }">
                        <span v-if="section.requirements.storage.title" class="title">
                            {{ section.requirements.storage.title }}
                        </span>
                        <span
                            v-if="!requirements.storage && section.requirements.storage.reminder"
                            class="reminder"
                        >
                            {{ section.requirements.storage.reminder }}
                        </span>
                        <span v-if="!requirements.storage" class="indicator">*</span>
                    </span>
                    <a-tooltip
                        v-if="section.requirements.storage.tooltip"
                        class="tooltip"
                        :text="section.requirements.storage.tooltip"
                        placement="top"
                    >
                        <a-glyph name="tooltip-o" class="tooltip-icon" />
                    </a-tooltip>
                </div>
                <slot name="selectOptions" />
            </div>
        </div>
    </section>
</template>

<script>
import AGlyph from '@core/components/glyph/glyph.vue';
import ATooltip from '@core/components/tooltip/tooltip.vue';
import helpers from '../utils/helpers.js';

export default {
    components: {
        AGlyph,
        ATooltip,
    },
    mixins: [helpers],
    props: {
        id: {
            type: String,
            required: true,
        },
        section: {
            type: Object,
            required: true,
        },
        requirements: {
            type: Object,
            default: null,
        },
    },
    computed: {
        hasWorkloadsReminder() {
            return !this.requirements.workloads && this.section.requirements.workloads.reminder;
        },
        hasStorageReminder() {
            return !this.requirements.storage && this.section.requirements.storage.reminder;
        },
    },
};
</script>

<style lang="postcss" scoped>
section {
    margin-bottom:64px;
    .requirement {
        padding: 24px;
        display: flex;
        align-items: center;
        color: var(--av-nav-primary);
        @media (--viewport-mobile-wide) {
            padding: 40px 40px 24px;
        }
        &.storage {
            padding: 0;
            margin-bottom: 24px;
        }
        .message {
            .title {
                @mixin paragraph-accent;
            }
            .reminder {
                @mixin paragraph;
                font-weight: 400;
            }
            .indicator {
                vertical-align: middle;
                color: var(--av-fixed-danger);
            }
            &.has-reminder {
                .title {
                    display: block;
                    @media (--viewport-mobile-wide) {
                        display: inline-block;
                    }
                }
            }
        }
        .tooltip {
            display: none;
            margin-inline-start: 8px;
            @media (--viewport-mobile-wide) {
                display: inline-block;
            }
        }
    }
    &.options-section {
        border: 1px solid var(--av-brand-light);
        border-radius: 4px;
        box-shadow: var(--av-shadow-small);
        background: var(--av-fixed-white);
        .options {
            .number-options {
                padding: 0 24px 48px;
                display: flex;
                flex-flow: row wrap;
                gap: 24px;
                @media (--viewport-mobile-wide) {
                    padding: 0 40px 48px;
                }
                @media (--viewport-desktop-wide) {
                    gap: 16px;
                }
            }
            .select-options {
                padding: 40px 24px;
                border-top: 1px solid var(--av-brand-light);
                @media (--viewport-mobile-wide) {
                    padding: 40px 40px 48px;
                }
                .select-wrapper {
                    margin-bottom: 24px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
</style>
