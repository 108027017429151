import { LOCALE_DEFAULT } from '@model/const/locales.ts';
import commonUtils from '@utils/common';
import formHelper from '@utils/form.js';
import { maps, PER_WORKLOAD_ID, PER_GB_ID } from './maps.js';

export default {
    data() {
        return {
            maps,
            form: {
                globalsPricingMethod: 1,
                globalsDataCenter: null,
                cloudPhysicalServers: 0,
                cloudVirtualMachines: 0,
                cloudVmDirector: 0,
                cloudWorkstations: 0,
                cloudHostingServers: 0,
                cloudMsSeats: 0,
                cloudGoogleWorspaceSeats: 0,
                cloudMobileDevices: 0,
                cloudWebsites: 0,
                cloudStorageOption: 1,
                cloudStorageOptionDataCenter: 0,
                cloudStorageOptionCloud: 0,
                cloudStorageOptionSpHosted: 0,
                cloudStorageOptionLocal: 0,
                cloudMsStorage: 0,
                backupPhysicalServers: 0,
                backupVirtualMachines: 0,
                backupWorkstations: 0,
                backupHostingServers: 0,
                securityWorkloads: 0,
                emailSecurityMailboxes: 0,
                managementWorkloads: 0,
                additional: {
                    disasterRecovery: false,
                    dlp: false,
                    edr: false,
                },
            },
            isLoading: true,
            cartRequirements: {
                dataCenter: false,
                workloads: false,
                storage: false,
            },
            pricingMethodChanged: false,
            expandedCart: true,
            detailedSummary: true,
            pdfInProgress: false,
            pdfUrl: null,
            pdfError: false,
            vc: null,
            variables: null,
            changes: [],
            allOutputs: {},
        };
    },
    watch: {
        // eslint-disable-next-line func-names
        'form.cloudMsSeats': function (val) {
            if (!val || val < 1) return this.clearRequirementMsStorage();
            if ('msStorage' in this.cartRequirements) return false;

            this.$set(this.cartRequirements, 'msStorage', false);
            return this.checkRequirements();
        },
    },
    computed: {
        locale() {
            return this.$route?.params.locale || LOCALE_DEFAULT;
        },
        language() {
            /* These are exceptions in their documentation that doesn't match the pattern of taking the first part of the locale
            *  Will discuss their removal eitehr using full locale for this method, or using only second part of standard iso codes.
            */
            switch (this.locale) {
                case ('en-gb'):
                    return 'gb';
                case ('ja-jp'):
                    return 'jp';
                default:
                    return this.locale.split('-').shift();
            }
        },
        showMsStorage() {
            return this.hasMsSeats && 'msStorage' in this.cartRequirements;
        },
        hasMsSeats() {
            return this.form.cloudMsSeats >= 1;
        },
        selectedPricingMethod() {
            return this.cart.pricingMethods.items.find((el) => el.id === this.form.globalsPricingMethod);
        },
        recommendedPricingMethod() {
            const isWorkloadRecommended = Boolean(this.allOutputs[this.maps.globalsPerWorkloadRecommended]?.value);
            return isWorkloadRecommended ? PER_WORKLOAD_ID : PER_GB_ID;
        },
        pricingState() {
            return this.selectedPricingMethod.id === this.recommendedPricingMethod ? 'recommended' : 'warning';
        },
        isOver900() {
            return Boolean(this.allOutputs[this.maps.globalsGreaterThan900]?.value);
        },
        isLessThan250() {
            return Boolean(this.allOutputs[this.maps.globalsLessThan250]?.value);
        },
        isWorkloads20() {
            return Boolean(this.allOutputs[this.maps.globalsWorkloadsRequirement]?.value);
        },
        isStorage200() {
            return Boolean(this.allOutputs[this.maps.globalsStorageRequirement]?.value);
        },
        requirementsMet() {
            return Object.values(this.cartRequirements).every((requirement) => Boolean(requirement));
        },
        requirementsCounter() {
            const requirements = Object.values(this.cartRequirements);
            const metRequirements = requirements.filter((el) => Boolean(el));

            return `(${metRequirements.length}/${requirements.length})`;
        },
        orderTotal() {
            return {
                cloud: this.allOutputs[this.maps.orderLine.cloudTotal],
                backup: this.allOutputs[this.maps.orderLine.backupTotal],
                security: this.allOutputs[this.maps.orderLine.securityTotal],
                emailSecurity: this.allOutputs[this.maps.orderLine.emailSecurityTotal],
                management: this.allOutputs[this.maps.orderLine.managementTotal],
                monthly: this.allOutputs[this.maps.orderLine.totalMonthly],
            };
        },
        minimumCommitAdjustment() {
            if (!this.orderTotal) return false;

            const minimum = this.orderTotal.monthly?.value || 0;
            const productAmounts = Object.keys(this.orderTotal)
                .filter((key) => key !== 'monthly')
                .map((key) => this.orderTotal[key]?.value || 0);

            const sum = productAmounts.reduce((acc, curr) => acc + curr, 0);
            const total = (minimum - sum).toFixed(2);
            if (total < 1) return false;
            return this.orderTotal.monthly?.decorated_value.replace(/\d+(\.\d+)?/, total);
        },
        monthlyTotal() {
            const price = this.orderTotal.monthly?.decorated_value;
            const suffix = this.cart.labels.monthly;

            return `${price} ${suffix}`;
        },
        pdfTitle() {
            if (this.pdfUrl) return this.cart.labels?.export.download;
            return this.cart.labels?.export[this.pdfInProgress ? 'progress' : 'generate'];
        },
        detailedSummaryTitle() {
            return this.cart.labels?.view[this.detailedSummary ? 'compact' : 'detailed'];
        },
        cartBackdropVisible() {
            if (!this.isMobile) return false;
            return this.requirementsMet && !this.isOver900 && this.expandedCart;
        },
    },
    methods: {
        async clearRequirementMsStorage() {
            this.$set(this.cartRequirements, 'msStorage', true);
            delete this.cartRequirements.msStorage;
            this.changeInput(this.maps.cloudMsStorage, 0);
            await this.$nextTick();
            this.checkRequirements();
        },
        findVariable(codeRef) {
            return this.variables?.find((el) => el.code_reference === codeRef);
        },
        changeInput(codeRef, value, calculate = true) {
            this.pdfUrl = null;
            this.pdfError = null;
            this.pushChanges(codeRef, value);
            const key = this.getKeyByCodeReference(codeRef);
            this.form[key] = value;

            if (!calculate) return;
            this.calculate();
            this.checkRequirements();
            this.debouncedUpdateUser();
        },
        changeStorageOptionValue(codeRef, value) {
            this.changeInput(this.maps.cloudStorageOptionCloud, 0, false);
            this.changeInput(this.maps.cloudStorageOptionDataCenter, 0, false);
            this.changeInput(this.maps.cloudStorageOptionSpHosted, 0, false);
            this.changeInput(this.maps.cloudStorageOptionLocal, 0, false);

            this.changeInput(codeRef, value);
        },
        pushChanges(codeRef, value) {
            const changed = this.changes.find((el) => el.code_reference === codeRef);
            if (!changed) {
                this.changes.push({ code_reference: codeRef, value });
                return;
            }
            changed.value = value;
        },
        calculate() {
            const allInputs = this.vc.calculate(this.changes);
            Object.keys(allInputs).forEach((key) => this.$set(this.allOutputs, key, allInputs[key]));

            this.dispatchEmptyResize(false);
        },
        downloadPdfClick() {
            formHelper.sendDataLayer({
                event: 'Acronis',
                eventCategory: 'Interactions',
                eventAction: 'documents',
                eventLabel: 'Your_Custom_Service_Provider_Pricing_from_Acronis',
                eventContent: this.selectedPricingMethod?.title,
            });
        },
        async exportPdf() {
            if (!navigator.onLine) {
                this.pdfError = true;
                return;
            }

            if (this.pdfInProgress) return;
            this.pdfInProgress = true;
            formHelper.sendDataLayer({
                event: 'Acronis',
                eventCategory: 'Interactions',
                eventAction: 'link',
                eventLabel: 'pdf export',
                eventContent: this.selectedPricingMethod?.title,
            });
            try {
                this.pdfUrl = await this.vc.export();
                await this.$nextTick();
                this.$refs.exportButton.click();
            } catch {
                this.pdfUrl = null;
                this.pdfError = true;
            } finally {
                this.pdfInProgress = false;
            }
        },

        changePricingMethod(codeRef, value, manual = false) {
            if (this.form.globalsPricingMethod === value) return;
            if (manual) { this.pricingMethodChanged = true; }

            this.changeInput(codeRef, value);
        },
        showOrderItem(codeRef, formKey = null) {
            const item = this.allOutputs[codeRef];
            const hasQty = this.form[formKey] > 0;
            return hasQty || (item && item.value && parseFloat(item.value) > 0);
        },
        getOrderItemQty(codeRef, suffix = false) {
            let orderLine = '';
            const variable = this.findVariable(this.maps[codeRef]);

            orderLine += this.form[codeRef];
            if (suffix) orderLine += ` ${suffix} `;
            orderLine += ` x ${variable.label} `;

            return orderLine;
        },
        getKeyByCodeReference(codeRef) {
            return Object.keys(this.maps).find((key) => this.maps[key] === codeRef);
        },
        checkRequirements() {
            this.cartRequirements.dataCenter = this.form.globalsDataCenter !== null && this.form.globalsDataCenter !== '';
            this.cartRequirements.workloads = this.isWorkloads20;
            this.cartRequirements.storage = this.isStorage200;

            if (this.showMsStorage) {
                this.cartRequirements.msStorage = this.form.cloudMsStorage > 0 || this.form.cloudMsSeats < 1;
            }
        },
        findLabelFromSelect(field) {
            const input = this.findVariable(this.maps[field]);
            if (!input || !input.decorated_value) return false;

            return Object.keys(input.decorated_value)
                .find((key) => this.form[field] === input.decorated_value[key].value) || null;
        },
        changeAdditional(field, value) {
            this.form.additional[field] = value;
            if (!value) return;

            formHelper.sendDataLayer({
                eventCategory: 'Conversions',
                eventAction: 'Add',
                eventLabel: field,
                eventContent: this.sections.additional.options[field].title,
            });
        },
        toggleDetailedSummary() {
            this.detailedSummary = !this.detailedSummary;
            this.dispatchEmptyResize(false);
        },
        toggleExpandedCart() {
            this.expandedCart = !this.expandedCart;

            if (this.expandedCart && this.cartBackdropVisible) {
                commonUtils.globalOverflowProperty('hidden');
            } else {
                commonUtils.globalOverflowProperty('auto');
            }
        },
        changeCurrency() {
            const currencyList = this.findVariable(this.maps.globalsCurrency)?.decorated_value || {};
            let currentCurrency;
            if (!this.higherCommitCountries.includes(this.userData.country)) {
                currentCurrency = currencyList[this.productCurrency]?.value || 1;
            } else {
                const countries = { US: 'USD', GB: 'GBP', IE: 'EUR', AU: 'AUD', JP: 'JPY' };
                const currency = countries[this.userData.country];
                currentCurrency = currencyList[currency]?.value;
            }
            this.pushChanges(this.maps.globalsCurrency, currentCurrency);
        },
        changeLanguage(calculate = true) {
            this.changeCurrency();
            const languages = { US: 'en-us', GB: 'en-gb', IE: 'en-eu', JP: 'en-jp' };

            const locale = languages[this.userData.country] || this.locale;
            this.vc.setLocale(locale, calculate);
            const variables = this.vc.conditional_variables_translation();
            if (!variables || !variables.length) return;
            variables.forEach((el) => this.$set(this.allOutputs, el.code_reference, el));
        },
        setEvent(item) {
            return item ? { category: 'Interactions', action: 'link', label: item.to } : {};
        },
        dispatchEmptyResize(debounce = true, timer = 150) {
            if (!debounce) return window.dispatchEvent(new Event('resize'));

            clearTimeout(this.resizeTimer);
            this.resizeTimer = setTimeout(() => window.dispatchEvent(new Event('resize')), timer);
            return this.resizeTimer;
        },
    },
};
