<template>
    <a-tabs class="s-workload">
        <a-tab v-for="(workload, workloadIndex) in listWorkloads" :key="`workload-${workloadIndex}`" :label="workload.name">
            <workload-editions :settings="settings" :product="workload" />
        </a-tab>
        <workload-summary :settings="settings" />
    </a-tabs>
</template>

<script>
import ATab from '@core/components/tabs/tab.vue';
import ATabs from '@core/components/tabs/tabs.vue';
import currency from '@core/mixins/currency.js';
import mixin from '../mixin.js';
import workloadEditions from './workload-editions.vue';
import workloadSummary from './workload-summary.vue';

export default {
    name: 'SStep1',

    components: {
        ATab,
        ATabs,
        workloadEditions,
        workloadSummary,
    },

    mixins: [mixin, currency],

    props: {
        /**
         * Settings
         */
        settings: {
            type: Object,
            default: null,
        },

        /**
         * Product
         */
        product: {
            type: Object,
            default: null,
        },
    },

    mounted() {
        this.ecommerceSendImpressions();
    },
};
</script>

<style lang="postcss" scoped>
.s-workload {
    &:deep(.a-tabs__header) {
        margin: 40px 0 16px;
    }
}
</style>
