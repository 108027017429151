<template>
    <div class="wrapper">
        <template v-if="productGroups.length">
            <div
                v-for="group in productGroups"
                :key="`productGroups_${group.id}`"
                class="group"
            >
                <div class="header">
                    <p class="title">
                        {{ group.title }}
                    </p>

                    <a
                        class="accordion__btn a-link-static"
                        href="#"
                        @click="isExpanded(group.id) ? collapse(group.id) : expand(group.id)"
                    >
                        <a-glyph :name="isExpanded(group.id) ? 'hide' : 'show'" fill="brand-primary" />
                        <span>
                            {{ isExpanded(group.id) ? dictionaryText.hideDocuments : dictionaryText.showDocuments }}
                        </span>
                    </a>
                </div>

                <a-accordion v-if="renderComponent" class="content-accordion" @changedActive="onChange">
                    <a-accordion-item
                        v-for="(product, index) in group.products"
                        :key="`productGroups_${group.id}_${index}`"
                        :ref="`accordion-${group.id}`"
                        :active="isExpanded(group.id)"
                    >
                        <template #title>
                            <a-glyph class="a-accordion__icon" name="icon-pointer-right" />
                            <p>{{ product.name }}</p>
                        </template>

                        <template v-for="(license, lIndex) in product.licenses">
                            <div :key="`license-${lIndex}`">
                                <ul>
                                    <li
                                        v-for="(document, dIndex) in license.documents"
                                        :key="`doc-${dIndex}`"
                                        class="license"
                                    >
                                        <a-link
                                            v-if="document.link"
                                            type="regular"
                                            :to="document.link"
                                            :text="document.name"
                                            :size="document.size || 'paragraph'"
                                            glyph="arrow-long"
                                            target="_blank"
                                            glyph-position="right"
                                            class="license__name"
                                        />
                                        <span v-else class="license__name">{{ document.name }}</span>
                                        <div class="license__doc">
                                            <a-link
                                                v-if="document.file"
                                                type="regular"
                                                :to="document.file"
                                                text="PDF Version"
                                                glyph="pdf"
                                                target="_blank"
                                                class="license__file"
                                                glyph-position="left"
                                            />
                                            <a-link
                                                v-if="document.rn"
                                                type="regular"
                                                :to="document.rn"
                                                text="Release notes"
                                                target="_blank"
                                                glyph="arrow-long"
                                                class="license__rn"
                                                glyph-position="right"
                                            />
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </template>
                    </a-accordion-item>
                </a-accordion>
            </div>
        </template>
        <div v-else class="empty-result">
            <a-glyph name="search" class="empty-result__icon" />
            <p class="empty-result__title">
                {{ noResults.title }}
            </p>
            <p class="empty-result__description">
                {{ noResults.description }}
            </p>
        </div>
    </div>
</template>

<script>
import AAccordionItem from '@core/components/accordion/accordion-item.vue';
import AAccordion from '@core/components/accordion/accordion.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALink from '@core/components/link/link.vue';

export default {
    name: 'SContent',
    components: {
        AAccordion,
        AAccordionItem,
        ALink,
        AGlyph,
    },
    props: {
        productGroups: {
            type: Array,
            default: () => ([]),
        },
        noResults: {
            type: Object,
            required: true,
        },
        dictionary: {
            type: Object,
            required: true,
        },
    },
    emits: ['accordionCollapse'],
    data() {
        return {
            expandedGroups: new Set(),
            renderComponent: true,
        };
    },
    computed: {
        dictionaryText() {
            return {
                showDocuments: this.dictionary['Show Documents'] || 'Show Documents',
                hideDocuments: this.dictionary['Hide Documents'] || 'Hide Documents',
            };
        },
    },
    watch: {
        productGroups() {
            this.init();
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.productGroups.forEach((group) => {
                this.collapse(group.id);
            });

            const singleGroup = this.productGroups && this.productGroups.length === 1;
            const singleInGroup = singleGroup && this.productGroups[0].products.length === 1;
            if (singleGroup && singleInGroup) {
                this.expand(this.productGroups[0].id);
            }
        },
        onChange(event) {
            this.$emit('accordionCollapse', event);
        },
        isExpanded(groupId) {
            return this.expandedGroups.has(groupId);
        },
        async forceRerender() {
            this.renderComponent = false;
            await this.$nextTick();
            this.renderComponent = true;
            return this.$forceUpdate();
        },
        async expand(groupId) {
            this.expandedGroups.add(groupId);
            await this.forceRerender();
        },
        async collapse(groupId) {
            this.expandedGroups.delete(groupId);
            await this.forceRerender();
        },
    },
};
</script>

<style lang="postcss" scoped>
.wrapper {
    padding-top: 48px;
    @mixin colls 12;
    @media (--viewport-desktop) {
        padding-top: 0;
    }
    .group {
        &:last-child {
            &:deep(.a-accordion-item) {
                margin: 0;
            }
        }
    }
    .content-accordion {
        &:deep(.title) {
            display: flex;
            align-items: center;
        }

        &:deep(.a-accordion__icon) {
            margin-inline-end: 16px;
        }

        &:deep(.a-accordion-item.active) {
            .a-accordion__icon {
                transform: rotateZ(90deg);
            }
        }
        &:deep(.title:active) {
            color: var(--av-nav-primary);
        }
    }

    .accordion__btn {
        @mixin paragraph-accent;
        display: flex;
        align-items: center;
        color: var(--av-brand-primary);
        cursor: pointer;

        .a-glyph {
            margin-inline-end: 9px;
        }
    }

    .header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;
        flex-direction: column;

        .title {
            margin-bottom: 8px;
            @media(--viewport-mobile-wide) {
                margin-bottom: 0px;
            }
        }

        @media(--viewport-mobile-wide) {
            flex-direction: row;
        }
    }

    .title {
        @mixin title-accent;
    }

    &:deep(.a-tag) {
        @mixin paragraph;
    }

    .empty-result {
        display: flex;
        flex-direction: column;
        align-items: center;
        &__icon {
            fill: var(--av-brand-light);
            margin-bottom: 26px;
            height: 58px;
            width: 58px;
        }
        &__title {
            @mixin title;
            margin-bottom: 8px;
        }
        &__description {
            @mixin paragraph;
            text-align: center;
            max-width: 448px;
        }
    }

    &:deep(.a-accordion) {
        &__icon {
            margin-inline-end: 16px;
        }
        .title {
            padding: 16px;
            @mixin paragraph-accent;
        }
        .content-wrapper {
            padding: 0;
        }
    }

    .license {
        display: flex;
        flex-direction: column;
        padding: 16px;
        background-color: var(--av-brand-lightest);
        border-bottom: 1px solid var(--av-brand-accent);
        @mixin caption;

        @media(--viewport-tablet) {
            padding: 16px 48px;
            flex-direction: row;
        }

        &__name {
            @mixin paragraph;
            margin-inline-end: auto;
            @media (--viewport-tablet) {
                flex-grow: 0;
                margin-inline-end: 48px;
            }
        }

        &__file:after,
        &__rn:before {
            content: '';
            height: 16px;
            width: 1px;
        }

        &:last-of-type {
            border-bottom: none;
        }

        &__file {
            display: flex;
            align-items: center;

            &:deep(.a-link__content) {
                margin-inline-end: 16px;
            }

            &:after {
                background-color: var(--av-brand-secondary-light);
                display: inline-block;
            }
        }

        &__rn {
            &:deep(.a-link__content) {
                margin-inline-start: 16px;
            }
        }

        &__doc {
            margin-top: 8px;
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-end;
            align-items: center;
            width: fit-content;
            @media (--viewport-tablet) {
                flex-grow: 1;
                margin-inline-start: auto;
                white-space: nowrap;
            }

            &:deep(.a-link__content) {
                font-size: 12px;
            }
            .hasGlyph {
                padding-inline-start: 0;
                .a-glyph {
                    display: none;
                }
            }

            @media(--viewport-tablet) {
                margin-top: 0px;
                &:deep(.a-link__content) {
                    font-size: 14px;
                }
                &:deep(.license__file .hasGlyph) {
                    padding-inline-start: 24px;
                }
                &:deep(.hasGlyph .a-glyph) {
                    display: inline-block;
                }
            }

            *:last-child {
                &:deep(.a-link__content) {
                    margin-inline-end: 0px;
                }
                &:after {
                    width: 0;
                }
            }
        }
    }
}
</style>
