<template>
    <section
        :id="id"
        class="s-slice s-header-message"
        :class="{
            ...basicClassName,
            breadcrumbs: isBreadcrumbs,
            's-header-message__visual': visual,
            's-header-message__is-only-slice': isOnlySliceOnPage,
        }"
    >
        <a-breadcrumbs
            v-if="isBreadcrumbs"
            :links="trans(breadcrumbs)"
            class="s-header-message__breadcrumbs"
            glyph-desktop="icon-pointer-right"
            glyph-mobile="long-arrow-left"
            :theme="breadCrumbsTheme"
            sublinks
        />
        <div class="s-header-message__wrapper" :class="className">
            <a-picture
                v-if="backgroundImage"
                class="s-header-message__img-container s-header-message__full-image"
                :background="backgroundImage.link"
                :fit="backgroundImage.fit"
                :position="backgroundImage.position"
                is-background
            />
            <div class="a-container s-header-message__container">
                <div class="s-header-message__text">
                    <a-slice-header
                        :title="trans(title)"
                        :lead="trans(lead)"
                        :title-tag="titleTag"
                        :body-text="trans(bodyText)"
                    />
                    <div v-if="card" class="s-header-message__card">
                        <a-header-card
                            :title-card="trans(titleCard)"
                            :description="trans(description)"
                            :buttons="trans(buttons)"
                        />
                    </div>
                    <div class="s-header-message__actions">
                        <div v-if="cta && buttons.length" class="s-header-message__buttons">
                            <div
                                v-for="(button, index) in trans(buttons)"
                                :key="button.main"
                                class="s-header-message__button-wrapper"
                            >
                                <a-button
                                    class="s-header-message__button"
                                    v-bind="button"
                                    :size="button.size || 's'"
                                    :type="button.type || getButtonType(index)"
                                />
                            </div>
                        </div>
                        <div v-if="email" class="s-header-message__email">
                            <a-dangerous-html :content="trans(email.text)" />
                        </div>
                        <div v-if="actionLinks" class="s-header-message__contacts">
                            <a-link
                                v-for="link in trans(actionLinks)"
                                :key="link.main"
                                class="s-header-message__link"
                                v-bind="link"
                                :size="link.size || 'paragraph'"
                                :type="breadCrumbsTheme === 'dark' ? 'light' : 'regular'"
                            />
                        </div>
                    </div>
                </div>
                <div
                    v-if="(!visual && media && !backgroundImage) || (!visual && imagePath && !backgroundImage)"
                    class="s-header-message__img-container"
                >
                    <a-media
                        class="s-header-message__img"
                        :type="media ? media.type : imageType"
                        :link="media ? media.link : imagePath"
                        :alt="media ? trans(media.alt) : trans(imageAlt)"
                        v-bind="trans(media)"
                    />
                </div>
                <div v-if="visual" class="s-header-message__img-container">
                    <a-picture v-if="visual.type === 'img'" class="s-header-message__img" :link="visual.link" />
                    <template v-if="visual.type === 'video' && visual.source">
                        <video
                            class="s-header-message__video"
                            playsinline
                            autoplay
                            muted
                            loop
                        >
                            <source
                                v-for="(item, index) in visual.source"
                                :key="`video-source-${index}`"
                                :src="item.link"
                                :type="`video/${item.type}`"
                            />
                        </video>
                    </template>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import ABreadcrumbs from '@core/components/breadcrumbs/breadcrumbs.vue';
import AButton from '@core/components/button/button.vue';
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import AHeaderCard from '@core/components/header-card/header-card.vue';
import ALink from '@core/components/link/link.vue';
import AMedia from '@core/components/media/media.vue';
import APicture from '@core/components/picture/picture.vue';
import ASliceHeader from '@core/components/slice-header/slice-header.vue';
import contentMixin from '@core/mixins/content.js';
import styleMixin from '@core/mixins/style.js';
import translations from '@core/mixins/translations.js';

export default {
    name: 'SHeaderMessage',

    components: {
        APicture,
        AMedia,
        ASliceHeader,
        ABreadcrumbs,
        AButton,
        AHeaderCard,
        ALink,
        ADangerousHtml,
    },

    mixins: [translations, contentMixin, styleMixin],

    props: {
        /**
         * Image type, Image Path and Image Alt are deprecated and to be replaced with media only.
         */

        imageType: {
            type: String,
            default: undefined,
        },

        imagePath: {
            type: String,
            default: undefined,
        },

        imageAlt: {
            type: String,
            default: undefined,
        },
        media: {
            type: Object,
            required: false,
            default: undefined,
        },
        cta: {
            type: Boolean,
            required: false,
        },

        card: {
            type: Boolean,
            default: false,
        },
        email: {
            type: Object,
            default: undefined,
        },
        useMinHeight: {
            type: Boolean,
            default: false,
        },

        titleCard: {
            type: String,
            default: undefined,
        },

        titleTag: {
            type: String,
            default: 'h2',
        },

        description: {
            type: String,
            default: undefined,
        },

        actionsHeader: {
            type: String,
            default: '',
        },

        actionLinks: {
            type: Array,
            default: undefined,
        },

        buttons: {
            type: Array,
            default: undefined,
            validator: (buttons) => buttons.length < 3,
        },

        articles: {
            type: Array,
            default: undefined,
        },

        backgroundImage: {
            type: [Object, String],
            default: undefined,
        },

        breadcrumbs: {
            type: Array,
            default: () => [],
        },

        visual: {
            type: Object,
            default: undefined,
        },

        isOnlySliceOnPage: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        className() {
            return {
                's-header-message__wrapper--cta': this.cta,
                's-header-message__wrapper--card': this.card,
                's-header-message__wrapper--extra-space': this.useMinHeight,
            };
        },
        isBreadcrumbs() {
            return Boolean(this.breadcrumbs.length);
        },
        breadCrumbsTheme() {
            if (this.background.includes('dark')) {
                return 'dark';
            }

            return 'light';
        },
    },

    methods: {
        getButtonType(index) {
            if (index === 0) {
                if (this.buttons.length === 1) return 'action';
                return 'main';
            }

            return 'action';
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-header-message {
    position: relative;
    padding-top: 115px;
    padding-bottom: 24px;

    @media (--viewport-desktop) {
        padding-top: 160px;
        padding-bottom: 88px;

        &.breadcrumbs {
            padding-top: 131px;
        }
    }

    @media (--viewport-desktop-wide) {
        padding-bottom: 104px;
    }

    &.s-slice_background_dark-blue {
        color: var(--av-fixed-white);

        &:deep(.a-slice-header__title),
        &:deep(.a-slice-header__lead),
        &:deep(.s-header-message__email),
        &:deep(.a-slice-header__body-text){
            color: var(--av-fixed-white);
        }
    }

    .a-slice-header {
        width: 100%;
        margin-bottom: 40px;

        @media (--viewport-tablet) {
            margin-bottom: 24px;
        }

        @media (--viewport-desktop) {
            margin-bottom: 0;
        }
    }

    &:deep(.a-slice-header__title) {
        @mixin display;
        @media (--viewport-mobile-wide) {
            @mixin hero;
        }
    }

    &__breadcrumbs {
        pointer-events: auto;
    }

    &__container {
        position: relative;

        @media (--viewport-desktop) {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
        }
    }

    &__text {
        @mixin colls 12;
        @media (--viewport-desktop) {
            @mixin colls 6;
            .breadcrumbs & {
                padding-top: 24px;
            }
        }
    }

    &__img-container {
        position: relative;
        padding: 16px 0 56px 0;

        @media (--viewport-tablet) {
            padding: 40px 0 56px 0;
        }

        @media (--viewport-desktop) {
            @mixin colls 5;
            margin: 0;
            padding: 0;
            display: flex;
            align-items: center;
        }
    }

    &__img {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (--viewport-desktop) {
            height: 100%;
        }

        &:deep(.a-picture__img) {
            @media (--viewport-desktop) {
                height: auto;
            }
        }
    }

    &__full-image {
        position: unset;

        &:deep(.a-picture__img) {
            position: absolute;
            max-height: none;
            height: auto;
            top: 0;
            inset-inline-start: 0;

            @media (--viewport-desktop) {
                max-height: 100%;
                height: 100%;
                top: auto;
                inset-inline-start: auto;
            }
        }
    }

    .a-breadcrumbs {
        margin-bottom: 20px;
        position: relative;
        top: auto;

        @media (--viewport-tablet) {
            margin-bottom: 28px;
        }

        @media (--viewport-desktop) {
            margin-bottom: 0;
        }
    }

    &__actions {
        margin-bottom: 24px;

        @media (--viewport-tablet) {
            margin-bottom: 0;
        }

        .s-header-message__link {
            margin-bottom: 8px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__buttons {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        margin-bottom: 24px;

        @media (--viewport-mobile-wide) {
            justify-content: flex-start;
        }
        @media (--viewport-tablet) {
            margin-bottom: 16px;
        }
    }

    &__button {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    &__button-wrapper {
        @mixin colls 12;
        margin: 0;

        &:not(:first-child) {
            margin-top: 16px;
        }

        @media (--viewport-mobile-wide) {
            width: auto;
            min-width: 200px;
            &:not(:first-child) {
                margin-top: 0;
                margin-inline-start: 16px;
            }
        }
    }

    &__contacts {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    &__email {
        margin-bottom: 8px;
        margin-top: 24px;
        color: var(--av-nav-primary);
    }

    &__card {
        margin-bottom: 24px;
    }

    &__wrapper {
        pointer-events: auto;

        &--cta {
            .a-slice-header {
                margin-bottom: 40px;
            }
        }

        &--extra-space {
            @media screen and (min-height: 700px) {
                min-height: 48vh;
            }
            @media screen and (min-height: 700px) {
                min-height: 50vh;
            }
            @media screen and (min-height: 900px) {
                min-height: 55vh;
            }
        }

        &--card {
            .a-slice-header {
                margin-bottom: 40px;

                @media (--viewport-tablet) {
                    margin-bottom: 40px;
                }

                @media (--viewport-desktop) {
                    margin-bottom: 24px;
                    padding-inline-end: 20px;
                }

                @media (--viewport-desktop-wide) {
                    padding-inline-end: 0;
                    margin-bottom: 40px;
                }
            }

            .s-header-message {
                &__text {
                    @media (--viewport-desktop) {
                        padding-inline-end: 16px;
                    }
                }

                &__card {
                    @media (--viewport-desktop) {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    &__visual {
        padding-top: 82px;

        .s-header-message {
            &__text {
                text-align: center;
            }

            &__img,
            &__video {
                height: 100%;
                width: 100%;
                vertical-align: middle;
            }
        }

        .a-slice-header {
            margin: 0;
        }

        &:deep(.a-slice-header__title) {
            @mixin display-accent;
            margin: 0 auto;
            max-width: 280px;

            @media (--viewport-mobile-wide) {
                @mixin hero-accent;
            }

            @media (--viewport-desktop) {
                @mixin large-accent;
            }
        }
        &:deep(.a-slice-header__body-text) {
            margin: 16px 0 8px;
            @mixin lead;
        }

        @media (--viewport-tablet) {
            padding-top: 74px;

            .s-header-message {
                &__container {
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                }

                &__text {
                    width: 284px;
                    min-width: 284px;
                    text-align: start;
                    margin-inline-end: 16px;
                }

                &__img-container {
                    width: auto;
                    min-width: 404px;
                    max-width: 665px;
                }
            }

            &:deep(.a-slice-header__title) {
                max-width: none;
                margin: 80px 0 0;
            }

            &:deep(.a-slice-header__body-text) {
                margin: 32px 0 0;
            }
        }

        @media (--viewport-desktop) {
            padding-top: 48px;

            &:deep(.a-slice-header__title) {
                @mixin large-accent;
            }

            &:deep(.a-slice-header__body-text) {
                @mixin title;
            }

            .s-header-message {
                &__text {
                    width: 392px;
                    min-width: 392px;
                }
            }
        }

        @media (--viewport-desktop-wide) {
            &:deep(.a-slice-header__body-text) {
                @mixin display;
            }

            .s-header-message {
                &__text {
                    width: 471px;
                    min-width: 471px;
                }
            }
        }
    }

    &__is-only-slice {
        min-height: 80vh;
    }
}
</style>
