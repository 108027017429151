<template>
    <div class="root">
        <div class="content a-container">
            <div class="logo">
                <a href="https://www.acronis.com/" class="to-www">
                    <a-logo type="full" :fill="null" />
                </a>

                <a href="/" class="to-home-page">
                    <h1>Developer portal</h1>
                </a>
            </div>

            <div class="message">
                <div class="code">
                    404
                </div>

                <h2>{{ lead }}</h2>
            </div>

            <div class="links">
                <template v-for="link of links">
                    <a-link
                        v-if="!link.type"
                        :key="link.link"
                        class="link"
                        v-bind="link"
                        :size="link.size || 'paragraph'"
                        :glyph="link.glyph || 'arrow'"
                        :glyph-position="link.glyphPosition || 'right'"
                        :to="link.link"
                    />

                    <a-button
                        v-if="link.type === 'button'"
                        :key="link.link"
                        v-bind="link"
                        :to="link.link"
                        class="link"
                        :size="link.size || 's'"
                        :type="link.type || 'main'"
                    />

                    <br :key="link.link + 'br'" />
                </template>
            </div>
        </div>

        <s-global-footer v-bind="footer" />
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import ALink from '@core/components/link/link.vue';
import ALogo from '@core/components/logo/logo.vue';
import SGlobalFooter from '@core/slices/pages/global-footer/global-footer.vue';

export default {
    name: 'AcronisPageNotFound',

    components: {
        AButton,
        ALink,
        ALogo,
        SGlobalFooter,
    },

    props: {
        lead: {
            type: String,
            required: true,
        },
        links: {
            type: Array,
            required: true,
        },
        footer: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style lang="postcss" scoped>
.root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.content {
    padding-top: 48px;
    padding-bottom: 48px;
    margin: 0 auto;
}

.to-home-page h1 {
    font-family: 'Acronis Cyber', 'Open Sans', sans-serif;
    color: #00204d;
    font-size: 32px;
    font-weight: 300;
    line-height: 40px;
    margin: 8px 0 0 0;
}

.message {
    margin-top: 64px;
}

.message .code {
    color: var(--av-brand-secondary);
    font-size: 56px;

    @media (--viewport-tablet) {
        font-size: 80px;
    }
}

.message h2 {
    color: #00204d;
    font-size: 28px;
    line-height: 32px;
    font-weight: 300;
    max-width: 600px;

    @media (--viewport-tablet) {
        font-size: 40px;
        line-height: 48px;
    }
}

.links {
    margin-top: 64px;
    margin-bottom: 64px;
}

.link {
    margin-top: 8px;
}
</style>
