<template>
    <div v-if="product.opened" class="s-card-onboarding-service">
        <div class="s-card-onboarding-service__selectors">
            <div v-if="product.quantity" class="s-card-onboarding-service__quantity">
                <el-num-picker
                    v-model="quantity"
                    :min="product.quantity.min"
                    :max="product.quantity.max"
                    :label="product.quantity.title"
                    :step="product.quantity.step || 1"
                    integer-only
                    @change="setProductQuantity({ productID: product.id, quantity: $event })"
                />
            </div>
            <div class="s-card-onboarding-service__info">
                <div class="s-card-onboarding-service__info-title">
                    {{ product.perpetual.title }}
                </div>
                <div class="s-card-onboarding-service__info-description" v-html="description" />
            </div>
        </div>
        <product-feature :product="product" />
        <notification
            v-for="(item, notificationIndex) in product.notifications"
            :key="`notification-${notificationIndex}`"
            :description="item"
            size="medium"
        />
    </div>
</template>

<script>
import mixin from '../mixin.js';
import notification from '../notification.vue';
import productFeature from '../step-2/product-feature.vue';

export default {
    name: 'SCardOnboardingService',

    components: {
        notification,
        productFeature,
        ElNumPicker: () => import('@uikit/ui-kit/packages/num-picker'),
    },

    mixins: [mixin],

    props: {
        /**
         * Settings
         */
        settings: {
            type: Object,
            default: null,
        },

        /**
         * Product
         */
        product: {
            type: Object,
            default: null,
        },
    },

    data: () => ({
        quantity: 0,
    }),

    computed: {
        description() {
            const price = this.setCurrency(this.productPrice(this.product));
            return this.product.perpetual.description.replace('$XX.XX', price);
        },
    },

    mounted() {
        this.quantity = this.product.selectedQuantity;
    },
};
</script>

<style lang="postcss" scoped>
.s-card-onboarding-service {
    padding: 0 32px;

    &__selectors {
        @mixin body;
        display: flex;
        margin: 16px 0 0;
        justify-content: space-between;
        color: var(--av-fixed-secondary);
    }

    &__info {
        width: 100%;
        margin-inline-start: 24px;

        &-title {
            @mixin body;
            color: var(--av-fixed-secondary);
        }

        &-description {
            font-size: 16px;
            line-height: 40px;

            &:deep(b) {
                font-weight: 700;
            }
        }
    }
}
</style>
