/* eslint-disable max-len */
const ARGS_DEFAULT = {
    translations: {
        title: 'Back up and protect your virtual environments easily and efficiently',
        lead: 'All-in-one data protection and cybersecurity to keep business flowing. No matter what.',
        bodyText: 'Mitigate risks with agentless and <nobr>agent-based</nobr> backup and recovery of your virtual <a href="#">machines </a>and hosts on any of six supported hypervisors – VMware vSphere, Microsoft <nobr>Hyper-V,</nobr> Red Hat Virtualization, Citrix XenServer, Linux.',
        description: 'Mitigate risks with agentless and agent-based backup and recovery of your virtual machines and hosts on any of six supported hypervisors',
        'breadcrumbs.2.text': 'Solution for service providers',
        'breadcrumbs.3.text': 'Managed server backups',
        'media.alt': 'Acronis',
        'email.text': 'Questions? Just email us at <a href="mailto:access-sales@acronis.com">access-sales@acronis.com</a>',
        'actionLinks.0.text': 'Contact sales +1 781 782 9000',
        'actionLinks.1.text': 'Find a Reseller',
        'buttons.0.text': 'Try now',
        'buttons.1.text': 'Buy now',
    },
    email: {
        text: 'email.text',
    },
    media: {
        type: 'image',
        link: '/images/schema-shield/schema',
        enlargedImage: '/images/schema-shield/schema',
        alt: 'media.alt',
    },
    actionLinks: [
        {
            text: 'actionLinks.0.text',
            to: 'tel:+17817829000',
        },
        {
            text: 'actionLinks.1.text',
            to: '#',
        },
    ],
    buttons: [
        { to: '#', text: 'buttons.0.text', type: 'action' },
        { to: '#', text: 'buttons.1.text', type: 'main' },
    ],
    breadcrumbs: [
        {
            text: '',
            to: '#',
            glyph: 'home',
            glyphPosition: 'left',
        },
        {
            sublinks: true,
        },
        {
            text: 'breadcrumbs.2.text',
            to: '#',
        },
        {
            text: 'breadcrumbs.3.text',
            to: '#',
        },
    ],
};

export const Figma = 'https://www.figma.com/design/eM4W6JS2MjG1gp5YhKoMp7Dd/Slices?node-id=44581-68721&t=TokMyJyDDac8zlkS-4';

export const Video = {
    args: {
        translations: {
            ...ARGS_DEFAULT.translations,
            'media.text': 'Real-time demo of ransomware protection',
        },
        navTheme: 'light',
        background: 'white',
        cta: false,
        card: false,
        title: 'title',
        bodyText: 'bodyText',
        media: {
            type: 'video',
            ytId: 'kSLEGX-j9Jg',
            text: 'media.text',
            time: '2:00',
            image: '/images/video_car/video_car',
            imageAlt: 'video',
        },
    },
};

export const BackgroundImage = {
    args: {
        translations: {
            ...ARGS_DEFAULT.translations,
        },
        navTheme: 'dark',
        background: 'dark-blue',
        cta: false,
        card: false,
        title: 'title',
        bodyText: 'bodyText',
        media: ARGS_DEFAULT.media,
        backgroundImage: {
            link: {
                mobile: '@638631ff403174f73f1a8d6c75044a41',
                tablet: '@638631ff403174f73f1a8d6c75044a41',
                desktop: '@638631ff403174f73f1a8d6c75044a41',
            },
        },
    },
};

export const Cta = {
    args: {
        translations: {
            ...ARGS_DEFAULT.translations,
        },
        navTheme: 'light',
        background: 'white',
        cta: true,
        card: false,
        title: 'title',
        bodyText: 'bodyText',
        media: ARGS_DEFAULT.media,
        buttons: ARGS_DEFAULT.buttons,
        actionLinks: ARGS_DEFAULT.actionLinks,
        email: ARGS_DEFAULT.email,
        breadcrumbs: ARGS_DEFAULT.breadcrumbs,
    },
};

export const Card = {
    args: {
        translations: {
            ...ARGS_DEFAULT.translations,
            titleCard: 'Acronis Cyber Backup',
        },
        navTheme: 'light',
        background: 'white',
        cta: false,
        card: true,
        title: 'title',
        bodyText: 'bodyText',
        lead: 'lead',
        description: 'description',
        titleCard: 'titleCard',
        media: ARGS_DEFAULT.media,
        buttons: ARGS_DEFAULT.buttons,
        email: ARGS_DEFAULT.email,
    },
};

export const Visual = {
    args: {
        translations: {
            ...ARGS_DEFAULT.translations,
        },
        navTheme: 'light',
        background: 'white',
        cta: false,
        card: false,
        title: 'title',
        lead: 'lead',
        visual: {
            type: 'video',
            source: [
                {
                    type: 'webm',
                    link: '//www.acronis.com/i/video/power.webm',
                },
                {
                    type: 'mp4',
                    link: '//www.acronis.com/i/video/power.mp4',
                },
            ],
        },
    },
};
