import { render, staticRenderFns } from "./legal.vue?vue&type=template&id=296a25b1&scoped=true"
import script from "./legal.vue?vue&type=script&lang=js"
export * from "./legal.vue?vue&type=script&lang=js"
import style0 from "./legal.vue?vue&type=style&index=0&id=296a25b1&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "296a25b1",
  null
  
)

export default component.exports