<template>
    <div class="error-modal-wrapper">
        <div class="error-modal">
            <div class="wrapper">
                <a-glyph name="revoke-24" class="icon" />
                <h6 class="title">
                    {{ labels.title }}
                </h6>
                <p class="description">
                    {{ labels.description }}
                </p>
                <a-button
                    v-if="!serverError"
                    :text="labels.button"
                    type="main"
                    class="button"
                    @click="$emit('onClose')"
                />
            </div>
            <button v-if="!serverError" class="close" @click="$emit('onClose');">
                <a-glyph name="close" class="close-icon" />
            </button>
        </div>
        <button v-if="!serverError" class="mobile-close" @click="$emit('onClose');">
            <a-glyph name="close" class="close-icon" />
        </button>
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import commonUtils from '@utils/common';

export default {
    components: {
        AButton,
        AGlyph,
    },
    props: {
        serverError: {
            type: Boolean,
            default: false,
        },
        labels: {
            type: Object,
            default: undefined,
        },
    },
    emits: ['onClose'],
    mounted() {
        commonUtils.globalOverflowProperty('hidden');
    },
    beforeDestroy() {
        commonUtils.globalOverflowProperty('auto');
    },
};
</script>

<style lang="postcss" scoped>
.error-modal-wrapper {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(36, 49, 67, 0.9);
    padding: 64px 0;
    z-index: 900;
    overflow: auto;
    .mobile-close {
        position: absolute;
        top: 24px;
        inset-inline-end: 24px;
        @media (--viewport-tablet) {
            display:none;
        }
            .close-icon {
            fill: #A6CBFF;
            width: 24px;
            height: 24px;
        }
    }
    .error-modal {
        position: relative;
        max-width: calc(100% - 32px);
        width: 100%;
        height: auto;
        overflow: visible;
        margin: auto;
        background: var(--av-fixed-white);
        border: 1px solid var(--av-brand-light);
        box-shadow: var(--av-shadow-modal);
        border-radius: 4px;
        animation: vroi-popup 0.3s both;
        @media (--viewport-mobile-wide) {
            max-width: 417px;
        }
        @media (--viewport-tablet) {
            max-width: 566px;
        }
        .close {
            display:none;
            position: absolute;
            top: 0px;
            inset-inline-end: -32px;
            z-index: 500;
            cursor: pointer;
            .close-icon {
                fill: #A6CBFF;
                width: 24px;
                height: 24px;
            }
            @media (--viewport-tablet) {
                display:block;
            }
        }
        .wrapper {
            padding: 100px 24px;
            text-align: center;
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            overflow: auto;
            @media (--viewport-mobile-wide) {
                padding: 100px 40px;
            }
            @media (--viewport-desktop) {
                padding: 112px 40px;
            }
            .icon {
                fill: var(--av-brand-light);
                width: 60px;
                height: 60px;
                margin-bottom:16px;
            }
            .title {
                @mixin colls 12;
                @mixin lead-accent;
                color: var(--av-fixed-primary);
                margin-bottom: 8px;
            }
            .description {
                @mixin body;
                color: var(--av-fixed-primary);
                margin-bottom:24px;
            }
            .button {
                @mixin paragraph;
                padding: 8px 16px;
            }
        }
        @keyframes vroi-popup {
            0% {
                opacity:0;
            }
            100% {
                opacity:1;
            }
        }
    }
}
</style>
