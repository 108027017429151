<template>
    <s-basic-slice class="s-data-centers" v-bind="basicSliceAttrs">
        <div class="map-container">
            <div id="map" class="map">
                <div
                    v-if="!loaded"
                    v-loading="true"
                    class="loader"
                    el-loading-size="48"
                />
            </div>
            <div
                v-show="popupOpened"
                ref="popup"
                class="data-centers-popup"
                :style="popupPosition"
                :class="popupClass"
                @click.stop
            >
                <div class="content">
                    <div class="platforms">
                        <div
                            v-for="(platform, i) in popupData"
                            :key="i"
                            class="platform"
                            :class="platform.id"
                        >
                            <div class="title-wrap">
                                <a-glyph class="platform-glyph" name="data-center" />
                                <div class="title">
                                    {{ platform.title }}
                                </div>
                            </div>
                            <div class="points">
                                <div v-for="point in platform.points" :key="point.country + point.city" class="point">
                                    {{ point.country }}<span v-if="point.city" class="city">, {{ point.city }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button
                    class="close"
                    type="button"
                    aria-label="close tooltip dropdown"
                    @click="closePopup"
                >
                    <a-glyph class="glyph" name="close-16" />
                </button>
            </div>
            <div v-if="dataCenters" class="legend">
                <div v-for="(item, i) in dataCenters.platforms" :key="i" class="legend-item">
                    <div class="legend-title">
                        {{ item.title }}
                        <a-tooltip
                            v-if="item.tooltipText"
                            class="legend-tooltip"
                            :text="item.tooltipText"
                            glyph="info-circle"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div v-if="bottomLink" class="link-container">
            <a-link
                class="link"
                v-bind="bottomLink"
                :type="bottomLink.type || 'direct'"
                :glyph="bottomLink.glyph || 'arrow'"
            />
        </div>
    </s-basic-slice>
</template>

<script>
import { MarkerClusterer, SuperClusterAlgorithm } from '@googlemaps/markerclusterer';
import Loading from '@uikit/ui-kit/packages/loading/src/directive';
import { get } from 'lodash';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALink from '@core/components/link/link.vue';
import ATooltip from '@core/components/tooltip/tooltip.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';
import { GOOGLE_MAP_API_KEY } from '@model/const/api-keys.ts';

export default {
    name: 'SDataCenters',
    components: {
        SBasicSlice,
        AGlyph,
        ALink,
        ATooltip,
    },
    directives: {
        Loading,
    },
    mixins: [breakpoint],
    props: {
        /**
         * If we show title, lead and bottom link
         */
        showExtra: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            MAPS_URL: `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&loading=async&callback=GoogleMapsInit&language=en`,
            STORE_SLICE_NAME: 's-data-centers',
            map: null,
            loaded: false,
            mapStyles: [
                {
                    elementType: 'labels',
                    stylers: [
                        {
                            visibility: 'off',
                        },
                    ],
                },
                {
                    featureType: 'administrative',
                    elementType: 'geometry',
                    stylers: [
                        {
                            visibility: 'off',
                        },
                    ],
                },
                {
                    featureType: 'administrative.country',
                    elementType: 'geometry.stroke',
                    stylers: [
                        {
                            color: '#ffffff',
                        },
                        {
                            visibility: 'on',
                        },
                        {
                            weight: 1,
                        },
                    ],
                },
                {
                    featureType: 'administrative.land_parcel',
                    stylers: [
                        {
                            visibility: 'off',
                        },
                    ],
                },
                {
                    featureType: 'administrative.neighborhood',
                    stylers: [
                        {
                            visibility: 'off',
                        },
                    ],
                },
                {
                    featureType: 'landscape',
                    stylers: [
                        {
                            visibility: 'off',
                        },
                    ],
                },
                {
                    featureType: 'landscape',
                    elementType: 'geometry',
                    stylers: [
                        {
                            color: '#d5e2f4', // '#F5F8FB',
                        },
                        {
                            visibility: 'on',
                        },
                    ],
                },
                {
                    featureType: 'poi',
                    stylers: [
                        {
                            visibility: 'off',
                        },
                    ],
                },
                {
                    featureType: 'road',
                    stylers: [
                        {
                            visibility: 'off',
                        },
                    ],
                },
                {
                    featureType: 'road',
                    elementType: 'labels.icon',
                    stylers: [
                        {
                            visibility: 'off',
                        },
                    ],
                },
                {
                    featureType: 'transit',
                    stylers: [
                        {
                            visibility: 'off',
                        },
                    ],
                },
                {
                    featureType: 'water',
                    stylers: [
                        {
                            color: '#ffffff',
                        },
                    ],
                },
            ],
            popupOpened: false,
            popupPosition: {},
            popupData: [],
            popupClass: null,
            overlayView: null,
        };
    },

    computed: {
        dataCenters() {
            return get(this.$store.state.slices.items, this.STORE_SLICE_NAME, {});
        },

        // getting 'title' and 'lead' from page settings (if need to)
        basicSliceAttrs() {
            const attrs = { ...this.$attrs, title: null, lead: null };
            if (this.showExtra) {
                attrs.title = get(this.dataCenters, 'title', null);
                attrs.lead = get(this.dataCenters, 'lead', null);
            }
            return attrs;
        },

        bottomLink() {
            return this.showExtra ? get(this.dataCenters, 'link', null) : null;
        },
    },

    watch: {
        currentBreakpoint(name) {
            if (!this.map) {
                return;
            }
            const zoom = this.getZoomLevel(name);
            this.map.setZoom(zoom);
        },
    },

    mounted() {
        const mapsLoaded = new Promise((resolve) => {
            window.GoogleMapsInit = resolve;

            const GMap = document.createElement('script');
            GMap.setAttribute('src', this.MAPS_URL);
            document.body.appendChild(GMap);
        });

        mapsLoaded.then(() => {
            this.initMap();
        });
        document.addEventListener('click', this.handleDocumentClick);
        window.addEventListener('resize', this.handleWindowResize);
    },

    destroy() {
        document.removeEventListener('click', this.handleDocumentClick);
        window.removeEventListener('resize', this.handleWindowResize);
    },

    methods: {
        getZoomLevel(bp) {
            let zoom;
            switch (bp) {
                case 'desktopLarge':
                case 'desktopWide':
                    zoom = 2.18;
                    break;
                case 'desktop':
                    zoom = 1.91;
                    break;
                case 'tablet':
                    zoom = 1.46;
                    break;
                case 'mobileWide':
                    zoom = 1;
                    break;
                default:
                    zoom = 0.35;
                    break;
            }
            return zoom;
        },

        initMap() {
            /* eslint-disable no-undef */
            this.loaded = true;
            const zoom = this.getZoomLevel(this.currentBreakpoint);

            // chosen manually to make sure the needed area is in the view
            const centerPoint = new google.maps.LatLng(44, 11);

            const styledMap = new google.maps.StyledMapType(this.mapStyles, {
                name: 'Data Center Map',
            });

            this.map = new google.maps.Map(document.getElementById('map'), {
                center: centerPoint,
                zoom,
                gestureHandling: 'none',
                zoomControl: false,
                mapTypeControl: false,
                streetViewControl: false,
                fullscreenControl: false,
                isFractionalZoomEnabled: true,
                keyboardShortcuts: false,
                mapTypeControlOptions: {
                    mapTypeIds: [google.maps.MapTypeId.TERRAIN, 'custom_map_style'],
                },
            });

            this.map.mapTypes.set('custom_map_style', styledMap);
            this.map.setMapTypeId('custom_map_style');

            this.overlayView = new google.maps.OverlayView();
            this.overlayView.setMap(this.map);

            const createIcon = (platforms, hasOnlyOneItem) => {
                let positionInSprite;
                switch (platforms.length) {
                    case 4:
                        positionInSprite = 8;
                        break;
                    case 3:
                        if (platforms.includes('microsoft') && platforms.includes('acronis') && platforms.includes('cybernet')) {
                            positionInSprite = 14;
                            break;
                        }
                        if (platforms.includes('microsoft') && platforms.includes('acronis') && platforms.includes('google')) {
                            positionInSprite = 16;
                            break;
                        }
                        if (platforms.includes('microsoft') && platforms.includes('cybernet') && platforms.includes('google')) {
                            positionInSprite = 17;
                            break;
                        }
                        positionInSprite = 15; // cybernet && acronis && google
                        break;
                    case 2:
                        if (platforms.includes('microsoft') && platforms.includes('acronis')) {
                            positionInSprite = 7;
                            break;
                        }
                        if (platforms.includes('microsoft') && platforms.includes('google')) {
                            positionInSprite = 12;
                            break;
                        }
                        if (platforms.includes('microsoft') && platforms.includes('cybernet')) {
                            positionInSprite = 6;
                            break;
                        }
                        if (platforms.includes('acronis') && platforms.includes('cybernet')) {
                            positionInSprite = 13;
                            break;
                        }
                        if (platforms.includes('acronis') && platforms.includes('google')) {
                            positionInSprite = 5;
                            break;
                        }
                        positionInSprite = 19; // cybernet && google
                        break;
                    case 1:
                    default:
                        if (platforms.includes('acronis')) {
                            positionInSprite = hasOnlyOneItem ? 1 : 10;
                            break;
                        }
                        if (platforms.includes('microsoft')) {
                            positionInSprite = hasOnlyOneItem ? 2 : 11;
                            break;
                        }
                        if (platforms.includes('cybernet')) {
                            positionInSprite = hasOnlyOneItem ? 3 : 18;
                            break;
                        }
                        positionInSprite = hasOnlyOneItem ? 9 : 4; // google
                        break;
                }
                return {
                    url: '/public/assets/images/dc-markers@2.png',
                    origin: new google.maps.Point(40 * (positionInSprite - 1), 0),
                    size: new google.maps.Size(40, 40),
                    scaledSize: new google.maps.Size(760, 40),
                    anchor: new google.maps.Point(20, 20),
                };
            };

            const markers = this.dataCenters.points.map((point) => {
                const icon = createIcon([point.platform], true);
                const marker = new google.maps.Marker({
                    position: new google.maps.LatLng(point.coords[0], point.coords[1]),
                    map: this.map,
                    icon,
                    city: point.city,
                    country: point.country,
                    platform: point.platform,
                });
                marker.addListener('click', (event) => {
                    this.onMarkerClick(event, marker);
                });
                return marker;
            });

            // eslint-disable-next-line no-new
            new MarkerClusterer({
                map: this.map,
                markers,
                onClusterClick: this.onClusterClick,
                renderer: {
                    render: ({ markers: _markers, _position: position }) => {
                        const platforms = [...new Set(_markers.map((marker) => marker.platform))];
                        const icon = createIcon(platforms, false);
                        return new google.maps.Marker({
                            position: {
                                lat: position.lat(),
                                lng: position.lng(),
                            },
                            icon,
                            label: {
                                text: String(_markers.length),
                                color: '#243143',
                                fontSize: '12px',
                                fontFamily: 'Acronis Cyber',
                                fontWeight: '700',
                            },
                        });
                    },
                },
                algorithm: new SuperClusterAlgorithm({
                    radius: 80,
                }),
            });

            document.map = this.map;
            /* eslint-enable no-undef */
        },

        openPopup(coordinates, markers) {
            const projection = this.overlayView.getProjection();
            const position = projection.fromLatLngToContainerPixel(coordinates);

            if (this.isMobile) {
                this.popupPosition = null;
            } else {
                this.popupPosition = {
                    left: `${position.x}px`,
                    top: `${position.y}px`,
                };
            }
            this.popupData = this.getPopupData(markers);
            this.popupOpened = true;
            this.checkPopupDirection();
        },

        closePopup() {
            this.popupData = null;
            this.popupOpened = false;
        },

        onMarkerClick(event, marker) {
            if (event.domEvent.type === 'click') {
                event.domEvent.stopImmediatePropagation();
            }
            const coordinates = marker.getPosition();
            this.openPopup(coordinates, [marker]);
        },

        onClusterClick(event, cluster) {
            if (event.domEvent.type === 'click') {
                event.domEvent.stopImmediatePropagation();
            }
            const coordinates = cluster.position;
            this.openPopup(coordinates, cluster.markers);
        },

        getPopupData(markers) {
            return this.dataCenters.platforms
                .map((platform) => {
                    const title = platform.title;
                    const id = platform.id;
                    const points = markers
                        .filter((marker) => marker.platform === id)
                        .map((marker) => ({
                            city: marker.city,
                            country: marker.country,
                        }))
                        .sort((a, b) => String(a.country).localeCompare(b.country));

                    return { id, title, points };
                })
                .filter((platform) => platform.points.length);
        },

        checkPopupDirection() {
            this.popupClass = null;
            this.$nextTick(() => {
                const popup = this.$refs.popup;
                const windowWidth = document.documentElement.clientWidth;
                const popupBounds = popup.getBoundingClientRect().left + popup.offsetWidth;
                this.popupClass = popupBounds > windowWidth ? 'direction-left' : null;
            });
        },

        requestDataCenters() {
            this.$store.dispatch('slices/getSyncedData', { slice: this.STORE_SLICE_NAME, locale: this.$route.params.locale });
        },

        handleDocumentClick(event) {
            if (!event.target.closest('.data-centers-popup')) {
                this.closePopup();
            }
        },

        handleWindowResize() {
            this.closePopup();
        },
    },

    serverPrefetch() {
        this.requestDataCenters();
    },
};
</script>

<style lang="postcss" scoped>
.s-data-centers {
    &.no-container {
        padding-top: 0;

        &:deep(.a-container) {
            padding: 0;
        }
    }

    &:deep(.a-slice-header) {
        margin-bottom: 24px;

        @media (--viewport-tablet) {
            margin: 0 auto 56px;
            width: 83.33%;
        }

        @media (--viewport-desktop-wide) {
            margin: 0 auto 48px;
        }

        &__title {
            margin-bottom: 24px;
            text-align: center;

            @media (--viewport-mobile-wide) {
                width: 100%;
            }
        }

        &__lead {
            text-align: center;
        }
    }
}

.map-container {
    position: relative;
    background: var(--av-fixed-invisible);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 200px;
    height: 235px;
    width: 328px;

    @media (--viewport-mobile-wide) {
        height: 364px;
        width: 512px;
    }

    @media (--viewport-tablet) {
        margin-bottom: 120px;
        height: 512px;
        width: 704px;
    }

    @media (--viewport-desktop) {
        height: 680px;
        width: 960px;
        margin-bottom: 64px;
    }

    @media (--viewport-desktop-wide) {
        height: 800px;
        width: 1152px;
    }
}

.map {
    width: 100%;
    height: 100%;
}

.loader {
    position: absolute;
    inset-inline-start: 0;
    top: 0;
    inset-inline-end: 0;
    bottom: 0;
    margin: auto;
    height: 48px;
    width: 48px;
}

.data-centers-popup {
    position: absolute;
    z-index: 10;
    min-width: 280px;
    max-width: 320px;
    box-sizing: border-box;
    border: var(--av-border);
    border-radius: 4px;
    box-shadow: 0 10px 20px var(--av-fixed-lightest);
    background-color: var(--av-fixed-white);
    inset-inline-start: 0;
    inset-inline-end: 0;
    margin: auto;

    &.direction-left {
        transform: translateX(-100%);
    }

    @media (--viewport-tablet) {
        inset-inline-end: auto;
    }
}

.content {
    padding: 32px 24px 32px;
    max-height: 420px;
    overflow: auto;
}

.platform {
    &:not(:last-of-type) {
        padding-bottom: 24px;
        border-bottom: var(--av-border);
        margin-bottom: 24px;
    }

    &.acronis {
        .platform-glyph {
            fill: var(--av-nav-primary);
            color: var(--av-nav-primary);
        }
    }

    &.cybernet {
        .platform-glyph {
            fill: var(--av-web-fixed-success-dark);
            color: var(--av-web-fixed-success-dark);
        }
    }

    &.google {
        .platform-glyph {
            fill: #dc4b3d;
            color: #dc4b3d;
        }
    }

    &.microsoft {
        .platform-glyph {
            fill: #00bbf5;
            color: #00bbf5;
        }
    }
}

.title-wrap {
    position: relative;
    margin-bottom: 16px;
}

.platform-glyph {
    position: absolute;
    height: 24px;
    width: 24px;
}

.title {
    @mixin paragraph-accent;

    padding-inline-start: 32px;
    color: var(--av-nav-primary);
}

.point {
    @mixin paragraph-accent;

    color: var(--av-fixed-secondary);

    &:not(:last-of-type) {
        margin-bottom: 8px;
    }

    .city {
        font-weight: 400;
    }
}

.close {
    outline: none;
    -webkit-tap-highlight-color: transparent;
    position: absolute;
    top: 16px;
    inset-inline-end: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    padding: 0;
    border: 0;
    background-color: transparent;
    cursor: pointer;

    .glyph {
        width: 10px;
        height: 10px;
    }
}

.legend {
    top: 100%;
    margin-top: 40px;
    position: absolute;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;

    @media (--viewport-tablet) {
        width: fit-content;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 16px;
    }

    @media (--viewport-desktop) {
        display: flex;
        width: auto;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
}

.legend-item {
    margin-bottom: 16px;
    position: relative;
    padding-inline-start: 22px;

    @media (--viewport-tablet) {
        margin: 0 16px;
        width: auto;
        white-space: nowrap;
    }

    @media (--viewport-desktop) {
        margin: 0 28px;
    }

    &::before {
        content: '';
        position: absolute;
        top: 5px;
        inset-inline-start: 0;
        width: 14px;
        height: 14px;
        border-radius: 50%;
    }

    &:nth-child(1)::before {
        background-color: var(--av-nav-primary);
    }

    &:nth-child(2)::before {
        background-color: #7c9b1e;
    }

    &:nth-child(3)::before {
        background-color: #d74235;
    }

    &:nth-child(4)::before {
        background-color: #01b3f3;
    }
}

.legend-title {
    @mixin paragraph;

    display: inline-block;
    padding-top: 0;
}

.legend-tooltip {
    margin-inline-start: 4px;
    position: relative;
    top: 3px;

    @media (--viewport-desktop) {
        margin-inline-start: 8px;
    }

    &:deep(.a-glyph) {
        fill: var(--av-fixed-lighter);

        &:hover {
            fill: var(--av-fixed-light);
        }
    }
}

.link-container {
    display: flex;
    justify-content: center;
    padding-top: 72px;

    @media (--viewport-mobile-wide) {
        padding-top: 64px;
    }
}

.map:deep(img) {
    max-block-size: none;
    max-inline-size: none;
    pointer-events: none;
}
</style>
