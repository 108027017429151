import {
    RECAPTCHA_SCRIPT_SRC,
    RECAPTCHA_ENTERPRISE_INVISIBLE_KEY,
} from '@model/const/api-keys.ts';

export default {
    data() {
        return {
            captchaError: false,
            recaptchaResponse: '',
            recaptchaWidgetId: null,
        };
    },

    computed: {
        hasRecaptchaInvisible() {
            const rc = this.content?.recaptcha || this.form?.registration?.recaptcha || {};
            return rc.isActive && rc.isInvisible;
        },

        recaptchaAction() {
            if (this.form?.form_id !== 'events_hub_main') return '';

            const result = ['utm_medium', 'utm_source', 'utm_term', 'utm_campaign']
                .filter((key) => window.sessionStorage.getItem(key))
                .map((key) => `${window.sessionStorage.getItem(key)}`)
                .join('/');

            return `form_events/${result}`.slice(0, 100).replace(/[^A-Za-z/_]/g, '_');
        },
    },

    methods: {
        recaptchaActive() {
            return window.grecaptcha.enterprise || window.grecaptcha;
        },

        recaptchaInject() {
            const callbackUniqueName = `gr_callback_${Math.random().toString().replace('.', '_')}`;
            window[callbackUniqueName] = this.recaptchaRender;

            const script = document.createElement('script');
            script.src = `${RECAPTCHA_SCRIPT_SRC}&onload=${callbackUniqueName}`;
            document.body.appendChild(script);
        },

        recaptchaRender() {
            this.recaptchaWidgetId = this.recaptchaActive().render(this.$refs.recaptcha, {
                sitekey: RECAPTCHA_ENTERPRISE_INVISIBLE_KEY,
                action: this.recaptchaAction,
                callback: this.recaptchaCallback,
                size: 'invisible',
            });
        },

        recaptchaCallback(response) {
            this.recaptchaResponse = response;
        },
    },
};
