<template>
    <button
        class="express-signup-question__button"
        :class="className"
        :disabled="disabled"
        @click="handleClick"
    >
        <span v-if="number || number === 0" class="express-signup-question__button__number">{{ number }}</span>
        <span v-if="text" class="express-signup-question__button__text">{{ text }}</span>
        <div v-if="hasCheckbox" class="express-signup-question__button__checkbox" :class="{ checked }">
            <a-glyph class="express-signup-question__button__checkbox__icon" name="check-square" />
        </div>
    </button>
</template>

<script>
import AGlyph from '@core/components/glyph/glyph.vue';

export default {
    name: 'ExpressSignupQuestionButton',
    components: {
        AGlyph,
    },
    props: {
        type: {
            type: String,
            default: 'main',
            validator(value) {
                return ['main', 'action'].includes(value);
            },
        },
        id: {
            type: Number,
            default: undefined,
        },
        number: {
            type: Number,
            default: undefined,
        },
        text: {
            type: String,
            default: '',
        },
        questionId: {
            type: Number,
            default: undefined,
        },
        hasCheckbox: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['toggleChecked', 'click'],
    data: () => ({
        checked: false,
    }),
    computed: {
        enabledCookies() {
            return navigator.cookieEnabled;
        },
        className() {
            const { id, type, disabled, hasCheckbox } = this;
            return {
                [`express-signup-question__button__${type}`]: type,
                [`express-signup-question__button__${id || '0'}`]: id || '0',
                'express-signup-question__button__disabled': disabled,
                'express-signup-question__button__hasCheckbox': hasCheckbox,
            };
        },
    },
    async mounted() {
        await this.$nextTick();

        if (!this.enabledCookies) return;
        this.checkAnswers();
    },
    methods: {
        checkAnswers() {
            const item = localStorage.getItem(`acronis_express_signup_${this.questionId}_answers`);
            if (!item) return;

            const checkedAnswers = JSON.parse(item);

            if (checkedAnswers.answers.find((elem) => elem.id === this.id)) {
                this.checked = true;
                this.$emit('toggleChecked', this.checked);
            }
        },
        handleClick(event) {
            if (!this.hasCheckbox) {
                return this.$emit('click', event);
            }

            this.checked = !this.checked;
            return this.$emit('toggleChecked', this.checked);
        },
    },
};
</script>

<style lang="postcss" scoped>
.express-signup-question__button {
    display: flex;
    padding: 8px 8px;
    align-items: center;
    border-radius: 4px;
    width: 100%;
    min-height: 40px;
    margin: 0 auto 8px;
    cursor: pointer;
    transition: all 0.2s linear;

    @media (--viewport-desktop) {
        margin: 0 0 8px;
    }

    &:last-child {
        margin-bottom: 0;
    }
    &__number {
        color: var(--av-fixed-white);
        border-radius: 2px;
        flex-basis: 20px;
        height: 20px;
        font-size: 10px;
        line-height: 20px;
        text-align: center;
        font-weight: 600;
        margin-inline-end: 8px;
        transition: all 0.2s ease-in;
    }
    &__text {
        @mixin body;
        line-height: 18px;
        flex-basis: calc(100% - 28px);
        transition: all 0.2s ease-in;
    }
    &__checkbox {
        border-radius: 4px;
        height: 16px;
        margin-inline-start: auto;
        width: 16px;
        position: relative;
        border: 1px solid var(--av-brand-secondary-light);
        transition: all 0.2s;
        &__icon {
            fill: var(--av-brand-secondary-light);
            max-width: 100%;
            max-height: 100%;
            position: absolute;
            inset-inline-start: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            transition: all 0.2s;
        }
        &.checked {
            border: 1px solid var(--av-nav-secondary);
            .express-signup-question__button__checkbox__icon {
                fill: var(--av-nav-secondary);
            }
        }
    }

    &__main {
        color: var(--av-nav-secondary);
        background: linear-gradient(0deg, var(--av-brand-accent), var(--av-brand-accent)),
            var(--av-fixed-white);
        border: 1px solid var(--av-brand-light);

        .express-signup-question__button__number {
            background: var(--av-nav-secondary);
        }
        &:hover {
            color: var(--av-brand-primary);
            background: linear-gradient(
                    0deg,
                    var(--av-brand-secondary-accent),
                    var(--av-brand-secondary-accent)
                ),
                var(--av-fixed-white);

            border: 1px solid var(--av-nav-secondary);
        }
        &:active,
        &:disabled {
            color: var(--av-brand-primary);
            background: linear-gradient(
                    0deg,
                    var(--av-brand-secondary-light),
                    var(--av-brand-secondary-light)
                ),
                var(--av-fixed-white);
            border: 1px solid var(--av-brand-primary);
        }
        &:disabled,
        .express-signup-question__button__disabled {
            opacity: 0.5;
        }
        &:hover,
        &:active,
        &:disabled {
            .express-signup-question__button__number {
                background: #0056c1;
            }
        }
    }

    &__action {
        color: var(--av-fixed-white);
        background: var(--av-fixed-success);
        border: 1px solid var(--av-fixed-success-dark);
        .express-signup-question__button__number {
            background: var(--av-fixed-success-dark);
        }
        &:hover {
            background: #8bac27;
            border: 1px solid var(--av-fixed-success-dark);
            .express-signup-question__button__number {
                background: #528419;
            }
        }
        &:active,
        &:disabled {
            background: #7ba400;
            border: 1px solid var(--av-fixed-success-dark);
            .express-signup-question__button__number {
                background: #467d07;
            }
        }
        &:disabled,
        .express-signup-question__button__disabled {
            opacity: 0.5;
            .express-signup-question__button__number {
                background: #467d07 !important;
            }
        }
    }
    &__hasCheckbox {
        .express-signup-question__button__text {
            padding-inline-end: 8px;
        }
    }
}
[dir="rtl"] {
    .express-signup-question__button__checkbox__icon {
        inset-inline-start: unset;
        inset-inline-end: 50%;
    }
}
</style>
