<template>
    <div class="blog-page blog-search">
        <s-locale-selector-ribbon :is-shown="isLocaleSelectorShown" />
        <div class="main-content">
            <s-main-header v-bind="sliceDataHeader" />
            <blog-container class="intro">
                <blog-head :title="translations.homeTitle" v-bind="sectionLink" />
                <blog-bcrumbs v-bind="bcrumbs" />
            </blog-container>
            <blog-container class="filters">
                <blog-filters
                    :title="translations.searchTitle"
                    :audiences="audiences"
                    :types-of-assets="assets"
                    :products="products"
                    :categories="categories"
                    :audience-label="translations.audience"
                    :all-label="translations.allLabel"
                    :type-of-asset-label="translations.typeOfAsset"
                    :products-label="translations.products"
                    :categories-label="translations.categories"
                    :show-stories-label="translations.showStories"
                    :results-title="translations.results"
                    :reset-filters-label="translations.resetFilters"
                    :no-results-title="translations.couldntFindResults"
                    :clear-filters-caption="translations.tryToClearFilters"
                    :recently-published-label="translations.recentlyPublished"
                    :search-results-title="translations.results"
                    :is-results-loading="isXHRInProgress"
                    :sorting-options="sortingOptions"
                    :has-results="searchResults.length > 0"
                    @updateFilterParams="updateFilterParams"
                    @resetFilterParams="resetFilterParams"
                    @sortingChanged="applySorting"
                >
                    <template #searchResults>
                        <blog-list
                            class="search-results-list"
                            :cards="searchResults"
                            load-more-type="pagination"
                            :is-loading="isXHRInProgress"
                            card-layout="row"
                        />
                    </template>
                    <template #latestCards>
                        <blog-list :cards="latestCards" card-layout="default" />
                    </template>
                </blog-filters>
                <blog-tag-list v-if="!filtersApplied" :title="translations.browseTags" :tags="allTags" />
            </blog-container>
            <div v-if="searchResultsTotalPages > 1" class="pagination">
                <blog-pager
                    :current-page="searchResultsCurrentPage"
                    :total-pages="searchResultsTotalPages"
                    @pageSelected="goToPage"
                />
            </div>
        </div>
        <s-global-footer class="footer" v-bind="sliceDataFooter" />
    </div>
</template>

<script>
import analytics from '@core/mixins/analytics.js';
import blog from '@core/mixins/blog.js';
import localeRibbon from '@core/mixins/locale-ribbon.js';
import BlogBcrumbs from '@core/slices/blog/blog-bcrumbs/blog-bcrumbs.vue';
import BlogFilters from '@core/slices/blog/blog-filters/blog-filters.vue';
import BlogHead from '@core/slices/blog/blog-head/blog-head.vue';
import BlogTagList from '@core/slices/blog/blog-tag-list/blog-tag-list.vue';
import BlogContainer from '@core/slices/blog/shared-components/blog-container/blog-container.vue';
import BlogList from '@core/slices/blog/shared-components/blog-list/blog-list.vue';
import BlogPager from '@core/slices/blog/shared-components/blog-pager/blog-pager.vue';
import SGlobalFooter from '@core/slices/pages/global-footer/global-footer.vue';
import SLocaleSelectorRibbon from '@core/slices/pages/locale-selector-ribbon/locale-selector-ribbon.vue';
import SMainHeader from '@core/slices/pages/main-header/main-header.vue';
import { SECTION_ID_BLOG, SECTION_ID_CPC, SECTION_SLUG_BY_ID } from '@model/const/blog.ts';
import '@core/styles/sections/blog.pcss';

const AUDIENCES_NAME_MAP = {
    1: 'Service Providers',
    2: 'Home Users',
    3: 'Business of all sizes',
    4: 'Other',
    5: 'Any',
};

export default {
    name: 'BlogSearchComponent',

    components: {
        SGlobalFooter,
        SMainHeader,
        BlogHead,
        BlogBcrumbs,
        BlogFilters,
        BlogTagList,
        BlogContainer,
        BlogList,
        BlogPager,
        SLocaleSelectorRibbon,
    },

    mixins: [blog, analytics, localeRibbon],

    props: {
        dispatchDeleteCards: {
            type: Function,
            required: true,
        },
        dispatchGoToPage: {
            type: Function,
            required: true,
        },
    },

    data() {
        return {
            searchResultsCurrentPage: 0,
            filter: {
                audienceId: null,
                assetId: null,
                productId: null,
                categoryId: null,
            },
            sortingBy: 'recent',
            filtersApplied: false,
            isXHRInProgress: false,
        };
    },

    computed: {
        sliceDataHeader() {
            return {
                ...this.$store.state.slices.items['s-main-header'],
                theme: 'light',
            };
        },

        sliceDataFooter() {
            return {
                ...this.$store.state.slices.items['s-global-footer'],
                type: 'short',
                theme: 'light',
            };
        },

        translations() {
            return this.$store.state.slices.items.blog || {};
        },

        localeHasCPC() {
            return !!this.$store.state.locales.locale?.is_active_on_blog_cpc;
        },

        sectionLink() {
            if (!this.localeHasCPC) {
                return null;
            }

            return {
                sectionLink: `/${SECTION_SLUG_BY_ID[SECTION_ID_CPC]}/`,
                sectionTitle: this.translations.cpcTitle,
                sectionText: this.translations.cpcLead,
            };
        },

        bcrumbs() {
            const blogItem = {
                to: '/blog/',
                title: this.translations.homeTitle,
            };
            const bcrumbs = [
                blogItem,
                {
                    title: this.translations.browseAllStories,
                },
            ];

            return {
                theme: 'light',
                bcrumbs,
                mobileLink: blogItem,
            };
        },

        assets() {
            return this.$store.state.blog.assets.items || [];
        },

        audiences() {
            const list = this.$store.state.blog.audiences.items || [];
            return list.map((audience) => ({ id: audience.id, name: AUDIENCES_NAME_MAP[audience.id] }));
        },

        products() {
            return Object.values(this.$store.state?.products?.items || []).filter((product) => !product.is_virtual);
        },

        categories() {
            const allCategories = this.$store.state.blog.categories.items;

            if (!allCategories) {
                return [];
            }

            const blogCategories = allCategories.filter((item) => item.section.id === SECTION_ID_BLOG);

            const categories = [{
                value: this.translations.acronisBlog,
                label: this.translations.acronisBlog,
                children: blogCategories.map((item) => ({
                    label: item.title,
                    value: item.id,
                })),
            }];

            if (this.localeHasCPC) {
                const CPCCategories = allCategories.filter((item) => item.section.id === SECTION_ID_CPC);

                categories.push({
                    value: this.translations.cpcTitle,
                    label: this.translations.cpcTitle,
                    children: CPCCategories.map((item) => ({
                        label: item.title,
                        value: item.id,
                    })),
                });
            }

            return categories;
        },

        sortingOptions() {
            return [
                {
                    name: this.translations.recent,
                    value: 'recent',
                },
                {
                    name: this.translations.popular,
                    value: 'popular',
                },
            ];
        },

        searchResults() {
            const cards = this.$store.state.blog.cards.items || [];
            return cards.map((card) => this.getBlogPostFields(card, this.translations));
        },

        searchResultsTotalPages() {
            return this.$store.state.blog.cards.pagination?.pages_total || 1;
        },

        latestCards() {
            const cards = this.$store.state.blog.latestCards.items || [];
            return cards.map((card) => this.getBlogPostFields(card, this.translations));
        },

        allTags() {
            const tags = this.$store.state.blog.tags.items || [];

            return tags.map((tag) => ({
                ...tag,
                link: `/blog/tags/${tag.slug}`,
            }));
        },
    },

    methods: {
        updateFilterParams(newParams) {
            this.filter.audienceId = newParams.audienceId;
            this.filter.assetId = newParams.assetId;
            this.filter.productId = newParams.productId;
            this.filter.categoryId = newParams.categoryId;
            this.filtersApplied = true;
            this.goToPage(1);
        },

        applySorting(selectedSorting) {
            this.sortingBy = selectedSorting;
            this.goToPage(1);
        },

        resetFilterParams() {
            this.filtersApplied = false;
            this.dispatchDeleteCards();
        },

        async goToPage(page) {
            this.isXHRInProgress = true;
            const res = await this.dispatchGoToPage(page, this.sortingBy, this.filter);
            this.isXHRInProgress = false;

            if (res) {
                this.searchResultsCurrentPage = page;
            }
        },
    },
};
</script>

<style lang="postcss" scoped>
.blog-search {
    .intro {
        border-top: 2px solid var(--av-brand-secondary-light);
    }

    .filters {
        margin-bottom: 40px;

        @media (--viewport-tablet) {
            margin-bottom: 80px;
        }
    }

    .pagination {
        margin-bottom: 104px;
    }
}
</style>
