<template>
    <div class="blog-news-search">
        <div class="filters">
            <div v-if="newsArchiveTitle" class="news-title">
                {{ newsArchiveTitle }}
            </div>
            <div class="filter-label">
                {{ filterNewsBy }}
            </div>
            <el-select
                v-model="yearFilter"
                class="filter-select"
                :label="yearLabel"
                :hide-on-resize="isDesktop"
                popper-class="blog-dropdown filter-select-dropdown"
                :class="{ 'form-error': yearError }"
            >
                <el-option :value="selectAll" />
                <el-option
                    v-for="item in years"
                    :key="item"
                    :label="item"
                    :value="item"
                />
            </el-select>
            <el-select
                v-model="monthFilter"
                class="filter-select"
                :label="monthLabel"
                :hide-on-resize="isDesktop"
                popper-class="blog-dropdown filter-select-dropdown"
            >
                <el-option :value="selectAll" />
                <el-option
                    v-for="(item, i) in months"
                    :key="item"
                    :label="item"
                    :value="i + 1"
                />
            </el-select>
            <a-button
                class="filter-button"
                type="main"
                :text="filterNews"
                @click="applyFilters"
            />
        </div>
        <div class="news">
            <div class="title">
                {{ title }}
            </div>
            <div v-if="$slots.searchResults" class="results">
                <slot name="searchResults" />
            </div>
            <div v-else class="no-results">
                {{ noNewsFound }}
            </div>
            <div
                v-if="isLoading"
                v-loading="true"
                class="loading"
                el-loading-size="48"
            />
        </div>
    </div>
</template>

<script>
import Loading from '@uikit/ui-kit/packages/loading/src/directive';
import AButton from '@core/components/button/button.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import commonUtils from '@utils/common';

export default {
    name: 'BlogNewsSearch',
    components: {
        ElSelect: () => import('@uikit/ui-kit/packages/select'),
        ElOption: () => import('@uikit/ui-kit/packages/option'),
        AButton,
    },
    directives: {
        Loading,
    },
    mixins: [breakpoint],
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },

        noNewsFound: {
            type: String,
            required: true,
        },

        newsArchiveTitle: {
            type: String,
            default: null,
        },
        filterNewsBy: {
            type: String,
            required: true,
        },

        followUsOn: {
            type: String,
            required: true,
        },

        yearLabel: {
            type: String,
            required: true,
        },

        monthLabel: {
            type: String,
            required: true,
        },

        filterNews: {
            type: String,
            required: true,
        },

        selectAll: {
            type: String,
            required: true,
        },

        title: {
            type: String,
            required: true,
        },

        years: {
            type: Array,
            required: true,
        },
    },
    emits: ['updateFilterParams'],
    data() {
        return {
            yearFilter: this.selectAll,
            monthFilter: this.selectAll,
            yearError: false,
            months: commonUtils.getArrayOfMonths(),
        };
    },
    methods: {
        applyFilters() {
            this.yearError = false;

            const year = this.yearFilter === this.selectAll ? null : this.yearFilter;
            const month = this.monthFilter === this.selectAll ? null : this.monthFilter;

            if (month && !year) {
                this.yearError = true;
                return;
            }

            this.$emit('updateFilterParams', { year, month });
        },
    },
};
</script>

<style lang="postcss" scoped>
.blog-news-search {
    @media (--viewport-tablet) {
        display: grid;
        column-gap: 16px;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    .news-title {
        @mixin colls 12;
        @mixin display-accent;
        text-align: center;
        display: none;
        margin-bottom: 24px;
        @media (--viewport-desktop-large) {
            @mixin hero-accent;
        }
    }

    .filters {
        margin-bottom: 48px;

        @media (--viewport-tablet) {
            margin-bottom: 0;
            padding-inline-end: 16px;
        }
    }

    .filter-label {
        @mixin lead;

        margin-bottom: 16px;
        text-align: center;
        color: var(--av-nav-primary);

        @media (--viewport-tablet) {
            text-align: start;
        }
    }

    .filter-select {
        margin-bottom: 16px;
        background: var(--av-fixed-white);
        color: var(--av-fixed-secondary);
        border-radius: 4px;
        height: 46px;
    }

    .filter-button {
        margin-top: 8px;
        height: 48px;
        width: 100%;
        border-radius: 4px;
        background: var(--av-brand-primary);
    }

    .news {
        position: relative;
        @media (--viewport-tablet) {
            grid-column-start: 2;
            grid-column-end: 5;
        }
    }

    .loading {
        position: absolute;
        inset-inline-start: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.5);
    }

    .title {
        @mixin display;

        margin-bottom: 64px;
        text-align: center;
        color: var(--av-nav-primary);

        @media (--viewport-tablet) {
            @mixin hero;

            margin-bottom: 24px;
            text-align: start;
        }
    }
}
</style>

<style lang="postcss">
.blog-news-search {
    .filter-select {
        &.form-error {
            .el-input__container {
                border-color: var(--av-fixed-danger);
            }
        }

        .el-input {
            height: 100%;

            &__container {
                height: 100%;
                padding: 0;
                padding-inline-end: 9px;
                padding-inline-start: 7px;
            }

            &__icon {
                color: var(--av-brand-primary) !important;
            }

            &__placeholder {
                @mixin body;

                color: var(--av-fixed-light);
            }

            &__editor--absolute-position {
                @mixin body;
                color: var(--av-nav-primary);
            }

            &__label.is-active {
                color: var(--av-fixed-light);
                padding-top: 3px;
            }
        }

        .el-input.is-active:not(.el-input--small).el-input--has-label .el-input__wrapper {
            padding-top: 19px;
        }
    }
}
</style>
