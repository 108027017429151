<template>
    <div class="a-submenu" :class="className">
        <div class="a-container">
            <div ref="submenu" class="a-submenu__container">
                <div ref="content" class="a-submenu__left">
                    <div
                        ref="heading"
                        class="a-submenu__heading"
                        :class="{ 'a-submenu__heading_visible': showProductName }"
                    >
                        <a-logo
                            v-if="!isCustom"
                            class="a-submenu__logo"
                            type="full"
                            :fill="isLight ? undefined : 'white'"
                        />
                        <div v-else class="a-submenu__caption">
                            {{ caption }}
                        </div>
                        <span v-if="!isCustom" class="a-submenu__name">{{ caption || productName }}</span>
                    </div>
                    <ul v-if="menuExists" ref="childPages" class="a-submenu__list">
                        <li v-for="item in childPages" :key="item.to" class="a-submenu__item">
                            <a-link
                                v-if="item.to"
                                class="a-submenu__link"
                                v-bind="item"
                                :class="{ 'nuxt-link-exact-active': isCurrentLocation(item.to) }"
                                :event="getLinkEvent(item, 'Interactions')"
                                :type="isLight ? 'regular' : 'light'"
                                size="paragraph"
                            />
                            <div v-if="item.sublinks" class="a-submenu__sublist-container">
                                <a-link
                                    class="a-submenu__sublist-toggler"
                                    v-bind="item"
                                    :type="isLight ? 'regular' : 'light'"
                                    :glyph="item.glyph || 'drop-down'"
                                    :size="item.size || 'paragraph'"
                                    @click="showSublist"
                                />
                                <ul ref="sublist" class="a-submenu__sublist" @mouseleave="hideSublist">
                                    <li v-for="sublink in item.sublinks" :key="sublink.to" class="a-submenu__subitem">
                                        <a-link
                                            v-if="sublink.to"
                                            v-bind="sublink"
                                            class="a-submenu__sublink"
                                            :class="{ 'nuxt-link-exact-active': isCurrentLocation(sublink.to) }"
                                            :event="getLinkEvent(sublink, 'Interactions')"
                                            size="paragraph"
                                        />
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                    <a-dropdown
                        id="a-submenu__dropdown"
                        ref="dropdown"
                        class="a-submenu__dropdown"
                        :link-text="menu"
                    >
                        <div class="a-submenu__dropdown-heading">
                            <a-logo v-if="!isCustom" class="a-submenu__dropdown-logo" type="full" />
                            <div v-else class="a-submenu__dropdown-caption">
                                {{ caption }}
                            </div>
                            <div v-if="!isCustom" class="a-submenu__dropdown-name">
                                {{ caption || productName }}
                            </div>
                        </div>
                        <ul v-if="menuExists" class="a-submenu__dropdown-list">
                            <li v-for="item in dropdownChildPages" :key="item.to" class="a-submenu__dropdown-item">
                                <a-link
                                    class="a-submenu__dropdown-link"
                                    v-bind="item"
                                    :event="getLinkEvent(item)"
                                    :type="isLight ? 'regular' : 'light'"
                                    size="paragraph"
                                />
                            </li>
                        </ul>
                        <a-link
                            v-if="secondActionInDropdown"
                            class="a-submenu__dropdown-second-action"
                            :glyph="!notGlyph && buttons[0].glyph ? buttons[0].glyph : ''"
                            v-bind="buttons[0]"
                            :event="getSecondActionEvent(buttons[0])"
                        />
                    </a-dropdown>
                    <a-link
                        v-if="secondActionExists"
                        v-bind="buttons[0]"
                        class="a-submenu__mobile-second-action"
                        :glyph="!notGlyph && buttons[0].glyph ? buttons[0].glyph : ''"
                        :event="getSecondActionEvent(buttons[0])"
                        :type="isLight ? 'regular' : 'light'"
                        size="paragraph"
                    />
                </div>
                <div ref="action" class="a-submenu__right">
                    <a-link
                        v-if="secondActionExists"
                        v-bind="buttons[0]"
                        ref="secondAction"
                        class="a-submenu__second-action"
                        :glyph="!notGlyph && buttons[0].glyph ? buttons[0].glyph : ''"
                        :event="getSecondActionEvent(buttons[0])"
                        :type="isLight ? 'regular' : 'light'"
                        size="paragraph"
                    />
                    <a-button
                        v-if="mainActionExists"
                        v-bind="buttons[btnOrder]"
                        ref="mainAction"
                        class="a-submenu__main-action"
                        :glyph="!notGlyph && buttons[btnOrder].glyph ? buttons[btnOrder].glyph : ''"
                        type="action"
                        size="s"
                        :event="mainActionEvent"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import ADropdown from '@core/components/dropdown/dropdown.vue';
import ALink from '@core/components/link/link.vue';
import ALogo from '@core/components/logo/logo.vue';

const sublistClassContainer = 'a-submenu__sublist-container';
const sublistClass = 'a-submenu__sublist-toggler';
const sublistClassActive = `${sublistClass}_active`;

export default {
    name: 'ASubMenu',

    components: {
        ALogo,
        ALink,
        AButton,
        ADropdown,
    },

    props: {
        /**
         * If GA event should be sent
         */
        sendEvent: {
            type: Boolean,
            default: false,
        },

        /**
         * Custom Text
         */
        caption: {
            type: String,
            default: '',
        },

        /**
         * Product name
         */
        productName: {
            type: String,
            default: '',
        },

        /**
         * Links
         */
        childPages: {
            type: Array,
            default: () => [],
        },

        buttons: {
            type: Array,
            default: () => [],
        },

        menu: {
            type: String,
            default: () => 'Menu',
        },

        /**
         * GA Event
         */
        event: {
            type: Object,
            default() {
                return {
                    category: 'Conversions',
                    location: 'Top CTA',
                };
            },
        },
        notGlyph: {
            type: Boolean,
            default: true,
        },

        isLight: {
            type: Boolean,
            default: false,
        },

        isCustom: {
            type: Boolean,
            default: false,
        },

        /**
         * If always visible
         */
        alwaysVisible: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        const btnLength = this.buttons.length > 1;
        return {
            showDropdown: false,
            showProductName: false,
            secondActionInDropdown: false,
            secondActionInLeft: false,
            isLoad: false,
            mainActionExists: this.buttons.length,
            secondActionExists: btnLength,
            btnOrder: btnLength ? 1 : 0,
            menuExists: this.childPages.length > 1,
            COLUMN_MARGIN: 16,
        };
    },

    computed: {
        dropdownChildPages() {
            let temp = [];

            this.childPages.forEach((item) => {
                if (item.sublinks) {
                    temp = temp.concat(item.sublinks);
                } else {
                    temp.push(item);
                }
            });

            return temp;
        },

        className() {
            return {
                'a-submenu_not-loaded': !this.isLoad,
                'a-submenu_light': this.isLight,
                'a-submenu_with-dropdown': this.showDropdown,
                'a-submenu_second-action-in-dropdown': this.secondActionInDropdown,
                'a-submenu_second-action-in-left': this.secondActionInLeft,
                'a-submenu_always-visible': this.alwaysVisible,
            };
        },

        mainActionEvent() {
            return { content: this.productName };
        },
    },

    mounted() {
        if (document.readyState === 'complete') {
            this.load();
        } else {
            window.addEventListener('load', this.load);
        }

        window.addEventListener('scroll', this.calculate);
        window.addEventListener('resize', this.calculate);
    },

    beforeDestroy() {
        window.removeEventListener('load', this.load);
        window.removeEventListener('scroll', this.calculate);
        window.removeEventListener('resize', this.calculate);
    },

    methods: {
        load() {
            this.$nextTick(() => {
                const { childPages, heading, mainAction, secondAction } = this.$refs;

                if (!this.$route) {
                    return;
                }

                this.childPagesWidth = this.menuExists ? childPages.offsetWidth : 0;
                this.contentWidth = heading.offsetWidth + this.childPagesWidth;
                this.mainActionWidth = mainAction ? mainAction.$el.offsetWidth : 0;
                this.secondActionWidth = secondAction ? secondAction.$el.offsetWidth : 0;

                this.calculate();
            });

            this.isLoad = true;
        },

        calculate() {
            if (!this.isLoad) {
                return;
            }

            const readyRefs = Object.values(this.$refs).filter(Boolean);
            if (!readyRefs.length) {
                return;
            }

            const { submenu, dropdown } = this.$refs;
            const availableWidth = submenu.offsetWidth - this.COLUMN_MARGIN;

            // show product name if everything fits
            this.showProductName = this.contentWidth + this.secondActionWidth + this.mainActionWidth <= availableWidth;

            // if links don't fit a show dropdown menu instead
            this.showDropdown = this.menuExists &&
                this.childPagesWidth + this.secondActionWidth + this.mainActionWidth > availableWidth;

            // put second action inside the dropdown menu if a dropdown menu is shown and second action does not fit
            if (this.secondActionExists && this.showDropdown) {
                const totalWidth = dropdown.$el.offsetWidth + this.secondActionWidth + this.mainActionWidth;
                this.secondActionInDropdown = totalWidth > availableWidth;
            } else {
                this.secondActionInDropdown = false;
            }

            // put the second action in left side if there is no menu and product name is hidden
            this.secondActionInLeft = !this.menuExists && !this.showProductName;
        },

        isCurrentLocation(link) {
            if (!this.$route) {
                // We're in Storybook, no refs available
                return false;
            }

            return this.$route.path === `/${this.$route.params.locale}${link}`;
        },

        getDataLayerAction(item, defaultAction) {
            if (item.dataLayer && item.dataLayer.action) {
                return item.dataLayer.action;
            }
            return defaultAction;
        },

        showSublist(event) {
            const button = event.target.closest(`.${sublistClass}`);
            button.classList.toggle(sublistClassActive);
        },

        hideSublist(event) {
            if (event.relatedTarget && event.relatedTarget.className !== sublistClassContainer) {
                event.target
                    .closest(`.${sublistClassContainer}`)
                    .querySelector(`.${sublistClass}`)
                    .classList.remove(sublistClassActive);
            }
        },

        getLinkEvent(item, category = null) {
            if (!this.sendEvent) return null;

            const event = {
                ...this.event,
                label: item.text,
                action: 'top product tab',
            };
            if (category) event.category = category;
            return event;
        },

        getSecondActionEvent(button) {
            return {
                ...this.event,
                label: button.to,
                action: this.getDataLayerAction(button, button.text),
                content: this.productName,
            };
        },
    },
};
</script>

<style lang="postcss" scoped>

/* WEB-43924 why do we use 300 and 310 here ? */
.a-submenu {
    position: fixed;
    top: -100%;
    inset-inline-start: 0;
    width: 100%;
    z-index: 300;
    background-color: var(--av-nav-primary);
    padding: 12px 0;
    transition: all linear 0.3s;

    &_light.a-submenu {
        background: var(--av-solid-grey);

        .a-submenu__name,
        .a-submenu__caption,
        &:deep(.a-link.nuxt-link-exact-active) {
            color: var(--av-nav-primary);
        }
    }

    &_with-dropdown {
        .a-submenu__dropdown {
            display: block;
        }

        .a-submenu__list {
            display: none;
        }
    }

    &_second-action-in-dropdown {
        .a-submenu__second-action {
            display: none;
        }

        .a-submenu__dropdown {
            &-list {
                margin-bottom: 24px;
            }

            &-second-action {
                display: block;

                &::before {
                    content: '';
                    width: 41px;
                    height: 1px;
                    background: var(--av-brand-secondary-accent);
                    display: block;
                    margin-bottom: 24px;
                }
            }
        }
    }

    &_second-action-in-left {
        .a-submenu__second-action {
            display: none;
        }

        .a-submenu__mobile-second-action &:deep(.a-link) {
            display: block;
        }
    }

    &_always-visible {
        position: relative;
        top: 0;

        .a-submenu__heading {
            position: absolute;
            visibility: hidden;
        }

        .a-submenu__right {
            padding: 0;
            visibility: hidden;
            overflow: hidden;
        }

        &.is-sticky {
            position: fixed;

            .a-submenu__heading {
                &_visible {
                    position: static;
                    visibility: visible;
                }
            }

            .a-submenu__right {
                padding-inline-start: 16px;
                width: auto;
                visibility: visible;
                overflow: auto;
            }
        }
    }

    &.is-sticky {
        top: 0;
        z-index: 310;
    }

    &__container {
        display: flex;
        align-items: center;
    }

    &__left {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
    }

    &__heading {
        padding-inline-end: 24px; /* Should be strictly padding */
        white-space: nowrap;
        position: absolute;
        top: -200px;

        &_visible {
            position: static;
            display: flex;
            align-items: center;
        }
    }

    &:deep(.a-logo) {
        width: 80px;
        margin-inline-end: 8px;
    }

    &__caption {
        @mixin title-accent;

        color: var(--av-fixed-white);
    }

    &__name {
        @mixin title;
        font-weight: 300;
        letter-spacing: -0.3px;
        color: var(--av-fixed-white);
        position: relative;
        top: -1px;
    }

    &__list {
        display: flex;
        flex-shrink: 0;
        white-space: nowrap;
    }

    &__item:not(:last-child) {
        margin-inline-end: 16px;
    }

    &__link {
        display: block;
    }

    &:deep(.a-link.nuxt-link-exact-active) {
        color: var(--av-fixed-white);
    }

    &-heading {
        color: var(--av-nav-primary);
        margin-bottom: 16px;
    }

    &__dropdown {
        display: none;

        &:deep(.a-dropdown__body) {
            top: calc(100% + 31px);
        }

        &-heading {
            color: var(--av-nav-primary);
            margin-bottom: 20px;
        }

        &-logo {
            width: 60px;
            height: 24px;
        }

        &-caption {
            @mixin lead-accent;
        }

        &-item:not(:last-child) {
            margin-bottom: 16px;
        }

        &-second-action {
            position: relative;
            padding-top: 24px;
            margin-top: 24px;

            &::before {
                background-color: var(--av-fixed-lightest);
                height: 1px;
                width: 41px;
                content: '';
                display: block;
                position: absolute;
                top: 0;
                inset-inline-start: 0;
            }

            @media (--viewport-tablet) {
                display: none;
            }
        }

        &:deep(.a-link.nuxt-link-exact-active) {
            color: var(--av-nav-primary);
        }
    }

    &:deep(.a-dropdown__content) {
        min-width: 213px;
    }

    &__right {
        margin-inline-start: auto;
        padding-inline-start: 16px;
        display: flex;
        align-items: center;
    }

    &__second-action {
        padding-inline-end: 16px;
        white-space: nowrap;
    }

    &__mobile-second-action.a-link {
        display: none;
    }

    .a-submenu__right {
        &:deep(.a-glyph) {
            margin-inline-start: 8px;
        }
    }

    &__sublist-container {
        position: relative;
        padding-bottom: 16px;
        margin-bottom: -16px;
    }

    &__sublist-toggler {
        display: flex;
        align-items: center;
        background: none;
        border: none;
        padding: 0;
        cursor: pointer;

        & > * {
            pointer-events: none;
        }

        &:hover {
            color: var(--av-fixed-white-secondary);
            opacity: 0.8;
            .a-link__content__glyph {
                fill: var(--av-fixed-white-secondary);
            }
        }
        &:focus {
            background: none;
        }
        &_active {
            color: var(--av-fixed-white-secondary);
            opacity: 0.8;
            &:deep(.a-link__content__glyph) {
                fill: var(--av-fixed-white-secondary);
            }

            .a-submenu {
                &__sublist-glyph {
                    transform: rotate(180deg);
                    transition: transform 0.3s ease-out;
                }
            }

            & + .a-submenu {
                &__sublist {
                    display: block;
                }
            }
        }

        &:deep(.a-link.nuxt-link-exact-active) {
            color: var(--av-nav-primary);
        }
    }

    &__sublist-glyph {
        margin-inline-start: 8px;
        fill: currentcolor;
        transition: transform 0.15s ease-out;
    }

    &__sublist {
        display: none;
        position: absolute;
        top: 100%;
        inset-inline-start: 0;
        padding: 16px 24px 16px 16px;
        background-color: var(--av-fixed-white);
        box-shadow: 0 6px 18px rgba(0, 32, 77, 0.2);
        border-radius: 2px;
    }

    &__subitem {
        &:not(:last-of-type) {
            margin-bottom: 16px;
        }
    }

    &__sublink {
        color: var(--av-brand-secondary);
        cursor: pointer;

        &:hover {
            color: var(--av-nav-secondary);
        }

        &:deep(&.a-link.nuxt-link-exact-active) {
            pointer-events: auto;
            color: var(--av-nav-primary);
        }
    }
}
</style>
