<template>
    <div v-if="product.opened" class="card-workload-editions">
        <div
            v-for="(edition, editionIndex) in editionAddedList(product.id)"
            :key="`edition-item-${editionIndex}`"
            class="card-workload-edition-item"
        >
            <div v-if="edition.name" class="card-workload-edition-name">
                <div v-html="edition.name" />
                <card-action :settings="settings" :product="product" :edition="edition" />
            </div>
            <div class="card-workload-edition-selectors">
                <div v-if="edition.quantity">
                    <el-num-picker
                        v-model="edition.selectedQuantity"
                        :min="edition.quantity.min"
                        :max="edition.quantity.max"
                        :label="edition.quantity.title"
                        :step="edition.quantity.step || 1"
                        integer-only
                    />
                </div>
                <div v-if="edition.subscriptions" class="card-workload-edition-period">
                    {{ edition.subscriptions.title }}
                    <dropdown-workload-edition :settings="settings" :product="product" :edition="edition" />
                </div>
            </div>
            <notification
                v-for="(item, notificationIndex) in edition.notifications"
                :key="`notification-item-${notificationIndex}`"
                :description="item"
                size="medium"
            />
        </div>
        <card-add-edition :settings="settings" :product="product" />
    </div>
</template>

<script>
import dropdownWorkloadEdition from '../dropdown/workload-edition.vue';
import mixin from '../mixin.js';
import notification from '../notification.vue';
import cardAction from '../step-2/card-action.vue';
import cardAddEdition from '../step-2/card-add-edition.vue';

export default {
    name: 'SCardWorkloadEditions',

    components: {
        dropdownWorkloadEdition,
        cardAction,
        cardAddEdition,
        notification,
        ElNumPicker: () => import('@uikit/ui-kit/packages/num-picker'),
    },

    mixins: [mixin],

    props: {
        /**
         * Settings
         */
        settings: {
            type: Object,
            default: null,
        },

        /**
         * Product
         */
        product: {
            type: Object,
            default: null,
        },
    },

    data: () => ({
        quantity: [],
    }),
};
</script>

<style lang="postcss" scoped>
.s-purchasing-acp {
    .card-workload-editions {
        padding: 24px 0 0;
    }

    .card-workload-edition-item {
        padding: 24px 32px;
        position: relative;
        border-top: 1px solid var(--av-brand-light);
    }

    .card-workload-edition-name {
        @mixin paragraph-accent;
        display: flex;
        justify-content: space-between;
        color: var(--av-nav-primary);

        .s-card-action {
            margin-top: -4px;
        }
    }

    .card-workload-edition-selectors {
        @mixin body;
        display: flex;
        margin: 16px 0 0;
        justify-content: space-between;
        color: var(--av-fixed-secondary);
    }

    .card-workload-edition-period {
        width: 100%;
        margin-inline-start: 24px;
    }
}
</style>
