<template>
    <div class="company-pages">
        <section class="s-company-contacts">
            <div class="a-container">
                <div v-if="title" class="s-company-contacts__title">
                    {{ title }}
                </div>
                <a-tabs>
                    <a-tab :label="headquartersLabel">
                        <div class="s-company-contacts__tab">
                            <div class="s-company-contacts__cards">
                                <div
                                    v-for="office in headquarters"
                                    :key="'Headquarters' + office.title"
                                    class="s-company-contacts__cards__card headquarters"
                                >
                                    <a-location-card v-bind="office" />
                                </div>
                            </div>
                        </div>
                    </a-tab>
                    <template v-for="tab in tabs">
                        <a-tab :key="tab.id" :label="tab.label" :anchor-id="tab.id">
                            <template v-if="tab.id !== 'sales'">
                                <div v-if="mainOffice && tab.id === 'offices'" class="s-company-contacts__cards">
                                    <div class="s-company-contacts__cards main">
                                        <div class="s-company-contacts__cards__card" :class="tab.id">
                                            <a-location-card v-bind="mainOffice" />
                                        </div>
                                    </div>
                                </div>
                                <div class="s-company-contacts__cards">
                                    <div
                                        v-for="(card, i) in tab.tabContent.items"
                                        :key="i"
                                        class="s-company-contacts__cards__card"
                                        :class="tab.id"
                                    >
                                        <a-location-card v-bind="card" :main="card.headquarter || null" />
                                    </div>
                                    <div v-if="tab.noticeLinks && tab.id === 'support'" class="s-company-contacts__cards__card__notice">
                                        <span>{{ supportText }}</span>
                                        <template v-for="(link, index) in tab.noticeLinks">
                                            <a-link
                                                :key="`noticeLink_${index}`"
                                                v-bind="link"
                                                :text="link.title"
                                                :target="link.target || '_blank'"
                                            />
                                            <em v-if="tab.noticeLinks.length - index > 1" :key="`noticeDelimiter_${index}`">,</em>
                                        </template>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div
                                    v-for="(block, index) in tab.tabContent"
                                    :key="`block__${index}`"
                                    class="s-company-contacts__block"
                                >
                                    <div class="s-company-contacts__block__title">
                                        {{ block.region }}
                                    </div>
                                    <div class="s-company-contacts__cards">
                                        <div
                                            v-for="card in block.items"
                                            :key="'Sales' + card.title"
                                            class="s-company-contacts__cards__card"
                                            :class="tab.id"
                                        >
                                            <a-location-card v-bind="card" contacts-type="column" />
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </a-tab>
                    </template>
                </a-tabs>
            </div>
        </section>
        <s-social-cards v-bind="social" />
    </div>
</template>

<script>
import ALink from '@core/components/link/link.vue';
import ALocationCard from '@core/components/location-card/location-card.vue';
import ATab from '@core/components/tabs/tab.vue';
import ATabs from '@core/components/tabs/tabs.vue';
import SSocialCards from '@core/slices/pages/social-cards/social-cards.vue';

export default {
    name: 'SCompanyPagesContacts',
    components: {
        ALink,
        ALocationCard,
        ATabs,
        ATab,
        SSocialCards,
    },
    props: {
        tabs: {
            type: Array,
            required: true,
        },
        supportText: {
            type: String,
            default: 'We also provide support in:',
        },
        title: {
            type: String,
            default: undefined,
        },
        headquartersLabel: {
            type: String,
            default: 'Headquarters',
        },
        social: {
            type: Object,
            default: undefined,
        },
    },
    data: () => ({
        activePersonModal: '',
    }),
    computed: {
        offices() {
            const offices = this.tabs.find((elem) => elem.id === 'offices');
            return offices.tabContent.items;
        },
        headquarters() {
            return this.offices.filter((i) => i.headquarter);
        },
        mainOffice() {
            const locale = this.$route?.params.locale;
            return this.offices.find((i) => i.locale === locale);
        },
    },
};
</script>

<style lang="postcss">
.company-pages {
    .s-company-contacts {
        padding: 40px 0 64px;
        border-bottom: 1px solid var(--av-brand-secondary-light);
        @media (--viewport-tablet) {
            padding: 64px 0;
        }
        @media (--viewport-desktop) {
            padding: 88px 0;
        }
        &__title {
            @mixin display;
            color: var(--av-nav-primary);
            margin-bottom: 45px;

            @media (--viewport-tablet) {
                @mixin hero;
                margin-bottom: 40px;
            }
        }
        &__block {
            width: 100%;
            margin-bottom: 104px;

            &:last-child {
                margin-bottom: 0;
            }
            &__title {
                @mixin title;
                color: var(--av-nav-primary);
                margin-bottom: 40px;
            }
        }
        &__cards {
            display: flex;
            flex-wrap: wrap;
            &.main {
                width: 100%;
                margin-bottom: 104px;
            }

            &__card {
                @mixin paragraph;
                width: 100%;
                margin: 0 0 16px;
                &.headquarters {
                    min-height: 208px;
                }
                &.offices {
                    min-height: 232px;
                }
                &.sales {
                    min-height: 144px;
                }
                &.pr {
                    min-height: 267px;
                }
                &.support {
                    min-height: 216px;
                }

                @media (--viewport-tablet) {
                    margin-inline-end: 16px;
                    @mixin colls 6;

                    &:nth-child(even) {
                        margin-inline-end: 0;
                    }
                }

                @media (--viewport-desktop) {
                    @mixin colls 4;

                    &:nth-child(even) {
                        margin-inline-end: 16px;
                    }

                    &:nth-child(3n) {
                        margin-inline-end: 0;
                    }
                }

                &__notice {
                    align-self: flex-start;
                    height: auto;
                    padding: 26px 24px 32px 34px;
                    background: rgba(38, 104, 197, 0.05);
                    border-radius: 4px;
                    color: var(--av-fixed-secondary);
                    width: 100%;

                    @media (--viewport-tablet) {
                        @mixin colls 6;
                    }

                    @media (--viewport-desktop-wide) {
                        @mixin colls 4;
                    }

                    span {
                        display: block;
                    }
                    a {
                        color: var(--av-brand-secondary);
                        text-decoration: underline;
                    }
                    em {
                        margin-inline-end: 3px;
                    }
                }
            }
        }
    }
}

.company-pages+.s-global-footer--locale-selector-visible {
    z-index: 2004;
}
</style>
