<template>
    <div
        class="event-speaker-card"
        :class="layoutClass"
        @mouseover="hovering = true"
        @mouseleave="hovering = false"
    >
        <template v-if="layout === 'picture'">
            <a-picture
                class="photo"
                :link="photoLink"
                fit="cover"
                size="thumb"
            />
            <div v-if="hovering && !isMobile" class="speaker-hover-wrap">
                <div class="speaker-hover">
                    <a-picture
                        class="photo"
                        :link="photoLink"
                        fit="cover"
                        size="small"
                    />
                    <div class="description">
                        <div class="name">
                            {{ name }}
                        </div>
                        <div class="title">
                            {{ title }}
                        </div>
                        <a class="event-link link">
                            {{ readMoreTitle }}
                            <a-glyph name="arrow" />
                        </a>
                    </div>
                </div>
            </div>
        </template>
        <template v-else-if="layout === 'picture-with-name'">
            <a-picture
                class="photo"
                :link="photoLink"
                fit="cover"
                size="thumb"
            />
            <div class="description">
                <div class="name">
                    {{ name }}
                </div>
                <div class="title">
                    {{ title }}
                </div>
            </div>
        </template>
        <template v-else-if="layout === 'full'">
            <div class="photo-wrap">
                <a-picture
                    class="photo"
                    :link="photoLink"
                    fit="cover"
                    size="small"
                />
                <div v-if="socials.length" class="socials">
                    <a
                        v-for="(social, i) in socials"
                        :key="i"
                        class="event-link social"
                        :href="social.link"
                    >
                        <a-glyph class="glyph" :name="social.name" />
                    </a>
                </div>
            </div>
            <div class="description">
                <div class="name">
                    {{ name }}
                </div>
                <div class="title">
                    {{ title }}
                </div>
                <div class="text">
                    {{ text }}
                </div>
            </div>
        </template>
        <template v-else-if="layout === 'medium'">
            <a-picture
                class="photo"
                :link="photoLink"
                fit="cover"
                size="small"
            />
            <div class="description">
                <div class="name">
                    {{ name }}
                </div>
                <div class="title">
                    {{ title }}
                </div>
                <v-clamp
                    class="text"
                    tag="div"
                    :max-lines="maxLines"
                    @clampchange="isTextClamped = $event"
                >
                    {{ strippedText }}
                    <template #after="{ clamped }">
                        <a v-if="clamped" class="event-link link">{{ readMoreTitle }}</a>
                    </template>
                </v-clamp>
            </div>
        </template>
        <template v-else-if="layout === 'short'">
            <div class="name">
                {{ name }}
            </div>
            <a-picture
                class="photo"
                :link="photoLink"
                fit="cover"
                size="small"
            />
            <div class="title">
                {{ title }}
            </div>
        </template>
    </div>
</template>

<script>
import VClamp from 'vue-clamp';
import AGlyph from '@core/components/glyph/glyph.vue';
import APicture from '@core/components/picture/picture.vue';
import breakpoint from '@core/mixins/breakpoint.js';

const LAYOUTS_LIST = ['picture', 'picture-with-name', 'full', 'medium', 'short'];

export default {
    name: 'EventSpeakerCard',

    components: { APicture, AGlyph, VClamp },

    mixins: [breakpoint],

    props: {
        layout: {
            type: String,
            required: true,
            validator: (value) => !value || LAYOUTS_LIST.includes(value),
        },
        photo: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: '',
        },
        socials: {
            type: Array,
            required: false,
            default: () => [],
        },
        text: {
            type: String,
            default: null,
        },
        readMoreTitle: {
            type: String,
            default: 'Read more',
        },
    },

    data: () => ({
        fallbackImage: 'fd3353668ea5bda3b807d3682fe6ac8e',
        isTextClamped: false,
        hovering: false,
    }),

    computed: {
        layoutClass() {
            const classes = [`layout-${this.layout}`];

            if (this.isTextClamped) {
                classes.push('with-hover');
            }

            return classes;
        },

        photoLink() {
            return this.photo || this.fallbackImage;
        },

        maxLines() {
            if (this.isMobile) return 6;
            if (this.isTablet) return 4;
            if (this.currentBreakpoint === 'desktop') return 8;
            return 5;
        },

        strippedText() {
            const regex = /(<([^>]+)>)/gi;
            return this.text ? this.text.replace(regex, '') : '';
        },
    },
};
</script>

<style lang="postcss" scoped>
.event-speaker-card {
    position: relative;

    .photo  {
        overflow: hidden;
        cursor: pointer;
        position: relative;
        border-radius: 50%;

        &:deep(.a-picture__img)  {
            width: 100%;
            height: 100%;
        }
    }

    &.layout-full {
        background: var(--av-fixed-white);
        text-align: start;

        @media (--viewport-tablet) {
            padding: 32px;
            display: flex;
            justify-content: flex-start;
        }

        .photo-wrap {
            margin-bottom: 48px;

            @media (--viewport-tablet) {
                margin-bottom: 0;
                margin-inline-end: 32px;
            }
        }

        .photo {
            width: 156px;
            height: 156px;
            margin: 0 auto 24px;

            @media (--viewport-tablet) {
                width: 180px;
                height: 180px;
                flex-shrink: 0;
                flex-grow: 0;
            }
        }

        .socials {
            text-align: center;
        }

        .social {
            display: inline-block;
            margin-inline-start: 8px;
            margin-inline-end: 8px;

            .glyph {
                margin: 0;
            }

            &:deep(.a-glyph) {
                width: 24px;
                height: 24px;
            }

            &:hover {
                &:deep(.a-glyph) {
                    fill: var(--av-brand-primary);
                }
            }
        }

        .name {
            @mixin title-accent;
            color: var(--av-nav-primary);
            margin-bottom: 8px;
        }

        .title {
            @mixin paragraph;
            color: var(--av-fixed-light);
            margin-bottom: 16px;
        }

        .text {
            @mixin paragraph;
            color: var(--av-fixed-primary);
        }

        .description {
            white-space: pre-line;
        }
    }

    &.layout-short {
        border: 1px solid var(--av-brand-secondary-accent);
        box-shadow: 0 4px 8px rgba(36, 49, 67, 0.1);
        background: var(--av-fixed-white);
        padding: 16px;
        border-radius: 6px;
        cursor: pointer;
        height: 100%;
        display: flex;
        flex-direction: column;

        @media (--viewport-tablet) {
            padding: 24px;
        }

        &:hover {
            box-shadow: 0 10px 20px rgba(36, 49, 67, 0.2);

            .name {
                color: var(--av-brand-secondary);
            }
        }

        .name {
            @mixin lead-strong;
            color: var(--av-nav-primary);
            min-height: 48px;
            margin-bottom: 24px;

            @media (--viewport-desktop) {
                @mixin title-accent;
                margin-bottom: 8px;
                min-height: 64px;
            }
        }

        .photo {
            width: 124px;
            height: 124px;
            margin: 0 auto 8px;

            @media (--viewport-tablet) {
                width: 136px;
                height: 136px;
            }
        }

        .title {
            @mixin caption;
            color: var(--av-fixed-light);
            margin-top: 16px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            @media (--viewport-tablet) {
                @mixin paragraph;
            }
        }
    }

    &.layout-picture {
        .photo {
            width: 48px;
            height: 48px;
        }
    }

    &.layout-picture-with-name {
        cursor: pointer;
        display: flex;
        justify-content: flex-start;

        .photo {
            flex-shrink: 0;
            margin-inline-end: 24px;
            width: 48px;
            height: 48px;
        }

        .description {
            flex-grow: 1;
        }

        .name {
            @mixin paragraph-accent;
            color: var(--av-nav-primary);
        }

        .title {
            @mixin paragraph;
            color: var(--av-fixed-light);
        }
    }

    &.layout-medium {
        pointer-events: none;
        border: 1px solid var(--av-brand-secondary-accent);
        padding: 24px 24px 32px;
        border-radius: 6px;
        background: var(--av-fixed-white);
        height: 100%;

        @media (--viewport-tablet) {
            padding: 32px;
            display: flex;
            justify-content: flex-start;
        }

        &.with-hover {
            pointer-events: all;
            cursor: pointer;

            &:hover {
                box-shadow: 0 10px 20px rgba(36, 49, 67, 0.2);

                .link {
                    color: var(--av-brand-primary);
                }
            }
        }

        .photo {
            width: 140px;
            height: 140px;
            margin: 0 auto 16px;

            @media (--viewport-tablet) {
                flex-shrink: 0;
                flex-grow: 0;
                margin-inline-end: 24px;
                margin-bottom: 0;
            }
        }

        .description {
            flex-grow: 1;
        }

        .name {
            @mixin title-accent;
            color: var(--av-nav-primary);
            margin-bottom: 8px;
        }

        .title {
            @mixin paragraph;
            color: var(--av-fixed-light);
            margin-bottom: 8px;
        }

        .text {
            @mixin paragraph;
            color: var(--av-fixed-primary);
            margin-bottom: 8px;
        }

        .link {
            @mixin paragraph;
            font-weight: 500;
            color: var(--av-brand-secondary);
            vertical-align: baseline;
        }
    }

    &.layout-picture,
    &.layout-picture-with-name {
        &:hover > .photo{
            box-shadow: var(--av-shadow-regular);
            &::after {
                content: '';
                display: block;
                position: absolute;
                inset: 0;
                border-radius: 50%;
                border: 2px solid var(--av-brand-secondary);
            }
        }
    }

    .speaker-hover-wrap {
        position: absolute;
        z-index: 2;
        bottom: 100%;
        padding-bottom: 8px;
        inset-inline-start: 50%;
        transform: translateX(-50%);
    }

    .speaker-hover {
        background: var(--av-fixed-white);
        width: 236px;
        border-radius: 6px;
        box-shadow: var(--av-shadow-regular);
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 24px 16px;
        cursor: pointer;

        .photo {
            width: 112px;
            height: 112px;
            margin-bottom: 16px;
        }

        .title {
            @mixin caption;
            color: var(--av-fixed-light);
            margin-bottom: 8px;
        }

        .name {
            @mixin paragraph-accent;
            color: var(--av-brand-secondary);
            margin-bottom: 4px;
        }

        .link {
            @mixin caption-accent;
            color: var(--av-brand-secondary);

            &:hover {
                .a-glyph {
                    fill: var(--av-brand-secondary);
                }
            }
        }

        .a-glyph {
            width: 11px;
            margin-inline-start: 2px;
        }
    }
}
</style>
