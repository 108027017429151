<template>
    <div>
        <a-button v-if="type === 'tag'" class="a-tag" v-bind="link" />
        <button
            v-if="visible && type === 'chip'"
            class="a-chip"
            :class="{'closable': closable}"
            :disabled="disabled"
            @click="handleClick"
        >
            <span>{{ text }}</span>
            <a-glyph v-if="closable" name="times" size="xs" />
        </button>
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import { TYPES } from './constants.js';

export default {
    name: 'ATag',
    components: {
        AButton,
        AGlyph,
    },
    props: {
        /**
         * General props
         */
        text: {
            type: String,
            required: false,
            default: undefined,
        },
        type: {
            type: String,
            required: false,
            default: 'tag',
            validator: (value) => TYPES.includes(value),
        },

        /* Tag Props */
        link: {
            type: Object,
            required: false,
            default: undefined,
        },

        /* Chip props */
        closable: {
            type: Boolean,
            required: false,
            default: false,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    emits: ['click'],
    data() {
        return {
            visible: true,
        };
    },
    methods: {
        handleClick(event) {
            this.$emit('click', event);

            if (this.closable) {
                this.visible = false;
            }
        },
    },
};
</script>

<style lang="postcss" scoped>
.a-tag,
.a-chip {
    @mixin note-heading;
    padding: 0 8px;
    border-radius: 8px;
    text-transform: uppercase;
    background: var(--av-brand-accent);
    color: var(--av-brand-primary);
    border: none;
    outline: 3px solid transparent;
    box-shadow: none;
    user-select: none;
    cursor: pointer;

    &:hover {
        background: var(--av-brand-secondary-bright-hover);
    }

    &:focus {
        background: var(--av-brand-accent);
        outline: 3px solid var(--av-brand-secondary-light);
    }

    &:active {
        background: var(--av-brand-secondary-light);
    }

    &:disabled {
        pointer-events: none;
        background: var(--av-brand-accent);
        opacity: 0.3;
    }
}
.a-chip {
    display: inline-flex;
    align-items: center;
    gap: 8px;

    &.closable {
        background: var(--av-brand-secondary-light);

        &:hover {
            background: var(--av-brand-secondary-light);
        }

        &:active {
            background: var(--av-brand-secondary-bright-hover);
            outline: none;
        }

        &:focus {
            background: var(--av-brand-accent);
        }

        .a-glyph {
            fill: var(--av-brand-secondary);
        }
    }
}
</style>
