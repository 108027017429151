<template>
    <div class="media">
        <div class="a-container media__wrap">
            <div class="media__video">
                <a-video-card
                    v-if="video"
                    class="media__video"
                    :yt-id="video.ytId"
                    :time="video.time"
                    :image="video.image"
                />
                <a-picture v-else class="media__image" :link="image" />
            </div>
            <div class="media__col">
                <div class="media__title">
                    {{ title }}
                </div>
                <div class="media__text">
                    {{ text }}
                </div>
                <div class="media__links">
                    <a-link
                        v-for="(item, index) in links"
                        :key="index"
                        class="media__link"
                        v-bind="item"
                        :glyph="item.glyph || 'arrow'"
                        :type="item.type || 'light'"
                        :size="item.size || 'lead'"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';
import AVideoCard from '@core/components/video-card/video-card.vue';

export default {
    name: 'SSportMedia',
    components: { ALink, AVideoCard, APicture },

    props: {
        video: {
            type: Object,
            default: null,
        },
        image: {
            type: String,
            default: null,
        },
        title: {
            type: String,
            required: true,
        },
        text: {
            type: String,
            required: true,
        },
        links: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style lang="postcss" scoped>
.media {
    background: linear-gradient(360deg, #15316f 0%, #00204d 100%);
    padding-top: 64px;
    padding-bottom: 80px;
    text-align: center;

    @media (--viewport-tablet) {
        text-align: start;
        padding-top: 80px;
        padding-bottom: 100px;
    }

    &__wrap {
        @media (--viewport-desktop) {
            display: flex;
            align-items: center;
        }
        @media (--viewport-desktop-large) {
            align-items: flex-start;
        }
    }

    &__title {
        @mixin display;
        margin-bottom: 32px;
        font-weight: 400;
        color: #fff;

        @media (--viewport-tablet) {
            margin-bottom: 24px;
            margin-top: 0;
        }

        @media (--viewport-desktop) {
            @mixin hero;
        }
        @media (--viewport-desktop-wide) {
            margin-top: -10px;
        }
        @media (--viewport-desktop-large) {
            margin-top: 0;
        }
    }

    &__text {
        color: #fff;
        @mixin paragraph;
        margin-bottom: 32px;
    }

    &__links {
        @media (--viewport-tablet) {
            display: flex;
        }
    }
    &__link {
        @mixin nav-title-accent;

        color: var(--av-brand-icon-solid-lighter);
        margin-bottom: 24px;
        font-weight: 500;
        width: 100%;

        @media (--viewport-tablet) {
            @mixin lead;
            width: auto;
            margin-bottom: 0;
            margin-inline-end: 24px;

            &:last-child {
                margin-inline-end: 0;
            }
        }

        .a-glyph {
            fill: var(--av-brand-icon-solid-lighter);
            margin-inline-start: 10px;
        }
    }

    &__video,
    &__image {
        padding-bottom: 0;
        height: 236px;
        width: 100%;
        margin-bottom: 40px;
        border-radius: 4px;
        overflow: hidden;

        @media (--viewport-tablet) {
            max-width: 463px;
            display: block;
            margin-inline-start: auto;
            margin-inline-end: auto;
            margin-bottom: 24px;
        }

        @media (--viewport-desktop) {
            min-width: 471px;
            height: 325px;
            margin-inline-end: 16px;
            margin-bottom: 0;
        }

        @media (--viewport-desktop-wide) {
            margin-inline-end: 114px;
        }

        @media (--viewport-desktop-large) {
            margin-inline-end: 85px;
        }
    }

    &__image {
        &:deep(.a-picture__img) {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
</style>
