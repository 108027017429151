<template>
    <section class="express-signup-thank-you">
        <div class="a-container">
            <transition name="express-signup-slide-up">
                <div v-if="!isAnimation" class="wrapper">
                    <a-logo class="logo" type="full" />
                    <h1 v-if="title" class="title">
                        {{ title }}
                    </h1>
                    <p v-if="lead" class="lead" v-html="lead" />
                </div>
            </transition>
        </div>
        <transition name="express-signup-slide-up">
            <div v-if="!isAnimation" class="footer">
                <express-signup-footer v-if="footer" v-bind="footer" />
            </div>
        </transition>
    </section>
</template>

<script>
import ALogo from '@core/components/logo/logo.vue';
import ExpressSignupFooter from '@core/slices/pages/express-signup-shared/express-signup-footer.vue';
import formHelper from '@utils/form.js';

export default {
    name: 'SExpressSignupThankYou',
    components: {
        ExpressSignupFooter,
        ALogo,
    },
    props: {
        header: {
            type: Object,
            required: false,
            default: undefined,
        },
        title: {
            type: String,
            required: false,
            default: '',
        },
        lead: {
            type: String,
            required: false,
            default: '',
        },
        footer: {
            type: Object,
            required: false,
            default: undefined,
        },
    },
    data() {
        return {
            isAnimation: true,
        };
    },
    mounted() {
        this.isAnimation = false;

        formHelper.sendDataLayer({
            eventCategory: 'form',
            eventAction: 'submit_form_success',
            eventLabel: 'express_signup_demo',
            // WEB-44946
            formTimer: formHelper.getSecondsOnPage(),
        });
    },
};
</script>

<style lang="postcss" scoped>
.express-signup-thank-you {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    min-height: 100vh;
    overflow-x: hidden;
    background: linear-gradient(
        180deg,
        var(--av-brand-secondary-accent) 0%,
        rgba(255, 255, 255, 0) 100%
    );
    color: var(--av-nav-primary);
    text-align: center;

    .wrapper {
        @mixin colls 12;
        .logo {
            width: 156px;
            height: 32px;
            margin-top: 40px;
            @media (--viewport-tablet) {
                width: 205px;
                height: 56px;
            }
        }
        .title {
            @mixin display;
            margin: 72px auto 64px;
            font-weight: 500;
            @media (--viewport-tablet) {
                max-width:400px;
                margin: 86px auto 72px;
            }
        }
        .lead {
            @mixin title;
            margin: 0 auto;
            @media (--viewport-tablet) {
                max-width: 476px;
            }
            &:deep(a) {
                color: var(--av-brand-secondary);
                text-decoration: none;
            }
        }
    }
    .footer {
        width: 100%;
        margin-top: 67px;
        padding-bottom: 40px;
        align-self: flex-end;
        @media (--viewport-tablet) {
            margin: 0;
        }
    }
}

.express-signup-slide-up-leave-active,
.express-signup-slide-up-enter-active {
    transition: 0.5s;
}
.express-signup-slide-up-enter {
    transform: translateY(50%);
    opacity: 0;
}
.express-signup-slide-up-leave-to {
    transform: translateY(0);
    opacity: 1;
}
</style>
