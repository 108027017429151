/* eslint-disable max-len */

export const Figma = 'https://www.figma.com/design/eM4W6JS2MjG1gp5YhKoMp7Dd/Slices?node-id=46214-83511&t=YdHBHy6Bx0MHVIeQ-4';

export const Default = {
    args: {
        translations: {
            title: 'Features',
            'link.text': 'All Features',
            'cards.0.title': 'Absolute VM Protection',
            'cards.0.content': 'Defend more hypervisors, including RedHat, KVM, Oracle VM, Citrix Xen, VMware and Hyper-V, with simple licensing per host allowing protection for an unlimited number of VMs',
            'cards.1.title': 'Acronis Instant Restore',
            'cards.1.content': 'Reduce outage recovery time with best-in-industry RTO by starting any physical or virtual Windows or Linux system directly from the backup storage',
            'cards.2.title': 'Acronis Universal Restore',
            'cards.2.content': 'Restore an entire system to new, dissimilar hardware with a few clicks. Our technology ensures easy system migration between physical and virtual platforms',
            'cards.3.title': 'Automated Bare-metal Recovery',
            'cards.3.content': 'Recover up to two times faster with a complete system image available and smart technology that automatically detects boot requirements',
            'cards.4.title': 'Acronis Universal Restore',
            'cards.4.content': 'Restore an entire system to new, dissimilar hardware with a few clicks. Our technology ensures easy system migration between physical and virtual platforms',
            'cards.5.title': 'Acronis Universal Restore',
            'cards.5.content': 'Restore an entire system to new, dissimilar hardware with a few clicks. Our technology ensures easy system migration between physical and virtual platforms',
        },
        title: 'title',
        link: { text: 'link.text', to: '#' },
        cards: [
            {
                icon: '1',
                title: 'cards.0.title',
                content: 'cards.0.content',
                to: '#',
            },
            {
                icon: '2',
                title: 'cards.1.title',
                content: 'cards.1.content',
                label: 'Advanced',
                to: '#',
            },
            {
                icon: '3',
                title: 'cards.2.title',
                content: 'cards.2.content',
            },
            {
                icon: '4',
                title: 'cards.3.title',
                content: 'cards.3.content',
            },
            {
                icon: '5',
                title: 'cards.4.title',
                content: 'cards.4.content',
            },
            {
                icon: '6',
                title: 'cards.5.title',
                content: 'cards.5.content',
            },
        ],
    },
};
