<template>
    <div class="blog-page blog-category">
        <template v-if="isLocaleSelectorShown">
            <s-locale-selector-ribbon v-if="isMounted" />
            <div v-else class="locale-ribbon-placeholder" />
        </template>
        <div class="main-content">
            <s-main-header v-bind="sliceDataHeader" />
            <blog-container class="intro">
                <blog-head v-bind="blogHead" />
                <blog-dropdown-menu
                    v-if="isMobile"
                    :links="[...menuLinks, browseAllStoriesLink]"
                    :placeholder="translations.selectCategory"
                    :active-link-id="category.id"
                />
                <blog-bcrumbs v-bind="bcrumbs" />
                <blog-title-row
                    :title="categoryTitle"
                    theme="light"
                />
                <blog-featured-post :post="topPost" :is-loading="cardsReloading" theme="light" />
                <div class="socials">
                    <div class="social-label">
                        {{ translations.followUsOn }}
                    </div>
                    <a-social class="social" :social="socialLinks" glyph-size="s" />
                </div>
            </blog-container>
            <blog-container class="cards">
                <blog-cards :cards="latestCards" :is-loading="cardsReloading">
                    <template v-if="listTotalPages > 1" #pagination>
                        <blog-pager
                            class="pagination"
                            :current-page="listCurrentPage"
                            :total-pages="listTotalPages"
                            :base-url="baseUrl"
                            @pageSelected="loadMoreCards"
                        />
                    </template>
                </blog-cards>
            </blog-container>
            <blog-container class="subscribe" theme="light-grey" width="short">
                <blog-subscribe
                    view="page-view"
                    :title="translations.subscribeFormTitle"
                    :lead="translations.subscribeFormLead"
                    :button="translations.subscribe"
                    :email="translations.emailAddress"
                    :agreement="translations.subscribeAgreement"
                    :sample-text="translations.mailSampleText"
                    :success-title="translations.subscribeSuccessTitle"
                    :success-text="translations.subscribeSuccessText"
                    :back-to-form-title="translations.tryAgainTitle"
                />
            </blog-container>
        </div>
        <s-global-footer class="footer" v-bind="sliceDataFooter" />
    </div>
</template>

<script>
import ASocial from '@core/components/social/social.vue';
import analytics from '@core/mixins/analytics.js';
import blog from '@core/mixins/blog.js';
import breakpoint from '@core/mixins/breakpoint.js';
import localeRibbon from '@core/mixins/locale-ribbon.js';
import BlogBcrumbs from '@core/slices/blog/blog-bcrumbs/blog-bcrumbs.vue';
import BlogCards from '@core/slices/blog/blog-cards/blog-cards.vue';
import BlogDropdownMenu from '@core/slices/blog/blog-dropdown-menu/blog-dropdown-menu.vue';
import BlogFeaturedPost from '@core/slices/blog/blog-featured-post/blog-featured-post.vue';
import BlogHead from '@core/slices/blog/blog-head/blog-head.vue';
import BlogSubscribe from '@core/slices/blog/blog-subscribe/blog-subscribe.vue';
import BlogTitleRow from '@core/slices/blog/blog-title-row/blog-title-row.vue';
import BlogContainer from '@core/slices/blog/shared-components/blog-container/blog-container.vue';
import BlogPager from '@core/slices/blog/shared-components/blog-pager/blog-pager.vue';
import SGlobalFooter from '@core/slices/pages/global-footer/global-footer.vue';
import SLocaleSelectorRibbon from '@core/slices/pages/locale-selector-ribbon/locale-selector-ribbon.vue';
import SMainHeader from '@core/slices/pages/main-header/main-header.vue';
import socials from '@model/const/socials.ts';
import '@core/styles/sections/blog.pcss';

export default {
    name: 'BlogCategory',

    components: {
        ASocial,
        SGlobalFooter,
        SMainHeader,
        BlogHead,
        BlogDropdownMenu,
        BlogBcrumbs,
        BlogTitleRow,
        BlogFeaturedPost,
        BlogContainer,
        BlogPager,
        BlogCards,
        BlogSubscribe,
        SLocaleSelectorRibbon,
    },

    mixins: [blog, analytics, breakpoint, localeRibbon],

    props: {
        dispatchLoadTopCard: {
            type: Function,
            required: true,
        },
        dispatchLoadMoreCards: {
            type: Function,
            required: true,
        },
        category: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            socials,
            listCurrentPage: 1,
            sortingBy: 'recent',
            cardsReloading: false,
            isLatestCardsLoading: false,
        };
    },

    computed: {
        sliceDataHeader() {
            return {
                ...this.$store.state.slices.items['s-main-header'],
                theme: 'light',
            };
        },

        sliceDataFooter() {
            return {
                ...this.$store.state.slices.items['s-global-footer'],
                type: 'short',
                theme: 'light',
            };
        },

        locale() {
            return this.$route.params.locale;
        },

        baseUrl() {
            return `/blog/categories/${this.$route.params.category}/`;
        },

        translations() {
            return this.$store.state.slices.items.blog || {};
        },

        localeHasCPC() {
            return !!this.$store.state.locales.locale?.is_active_on_blog_cpc;
        },

        blogHead() {
            return {
                theme: 'light',
                title: this.translations.homeTitle,
            };
        },

        categories() {
            return this.$store.state.blog.categories.items || [];
        },

        menuLinks() {
            return this.categories.map((category) => this.getCategoryFields(category, this.locale));
        },

        newsLink() {
            return {
                title: this.translations.newsArchive,
                link: `/${this.locale}/blog/categories/news/`,
            };
        },

        browseAllStoriesLink() {
            const link = `/${this.locale}/blog/search/`;

            return {
                title: this.translations.browseAllStories,
                link,
                glyph: 'search',
            };
        },
        bcrumbs() {
            const blogItem = {
                to: '/blog/',
                title: this.translations.homeTitle,
            };
            const bcrumbs = [
                blogItem, { title: this.category.title },
            ];

            return {
                theme: 'light',
                bcrumbs,
                mobileLink: blogItem,
            };
        },

        categoryTitle() {
            const title = this.sortingBy === 'recent'
                ? this.translations.categoryRecentTitle
                : this.translations.categoryPopularTitle;

            return title.replace('@value', this.category.title);
        },

        socialLinks() {
            const linksSource = this.translations.customSocialLinks || socials.links;
            const links = Array.from(linksSource);
            const rss = {
                link: '/blog/feed.xml#',
                name: 'rss',
                title: 'RSS',
            };
            links.push(rss);
            return links;
        },

        topPost() {
            const post = (this.$store.state.blog.topPost.items || [])[0] || {};
            return this.getBlogPostFields(post, this.translations);
        },

        latestCards() {
            const cards = this.$store.state.blog.latestCards.items || [];
            return cards.map((card) => this.getBlogPostFields(card, this.translations));
        },

        listTotalPages() {
            return this.$store.state.blog.latestCards.pagination?.pages_total || 1;
        },
    },

    mounted() {
        this.listCurrentPage = parseInt(this.$route.params.page, 10) || 1;
    },

    methods: {
        async loadTopCard() {
            await this.dispatchLoadTopCard(this.category.id, this.sortingBy);
        },

        async loadMoreCards(page) {
            this.isLatestCardsLoading = true;
            const res = await this.dispatchLoadMoreCards(this.category.id, this.sortingBy, page, false);
            this.isLatestCardsLoading = false;
            if (res) {
                this.listCurrentPage = page;
            }
        },
    },
};
</script>

<style lang="postcss" scoped>
.blog-category {
    .intro {
        border-top: 2px solid var(--av-brand-secondary-light);
        margin-bottom: 40px;

        @media (--viewport-tablet) {
            margin-bottom: 48px;
        }

        &.cpc {
            border-top: 0;
            padding-bottom: 40px;
        }
    }

    .cards {
        padding-bottom: 48px;
    }

    .pagination {
        margin: 80px auto 0;
    }

    .subscribe {
        padding: 64px 0;
        border-bottom: 1px solid var(--av-brand-light);
    }
}

.socials {
    @mixin body-accent;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    max-width: 260px;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 24px auto 0;
    text-align: center;

    @media (--viewport-tablet) {
        justify-content: flex-start;
        margin: 24px 0 0;
    }
}

.social-label {
    @mixin body;
    color: #fff;
    flex-shrink: 0;
    margin-top: 4px;
}
</style>
