<template>
    <section :id="id" class="s-slice s-header-with-anchor-cards" :class="{ ...basicClassName }">
        <a-picture
            v-if="background"
            fit="cover"
            :background="background"
            is-background
        />
        <div class="a-container">
            <div v-if="title || lead" class="a-slice-header">
                <div v-if="title" class="a-slice-header__title">
                    {{ title }}
                </div>
                <a-dangerous-html
                    v-if="lead"
                    class="a-slice-header__lead"
                    :content="lead"
                    tag="h1"
                />
                <a-dangerous-html
                    v-if="bodyText"
                    class="a-slice-header__body-text"
                    :content="bodyText"
                    tag="p"
                />
            </div>
            <div v-if="list" class="s-header-with-anchor-cards__list">
                <div v-for="item in list" :key="item.to" class="s-header-with-anchor-cards__item">
                    <template v-if="item.to">
                        <a class="s-header-with-anchor-cards__item-link" :href="item.to">
                            <span class="s-header-with-anchor-cards__text">{{ item.text }}</span>
                            <span class="s-header-with-anchor-cards__description">{{ item.description }}</span>
                        </a>
                    </template>
                </div>
                <div v-if="cta" class="s-header-with-anchor-cards__cta">
                    <div v-for="item in cta" :key="item.text" class="s-header-with-anchor-cards__item-cta">
                        <template v-if="item.modal && item.modal.page">
                            <a v-modal="'header-modal'" class="a-link" href="#header-modal">{{ item.text }}</a>
                            <a-modal id="header-modal">
                                <div class="s-header-with-anchor-cards__content-page">
                                    <a-icon
                                        v-if="item.modal.page.icon"
                                        class="s-header-with-anchor-cards__content-page-icon"
                                        :number="item.modal.page.icon"
                                    />
                                    <p
                                        v-if="item.modal.page.title"
                                        class="s-header-with-anchor-cards__content-page-title"
                                    >
                                        {{ item.modal.page.title }}
                                    </p>
                                    <ul
                                        v-if="item.modal.page.list"
                                        class="s-header-with-anchor-cards__content-page-list"
                                    >
                                        <li
                                            v-for="(listItem, j) in item.modal.page.list"
                                            :key="j"
                                            class="s-header-with-anchor-cards__content-page-list-item"
                                            v-html="listItem"
                                        />
                                    </ul>
                                    <p
                                        v-if="item.modal.page.note"
                                        class="s-header-with-anchor-cards__content-page-note"
                                        v-html="item.modal.page.note"
                                    />
                                </div>
                            </a-modal>
                        </template>
                        <template v-else>
                            <a-button
                                v-if="item.type"
                                v-bind="item"
                                :size="item.size || 's'"
                            />
                            <a-link v-else v-bind="item" />
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import AIcon from '@core/components/icon/icon.vue';
import ALink from '@core/components/link/link.vue';
import AModal from '@core/components/modal/modal.vue';
import APicture from '@core/components/picture/picture.vue';
import Modal from '@core/directives/modal.js';
import contentMixin from '@core/mixins/content.js';
import styleMixin from '@core/mixins/style.js';

export default {
    name: 'SHeaderWithAnchorCards',

    components: {
        AIcon,
        ALink,
        AButton,
        APicture,
        AModal,
        ADangerousHtml,
    },

    directives: { Modal },

    mixins: [contentMixin, styleMixin],

    props: {
        background: {
            type: [Object, Boolean],
            default: () => false,
        },

        list: {
            type: [Array, Boolean],
            default: () => false,
        },

        cta: {
            type: [Array, Boolean],
            default: () => false,
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-header-with-anchor-cards {
    & ~ .s-anchor-menu {
        height: 0;
        visibility: hidden;

        &.is-sticky {
            height: 56px;
            visibility: visible;
        }
    }

    &:deep(.a-picture__img) {
        top: 0;
        height: auto;
        bottom: auto;
    }

    .a-slice-header {
        &__title {
            @mixin hero-subtle;
            color: var(--av-fixed-white);
        }

        &__description {
            @mixin display;
            color: var(--av-fixed-white);
            font-weight: 300;

            @media (--viewport-desktop-wide) {
                @mixin large;
            }
        }

        &__lead {
            @mixin display-accent;
            margin-top: 248px;
            color: var(--av-fixed-white);
        }
    }

    padding-top: 72px;
    position: relative;
    padding-bottom: 56px;
    background-color: #091f58;
    color: var(--av-fixed-white);

    &__list {
        display: flex;
        margin-top: 40px;
        align-items: stretch;
        flex-direction: column;
    }

    &__item {
        text-align: center;
        border-radius: 4px;
        background: var(--av-fixed-white);
        box-shadow: var(--av-shadow-small);
        border: 1px solid var(--av-fixed-lightest);

        &:not(:first-child) {
            margin-top: 16px;
        }
    }

    &__item-link {
        height: 100%;
        padding: 16px;
        display: block;
        text-decoration: none;
    }

    &__text {
        @mixin lead;
        font-weight: 600;
        color: var(--av-brand-secondary);
    }

    &__description {
        @mixin body;
        display: block;
        margin-top: 8px;
        color: var(--av-fixed-secondary);
    }

    &__cta {
        margin-top: 40px;
        text-align: center;
    }

    &__item-cta {
        &:not(:first-child) {
            margin-top: 16px;
        }

        .a-button {
            width: 100%;
        }

        .a-link {
            color: var(--av-fixed-white-light);
        }
    }

    &__menu {
        visibility: hidden;
        margin-bottom: -56px;

        &.is-sticky {
            visibility: visible;
        }
    }

    &__content-page {
        text-align: start;
        padding: 48px 8px;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: var(--av-shadow-modal);
        @media (--viewport-tablet) {
            padding: 48px 60px;
        }
        @media (--viewport-desktop) {
            max-width: 798px;
            padding: 48px 82px 64px;
        }
        @media (--viewport-desktop-wide) {
            max-width: 761px;
            padding: 48px 96px 64px;
        }
        @media (--viewport-desktop-large) {
            max-width: 870px;
            padding: 48px 111px 64px;
        }
    }

    &__content-page-icon {
        width: 64px;
        height: 64px;
        margin: 0 auto;
        @media (--viewport-desktop) {
            width: 96px;
            height: 96px;
        }
    }

    &__content-page-title {
        @mixin lead;
        margin: 24px 0 0;
        color: var(--av-fixed-secondary);
        @media (--viewport-desktop) {
            @mixin title;
        }
    }

    &__content-page-list {
        margin: 16px 0 0;
        padding-inline-start: 16px;
        list-style: decimal;

        @media (--viewport-tablet) {
            margin-top: 24px;
        }
    }

    &__content-page-list-item {
        @mixin body;
        padding-inline-start: 8px;
        color: var(--av-fixed-secondary);

        &:not(:first-child) {
            margin: 16px 0 0;
        }

        a {
            text-decoration: none;
            color: var(--av-brand-secondary);
        }

        @media (--viewport-desktop) {
            @mixin paragraph;
        }
    }

    &__content-page-note {
        @mixin body;
        color: var(--av-fixed-secondary);
        margin-top: 24px;
        padding-inline-end: 40px;

        a {
            font-weight: 600;
            text-decoration: none;
            color: var(--av-brand-secondary);
        }

        @media (--viewport-desktop) {
            @mixin paragraph;
        }
    }
}

@media (--viewport-tablet) {
    .s-header-with-anchor-cards {
        .a-slice-header {
            margin-bottom: 32px;
        }

        &__list {
            flex-wrap: wrap;
            flex-direction: row;
            margin: -16px -8px 0;
        }

        &__cta,
        &__item {
            margin: 16px 8px 0;
            width: calc(50% - 8px * 2);

            &:first-child {
                margin-top: 16px;
            }
        }

        &__cta-list {
            display: flex;
            flex-wrap: nowrap;
            flex-direction: column;
            justify-content: center;
        }

        &__item-cta {
            &:not(:first-child) {
                margin-top: 8px;
            }

            .a-button {
                width: auto;
                min-width: 190px;
            }
        }
    }
}

@media (--viewport-mobile-wide) {
    .s-header-with-anchor-cards {
        .a-slice-header {
            &__lead {
                @mixin hero-accent;
            }
        }
    }
}

@media (--viewport-desktop) {
    .s-header-with-anchor-cards {
        .a-slice-header {
            &__lead {
                @mixin large-accent;
                margin-top: 128px;
            }
        }

        &:deep(.a-picture__img) {
                height: 100%;
        }

        &__cta,
        &__item {
            width: calc(33.33% - 8px * 2);
        }
    }
}
</style>

<style lang="postcss">
#header-modal .inner {
    width: auto;
}
</style>
