<template>
    <div class="visual" :class="{ 'visual-short': productName }">
        <a-picture
            v-if="content.background"
            fit="cover"
            :is-background="true"
            :background="content.background"
        />
        <div class="a-container">
            <div class="visual-item">
                <div class="visual-logo">
                    <dynamic-link class="visual-logo-item" to="/">
                        <a-logo type="full" fill="white" />
                    </dynamic-link>
                    <div v-if="content.partner" class="visual-logo-item">
                        <dynamic-link v-if="content.partner.to" :to="content.partner.to">
                            <a-picture
                                :link="content.partner.image"
                                :alt="content.partner.alt || ''"
                            />
                        </dynamic-link>
                        <a-picture
                            v-else
                            :link="content.partner.image"
                            :alt="content.partner.alt || ''"
                        />
                    </div>
                </div>
                <p v-if="productName" class="visual-product-name">
                    {{ productName }}
                </p>
                <p v-if="content.label" class="visual-product-label">
                    {{ content.label }}
                </p>
            </div>
            <div v-if="content.title" class="visual-item">
                <h1 class="visual-title">
                    <a-dangerous-html :content="content.title" />
                </h1>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import ALogo from '@core/components/logo/logo.vue';
import APicture from '@core/components/picture/picture.vue';

export default {
    name: 'SVisual',

    components: {
        ADangerousHtml,
        APicture,
        DynamicLink,
        ALogo,
    },

    props: {
        /**
         * Content
         */
        content: {
            type: Object,
            default: null,
        },
    },

    computed: {
        ...mapState({
            productId: (state) => state.pages.page.product_id,
            productItems: (state) => state.products.items,
        }),

        locale() {
            return this.$route.params.locale;
        },

        productData() {
            return this.productItems[this.productId] || {};
        },

        productName() {
            return this.productData?.parameters?.common?.productName || null;
        },
    },
};
</script>

<style lang="postcss" scoped>
.visual {
    display: flex;
    padding: 32px 0 40px;
    min-height: 404px;
    position: relative;

    .a-container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
    }
}

.visual-item {
    width: 100%;

    &:not(:first-child) {
        margin-top: 120px;
    }
}

.visual-logo {
    width: 100%;
    display: flex;
    height: 27px;
    align-items: center;

    &:deep(.a-picture__img) {
        height: 27px;
    }
}

.visual-logo-item {
    height: 100%;
    display: flex;
    align-items: center;

    &:not(:first-child) {
        margin-inline-start: 16px;
        padding-inline-start: 16px;
        border-inline-start: 1px solid #647cca;
    }
}

.visual-product-name {
    @mixin display;
    margin-top: 8px;
    color: var(--av-fixed-white);
}

.visual-product-label {
    @mixin paragraph-accent;
    color: var(--av-fixed-success);
}

.visual-title {
    @mixin display;
    font-weight: 600;
    margin-top: auto;
    color: var(--av-fixed-white);
}

.visual-short {
    .visual-item {
        &:not(:first-child) {
            margin-top: 40px;
        }
    }
}

@media (--viewport-mobile-wide) {
    .visual {
        padding: 40px 0 48px;
    }

    .visual-title {
        @mixin hero;
        max-width: 66%;
    }
}

@media (--viewport-desktop) {
    .visual-title {
        width: 40.521%;
    }

    .visual-logo {
        height: 32px;

        &:deep(.a-picture__img) {
            height: 32px;
        }
    }
}
</style>
