<template>
    <s-basic-slice :id="id" class="s-cyber-protection" :class="basicClassName">
        <div class="a-container">
            <a-slice-header :title="title" :lead="lead" />
            <div class="slides-container" :class="{ 'alt-height': isWithoutControls }">
                <div
                    v-for="(slide, i) in slides"
                    :key="`cyber-protection-slide-${i}`"
                    class="slide"
                    :class="{ 'active': i === activeItemIndex }"
                >
                    <a-picture
                        class="main-img"
                        :link="slide.mainImage.path"
                        :alt="slide.mainImage.alt"
                        fit="contain"
                    />
                    <a-picture
                        v-if="slide.droneImage"
                        class="drone-img"
                        :link="slide.droneImage.path"
                        :alt="slide.droneImage.alt"
                        fit="contain"
                    />
                </div>
                <template v-if="!isWithoutControls">
                    <button
                        v-for="i in slides.length - 1"
                        :key="`cyber-protection-switcher-${i}`"
                        class="switcher"
                        :class="getSwitcherClass(i)"
                        aria-label="cyber-protection-switcher"
                        @click="handleSwitcherClick(i)"
                    >
                        <a-glyph class="icon" :name="'plus'" />
                    </button>
                </template>
            </div>
            <a-accordion class="accordion" one-only-mode @change="handleChange">
                <a-accordion-item v-for="(item, i) in items" :key="`cyber-protection-item-${i}`">
                    <template #title>
                        <div ref="title" class="accordion-title">
                            <a-glyph class="accordion-icon icon" name="icon-pointer-right" />
                            <a-dangerous-html class="accordion-title-text" :content="item.title" />
                        </div>
                    </template>
                    <div class="text">
                        {{ item.contentText }}
                    </div>
                </a-accordion-item>
            </a-accordion>
        </div>
    </s-basic-slice>
</template>

<script>
import AAccordionItem from '@core/components/accordion/accordion-item.vue';
import AAccordion from '@core/components/accordion/accordion.vue';
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import APicture from '@core/components/picture/picture.vue';
import ASliceHeader from '@core/components/slice-header/slice-header.vue';
import contentMixin from '@core/mixins/content.js';
import styleMixin from '@core/mixins/style.js';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';

export default {
    name: 'SCyberProtection',

    components: {
        ASliceHeader,
        AAccordion,
        AAccordionItem,
        ADangerousHtml,
        AGlyph,
        APicture,
        SBasicSlice,
    },

    mixins: [contentMixin, styleMixin],

    props: {
        items: {
            type: Array,
            required: true,
        },

        slides: {
            type: Array,
            required: true,
        },

        isWithoutControls: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    data() {
        return {
            activeItemIndex: 0,
        };
    },

    methods: {
        getSwitcherClass(i) {
            return {
                [`pos_${i}`]: true,
                active: i === this.activeItemIndex,
            };
        },

        handleChange(i) {
            this.activeItemIndex = i >= 0 ? i + 1 : 0; // +1 because first element is shown by default
        },

        handleSwitcherClick(i) {
            this.$refs.title[i - 1]?.click();
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-cyber-protection {
    overflow: hidden;

    .a-container {
        @media (--viewport-mobile-wide) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            -ms-grid-columns: 1fr 16px 1fr;
            -ms-grid-rows: auto 16px auto;
            grid-auto-rows: auto 100%;
            grid-column-gap: 16px;
            grid-row-gap: 32px;
        }

        @media (--viewport-desktop) {
            grid-row-gap: 0;
            -ms-grid-rows: auto auto;
        }
    }

    .a-slice-header {
        margin-bottom: 8px;

        @media (--viewport-mobile-wide) {
            grid-column: span 2;
            -ms-grid-column-span: 3;
        }

        @media (--viewport-desktop) {
            margin-bottom: 40px;
            grid-column: auto;
            -ms-grid-column-span: 1;
            width: 100%;
        }
    }

    .slides-container {
        position: relative;
        margin: 0 auto 24px;
        width: 66.66%;
        height: 300px;

        @media (--viewport-mobile-wide) {
            order: 3;
            -ms-grid-column: 3;
            -ms-grid-row: 3;
            margin: 0;
            width: 100%;
            height: 471px;
        }

        @media (--viewport-desktop) {
            order: 2;
            grid-row: span 2;
            -ms-grid-row-span: 2;
            -ms-grid-column: 3;
            -ms-grid-row: 1;
            margin-top: 72px;
            margin-inline-start: 48px;
            height: 514px;
        }

        @media (--viewport-desktop-wide) {
            margin-top: 0;
            margin-inline-start: 96px;
        }

        @media (--viewport-desktop-large) {
            margin-top: 6px;
            margin-inline-start: 135px;
            padding-top: 80%;
        }

        &.alt-height {
            @media (--viewport-desktop-wide) {
                height: 600px;
            }
        }
    }

    .slide {
        position: absolute;
        top: 0;
        inset-inline-start: 0;
        inset-inline-end: 0;
        bottom: 0;

        /* opacity: 0; */

        transition: opacity 0.25s ease-out;
        height: 100%;

        &.active {
            opacity: 1;
        }
    }

    .main-img {
        max-width: 100%;
        height: 100%;
        object-position: center center;

        @media (--viewport-desktop) {
            object-position: left center;
        }

        &:deep(.a-picture__img) {
            @media (--viewport-desktop) {
                margin-inline-start: 0;
            }
        }
    }

    .drone-img {
        display: none;
        width: 125px;
        height: 137px;

        @media (--viewport-desktop) {
            display: block;
            position: absolute;
            top: -28%;
            inset-inline-start: 63%;
        }

        @media (--viewport-desktop-wide) {
            top: -14%;
            inset-inline-start: 61%;
        }

        @media (--viewport-desktop-large) {
            top: -9%;
        }

        .a-picture__img {
            margin-inline-start: 0;
        }
    }

    .accordion {
        @media (--viewport-mobile-wide) {
            order: 2;
            -ms-grid-row: 3;
        }

        @media (--viewport-desktop) {
            order: 3;
            -ms-grid-row: 2;
        }
    }

    .accordion-title {
        display: flex;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.3px;

        @media (--viewport-desktop) {
            font-size: 32px;
            line-height: 40px;
        }
    }

    .accordion-icon.icon {
        margin-top: 8px;

        @media (--viewport-desktop) {
            margin-top: 10px;
        }
    }

    .accordion-title-text {
        font-weight: 700;
        color: var(--av-nav-primary);

        &:deep(span) {
            @media (--viewport-desktop) {
                font-weight: 300;
            }
        }
    }

    .switcher {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        box-sizing: border-box;
        border: 1px solid #e6e6e6;
        border-radius: 50%;
        background: var(--av-fixed-white);
        box-shadow: 0 5px 6px rgba(138, 173, 207, 0.1865);
        transition: opacity ease-out 0.2s;

        /* opacity: 0.8; */

        &.active {
            opacity: 1;

            .a-glyph {
                transform: rotate(45deg);
            }
        }

        &.pos {
            &_1 {
                top: 9%;
                inset-inline-start: 15%;

                @media (--viewport-mobile-wide) {
                    top: 10%;
                    inset-inline-start: 30%;
                }

                @media (--viewport-desktop) {
                    top: 11%;
                    inset-inline-start: 25%;
                }

                @media (--viewport-desktop-wide) {
                    inset-inline-start: 21%;
                }

                @media (--viewport-desktop-large) {
                    inset-inline-start: 19%;
                    top: 10%;
                }
            }

            &_2 {
                top: 45%;
                inset-inline-start: 74%;

                @media (--viewport-mobile-wide) {
                    top: 48%;
                    inset-inline-start: 77%;
                }

                @media (--viewport-desktop) {
                    top: 51%;
                    inset-inline-start: 56%;
                }

                @media (--viewport-desktop-wide) {
                    inset-inline-start: 46%;
                }

                @media (--viewport-desktop-large) {
                    inset-inline-start: 40%;
                    top: 50%;
                }
            }

            &_3 {
                top: 67%;
                inset-inline-start: 20%;

                @media (--viewport-mobile-wide) {
                    top: 62%;
                    inset-inline-start: 12%;
                }

                @media (--viewport-desktop) {
                    top: 67%;
                    inset-inline-start: 15%;
                }

                @media (--viewport-desktop-wide) {
                    inset-inline-start: 13%;
                }

                @media (--viewport-desktop-large) {
                    inset-inline-start: 11%;
                    top: 66%;
                }
            }
        }

        .a-glyph {
            transition: transform 0.25s ease-out;
        }
    }
}
</style>
