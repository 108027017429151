<template>
    <main class="express-signup-trial-wrapper">
        <section class="landing">
            <a-picture class="bg" :background="card.background" is-background />
            <div class="a-container">
                <transition name="express-signup-slide-up">
                    <div v-if="!isAnimation" class="header">
                        <a-logo class="logo" type="full" fill="white" />
                        <h1 v-if="header.title" class="title">
                            {{ header.title }}
                        </h1>
                    </div>
                </transition>
                <transition name="express-signup-slide-up">
                    <section v-if="!isAnimation" class="card-wrapper">
                        <div class="content">
                            <div v-if="card" class="card">
                                <h2 v-if="card.title" class="title">
                                    {{ computedTitle }}
                                </h2>
                                <p v-if="card.description" class="description" v-html="card.description" />
                                <div class="list-wrapper">
                                    <ul class="list">
                                        <li v-for="(feature, index) in card.features" :key="`features_${index}`">
                                            <a-glyph class="icon" name="check-square" fill="brand-primary" />
                                            <span class="text">{{ feature }}</span>
                                        </li>
                                    </ul>
                                </div>
                                <a-button
                                    v-if="card.button"
                                    class="button"
                                    :disabled="isLoading || disableButton"
                                    v-bind="card.button"
                                    :event="{ doNotSendGA: true }"
                                    @click="onSignUp"
                                />
                            </div>
                        </div>
                    </section>
                </transition>
            </div>
        </section>
        <section class="licensing">
            <div class="a-container">
                <transition-group appear name="express-signup-slide-up">
                    <section v-if="payment && !isAnimation" key="payment" class="payment">
                        <h3 v-if="payment.title" class="title">
                            {{ payment.title }}
                        </h3>
                        <p v-if="payment.lead" class="lead" v-html="payment.lead" />
                        <express-signup-payment-infographic class="infographic" :data="payment.infographic" />
                    </section>
                    <section v-if="trial && !isAnimation" key="trial" class="trial">
                        <h3 v-if="trial.title" class="title">
                            {{ trial.title }}
                        </h3>
                        <p v-if="trial.text" class="text" v-html="trial.text" />
                        <a-link
                            v-if="trial.link"
                            class="link"
                            v-bind="trial.link"
                            :size="trial.link.size || 'paragraph'"
                        />
                        <a-button v-if="trial.button" class="button" v-bind="trial.button" />
                    </section>
                </transition-group>
            </div>
        </section>
        <transition name="express-signup-slide-up">
            <div v-if="!isAnimation" class="footer">
                <express-signup-footer v-if="footer" v-bind="footer" />
            </div>
        </transition>
    </main>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import AButton from '@core/components/button/button.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALink from '@core/components/link/link.vue';
import ALogo from '@core/components/logo/logo.vue';
import APicture from '@core/components/picture/picture.vue';
import currency from '@core/mixins/currency.js';
import ExpressSignupFooter from '@core/slices/pages/express-signup-shared/express-signup-footer.vue';
import ExpressSignupPaymentInfographic from '@core/slices/pages/express-signup-shared/payment-infographic.vue';
import expressSignupTrial from '@core/slices/pages/express-signup-shared/trial-mixin.js';
import commonUtils from '@utils/common';
import formHelper from '@utils/form.js';

export default {
    name: 'SExpressSignupTrialLicensing',
    components: {
        ALogo,
        ALink,
        AButton,
        AGlyph,
        APicture,
        ExpressSignupFooter,
        ExpressSignupPaymentInfographic,
    },
    mixins: [expressSignupTrial, currency],
    props: {
        header: {
            type: Object,
            required: false,
            default: undefined,
        },
        card: {
            type: Object,
            required: false,
            default: undefined,
        },
        disclaimer: {
            type: String,
            required: false,
            default: undefined,
        },
        payment: {
            type: Object,
            required: false,
            default: undefined,
        },
        trial: {
            type: Object,
            required: false,
            default: undefined,
        },
        animation: {
            type: Object,
            required: false,
            default: undefined,
        },
        video: {
            type: Object,
            required: false,
            default: undefined,
        },
        footer: {
            type: Object,
            required: false,
            default: undefined,
        },
    },
    data() {
        return {
            isAnimation: true,
            isLoading: false,
            cleverbridgeLink: null,
            form: {
                first_name: '',
                last_name: '',
                email: '',
                country: '',
                company: '',
                aan: '',
                tenantId: '',
            },
        };
    },
    computed: {
        ...mapState({
            queryStringData: (state) => state.trial?.queryStringData || {},
        }),
        computedTitle() {
            const greeting = [this.card.title, this.form.first_name]
                .filter(Boolean)
                .map((x) => x.trim())
                .join(', ');

            return `${greeting}!`;
        },
    },
    mounted() {
        this.isAnimation = false;
    },
    methods: {
        ...mapActions({
            generateSessionURL: 'cleverbridge/get',
        }),
        async getSURL() {
            const localeList = 'cn|de|es|eu|fr|gb|it|jp|kr|mx|tw';
            const lang = localeList.includes(this.form.country) ? `-${this.form.country}` : '';

            const options = {
                shopId: 837,
                targetPathname: `purl-express-partner-signup${lang}`,
                params: {
                    cart: 245936,
                    'x-adminfirstname': this.form.first_name,
                    'x-adminlastname': this.form.last_name,
                    'x-adminemail': this.form.email,
                    'x-aan': this.form.aan,
                    'x-tenant-uid': this.form.tenantId,
                    'x-webuid': this.guid,
                    'x-country': this.form.country || this.country,
                    'x-currency': this.setExpressSignupCurrency || 'USD',
                    'x-language': this.language || 'en',
                    'x-locale': this.locale,
                    country: this.form.country || this.country,
                    language: this.language || 'en',
                    currency: this.setExpressSignupCurrency || 'USD',
                    locale: this.locale,
                    deliveryCompany: this.form.company,
                    deliveryEmail: this.form.email,
                    deliveryFirstname: this.form.first_name,
                    deliveryLastname: this.form.last_name,
                },
            };

            options.params = { ...options.params, ...formHelper.labelsUTMCleverbridge() };

            const param = Object.entries(options.params)
                .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
                .join('&');

            const defaultLink = `https://store.acronis.com/837/purl-express-partner-signup${lang}?${param}`;

            try {
                const response = await this.generateSessionURL(options);
                if (response && response.status === 200) {
                    this.cleverbridgeLink = response.data.data;
                }
            } finally {
                this.cleverbridgeLink = this.cleverbridgeLink || defaultLink;
            }
        },
        async sendDataToMarketo() {
            const formID = 'EXPRESS_SIGNUP_SUCCESS';
            const formattedForm = { ...this.form };
            delete formattedForm.country;

            const secondsOnPage = formHelper.getSecondsOnPage();
            const params = {
                form_id: formID,
                ...formattedForm,
                webuid: this.guid,
                munchkinId:
                    formHelper.getCookies(formHelper.MRKTO_COOKIE_NAME) ||
                    formHelper.createMarketoToken(),
                google_analytics_trackingid: formHelper.GA_TRACKING_ID,
                google_analytics_userid: formHelper.getCookies(formHelper.UID_COOKIE_NAME) || '',
                google_analytics_clientid:
                    formHelper.getCIDval() || '[ERROR] Client ID not generated',
                ...formHelper.labelsUTM(),
                // WEB-44946
                uit: secondsOnPage,
            };

            await this.doExpressSignupRequest(params);

            formHelper.sendDataLayer({
                eventCategory: 'form',
                eventAction: 'submit_form_success',
                eventLabel: 'express_signup_trial',
                formTimer: secondsOnPage, // WEB-44946
                emailAddress: commonUtils.toSHA256String(this.form.email), // WEB-45304
            });
        },
        async onSignUp() {
            this.isLoading = true;

            try {
                await this.sendDataToMarketo();
            } finally {
                await this.getSURL();
                window.location.assign(this.cleverbridgeLink);
            }
        },
    },
};
</script>

<style lang="postcss" scoped>
.express-signup-trial-wrapper {
    overflow-x: hidden;
    .landing {
        position: relative;
        display: flex;
        flex-flow: row wrap;
        .bg {
            &:deep(img) {
                object-fit: none !important;
                object-position: top center !important;
                @media all and (min-width: 480px) {
                    object-fit: contain !important;
                }
                @media (--viewport-tablet) {
                    object-fit: cover !important;
                    object-position: center center !important;
                }
            }
        }
        .header, .card-wrapper {
            flex-basis: 100%;
        }
        .header {
            color: var(--av-fixed-white);
            text-align: center;
            .logo {
                width: 156px;
                height: 32px;
                margin-top: 48px;
                @media (--viewport-tablet) {
                    margin-top: 40px;
                    width: 205px;
                    height: 56px;
                }
            }
            .title {
                @mixin title;
                font-weight: 700;
                margin: 32px auto 24px;
                @media (--viewport-tablet) {
                    @mixin display;
                    margin: 32px auto;
                }
            }
        }
        .card-wrapper {
            position: relative;
            z-index: 1;
            margin: 0 -18px;
            @media (--viewport-mobile-wide) {
                margin: 0 -34px;
            }
            @media (--viewport-tablet) {
                margin: 0;
            }
            .content {
                .card {
                    padding: 24px 16px;
                    background: var(--av-fixed-white);
                    box-shadow: var(--av-shadow-regular);
                    border: 2px solid var(--av-brand-secondary);
                    color: var(--av-nav-primary);
                    @media (--viewport-tablet) {
                        max-width: 704px;
                        border-radius: 8px;
                        margin: 32px auto -48px;
                        padding: 24px;
                    }
                    @media (--viewport-desktop) {
                        max-width: 762px;
                        padding: 24px 56px;
                    }
                    .title {
                        @mixin paragraph;
                        font-weight: 500;
                        text-align: center;
                        margin-bottom: 8px;
                    }
                    .description {
                        @mixin paragraph;
                        margin: 0 auto 16px;
                        text-align: center;
                        @media (--viewport-tablet) {
                            max-width: 580px;
                            margin-bottom: 24px;
                        }
                        @media (--viewport-desktop) {
                            max-width: 610px;
                        }
                    }
                    .list-wrapper {
                        display: flex;
                        align-items: flex-start;
                        flex-flow: row wrap;
                        justify-content: space-between;
                        margin-bottom: 24px;
                    }
                    .list {
                        @mixin colls 12;
                        display: flex;
                        flex-flow: row wrap;
                        justify-content: space-between;
                        gap: 8px 32px;
                        li {
                            @mixin paragraph;
                            flex-basis: 100%;
                            display: flex;
                            align-items: flex-start;
                            @media (--viewport-tablet) {
                                flex-basis: calc(50% - 16px);
                            }

                            .text {
                                flex-basis: calc(100% - 20px);
                            }
                            .icon {
                                width:16px;
                                flex-basis: 16px;
                                margin-inline-end: 4px;
                                margin-top: 6px;
                            }
                        }
                    }
                    .button {
                        @mixin paragraph;
                        font-weight: 500;
                        width: 100%;
                        margin: 16px auto 0;
                        @media (--viewport-tablet) {
                            display: block;
                            text-align: center;
                            min-width: 276px;
                            width: auto;
                            max-width: 100%;
                            margin: 24px auto 0;
                        }
                    }
                }
            }
        }
}

.licensing {
    position: relative;
    background: linear-gradient(180deg, rgba(64, 139, 234, 0.075) 0%, rgba(64, 139, 234, 0) 100%);
    .payment {
        @mixin colls 12;
        margin: 0 auto 54px;
        text-align: center;
        padding-top: 48px;
        color: var(--av-nav-primary);
        @media (--viewport-tablet) {
            margin: 0 auto 32px;
            padding-top: 110px;
        }
        .title {
            @mixin title;
            font-weight: 500;
            margin: 0 auto 16px;
            @media (--viewport-tablet) {
                @mixin display;
                @mixin colls 10;
            }
            @media (--viewport-desktop) {
                @mixin colls 12;
            }
        }
        .lead {
            @mixin paragraph;
            margin-bottom: 32px;
        }
    }
    .trial {
        color: var(--av-nav-primary);
        margin: 0 auto;
        max-width: 520px;
        text-align: center;
        .title {
            @mixin colls 12;
            @mixin paragraph;
            margin-bottom: 8px;
            font-weight: 700;
            text-align: center;
            @media (--viewport-tablet) {
                margin-bottom: 16px;
            }
        }
        .text {
            @mixin colls 12;
            @mixin paragraph;
            margin: 0 auto 8px;
        }
        .link {
            display: block;
        }
        .button {
            margin: 48px auto;
            min-width: 338px;
            width:auto;
            max-width: 100%;
            font-weight: 400;
            color: var(--av-brand-primary);
        }
    }
}
    .footer {
        padding: 32px 0;
        border-top: 1px solid #c6dcf9;
        background: linear-gradient(0deg, rgba(38, 104, 197, 0.05), rgba(38, 104, 197, 0.05)),
            #fff;
        @media (--viewport-tablet) {
            background: rgba(64, 139, 234, 0.05);
            padding: 24px 0;
        }
        &:deep(.express-signup-main-footer) {
            &__logo {
                flex-basis: 100%;
                margin-bottom: 16px;
                @media (--viewport-tablet) {
                    margin-bottom: 0;
                    margin-inline-end: 16px;
                    flex-basis: auto;
                }
            }
            &__copyright {
                flex-basis: auto;
                margin-inline-end: 0 !important;
                @media (--viewport-tablet) {
                    margin-inline-end: auto !important;
                }
            }
            &__links {
                margin-top: 32px;
                flex-flow: column;
                align-items: center;
                @media (--viewport-tablet) {
                    flex-flow: row wrap;
                    margin: 0;
                }
                &__item {
                    margin-bottom: 8px;
                    margin-inline-end: 0 !important;
                    @media (--viewport-tablet) {
                        margin-bottom: 0;
                        margin-inline-end: 16px !important;
                    }
                    &:last-child {
                        margin-bottom: 0;
                        @media (--viewport-tablet) {
                            margin-inline-end: 0 !important;
                        }
                    }
                }
            }
        }
    }
}

.express-signup-slide-up-leave-active,
.express-signup-slide-up-enter-active {
    transition: 0.5s;
}
.express-signup-slide-up-enter {
    transform: translateY(50%);
    opacity: 0;
}
.express-signup-slide-up-leave-to {
    transform: translateY(0);
    opacity: 1;
}
</style>
