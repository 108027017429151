<template>
    <div class="company-pages">
        <section class="s-company-leadership">
            <div class="a-container">
                <div v-for="section in sections" :key="section.title" class="s-company-leadership__section">
                    <div v-if="section.title" class="s-company-leadership__header">
                        {{ section.title }}
                    </div>
                    <div class="s-company-leadership__people">
                        <div
                            v-for="person in section.items"
                            :key="person.name"
                            class="s-company-leadership__people__manager"
                        >
                            <div
                                class="s-company-leadership__people__manager__content"
                                tabindex="0"
                                @click="activePersonModal = person.name"
                                @keydown.esc="activePersonModal = ''"
                            >
                                <a-picture
                                    class="s-company-leadership__people__manager__photo"
                                    :link="person.photo"
                                    :alt="person.name"
                                />
                                <div class="s-company-leadership__people__manager__name">
                                    {{ person.name }}
                                </div>
                                <div class="s-company-leadership__people__manager__position">
                                    {{ person.position || ' ' }}
                                </div>
                                <div class="s-company-leadership__people__manager__country">
                                    {{ person.country }}
                                </div>
                                <div
                                    v-if="person.modal && activePersonModal === person.name"
                                    class="s-company-leadership__people__manager__overlay"
                                >
                                    <div
                                        class="s-company-leadership__people__manager__modal__click"
                                        @click.stop="activePersonModal = ''"
                                    />
                                    <div class="s-company-leadership__people__manager__modal">
                                        <div class="s-company-leadership__people__manager__modal__header">
                                            <div class="s-company-leadership__people__manager__modal__header__name">
                                                {{ person.name }}
                                            </div>
                                            <div class="s-company-leadership__people__manager__modal__header__position">
                                                {{ person.position || ' ' }}
                                            </div>
                                            <div class="s-company-leadership__people__manager__modal__header__social">
                                                <a
                                                    v-for="link in person.modal.social"
                                                    :key="link.url"
                                                    class="s-company-leadership__people__manager__modal__header__social__item"
                                                    :href="link.url"
                                                    target="_blank"
                                                >
                                                    <a-glyph :name="link.type" size="xs" />
                                                </a>
                                            </div>
                                            <button
                                                class="s-company-leadership__people__manager__modal__header__close"
                                                @click.stop="activePersonModal = ''"
                                            >
                                                {{ closeButtonText }}
                                                <a-glyph name="close" size="xs" />
                                            </button>
                                        </div>
                                        <div class="s-company-leadership__people__manager__modal__content">
                                            <a-dangerous-html :content="person.modal.content" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import APicture from '@core/components/picture/picture.vue';

export default {
    name: 'SCompanyPagesLeadership',
    components: {
        ADangerousHtml,
        AGlyph,
        APicture,
    },
    props: {
        closeButtonText: {
            type: String,
            default: 'Close',
        },
        sections: {
            type: Array,
            required: true,
        },
    },
    data: () => ({
        activePersonModal: '',
    }),
};
</script>

<style lang="postcss">
.company-pages {
    .s-company-leadership {
        @mixin basic-slice-paddings;

        &__section {
            @mixin basic-slice-paddings;
            padding-top: 0;
            display: flex;
            justify-content: space-between;
            flex-flow: row wrap;

            &:last-of-type {
                padding: 0;
            }
        }

        &__header {
            @mixin display;
            color: var(--av-nav-primary);
            margin-bottom: 40px;
            width: 100%;

            @media (--viewport-tablet) {
                @mixin hero;
                margin-bottom: 48px;
            }

            @media (--viewport-desktop) {
                margin: 0;
                @mixin colls 3;
            }
        }

        &__people {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;
            @media (--viewport-tablet) {
                justify-content: flex-start;
            }
            @media (--viewport-desktop) {
                @mixin colls 9;
            }

            &__manager {
                @mixin colls 6;
                text-align: center;
                margin-bottom: 24px;

                @media (--viewport-tablet) {
                    @mixin colls 4;
                }

                &__content {
                    cursor: pointer;
                }

                &__photo {
                    display: block;
                    margin: 0 auto;
                    max-width: 156px;
                    border-radius: 8px;
                    overflow: hidden;
                    @media (--viewport-tablet) {
                        width: 180px;
                    }
                }

                &__name {
                    @mixin paragraph;
                    font-weight: 500;
                    margin-top: 16px;
                    color: var(--av-brand-secondary);
                    &:hover {
                        color: var(--av-nav-secondary);
                    }
                }

                &__position,
                &__country {
                    @mixin body;
                    font-weight: 500;
                    color: var(--av-fixed-primary);
                }

                &__overlay {
                    position: fixed;
                    top: 0;
                    z-index: 2147483647;
                    inset-inline-start: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-content: center;
                    padding: 20px 16px;
                    background: rgba(255, 255, 255, 0.6);

                    @media (--viewport-desktop) {
                        padding: 40px 0;
                    }
                }
                &__modal {
                    align-self: center;
                    box-sizing: border-box;
                    margin: 0 auto;
                    width: 640px;
                    max-width: 100%;
                    max-height: 100%;
                    background: var(--av-fixed-white);
                    border: 1px solid var(--av-brand-accent);
                    box-shadow: 0 10px 20px var(--av-fixed-lightest);
                    border-radius: 8px;
                    color: var(--av-fixed-secondary);
                    display: flex;
                    flex-direction: column;
                    position: relative;

                    &__header {
                        padding: 40px 40px 16px;
                        position: relative;
                        border-bottom: 1px solid #c3ccdb;
                        flex-shrink: 0;

                        &__name {
                            @mixin title;
                            color: var(--av-nav-primary);
                        }

                        &__position {
                            @mixin paragraph;
                            color: var(--av-fixed-primary);
                        }

                        &__social {
                            padding-top: 16px;
                            justify-content: center;
                            display: flex;

                            &__item {
                                display: flex;
                                flex-shrink: 0;
                                justify-content: center;
                                align-items: center;
                                margin-inline-end: 16px;
                                width: 24px;
                                height: 24px;
                                background: #408bea;
                                border-radius: 50%;

                                .a-glyph {
                                    fill: #fff;
                                }
                            }
                        }

                        &__close {
                            @mixin caption;
                            display: flex;
                            align-items: center;
                            border: none;
                            outline: none;
                            background: none;
                            cursor: pointer;
                            position: absolute;
                            top: 24px;
                            inset-inline-end: 24px;
                            color: var(--av-brand-secondary);

                            .a-glyph {
                                margin-inline-start: 10px;
                            }
                        }
                    }

                    &__content {
                        @mixin body;
                        padding: 32px 40px 48px;
                        overflow-y: auto;
                    }

                    &__click {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        inset-inline-start: 0;
                    }
                }
            }
        }
    }
}

.company-pages+.s-global-footer--locale-selector-visible {
    z-index: 2004;
}
</style>
