<template>
    <div class="wrapper">
        <a-picture
            :background="fixedBackground"
            :position="backgroundPosition"
            fit="cover"
            is-background
        />
        <s-basic-slice class="s-header-service" :class="className">
            <div class="s-header-service__content">
                <div class="s-header-service__top">
                    <h1 class="s-header-service__product">
                        {{ title }}
                    </h1>
                </div>
                <div class="s-header-service__left">
                    <ul v-if="links" class="s-header-service__links">
                        <li v-for="(link, index) in links" :key="index" class="s-header-service__link-item">
                            <a-link
                                v-bind="link"
                                :glyph-position="link.glyphPosition || 'left'"
                                :size="link.size || 'paragraph'"
                                :type="theme === 'dark' ? 'light' : 'regular'"
                            />
                        </li>
                    </ul>
                    <div v-if="updateText" class="s-header-service__update-text">
                        {{ updateText }}
                    </div>
                </div>
                <div v-if="cards" class="s-header-service__card-wrapper">
                    <div v-for="(card, index) in cards" :key="index" class="s-header-service__card">
                        <a-service-card
                            :title="card.title"
                            :large="cards.length > 1"
                            :description="card.description"
                            :links="card.links"
                        />
                    </div>
                </div>
            </div>
        </s-basic-slice>
    </div>
</template>

<script>
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';
import AServiceCard from '@core/components/service-card/index.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';

export default {
    name: 'SHeaderService',

    components: {
        SBasicSlice,
        ALink,
        AServiceCard,
        APicture,
    },

    mixins: [breakpoint],

    props: {
        theme: {
            type: String,
            default: 'light',
            required: false,
        },

        background: {
            type: Object,
            required: true,
        },

        backgroundPosition: {
            type: String,
            default: 'center',
        },

        title: {
            type: String,
            required: true,
        },

        links: {
            type: Array,
            required: false,
            default: undefined,
        },

        cards: {
            type: Array,
            default: () => [],
        },

        updateText: {
            type: String,
            required: false,
            default: undefined,
        },
    },

    computed: {
        fixedBackground() {
            if (typeof window === 'undefined') return this.background;

            const mobile = window.outerWidth < this.breakpoints.mobileWide
                ? this.background.mobile
                : this.background.tablet;

            return {
                ...this.background,
                mobile,
            };
        },

        className() {
            return {
                's-header-service_large': this.cards.length > 1,
                's-header-service_full': this.cards.length > 2,
                's-header-service_text': this.updateText,
                's-header-service_theme_dark': this.theme === 'dark',
                's-header-service_theme_light': this.theme === 'light',
            };
        },
    },
};
</script>

<style lang="postcss" scoped>
.a-container {
    width: 100%;
}

.a-service-card {
    width: 100%;
}

.s-header-service {
    width: 100%;
    display: flex;
    background-color: var(--av-solid-grey);
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 51px;
    padding-bottom: 24px;

    &_theme {
        &_dark {
            background-color: #00214d;

            .s-header-service__product {
                color: #fff;
            }
        }
    }

    &_text {
        .s-header-service__left {
            align-self: center;
        }

        .s-header-service__left {
            grid-row-start: 3;
            margin-top: 0;
            margin-bottom: 24px;
        }

        .s-header-service__card-wrapper {
            grid-row-start: 4;
        }

        @media (--viewport-mobile-wide) {
            .s-header-service__update-text {
                max-width: 50%;
            }
        }
        @media (--viewport-desktop) {
            .s-header-service__update-text {
                max-width: 100%;
            }

            .s-header-service__card-wrapper {
                grid-row-start: 3;
            }

            .s-header-service__left {
                margin-bottom: 0;
            }
        }
    }

    &_large {
        @media (--viewport-desktop) {
            .s-header-service__content {
                grid-template-rows: 2fr 1fr;
            }
        }
        @media (--viewport-mobile-wide) {
            .s-header-service__card {
                width: calc(50% - 8px);
            }
        }
    }

    &_full {
        @media (--viewport-mobile-wide) {
            .s-header-service__left {
                align-self: end;
                margin-bottom: 24px;
            }
        }
        @media (--viewport-desktop) {
            .s-header-service__content {
                grid-template-rows: 2fr 1fr 1fr;
                grid-template-columns: 1fr;
            }

            .s-header-service__card-wrapper {
                grid-row-start: 3;
            }

            .s-header-service__card {
                width: calc((100% / 3) - 11px);
            }
        }
    }

    &__card {
        width: 100%;
    }

    &__left {
        grid-row-start: 4;
        margin-top: 24px;
        @media (--viewport-mobile-wide) {
            grid-row-start: 3;
            margin-bottom: 24px;
            margin-top: 0;
        }
        @media (--viewport-desktop) {
            margin-bottom: 0;
        }
    }

    &__card-wrapper {
        grid-row-start: 3;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        grid-gap: 16px;
        @media (--viewport-mobile-wide) {
            grid-row-start: 4;
        }
    }

    &__alt-info {
        grid-row-start: 4;
        -ms-grid-row: 4;
        width: 100%;
    }

    &__top {
        width: 100%;
        -ms-grid-row: 1;
    }

    &__links {
        -ms-grid-row: 2;
    }

    &__content {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows:
            minmax(min-content, 350px) minmax(0, max-content)
            1fr;
        -ms-grid-columns: 100%;
        -ms-grid-rows: minmax(min-content, 350px) minmax(0, max-content) 1fr;
        height: 100%;

        @media (--viewport-desktop-wide) {
            grid-template-rows:
                minmax(min-content, 400px) minmax(0, max-content)
                1fr;
            -ms-grid-columns: 100%;
            -ms-grid-rows: minmax(min-content, 400px) minmax(0, max-content) 1fr;
        }
    }

    &__product {
        @mixin display-accent;
        color: #00204d;
        margin: 30px 0;
        max-width: 327px;

        @media (--viewport-mobile-wide) {
            @mixin hero-accent;
        }

        @media (--viewport-desktop) {
            @mixin large-accent;
        }
    }

    &__label-flag {
        font-weight: 600;
        color: var(--av-fixed-success);
    }

    &__info-flag {
        font-weight: 600;
        color: var(--av-inversed-light);
    }

    &__price {
        @mixin title;

        @media (--viewport-tablet) {
            @mixin display;
        }

        margin-bottom: 24px;
    }

    &__block {
        &:first-child {
            margin-bottom: 40px;
        }
    }

    &__title {
        @mixin title;

        margin-bottom: 48px;

        @media (--viewport-desktop) {
            @mixin display;
        }

        @media (--viewport-desktop-wide) {
            @mixin hero;
        }
    }

    &__contacts {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    &__link {
        &_call {
            color: var(--av-fixed-white);
        }
    }

    &__update-text {
        font-weight: 600;
    }

    &__buttons {
        margin-bottom: 24px;
    }

    &__button-wrapper {
        position: relative;

        &:not(:first-child) {
            margin-top: 16px;
        }
    }

    &__button {
        display: flex;
        justify-content: center;
        width: 100%;

        .a-glyph {
            display: none;
        }
    }

    &__button-disclaimer {
        @mixin caption;

        display: none;
        position: absolute;
        width: 100%;
        margin-top: 8px;
        text-align: center;
        color: var(--av-fixed-white-light);
    }

    &__link-item:not(:last-child) {
        margin-bottom: 16px;
    }

    @media (--viewport-mobile-wide) {
        &__product {
            margin: 40px 0;
        }

        &__top {
            width: 50%;
        }

        &__buttons {
            display: flex;
        }

        &__button-wrapper {
            width: calc(50% - 8px);
            min-width: 164px;

            &:not(:first-child) {
                margin-inline-start: 16px;
                margin-top: 0;
            }
        }

        &__button {
            display: inline-flex;
            justify-content: space-between;

            .a-glyph {
                display: block;
            }
        }
    }

    @media (--viewport-tablet) {
        padding-top: 67px;
        padding-bottom: 40px;

        &__bottom {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            &:first-child {
                margin-bottom: 40px;
            }
        }

        &__block {
            display: flex;

            &:first-child {
                align-items: flex-end;
                margin-bottom: 24px;
            }
        }

        &__price,
        &__links {
            flex-grow: 0;
            flex-shrink: 0;
            padding-inline-end: 16px;
        }

        &__price {
            margin-bottom: 0;
        }

        &__links {
            display: block;
        }

        &__buttons {
            margin-bottom: 16px;
        }

        &__button-wrapper {
            width: auto;
        }

        &__button {
            padding-top: 12px;
            padding-bottom: 12px;
        }
    }

    @media (--viewport-desktop) {
        height: 600px;
        padding-top: 72px;
        padding-bottom: 40px;

        &_large {
            height: 800px;
        }

        &__content {
            grid-template-columns: 4fr 8fr;
            -ms-grid-columns: 4fr 8fr;
            grid-template-rows:
                minmax(min-content, 450px) minmax(50px, max-content)
                1fr;
            -ms-grid-rows: minmax(min-content, 450px) minmax(50px, max-content) 1fr;
        }

        &__left {
            grid-row-start: 2;
            -ms-grid-row: 2;
        }

        &__card-wrapper {
            grid-row-start: 2;
            -ms-grid-row: 2;
            -ms-grid-column: 2;
            align-self: end;
        }

        &__alt-info {
            grid-row-start: 2;
            -ms-grid-row: 2;
            -ms-grid-column: 2;
            align-self: center;
        }

        &__actions {
            display: flex;
        }

        &__block {
            &:last-child {
                align-items: center;
            }
        }

        &__button-wrapper {
            min-width: 147px;
        }

        &__buttons {
            margin-inline-end: 16px;
            margin-bottom: 0;
        }

        &__button-disclaimer {
            display: block;
        }

        &__top {
            width: 100%;
        }
    }

    @media (--viewport-desktop-wide) {
        &__product {
            max-width: 373px;
        }

        &__button-wrapper {
            width: 180px;
        }
    }

    @media (--viewport-desktop-large) {
        &__product {
            max-width: 426px;
        }

        &__button-wrapper {
            width: 205px;
        }
    }
}
</style>
