<template>
    <div v-if="content" class="s-info-sem-ebook">
        <a-picture
            v-if="content.image && content.image.path"
            class="s-info-sem-ebook__screenshot"
            :link="content.image.path"
            :alt="content.image.alt || 'Acronis'"
        />
        <div class="s-info-sem-ebook__content">
            <div v-if="content.description" class="s-info-sem-ebook__description">
                <p
                    v-for="(item, i) in content.description"
                    :key="`description-${i}`"
                    class="s-info-sem-ebook__description-item"
                    v-html="item"
                />
            </div>
            <div v-if="content.list" class="s-info-sem-ebook__list-container">
                <div v-if="content.list.title" class="s-info-sem-ebook__list-title" v-html="content.list.title" />
                <ul v-if="content.list.items" class="s-info-sem-ebook__list-items">
                    <li
                        v-for="(item, i) in content.list.items"
                        :key="`list-${i}`"
                        class="s-info-sem-ebook__list-item"
                        v-html="item"
                    />
                </ul>
            </div>
            <div v-if="content.notes" class="s-info-sem-ebook__notes">
                <p
                    v-for="(item, i) in content.notes"
                    :key="`note-${i}`"
                    class="s-info-sem-ebook__note-item"
                    v-html="item"
                />
            </div>
        </div>
    </div>
</template>

<script>
import APicture from '@core/components/picture/picture.vue';
import form from '@core/mixins/form.js';

export default {
    name: 'SInfoSemEbook',

    components: {
        APicture,
    },

    mixins: [form],

    props: {
        content: {
            type: Object,
            default: null,
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-info-sem-ebook {
    text-align: start;
    padding: 40px 0 0;

    &__content {
        &:deep(a) {
            text-decoration: none;
            color: var(--av-brand-secondary);
        }
    }

    &__content {
        margin: 40px 0 0;
    }

    &__description {
        @mixin paragraph;
        font-weight: 500;
        color: var(--av-nav-primary);
    }

    &__description-item {
        &:not(:first-child) {
            margin: 16px 0 0;
        }
    }

    &__screenshot {
        width: 212px;
        margin: 0 auto;
        overflow: hidden;
        border-radius: 4px;
        border: 1px solid var(--av-fixed-lightest);
        box-shadow: 0 4px 8px var(--av-fixed-lightest);

        &:deep(img) {
            width: 100%;
        }
    }

    &__notes,
    &__list-container {
        @mixin paragraph;
        margin: 24px 0 0;
        color: var(--av-fixed-secondary);
    }

    &__list-items {
        margin: 8px 0 0;
    }

    &__list-item {
        padding-inline-start: 24px;
        position: relative;

        &:not(:first-child) {
            margin: 8px 0 0;
        }

        &:before {
            top: 10px;
            inset-inline-start: 8px;
            width: 4px;
            height: 4px;
            content: '';
            position: absolute;
            background: var(--av-fixed-secondary);
        }
    }
}

@media (--viewport-tablet) {
    .s-info-sem-ebook {
        padding: 40px 0 0 0;
        padding-inline-end: 16px;

        &__content {
            margin: 32px 0 0;
        }

        &__screenshot {
            margin: 0;
        }
    }
}

@media (--viewport-desktop) {
    .s-info-sem-ebook {
        max-width: 98%;
        margin: 40px 0 0;
        position: relative;
        padding-inline-start: 244px;
        padding-inline-end: 16px;

        &__content {
            margin: 0;
        }

        &__screenshot {
            top: 0;
            inset-inline-start: 0;
            position: absolute;
        }
    }
}

@media (--viewport-desktop-wide) {
    .s-info-sem-ebook {
        max-width: 90%;
        padding-inline-start: 195px;

        &__screenshot {
            width: 163px;
        }
    }
}

@media (--viewport-desktop-large) {
    .s-info-sem-ebook {
        max-width: 89.1%;
        padding-inline-start: 221px;
    }
}
</style>
