<template>
    <div v-if="list" :class="['dropdown', {'dropdown-disabled': disabled}]">
        <p class="description">
            {{ description }}
            <a-tooltip v-if="tooltip" glyph="info-circle" :text="tooltip" />
        </p>
        <div
            :class="['wrapper', { opened: isOpened }]"
            @mouseleave="isFocus = false"
        >
            <div :class="['selector', { 'selector-active': isSelectorActive }]" @click="clickOpen()">
                <div v-if="placeholder" class="title" v-html="placeholder" />
                <s-dropdown-item v-else :item="itemSelected" @changeQuantity="$emit('changeQuantity', $event)" />
                <div class="suffix">
                    <a-glyph name="drop-down" />
                </div>
            </div>

            <ul :class="classOptions">
                <li
                    v-for="(item, itemIndex) in list"
                    :key="`option-${itemIndex}`"
                    class="option"
                    @click="changeItem(item)"
                >
                    <el-checkbox
                        v-if="item.hasCheckbox"
                        v-model="item.isChecked"
                        @change="$emit('changeQuantity', { ...item, quantity: Number($event), isChecked: Boolean($event) })"
                    />
                    <s-dropdown-item :item="item" @changeQuantity="$emit('changeQuantity', $event)" />
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import AGlyph from '@core/components/glyph/glyph.vue';
import ATooltip from '@core/components/tooltip/tooltip.vue';
import mixin from '../mixin.js';
import SDropdownItem from './dropdown-item.vue';

export default {
    name: 'SDropdown',

    components: {
        AGlyph,
        ATooltip,
        SDropdownItem,
        elCheckbox: () => import('@uikit/ui-kit/packages/checkbox'),
    },

    mixins: [mixin],

    props: {
        description: {
            type: String,
            default: '',
        },

        list: {
            type: Array,
            default: () => [],
        },

        placeholder: {
            type: String,
            default: '',
        },

        isSelectorActive: {
            type: Boolean,
            default: false,
        },

        tooltip: {
            type: String,
            default: '',
        },

        disabled: {
            type: Boolean,
            default: false,
        },
    },

    emits: ['changeItem', 'changeQuantity', 'clickOpen'],

    data: () => ({
        isOpened: false,
        isLabel: false,
        isFocus: false,
    }),

    computed: {
        classOptions() {
            return [
                'options',
                { 'has-checkbox': this.hasCheckbox },
                { 'has-number-picker': this.hasNumberPicker },
            ];
        },

        hasCheckbox() {
            return this.list.find((item) => item.hasCheckbox) || false;
        },

        hasNumberPicker() {
            return this.list.find((item) => item.hasNumberPicker) || false;
        },

        itemSelected() {
            return this.list.find((item) => item.quantity || item.selected);
        },
    },

    methods: {
        clickOpen() {
            if (this.disabled) return;
            this.isFocus = true;
            this.isOpened = true;
            window.addEventListener('click', this.clickClose, true);
            window.addEventListener('scroll', this.scrollClose, true);
            this.$emit('clickOpen');
        },

        clickClose(el) {
            if (this.doNotClose(el)) return;
            this.close();
        },

        scrollClose() {
            if (this.isFocus) return;
            this.close();
        },

        close() {
            this.isOpened = false;
            window.removeEventListener('click', this.clickClose, true);
            window.removeEventListener('scroll', this.scrollClose, true);
        },

        doNotClose(el) {
            const elClassName = el?.target?.parentNode?.className || '';
            this.isLabel = elClassName.includes('label');
            const elements = [
                'label',
                'opened',
                'option',
                'el-input__wrapper',
                'el-checkbox__content',
                'el-checkbox__control',
                'el-num-picker',
            ];
            return this.hasNumberPicker && elements.some((item) => elClassName.includes(item));
        },

        changeItem(license) {
            if (license.hasNumberPicker && !this.isLabel) return;
            if (license.hasCheckbox) {
                this.$emit('changeItem', {
                    ...license,
                    quantity: Number(!license.quantity),
                    isChecked: Boolean(!license.quantity),
                });
                return;
            }
            this.$emit('changeItem', license);
        },
    },
};
</script>

<style lang="postcss" scoped>
.dropdown {
    user-select: none;
    min-width: 112px;

    &:not(:first-child) {
        width: 100%;
    }
}

.description {
    @mixin body;
    color: var(--av-fixed-primary);
}

.wrapper {
    display: flex;
    height: 40px;
    border-radius: 4px;
    position: relative;
    align-items: center;
    justify-content: space-between;
    background: var(--av-fixed-white);
    border: 1px solid var(--av-brand-light);

    &:hover {
        cursor: pointer;
    }

    &.opened {
        cursor: default;
    }

    &:before {
        cursor: pointer;
    }
}

.selector {
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
    flex-wrap: nowrap;
    align-items: center;
    color: var(--av-fixed-primary);
    justify-content: space-between;

    &:deep(.label-item) {
        line-height: 1;

        .label-text-promo {
            display: none;
        }
    }
}

.selector-active {
    background: var(--av-brand-secondary-accent);
    border: 1px solid var(--av-brand-secondary-light);
}

.title {
    @mixin body;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    line-height: 1.2;
    padding-inline-start: 16px;
    color: var(--av-nav-primary);
    justify-content: space-between;
}

.suffix {
    padding-inline-end: 16px;

    .a-glyph {
        transition: none;
        margin-bottom: 2px;
        vertical-align: middle;
        fill: var(--av-brand-primary);
    }
}

.options {
    z-index: 2;
    display: none;
    padding: 8px 0;
    margin: 0 -1px;
    width: calc(100% + 2px);
    border-radius: 0 0 4px 4px;
    border: 1px solid var(--av-brand-primary);
    border-top: 1px solid var(--av-brand-primary);
}

.option {
    position: relative;

    &:hover {
        background: var(--av-brand-secondary-bright-hover);
    }
}

.el-checkbox {
    top: 12px;
    position: absolute;
    inset-inline-start: 16px;

    .label {
        cursor: default;
    }
}

.has-checkbox {
    .label {
        font-weight: 400;
        padding-inline-start: 40px;
    }
}

.has-number-picker {
    padding: 24px 0;

    .option {
        &:not(:first-child) {
            margin-top: 16px;
        }

        &:hover {
            background: transparent;
        }
    }
}

.opened {
    border-radius: 4px 4px 0 0;
    border-color: var(--av-brand-primary);

    &:before {
        top: 0;
        z-index: 1;
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        inset-inline-start: 0;
    }

    .suffix {
        .a-glyph {
            transform: rotate(180deg);
        }
    }

    .options {
        inset-inline-start: 0;
        top: 100%;
        display: block;
        position: absolute;
        background: var(--av-fixed-white);
    }
}

.dropdown-disabled {
    .wrapper {
        border: 1px solid var(--av-brand-secondary-accent);
        background: var(--av-brand-lightest);

        &:hover {
            cursor: default;
        }
    }

    .title {
        color: var(--av-fixed-lighter);
    }

    &:deep(.label-text) {
        line-height: 1.2;
        color: var(--av-fixed-lighter);
    }

    .a-glyph {
        fill: var(--av-brand-secondary-light);
    }
}
</style>
