<template>
    <div v-if="subscription" class="s-dropdown-disaster-recovery">
        <div class="s-dropdown-disaster-recovery__title">
            {{ subscription.title }}
        </div>
        <div class="s-dropdown-disaster-recovery__wrapper" :class="className">
            <div class="s-dropdown-disaster-recovery__selector" @click="open()">
                <div class="s-dropdown-disaster-recovery__selector-title">
                    {{ title() }}
                </div>
                <div class="s-dropdown-disaster-recovery__price" v-html="setCurrency(price)" />
                <div class="s-dropdown-disaster-recovery__suffix">
                    <a-glyph class="s-dropdown-disaster-recovery__glyph" name="chevron-down" />
                </div>
            </div>
            <div class="s-dropdown-disaster-recovery__options">
                <div v-for="(item, optionIndex) in list" :key="`option-${optionIndex}`" class="s-dropdown-disaster-recovery__option">
                    <label class="s-dropdown-disaster-recovery__option-label">
                        <input
                            v-model="selectedOption"
                            class="s-dropdown-disaster-recovery__option-input"
                            name="disaster-recovery"
                            type="radio"
                            :value="optionIndex"
                            @change="setProductPeriod({ productID: product.id, quantity: selectedOption })"
                        />
                        <div class="s-dropdown-disaster-recovery__option-title">{{ title(item) }}</div>
                        <div class="s-dropdown-disaster-recovery__option-price" v-html="licensePrice(item)" />
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AGlyph from '@core/components/glyph/glyph.vue';
import mixin from '../mixin.js';

export default {
    name: 'SDropdownDisasterRecovery',

    components: {
        AGlyph,
    },

    mixins: [mixin],

    props: {
        /**
         * Settings
         */
        settings: {
            type: Object,
            default: null,
        },

        /**
         * Product
         */
        product: {
            type: Object,
            default: null,
        },

        /**
         * Price
         */
        price: {
            type: Number,
            default: 0,
        },
    },

    data: () => ({
        isActive: false,
        selectedOption: 0,
    }),

    computed: {
        className() {
            return {
                's-dropdown-disaster-recovery__opened': this.isActive,
            };
        },

        subscription() {
            return this.product.subscriptions;
        },

        selected() {
            return this.product?.selectedPeriod || 0;
        },

        type() {
            return this.subscription.isAdvanced ? 'advanced' : 'standard';
        },

        list() {
            return this.subscription[this.type];
        },

        license() {
            return this.list[this.selected];
        },
    },

    mounted() {
        this.selectedOption = this.product.selectedPeriod;
    },

    methods: {
        open() {
            this.isActive = true;
            window.addEventListener('click', this.close, true);
        },

        close() {
            this.isActive = false;
            window.removeEventListener('click', this.close, true);
        },

        title(current) {
            return current?.title || this.license.title;
        },

        licensePrice(item) {
            const cleverBridgeCartID = item.cleverBridgeCartID;
            const license = this.licenseData({ cleverBridgeCartID, promo: this.settings.promo });
            return this.setCurrency(license.price);
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-dropdown-disaster-recovery {
    &__title {
        @mixin body;
        color: var(--av-fixed-secondary);
    }

    &__wrapper {
        height: 40px;
        border-radius: 4px;
        position: relative;
        background: var(--av-fixed-white);
        border: 1px solid var(--av-brand-light);
    }

    &__selector {
        display: flex;
        padding: 7px 0;
        cursor: pointer;
        flex-wrap: nowrap;
        align-items: center;
        color: var(--av-fixed-primary);
        justify-content: space-between;

        &-title {
            @mixin paragraph-accent;
            white-space: nowrap;
            padding: 0 16px;
        }
    }

    &__price {
        width: 100%;
        text-align: end;
    }

    &__suffix {
        @mixin paragraph;
        padding-inline-end: 11px;
        padding-inline-start: 8px;

        .a-glyph {
            margin: 0 0 2px;
            transition: none;
            vertical-align: middle;
            fill: var(--av-brand-primary);
        }
    }

    &__options {
        z-index: 2;
        display: none;
        padding: 8px 0;
        margin: 0 -1px;
        width: calc(100% + 2px);
        border-radius: 0 0 4px 4px;
        border: 1px solid var(--av-brand-primary);
        border-top: 1px solid var(--av-brand-primary);
    }

    &__option {
        position: relative;

        &:hover {
            background: var(--av-brand-secondary-bright-hover);
        }

        &-title {
            @mixin paragraph-accent;
            white-space: nowrap;
            padding-inline-end: 8px;
            padding-inline-start: 16px;
        }

        &-description {
            @mixin descriptor;
            line-height: 18px;
            padding: 8px 16px 4px;
            padding-inline-end: 32px;
        }

        &-price {
            width: 100%;
            text-align: end;
        }

        &-label {
            width: 100%;
            display: flex;
            cursor: pointer;
            padding: 8px 0;
            padding-inline-end: 34px;
            justify-content: space-between;
        }

        &-input {
            top: 0;
            inset-inline-start: 0;
            opacity: 0;
            width: 1px;
            height: 1px;
            border: none;
            position: absolute;
        }
    }

    &__opened {
        border-radius: 4px 4px 0 0;
        border-color: var(--av-brand-primary);

        &:before {
            top: 0;
            inset-inline-start: 0;
            z-index: 1;
            content: '';
            width: 100%;
            height: 100%;
            cursor: default;
            position: absolute;
        }

        .s-dropdown-disaster-recovery {
            &__selector {
                cursor: default;
            }

            &__suffix {
                .a-glyph {
                    transform: rotate(180deg);
                }
            }

            &__options {
                inset-inline-start: 0;
                top: 100%;
                display: block;
                position: absolute;
                background: var(--av-fixed-white);
            }
        }
    }
}
</style>
