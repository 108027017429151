<template>
    <div class="s-serial-number-tab">
        <a-dangerous-html v-if="description" class="description" :content="description" />
        <div v-if="downloads.length > 0" class="download-links">
            <div class="download-links-tabs">
                <div v-for="(tab, i) in downloads" :key="i" class="download-links-tab">
                    <div v-if="tab.title" class="download-links-title">
                        {{ tab.title }}
                    </div>
                    <dynamic-link
                        v-for="(link, j) in tab.links"
                        :key="j"
                        class="download-link"
                        :to="link.to"
                    >
                        <div class="link-inner">
                            <a-glyph class="link-glyph" :name="link.glyph" />
                            <div class="link-title">
                                {{ link.title }}
                            </div>
                        </div>
                        <div v-if="link.text" class="link-text">
                            {{ link.text }}
                        </div>
                        <div v-if="link.description" class="link-desc">
                            {{ link.description }}
                        </div>
                    </dynamic-link>
                </div>
            </div>
        </div>
        <div class="main">
            <div v-if="title" class="title">
                {{ title }}
            </div>
            <div v-if="lead" class="lead">
                {{ lead }}
            </div>
            <form class="form" @submit.prevent="sendSerialNumber">
                <input
                    v-model="serialNumber"
                    class="text-input"
                    type="text"
                    :class="{ 'text-input_error': state === 'error' }"
                    :placeholder="inputPlaceholder"
                />
                <button class="submit" type="submit">
                    <div
                        v-if="isLoading"
                        v-loading="true"
                        class="loading"
                        el-loading-size="24"
                    />
                    <template v-else>
                        {{ submitText }}
                    </template>
                </button>
            </form>
            <div v-if="links" class="links">
                <a-link
                    v-for="(link, i) in links"
                    :key="i"
                    class="link"
                    v-bind="link"
                />
            </div>
        </div>
        <div v-if="state === 'success'" class="success">
            <a-glyph class="success-glyph" name="success" />
            <div class="success-title">
                {{ successTitle }}
            </div>
            <a-dangerous-html class="expiring-title" :content="expiringTitle" />
        </div>
        <div v-if="distributions.length > 0" class="distributions">
            <div v-if="moreThanOneDist" class="distributions-title">
                {{ downloadTitle }}
            </div>
            <div class="distributions-tabs">
                <div v-for="(distribution, i) in distributions" :key="i" class="distributions-tab">
                    <template v-if="moreThanOneDist">
                        <div v-if="distribution.title" class="distribution-title">
                            {{ distribution.title }}
                        </div>
                        <dynamic-link
                            v-for="(item, j) in distribution.distributions"
                            :key="j"
                            class="download-link"
                            :to="item.link"
                        >
                            <div class="link-inner">
                                <a-glyph class="link-glyph" name="download-arrow" />
                                <div class="link-title">
                                    {{ item.title }}
                                </div>
                            </div>
                            <div v-if="item.subtitle" class="link-text">
                                {{ item.subtitle }}
                            </div>
                        </dynamic-link>
                    </template>
                    <template v-else>
                        <div class="download-title">
                            {{ distribution.distributions[0].title }}
                        </div>
                        <a-button
                            class="download-button"
                            v-bind="distribution.distributions[0]"
                            :to="distribution.distributions[0].link"
                            type="action"
                            :text="downloadText"
                        />
                    </template>
                </div>
            </div>
        </div>
        <div v-if="state === 'error' && errorTitle" class="error">
            <a-glyph class="error-glyph" name="error" />
            <a-dangerous-html class="error-title" :content="errorTitle" />
            <a-dangerous-html v-if="errorLead" class="error-lead" :content="errorLead" />
        </div>
    </div>
</template>

<script>
import Loading from '@uikit/ui-kit/packages/loading/src/directive';
import { format as dateFNSFormat } from 'date-fns';
import { mapActions } from 'vuex';
import AButton from '@core/components/button/button.vue';
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALink from '@core/components/link/link.vue';

export default {
    name: 'SSerialNumberTab',
    components: {
        AButton,
        ALink,
        AGlyph,
        ADangerousHtml,
        DynamicLink,
    },
    directives: {
        Loading,
    },
    props: {
        title: {
            type: String,
            required: false,
            default: undefined,
        },

        description: {
            type: String,
            required: false,
            default: '',
        },

        lead: {
            type: String,
            required: false,
            default: undefined,
        },

        inputPlaceholder: {
            type: String,
            default: 'Serial number',
        },

        submitText: {
            type: String,
            default: 'Submit',
        },

        downloadText: {
            type: String,
            default: 'Download now',
        },

        downloadTitle: {
            type: String,
            default: 'Download',
        },

        links: {
            type: Array,
            default: () => [],
        },

        downloads: {
            type: Array,
            default: () => [],
        },

        productCode: {
            type: String,
            required: true,
        },

        messages: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            serialNumber: null,
            state: null,
            errorTitle: null,
            errorLead: null,
            successTitle: null,
            expiringTitle: null,
            isLoading: false,
            distributions: [],
        };
    },

    computed: {
        moreThanOneDist() {
            const allDistCount = this.distributions.reduce(
                (count, current) => count + current.distributions.length,
                0,
            );
            return allDistCount > 1;
        },

        downloadsToShow() {
            if (this.state !== 'success') {
                return this.downloads;
            }
            return this.downloads.filter((tab) => !tab.hideAfterCheck);
        },
    },

    beforeMount() {
        const blackListCharacters = /['"<>/\\|{}[\]%]/ig;
        this.serialNumber = (this.$route?.query?.serial || '').replace(blackListCharacters, '');

        if (this.serialNumber && this.productCode === 'files_connect') {
            this.sendSerialNumber();
        }
    },

    methods: {
        ...mapActions({
            checkSerialNumber: 'partners/checkSerialNumber',
        }),

        async sendSerialNumber() {
            this.state = null;
            this.errorTitle = null;
            this.errorLead = null;
            this.successTitle = null;
            this.expiringTitle = null;
            this.isLoading = true;
            this.distributions = [];

            const params = {
                serialNumber: this.serialNumber,
                productCode: this.productCode,
            };

            if (!this.serialNumber) {
                this.showError();
                this.isLoading = false;
                return;
            }

            let result;
            try {
                result = await this.checkSerialNumber(params);
            } catch (e) {
                // console.error(e);
                result = { data: { keyStatus: '' } };
            } finally {
                this.isLoading = false;
            }

            this.handleResult(result);
        },

        handleResult(result) {
            switch (result.data.keyStatus) {
                case 'invalid': {
                    const tmpl = this.messages.serialNumberError;
                    const err = tmpl.replace('@serial', this.serialNumber);
                    this.showError(err);
                    break;
                }
                case 'retired': {
                    this.showError(this.messages.serialNumberRetired);
                    break;
                }
                case 'expired': {
                    const date = this.formatDate(result.data.maintenanceExpiresAt);
                    const err = this.messages.serialNumberExpired.replace('@date', date);
                    this.showError(err, this.messages.supportNotify);
                    break;
                }
                case 'notfound': {
                    const tmpl = this.messages.serialNumberNotFound;
                    const err = tmpl.replace('@serial', this.serialNumber);
                    this.showError(err);
                    break;
                }
                case 'qualified': {
                    const date = this.formatDate(result.data.maintenanceExpiresAt);
                    const successTitle = this.messages.serialNumberSuccess
                        .replace('@serial', this.serialNumber)
                        .replace('@date', date);
                    this.showSuccess(successTitle);
                    this.distributions = result.data.distributions;

                    const days = result.data.maintenanceExpiresInDays;
                    if (days <= 30) {
                        const phrase = days !== 1 ? `${days} days` : `${days} day`;
                        const tmpl = this.messages.serialNumberExpiring;
                        this.expiringTitle = tmpl.replace('@days', phrase);
                    }

                    break;
                }
                default: {
                    this.showError('Unknown error occured, please try again later');
                }
            }
        },

        showError(title = '', lead = '') {
            this.state = 'error';
            this.errorTitle = title;
            this.errorLead = lead;
        },

        showSuccess(title) {
            this.state = 'success';
            this.successTitle = title;
        },

        formatDate(date = '') {
            return dateFNSFormat(new Date(date), 'MM/dd/yyyy');
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-serial-number-tab {
    .download-links {
        padding-bottom: 32px;
        margin-bottom: 64px;

        @media (--viewport-tablet) {
            padding-bottom: 80px;
            margin-bottom: 88px;
        }
    }

    .description {
        max-width: 763px;
        margin: 16px 0 64px;
    }

    .download-links-tabs {
        @media (--viewport-tablet) {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            column-gap: 16px;
        }
    }

    .download-links-tab {
        margin-bottom: 48px;

        @media (--viewport-tablet) {
            margin-bottom: 0;
        }
    }

    .download-links-title {
        @mixin paragraph;

        font-weight: 600;
        color: var(--av-nav-primary);
        margin-bottom: 24px;
    }

    .download-link {
        @mixin paragraph;

        display: block;
        text-decoration: none;
        color: var(--av-brand-secondary);
        margin-bottom: 24px;
    }

    .link-inner {
        display: flex;
        align-items: baseline;
    }

    .link-glyph {
        display: inline-block;
        vertical-align: middle;
        margin-inline-end: 8px;
        width: 14px;
        height: 14px;
        flex-shrink: 0;
    }

    .link-title {
        display: inline-block;
        vertical-align: middle;
        font-weight: 600;
    }

    .link-text {
        color: var(--av-fixed-primary);
        padding-inline-start: 22px;
    }

    .link-desc {
        color: var(--av-fixed-light);
        font-size: 14px;
        padding-inline-start: 22px;
    }

    .main,
    .success,
    .error {
        @media (--viewport-desktop) {
            max-width: 70%;
        }
        @media (--viewport-desktop-wide) {
            max-width: 60%;
        }
    }

    .title {
        @mixin display;

        color: var(--av-nav-primary);
        margin-bottom: 24px;

        @media (--viewport-tablet) {
            @mixin hero;
        }
    }

    .lead {
        @mixin lead;

        color: var(--av-fixed-primary);
        margin-bottom: 40px;

        @media (--viewport-tablet) {
            @mixin title;
        }
    }

    .form {
        margin-bottom: 48px;

        @media (--viewport-tablet) {
            display: flex;
            margin-bottom: 16px;
        }
    }

    .text-input {
        @mixin paragraph;

        width: 100%;
        font: var(--font-default);
        border-radius: 4px;
        background: var(--av-fixed-white);
        border: 1px solid var(--av-brand-light);
        height: 48px;
        margin-inline-end: 16px;
        padding: 12px 16px;
        outline: none;
        margin-bottom: 16px;

        &_error {
            border-color: var(--av-fixed-danger);
        }

        @media (--viewport-tablet) {
            margin-bottom: 0;
        }
    }

    .submit {
        height: 48px;
        font: var(--font-default);
        font-weight: 600;
        border-radius: 4px;
        color: var(--av-fixed-white);
        font-size: 16px;
        line-height: 24px;
        padding: 12px 32px;
        min-width: 120px;
        outline: none;
        border: 0;
        width: 100%;
        text-align: center;

        background: var(--av-nav-secondary);
        &:hover {
            background: var(--av-nav-secondary-darker);
        }
        &:active {
            background: var(--av-nav-secondary-darkest);
        }

        @media (--viewport-tablet) {
            width: auto;
        }
    }

    .links {
        margin-bottom: 24px;
    }

    .link {
        display: inline-block;
        text-decoration: none;
        margin-inline-end: 24px;
        margin-bottom: 16px;
    }

    .loading {
        width: 24px;
        height: 24px;
        margin: 0 auto;
    }

    .error,
    .success {
        border-radius: 4px;
        padding: 16px 24px;
        padding-inline-start: 48px;
        margin-bottom: 40px;
        overflow: hidden;
        word-break: break-word;
    }

    .error {
        color: var(--av-fixed-secondary);
        background: linear-gradient(0deg, rgba(217, 44, 35, 0.15), rgba(217, 44, 35, 0.15)),
            var(--av-fixed-white);
    }

    .success {
        color: var(--av-fixed-primary);
        background: linear-gradient(0deg, rgba(155, 194, 37, 0.15), rgba(155, 194, 37, 0.15)),
            var(--av-fixed-white);
    }

    .error-glyph,
    .success-glyph {
        display: inline-block;
        vertical-align: middle;
        width: 16px;
        height: 16px;
        margin-inline-start: -24px;
        margin-inline-end: 8px;
    }

    .error-glyph {
        fill: var(--av-fixed-danger);
    }

    .success-glyph {
        fill: var(--av-fixed-success);
    }

    .error-title,
    .success-title,
    .expiring-title {
        @mixin paragraph;

        display: inline;
        vertical-align: middle;
        font-weight: 600;
    }

    .expiring-title {
        display: inline-block;
        margin-top: 16px;
    }

    .error-lead {
        @mixin paragraph;
    }

    .distributions-title {
        @mixin title;

        font-weight: 600;
        color: var(--av-nav-primary);
        margin-bottom: 24px;
    }

    .distributions-tabs {
        @media (--viewport-tablet) {
            max-width: 740px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 16px;
        }
    }

    .distributions-tab {
        margin-bottom: 48px;

        @media (--viewport-tablet) {
            margin-bottom: 0;
        }
    }

    .distribution-title {
        @mixin paragraph;

        font-weight: 600;
        color: var(--av-nav-primary);
        margin-bottom: 24px;
    }

    .download-title {
        @mixin paragraph;

        font-weight: 600;
        margin-bottom: 24px;
    }

    .download-button {
        padding: 12px 16px;
        width: 200px;
        font-weight: 600;
    }
}
</style>

<style lang="postcss">
.s-serial-number-tab {
    .el-spinner--color-brand-primary {
        border-color: var(--av-fixed-white);
    }
}
</style>
