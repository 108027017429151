<template>
    <div class="event-on-demand-head" :class="className">
        <a-picture
            ref="bg"
            class="bg"
            :link="image"
            size="background"
            fit="cover"
        />
        <event-container>
            <div class="columns">
                <div ref="main" class="main">
                    <a :href="backLink.to" class="event-link back-link" :class="backLinkClassName">
                        <a-glyph class="glyph left" name="arrow-left" />
                        {{ backLink.title }}
                    </a>
                    <div class="labels">
                        <event-label
                            v-for="label in labels"
                            :key="label.title"
                            class="label"
                            v-bind="label"
                        />
                    </div>
                    <div class="title-with-video">
                        <div>
                            <h1 class="title">
                                {{ title }}
                            </h1>
                            <div v-if="action && !showCTAafterVideo" class="action-wrap">
                                <a :href="action.to" class="event-button action">{{ action.title }}</a>
                            </div>
                        </div>
                        <div v-if="video" v-show="isMounted" class="video-wrap">
                            <event-video
                                v-bind="video"
                                :ref="`video-${video.ytId}`"
                            />
                        </div>
                        <div v-if="action && showCTAafterVideo" class="action-wrap">
                            <a :href="action.to" class="event-button action">{{ action.title }}</a>
                        </div>
                    </div>
                    <event-head-features :features="features" :theme="theme" />
                </div>

                <div v-if="isFormShown" class="form-wrap">
                    <div ref="formInner" class="form-inner" :class="{ 'is-sticky': isFormSticky }">
                        <slot name="form" />
                    </div>
                </div>

                <div class="about-wrap">
                    <slot name="about" />
                </div>
            </div>
        </event-container>
        <a-modal
            v-if="video"
            :id="video.ytId"
            is-youtube
            type="youtube"
            :use-layout="false"
        >
            <a-youtube
                :yt-id="video.ytId"
                :params="video.ytParams || { autoplay: 1 }"
                @progressChanged="$emit('progressChanged', $event)"
            />
        </a-modal>
    </div>
</template>

<script>
import AGlyph from '@core/components/glyph/glyph.vue';
import AModal from '@core/components/modal/modal.vue';
import APicture from '@core/components/picture/picture.vue';
import AYoutube from '@core/components/youtube/youtube.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import EventContainer from '../shared-components/event-container/event-container.vue';
import EventHeadFeatures from '../shared-components/event-head-features/event-head-features.vue';
import EventLabel from '../shared-components/event-label/event-label.vue';
import EventVideo from '../shared-components/event-video/event-video.vue';

export default {
    name: 'EventOnDemandHead',

    components: {
        APicture,
        AModal,
        AYoutube,
        EventVideo,
        EventHeadFeatures,
        AGlyph,
        EventLabel,
        EventContainer,
    },
    mixins: [breakpoint],
    props: {
        title: {
            type: String,
            required: true,
        },
        action: {
            type: Object,
            default: () => null,
        },
        theme: {
            type: String,
            default: 'light',
        },
        features: {
            type: Array,
            default: null,
        },
        labels: {
            type: Array,
            required: true,
        },
        image: {
            type: String,
            required: true,
        },
        video: {
            type: Object,
            default: null,
        },
        backLink: {
            type: Object,
            required: true,
        },
        isFormShown: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['progressChanged'],
    data() {
        return {
            isMounted: false,
            isFormSticky: true,
        };
    },
    computed: {
        className() {
            return {
                light: this.theme === 'light',
                'no-form': !this.isFormShown,
            };
        },
        backLinkClassName() {
            return {
                white: this.theme === 'dark',
            };
        },
        showCTAafterVideo() {
            return this.isFormShown || !this.isDesktop;
        },
    },
    mounted() {
        this.isMounted = true;
        window.addEventListener('resize', this.onResize);
        this.onResize();
    },
    async updated() {
        await this.$nextTick();
        this.onResize();
    },
    methods: {
        onResize() {
            const mainBlockHeight = this.$refs.main.offsetHeight;
            this.$refs.bg.$el.style.height = `${mainBlockHeight}px`;
            if (!this.isFormShown) return;

            const formHeight = this.$refs.formInner.clientHeight;
            this.isFormSticky = formHeight < document.body.clientHeight;
        },
        triggerVideoClick() {
            const videoRef = this.$refs[`video-${this.video?.ytId}`];
            videoRef?.triggerClick();
        },
    },
    destroy() {
        window.removeEventListener('resize', this.onResize);
    },
};
</script>

<style lang="postcss" scoped>
.event-on-demand-head {
    position: relative;
    padding-bottom: 80px;
    border-bottom: 1px solid var(--av-brand-secondary-light);

    .event-container {
        overflow: visible;

        &:deep(.container) {
            position: relative;
        }
    }

    .columns {
        @media (--viewport-desktop) {
            display: grid;
            grid-column-gap: 16px;
            grid-template-columns: 7fr 5fr;
        }
    }

    .main {
        color: var(--av-fixed-white);
        padding: 102px 0 48px;

        @media (--viewport-tablet) {
            padding: 102px 0 48px;
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        @media (--viewport-desktop-wide) {
            padding: 108px 0 48px;
        }
    }

    .form-wrap {
        margin: 56px 0;
        top: 0;
        inset-inline-end: 0;

        @media (--viewport-desktop) {
            grid-row: span 2;
            margin-top: 102px;
            margin-bottom: 0;
        }
    }

    .form-inner {
        &.is-sticky {
            position: sticky;
            top: 8px;
        }
    }

    .about-wrap {
        margin: 56px auto 0;
        display: flex;
        flex-direction: column;

        @media (--viewport-desktop) {
            margin-top: 0;
            padding-top: 64px;
        }
    }

    .bg {
        position: absolute;
        top: 0;
        inset-inline: 0;
        z-index: -1;
        height: 0;

        &:deep(.a-picture__img) {
            width: 100%;
            height: 100%;
        }
    }

    .back-link {
        display: block;
        text-align: center;
        margin-bottom: 32px;

        @media (--viewport-desktop) {
            display: inline-block;
            text-align: start;
        }
    }

    .labels {
        margin-top: auto;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        @media (--viewport-desktop) {
            justify-content: flex-start;
        }
    }

    .label {
        @media (--viewport-tablet) {
            margin-inline-end: 6px;
            margin-inline-start: 0;
        }
    }

    .title {
        @mixin display-accent;
        color: var(--av-fixed-white);
        text-align: center;
        margin-bottom: 0;
        word-break: break-word;

        @media (--viewport-mobile-wide) {
            @mixin hero-accent;
        }

        @media (--viewport-desktop) {
            @mixin large-accent;
            text-align: start;
        }
    }

    .video-wrap {
        @mixin colls 12;
        margin: 24px auto 24px;

        @media (--viewport-mobile-wide) {
            @mixin colls 8;
        }

        @media (--viewport-desktop) {
            width: 70%;
            margin-inline: 0;
        }
    }

    .action-wrap {
        position: relative;
        display: flex;
        justify-content: center;
        margin-block: 16px 32px;

        @media (--viewport-desktop) {
            margin-block: 32px 32px;
            justify-content: flex-start;
        }
    }

    .video-wrap + .action-wrap {
        margin-top: 16px;
    }

    .action {
        @mixin paragraph-accent;
        padding: 12px 16px;
    }

    &:deep(.event-head-features) {
        margin-top: 24px;

        @media (--viewport-tablet) {
            justify-content: center;
        }
        @media (--viewport-desktop) {
            justify-content: flex-start;
        }
    }

    &.light {
        .main,
        .title {
            color: var(--av-nav-primary);
        }
    }

    .title-with-video {
        display: flex;
        flex-direction: column;
    }

    &.no-form {
        @media (--viewport-desktop) {
            .title-with-video {
                display: grid;
                grid-template-columns: 7fr 5fr;
                gap: 32px;
                margin-bottom: 16px;
            }
            .columns {
                display: block;
            }
            .video-wrap {
                width: auto;
                margin: 0;
            }
        }
    }
}
</style>
