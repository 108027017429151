<template lang="html">
    <s-basic-slice class="root" v-bind="$attrs">
        <div ref="widgetWrapper" class="trustpilot-widget" v-bind="widgetOptions">
            <a :href="externalLink" target="_blank" rel="noopener">Trustpilot</a>
        </div>
    </s-basic-slice>
</template>

<script>
import { mapGetters } from 'vuex';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';

export default {
    name: 'STrustpilot',

    components: {
        SBasicSlice,
    },

    props: {
        businessUnitID: {
            type: String,
            required: true,
        },
        templateID: {
            type: String,
            required: true,
        },
        locale: {
            type: String,
            required: true,
        },
        languages: {
            type: String,
            required: true,
        },
        stars: {
            type: String,
            required: false,
            default: () => '1,2,3,4,5',
        },
        theme: {
            type: String,
            required: false,
            default: () => 'light',
            validator: (value) => ['light', 'dark'].includes(value),
        },
        height: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            externalLink: 'https://www.trustpilot.com/review/www.acronis.com',
            scriptSrc: 'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js',
        };
    },

    computed: {
        ...mapGetters('config', ['$config']),
        scriptNonce() {
            return this.$config?.cspNonce || '';
        },
        widgetOptions() {
            return {
                'data-businessunit-id': this.businessUnitID,
                'data-template-id': this.templateID,
                'data-locale': this.locale,
                'data-review-languages': this.languages,
                'data-stars': this.stars,
                'data-theme': this.theme,
                'data-style-height': this.height,
                'data-style-width': '100%',
            };
        },
    },

    mounted() {
        const script = document.createElement('script');
        script.src = this.scriptSrc;
        script.async = true;
        script.setAttribute('nonce', this.scriptNonce);
        document.body.appendChild(script);
    },
};
</script>
