import { render, staticRenderFns } from "./content-form.vue?vue&type=template&id=a4973c90&scoped=true"
import script from "./content-form.vue?vue&type=script&lang=js"
export * from "./content-form.vue?vue&type=script&lang=js"
import style0 from "./content-form.vue?vue&type=style&index=0&id=a4973c90&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a4973c90",
  null
  
)

export default component.exports