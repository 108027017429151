<template>
    <s-basic-slice :id="id" class="s-video-featured-wrapper" :class="basicClassName">
        <a-picture
            v-if="backgroundImage"
            :background="backgroundImage"
            is-background
            fit="cover"
        />
        <div class="s-video-featured" :class="className">
            <div class="a-container">
                <div class="s-video-featured__container">
                    <div class="s-video-featured__video-container">
                        <a-media
                            v-if="media"
                            v-bind="trans(media)"
                        />
                        <a-video-card
                            v-else
                            :yt-id="ytId"
                            :time="time"
                            :image="videoImage"
                            :text="trans(videoTitle)"
                        />
                    </div>
                    <a-slice-header
                        :title="trans(title)"
                        :lead="trans(lead)"
                        :body-text="trans(bodyText)"
                    />
                </div>
            </div>
        </div>
    </s-basic-slice>
</template>

<script>
import AMedia from '@core/components/media/media.vue';
import APicture from '@core/components/picture/picture.vue';
import ASliceHeader from '@core/components/slice-header/slice-header.vue';
import AVideoCard from '@core/components/video-card/video-card.vue';
import contentMixin from '@core/mixins/content.js';
import styleMixin from '@core/mixins/style.js';
import translations from '@core/mixins/translations.js';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';

export default {
    name: 'SVideoFeatured',

    components: {
        APicture,
        AMedia,
        ASliceHeader,
        AVideoCard,
        SBasicSlice,
    },

    mixins: [translations, contentMixin, styleMixin],

    props: {
        backgroundImage: {
            type: [Object, Boolean],
            default: () => false,
        },

        media: {
            type: Object,
            default: undefined,
        },

        time: {
            type: String,
            default: '',
        },

        ytId: {
            type: String,
            default: '',
        },

        videoTitle: {
            type: String,
            default: '',
        },

        videoImage: {
            type: String,
            required: true,
        },

        theme: {
            type: String,
            required: false,
            default: 'dark',
        },

        isReversed: {
            type: Boolean,
            required: false,
        },

        withSmallLead: {
            type: Boolean,
            required: false,
        },
    },

    data() {
        return {
            modalId: `yt_id-${this.ytId}`,
        };
    },

    computed: {
        className() {
            return {
                's-video-featured_theme_dark': this.theme === 'dark',
                's-video-featured_theme_light': this.theme === 'light',
                's-video-featured_reversed': this.isReversed,
                's-video-featured_with-small-lead': this.withSmallLead,
            };
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-video-featured-wrapper {
    position: relative;

    & > {
        &:deep(.a-container) {
            padding: 0;
            position: unset;
            max-width: none;
        }
    }
}

.s-video-featured {
    position: relative;
    background: no-repeat 50% 50% / cover;
    overflow-x: hidden;

    &__container {
        @media (--viewport-tablet) {
            margin: 0 -8px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    &:deep(.a-slice-header) {
        @media (--viewport-tablet) {
            @mixin colls 6;

            margin: 0 8px;
        }

        &__title {
            margin-bottom: 16px;

            @media (--viewport-mobile-wide) {
                @mixin display;

                margin-bottom: 24px;
            }

            @media (--viewport-desktop) {
                @mixin hero;
            }
        }

        &__lead {
            &:not(:only-child) {
                margin-top: 16px;

                @media (--viewport-mobile-wide) {
                    margin-top: 24px;
                }
            }

            @media (--viewport-mobile-wide) {
                @mixin lead;
            }

            @media (--viewport-desktop) {
                @mixin title;
            }
        }
    }

    &__video-container {
        margin-bottom: 32px;

        @media (--viewport-tablet) {
            @mixin colls 6;

            margin: 0 8px;
        }

        @media (--viewport-desktop-wide) {
            @mixin colls 5;
        }
    }

    &_theme {
        &_dark {
            &:deep(.a-slice-header) {
                margin-bottom: 0;

                &__title {
                    color: var(--av-fixed-white);
                }

                &__lead {
                    color: var(--av-fixed-white);
                }

                &__body-text {
                    color: var(--av-fixed-white);
                }
            }
        }

        &_light {
            &:deep(.a-slice-header) {
                &__title {
                    color: var(--av-nav-primary);
                }

                &__lead {
                    color: var(--av-nav-primary);
                }

                &__body-text {
                    color: var(--av-nav-primary);
                }
            }
        }
    }

    &_reversed {
        .s-video-featured {
            &__container {
                @media (--viewport-tablet) {
                    flex-direction: row-reverse;
                }
            }
        }
    }

    &_with-small-lead {
        &:deep(.a-slice-header__lead) {
            @mixin paragraph;
        }
    }
}
</style>
