<template>
    <div class="s-purchasing-acp-cart">
        <div class="close" @click="$emit('toggleCart', 'cart')" />
        <div class="wrapper">
            <div class="title">
                <div>{{ translation('Cart') }}</div>
                <a-button
                    size="s"
                    glyph="arrow-left"
                    :event="{ doNotSendGA: true }"
                    :text="translation('Back to shopping')"
                    @click="$emit('toggleCart', 'cart')"
                />
            </div>

            <div v-if="addedLicenses.length" class="order">
                <s-order-edition :settings="settings" />
                <s-order-addon :settings="settings" />
            </div>

            <s-warnings
                :list="warnings"
                @addCloud="addCloud()"
                @changePage="$emit('toggleCart', 'cart')"
                @changeItem="$emit('changeItem', $event)"
                @changeQuantity="$emit('changeQuantity', $event)"
                @cartRecommendation="$emit('cartRecommendation', $event)"
            />

            <s-addon-list
                :settings="settings"
                @changeQuantity="$emit('changeQuantity', $event)"
                @changeAddon="$emit('changeAddon', $event)"
                @toggleProduct="$emit('toggleProduct', $event)"
            />

            <s-total :settings="settings" />
        </div>
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import mixin from '../mixin.js';
import SAddonList from './addon-list.vue';
import SOrderAddon from './order-addon.vue';
import SOrderEdition from './order-product.vue';
import STotal from './total.vue';
import SWarnings from './warnings.vue';

export default {
    name: 'SCart',

    components: {
        AButton,
        SAddonList,
        SOrderAddon,
        SOrderEdition,
        STotal,
        SWarnings,
    },

    mixins: [mixin],

    props: {
        settings: {
            type: Object,
            default: null,
        },
    },

    emits: ['toggleCart', 'changeAddon', 'toggleProduct', 'changeItem', 'changeQuantity', 'cartRecommendation'],

    computed: {
        priceTotal() {
            return this.addedLicenses.map((license) => license.quantity * license.store.price).reduce((a, b) => a + b, 0);
        },
    },

    beforeDestroy() {
        window.removeEventListener('keyup', this.onKeyUp, true);
    },

    mounted() {
        window.addEventListener('keyup', this.onKeyUp, true);
    },

    methods: {
        onKeyUp(event) {
            if (event.keyCode !== 27) return;
            this.$emit('toggleCart');
        },

        addCloud() {
            const product = this.settings.products.find((item) => item.product === 'cloud-storage');
            this.$emit('toggleProduct', product);
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-purchasing-acp-cart {
    top: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
    position: fixed;
    overflow: auto;
    inset-inline-end: 0;
    overscroll-behavior: none;
    background: var(--av-fixed-white);

    @media (--viewport-tablet) {
        background: rgba(0, 47, 114, 0.4);
    }
}

.close {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}

.wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    background: var(--av-fixed-white);

    @media (--viewport-tablet) {
        top: 0;
        width: 598px;
        position: absolute;
        inset-inline-end: 0;
    }

    @media (--viewport-desktop) {
        width: 624px;
    }

    @media (--viewport-desktop-wide) {
        width: 672px;
    }
}

.order {
    padding: 32px 16px;
    background: #f5f9fe;
    border-bottom: 1px solid var(--av-brand-secondary-light);

    @media (--viewport-tablet) {
        padding: 32px 40px;
    }
}

.title {
    @mixin title-accent;
    display: flex;
    flex-wrap: wrap;
    padding: 24px 16px 32px;
    align-items: center;
    color: var(--av-nav-primary);
    justify-content: space-between;
    background: var(--av-brand-secondary-accent);
    border-bottom: 1px solid var(--av-brand-secondary-light);

    @media (--viewport-tablet) {
        padding-inline-end: 40px;
        padding-inline-start: 40px;
    }

    .a-button {
        @mixin paragraph-accent;
        height: 32px;
        display: flex;
        flex-direction: row-reverse;
        color: var(--av-brand-primary);
        box-shadow: 0 0 0 1px var(--av-brand-primary) inset;
    }

    &:deep(.a-glyph) {
        margin-inline-start: 0;
        margin-inline-end: 8px;
        fill: var(--av-brand-primary);
    }
}

.warnings {
    padding: 16px 40px 0;
    background: var(--av-fixed-white);

    @media (--viewport-tablet) {
        padding: 16px 40px 0;
    }
}
</style>
