<script lang="ts">
import { StatusCodes } from 'http-status-codes';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { ProductAPIQueryBuilder } from '@api/builders/product';
import { CARD_FIELDS_SUBSET, SECTION_ID_CPC, SECTION_SLUG_BY_ID } from '@model/const/blog';
import { HttpRejection } from '@model/http/rejection';
import { assertHttpErrors } from '@utils/api-response';
import { getTextDirection, getOgImage, ACRONIS_OG_IMAGE_ID, getHtmlLang } from '@utils/html-meta';
import BlogCPCHomeComponent from './component.vue';

const CARDS_NUMBER_ON_PAGE = 6;
const AUTHORS_COUNT = 6;

export default Vue.extend({
    name: 'BlogCPCHomeContainer',

    async serverPrefetch(): Promise<void> {
        // Init
        const locale = this.$route.params.locale;
        const page = parseInt(this.$route.params.page, 10) || 1;

        // Determine if we should proceed
        await this.$store.dispatch('locales/getLocaleData', { locale });
        const cpcIsActiveForLocale = this.$store.state.locales.locale.is_active_on_blog_cpc;
        if (!cpcIsActiveForLocale) {
            throw new HttpRejection('Premature rendering stop', StatusCodes.NOT_FOUND);
        }

        const latestCardsRequest = new ProductAPIQueryBuilder('latestCards')
            .setEntityPath('/api/blog/posts/')
            .setLocales([locale])
            .addMatchesAll('section_id', '=', SECTION_ID_CPC.toString())
            .addMatchesAll('is_news', '<>', '1')
            .setCustomParam('process-macros', '1')
            .setOutputOnly(CARD_FIELDS_SUBSET)
            .addSort('translation.published_at', 'desc')
            .addSort('id', 'desc')
            .setPaginate(page, CARDS_NUMBER_ON_PAGE, page === 1 ? 1 : null)
            .toObject();

        await this.$store.dispatch('blog/getEntity', { request: latestCardsRequest });

        const latestCards = this.$store.state.blog.latestCards;

        assertHttpErrors([{ entity: latestCards, throwIfEmpty: true }]);

        // Get the rest of entities
        const CPCNewsRequest = new ProductAPIQueryBuilder('CPCNews')
            .setEntityPath('/api/blog/posts/')
            .setLocales([locale])
            .addMatchesAll('is_news', '=', '1')
            .addMatchesAll('section_id', '=', SECTION_ID_CPC.toString())
            .setCustomParam('process-macros', '1')
            .setOutputOnly(CARD_FIELDS_SUBSET)
            .addSort('translation.published_at', 'desc')
            .addSort('id', 'desc')
            .setPaginate(1, 6)
            .toObject();

        const spotlightedCPCRequest = new ProductAPIQueryBuilder('spotlightedCPC')
            .setEntityPath('/api/blog/posts/')
            .setLocales([locale])
            .addMatchesAll('translation.is_spotlighted', '=', '1')
            .addMatchesAll('section_id', '=', SECTION_ID_CPC.toString())
            .addMatchesAll('is_news', '<>', '1')
            .setCustomParam('process-macros', '1')
            .setOutputOnly(CARD_FIELDS_SUBSET)
            .addSort('translation.published_at', 'desc')
            .addSort('id', 'desc')
            .setPaginate(1, 1)
            .toObject();

        const categoriesRequest = new ProductAPIQueryBuilder('categories')
            .setEntityPath('/api/blog/categories/')
            .setLocales([locale])
            .addMatchesAll('section_id', '=', SECTION_ID_CPC.toString())
            .setCustomParam('process-macros', '1')
            .setOutputOnly(['id', 'section_id', 'slug', 'title'])
            .toObject();

        const authorsRequest = new ProductAPIQueryBuilder('authors')
            .setEntityPath('/api/blog/authors/')
            .addMatchesAll('translation.is_featured', '=', '1')
            .setCustomParam('has', { sections: [SECTION_ID_CPC] })
            .setCustomParam('process-macros', '1')
            .setOutputOnly(['id', 'name', 'photo', 'position', 'slug'])
            .setPaginate(1, AUTHORS_COUNT)
            .setLocales([locale])
            .toObject();

        const translationsListRequest = new ProductAPIQueryBuilder('sectionTranslationsList')
            .setEntityPath('/api/blog/sections/translations/')
            .addMatchesAll('section_id', '=', SECTION_ID_CPC.toString())
            .setOutputOnly(['locale'])
            .setPaginate(1, 20)
            .toObject();

        await Promise.all([
            this.$store.dispatch('slices/getSyncedData', { slice: 's-main-header', locale }),
            this.$store.dispatch('slices/getSyncedData', { slice: 'blog', locale }),
            this.$store.dispatch('blog/getEntity', { request: CPCNewsRequest }),
            this.$store.dispatch('blog/getEntity', { request: spotlightedCPCRequest }),
            this.$store.dispatch('blog/getEntity', { request: categoriesRequest }),
            this.$store.dispatch('blog/getEntity', { request: authorsRequest }),
            this.$store.dispatch('blog/getEntity', { request: translationsListRequest }),
        ]);

        assertHttpErrors([
            { entity: this.$store.state.blog.CPCNews },
            { entity: this.$store.state.blog.spotlightedCPC },
            { entity: this.$store.state.blog.categories },
            { entity: this.$store.state.blog.authors },
            { entity: this.$store.state.blog.sectionTranslationsList },
        ]);

        // Building page meta
        this.$ssrContext.res.meta = this.getMeta();
    },

    computed: {
        ...mapGetters('config', ['$config']),
    },

    methods: {
        getMeta(): any {
            const locale = this.$route.params.locale;
            const uiStrings: any = this.$store.state.slices.items.blog || {};
            const canonical = `https://${this.$config.domain}${this.$route.path}`;
            const page = parseInt(this.$route.params.page, 10) || 1;
            const slug = SECTION_SLUG_BY_ID[SECTION_ID_CPC];
            const urlBase = `https://${this.$config.domain}/${locale}/${slug}/`;
            const totalPages = this.$store.state.blog.latestCards.pagination?.pages_total || 1;
            const paginationLinks = [];

            if (page > 1) {
                const href = page === 2 ? urlBase : `${urlBase}page/${page - 1}/`;
                paginationLinks.push({ tag: 'link', rel: 'prev', href });
            }
            if (totalPages > page) {
                const href = `${urlBase}page/${page + 1}/`;
                paginationLinks.push({ tag: 'link', rel: 'next', href });
            }

            const ogImage = getOgImage(`@${ACRONIS_OG_IMAGE_ID}`, this.$config.env.HEAD_SITE_MAIN_PUBLIC_BASE_URL_STORAGE);
            const title = uiStrings.cpcMetaTitle;
            const description = uiStrings.cpcMetaDescription;

            return {
                title,
                head: [
                    { tag: 'meta', name: 'title', content: title },
                    { tag: 'meta', name: 'description', content: description },
                    { tag: 'meta', property: 'og:title', content: title },
                    { tag: 'meta', property: 'og:description', content: description },
                    { tag: 'meta', property: 'og:image', content: ogImage },
                    { tag: 'meta', property: 'og:url', content: canonical },
                    { tag: 'meta', name: 'twitter:title', content: title },
                    { tag: 'meta', name: 'twitter:description', content: description },
                    { tag: 'meta', name: 'twitter:image', content: ogImage },
                    { tag: 'meta', name: 'twitter:url', content: canonical },
                    { tag: 'link', rel: 'image_src', href: ogImage },
                    { tag: 'link', rel: 'canonical', href: canonical },
                    ...paginationLinks,
                ],
                htmlAttrs: {
                    dir: getTextDirection(locale),
                    lang: getHtmlLang(locale),
                },
            };
        },

        async loadLatestCardsPage(page) {
            const request = new ProductAPIQueryBuilder('latestCards')
                .setEntityPath('/api/blog/posts/')
                .setLocales([this.$route.params.locale])
                .addMatchesAll('section_id', '=', SECTION_ID_CPC.toString())
                .addMatchesAll('is_news', '<>', '1')
                .setOutputOnly(CARD_FIELDS_SUBSET)
                .addSort('translation.published_at', 'desc')
                .addSort('id', 'desc')
                .setPaginate(page, CARDS_NUMBER_ON_PAGE, 1)
                .toObject();

            await this.$store.dispatch('blog/getEntity', { request });

            return this.$store.state.blog[request.id].httpStatus === StatusCodes.OK;
        },
    },

    render(h) {
        const props = { dispatchLoadLatestCardsPage: this.loadLatestCardsPage };
        return h(BlogCPCHomeComponent, { props });
    },
});
</script>
