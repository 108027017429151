/* eslint-disable max-len */
export const Figma = 'https://www.figma.com/design/eM4W6JS2MjG1gp5YhKoMp7Dd/Slices?node-id=14738-14695&t=JMJ2fbzoLIVoGwai-4';

export const Default = {
    args: {
        translations: {
            title: 'Resources',
            'link.text': 'More Documents',
            note: 'Looking for help? Email us at <a href="mailto:access-sales@acronis.com">access-sales@acronis.com</a> or call <a href="tel:+1 781-782-9000">+1 781-782-9000<a>',
            'cards.0.title': "What's New",
            'cards.0.text': "What's New in Acronis Backup Advanced Edition",
            'cards.1.title': 'Card with glyph',
            'cards.1.text': 'PDF, 0.9 MB',
            'cards.2.title': 'Executive Summary',
            'cards.2.text': 'Hundreds of G2 Crowd users rank Acronis Backup higher than Veritas',
            'cards.3.title': 'Whitepaper',
            'cards.3.text': 'Six costly data protection gaps in Microsoft Office 365 and how to close them',
            'cards.4.title': "What's New",
            'cards.4.text': "What's New in Acronis Backup Advanced Edition",
            'cards.5.title': 'Executive Summary',
            'cards.5.text': 'Hundreds of G2 Crowd users rank Acronis Backup higher than Veritas',
        },
        background: 'light-blue',
        title: 'title',
        link: { text: 'link.text', to: '#' },
        note: 'note',
        cards: [
            {
                to: '#1',
                preferred: true,
                title: 'cards.0.title',
                text: 'cards.0.text',
            },
            {
                to: '#2',
                preferred: true,
                title: 'cards.1.title',
                text: 'cards.1.text',
                glyph: 'download-arrow',
            },
            {
                to: '#3',
                preferred: true,
                title: 'cards.2.title',
                text: 'cards.2.text',
            },
            {
                to: '#4',
                preferred: true,
                title: 'cards.3.title',
                text: 'cards.3.text',
            },
            {
                to: '#1',
                preferred: true,
                title: 'cards.4.title',
                text: 'cards.4.text',
            },
            {
                to: '#3',
                preferred: true,
                title: 'cards.5.title',
                text: 'cards.5.text',
            },
        ],
    },
};
