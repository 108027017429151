import breakpoint from '@core/mixins/breakpoint.js';
// @ts-ignore
import ResourceBreadcrumbs from '@core/slices/resource-center/resource-breadcrumbs/resource-breadcrumbs.vue';
// @ts-ignore
import ResourceSearchActionBar from '@core/slices/resource-center/resource-search-action-bar/resource-search-action-bar.vue';
// @ts-ignore
import ResourceSearchFiltersPanel from '@core/slices/resource-center/resource-search-filters-panel/resource-search-filters-panel.vue';
// @ts-ignore
import ResourceSearchItem from '@core/slices/resource-center/resource-search-item/resource-search-item.vue';
// @ts-ignore
import ResourceSearchPagination from '@core/slices/resource-center/resource-search-pagination/resource-search-pagination.vue';
// @ts-ignore
import ResourceSearchPanel from '@core/slices/resource-center/resource-search-panel/resource-search-panel.vue';
// @ts-ignore
import ResourcePage from '@core/slices/resource-center/shared-components/resource-page.vue';
import { LOCALE_DEFAULT } from '@model/const/locales';
import { RC_LOCAL_STORAGE_SEARCH_FILTERS } from '@model/const/resource-center';
import { extendResourceData, handleSearchQuery } from '@utils/resource-center';

// TODO: replace this mixin with Vue 3 composable when will it be possible (useSearch) or something like that
export default {
    mixins: [breakpoint],

    components: {
        ResourceSearchPagination,
        ResourceSearchItem,
        ResourceSearchActionBar,
        ResourceSearchFiltersPanel,
        ResourceSearchPanel,
        ResourceBreadcrumbs,
        ResourcePage,
    },

    props: {
        page: {
            type: Number,
            default: 1,
        },
    },

    data: () => ({
        filterPanelVisible: false,
        searchRequest: '',
        currentPage: 1,
        searchData: {} as any,
        searchIsPending: false,
        isMounted: false,
        filterType: '',
    }),

    computed: {
        filterKeys() {
            return ['products', 'types', 'audiences', 'tags', 'industries'];
        },

        commonTranslations() {
            return this.$store.state.slices.items['resource-center'] || {};
        },

        locale() {
            return this.$route.params.locale || LOCALE_DEFAULT;
        },

        uiStrings() {
            return this.$store.state.slices.items['resource-center'] || {};
        },

        resources() {
            const fn = (item: any) => extendResourceData(item, this.locale);
            return this.$store.state.resourceCenter.resources.items?.map(fn);
        },

        products() {
            const products = Object.values(this.$store.state?.products?.items || [])
                .filter((product: any) => !product.is_virtual)
                .map((product: any) => ({
                    id: product.id,
                    title: `${this.commonTranslations.productPrefix} ${product.name}`,
                }));

            products.unshift({ id: 0, title: this.commonTranslations.all });
            return products;
        },

        types() {
            return this.$store.state.resourceCenter.types.items;
        },

        audiences() {
            return this.$store.state.resourceCenter.audiences.items;
        },

        industries() {
            return this.$store.state.resourceCenter.industries.items.map((industry: any) => ({
                id: industry.id,
                title: industry.name,
            }));
        },

        tags() {
            return this.$store.state.resourceCenter.tags.items;
        },

        totalResults() {
            return this.$store.state.resourceCenter.resources.pagination.items_total || 0;
        },

        totalPages() {
            return this.$store.state.resourceCenter.resources.pagination?.pages_total || 0;
        },
    },

    created() {
        const searchQuery: any = this.$route.query;
        this.currentPage = this.page;

        if (!searchQuery.sort) {
            this.$router.replace({ query: { ...searchQuery, sort: 'recent' } });
        }

        this.searchData = { ...this.searchData, ...handleSearchQuery(searchQuery) };

        if (searchQuery.searchString?.length) {
            this.searchRequest = decodeURIComponent(searchQuery.searchString);
            this.searchData.searchString = this.searchRequest;
        }
    },

    async mounted() {
        await this.search();
        this.isMounted = true;
    },

    methods: {
        applySearchFilters(selectedFilters: Object) {
            // we don't want to remove searchString, page and sort params here
            this.filterKeys
                .filter((key: string) => key !== this.filterType)
                .forEach((key: string) => delete this.searchData[key]);

            Object.entries(selectedFilters).forEach(([filter, value]) => {
                this.searchData[filter] = value;
            });

            this.search({ resetPage: true });
        },

        saveFiltersToLocalStorage(filters: Object) {
            localStorage.setItem(RC_LOCAL_STORAGE_SEARCH_FILTERS, JSON.stringify(filters));
        },

        triggerResetFilters() {
            this.searchData = { sort: 'recent' };
            this.currentPage = 1;
            this.$refs.searchPanel?.resetSearchRequest();
            this.$refs.filtersPanel?.resetFilters();
        },

        applySortRule(rule: string) {
            this.searchData.sort = rule;
            this.search({ resetPage: true });
        },

        toggleFiltersPanel(state: Boolean) {
            this.filterPanelVisible = state;
        },

        applySearchString() {
            if (this.searchRequest?.length) {
                this.searchData.searchString = this.searchRequest;
            } else {
                delete this.searchData.searchString;
            }

            this.search({ resetPage: true });
        },

        setPage(pageNumber: number) {
            this.currentPage = pageNumber;
            this.search();
        },

        setSearchQueryParams() {
            this.saveFiltersToLocalStorage(this.searchData);

            const query: any = this.getQuery;
            delete query.page;

            if (this.currentPage === 1) {
                this.$router.replace({
                    name: this.redirectPathNameFirstPage,
                    params: this.$route.params,
                    query,
                }).catch(() => {});
                return;
            }

            this.$router.replace({
                name: this.redirectPathNameOtherPages,
                params: {
                    ...this.$route.params,
                    page: this.currentPage,
                },
                query,
            }).catch(() => {});
        },

        async search(options = { resetPage: false }) {
            if (this.searchIsPending) return;

            this.searchIsPending = true;

            if (options.resetPage) {
                this.currentPage = 1;
            } else {
                this.searchData.sort = this.searchData.sort || 'recent';
                this.currentPage = this.currentPage || 1;
            }

            this.searchData = {
                ...this.searchData,
                page: this.currentPage,
                ...this.extendedSearchParams,
            };
            const searchParams = {
                searchData: this.searchData,
                locale: this.locale,
            };

            this.setSearchQueryParams();

            await this.$store.dispatch('resourceCenter/applySearchData', searchParams);

            if (typeof document !== 'undefined') {
                document.scrollingElement.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            }

            this.searchIsPending = false;
        },
    },
};
