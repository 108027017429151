<script lang="ts">
import { StatusCodes } from 'http-status-codes';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { ProductAPIQueryBuilder } from '@api/builders/product';
import structuredData from '@core/mixins/structuredData';
import { CARD_FIELDS_SUBSET, SECTION_ID_CPC } from '@model/const/blog';
import { HttpRejection } from '@model/http/rejection';
import { assertHttpErrors } from '@utils/api-response';
import { getOgImage, getTextDirection, getHtmlLang } from '@utils/html-meta';
import BlogPostComponent from './component.vue';

const MORE_POSTS_ON_PAGE = 4;

export default Vue.extend({
    name: 'BlogPostContainer',

    mixins: [structuredData],

    async serverPrefetch(): Promise<void> {
        // Init
        const locale = this.$route.params.locale;
        const previewKey = this.$route.query.key;
        const slug = this.$route.params.post;
        const sectionID: number = SECTION_ID_CPC;

        if (!sectionID) {
            throw new HttpRejection('Premature rendering stop', StatusCodes.NOT_FOUND);
        }

        // ==== Get a post and determine if we should proceed
        const postRequest = new ProductAPIQueryBuilder('post')
            .setEntityPath('/api/blog/posts/')
            .setLocales([locale])
            .addMatchesAll('slug', '=', slug)
            .setCustomParam('process-macros', '1')
            .setPaginate(1, 1)
            .setCustomParam('post', previewKey ? { key: previewKey } : null)
            .toObject();

        await this.$store.dispatch('blog/getEntity', { request: postRequest });

        const postState = this.$store.state.blog.post;

        assertHttpErrors([{ entity: postState, throwIfEmpty: true }]);

        const post = postState.items[0];

        if (post.section && post.section.id !== sectionID) {
            const fixedURL = `/${locale}/${post.section.slug}/posts/${slug}/`;
            const rejection = new HttpRejection('Premature rendering stop', StatusCodes.MOVED_PERMANENTLY);
            rejection.addHeader('Location', fixedURL);
            throw rejection;
        }

        // Get the rest of entities
        if (previewKey) {
            this.$ssrContext.res.headers.push({ key: 'Cache-Control', val: 'no-store' });
        }

        const siblingPostRequestBase = new ProductAPIQueryBuilder('prevNextBase')
            .setEntityPath('/api/blog/posts/')
            .setLocales([locale])
            .addMatchesAll('section_id', '=', sectionID.toString())
            .setCustomParam('process-macros', '1')
            .setOutputOnly(['id', 'slug', 'section_id'])
            .setPaginate(1, 1);

        const nextPostRequest = siblingPostRequestBase
            .clone('nextPost')
            .addMatchesAll('translation.published_at', '>', post.published_at)
            .setCustomParam('process-macros', '1')
            .addSort('translation.published_at', 'asc')
            .addSort('id', 'desc')
            .toObject();

        const prevPostRequest = siblingPostRequestBase
            .clone('previousPost')
            .addMatchesAll('translation.published_at', '<', post.published_at)
            .setCustomParam('process-macros', '1')
            .addSort('translation.published_at', 'desc')
            .addSort('id', 'desc')
            .toObject();

        const categoriesRequest = new ProductAPIQueryBuilder('categories')
            .setEntityPath('/api/blog/categories/')
            .setLocales([locale])
            .addMatchesAll('section_id', '=', sectionID.toString())
            .setCustomParam('process-macros', '1')
            .setOutputOnly(['id', 'section_id', 'slug', 'title'])
            .toObject();

        const morePostsRequest = new ProductAPIQueryBuilder('morePosts')
            .setEntityPath('/api/blog/posts/')
            .setLocales([locale])
            .addMatchesAll('slug', '<>', slug)
            .addMatchesAll('section_id', '=', sectionID.toString())
            .setOutputOnly(CARD_FIELDS_SUBSET)
            .addSort('translation.published_at', 'desc')
            .addSort('id', 'desc')
            .setPaginate(1, MORE_POSTS_ON_PAGE)
            .toObject();

        const translationsListRequest = new ProductAPIQueryBuilder('postTranslationsList')
            .setEntityPath('/api/blog/posts/translations/')
            .addMatchesAll('post_id', '=', post.id)
            .setOutputOnly(['locale'])
            .setPaginate(1, 50) // 50 should be safe like, forever
            .toObject();

        await Promise.all([
            this.$store.dispatch('slices/getSyncedData', { slice: 's-main-header', locale }),
            this.$store.dispatch('slices/getSyncedData', { slice: 'blog', locale }),
            this.$store.dispatch('seo/getSyncedData', { key: 'routes-config', locale }),
            this.$store.dispatch('seo/getSyncedData', { key: 'schema-webpage', locale }),
            this.$store.dispatch('seo/getSyncedData', { key: 'schema-article', locale }),
            this.$store.dispatch('seo/getSyncedData', { key: 'schema-breadcrumbs', locale }),
            this.$store.dispatch('seo/getSyncedData', { key: 'schema-video-object', locale }),
            this.$store.dispatch('blog/getEntity', { request: nextPostRequest }),
            this.$store.dispatch('blog/getEntity', { request: prevPostRequest }),
            this.$store.dispatch('blog/getEntity', { request: morePostsRequest }),
            this.$store.dispatch('blog/getEntity', { request: translationsListRequest }),
            this.$store.dispatch('blog/getEntity', { request: categoriesRequest }),
            this.$store.dispatch('products/getProducts', locale),
        ]);

        assertHttpErrors([
            { entity: this.$store.state.blog.nextPost },
            { entity: this.$store.state.blog.previousPost },
            { entity: this.$store.state.blog.morePosts },
            { entity: this.$store.state.blog.postTranslationsList },
            { entity: this.$store.state.blog.cta },
            { entity: this.$store.state.blog.ctaData },
        ]);

        // Building page meta
        this.$ssrContext.res.meta = await this.getMeta(post);
    },

    computed: {
        ...mapGetters('config', ['$config']),
        ...mapGetters('seo', ['getRoutesConfig', 'getStaticConfigs']),
    },

    methods: {
        async getMeta(post: Record<string, any>): any {
            const locale = this.$route.params.locale;

            const canonical = `https://${this.$config.domain}${this.$route.path}`;

            const ogimage = getOgImage(`@${post.image_id}`, this.$config.env.HEAD_SITE_MAIN_PUBLIC_BASE_URL_STORAGE);
            const title = post.seo_title;
            const description = post.seo_description;

            const ldJsonSchema = await this.getStructuredDataMarkup({
                siteID: this.$config.siteID,
                domain: this.$config.domain,
                schemasConfig: this.getStaticConfigs,
                routesConfig: this.getRoutesConfig,
            });

            return {
                title,
                head: [
                    { tag: 'meta', name: 'title', content: title },
                    { tag: 'meta', name: 'description', content: description },
                    { tag: 'meta', property: 'og:title', content: title },
                    { tag: 'meta', property: 'og:description', content: description },
                    { tag: 'meta', property: 'og:image', content: ogimage },
                    { tag: 'meta', property: 'og:url', content: canonical },
                    { tag: 'meta', name: 'twitter:title', content: title },
                    { tag: 'meta', name: 'twitter:description', content: description },
                    { tag: 'meta', name: 'twitter:image', content: ogimage },
                    { tag: 'meta', name: 'twitter:url', content: canonical },
                    { tag: 'link', rel: 'image_src', href: ogimage },
                    { tag: 'link', rel: 'canonical', href: canonical },
                ],
                htmlAttrs: {
                    dir: getTextDirection(locale),
                    lang: getHtmlLang(locale),
                },
                ldJsonSchema,
            };
        },

        sendViewBeacon() {
            const request = new ProductAPIQueryBuilder('viewBeacon')
                .setCustomParam('post_id', this.$store.state.blog.post.items[0].id)
                .setCustomParam('locale', this.$route.params.locale)
                .toObject();

            this.$store.dispatch('blog/sendViewBeacon', { request });
        },
    },

    render(h) {
        const props = { dispatchSendViewBeacon: this.sendViewBeacon };
        return h(BlogPostComponent, { props });
    },
});
</script>
