// TODO: Remove after WEB-43441
export const INDUSTRY = [
    {
        code: 'Other',
        name: 'Other',
    },
    {
        code: 'Information',
        name: 'Technology',
    },
    {
        code: 'Health Care and Social Assistance',
        name: 'Healthcare',
    },
    {
        code: 'Educational Services',
        name: 'Education',
    },
    {
        code: 'Manufacturing',
        name: 'Manufacturing',
    },
    {
        code: 'Public Administration',
        name: 'Government',
    },
    {
        code: 'Transportation and Warehousing',
        name: 'Logistics',
    },
];

export const COMPANY = [
    {
        code: 'Corporate End-Customer',
        name: 'For use at my company',
    },
    {
        code: 'Service Provider',
        name: 'To resell to customers',
    },
    {
        code: 'Prosumer End-Customer',
        name: 'For home use',
    },
];

export const EMPLOYEES = [
    {
        code: '6-10',
        name: '1-10',
    },
    {
        code: '51-100',
        name: '11-100',
    },
    {
        code: '101-250',
        name: '101-250',
    },
    {
        code: '251-500',
        name: '251-500',
    },
    {
        code: '501-1000',
        name: '501-1000',
    },
    {
        code: '1001-5000',
        name: '1001-5000',
    },
    {
        code: '5001+',
        name: '5001+',
    },
];

export default {};
