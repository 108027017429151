<template>
    <div class="wrapper">
        <a-picture :background="background" is-background fit="cover" />
        <div class="s-slice s-header-product">
            <div class="s-header-product__inner">
                <div class="s-header-product__main">
                    <div class="a-container">
                        <div class="s-header-product__content">
                            <div class="s-header-product__top">
                                <div class="s-header-product__product">
                                    {{ getData('productName') }}
                                </div>
                                <a-product-cta :settings="{ hasLabel: true, ...productPageCTA }" />
                            </div>
                            <div class="s-header-product__bottom">
                                <div class="s-header-product__block">
                                    <ul class="s-header-product__links">
                                        <li
                                            v-for="(link, linkIndex) in getData('links')"
                                            :key="`link-${linkIndex}`"
                                            class="s-header-product__link-item"
                                        >
                                            <a-link
                                                v-bind="link"
                                                :glyph-position="link.glyphPosition || 'left'"
                                                :type="link.type || 'light'"
                                                :size="link.size || 'paragraph'"
                                            />
                                        </li>
                                    </ul>
                                    <h1 class="s-header-product__title">
                                        {{ getData('title') }}
                                    </h1>
                                </div>
                                <div class="s-header-product__block">
                                    <div class="s-header-product__cta">
                                        <a-product-cta :settings="{ hasDescription: true, ...productPageCTA }" />
                                    </div>
                                    <div class="s-header-product__actions">
                                        <div v-if="isVisible('buttons')" class="s-header-product__buttons">
                                            <div
                                                v-for="(button, index) in getData('buttons')"
                                                :key="button.main"
                                                class="s-header-product__button-wrapper"
                                            >
                                                <a-button
                                                    class="s-header-product__button"
                                                    v-bind="button"
                                                    :glyph="button.glyph || 'arrow'"
                                                    :size="button.size || 's'"
                                                    :type="button.type || getButtonType(index)"
                                                    :event="setEvent(button)"
                                                />
                                                <div
                                                    v-if="button.disclaimer"
                                                    class="s-header-product__button-disclaimer"
                                                >
                                                    {{ button.disclaimer }}
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="isVisible('actionLinks')" class="s-header-product__contacts">
                                            <a-link
                                                v-for="link in getData('actionLinks')"
                                                :key="link.main"
                                                v-bind="link"
                                                class="s-header-product__link"
                                                :type="link.type || 'light'"
                                                :size="link.size || 'paragraph'"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';
import AProductCta from '@core/components/product-cta/product-cta.vue';
import analytics from '@core/mixins/analytics.js';
import cleverbridge from '@core/mixins/cleverbridge.js';
import contentMixin from '@core/mixins/content.js';
import form from '@core/mixins/form.js';
import productMixin from '@core/mixins/product.js';
import purchasing from '@core/mixins/purchasing.js';
import styleMixin from '@core/mixins/style.js';

export default {
    name: 'SHeaderProduct',

    components: {
        AProductCta,
        AButton,
        ALink,
        APicture,
    },

    mixins: [contentMixin, styleMixin, productMixin, purchasing, form, analytics, cleverbridge],

    props: {
        background: {
            type: Object,
            required: true,
        },

        productName: {
            type: String,
            default: '',
        },

        title: {
            type: String,
            default: '',
        },

        productPageCTA: {
            type: Object,
            default: undefined,
        },

        actionLinks: {
            type: Array,
            validator: (actionLinks) => actionLinks.length < 3,
            default: () => [],
        },

        links: {
            type: Array,
            default: () => [],
        },

        buttons: {
            type: Array,
            validator: (buttons) => buttons.length < 3,
            default: () => [],
        },
    },

    methods: {
        getButtonType(index) {
            return index || this.buttons.length === 1 ? 'action' : 'main';
        },
    },
};
</script>

<style lang="postcss" scoped>
.wrapper {
    position: relative;
}

.s-header-product {
    height: 736px;
    color: var(--av-fixed-white);
    display: flex;
    background-color: #091f58;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 51px;
    padding-bottom: 0;

    &__inner {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    &__main {
        flex: 1;
        display: flex;
        width: 100%;
        padding-bottom: 24px;
    }

    &__content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__product {
        @mixin hero-subtle;
        margin: 16px 0;
        max-width: 327px;

        @media (--viewport-desktop-wide) {
            @mixin large-subtle;
        }
    }

    &__links {
        display: none;
    }

    &__title {
        @mixin title;

        @media (--viewport-desktop) {
            @mixin display;
        }

        @media (--viewport-desktop-wide) {
            @mixin hero;
        }
    }

    &__block {
        &:first-child {
            margin-bottom: 40px;
        }
    }

    &__buttons {
        margin-bottom: 24px;
    }

    &__button-wrapper {
        position: relative;

        &:not(:first-child) {
            margin-top: 16px;
        }
    }

    &__button {
        display: flex;
        justify-content: center;
        width: 100%;

        .a-glyph {
            display: none;
        }
    }

    &__button-disclaimer {
        @mixin caption;

        display: none;
        position: absolute;
        width: 100%;
        margin-top: 8px;
        text-align: center;
        color: var(--av-fixed-white-light);
    }

    &__contacts {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    &__link {
        &_call {
            color: var(--av-fixed-white);
        }
    }

    &:deep(.cta) {
        margin-bottom: 24px;
        color: var(--av-fixed-white);

        @media (--viewport-desktop) {
            @mixin display;
            margin-bottom: 0;
        }
    }

    &:deep(.cta-label) {
        font: var(--font-default);
        font-weight: 600;
        margin-bottom: 24px;
    }

    &:deep(.cta-description),
    &:deep(.cta-description del) {
        @mixin title;
        margin: 4px 0 0;

        @media (--viewport-desktop) {
            @mixin display;
        }
    }

    &:deep(.cta-description) {
        del {
            opacity: 0.7;
            color: var(--av-fixed-white-light);
        }
    }

    @media (--viewport-mobile-wide) {
        &__buttons {
            display: flex;
        }

        &__button-wrapper {
            width: calc(50% - 8px);
            min-width: 164px;

            &:not(:first-child) {
                margin-inline-start: 16px;
                margin-top: 0;
            }
        }

        &__button {
            display: inline-flex;
            justify-content: space-between;

            .a-glyph {
                display: block;
            }
        }
    }

    @media (--viewport-tablet) {
        height: 622px;
        padding-top: 67px;

        &__main {
            padding-bottom: 40px;
        }

        &__block {
            display: flex;

            &:first-child {
                align-items: flex-end;
                margin-bottom: 48px;
            }
        }

        &__links {
            @mixin colls 4;

            flex-grow: 0;
            flex-shrink: 0;
            margin-inline-end: 16px;
        }

        &__links {
            display: block;
        }

        &__link-item:not(:last-child) {
            margin-bottom: 16px;
        }

        &__buttons {
            margin-bottom: 16px;
        }

        &__button-wrapper {
            width: auto;
        }

        &__button {
            padding-top: 12px;
            padding-bottom: 12px;
        }

        &__cta {
            @mixin colls 4;
            flex-grow: 0;
            flex-shrink: 0;
            margin-inline-end: 16px;
        }
    }

    @media (--viewport-desktop) {
        height: 536px;
        padding-top: 64px;

        &__main {
            padding-bottom: 40px;
        }

        &__actions {
            display: flex;
            align-items: center;
        }

        &__button-wrapper {
            min-width: 147px;
        }

        &__buttons {
            margin-inline-end: 16px;
            margin-bottom: 0;
        }

        &__block {
            &:last-child {
                align-items: flex-start;
            }
        }

        &__button-disclaimer {
            display: block;
        }
    }

    @media (--viewport-desktop-wide) {
        &__product {
            max-width: 373px;
        }

        &__button-wrapper {
            width: 180px;
        }
    }

    @media (--viewport-desktop-large) {
        &__product {
            max-width: 426px;
        }

        &__button-wrapper {
            width: 205px;
        }
    }
}
</style>
