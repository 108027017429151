<template>
    <ul v-if="content" class="cta">
        <li v-for="(item, i) in list" :key="`item-${i}`" class="cta-item">
            <component
                :is="item.isButton ? 'a-button' : 'a-link'"
                :text="item.text"
                :to="item.to"
                :type="elementType(item)"
                :size="elementSize(item)"
                :glyph="elementGlyph(item)"
                :event="elementEvent(item)"
            />
        </li>
    </ul>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import ALink from '@core/components/link/link.vue';

export default {
    name: 'SHomePageNewCta',

    components: {
        AButton,
        ALink,
    },

    props: {
        content: {
            type: Object,
            default: null,
        },

        activeSection: {
            type: String,
            default: null,
        },
    },

    computed: {
        list() {
            return Object.entries(this.content).map((item) => ({
                isButton: item[0] === 'button',
                ...item[1],
            }));
        },
    },

    methods: {
        elementType(item) {
            if (item.type === 'transparent') {
                return '';
            }
            return item.type || (item.isButton ? 'action' : 'regular');
        },

        elementGlyph(item) {
            return item.glyph || (item.isButton ? '' : 'arrow');
        },

        elementSize(item) {
            return item.size || (item.isButton ? 's' : 'paragraph');
        },

        elementEvent(item) {
            return {
                event: 'Acronis',
                category: 'Conversions',
                label: item.to,
                action: item.text,
                ...item.sendEventGA,
            };
        },
    },
};
</script>

<style lang="postcss" scoped>
.cta {
    margin-top: auto;

    &:deep(.a-link) {
        @mixin body;
        z-index: 4;
        display: block;
        position: relative;
        pointer-events: all;
        margin-bottom: 24px;

        &:hover,
        &:focus {
            .a-link__content {
                background: none;
                color: var(--av-fixed-white-light);

                .a-glyph {
                    transition: unset;
                    fill: var(--av-fixed-white-light);
                }
            }
        }
    }

    &:deep(.a-link__content) {
        @mixin body;
        font-weight: bold;
        padding-inline-end: 20px;
        color: var(--av-fixed-white);
    }

    &:deep(.a-glyph) {
        top: 6px;
        width: 14px;
        height: 14px;
        fill: var(--av-fixed-white);
    }

    &:deep(.a-button) {
        @mixin body;
        color: #aed2ff;
        pointer-events: all;

        @media (--viewport-tablet) {
            padding: 8px;
        }
    }

    &:deep(.a-button_type) {
        color: var(--av-fixed-white);
    }
}

.cta-item {
    &:not(:first-child) {
        margin-top: 16px;
    }
}
</style>
