<template>
    <s-basic-slice :id="id" class="s-screenshots" :class="{ ...className, ...basicClassName }">
        <a-slice-header :title="title" :lead="lead" />
        <figure v-if="isItemSingle" class="s-screenshots__item">
            <div class="s-screenshots__img__wrapper">
                <a-media class="screenshots__img" v-bind="items[0].media" :persist-original="true" />
            </div>
            <figcaption v-if="items[0].description" class="s-screenshots__description">
                {{ items[0].description }}
            </figcaption>
        </figure>
        <div v-else class="s-screenshots__content">
            <a-slider class="s-screenshots__slider" :options="{ ...sliceSliderOptions, slideToClickedSlide: !hasModals }">
                <a-slide v-for="(item, index) in items" :key="`innovation-leadership-slide-${index}`" class="s-screenshots__slide">
                    <figure class="s-screenshots__item">
                        <div class="s-screenshots__img__wrapper">
                            <a-media
                                v-bind="item.media"
                                :type="itemHasModal(item) ? 'screenshot' : item.media.type"
                                :disable-modal="itemHasModal(item)"
                                class="screenshots__img"
                                :modal-id="item.media?.modalId || item.media?.modal?.modalId || null"
                                :enlarged-image="item.media?.modal?.enlargedImage || null"
                                fit="contain"
                                :is-shadow="false"
                            />
                        </div>
                        <figcaption v-if="item.description" class="s-screenshots__description">
                            {{ item.description }}
                        </figcaption>
                    </figure>
                </a-slide>
            </a-slider>
        </div>
        <a-link
            v-if="link"
            class="s-screenshots__link"
            v-bind="link"
            :type="link.type || 'direct'"
            :glyph="link.glyph || 'arrow'"
        />
        <template v-if="hasModals">
            <template v-for="(modal, i) in modals">
                <a-modal
                    v-if="modal"
                    :id="modal.modalId"
                    :key="`screenshots_enlarged_modals_${i}`"
                    :is-overlay-close="modal.isOverlayClose || true"
                    :caption="modal.caption"
                    :type="modal.type || 'screenshot'"
                >
                    <template #default>
                        <a-picture
                            :link="modal.enlargedImage"
                            position="center"
                            :is-shadow="false"
                            persist-original
                        />
                    </template>
                    <template v-if="modal.type === 'gallery'" #gallery>
                        <a-modal-gallery
                            :caption="modal.caption"
                            :next-id="modal.nextId"
                            :prev-id="modal.prevId"
                        />
                    </template>
                </a-modal>
            </template>
        </template>
    </s-basic-slice>
</template>

<script>
import ALink from '@core/components/link/link.vue';
import AMedia from '@core/components/media/media.vue';
import AModalGallery from '@core/components/modal/modal-gallery.vue';
import AModal from '@core/components/modal/modal.vue';
import APicture from '@core/components/picture/picture.vue';
import ASliceHeader from '@core/components/slice-header/slice-header.vue';
import ASlide from '@core/components/slider/slide.vue';
import ASlider from '@core/components/slider/slider.vue';
import Modal from '@core/directives/modal.js';
import contentMixin from '@core/mixins/content.js';
import styleMixin from '@core/mixins/style.js';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';

export default {
    name: 'SScreenshots',

    components: {
        ALink,
        AMedia,
        APicture,
        ASliceHeader,
        ASlide,
        AModal,
        ASlider,
        SBasicSlice,
        AModalGallery,
    },

    directives: { Modal },

    mixins: [contentMixin, styleMixin],

    props: {
        items: {
            type: Array,
            required: true,
        },

        isRowLayout: {
            type: Boolean,
            default: false,
        },

        isWideImage: {
            type: Boolean,
            default: false,
        },

        isRowReversed: {
            type: Boolean,
            default: false,
        },

        isShadow: {
            type: Boolean,
            default: false,
        },

        isCentered: {
            type: Boolean,
            default: false,
        },

        sliderOptions: {
            type: Object,
            required: false,
            default: () => ({}),
        },

        link: {
            type: Object,
            required: false,
            default: undefined,
        },
    },

    data() {
        return {
            sliceSliderOptions: {
                // disabled due to Swiper conflict between the Fraction pagination and slidesPerView: auto
                showCounter: false, // this.items.length >= 6,
                slidesPerView: 'auto',
                showNavigation: true,
                spaceBetween: 16,
                ...this.sliderOptions,
            },
        };
    },

    computed: {
        hasModals() {
            return this.items.some((el) => el.media.modal);
        },
        modals() {
            if (!this.hasModals) return false;
            return this.items.map((el) => el.media.modal);
        },
        className() {
            return {
                's-screenshots_layout_wide-image': this.isWideImage,
                's-screenshots_layout_center-image': this.isCentered,
                's-screenshots_layout_with-slider': this.items.length > 1,
                's-screenshots_layout_row': this.isRowLayout,
                's-screenshots_layout_row-reversed': this.isRowLayout && this.isRowReversed,
            };
        },

        isItemSingle() {
            return this.items.length === 1;
        },
    },
    methods: {
        itemHasModal(item) {
            return Boolean(item.media && item.media.modal);
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-screenshots {
    &__link {
        margin-top: 56px;
    }

    &__slide {
        user-select: none;
        cursor: pointer;

        /* Disabled during Swiper upgrade, needs rework from the Design team
        &:not(.a-slide_active) {
            .s-screenshots__item {
                opacity: 0.5;

                &:hover {
                    opacity: 1;
                }
            }
        }
        */
    }

    &__item {
        display: table;
        width: 100%;
    }

    &__img {
        display: block;
        max-width: 100%;
    }

    &__description {
        @mixin body;
        display: table-caption;
        padding-inline-end: 16px;
        caption-side: bottom;
        margin-top: 24px;
    }

    &_layout {
        &_with-slider {
            &:deep(.a-slider) {
                visibility: visible;
                .a-slider__nav {
                    display: flex;
                    margin-top: 24px;
                    margin-bottom: 16px;
                }
            }
            &:deep(.a-slide) {
                max-width: 269px;

                @media (--viewport-mobile-wide) {
                    max-width: 550px;
                }

                @media (--viewport-desktop) {
                    max-width: 712px;
                }
            }

            .s-screenshots {
                overflow-x: hidden;
            }
        }

        &_center-image {
            .s-screenshots {
                &__item {
                    margin-inline-start: auto;
                    margin-inline-end: auto;
                }
            }
        }
    }

    @media (--viewport-mobile-wide) {
        &__desription {
            @mixin paragraph;
            padding-inline-end: 16px;
        }
    }

    @media (--viewport-tablet) {
        &_layout {
            &_with-slider {
                &:deep(.a-container) {
                    margin-inline-end: 0;
                    padding-inline-end: 0;
                }
            }
        }
    }

    @media (--viewport-desktop) {
        &:deep(.a-slice-header) {
            width: 66.66%;
        }

        &__item {
            max-width: 66.66%;
        }

        &__link {
            position: absolute;
            top: 14px;
            inset-inline-end: 40px;
            max-width: 25%;
            margin-top: 0;
        }

        &_layout {
            &_with-slider {
                .s-screenshots {
                    &__item {
                        max-width: 100%;
                        width: 100%;
                    }
                }
            }

            &_wide-image {
                .s-screenshots {
                    &__item {
                        max-width: 100%;
                    }
                }
            }
        }
    }

    @media (--viewport-desktop-wide) {
        &__slider {
            width: 100%;
        }

        &__link {
            inset-inline-end: 73px;
        }

        &_layout {
            &_row {
                &:deep(.a-container) {
                    display: grid;
                    grid-template-columns: calc(33.33% - 32px) 66.66%;
                    -ms-grid-columns: calc(33.33% - 32px) 32px 66.66%;
                    grid-column-gap: 32px;
                }

                &:deep(.a-slice-header) {
                    -ms-grid-column: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    width: 100%;
                }

                .s-screenshots {
                    &__item {
                        -ms-grid-column: 3;
                        grid-row: span 2;
                        -ms-grid-row-span: 2;
                        max-width: 100%;
                    }

                    &__link {
                        -ms-grid-row: 2;
                        position: static;
                        max-width: 100%;
                    }
                }
            }

            &_row-reversed {
                &:deep(.a-container) {
                    grid-template-columns: calc(66% - 16px) 33%;
                    -ms-grid-columns: calc(66% - 16px) 32px 33%;
                }

                &:deep(.a-slice-header) {
                    order: 2;
                }

                .s-screenshots {
                    &__item {
                        order: 1;
                    }

                    &__link {
                        order: 3;
                    }
                }
            }
        }
    }

    @media (--viewport-desktop-large) {
        &_layout {
            &_with-slider {
                &:deep(.a-container) {
                    max-width: 1312px;
                    margin: 0 auto;
                    width: 100%;
                    padding-inline-start: 0;
                    padding-inline-end: 0;
                }
            }
        }
        &__slider {
            width: 100%;
        }

        /*
        &__content {
            mask-image: linear-gradient(to right, rgba(0, 0, 0, 1) 83.33%, rgba(0, 0, 0, 0));
        }
        */

        &__link {
            inset-inline-end: 4px;
        }
    }

    &:deep(.a-picture) {
        &__img {
            width: 100%;
            height: 100%;
        }
    }
}

/*
[dir='rtl'] {
    .s-screenshots__content {
        @media (--viewport-desktop-large) {
            mask-image: linear-gradient(to left, rgba(0, 0, 0, 1) 83.33%, rgba(0, 0, 0, 0));
        }
    }
}
*/
</style>
