<template>
    <div
        v-if="prevId || nextId"
        class="a-modal-playlist"
        :class="{ 'centered': !prevId && !nextId }"
    >
        <div v-if="prevId" class="prev">
            <div class="title">
                {{ prevText }}
            </div>
            <button
                v-modal="prevId"
                class="button"
                data-close
                type="button"
            >
                <a-glyph class="glyph" name="arrow-left" />
                <span v-if="prevTitle" class="text">{{ prevTitle }}</span>
            </button>
        </div>
        <div v-if="title || desc" class="info">
            <div v-if="title" class="title">
                {{ title }}
            </div>
            <div v-if="desc" class="desc">
                {{ desc }}
            </div>
        </div>
        <div v-if="nextId" class="next">
            <div class="title">
                {{ nextText }}
            </div>
            <button
                v-modal="nextId"
                class="button"
                data-close
                type="button"
            >
                <span v-if="nextTitle" class="text">{{ nextTitle }}</span>
                <a-glyph class="glyph" name="arrow" />
            </button>
        </div>
    </div>
</template>

<script>
import AGlyph from '@core/components/glyph/glyph.vue';
import Modal from '@core/directives/modal.js';

export default {
    name: 'AModalPlaylist',

    components: { AGlyph },

    directives: { Modal },

    props: {
        title: {
            type: String,
            default: undefined,
        },

        desc: {
            type: String,
            default: undefined,
        },

        prevText: {
            type: String,
            default: 'Previous video',
        },

        prevId: {
            type: String,
            default: undefined,
        },

        prevTitle: {
            type: String,
            default: undefined,
        },

        nextText: {
            type: String,
            default: 'Next video',
        },

        nextId: {
            type: String,
            default: undefined,
        },

        nextTitle: {
            type: String,
            default: undefined,
        },
    },
};
</script>

<style lang="postcss" scoped>
.a-modal-playlist {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin-top: 32px;
    @media (--viewport-mobile-wide) {
        margin-top: 40px;
        align-items: center;
    }
    .info{
        @mixin colls 12;
        order: 1;
        margin-bottom: 24px;
        text-align: center;
        @media (--viewport-mobile-wide) {
            @mixin colls 4;
            order: 2;
            margin-bottom: 0;
        }
        .title {
            @mixin lead-accent;
            margin-bottom: 8px;
            color: var(--av-fixed-white);
        }
        .desc {
            @mixin body;
            color: var(--av-fixed-white);
        }
    }
    .prev {
        @mixin colls 6;
        order: 2;
        text-align: end;
        @media (--viewport-mobile-wide) {
            order: 2;
        }
        .text {
            text-align: end;
        }
    }
    .next {
        @mixin colls 6;
        order: 3;
    }
    .next, .prev {
        @mixin colls 6;
        @media (--viewport-mobile-wide) {
            @mixin colls 3;
        }
        .title {
            @mixin caption;
            margin-bottom: 4px;
            color: var(--av-fixed-white);
        }
        .button {
            @mixin paragraph-accent;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            gap: 8px;
            width: 100%;
            padding: 0;
            border: none;
            background: none;
            color: var(--av-fixed-white);
            text-align: start;
            cursor: pointer;
            &:hover {
                background: none;
            }
            .glyph {
                position: relative;
                top: 0.25em;
                flex: 0 0 16px;
            }
        }
    }
    @media (--viewport-mobile-wide) {
        &.centered {
            justify-content: center;
            .info {
                width: 90%;
            }
        }
    }
}
</style>
