<template>
    <div v-show="isEnabled" class="locale-selector-ribbon">
        <div class="a-container">
            <div class="content">
                <div class="description" v-html="convertToHtml(description)" />
                <div
                    class="select"
                    :class="{ opened: isSelectOpened }"
                    tabindex="0"
                    @click="selectToggle"
                >
                    <div class="selected-option">
                        <div class="label">
                            {{ selectedOption.label }}
                        </div>
                        <div class="sublabel">
                            {{ selectedOption.sublabel }}
                        </div>
                    </div>

                    <a-glyph class="glyph" name="chevron-down" />

                    <div class="dropdown" :class="{opened: isSelectOpened}" @click.stop>
                        <div
                            v-for="(option, i) in options"
                            :key="`option-${i}`"
                            ref="option"
                            class="option"
                            :class="getOptionClasses(option)"
                            tabindex="0"
                            @click="onOptionClick(option)"
                        >
                            <div class="option-inner">
                                <div class="label">
                                    {{ option.label }}
                                </div>
                                <div class="sublabel">
                                    {{ option.sublabel }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <s-locale-selector :is-shown="isLocaleSelectorShown" :event="event" @close="hideLocaleSelector" />

                <a-button
                    class="button"
                    size="s"
                    type="main"
                    :text="buttonTitle"
                    tabindex="0"
                    @click="chooseSelectedAction"
                />
                <div class="close" tabindex="0" @click="saveAndClose">
                    <a-glyph class="close-glyph" name="close-16" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { StatusCodes } from 'http-status-codes';
import { mapActions } from 'vuex';
import AButton from '@core/components/button/button.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import locales from '@core/mixins/locales.js';
import commonUtils from '@utils/common';
import SLocaleSelector from '../locale-selector/locale-selector.vue';

export default {
    name: 'SLocaleSelectorRibbon',

    components: {
        AGlyph,
        AButton,
        SLocaleSelector,
    },

    mixins: [locales],

    props: {
        defaultCountryCode: {
            type: String,
            default: 'us',
        },
        isShown: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            RIBBON_SHOWN_STORAGE_KEY: 'locale-ribbon-shown',
            userCountry: null,
            isSelectOpened: false,
            isLocaleSelectorShown: false,
            isEnabled: false,
            description: "You are on @locale('United States + Canada') website. Select another region to view location-specific content",
            selectRegion: 'Select another region',
            buttonTitle: 'Continue',
            selectedOption: {},
            event: {
                eventCategory: 'language popup',
                eventAction: 'select another region',
                eventContext: null,
            },
        };
    },

    computed: {
        countries() {
            const localesData = this.$store.state.slices.items?.['s-locale-selector'] || {};
            if (!localesData.columns) return [];
            return localesData.columns.flatMap((column) => column.regions.flatMap((region) => region.locales));
        },

        localeSelectorMapping() {
            return this.$store.state.slices.items?.['s-locale-selector-ribbon'] || {};
        },

        defaultCountry() {
            return this.countries.find((item) => item.regionId === this.defaultCountryCode);
        },

        userCountryOption() {
            return this.userCountry ? {
                action: 'userCountry',
                label: this.userCountry.country,
                sublabel: this.userCountry.language,
            } : null;
        },

        defaultCountryOption() {
            return this.defaultCountry ? {
                action: 'defaultCountry',
                label: this.defaultCountry.country,
                sublabel: this.defaultCountry.language,
            } : null;
        },

        selectRegionOption() {
            return {
                action: 'regionSelect',
                class: 'region-select',
                label: this.selectRegion,
            };
        },

        options() {
            return [
                ...(this.userCountryOption ? [this.userCountryOption] : []),
                ...(this.defaultCountryOption ? [this.defaultCountryOption] : []),
                ...(this.selectRegionOption ? [this.selectRegionOption] : []),
            ];
        },
    },

    watch: {
        isShown(isShown) {
            if (isShown && !this.isEnabled) this.init();
        },
    },

    beforeDestroy() {
        document.removeEventListener('keydown', this.keyDownHandler);
        document.removeEventListener('click', this.clickHandler);
    },

    methods: {
        ...mapActions({
            loadGeoLocation: 'geolocation/getGeoLocation',
        }),

        async init() {
            document.addEventListener('keydown', this.keyDownHandler);
            document.addEventListener('click', this.clickHandler);
            await this.getUserCountry();
            this.isEnabled = true;
            this.addKeyboardFocusControl();
            this.sendRibbonShownEvent();
        },

        getOptionClasses(option) {
            return [option.class, option.action === this.selectedOption.action ? 'selected' : null];
        },

        async selectToggle() {
            this.isSelectOpened = !this.isSelectOpened;

            await this.$nextTick(); // focus will not trigger otherwise cause element is still hidden
            if (this.isSelectOpened) {
                const i = this.options.findIndex((x) => x.action === this.selectedOption.action);
                this.$refs.option[i]?.focus();
            }
        },

        chooseSelectedAction() {
            if (this.selectedOption.action === 'userCountry') {
                this.sendAnalyticsEvents({
                    category: 'language popup',
                    action: 'click continue button',
                    label: `/${this.userCountry.isoCode}/`,
                });
                window.location.href = this.userCountry.link;
            }

            if (this.selectedOption.action === 'defaultCountry') {
                this.sendAnalyticsEvents({
                    category: 'language popup',
                    action: 'clicked on default country',
                    label: this.$route?.fullPath,
                });
                this.storeLocaleInCookies(this.defaultCountry.isoCode);
                this.isEnabled = false;
            }

            if (this.selectedOption.action === 'regionSelect') {
                this.isLocaleSelectorShown = true;
            }
        },

        onOptionClick(option) {
            this.selectOption(option);

            if (this.selectedOption.action === 'userCountry') return;

            this.chooseSelectedAction();
        },

        selectOption(option) {
            this.selectedOption = option;
            this.isSelectOpened = false;
        },

        hideLocaleSelector() {
            this.isLocaleSelectorShown = false;
        },

        clickHandler(e) {
            if (!e.target.closest('.select')) {
                this.isSelectOpened = false;
            }
        },

        addKeyboardFocusControl() {
            this.$refs.option?.forEach((option, i) => {
                option.addEventListener('keyup', (e) => this.optionKeyUpHandler(e, i));
            });
        },

        keyDownHandler(e) {
            if (!e.target.closest('.locale-selector-ribbon')) return;

            switch (e.key) {
                case 'ArrowUp':
                case 'ArrowDown':
                    e.preventDefault();
                    break;
                case 'Enter':
                case ' ':
                    e.preventDefault();
                    e.target?.click();
                    break;
                case 'Escape':
                    e.preventDefault();
                    this.saveAndClose();
                    break;
                default: break;
            }
        },

        optionKeyUpHandler(e, i) {
            let itemToFocus;
            switch (e.key) {
                case 'ArrowDown': itemToFocus = this.$refs.option[i + 1]; break;
                case 'ArrowUp': itemToFocus = this.$refs.option[i - 1]; break;
                default: break;
            }
            itemToFocus?.focus();
        },

        saveAndClose() {
            const isoCode = this.userCountry?.isoCode || this.defaultCountry?.isoCode;
            this.sendAnalyticsEvents({
                category: 'language popup',
                action: 'closed',
                label: `/${isoCode}/`,
            });
            this.storeLocaleInCookies(this.defaultCountry?.isoCode);
            this.isEnabled = false;
        },

        sendAnalyticsEvents(event) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'Acronis',
                eventCategory: event.category,
                eventAction: event.action,
                eventLabel: event.label,
            });
        },

        convertToHtml(description) {
            return commonUtils.handleHtmlInTranslations(description, [{
                target: '@locale',
                replacement: '<strong>$1</strong>',
            }]);
        },

        async getUserCountry() {
            await this.loadGeoLocation();

            const countryCode = this.$store.state.geolocation?.location?.data?.country?.code.toLowerCase();

            if (!this.$route || !countryCode) {
                this.selectOption(this.selectRegionOption);
                return;
            }

            const [mappedRegionCode, mappedRegion] = Object.entries(this.localeSelectorMapping)
                .find(([, region]) => region?.mappings.includes(countryCode)) || [];

            if (mappedRegionCode) {
                this.userCountry = this.countries.find((item) => item.isoCode.endsWith(mappedRegionCode));
                this.description = mappedRegion.description;
                this.selectRegion = mappedRegion.selectRegion;
                this.buttonTitle = mappedRegion.buttonTitle;

                const path = this.$route.fullPath.replace(
                    this.$route.params.locale,
                    this.userCountry.isoCode,
                );
                const pageExists = await this.checkPageExistence(path);

                if (pageExists) {
                    this.userCountry.link = path;
                } else {
                    this.userCountry.link = `/${this.userCountry.isoCode}/`;
                }
                this.selectOption(this.userCountryOption);

                this.sendAnalyticsEvents({
                    category: 'language popup',
                    action: 'locale exist - yes',
                    label: this.userCountry.link,
                });
            } else {
                this.selectOption(this.defaultCountryOption);

                this.sendAnalyticsEvents({
                    category: 'language popup',
                    action: 'locale exist - no',
                    label: this.$route.fullPath,
                });
            }
        },

        sendRibbonShownEvent() {
            if (!window.sessionStorage) return;

            if (!sessionStorage.getItem(this.RIBBON_SHOWN_STORAGE_KEY)) {
                const isoCode = this.userCountry?.isoCode || this.defaultCountry?.isoCode;

                this.sendAnalyticsEvents({
                    category: 'language popup',
                    action: 'shown',
                    label: `/${isoCode}/`,
                });
                sessionStorage.setItem(this.RIBBON_SHOWN_STORAGE_KEY, true);
            }
        },

        async checkPageExistence(path) {
            const opts = {
                timeout: 10000,
                validateStatus: (status) => status === StatusCodes.OK,
                method: 'HEAD',
                url: path,
            };

            try {
                return await axios.request(opts);
            } catch {
                return false;
            }
        },
    },
};
</script>
<style lang="postcss" scoped>
.locale-selector-ribbon {
    position: relative;
    background: var(--av-fixed-secondary);
    padding: 12px 0 16px;
    z-index: 2147483647; /* this must be on top of cookie popup, which has z-index: 2147483645 */

    @media (--viewport-desktop) {
        padding-block: 12px;
    }

    > .a-container {
        padding-top: 0;
        padding-bottom: 0;
    }

    .content {
        display: flex;
        position: relative;
        align-items: flex-start;
        flex-wrap: wrap;
        row-gap: 16px;

        @media (--viewport-desktop) {
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
            padding-inline-end: 40px;
        }
    }

    .description {
        @mixin body;

        width: 100%;
        color: var(--av-fixed-white);
        padding-inline-end: 32px;
        margin-bottom: -4px;

        @media (--viewport-desktop) {
            margin-bottom: 0;
        }
    }

    .select {
        position: relative;
        cursor: pointer;
        height: 32px;
        width: auto;
        max-width: 100%;
        border-radius: 4px;
        background: var(--av-fixed-white);
        padding-block: 3px;
        margin-inline-end: 16px;
        border: 1px solid var(--av-brand-light);
        color: var(--av-fixed-secondary);
        flex-shrink: 0;
        white-space: nowrap;

        &.opened {
            border-color: var(--av-brand-primary);

            .glyph {
                transform: scaleY(-1);
            }
        }

        @media (--viewport-desktop) {
            margin-inline-start: auto;
            max-width: 60%;
        }

        &:focus-visible {
            border-color: var(--av-brand-primary);
        }
    }

    .button {
        padding-block: 4px;

        &:focus-visible {
            outline: 3px solid var(--av-fixed-focus);
        }
    }

    .selected-option {
        overflow: hidden;
        margin-inline: 15px 39px;
    }

    .label {
        @mixin paragraph-accent;

        display: inline-block;
        margin-inline-end: 4px;
    }

    .sublabel {
        @mixin paragraph;

        font-weight: 400;
        display: inline;
    }

    .dropdown {
        position: relative;
        cursor: auto;
        z-index: 450;
        margin-top: 8px;
        inset-inline-start: 0;
        width: 100%;
        background: var(--av-fixed-white);
        border-radius: 4px;
        padding: 7px 0;
        border: 1px solid var(--av-brand-primary);
        box-shadow: var(--av-shadow-regular);

        &:not(.opened) {
            visibility: hidden;
        }
    }

    .option {
        @mixin paragraph-accent;

        overflow: hidden;
        display: block;
        text-decoration: none;
        cursor: pointer;
        padding-block: 8px;

        &:hover {
            background: var(--el-secondary-hover);
        }

        &:focus-visible {
            background: var(--av-fixed-focus);
        }

        &:active,
        &.selected {
            background: var(--el-secondary-active);
        }

        &.region-select {
            overflow: visible;
            margin-top: 17px;
            position: relative;

            &::before {
                content: '';
                display: block;
                height: 1px;
                position: absolute;
                width: 100%;
                top: -9px;
                inset-inline-start: 0;
                background: var(--av-brand-accent);
            }
        }
    }

    .option-inner {
        margin-inline: 15px 39px;
    }

    .glyph {
        position: absolute;
        cursor: pointer;
        inset-inline-end: 16px;
        top: 7px;
        fill: var(--av-brand-primary);
        transition: none;

        &:hover {
            fill: var(--av-brand-secondary);
        }
    }

    .close {
        position: absolute;
        inset-inline-end: -4px;
        top: 2px;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            .close-glyph {
                fill: var(--av-brandless-icon-solid-light);
            }
        }

        &:active {
            .close-glyph {
                fill: var(--av-branded-icon-solid-lightest);
            }
        }

        &:focus-visible {
            background: var(--av-brand-secondary-light);
        }

        @media (--viewport-desktop) {
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .close-glyph {
        cursor: pointer;
        fill: var(--av-brand-icon-solid-lighter);
        transition: none;
    }

    .s-locale-selector {
        cursor: default;
    }
}
</style>
