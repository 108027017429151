<template>
    <section :id="id" class="acronis-scs-intro" :class="basicClassName">
        <div class="a-container">
            <div class="acronis-scs-intro__wrapper">
                <div class="acronis-scs-intro__content">
                    <a-picture v-if="logo" class="acronis-scs-intro__content__logo" :link="logo" />
                    <div v-if="title" class="acronis-scs-intro__content__title">
                        {{ title }}
                    </div>
                    <a-link
                        v-if="link"
                        class="acronis-scs-intro__content__link"
                        v-bind="link"
                        :size="link.size || 'lead'"
                        accent
                    />
                    <div v-if="text" class="acronis-scs-intro__content__text" v-html="text" />
                </div>
                <div v-if="logos" class="acronis-scs-intro__logos">
                    <div v-for="logoLink in logos.slice(0, limit)" :key="logoLink" class="acronis-scs-intro__logos__item">
                        <a-picture :link="logoLink" alt="Acronis SCS" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';
import contentMixin from '@core/mixins/content.js';
import styleMixin from '@core/mixins/style.js';

export default {
    name: 'SAcronisScsIntro',
    components: {
        APicture,
        ALink,
    },
    mixins: [contentMixin, styleMixin],
    props: {
        background: {
            type: String,
            required: false,
            default: 'light-blue',
        },
        title: {
            type: String,
            required: false,
            default: undefined,
        },
        logo: {
            type: String,
            required: false,
            default: undefined,
        },
        link: {
            type: Object,
            required: false,
            default: undefined,
        },
        text: {
            type: String,
            required: false,
            default: undefined,
        },
        logos: {
            type: Array,
            required: false,
            default: undefined,
        },
    },
    data() {
        return {
            limit: 8,
        };
    },
};
</script>

<style lang="postcss" scoped>
.acronis-scs-intro {
    padding: 56px 0;
    @media (--viewport-mobile-wide) {
        padding: 56px 0 48px;
    }
    &.s-slice_background_dark-blue {
        .acronis-scs-intro__content__text,
        .acronis-scs-intro__content__title {
            color: var(--av-fixed-white);
        }
    }
    &__wrapper {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;
    }
    &__content {
        @mixin colls 12;
        @media (--viewport-desktop) {
            @mixin colls 7;
        }
        &__logo {
            :deep(img) {
                margin: 0 0 16px;
            }
        }
        &__title {
            @mixin display;
            margin-bottom: 16px;
            color: var(--av-nav-primary);
            @media (--viewport-mobile-wide) {
                @mixin hero;
            }
        }
        &__link {
            margin-bottom: 16px;
            @media (--viewport-mobile-wide) {
                margin-bottom: 8px;
            }
        }
        &__text {
            @mixin paragraph;
            color: var(--av-fixed-secondary);
        }
    }
    &__logos {
        @mixin colls 12;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        gap: 0 2px;
        margin: 32px auto 0;
        @media (--viewport-mobile-wide) {
            @mixin colls 10;
            gap: 0 40px;
        }
        @media (--viewport-tablet) {
            @mixin colls 8;
        }
        @media (--viewport-desktop) {
            @mixin colls 5;
            gap: 0 24px;
            justify-content: space-evenly;
            margin: 0 auto;
        }
        @media (--viewport-desktop-wide) {
            justify-content: center;
            gap: 0 40px;
        }
        &__item {
            padding: 14px 16px;
            background: var(--av-fixed-white);
            border: 1px solid var(--av-brand-light);
            box-shadow: var(--av-shadow-small);
            border-radius: 4px;
            margin-bottom: 4px;

            @media (--viewport-mobile-wide) {
                margin-bottom: 8px;
            }

            img {
                max-width: 48px;
                max-height: 52px;
                width: auto;
                height: auto;
            }
        }
    }
}
</style>
