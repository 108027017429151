import { mapState, mapActions } from 'vuex';
import listCountriesGDPR from '@model/const/countries-gdpr.ts';
import { LOCALE_DEFAULT } from '@model/const/locales.ts';
import states from '@model/const/states.ts';
import formHelper from '@utils/form.js';

export default {
    data() {
        return {
            states,
            guid: null,
            localItems: [],
            termsError: false,
            optInError: false,
            isLoading: false,
            disableButton: false,
            isAnimation: true,
        };
    },
    computed: {
        ...mapState({
            countries: (state) => state.countries?.items || [],
            location: (state) => state.geolocation?.location || null,
            queryStringData: (state) => state.trial?.queryStringData || {},
        }),
        locale() {
            return this.$route.params.locale || LOCALE_DEFAULT;
        },
        language() {
            if (this.locale === 'zh-tw') return 'zg';

            return this.locale.split('-').shift();
        },
        setExpressSignupCurrency() {
            const list = {
                'es-mx': 'MXN',
                'ko-kr': 'KRW',
            };
            return list[this.locale] || this.productCurrency;
        },
        isCountryGDPR() {
            return listCountriesGDPR.includes(this.form.country);
        },
        enabledCookies() {
            return navigator.cookieEnabled;
        },
    },
    async mounted() {
        await this.fillGeoData();
        this.searchParams = new URLSearchParams(this.queryStringData);
        this.guid = formHelper.getGUID();
        const expectedKeys = new Set([
            'first_name',
            'last_name',
            'email',
            'aan',
            'tenantId',
            'country',
            'company',
        ]);
        Object.keys(this.form)
            .filter((x) => expectedKeys.has(x))
            .forEach(this.prefillData);

        if (Object.keys(this.$route.query || {}).length) this.$router.replace({ query: null });
        this.isAnimation = false;
    },
    methods: {
        ...mapActions({
            loadGeoLocation: 'geolocation/getGeoLocation',
            doExpressSignupRequest: 'expressSignup/doExpressSignupRequest',
        }),
        replaceLocale(str) {
            return str.replace(/\/LOCALE\//g, `/${this.locale}/`);
        },
        async fillGeoData() {
            await this.loadGeoLocation();
            this.form.country = this.location.data.country?.code || '';
            if (this.form.country === 'US') {
                this.form.state = this.location.data.region?.code || '';
            }
        },
        prefillData(key) {
            const val = this.queryStringData[key] || localStorage.getItem(`trial_es_${key}`);
            if (!val || val === 'undefined') return;
            const normalizedValue = key === 'email' ? val.replaceAll(' ', '+') : val;
            this.form[key] = normalizedValue;
            localStorage.setItem(`trial_es_${key}`, normalizedValue);
        },
        validateEmail(rule, value, callback) {
            if (!value) {
                return callback(new Error(this.setErrorMessage('notFilledEmailBusiness')));
            }
            if (!formHelper.checkIfFieldLengthValid('input', value)) {
                return callback(new Error(this.setErrorMessage('invalidInputLength')));
            }
            if (!formHelper.checkIfEmailHasAddressSign(value)) {
                return callback(new Error(this.setErrorMessage('missingEmailSymbol')));
            }
            if (!formHelper.checkIfEmailValid(value)) {
                return callback(new Error(this.setErrorMessage('invalidEmailBusiness')));
            }
            return callback();
        },
        validatePhone(rule, value, callback) {
            if (!value || value === '+') {
                return callback(new Error(this.setErrorMessage('notFilledPhone')));
            }
            if (!formHelper.checkIfFieldLengthValid('input', value)) {
                return callback(new Error(this.setErrorMessage('invalidInputLength')));
            }
            if (!formHelper.checkIfPhoneValid(value)) {
                return callback(new Error(this.setErrorMessage('invalidPhone')));
            }
            return callback();
        },
    },
    serverPrefetch() {
        this.$store.dispatch('countries/getCountries');
    },
};
