export default {
    props: {
        translations: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    methods: {
        trans(key) {
            if (typeof key === 'object') return this.replaceNestedValues(key, this.translations);
            return this.translations[key] || key;
        },
        replaceNestedValues(obj, translations) {
            if (Array.isArray(obj)) {
                return obj.map((item) => {
                    if (typeof item === 'object') {
                        return this.replaceNestedValues(item, translations);
                        // eslint-disable-next-line no-else-return
                    } else if (translations[item] !== undefined) {
                        return translations[item];
                    } else {
                        return item;
                    }
                });
            }

            try {
                return Object.keys(obj).reduce((acc, key) => {
                    const value = obj[key];
                    if (typeof value === 'object' && value !== null) {
                        acc[key] = this.replaceNestedValues(value, translations);
                    } else if (translations[value] !== undefined) {
                        acc[key] = translations[value];
                    } else {
                        acc[key] = value;
                    }
                    return acc;
                }, {});
            } catch (e) { return false; }
        },
    },
};
