<template>
    <div class="blog-tabs" :class="className">
        <a-tabs v-if="haveMultipleTabs" class="tabs">
            <a-tab v-for="(slice, index) in tabs" :key="index" :label="slice.label">
                <blog-list class="blog-list" v-bind="{ cards: slice.cards, loadMoreType: null, theme }" />
            </a-tab>
        </a-tabs>
        <div v-else class="tab">
            <div class="title">
                <span>{{ tabs[0].label }}</span>
                <div class="title-line" />
            </div>
            <blog-list class="blog-list" v-bind="{ cards: tabs[0].cards, loadMoreType: null, theme }" />
        </div>
    </div>
</template>

<script>
import ATab from '@core/components/tabs/tab.vue';
import ATabs from '@core/components/tabs/tabs.vue';
import BlogList from '../shared-components/blog-list/blog-list.vue';

export default {
    name: 'BlogTabs',
    components: {
        ATabs,
        ATab,
        BlogList,
    },
    props: {
        tabs: {
            type: Array,
            required: true,
        },

        theme: {
            type: String,
            default: 'light',
        },
    },
    computed: {
        className() {
            return {
                dark: this.theme === 'dark',
            };
        },

        haveMultipleTabs() {
            return this.tabs.length > 1;
        },
    },
};
</script>

<style lang="postcss" scoped>
.blog-tabs {
    margin-bottom: -40px;

    @media (--viewport-tablet) {
        margin-bottom: 0;
    }

    .title {
        @mixin title-accent;
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding-bottom: 18px;
        margin-bottom: 24px;
        position: relative;

        @media (--viewport-tablet) {
            flex-direction: row;
            align-items: center;
        }

        span {
            flex-shrink: 0;
        }
    }

    .title-line {
        width: 100%;
        height: 2px;
        background: linear-gradient(90deg, #7EB4FF 0%, #0067E9 100%);

        @media (--viewport-tablet) {
            transform: translateY(2px);
        }
    }

    &.dark {
        .title {
            color: var(--av-fixed-white);
        }
    }
}
</style>

<style lang="postcss">
.blog-tabs {
    .tabs {
        .a-tabs {
            &__header {
                margin-bottom: 16px;
            }
        }

        .a-tabs-item__label {
            @mixin title;
            font-weight: bold;
        }
    }

    .tabs,
    .tab {
        .blog-list {
            .item {
                &:nth-child(3),
                &:nth-child(4) {
                    display: none;
                }

                @media (--viewport-tablet) {
                    &:nth-child(3) {
                        display: block;
                    }
                }

                @media (--viewport-desktop-wide) {
                    &:nth-child(4) {
                        display: block;
                    }
                }
            }
        }
    }

    &.dark {
        .tabs {
            .a-tabs-item__label {
                color: var(--av-fixed-white);
            }
        }
    }
}
</style>
