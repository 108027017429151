<template>
    <s-basic-slice :id="id" class="s-timeline" :class="{ ...className, ...basicClassName }">
        <a-slice-header :title="title" />
        <div class="s-timeline__wrapper">
            <div class="s-timeline__leftside">
                <a-slice-header v-if="lead" :lead="lead" />
                <div class="s-timeline__accordeon" :class="{ 's-timeline__accordeon_is-opened': isOpened }">
                    <ul
                        ref="list"
                        class="s-timeline__list"
                        :style="{ height: listHeight }"
                    >
                        <li
                            v-for="(item, i) in timelineItems"
                            :key="i"
                            ref="listItems"
                            class="s-timeline__list-item timeline-item"
                            :class="{ 'timeline-item_type_with-title': item.title }"
                        >
                            <div v-if="item.title" class="timeline-item__title">
                                {{ item.title }}
                            </div>
                            <a-dangerous-html
                                v-if="item.content"
                                class="timeline-item__text"
                                :content="item.content"
                            />
                        </li>
                    </ul>
                    <div v-if="isToggleable" class="s-timeline__showmore-wrapper" @click="toggleList">
                        <button class="s-timeline__showmore" type="button">
                            <span class="s-timeline__showmore-more-text">{{ showMoreText }}</span>
                            <span class="s-timeline__showmore-less-text">{{ showLessText }}</span>
                            <a-glyph class="s-timeline__showmore-icon" name="drop-down-arrow" />
                        </button>
                    </div>
                </div>
            </div>
            <div v-if="addHelp" class="s-timeline__additional-help">
                <div class="s-timeline__additional-help-title">
                    {{ addHelp.title }}
                </div>
                <a-dangerous-html v-if="addHelp.content" class="s-timeline__content" :content="addHelp.content" />
            </div>
        </div>
    </s-basic-slice>
</template>

<script>
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ASliceHeader from '@core/components/slice-header/slice-header.vue';
import contentMixin from '@core/mixins/content.js';
import styleMixin from '@core/mixins/style.js';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';

const LIST_ADDITIONAL_HEIGHT_PX = 5;

export default {
    name: 'STimeline',

    components: {
        ADangerousHtml,
        AGlyph,
        ASliceHeader,
        SBasicSlice,
    },

    mixins: [contentMixin, styleMixin],

    props: {
        timelineItems: {
            type: Array,
            required: true,
            validator: (timelineItems) => timelineItems.length <= 8,
        },

        addHelp: {
            type: [Object, Boolean],
            default: false,
        },

        showMoreText: {
            type: String,
            default: '',
        },

        showLessText: {
            type: String,
            default: '',
        },

        background: {
            type: String,
            default: 'l-b-gradient-bottom',
        },

        visibleItemsLimit: {
            type: Number,
            default: 5,
        },
    },

    data: () => ({
        isOpened: false,
        listHeight: 'auto',
    }),

    computed: {
        className() {
            return {
                's-timeline_layout_with-lead': this.lead,
                's-timeline_layout_with-showmore': this.timelineItems.length > this.visibleItemsLimit,
            };
        },

        isToggleable() {
            return this.timelineItems.length > this.visibleItemsLimit;
        },
    },

    mounted() {
        if (this.isToggleable) {
            this.setListHeight();
            window.addEventListener('load', this.setListHeight);
            window.addEventListener('resize', this.setListHeight);
        }
    },

    beforeDestroy() {
        window.removeEventListener('load', this.setListHeight);
        window.removeEventListener('resize', this.setListHeight);
    },

    methods: {
        toggleList() {
            this.isOpened = !this.isOpened;
            this.setListHeight();
        },

        setListHeight() {
            this.listHeight = this.getListHeight();
        },

        getListHeight() {
            const list = this.isOpened ? this.$refs.listItems : this.$refs.listItems.slice(0, this.visibleItemsLimit);
            return `${list.reduce((total, item) => total + item.offsetHeight, 0) + LIST_ADDITIONAL_HEIGHT_PX}px`;
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-timeline {
    &:deep(.a-slice-header__lead) {
        margin-bottom: 48px;
    }

    &__list {
        margin-top: 40px;
        height: auto;
        overflow: hidden;
        transition: height 0.5s cubic-bezier(0.6, 0, 0.4, 1);
    }

    .timeline-item {
        position: relative;
        width: 100%;
        padding-inline-start: 18%;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            inset-inline-start: 7%;
            width: 23px;
            height: 23px;
            background-color: var(--av-brand-secondary);
            border-radius: 50%;
            transform: translateX(-50%);
        }

        &::after {
            content: '';
            position: absolute;
            top: 12px;
            inset-inline-start: 7%;
            width: 1px;
            height: calc(100% - 8px);
            background-color: var(--av-brand-secondary);
            transform: translateX(-50%);
        }

        &:not(:last-child) {
            padding-bottom: 48px;
        }

        &:last-child {
            &::after {
                display: none;
            }
        }

        &_type_with-title {
            &::before {
                top: 5px;
            }

            &::after {
                top: 5px;
                height: 100%;
            }
        }

        &__title {
            @mixin title-accent;

            margin-bottom: 8px;
            color: var(--av-nav-primary);
        }
    }

    &__additional-help {
        margin-top: 56px;
    }

    &__additional-help-title {
        margin-bottom: 8px;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        color: var(--av-nav-primary);
    }

    &__accordeon {
        &_is-opened {
            .s-timeline {
                &__showmore-wrapper {
                    margin-top: 24px;

                    &::before {
                        opacity: 0;
                        transition: opacity 0.5s cubic-bezier(0.6, 0, 0.4, 1);
                    }
                }

                &__showmore-more-text {
                    display: none;
                }

                &__showmore-less-text {
                    display: inline-block;
                }

                &__showmore-icon {
                    transform: rotate(-180deg);
                    transition: transform 0.25s ease-out;
                }
            }
        }
    }

    &__showmore-wrapper {
        position: relative;
        top: -8px;
        padding-inline-start: 18%;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            inset-inline-start: 7%;
            transform: translateX(-50%);
            width: 23px;
            height: 23px;
            background-color: var(--av-brand-secondary);
            border-radius: 50%;
            transition: opacity 0.5s cubic-bezier(0.6, 0, 0.4, 1);
        }

        &::after {
            transition: opacity 0.5s cubic-bezier(0.6, 0, 0.4, 1);
        }
    }

    &__showmore {
        display: none;
        align-items: center;
        padding: 0;
        border: none;
        background: none;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: var(--av-brand-secondary);
        cursor: pointer;
        transition: color linear 0.12s;

        &-more-text {
            display: inline-block;
        }

        &-less-text {
            display: none;
        }

        &:hover {
            color: var(--av-brand-secondary-darker);

            .s-timeline__showmore-icon {
                fill: var(--av-brand-secondary-darker);
            }
        }
    }

    &__showmore-icon {
        margin-top: 3px;
        margin-inline-start: 8px;
        transition: transform 0.25s ease-out, fill 0.25s ease-out;
    }

    &_layout {
        &_with-showmore {
            .s-timeline {
                &__showmore {
                    display: flex;
                }

                &__accordeon {
                    &_is-opened {
                        .timeline-item {
                            &:last-child {
                                &::after {
                                    opacity: 0;
                                }
                            }
                        }
                    }
                }
            }

            .timeline-item {
                &:last-child {
                    padding-bottom: 48px;

                    &::after {
                        opacity: 1;
                    }
                }
            }
        }
    }

    @media (--viewport-mobile-wide) {
        &__list {
            margin-top: 48px;
        }

        .timeline-item {
            padding-inline-start: 8%;

            &::before {
                inset-inline-start: calc(4% - 6px);
            }

            &::after {
                inset-inline-start: calc(4% - 6px);
            }
        }

        &__showmore-wrapper {
            padding-inline-start: 8%;

            &::before {
                inset-inline-start: calc(4% - 6px);
            }
        }
    }

    @media (--viewport-desktop) {
        &:deep(.a-slice-header) {
            &__title {
                width: 66%;
            }

            &__lead {
                margin-bottom: 48px;
            }
        }

        &__wrapper {
            margin-top: 40px;
            display: flex;
        }

        &__list {
            margin: 0;
        }

        .timeline-item {
            padding-inline-start: 13%;

            &::before {
                inset-inline-start: calc(6.5% - 8px);
            }

            &::after {
                inset-inline-start: calc(6.5% - 8px);
            }
        }

        &__showmore-wrapper {
            padding-inline-start: 13%;

            &::before {
                inset-inline-start: calc(6.5% - 8px);
            }
        }

        &__leftside {
            margin-inline-end: 10%;
            width: 66%;
        }

        &__additional-help {
            margin: 0;
            width: 24%;
        }

        &__additional-help-title {
            color: var(--av-nav-primary);
        }

        &_layout {
            &_with-lead {
                .s-timeline {
                    &__wrapper {
                        margin-top: 0;
                    }
                }
            }
        }
    }
}
</style>
