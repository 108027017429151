const ARG_DEFAULTS = {
    productId: 1,
    isSubpage: false,
    isShort: false,
    background: {
        desktop: '/images/header-product/bg-desktop',
        tablet: '/images/header-product/bg-tablet',
        mobile: '/images/header-product/bg-mobile',
    },
};

export const Figma = 'https://www.figma.com/design/eM4W6JS2MjG1gp5YhKoMp7Dd/Slices?node-id=37340-38863&t=JMJ2fbzoLIVoGwai-4';

export const Default = {
    args: ARG_DEFAULTS,
};

export const Promo = {
    args: {
        translations: {
            'productPageCTA.label': 'Now with identity protection',
            'productPageCTA.description': 'From <del>@price</del> <b>@priceFull / year</b>',
        },
        productPageCTA: {
            productId: 1,
            promo: {
                glyph: null,
                label: 'productPageCTA.label',
                description: 'productPageCTA.description',
                cleverBridgeCartList: [243238],
                coupon: 'basic',
            },
            cleverBridgeCartID: 243238,
        },
        ...ARG_DEFAULTS,
    },
};
