/* eslint-disable max-len */
export const Figma = 'https://www.figma.com/design/eM4W6JS2MjG1gp5YhKoMp7Dd/Slices?node-id=57964-1880&t=JMJ2fbzoLIVoGwai-4';

const translations = {
    lead: 'Convert customers and reap commission rewards: our globally available selection of consumer and corporate products makes it easy.',
    addText: `
        <p>
            Have questions?
            <a href="#">Contact us</a>
        </p>
        <p>
            Already Acronis user?
            <a href="#">Login now</a>
        </p>
    `,
};

export const ValueCard = {
    args: {
        translations: {
            ...translations,
            title: 'Cards Highlight + Value Card',
            'valueCard.title': 'Start Growing Your Business with Acronis',
            'valueCard.text': 'Apply for the Acronis Cyber Partner Program and start earning money with Acronis.',
            'valueCard.button.text': 'Join Program',
        },
        title: 'title',
        lead: 'lead',
        addText: 'addText',
        centered: false,
        type: '0',
        valueCard: {
            title: 'valueCard.title',
            text: 'valueCard.text',
            icon: '131',
            to: '#',
            buttonText: 'valueCard.button.text',
            type: 'action',
        },
        background: 'light-blue',
    },
};

export const ProductCard = {
    args: {
        translations: {
            ...translations,
            title: 'Cards Highlight + Product Card + List',
            lead: 'Technical documentation',
            'productCard.title': 'Live demo webinar',
            'productCard.text': 'Join our regular demo sessions to learn how you and your team can leverage the Acronis Cyber Cloud solution.',
            'productCard.learnLink.text': 'Learn More',
            'productCard.tryLink.text': 'Register for the webinar',
            'productCard.list.0.text': 'Acronis Cyber Cloud - Partner Guide',
            'productCard.list.1.text': 'Acronis Cyber Protect Cloud - Cyber Protection Guide',
            'productCard.list.2.text': 'Acronis Cyber Cloud - Management Portal Administrator Guide',
        },
        title: 'title',
        lead: 'lead',
        addText: '',
        centered: false,
        type: '1',
        productCard: {
            title: 'productCard.title',
            text: 'productCard.text',
            learnLink: { text: 'productCard.learnLink.text', to: '#try' },
            tryLink: { text: 'productCard.tryLink.text', to: '#learn' },
        },
        list: [
            {
                text: 'productCard.list.0.text',
                to: '#list1',
            },
            {
                text: 'productCard.list.1.text',
                to: '#list2',
            },
            {
                text: 'productCard.list.2.text',
                glyph: 'download-arrow',
                to: '#list3',
            },
        ],
        background: 'light-blue',
    },
};

export const SmallCards = {
    args: {
        translations: {
            ...translations,
            title: 'Cards Highlight + Small Cards',
            'smallCardArr.0.title': 'Sell Products',
            'smallCardArr.0.text': 'Apply for a new reseller partnership and start earning money with Acronis',
            'smallCardArr.1.title': 'Sell Services',
            'smallCardArr.1.text': 'Acronis resellers can add cloud services to their portfolio.',
        },
        title: 'title',
        lead: 'lead',
        addText: 'addText',
        centered: false,
        type: '0',
        smallCardArr: [
            {
                title: 'smallCardArr.0.title',
                text: 'smallCardArr.0.text',
                to: '#try',
                icon: '76',
                iconSmall: true,
            },
            {
                title: 'smallCardArr.1.title',
                text: 'smallCardArr.1.text',
                to: '#learn',
                icon: '131',
                iconSmall: true,
            },
        ],
        background: 'light-blue',
    },
};
