<template>
    <section v-if="content" class="main-page-new__casestudy" :class="basicClassName">
        <div class="a-container">
            <div class="wrapper">
                <div class="content">
                    <a-dangerous-html v-if="content.title" class="heading" :content="content.title" />
                    <a-dangerous-html v-if="content.subtitle" class="subtitle" :content="content.subtitle" />
                    <div v-if="content.description" class="description">
                        <a-dangerous-html :content="content.description" />
                    </div>
                    <div v-if="content.company" class="company">
                        <a-picture
                            class="logo"
                            :class="{ 'logo--full-width': hasCompanyLogoOnly }"
                            :link="content.company.logo"
                        />
                        <div v-if="!hasCompanyLogoOnly" class="info">
                            <h4 v-if="content.company.title" class="title">
                                {{ content.company.title }}
                            </h4>
                            <p v-if="content.company.description" class="company-description">
                                <a-dangerous-html :content="content.company.description" />
                            </p>
                        </div>
                    </div>
                    <div class="cta-wrapper">
                        <a-button v-if="content.button" class="button" v-bind="content.button" />
                        <a-button v-if="content.buttonSecond" class="button" v-bind="content.buttonSecond" />
                    </div>
                </div>
                <div v-if="content.media" class="image">
                    <a-media v-bind="content.media" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import AMedia from '@core/components/media/media.vue';
import APicture from '@core/components/picture/picture.vue';
import contentMixin from '@core/mixins/content.js';
import styleMixin from '@core/mixins/style.js';

export default {
    name: 'SMainPageCaseStudy',
    components: {
        ADangerousHtml,
        APicture,
        AMedia,
        AButton,
    },
    mixins: [contentMixin, styleMixin],
    props: {
        content: {
            type: Object,
            default: null,
        },
    },
    computed: {
        hasCompanyLogoOnly() {
            const { logo, title, description } = this.content.company;
            return logo && !title && !description;
        },
    },
};
</script>

<style lang="postcss" scoped>
.main-page-new {
    &__casestudy {
        text-align: center;
        overflow: visible;

        @media all and (min-width: 420px) {
            padding-bottom: 60px;
        }

        @media (--viewport-tablet) {
            padding-bottom: 0;
        }

        @media (--viewport-desktop) {
            overflow: hidden;
            text-align: start;
            transform: translateY(-50%);
            margin-bottom: -210px;
        }
        @media (--viewport-desktop-wide) {
            margin-bottom: -218px;
        }
        .a-container {
            @media all and (max-width:1023px) {
                padding:0;
            }
        }
        .wrapper {
            display: flex;
            flex-flow: row wrap;
            align-items:center;
            justify-content: space-between;
            background: linear-gradient(360deg, #06306E 0%, #060F28 100%);
            border: 2px solid #143055;
            padding: 48px 18px 144px;
            @media (--viewport-tablet) {
                padding: 48px 0 122px;
            }
            @media (--viewport-desktop) {
                border-radius: 3px;
                padding:48px 16px;
            }
            @media (--viewport-desktop-wide) {
                padding: 48px 32px;
            }
            @media (--viewport-desktop-large) {
                padding: 48px 36px;
            }

            .content {
                color: #f5f5f5;
                @mixin colls 12;
                @media (--viewport-tablet) {
                    @mixin colls 10;
                    margin: 0 auto;
                }
                @media (--viewport-desktop) {
                    @mixin colls 6;
                    padding-inline-end:16px;
                    margin: 0;
                }
                @media (--viewport-desktop-large) {
                    max-width:558px;
                    padding:0;
                }
                .heading {
                    @mixin title;
                    margin-bottom: 16px;
                }
                .subtitle {
                    @mixin paragraph;
                    margin-bottom: 32px;
                }
                .description {
                    @mixin paragraph;
                    margin-bottom: 24px;
                    line-height:21px;
                    @media (--viewport-tablet) {
                        @mixin colls 10;
                        margin: 0 auto 24px;
                    }
                    @media (--viewport-desktop) {
                        @mixin colls 12;
                        margin: 0 0 32px;
                    }
                    @media (--viewport-desktop-wide) {
                        padding-inline-end: 32px;
                    }
                    @media (--viewport-desktop-large) {
                        padding: 0;
                    }
                }
                .company {
                    display: flex;
                    flex-flow: row wrap;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 24px;
                    @media (--viewport-desktop) {
                        @mixin colls 12;
                        margin: 0 auto 40px;
                        text-align: start;
                        justify-content: flex-start;
                    }
                    .logo {
                        max-width: 169px;
                        width: 100%;

                        @media (--viewport-desktop) {
                            margin-inline-end: 16px;
                        }

                        &:deep(img) {
                            max-width: 100%;
                            margin: 0;
                        }

                        &--full-width {
                            max-width: 328px;
                            max-height: 38px;

                            @media (--viewport-tablet) {
                                max-width: 400px;
                                max-height: 47px;
                            }
                        }
                    }
                    .info {
                        @mixin colls 12;
                        margin-top: 8px;
                        @mixin body;
                        line-height:20px;
                        @media (--viewport-desktop) {
                            width: auto;
                            margin: 0;
                        }
                        .title {
                            font-weight: 500;
                        }
                    }
                }
                .button {
                    min-width: 200px;
                    padding: 8px;
                    width: auto;
                }
            }
            .image {
                max-width: 456px;
                transform: translateY(100%);
                margin: -50% auto 0;

                @media all and (min-width: 420px) {
                    margin: -190px auto 0;
                }

                @media (--viewport-desktop) {
                    transform: none;
                    margin: 0;
                }

                @media (--viewport-desktop-wide) {
                    max-width: 493px;
                    @mixin colls 6;
                }
                @media (--viewport-desktop-large) {
                    max-width: 560px;
                }

                &:deep(.a-media__type__screenshot) {
                    border: 0;
                }
            }
        }

        .cta-wrapper {
            display: flex;
            gap: 24px;
            flex-direction: column;

            @media (--viewport-desktop) {
                flex-direction: row;
            }

            .a-button_type_inverse {
                color: #AED2FF;
                border-color: rgba(174, 210, 255, 0.40);
            }
        }
    }
}
</style>
