import SchemaHandler from '@model/seo/schemaHandler';
import { OfferSchema, SchemaConfig } from '@model/seo/schemas';
import { getUrlWithLocale } from '../helper';

const PAGINATE_SIZE = 50;

export default class OfferHandler implements SchemaHandler {
    ctx: any;

    schemasConfig: SchemaConfig[];

    constructor(ctx: any, schemasConfig: SchemaConfig[]) {
        this.ctx = ctx;
        this.schemasConfig = schemasConfig;
    }

    getSchemaData(staticConfig: OfferSchema): Promise<OfferSchema> {
        return this.getRequiredFields(staticConfig);
    }

    async getRequiredFields(staticConfig: OfferSchema): Promise<OfferSchema> {
        const locale = this.ctx.$route?.params?.locale || 'en-us';
        const productId = this.ctx.$store.state.pages.page?.product_id;

        if (!productId) {
            return this.getFreeOffer(null);
        }

        await this.ctx.$store.dispatch('products/getProduct', { id: productId, locale });

        const productData = this.ctx.$store.state.products.items[productId];

        if (!productData) {
            return this.getFreeOffer(null);
        }

        return this.getOfferData(staticConfig, productData);
    }

    async getOfferData(staticConfig: any, productData: any): Promise<OfferSchema> {
        const locale = this.ctx.$route?.params?.locale || 'en-us';
        const url = getUrlWithLocale(this.ctx);
        const ctaConfig = productData?.parameters?.common?.productPageCTA;

        await this.ctx.$store.dispatch('locales/getLocaleData', { locale });

        const currency = productData?.parameters?.common?.currency;
        const priceCurrency = currency || this.ctx.$store.state.locales.locale.currency;

        if (!ctaConfig) {
            return {
                ...staticConfig,
                ...this.getFreeOffer(productData),
            };
        }

        const cleverBridgeID = ctaConfig.cleverBridgeCartID;
        let coupon = '';

        if (ctaConfig.promo?.cleverBridgeCartList?.includes(cleverBridgeID)) {
            coupon = ctaConfig.promo.coupon;
        }

        const payload = {
            locale,
            currency: priceCurrency,
            paginate: { number: 1, size: PAGINATE_SIZE },
        };

        await this.ctx.$store.dispatch('licenses/getList', {
            ...payload,
            cleverbridgeIDs: [cleverBridgeID],
            coupon,
        });

        const license = this.ctx.$store.state.licenses.data.find((l) => l.cleverbridge_id === `${cleverBridgeID}`);

        if (license) {
            const offer: any = {
                '@type': 'Offer',
                availability: 'https://schema.org/InStock',
                name: license.name,
                price: license.price,
                priceCurrency: license.currency,
            };

            if (productData.parameters.common.buyLink) {
                const buyLinkUrl = productData.parameters.common.buyLink.to;

                if (buyLinkUrl.startsWith('https://')) {
                    offer.url = buyLinkUrl;
                } else {
                    offer.url = `${url}${buyLinkUrl}`;
                }
            }

            return {
                ...staticConfig,
                ...offer,
            };
        }

        return {
            ...staticConfig,
            ...this.getFreeOffer(productData),
        };
    }

    getFreeOffer(productData = null): OfferSchema {
        const locale = this.ctx.$route?.params?.locale || 'en-us';
        const url = getUrlWithLocale(this.ctx);
        const currency = productData?.parameters?.common?.currency;
        const priceCurrency = currency || this.ctx.$store.state.locales.locale.currency;

        const offer: any = {
            '@type': 'Offer',
            '@id': `${url}/#FreeOffer`,
            price: 0,
            priceCurrency,
            name: `${locale.toUpperCase()} Free Offer`,
        };
        if (productData?.parameters?.common.tryLink) {
            const tryLinkUrl = productData.parameters.common.tryLink.to;
            if (tryLinkUrl.startsWith('https://')) {
                offer.url = tryLinkUrl;
            } else {
                offer.url = `${url}${tryLinkUrl}`;
            }
        }
        return offer;
    }
}
