<script lang="ts">
import { StatusCodes } from 'http-status-codes';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { ProductAPIQueryBuilder } from '@api/builders/product';
import { LOCALE_DEFAULT } from '@model/const/locales';
import { HttpRejection } from '@model/http/rejection';
import { assertHttpErrors } from '@utils/api-response';
import { getOgImage, ACRONIS_OG_IMAGE_ID } from '@utils/html-meta';
import EventsCategoryComponent from './component.vue';

// slug can be moved to category property on backend instead
const CATEGORY_SLUG_MAP = {
    'product-demo': 2,
    'training-and-certifications': 3,
};
const CATEGORY_MATCHES_MAP = {
    'product-demo': [['is_virtual', '=', '1']],
};

export default Vue.extend({
    name: 'EventsCategoryContainer',

    async serverPrefetch(): Promise<void> {
        const countryId = parseInt(this.$ssrContext.req.cookies.events_country, 10);
        const categorySlug = this.$route.params.category;

        if (!CATEGORY_SLUG_MAP[categorySlug]) {
            throw new HttpRejection(
                `Premature rendering stop: '${categorySlug}' not found`,
                StatusCodes.NOT_FOUND,
            );
        }

        const languageQuery = new ProductAPIQueryBuilder('languages')
            .setEntityPath('/api/events/languages/')
            .toObject();

        const unlocalizedPromises = [
            this.$store.dispatch('slices/getSyncedData', { slice: 'events', locale: LOCALE_DEFAULT }),
            this.$store.dispatch('products/getProducts', LOCALE_DEFAULT),
            this.$store.dispatch('events/getEntity', { request: languageQuery }),
            this.$store.dispatch('countries/getCountries'),
        ];

        if (this.categorySlug === 'product-demo') {
            const audiencesQuery = new ProductAPIQueryBuilder('audiences')
                .setEntityPath('/api/events/audiences/')
                .toObject();
            unlocalizedPromises.push(this.$store.dispatch('events/getEntity', { request: audiencesQuery }));
        }

        let localizedPromises = [];
        if (!Number.isNaN(countryId)) {
            const searchQuery = { ...this.$route.query, countryId };

            localizedPromises = [
                this.$store.dispatch('events/setCountry', { countryId, isConfirmed: true }),
                this.getSearchResults(searchQuery),
            ];
        }

        await Promise.all([...unlocalizedPromises, ...localizedPromises]);

        assertHttpErrors([
            { entity: this.$store.state.countries },
            { entity: this.$store.state.events.languages },
            { entity: this.$store.state.events.searchResults },
        ]);

        // Building page meta
        this.$ssrContext.res.meta = this.getMeta();
    },

    computed: {
        ...mapGetters('config', ['$config']),

        categorySlug() {
            return this.$route?.params.category;
        },
    },

    methods: {
        getMeta(): any {
            const uiStrings: any = this.$store.state.slices.items.events || {};
            const canonical = `https://${this.$config.domain}${this.$route.path}`;
            const ogImage = getOgImage(`@${ACRONIS_OG_IMAGE_ID}`, this.$config.env.HEAD_SITE_MAIN_PUBLIC_BASE_URL_STORAGE);
            const category = uiStrings.categories[this.categorySlug];
            const title = category?.seoTitle || category?.pageTitle || '';
            const description = category?.seoDescription || '';

            return {
                title,
                head: [
                    { tag: 'meta', name: 'title', content: title },
                    { tag: 'meta', name: 'description', content: description },
                    { tag: 'meta', property: 'og:title', content: title },
                    { tag: 'meta', property: 'og:description', content: description },
                    { tag: 'meta', property: 'og:image', content: ogImage },
                    { tag: 'meta', property: 'og:url', content: canonical },
                    { tag: 'meta', name: 'twitter:title', content: title },
                    { tag: 'meta', name: 'twitter:description', content: description },
                    { tag: 'meta', name: 'twitter:image', content: ogImage },
                    { tag: 'meta', name: 'twitter:url', content: canonical },
                    { tag: 'link', rel: 'image_src', href: ogImage },
                    { tag: 'link', rel: 'canonical', href: canonical },
                ],
            };
        },

        getSearchResults(searchQuery: any) {
            const hasCriteria: Record<string, any> = {
                countries: [searchQuery.countryId],
                categories: [CATEGORY_SLUG_MAP[this.categorySlug]],
            };

            if (searchQuery.product) hasCriteria.products = [searchQuery.product];
            if (searchQuery.audience) hasCriteria.audiences = [searchQuery.audience];

            let occurrenceCriteria: Record<string, string>;
            // Has FUTURE occurrence
            occurrenceCriteria = { from: new Date().toISOString() };
            // Period of ANY occurrence
            if (searchQuery.date?.length > 0) {
                // plus one day to end range to cover the last day
                const lastDay = new Date(searchQuery.date[1]);
                lastDay.setDate(lastDay.getDate() + 1);

                occurrenceCriteria = {
                    from: new Date(searchQuery.date[0]).toISOString(),
                    to: lastDay.toISOString(),
                };
            }

            const requestBuilder = new ProductAPIQueryBuilder('searchResults')
                .setEntityPath('/api/events/events/')
                .addMatchesAll('language_id', '=', searchQuery.language)
                .addMatchesAll('is_past', '<>', '1')
                .addMatchesAll('is_on_demand', '<>', '1')
                .setCustomParam('has', hasCriteria)
                .setCustomParam('has-occurrences', occurrenceCriteria)
                .setCustomParam('process-macros', '1')
                .addSort('is_featured', 'desc')
                .addSort('dynamic_next_occurrence', 'asc')
                .setPaginate(searchQuery.currentPage, searchQuery.cardsCountToShow);

            const matchesMap = CATEGORY_MATCHES_MAP[this.categorySlug];
            if (matchesMap) {
                matchesMap.forEach((rule) => requestBuilder.addMatchesAll(...rule));
            }

            const request = requestBuilder.toObject();

            return this.$store.dispatch('events/getEntity', { request });
        },
    },

    render(h) {
        const props = {
            dispatchSearch: async (searchQuery: any) => { await this.getSearchResults(searchQuery); },
            categorySlug: this.categorySlug,
        };
        return h(EventsCategoryComponent, { props });
    },
});
</script>
