<template>
    <section v-if="hasSpotlight" class="main-page-new__spotlight" :class="basicClassName">
        <div class="a-container">
            <div class="wrapper">
                <div class="blog-articles">
                    <h4 v-if="content.title" class="heading">
                        {{ content.title }}
                    </h4>
                    <div v-if="blogPosts" class="links">
                        <div v-for="(item) in blogPosts.items" :key="item.id" class="item">
                            <span class="title">{{ blogPostsTitle }}</span>
                            <a-link
                                class="link"
                                v-bind="item"
                                :to="blogLocale + item.pathname"
                                :text="item.title"
                                :size="item.size || 'paragraph'"
                                :target="item.target || '_blank'"
                            />
                        </div>
                    </div>
                </div>
                <div class="events">
                    <event-card v-for="(event) in eventCards" :key="event.id" v-bind="event" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import ALink from '@core/components/link/link.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import contentMixin from '@core/mixins/content.js';
import styleMixin from '@core/mixins/style.js';
import EventCard from '@core/slices/events/shared-components/event-card/event-card.vue';
import { LOCALE_DEFAULT } from '@model/const/locales.ts';

export default {
    name: 'SMainPageSpotlight',
    components: {
        ALink,
        EventCard,
    },
    mixins: [contentMixin, styleMixin, breakpoint],
    props: {
        content: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            eventsURL: 'acronis.events',
        };
    },
    serverPrefetch() {
        const locale = this.locale;

        return Promise.all([
            this.$store.dispatch('slices/getSyncedData', { slice: 'events', LOCALE_DEFAULT }),
            this.$store.dispatch('eventsFeed/getFeed', { limit: 2, locale }),
            this.$store.dispatch('blog/getLatestBlogPosts', { limit: 2, locale }),
        ]);
    },
    computed: {
        locale() {
            return this.$route?.params.locale || LOCALE_DEFAULT;
        },

        hasSpotlight() {
            return this.content && (this.blogPosts || this.eventCards.length);
        },
        eventTranslations() {
            return this.$store.state.slices.items.events;
        },
        eventCards() {
            if (!this.eventTranslations) return [];
            return this.$store.getters['eventsFeed/getEventCards'](this.content, this.eventTranslations, this.locale);
        },
        blogLocale() {
            return this.$store.state.blog.latestBlogPosts?.locale || LOCALE_DEFAULT;
        },
        blogPostsTitle() {
            if (this.isMobile || this.isTablet) {
                return this.content?.blogPostsTitleMobile || 'Blog';
            }
            return this.content?.blogPostsTitle || 'Blog';
        },
        blogPosts() {
            const items = this.$store.state.blog.latestBlogPosts?.items || [];
            return items.length ? { items } : null;
        },
    },
};
</script>

<style lang="postcss" scoped>
.main-page-new__spotlight {
    width: 100%;
    overflow: hidden;
    padding:80px 0;
    @media (--viewport-desktop-wide) {
        padding:72px 0 80px;
    }
    @media (--viewport-desktop-large) {
        padding:80px 0;

    }
    .wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-flow: row wrap;
        .blog-articles {
            @mixin colls 12;
            @media (--viewport-desktop) {
                @mixin colls 4;
            }
            .heading {
                @mixin colls 12;
                @mixin display;
                text-align: center;
                color: var(--av-nav-primary);
                margin-bottom: 32px;
                @media (--viewport-tablet) {
                    @mixin colls 10;
                    margin: 0 auto 40px;
                }
                @media (--viewport-desktop) {
                    @mixin colls 12;
                    text-align: start;
                    margin: 0 0 8px;
                }
                @media (--viewport-desktop-wide) {
                    margin: 0 0 48px;
                }
            }
            .links {
                display: flex;
                justify-content: space-between;
                flex-flow: row wrap;
                .item {
                    @mixin colls 12;
                    text-align:center;
                    margin-bottom:24px;
                    @media (--viewport-desktop) {
                        text-align: start;
                        margin-bottom:48px;
                    }
                    @media (--viewport-desktop-wide) {
                        padding-inline-end: 20px;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                    .title {
                        @mixin body;
                        font-weight: 500;
                    }
                    .link {
                        @mixin colls 12;
                        @mixin paragraph;
                        font-weight: 500;
                        text-align:center;
                        @media (--viewport-desktop) {
                            text-align: start;
                        }
                    }
                }
            }
        }
        .events {
            @mixin colls 12;
            margin: 56px auto 0;
            position: relative;
            max-width: 868px;

            @media (--viewport-tablet) {
                display: flex;
                justify-content: space-between;
                margin-top: 48px;
            }
            @media (--viewport-desktop) {
                @mixin colls 8;
                margin-top: 0;
                max-width: none;
            }
            &:deep(.event-card) {
                @mixin colls 12;
                overflow: hidden;
                margin-inline: auto;
                border-radius: 8px;
                box-shadow: 0 10px 20px rgba(36, 49, 67, 0.2);
                border: 1px solid var(--av-brand-secondary-bright-active);
                @media (--viewport-tablet) {
                    @mixin colls 6;
                }

                &:not(:first-child) {
                    margin-top: 24px;
                    @media (--viewport-tablet) {
                        margin-top: 0;
                    }
                }

                &.main {
                    .event-link {
                        padding-bottom: 32px;
                        display: flex;
                        flex-direction: column;
                    }

                    .labels {
                        margin: -4px;
                        padding: 0 16px;

                        .event-label {
                            margin: 4px 0;
                            margin-inline-end: 8px;
                        }
                    }

                    .title {
                        @mixin title;
                        padding: 0 16px;
                        font-weight: 700;
                        margin: 16px 0 24px;
                        & + div {
                            margin-top: auto;
                        }
                    }

                    .date {
                        @mixin paragraph;
                        padding: 0 16px;
                        line-height: 24px;
                    }

                    .details {
                        @mixin paragraph;
                        padding: 0 16px;

                        &:first-child {
                            font-weight: 500;
                        }
                    }
                }
            }
        }
        @media (--viewport-tablet) {
            .events:deep(.event-card.main) {
                .date,
                .title,
                .labels,
                .details {
                    padding: 0 24px;
                }
            }
        }
    }
}
</style>
