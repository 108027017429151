<script lang="ts">
import { StatusCodes } from 'http-status-codes';
import { trim } from 'lodash';
import Vue from 'vue';
import { ProductAPIQueryBuilder } from '@api/builders/product';
import { HttpRejection } from '@model/http/rejection';
import InAppComponent from './component.vue';

export default Vue.extend({
    name: 'InAppContainer',

    async serverPrefetch(): Promise<void> {
        // ==== Init
        const locale = this.$route.params.locale;
        const slug = trim(`/inapp/${this.$route.params.pathMatch}`, '/');

        const inAppPage = new ProductAPIQueryBuilder('page')
            .setEntityPath('/api/core/in-apps/')
            .addMatchesAll('slug', '=', slug)
            .setOutputOnly(['id', 'type_id'])
            .toObject();
        await this.$store.dispatch('inapp/getEntity', { request: inAppPage });

        const page = this.$store.state.inapp.page;
        if (!page) throw new HttpRejection('Premature rendering stop', StatusCodes.NOT_FOUND);

        const inAppTranslation = new ProductAPIQueryBuilder('translation')
            .setEntityPath('/api/core/in-apps/translations/')
            .addMatchesAll('locale', '=', locale)
            .addMatchesAll('in_app_id', '=', page.id)
            .setOutputOnly(['content'])
            .toObject();
        await this.$store.dispatch('inapp/getEntity', { request: inAppTranslation });

        const translation = this.$store.state.inapp.translation;
        if (!translation) throw new HttpRejection('Premature rendering stop', StatusCodes.NOT_FOUND);
    },

    render(h) {
        return h(InAppComponent);
    },
});
</script>
