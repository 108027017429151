<template>
    <div class="press-releases-home-filters">
        <div class="action-links">
            <span class="filters-counter">{{ commonTranslations.filters }} ({{ filtersCount }})</span>
            <a-link
                v-if="filtersCount > 0"
                class="reset-filters"
                :text="commonTranslations.resetFilters"
                glyph="close"
                :has-no-ref="true"
                @click="resetFilters"
            />
        </div>
        <el-select
            v-model="filters.year"
            v-bind="labelSelect"
            name="year-filter"
            :label="commonTranslations.year"
            :placeholder="commonTranslations.year"
            :hide-on-resize="isDesktop"
            :class="{ 'filter-empty': !filters.year }"
            popper-class="press-release-filter-option"
            filterable
            @change="$emit('change', { filters })"
        >
            <el-option
                v-for="yearOpt in yearOptions"
                :key="yearOpt"
                :value="yearOpt"
                :label="yearOpt"
            >
                <a :href="getUrlByYear(yearOpt)" target="_blank">
                    {{ yearOpt }}
                </a>
            </el-option>
        </el-select>
        <el-select
            v-model="filters.locale"
            v-bind="labelSelect"
            name="language-filter"
            class="language-filter"
            :class="{ 'filter-empty': !filters.locale }"
            :label="commonTranslations.availableLanguages"
            :placeholder="commonTranslations.availableLanguages"
            :hide-on-resize="isDesktop"
            popper-class="press-release-filter-option"
            filterable
            @change="$emit('change', { filters })"
        >
            <el-option
                v-for="loc in availableLocales"
                :key="loc.code"
                :value="loc.code"
                :label="LOCALE_TO_LANGUAGE_MAP[loc.code]"
            >
                <a :href="getUrlByLocale(loc.code)" target="_blank">
                    {{ LOCALE_TO_LANGUAGE_MAP[loc.code] }}
                </a>
            </el-option>
        </el-select>

        <template v-if="!isMounted">
            <div class="seo-years">
                <a v-for="y in yearOptions" :key="y" :href="getPageURL(y)">
                    {{ y }}
                </a>
            </div>
            <div class="seo-locales">
                <a
                    v-for="loc in availableLocales"
                    :key="loc.code"
                    :href="getUrlByLocale(loc.code)"
                    target="_blank"
                >
                    {{ LOCALE_TO_LANGUAGE_MAP[loc.code] }}
                </a>
            </div>
        </template>
    </div>
</template>

<script lang="ts">
import { rangeRight } from 'lodash';
import ALink from '@core/components/link/link.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import form from '@core/mixins/form.js';
import { LOCALE_DEFAULT } from '@model/const/locales';
import { LOCALE_TO_LANGUAGE_MAP } from '@utils/locales';

const MIN_AVAILABLE_YEAR = 2003;

export default {
    name: 'PressReleasesHomeFilters',

    components: {
        ALink,
        ElSelect: () => import('@uikit/ui-kit/packages/select/index.js'),
        ElOption: () => import('@uikit/ui-kit/packages/option/index.js'),
    },

    mixins: [form, breakpoint],

    props: {
        commonTranslations: {
            type: Object as any,
            required: true,
        },
        year: {
            type: Number,
            default: undefined,
        },
    },

    emits: ['change'],

    data: () => ({
        isMounted: false,
        filters: {
            year: '',
            locale: '',
        },
        LOCALE_TO_LANGUAGE_MAP,
    }),

    computed: {
        availableLocales() {
            return this.$store.state.locales.locales.filter(
                (x) => x.is_active_on_public_relations && LOCALE_TO_LANGUAGE_MAP[x.code],
            );
        },

        locale() {
            return this.$route?.params?.locale || LOCALE_DEFAULT;
        },

        filtersCount() {
            const count = Object
                .keys(this.filters)
                .map((val) => this.filters[val])
                .filter(Boolean)
                .length;
            // exclude locale filter
            return Math.max(count - 1, 0);
        },

        yearOptions() {
            return rangeRight(MIN_AVAILABLE_YEAR, new Date().getFullYear() + 1);
        },
    },

    mounted() {
        this.filters.year = this.year || '';
        this.filters.locale = this.$route?.params?.locale || LOCALE_DEFAULT;
        this.isMounted = true;
    },

    methods: {
        getPageURL(year) {
            return `/${this.locale}/pr/${year}/`;
        },

        resetFilters() {
            this.filters = {
                locale: this.filters.locale,
            };
            this.$emit('change', {
                filters: this.filters,
                reset: true,
            });
        },

        getUrlByLocale(locale) {
            let url = `/${locale}/pr/`;
            if (this.year) url += `${this.year}/`;
            return url;
        },

        getUrlByYear(year) {
            return `/${this.locale}/pr/${year}/`;
        },
    },
};
</script>

<style lang="postcss" scoped>
.press-releases-home-filters {
    margin: 32px auto 48px;
    width: 100%;

    @media (--viewport-tablet) {
        margin-top: 0;
        max-width: 224px;
    }

    @media (--viewport-desktop) {
        max-width: 228px;
    }

    @media (--viewport-desktop-wide) {
        max-width: 276px;
    }
}

.action-links {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}

.filters-counter {
    @mixin paragraph;
    color: var(--av-fixed-light);
}

.reset-filters {
    &:deep(.a-link__content) {
        @mixin body;
    }
}

.el-select {
    &:deep(.el-input__label) {
        padding-top: 4px;
    }
}

.filter-empty {
    &:deep(.el-input__label) {
        display: none;
    }

    &:deep(.el-input__placeholder),
    &:deep(.el-input__editor::placeholder) {
        transform: translateY(-6px);
    }
}

.language-filter {
    margin-top: 16px;
}

.seo-years,
.seo-locales {
    display: none;
}
</style>

<style lang="postcss">
.el-popper.press-release-filter-option a {
    @mixin paragraph;
    color: var(--av-fixed-primary, #243143);
    pointer-events: none;
}
</style>
