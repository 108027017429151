<template>
    <s-basic-slice v-bind="$attrs" class="s-comparison">
        <h3 v-if="description" class="description">
            {{ description }}
        </h3>

        <div v-for="(cards, sectionIndex) in visibleCards" :key="sectionIndex" class="cards-wrapper">
            <div
                v-for="(card, cardIndex) in cards"
                :key="`${sectionIndex}-${cardIndex}`"
                class="comparison-card"
            >
                <a-label type="text-label" v-bind="card.alert" class="alert">
                    {{ card.alert.text }}
                </a-label>

                <h4 class="title">
                    {{ card.title }}
                </h4>
                <p class="description">
                    {{ card.description }}
                </p>
            </div>
        </div>

        <p v-if="isMobile" class="collapse-activator" @click="toggleCardsVisibility">
            <span v-if="displayAllCards">{{ collapseText }}</span>
            <span v-else>{{ expandedText }}</span>

            <a-glyph name="drop-down-arrow" :class="{rotate: displayAllCards}" />
        </p>
    </s-basic-slice>
</template>

<script>
import AGlyph from '@core/components/glyph/glyph.vue';
import ALabel from '@core/components/label/label.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';

export default {
    name: 'SComparisonCards',

    components: {
        ALabel,
        AGlyph,
        SBasicSlice,
    },

    mixins: [breakpoint],

    props: {
        description: {
            type: String,
            default: '',
        },
        collapseText: {
            type: String,
            default: 'Show less',
        },
        expandedText: {
            type: String,
            default: 'Show more',
        },
        sections: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            displayAllCards: false,
        };
    },

    computed: {
        visibleCards() {
            return !this.isMobile || this.displayAllCards ? this.sections : this.sections.slice(0, 3);
        },
    },

    methods: {
        toggleCardsVisibility() {
            this.displayAllCards = !this.displayAllCards;
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-comparison {

    .description {
        @mixin title;
        color: var(--av-nav-primary);
        margin-bottom: 48px;
        max-width: 869px;
    }

    .collapse-activator {
        @mixin title-accent;
        color: var(--av-brand-primary);
        align-items: center;
        display: inline-flex;
        margin-top: 40px;
        cursor: pointer;

        > span {
            margin-inline-end: 17px;
        }

        .a-glyph.rotate {
            transform: rotateZ(180deg);
            height: 14px;
            width: 14px;
        }
    }
}

.cards-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 32px;

    @media(--viewport-tablet) {
        grid-template-columns: 1fr 1fr;
        column-gap: 16px;
    }

    &:hover .comparison-card {
        box-shadow: var(--av-shadow-regular);
    }
}
.comparison-card {
    padding: 24px 24px 32px;
    box-shadow: var(--av-shadow-small);
    border: 1px solid var(--av-brand-secondary-light);
    background: var(--av-fixed-white);
    border-radius: 4px;
    width: 100%;
    margin-bottom: 16px;
    transition: 0.5s;

    &:last-of-type {
        margin-bottom: 0;
    }

    @media(--viewport-tablet) {
        margin-bottom: 0px;
    }

    .alert {
        @mixin body-accent;
        margin-bottom: 8px;
    }

    .title {
        @mixin title-accent;
        color: var(--av-nav-primary);
        margin-bottom: 8px;
    }

    .description {
        @mixin body;
        color: var(--av-fixed-secondary);
        margin-bottom: 0;
    }
}
</style>
