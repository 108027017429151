<template>
    <div :class="['event-sections', { 'on-demand-layout': hasOnDemandLayout }]">
        <event-container v-if="partners?.length" id="partners" class="section partners">
            <template v-if="partners.length === 1">
                <h2 class="title">
                    {{ event.section_partners_title }}
                </h2>
                <div v-if="event.section_partners_description" class="section-lead">
                    {{ event.section_partners_description }}
                </div>
                <event-partner-card v-bind="partners[0]" />
            </template>
            <event-partner-logos
                v-else
                :title="event.section_partners_title"
                :lead="event.section_partners_description"
                :partners="partners"
            />
        </event-container>

        <event-container v-if="speakers?.length" id="speakers" class="section speakers">
            <event-speakers
                :title="event.section_speakers_title"
                :lead="event.section_speakers_description"
                :speakers="speakers"
                :limit="allSpeakersShown ? 0 : speakersLimit"
            />
            <div v-if="speakers.length > speakersLimit" class="show-all-button">
                <event-show-all-button
                    :show-all-text="translations.showAllSpeakersTitle"
                    :show-less-text="translations.showLessSpeakersTitle"
                    @toggle="speakerListToggle"
                />
            </div>
        </event-container>

        <event-container v-if="daysWithActivities" id="agenda" class="section agenda">
            <event-agenda
                :title="event.section_activities_title"
                :lead="event.section_activities_description"
                :local-time-days="daysWithActivities.localTime"
                :zoned-time-days="daysWithActivities.zonedTime"
                :my-time-title="translations.myTime"
                :event-time-title="translations.eventTime"
                :topic-title="translations.topic"
                :speaker-title="translations.speaker"
                :training-title="translations.training"
            />
        </event-container>

        <event-container v-if="map" id="location" class="section map">
            <event-map
                :title="map.title"
                :location="map.location"
                :website="map.website"
                :phone="map.phone"
                :marker="map.marker"
                :loading-text="map.loadingText"
            />
        </event-container>

        <event-container
            v-if="videos?.length"
            id="videos"
            class="section videos"
            @progressChanged="onProgressChange($event)"
        >
            <event-video-list
                :title="event.section_videos_title"
                :lead="event.section_videos_description"
                :videos="videos"
                :translations="translations"
                @progressChanged="$emit('progressChanged', $event)"
                @scrollToFormClick="$emit('scrollToFormClick')"
            />
        </event-container>

        <event-container v-if="resources?.length" id="resources" class="section resources">
            <event-resources
                :title="event.section_resources_title"
                :lead="event.section_resources_description"
                :resources="resources"
                :limit="allResourcesShown ? 0 : RESOURCES_LIMIT"
                :download-label="translations.download"
            >
                <event-form
                    class="form"
                    v-bind="form"
                    layout="columns"
                    @unlock="unlockEvent"
                />
            </event-resources>
            <div v-if="resources.length > RESOURCES_LIMIT" class="show-all-button">
                <event-show-all-button
                    :show-all-text="translations.showAllResourcesTitle"
                    :show-less-text="translations.showLessResourcesTitle"
                    @toggle="resourcesToggle"
                />
            </div>
        </event-container>

        <event-container v-if="otherEvents" class="section other-events">
            <h2 class="title">
                {{ translations.otherEventsTitle }}
            </h2>
            <event-card-list
                :cards="otherEvents"
                :limit="OTHER_EVENTS_COUNT"
                :learn-more-title="translations.learnMoreTitle"
                :not-found-title="translations.noEventsFoundTitle"
                :is-loading="otherEventsLoading"
            />
            <div v-if="!otherEventsLoading" class="event-button-wrap">
                <a class="event-button" href="/">
                    {{ translations.moreEventsTitle }}
                    <a-glyph name="drop-down" />
                </a>
            </div>
        </event-container>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import AGlyph from '@core/components/glyph/glyph.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import EventAgenda from '@core/slices/events/event-agenda/event-agenda.vue';
import EventCardList from '@core/slices/events/event-card-list/event-card-list.vue';
import EventForm from '@core/slices/events/event-form/event-form.vue';
import EventMap from '@core/slices/events/event-map/event-map.vue';
import EventPartnerCard from '@core/slices/events/event-partner-card/event-partner-card.vue';
import EventPartnerLogos from '@core/slices/events/event-partner-logos/event-partner-logos.vue';
import EventResources from '@core/slices/events/event-resources/event-resources.vue';
import EventSpeakers from '@core/slices/events/event-speakers/event-speakers.vue';
import EventVideoList from '@core/slices/events/event-video-list/event-video-list.vue';
import EventContainer from '@core/slices/events/shared-components/event-container/event-container.vue';
import EventShowAllButton from '@core/slices/events/shared-components/event-show-all-button/event-show-all-button.vue';
import { RESOURCES_LIMIT, OTHER_EVENTS_COUNT } from '@model/const/events.ts';
import { LOCALE_DEFAULT } from '@model/const/locales.ts';
import eventUtils from '@utils/events.js';

const sortDates = (a, b) => new Date(a.date) - new Date(b.date);

export default {
    name: 'EventSections',

    components: {
        AGlyph,
        EventContainer,
        EventAgenda,
        EventCardList,
        EventMap,
        EventVideoList,
        EventResources,
        EventSpeakers,
        EventPartnerCard,
        EventPartnerLogos,
        EventForm,
        EventShowAllButton,
    },

    mixins: [breakpoint],

    props: {
        event: {
            type: Object,
            required: true,
        },

        eventType: {
            type: String,
            required: true,
        },

        translations: {
            type: Object,
            required: true,
        },

        form: {
            type: Object,
            required: true,
        },

        videos: {
            type: Array,
            default: () => [],
        },

        resourceBaseUrl: {
            type: String,
            required: true,
        },

        hasOnDemandLayout: {
            type: Boolean,
            required: true,
        },

        accessFormEnabled: {
            type: Boolean,
            required: true,
        },
    },

    emits: ['unlock', 'progressChanged', 'scrollToFormClick'],

    data() {
        return {
            RESOURCES_LIMIT,
            OTHER_EVENTS_COUNT,
            allSpeakersShown: false,
            allVideosShown: false,
            allResourcesShown: false,
            unlockedEvents: [],
        };
    },

    computed: {
        ...mapState({ eventsStore: (state) => state.events }),

        locale() {
            return this.$route.params.locale;
        },

        partners() {
            return this.event.partners.map((partner) => this.getPartnerFields(partner));
        },

        speakers() {
            return this.event.speakers?.map((speaker) => this.getSpeakerFields(speaker));
        },

        otherEventsLoading() {
            return this.eventsStore.otherEvents.pagination?.loading;
        },

        speakersLimit() {
            if (this.isMobile) return 6;
            if (this.isTablet) return 9;
            return 8;
        },

        map() {
            if (!this.event.place_latitude || !this.event.place_longitude || !this.event.place_name) return null;

            const map = {
                title: this.translations.mapTitle,
                marker: {
                    lat: this.event.place_latitude,
                    lng: this.event.place_longitude,
                },
                loadingText: this.translations.dataIsLoading,
            };

            if (this.event.place_address) {
                map.location = {
                    title: this.translations.location,
                    value: this.event.place_address,
                };
            }

            if (this.event.place_website) {
                map.website = {
                    title: this.translations.website,
                    value: this.event.place_website,
                };
            }

            if (this.event.place_phone_number) {
                map.phone = {
                    title: this.translations.phone,
                    value: this.event.place_phone_number,
                };
            }

            return map;
        },

        resources() {
            if (!this.event.materials.length) return null;

            const locked = this.event.section_materials_is_gated && this.accessFormEnabled;
            return this.event.materials.map((resource) => ({
                link: `${this.resourceBaseUrl}${resource.file_id}`,
                locked,
                title: resource.name,
                type: resource.type.name,
            }));
        },

        daysWithActivities() {
            if (!this.event.activities.length) return null;

            const days = {
                localTime: [],
                zonedTime: [],
            };

            this.event.activities.forEach((item) => {
                const localTimeTitle = eventUtils.convertDate(
                    item.time_begining,
                    this.eventType,
                    this.event.timezone,
                    LOCALE_DEFAULT,
                    'd MMMM',
                ).formatted;

                const zonedTimeTitle = eventUtils.convertDate(
                    item.time_begining,
                    'virtual',
                    null,
                    LOCALE_DEFAULT,
                    'd MMMM',
                ).formatted;

                const localStartTime = eventUtils.convertDate(
                    item.time_begining,
                    this.eventType,
                    this.event.timezone,
                    LOCALE_DEFAULT,
                    'HH:mm',
                ).formatted;

                const localEndTime = eventUtils.convertDate(
                    item.time_ending,
                    this.eventType,
                    this.event.timezone,
                    LOCALE_DEFAULT,
                    'HH:mm',
                ).formatted;

                const zonedStartTime = eventUtils.convertDate(
                    item.time_begining,
                    'virtual',
                    null,
                    LOCALE_DEFAULT,
                    'HH:mm',
                ).formatted;

                const zonedLocalEndTime = eventUtils.convertDate(
                    item.time_ending,
                    'virtual',
                    null,
                    LOCALE_DEFAULT,
                    'HH:mm',
                ).formatted;

                let labels = [];
                try {
                    labels = JSON.parse(item.labels);
                } catch {
                    // Can it even throw?
                }

                const speakers = item.speakers.map(this.getSpeakerFields);
                const activity = {
                    date: item.time_begining,
                    title: item.title,
                    isTraining: item.is_training,
                    description: item.description,
                    location: item.location,
                    labels,
                    speakers,
                };
                const localTimeActivity = { ...activity, time: `${localStartTime} - ${localEndTime}` };
                const zonedTimeActivity = { ...activity, time: `${zonedStartTime} - ${zonedLocalEndTime}` };

                days.localTime = this.addActivity(days.localTime, localTimeTitle, localTimeActivity);
                days.zonedTime = this.addActivity(days.zonedTime, zonedTimeTitle, zonedTimeActivity);
            });

            days.localTime.forEach((day) => day.activities.sort(sortDates));
            days.zonedTime.forEach((day) => day.activities.sort(sortDates));

            return {
                localTime: days.localTime.sort(sortDates),
                zonedTime: days.zonedTime.sort(sortDates),
            };
        },

        otherEvents() {
            const cards = this.eventsStore.otherEvents.items || [];

            if (!cards.length) return null;

            return cards?.map((event) => eventUtils.getEventCardFields(event, this.translations));
        },

    },

    methods: {
        getPartnerFields: (partner) => ({
            logo: `@${partner.logo_regular_id}`,
            title: partner.title,
            description: partner.description,
            link: partner.website_url ? {
                to: partner.website_url,
                text: partner.title,
            } : null,
        }),

        getSpeakerFields(speaker) {
            const socialLinks = [];

            if (speaker.link_website) {
                socialLinks.push({
                    link: speaker.link_website,
                    name: 'language-select',
                });
            }
            if (speaker.link_facebook) {
                socialLinks.push({
                    link: speaker.link_facebook,
                    name: 'facebook',
                });
            }
            if (speaker.link_twitter) {
                socialLinks.push({
                    link: speaker.link_twitter,
                    name: 'twitter',
                });
            }
            if (speaker.link_linked_in) {
                socialLinks.push({
                    link: speaker.link_linked_in,
                    name: 'linkedin',
                });
            }

            return {
                name: speaker.full_name,
                photo: `@${speaker.photo_id}`,
                position: speaker.position,
                company: speaker.company,
                socials: socialLinks,
                text: speaker.description,
            };
        },

        addActivity(days, title, activity) {
            const dayIndex = days.findIndex((d) => d.title === title);
            if (dayIndex !== -1) {
                days[dayIndex].activities.push(activity);
            } else {
                const date = new Date(activity.date);
                days.push({
                    title,
                    date,
                    activities: [activity],
                });
            }
            return days;
        },

        speakerListToggle(showAll) {
            this.allSpeakersShown = showAll;
        },

        resourcesToggle(showAll) {
            this.allResourcesShown = showAll;
        },

        unlockEvent() {
            this.$emit('unlock');
        },
    },
};
</script>

<style lang="postcss" scoped>
.event-sections {
    font-family: var(--font-family-cyber);

    .section {
        padding-top: 32px;
        padding-bottom: 40px;

        @media (--viewport-tablet) {
            padding-top: 64px;
            padding-bottom: 80px;
        }

        &.speakers {
            padding-bottom: 8px;

            @media (--viewport-tablet) {
                padding-bottom: 48px;
            }
        }
    }

    .show-all-button {
        margin-top: 56px;
    }

    .speakers {
        color: var(--av-nav-primary);
    }

    .other-events {
        border-top: 1px solid var(--av-brand-secondary-light);

        .event-button-wrap {
            margin-top: 56px;
            text-align: center;

            @media (--viewport-tablet) {
                margin-top: 64px;
            }
        }

        .event-button {
            display: inline-flex;
            align-items: center;
            text-align: center;
            padding: 12px 16px;
            width: 100%;
            font-weight: 700;

            @media (--viewport-tablet) {
                width: auto;
            }
        }
    }

    &.on-demand-layout {
        .videos {
            position: relative;
            order: 1;
        }
        .partners {
            position: relative;
            order: 2;
        }
        .speakers {
            position: relative;
            order: 3;
        }
        .agenda {
            position: relative;
            order: 4;
        }
        .map {
            position: relative;
            order: 5;
        }
        .resources {
            position: relative;
            order: 6;
        }
        .other-events {
            position: relative;
            order: 7;
        }
    }
}
</style>
