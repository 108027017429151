<template>
    <s-basic-slice :id="id" class="s-awards" v-bind="$attrs">
        <div
            class="list"
            :class="{ large: computedLarge, 'columns-4': computedLarge && columns === 4 }"
        >
            <component
                :is="award.url ? 'dynamic-link' : 'div'"
                v-for="(award, index) in computedAwards"
                :key="index"
                class="item"
                :to="award.url"
                target="_blank"
            >
                <figure class="award">
                    <div class="image-wrapper">
                        <a-picture class="image" :link="award.image" :alt="award.title" />
                    </div>
                    <figcaption v-if="award.title" class="title">
                        {{ award.title }}
                        <span v-if="!computedLarge && (showDescription && award.description)" class="desc">
                            {{ award.description }}
                        </span>
                    </figcaption>
                </figure>
            </component>
        </div>
        <a-link
            v-if="link"
            class="link"
            v-bind="link"
            :type="link.type || 'direct'"
            :glyph="link.glyph || 'arrow'"
        />
    </s-basic-slice>
</template>

<script>
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';

export default {
    name: 'SAwards',

    components: {
        SBasicSlice,
        ALink,
        APicture,
        DynamicLink,
    },

    inheritAttrs: false,

    serverPrefetch() {
        if (this.awards.length) return;
        this.requestData();
    },

    props: {
        id: {
            type: String,
            default: '',
        },

        showDescription: {
            type: Boolean,
            default: false,
        },

        /**
         * Used only for the large type
         */
        columns: {
            type: Number,
            default: 5,
        },

        /**
         * Alternative to using award_ids if you want to add awards detached from awards storage.
         * Necessary to use when you use large|true, because the images from award storage would be too small.
         */
        awards: {
            type: Array,
            default: () => [],
        },

        /**
         * Slice items
         */
        // eslint-disable-next-line vue/prop-name-casing
        award_ids: {
            type: Array,
            default: () => [],
        },

        /**
         * Slice link
         */
        link: {
            type: [Object, Boolean],
            default: false,
        },
    },

    computed: {
        computedLarge() {
            return this.awards.length;
        },
        computedAwards() {
            if (this.awards.length) return this.awards;
            return this.award_ids.map((pid) => this.$store.state.awards.items[pid]).filter(Boolean);
        },
    },

    methods: {
        requestData() {
            this.award_ids.forEach((aid) => this.$store.dispatch('awards/getAward', aid));
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-awards {
    &:deep(.a-slice-header) {
        margin-bottom: 48px;
        &__title {
            @mixin display-accent;
            @media (--viewport-desktop) {
                @mixin hero-accent;
            }
        }
        &__lead {
            @mixin title;
        }

        &__body-text {
            @mixin lead;
        }
    }

    .list {
        display: flex;
        flex-wrap: wrap;
        row-gap: 40px;
        column-gap: 16px;

        @media (--viewport-mobile-wide) {
            gap: 40px;
        }
        .item {
            max-width: 156px;
        }
        a.item .title {
            color: var(--av-brand-primary);
        }
        .award {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 16px;
            .image {
                height: 48px;
            }
            .title {
                @mixin paragraph;
                font-weight: 600;
                color: var(--av-nav-primary);
            }
            .desc {
                @mixin paragraph;
                color: var(--av-fixed-secondary);
                margin-top: 8px;
            }
        }
        &.large {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: auto;
            gap: 48px 16px;
            align-items: start;
            justify-items: center;
            @media (--viewport-mobile-wide) {
                gap: 48px 24px;
                grid-template-columns: 1fr 1fr 1fr;
            }
            @media (--viewport-desktop) {
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            }
            &.columns-4 {
                @media (--viewport-desktop) {
                    grid-template-columns: 1fr 1fr 1fr 1fr;
                }
                .item {
                max-width: 156px;
                    @media (--viewport-mobile-wide) {
                        max-width: 172px;
                    }
                    @media (--viewport-desktop) {
                        max-width: 212px;
                    }
                    .award {
                        .image-wrapper {
                            @media (--viewport-desktop) {
                                height: 212px;
                            }
                        }
                    }
                }
            }
            .item {
                text-align: center;
                max-width: 156px;
                @media (--viewport-mobile-wide) {
                    max-width: 172px;
                }
                .award {
                    .image-wrapper {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        height: 156px;
                        @media (--viewport-mobile-wide) {
                            height: 172px;
                        }
                    }
                    .image {
                        max-width: 100%;
                        height: 100%;
                        align-content: center;
                        margin: 0 auto;
                    }
                    .title {
                        @mixin descriptor;
                        font-weight: 400;
                    }
                }
            }
        }
    }
    .link {
        margin-top: 56px;

        @media (--viewport-desktop) {
            position: absolute;
            top: 0;
            inset-inline-end: 32px;
            margin: 0;
        }

        @media (--viewport-desktop-wide) {
            inset-inline-end: 64px;
        }

        @media (--viewport-desktop-large) {
            inset-inline-end: 0;
        }
    }
}
</style>
