<template>
    <div class="blog-news-item" :class="className">
        <dynamic-link class="link" :to="link" native-type="a">
            <div class="date">
                <blog-date-localized :date="published_at" />
                <div v-if="video" class="video">
                    — {{ videoLabel }}
                    <a-glyph class="glyph" name="play-video" />
                </div>
            </div>
            <template v-if="type === 'short'">
                <v-clamp class="title" tag="div" :max-lines="3">
                    {{ title }}
                </v-clamp>
            </template>
            <template v-else-if="type === 'full'">
                <div class="title">
                    {{ title }}
                </div>
                <div class="text">
                    {{ description }}
                </div>
            </template>
        </dynamic-link>
    </div>
</template>

<script>
import VClamp from 'vue-clamp';
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import BlogDateLocalized from '../blog-date-localized/blog-date-localized.vue';

export default {
    name: 'BlogNewsItem',
    components: {
        DynamicLink,
        VClamp,
        AGlyph,
        BlogDateLocalized,
    },
    props: {
        link: {
            type: String,
            required: true,
        },

        // eslint-disable-next-line vue/prop-name-casing
        published_at: {
            type: String,
            required: true,
        },

        title: {
            type: String,
            required: true,
        },

        description: {
            type: String,
            required: true,
        },

        video: {
            type: Boolean,
            default: false,
        },

        videoLabel: {
            type: String,
            required: false,
            default: undefined,
        },

        type: {
            type: String,
            default: 'short',
        },
    },

    computed: {
        className() {
            return {
                full: this.type === 'full',
            };
        },
    },
};
</script>

<style lang="postcss" scoped>
.blog-news-item {
    margin-bottom: 16px;

    &:last-child {
        margin-bottom: 0;
    }

    .link {
        text-decoration: none;
        color: var(--av-branded-icon-solid-lightest);

        &:hover {
            color: var(--av-brand-primary);
        }
    }

    .date {
        color: #b0cbe4;
        font-size: 11px;
        line-height: 16px;
    }

    .video {
        display: inline;
    }

    .glyph {
        fill: #b0cbe4;
        margin-inline-start: 4px;
    }

    .title {
        @mixin paragraph;
        font-weight: 500;
    }

    &.full {
        margin-bottom: 40px;

        .link {
            &:hover {
                .title {
                    color: var(--av-brand-primary);
                }
            }
        }
        .date {
            font-size: 11px;
            line-height: 16px;
            color: var(--av-fixed-primary);
            opacity: 0.9;
            margin-bottom: 8px;
        }

        .title {
            font-weight: bold;
            color: var(--av-brand-secondary);
        }

        .text {
            font-size: 14px;
            line-height: 24px;
            color: var(--av-fixed-secondary);
        }
    }
}
</style>
