<template>
    <section class="s-slice site-search">
        <a-breadcrumbs
            v-if="breadcrumbs.length"
            :links="breadcrumbs"
            glyph-desktop="icon-pointer-right"
            glyph-mobile="long-arrow-left"
            theme="light"
        />
        <div class="a-container">
            <div class="input-wrapper">
                <div class="search-field" @keydown.enter="onFilterSubmit">
                    <el-input v-model="searchPhrase" type="search" enterkeyhint="search">
                        <template #prefix>
                            <span class="search-input-prefix">
                                <a-glyph name="mo-search-o--24" size="l" />
                            </span>
                        </template>
                        <template #suffix>
                            <span
                                v-if="searchPhrase.length"
                                class="search-input-suffix"
                                name="close"
                                @click="clearSearch"
                            >
                                <a-glyph name="close" size="m" />
                            </span>
                        </template>
                    </el-input>
                </div>

                <div class="action-btn-wrapper">
                    <a-button
                        class="filter-toggle-btn"
                        glyph="controls"
                        :text="filterLabel"
                        type="main"
                        @click="openMobileFilter"
                    />
                    <div class="search-btn-wrapper">
                        <a-button
                            class="search-btn"
                            :text="searchText"
                            type="main"
                            @click="startSearch()"
                        />
                    </div>
                    <div v-if="helpText" class="help-text desktop">
                        <a-tooltip :text="helpText" placement="right" glyph="info-circle" />
                    </div>
                </div>
            </div>

            <div v-if="helpText" class="help-text mobile">
                <a-glyph name="info-circle-o" />
                <span>{{ helpText }}</span>
            </div>

            <div v-if="isPdfOnly || selectedCategoryFilters.length" class="applied-filter">
                <div class="tags">
                    <a-tag
                        v-for="item in selectedCategoryFilters"
                        :key="item"
                        :text="item"
                        type="chip"
                        closable
                        @click="uncheckCategoryInFilter(item)"
                    />
                    <a-tag
                        v-if="isPdfOnly"
                        :text="pdfSearchText"
                        type="chip"
                        closable
                        @click="isPdfOnly = false;"
                    />
                </div>
                <a-link
                    :text="clearAllFiltersText"
                    size="paragraph"
                    @click="resetCategoryFilter"
                />
            </div>

            <a-button
                class="filter-toggle-btn mobile"
                glyph="controls"
                :text="filterLabel"
                type="main"
                @click="openMobileFilter"
            />

            <template v-if="results.length">
                <div class="search-info">
                    {{ totalResultsText }}
                </div>
                <a-dangerous-html class="results-summary" :content="showingResultsTextFull" />
            </template>

            <a-dangerous-html
                v-else-if="searchPhrase && !isXHRInProgress"
                class="results-summary"
                :content="noResultsTextFull"
            />

            <div v-if="correctedQuery && !isXHRInProgress" class="results-correction">
                {{ searchForText }}
                <a-link
                    :text="correctedQuery"
                    size="lead"
                    @click="correctQuery(correctedQuery)"
                />
            </div>

            <div class="search-body">
                <div v-if="displayFilter" class="filters-mobile" @click="displayFilter = false;">
                    <div class="filters" @click.stop>
                        <a href="#" class="close-button" @click="displayFilter = false;">
                            <a-glyph name="close" />
                        </a>
                        <div>
                            <p class="category-filter-label">
                                {{ categoryFilterLabel }}
                            </p>
                            <el-checkbox-group v-model="pendingCategoryFilters">
                                <el-checkbox
                                    v-for="filter in categoryFilters"
                                    :key="filter.label"
                                    :label="filter.label"
                                >
                                    {{ filter.label }}
                                </el-checkbox>
                                <el-checkbox label="pdf">
                                    {{ pdfSearchText }}
                                </el-checkbox>
                            </el-checkbox-group>
                        </div>
                        <div class="buttons">
                            <a-button
                                class="reset-btn"
                                :text="closePopupText"
                                @click="displayFilter = false;"
                            />
                            <a-button
                                :text="applyFilterText"
                                type="main"
                                @click="applyPendingFilter"
                            />
                        </div>
                    </div>
                </div>

                <div class="filters filters-desktop">
                    <div>
                        <p class="category-filter-label">
                            {{ categoryFilterLabel }}
                        </p>

                        <el-checkbox-group v-model="selectedCategoryFilters" @change="startSearch()">
                            <el-checkbox
                                v-for="filter in categoryFilters"
                                :key="filter.label"
                                :label="filter.label"
                            >
                                {{ filter.label }}
                            </el-checkbox>
                        </el-checkbox-group>
                    </div>
                    <div class="extra-category-filter">
                        <el-checkbox v-model="isPdfOnly" @change="startSearch()">
                            {{ pdfSearchText }}
                        </el-checkbox>
                    </div>
                </div>

                <div
                    v-if="isXHRInProgress"
                    v-loading="true"
                    class="loading"
                    el-loading-size="48"
                />

                <div v-if="results.length && !isXHRInProgress" class="search-results">
                    <div v-for="result in results" :key="result.cacheId" class="result-item">
                        <div class="result-text-content">
                            <a-link
                                class="result-title"
                                :to="result.link"
                                :text="result.title"
                                size="paragraph"
                                target="_blank"
                            />
                            <a-link
                                class="result-link"
                                :to="result.link"
                                :text="formattedLinkPath(result.link)"
                                size="caption"
                                target="_blank"
                            />
                            <a-dangerous-html :content="result.htmlSnippet" class="result-snippet" />
                        </div>
                        <a-dynamic-link class="result-thumbnail" :to="result.link">
                            <img
                                v-if="result.pagemap?.cse_thumbnail?.[0].src"
                                :src="result.pagemap?.cse_thumbnail?.[0].src"
                                alt="Acronis"
                            />
                            <p v-else class="no-image-thumbnail">
                                <a-logo type="full" fill="white" />
                            </p>
                        </a-dynamic-link>
                    </div>

                    <div v-if="totalPagesResult > 1" class="pages-info">
                        <template v-for="page in totalPagesResult">
                            <a
                                v-if="pagination.page !== page"
                                :key="page"
                                class="page-link"
                                :href="getPageLink(page)"
                                @click.prevent="startSearch(page)"
                            >
                                {{ page }}
                            </a>
                            <span v-else :key="page" class="page-link active">
                                {{ page }}
                            </span>
                        </template>
                    </div>
                    <a-link
                        v-if="searchOnGoogleLink"
                        class="search-on-google"
                        glyph="search"
                        glyph-position="left"
                        target="_blank"
                        size="paragraph"
                        :to="searchOnGoogleLink"
                        :text="searchOnGoogleLinkText"
                    />
                </div>
                <div v-if="!results.length && !isXHRInProgress">
                    <p class="top-search-label">
                        {{ topSearchLabel }}
                    </p>

                    <div class="suggestion-wrapper">
                        <span
                            v-for="suggestion in suggestions"
                            :key="suggestion"
                            class="suggestion"
                            @click="onSuggestionClick(suggestion)"
                        >
                            {{ suggestion }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Loading from '@uikit/ui-kit/packages/loading/src/directive';
import axios from 'axios';
import ABreadcrumbs from '@core/components/breadcrumbs/breadcrumbs.vue';
import AButton from '@core/components/button/button.vue';
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import ADynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALink from '@core/components/link/link.vue';
import ALogo from '@core/components/logo/logo.vue';
import ATag from '@core/components/tag/tag.vue';
import ATooltip from '@core/components/tooltip/tooltip.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import { GOOGLE_SEARCH_CX_KEY, GOOGLE_SEARCH_API_KEY } from '@model/const/api-keys';
import { LOCALE_DEFAULT } from '@model/const/locales.ts';
import commonUtils from '@utils/common.ts';
import { localeMapping } from './locales-mapping.js';

export default {
    name: 'SSiteSearch',
    components: {
        ABreadcrumbs,
        AButton,
        ALink,
        ALogo,
        ADynamicLink,
        ADangerousHtml,
        AGlyph,
        ATag,
        ATooltip,
        ElCheckbox: () => import('@uikit/ui-kit/packages/checkbox'),
        ElCheckboxGroup: () => import('@uikit/ui-kit/packages/checkbox-group'),
        ElInput: () => import('@uikit/ui-kit/packages/input'),
    },
    directives: {
        Loading,
    },
    mixins: {
        breakpoint,
    },
    props: {
        suggestions: {
            type: Array,
            default: () => [],
        },
        categoryFilters: {
            type: Array,
            default: () => [],
        },
        filterLabel: {
            type: String,
            default: 'Filter',
        },
        categoryFilterLabel: {
            type: String,
            default: 'Filter results by category',
        },
        topSearchLabel: {
            type: String,
            default: 'Top searches on Acronis',
        },
        breadcrumbs: {
            type: Array,
            default: () => [],
        },
        helpText: {
            type: String,
            default: null,
        },
        pdfSearchText: {
            type: String,
            default: 'Show only PDFs',
        },
        noResultsText: {
            type: String,
            default: 'No results found for',
        },
        showingResultsText: {
            type: String,
            default: 'Showing results for',
        },
        searchOnGoogleText: {
            type: String,
            default: 'Search for @query on Google',
        },
        searchForText: {
            type: String,
            default: 'Search instead for',
        },
        searchText: {
            type: String,
            default: 'Search',
        },
        numberOfItemsText: {
            type: String,
            default: '@onPage of @total items',
        },
        closePopupText: {
            type: String,
            default: 'Close',
        },
        applyFilterText: {
            type: String,
            default: 'Apply filter',
        },
        clearAllFiltersText: {
            type: String,
            default: 'Clear all filters',
        },
    },
    data() {
        return {
            GOOGLE_SEARCH_LINK: 'https://www.google.com/search?q=',
            MAX_PAGES: 10,
            MAX_RESULTS: 100,
            isXHRInProgress: false,
            searchPhrase: '',
            noResultsTextFull: '',
            showingResultsTextFull: '',
            selectedCategoryFilters: [],
            pendingCategoryFilters: [],
            searchOnGoogleLink: null,
            searchOnGoogleLinkText: null,
            correctedQuery: null,
            isPdfOnly: false,
            results: [],
            searchInformation: {},
            displayFilter: false,
            pagination: {
                page: 1,
                start: 1,
                size: 10,
                totalListedResults: 0,
            },
        };
    },

    computed: {
        totalPagesResult() {
            const totalPages = Math.ceil(this.pagination.totalListedResults / this.pagination.size);
            return totalPages < this.MAX_PAGES ? totalPages : this.MAX_PAGES;
        },

        totalResultsText() {
            if (!this.results.length) return '';

            const onPage = `${this.searchInfo.start} - ${this.searchInfo.end}`;
            return this.numberOfItemsText
                .replace('@onPage', onPage)
                .replace('@total', this.pagination.totalListedResults);
        },

        locale() {
            return this.$route?.params.locale || LOCALE_DEFAULT;
        },

        searchInfo() {
            const start = 1 + (this.pagination.page - 1) * this.pagination.size;
            const end = start + this.pagination.size - 1;
            return {
                start,
                end: end < this.pagination.totalListedResults ? end : this.pagination.totalListedResults,
            };
        },
    },

    watch: {
        searchPhrase(val) {
            if (!val.length) {
                this.correctedQuery = '';
                this.noResultsTextFull = '';
                this.searchOnGoogleLink = '';
                this.searchOnGoogleLinkText = '';
            }
        },
    },

    async mounted() {
        this.pagination.page = parseInt(this.$route?.query?.page, 10) || 1;
        this.pagination.start = (this.pagination.page - 1) * this.pagination.size + 1;
        this.searchPhrase = commonUtils.sanitize(this.$route?.query?.query || '');
        const category = this.$route?.query?.category || [];
        this.selectedCategoryFilters = Array.isArray(category) ? category : [category];
        this.isPdfOnly = this.$route?.query?.pdf === '1';

        await this.doSearch();
    },

    methods: {
        resetCategoryFilter() {
            if (this.isPdfOnly || this.selectedCategoryFilters.length) {
                this.isPdfOnly = false;
                this.selectedCategoryFilters = [];
                this.startSearch();
            }
            this.displayFilter = false;
        },

        uncheckCategoryInFilter(category) {
            this.selectedCategoryFilters = this.selectedCategoryFilters.filter((x) => x !== category);
            this.startSearch();
        },

        getPageLink(page) {
            const queryString = new URLSearchParams({ ...this.$route?.query, page }).toString();
            return `${this.$route?.path}?${queryString}`;
        },

        onFilterSubmit(event) {
            event.srcElement?.blur();
            this.startSearch();
        },

        onFilterChange() {
            this.displayFilter = false;
            this.startSearch();
        },

        openMobileFilter() {
            this.pendingCategoryFilters = [
                ...this.selectedCategoryFilters,
                ...(this.isPdfOnly ? ['pdf'] : []),
            ];
            this.displayFilter = true;
        },

        applyPendingFilter() {
            this.selectedCategoryFilters = this.pendingCategoryFilters.filter((x) => x !== 'pdf');
            this.isPdfOnly = this.pendingCategoryFilters.some((x) => x === 'pdf');
            this.displayFilter = false;

            if (!this.isDesktop) {
                this.startSearch();
            }
        },

        async startSearch(page = 1) {
            this.pagination.page = page;
            this.pagination.start = (page - 1) * this.pagination.size + 1;

            this.changeQueryParams();

            await this.doSearch();

            window.scrollTo({ top: 0, behavior: 'smooth' });
        },

        changeQueryParams() {
            const queryData = {
                query: this.searchPhrase,
                category: this.selectedCategoryFilters,
                ...(this.isPdfOnly && { pdf: 1 }),
                ...(this.pagination.page > 1 && { page: this.pagination.page }),
            };

            const filteredQueryEntries = Object.entries(queryData).filter(([, value]) => value !== null && value !== '');
            const filteredQuery = Object.fromEntries(filteredQueryEntries);

            this.$router?.replace({ query: filteredQuery }).catch(() => {});
        },

        formattedLinkPath(path) {
            if (!path) return '';

            return path.replace(/^http[s]?:\/\//gm, '')
                .split('/')
                .filter(Boolean)
                .join(' › ');
        },

        clearSearch() {
            this.searchPhrase = '';
            this.results = [];
        },

        onSuggestionClick(suggestion) {
            this.searchPhrase = suggestion;
            this.startSearch();
        },

        async fetchSearchResults(data) {
            const client = axios.create({
                baseURL: 'https://customsearch.googleapis.com/customsearch/v1',
            });

            try {
                const response = await client.get('', {
                    params: {
                        cx: GOOGLE_SEARCH_CX_KEY,
                        key: GOOGLE_SEARCH_API_KEY,
                        ...data,
                    },
                });
                return response.data;
            } catch (error) {
                return null;
            }
        },

        composeSearchTerm() {
            if (!this.selectedCategoryFilters.length) return this.searchPhrase;

            let selectedSites = this.selectedCategoryFilters
                .flatMap((label) => this.categoryFilters.find((category) => category.label === label)?.links);

            selectedSites = selectedSites.map((site) => `site:${site}`).join(' OR ');
            return `${this.searchPhrase} ${selectedSites}`;
        },

        correctQuery(correctedQuery) {
            this.searchPhrase = correctedQuery;
            this.startSearch();
        },

        async doSearch() {
            this.searchPhrase = commonUtils.sanitize(this.searchPhrase);

            if (!this.searchPhrase) {
                this.results = [];
                return;
            }

            this.isXHRInProgress = true;

            const searchObject = {
                q: this.composeSearchTerm(),
                start: this.pagination.start,
                num: this.pagination.size,
                lr: localeMapping[this.locale],
            };

            if (this.isPdfOnly) searchObject.fileType = 'pdf';

            this.correctedQuery = null;

            const response = await this.fetchSearchResults(searchObject);

            this.results = response?.items || [];

            if (!this.results.length && this.pagination.page > 1) {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: 'Acronis',
                    eventCategory: 'Webtest',
                    eventAction: 'search page',
                    eventLabel: 'redirect counter',
                });
                this.startSearch();
                return;
            }

            const searchRequest = encodeURIComponent(response?.queries?.request[0].searchTerms || this.searchPhrase);
            this.searchOnGoogleLink = `${this.GOOGLE_SEARCH_LINK}${searchRequest}`;
            this.searchOnGoogleLinkText = this.searchOnGoogleText.replace(/@query/i, `<span>${this.searchPhrase}</span>`);

            this.noResultsTextFull = `${this.noResultsText} <strong>${this.searchPhrase}</strong>`;
            this.showingResultsTextFull = `${this.showingResultsText} <strong>${this.searchPhrase}</strong>`;

            const totalResults = Number(response?.searchInformation.totalResults) || 0;
            this.pagination.totalListedResults = totalResults < this.MAX_RESULTS ? totalResults : this.MAX_RESULTS;

            if (response?.spelling?.correctedQuery) {
                this.correctedQuery = response?.spelling?.correctedQuery.replace(/\ssite:.*/i, '');
            }

            this.isXHRInProgress = false;
        },
    },
};
</script>

<style lang="postcss" scoped>
.site-search {
    margin-top: 32px;

    @media(--viewport-desktop) {
        margin-top: 40px;
    }
}

.a-breadcrumbs {
    border-top: 1px solid var(--av-brand-secondary-light);
    padding-top: 24px;
}

.input-wrapper {
    display: flex;
    padding-top: 56px;
    gap: 16px;

    > div:first-of-type {
        flex-grow: 1;
    }

    .search-input-prefix {
        display: inline-flex;
        align-items: center;
        padding: 0 13px 0 8px;

        .a-glyph {
            fill: var(--av-fixed-lighter);
        }
    }

    .search-input-suffix {
        cursor: pointer;
    }

    &:deep(::-webkit-search-cancel-button) {
        display: none;
    }
}

.results-summary {
    @mixin display-accent;
    margin-top: 24px;

    &:deep(strong) {
        font-weight: 700;
        color: var(--av-nav-secondary);
    }
}

.results-correction {
    @mixin lead;
    margin-top: 8px;

    &:deep(.a-link__content) {
        font-weight: 700;
    }
}

.result {
    &-item {
        display: flex;
        margin-bottom: 32px;
        gap: 24px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &-link {
        margin-bottom: 8px;
        line-break: anywhere;

        &:deep(.a-link__content) {
            color: var(--av-fixed-success-dark);
        }
    }

    &-snippet {
        @mixin body;
    }

    &-text-content {
        flex-wrap: wrap;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }

    &-thumbnail {
        height: 96px;
        width: 178px;
        border-radius: 4px;
        overflow: hidden;
        margin-left: auto;
        justify-content: center;
        display: none;
        flex-shrink: 0;

        @media(--viewport-tablet) {
            display: block;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }

        .no-image-thumbnail {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--av-nav-primary);
            height: 100%;

            .a-logo {
                height: 18px;
            }
        }
    }
}

.search-on-google {
    @mixin paragraph;

    margin-top: 24px;

    span {
        font-weight: 700;
    }
}

.help-text {
    display: flex;
    justify-content: center;
    padding-top: 15px;

    &.desktop {
        display: none;
        @media(--viewport-tablet) {
            display: inline-flex;
        }
    }

    &.mobile {
        @mixin caption;
        color: var(--av-fixed-light);
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 16px;
        gap: 8px;

        &:deep(.a-glyph) {
            fill: var(--av-fixed-light);
            flex-shrink: 0;
        }

        @media(--viewport-tablet) {
            display: none;
        }
    }
}

.pages-info {
    margin-top: 54px;
    margin-inline-start: -4px;
}

.page-link {
    padding: 4px;
    cursor: pointer;

    &:hover {
        color: var(--av-brand-primary);
    }

    &.active {
        color: var(--av-brand-primary);
        font-weight: bold;
    }
}

.filter-toggle-btn {
    flex-direction: row-reverse;
    background-color: var(--av-brand-secondary-bright-hover);
    color: var(--av-brand-primary);
    height: fit-content;
    justify-content: center;

    @media(--viewport-desktop) {
        display: none;
    }

    &:deep(.a-glyph) {
        margin-inline-end: 8px;
        margin-inline-start: 0;
        fill: var(--av-brand-primary);
    }

    &.mobile {
        display: flex;
        width: 100%;
        margin-top: 16px;

        @media(--viewport-tablet) {
            display: none;
        }
    }
}

.search-btn {
    min-width: 200px;

    @media(--viewport-tablet) {
        min-width: 112px;
    }
}

.filter-toggle-btn,
.search-btn {
    @media(--viewport-tablet) {
        min-width: 112px;
    }

    @media(--viewport-desktop) {
        min-width: 136px;
    }

    @media(--viewport-desktop-wide) {
        min-width: 200px;
    }
}

.action-btn-wrapper {
    display: none;
    gap: 16px;

    @media(--viewport-tablet) {
        display: flex;
    }

    .search-btn-wrapper {
        flex-direction: column;
        align-items: center;
        display: inline-flex;

        .a-button {
            width: 100%;
        }
    }

    &:deep(.a-tooltip .tooltip-wrapper) {
        height: 16px;
        display: block;
    }

    &:deep(.a-tooltip .a-glyph) {
        fill: var(--av-fixed-lighter);
    }
}

.search-info {
    margin-bottom: 28px;
    color: var(--av-fixed-light);
    margin-top: 32px;

    @media(--viewport-tablet) {
        margin-top: 24px;
    }
}

.applied-filter {
    display: flex;
    flex-wrap: wrap;
    column-gap: 24px;
    row-gap: 16px;
    margin-top: 16px;

    .tags {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        align-items: center;

        > div {
            display: flex;
        }
    }

    @media(--viewport-desktop) {
        display: none;
    }
}

.search-body {
    display: flex;
    gap: 32px;
    margin-top: 40px;

    .category-filter-label {
        @mixin lead-accent;
        margin-bottom: 16px;
    }

    .filters-mobile {
        position: relative;
        position: fixed;
        z-index: 122; /* above breadcrumbs */
        display: flex;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: var(--av-fixed-lighter);
        justify-content: center;
        align-items: flex-end;

        @media(--viewport-mobile-wide) {
            align-items: center;
        }

        @media(--viewport-desktop) {
            display: none;
        }

        .filters {
            position: relative;
            width: 100%;
            max-width: 576px;
            padding: 24px;
            border-start-start-radius: 16px;
            border-start-end-radius: 16px;
            background-color: var(--av-fixed-white);

            > div {
                margin-bottom: 0;
                background-color: var(--av-fixed-white);
            }

            @media(--viewport-mobile-wide) {
                border-radius: 8px;
                max-width: 454px;
            }
        }

        .close-button {
            position: absolute;
            top: 0;
            inset-inline-end: -48px;
            height: 32px;
            width: 32px;
            border: 1px solid var(--av-fixed-white);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;

            .a-glyph {
                fill: var(--av-fixed-white);
            }
        }

        .category-filter-label {
            @mixin lead-accent;
            text-align: center;

            @media(--viewport-mobile-wide) {
                @mixin title-accent;
            }
        }

        .el-checkbox-group {
            display: block;
        }

        &:deep(.el-checkbox-group__content) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 24px;
            row-gap: 16px;
        }

        .el-checkbox {
            margin-block: 4px;
        }

        &:deep(.el-checkbox__content) {
            align-items: flex-start;
        }

        &:deep(.el-checkbox__control) {
            margin-top: 4px;
        }

        .buttons {
            margin-top: 32px;
            display: grid;
            gap: 16px;

            @media(--viewport-mobile-wide) {
                grid-template-columns: 1fr 1fr;
            }
        }

        .a-button {
            padding: 8px 16px;

            @media(--viewport-mobile-wide) {
                padding: 12px 16px;
            }
        }
    }

    .filters-desktop {
        display: none;
        @media(--viewport-desktop) {
            display: flex;
            flex-direction: column;
        }

        > div {
            padding: 16px 24px;
            display: flex;
            flex-direction: column;
            background: var(--layout-brand-lightest, rgba(38, 104, 197, 0.05));
            margin-bottom: 16px;

            .el-checkbox:not(:last-of-type) {
                margin-bottom: 16px;
            }
        }

        &:deep(.el-checkbox-group__content) {
            display: flex;
            flex-direction: column;

            .el-checkbox__label {
                @mixin paragraph;
            }
        }
    }

    .filters {
        min-width: 300px;
    }

    .top-search-label {
        @mixin title;
        font-weight: 500;
        margin-bottom: 16px;
    }

    .suggestion-wrapper {
        gap: 8px;
        display: flex;
        flex-wrap: wrap;
    }
    .suggestion {
        @mixin note;
        letter-spacing: 1px;
        text-transform: uppercase;
        cursor: pointer;
        border-radius: 999px;
        padding: 4px 8px;
        border: 1px solid var(--av-brand-secondary);
        color: var(--av-fixed-info-dark);
        background: var(--av-fixed-info-accent);
    }
}

.loading {
    width: 100%;
}
</style>
