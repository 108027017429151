<template>
    <section :id="id" class="s-slice s-feature-single" :class="{ ...className, ...basicClassName }">
        <div class="a-container">
            <a-icon
                v-if="icon"
                class="s-feature-single__icon"
                :number="icon"
                :icon-variant="iconVariant"
                :icon-alt="iconAlt || title"
            />
            <div class="a-slice-header__title">
                {{ title }}
            </div>
            <div class="s-feature-single__wrapper">
                <div class="s-feature-single__description">
                    <div class="a-slice-header__lead s-feature-single__lead">
                        {{ lead }}
                    </div>
                    <div v-if="bodyText" class="a-slice-header__body-text s-feature-single__body-text">
                        {{ bodyText }}
                    </div>
                    <ul v-if="content && content.features" class="a-list a-list_type_bullet s-feature-single__features">
                        <li
                            v-for="item in content.features"
                            :key="item"
                            class="a-list__item s-feature-single__features-item"
                        >
                            {{ item }}
                        </li>
                    </ul>
                </div>
                <div class="s-feature-single__list-container">
                    <ul v-if="list" class="s-feature-single__list a-list a-list_type_bullet">
                        <li v-for="item in list" :key="item" class="s-feature-single__item a-list__item">
                            {{ item }}
                        </li>
                    </ul>
                    <div v-if="content && content.list && content.list.links" class="s-feature-single__list-links-container">
                        <p v-if="content.list.title" class="s-feature-single__list-links-title">
                            {{ content.list.title }}
                        </p>
                        <ul class="s-feature-single__list-links">
                            <li
                                v-for="item in content.list.links"
                                :key="item.to"
                                class="s-feature-single__list-links-item"
                                :class="{ 's-feature-single__list-links-item-3': content.list.column === 3 }"
                            >
                                <a-link
                                    v-if="item.to"
                                    v-bind="item"
                                    :glyph="item.glyph || 'download-arrow'"
                                    :glyph-position="item.glyphPosition || 'left'"
                                /><span v-else>{{ item.text }}</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <ul v-if="content && content.cta" class="s-feature-single__cta">
                    <li v-for="item in content.cta" :key="item.to" class="s-feature-single__cta-item">
                        <a-button
                            v-if="item.type"
                            v-bind="item"
                            :size="item.size || 's'"
                        />
                        <a-link
                            v-else
                            v-bind="item"
                            :glyph="item.glyph || 'download-arrow'"
                            :size="item.size || 'paragraph'"
                        />
                    </li>
                </ul>
                <a-link
                    v-if="link"
                    class="s-feature-single__link"
                    v-bind="link"
                    :type="link.type || 'direct'"
                    :glyph="link.glyph || 'arrow'"
                />
            </div>
        </div>
    </section>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import AIcon from '@core/components/icon/icon.vue';
import ALink from '@core/components/link/link.vue';
import contentMixin from '@core/mixins/content.js';
import styleMixin from '@core/mixins/style.js';

export default {
    name: 'SFeatureSingle',

    components: {
        ALink,
        AIcon,
        AButton,
    },

    mixins: [contentMixin, styleMixin],

    props: {
        /**
         * icon
         */
        icon: {
            type: [String, Number],
            required: false,
            default: undefined,
        },

        /**
         * Icon variant
         */
        iconVariant: {
            type: Boolean,
            default: false,
        },

        /**
         * icon alt
         */
        iconAlt: {
            type: String,
            required: false,
            default: undefined,
        },

        /**
         * list
         */
        list: {
            type: Array,
            required: false,
            default: undefined,
        },

        /**
         * Slice link
         */
        link: {
            type: Object,
            required: false,
            default: undefined,
        },

        /**
         * theme
         */
        theme: {
            type: String,
            required: false,
            default: '',
        },

        /**
         * Content
         */
        content: {
            type: Object,
            required: false,
            default: undefined,
        },
    },

    computed: {
        className() {
            return {
                's-feature-single_layout_2-col': this.list && this.list.length % 2 === 0,
                's-feature-single_layout_3-col': this.list && this.list.length % 3 === 0,
                's-feature-single_theme_light': this.theme === 'light',
                's-feature-single_theme_dark': this.theme === 'dark',
                's-feature-single_theme_darker': this.theme === 'darker',
                's-feature-single_has-body-text': this.bodyText,
            };
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-feature-single {
    &__icon {
        margin-bottom: 24px;
        width: 64px;
        height: 64px;
    }

    &__list {
        margin-top: 40px;
        padding: 24px 16px 24px 24px;
        background-color: var(--av-brand-lightest);
    }

    &__item {
        &:not(:last-of-type) {
            margin-bottom: 16px;
        }
    }

    &__link {
        margin-top: 56px;
    }

    &_theme {
        &_light {
            background-color: var(--av-fixed-white);

            .s-feature-single {
                &__list {
                    background: var(--av-brand-lightest);
                }
            }
        }

        &_dark {
            background: var(--av-brand-lightest);

            .s-feature-single {
                &__list,
                &__list-links-container {
                    background-color: var(--av-fixed-white);
                }
            }
        }

        &_darker {
            background: var(--av-brand-lightest);

            .s-feature-single {
                &__list {
                    background-color: var(--av-solid-grey);
                }
            }
        }
    }

    &__lead {
        margin-top: 40px;
    }

    &__features {
        margin-top: 32px;
    }

    &__features-item {
        padding-inline-end: 0 !important;
    }

    &__cta {
        display: flex;
        margin-top: 56px;
        text-align: center;
        flex-direction: column;
    }

    &__cta-item {
        .a-button {
            min-width: 100%;
        }
        .a-link {
            vertical-align: top;
        }
        .a-glyph {
            margin-inline-start: 8px;
        }
    }

    &__list-links-container {
        padding: 24px;
        margin: 40px 0 0;
        border-radius: 4px;
        background-color: var(--av-brand-lightest);
    }

    &__list-links-title {
        @mixin lead;
        font-weight: 600;
        color: var(--av-nav-primary);
    }

    @media (--viewport-tablet) {
        &__icon {
            width: 96px;
            height: 96px;
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
            padding: 24px 0 0 24px;
        }

        &__item {
            margin-bottom: 24px;
            padding: 0 24px;
        }

        &_layout {
            &_3-col {
                .s-feature-single {
                    &__item {
                        width: 33.3%;
                    }
                }
            }

            &_2-col {
                .s-feature-single {
                    &__item {
                        width: 50%;
                    }
                }
            }
        }

        &__cta {
            text-align: start;
            align-items: center;
            flex-direction: row;
        }

        &__cta-item {
            &:not(:first-child) {
                margin-inline-start: 16px;
            }
            .a-button {
                min-width: 120px;
            }
        }

        &__list-links {
            display: flex;
            margin: 0 -8px;
            flex-wrap: wrap;
            flex-direction: row;
        }

        &__list-links-item {
            width: calc(50% - 8px * 2);
            margin: 16px 8px 0;
        }

        &__list-links-item-3 {
            width: calc(33.33% - 8px * 2);
        }
    }

    @media (--viewport-desktop) {
        .a-slice-header {
            &__title {
                width: 66.6%;
            }

            &__lead {
                max-width: 100%;
            }
        }

        &__wrapper {
            margin-top: 24px;
            display: grid;
            grid-template-columns: 7fr 4fr;
            -ms-grid-columns: 7fr 10% 4fr;
            grid-template-rows: auto 1fr;
            -ms-grid-rows: auto 1fr;
            grid-column-gap: 10%;
        }

        &__lead {
            margin-top: 0;
            -ms-grid-column: 1;
        }

        &__list-container {
            grid-row: span 2;
            -ms-grid-column: 3;
            -ms-grid-row-span: 2;
        }

        &__list {
            margin-top: 0;
            display: block;
            padding: 24px 0 24px 24px;
        }

        &__item {
            padding: 0;
            padding-inline-end: 8px;
            padding-inline-start: 24px;

            &:not(:last-of-type) {
                margin-bottom: 16px;
            }
        }

        &__cta {
            margin-top: 40px;
        }

        &__link {
            -ms-grid-row: 2;
        }

        .s-feature-single {
            &__list-links-container {
                margin: 16px 0 0;
            }

            &__item {
                width: 100%;

                &:not(:last-of-type) {
                    margin-bottom: 16px;
                }
            }
        }

        &_layout {
            &_3-col {
                .s-feature-single {
                    &__item {
                        margin-bottom: 24px;

                        &:last-of-type {
                            margin-bottom: 0;
                        }
                    }
                }
            }

            &_2-col {
                .s-feature-single {
                    &__item {
                        margin-bottom: 24px;

                        &:last-of-type {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }

        &_has-body-text {
            .s-feature-single {
                &__lead {
                    margin: 16px 0 0;
                }
            }
        }

        &__list-links {
            flex-wrap: nowrap;
            flex-direction: column;
        }

        &__list-links-item {
            width: 100%;
            margin: 16px 8px 0;
        }

        &__list-links-item-3 {
            width: 100%;
        }
    }

    @media (--viewport-desktop-wide) {
        .a-slice-header__title {
            width: 67%;
        }

        &__list {
            padding: 24px;
        }
    }
}

.s-slice_background {
    &_light-blue {
        .s-feature-single {
            &_theme {
                &_dark {
                    background: rgba(255, 255, 255, 0);
                }
            }
        }
    }
}
</style>
