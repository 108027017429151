<template>
    <div :class="['s-purchasing-acp-addons', ...className]">
        <div class="a-container">
            <p class="title">
                {{ translation('Add-ons for Acronis Cyber Protect') }}
            </p>

            <div class="wrapper">
                <s-addon-list
                    :settings="settings"
                    @changeAddon="$emit('changeAddon', $event)"
                    @changeQuantity="$emit('changeQuantity', $event)"
                    @toggleProduct="$emit('toggleProduct', $event)"
                />

                <div class="summary">
                    <div class="summary-sticky">
                        <p class="summary-title">
                            {{ translation('Order summary') }}
                        </p>
                        <template v-if="addedLicenses.length">
                            <s-order-addon :settings="settings" />
                            <s-total :settings="settings" @changePage="$emit('changePage', 'products')" />
                        </template>
                        <template v-else>
                            <p class="get-started">
                                {{ translation('Get started by selecting add-on products') }}
                            </p>
                            <s-warnings
                                v-if="settings.mode === 'addons'"
                                :list="cloudWarnings"
                                @changePage="$emit('changePage', 'products')"
                            />
                        </template>
                    </div>
                </div>
            </div>

            <p class="summary-link-product">
                <a-link size="paragraph" :text="textLink" @click="$emit('changePage', 'products')" />
            </p>
        </div>
    </div>
</template>

<script>
import ALink from '@core/components/link/link.vue';
import SAddonList from './components/addon-list.vue';
import SOrderAddon from './components/order-addon.vue';
import STotal from './components/total.vue';
import SWarnings from './components/warnings.vue';
import mixin from './mixin.js';

export default {
    name: 'SAddons',

    components: {
        ALink,
        SAddonList,
        SOrderAddon,
        STotal,
        SWarnings,
    },

    mixins: [mixin],

    props: {
        settings: {
            type: Object,
            default: null,
        },
    },

    emits: ['changeAddon', 'toggleProduct', 'changePage', 'changeQuantity'],

    computed: {
        className() {
            return [{ 'has-border': this.settings?.page?.hasBorder }, { 'has-background': this.settings?.page?.hasBackground }];
        },

        textLink() {
            return this.translation('I want to add Acronis Cyber Protect licenses');
        },
    },

    mounted() {
        this.addCloud();
    },
};
</script>

<style lang="postcss" scoped>
.s-purchasing-acp-addons {
    position: relative;
    padding: 96px 0 40px;

    @media (--viewport-tablet) {
        padding-top: 112px;
    }

    @media (--viewport-desktop) {
        padding-top: 128px;
    }

    &:before {
        left: 0;
        bottom: 0;
        z-index: 1;
        content: '';
        width: 100%;
        height: 388px;
        position: absolute;
        background: linear-gradient(180deg, rgba(38, 104, 197, 0) 0%, rgba(38, 104, 197, 0.05) 100%);
    }

    .a-container {
        z-index: 1;
    }
}

.has-border {
    border-bottom: 2px solid var(--av-brand-secondary-light);
}

.has-background {
    background: var(--av-inversed-primary);
}

.title {
    @mixin display;
    text-align: center;
    color: var(--nav-nav-primary);

    @media (--viewport-tablet) {
        text-align: left;
    }

    @media (--viewport-desktop) {
        @mixin hero;
    }
}

.wrapper {
    display: flex;
    margin: 40px 0 0;
    flex-direction: column;

    @media (--viewport-tablet) {
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: space-between;
    }

    @media (--viewport-desktop) {
        margin: 32px 0 0;
    }
}

.summary {
    width: 100%;

    @media (--viewport-tablet) {
        width: 33.33%;
        margin-inline-start: 16px;
    }

    @media (--viewport-desktop) {
        width: 40.9%;
    }
}

.summary-sticky {
    top: 24px;
    position: sticky;
}

.summary-title {
    @mixin nav-title-accent;

    margin: 32px 0 0;
    color: var(--av-nav-primary);

    @media (--viewport-tablet) {
        margin: 0;
    }
}

.summary-link-product {
    margin: 32px 0 0;

    @media (--viewport-tablet) {
        text-align: center;
    }

    &:deep(.a-link) {
        &__content {
            @mixin title-accent;
            color: var(--av-brand-primary);
        }
    }
}

.get-started {
    padding: 48px 0;
    margin: 24px 0 64px;
    border-top: 1px solid var(--av-brand-secondary-light);
    border-bottom: 1px solid var(--av-brand-secondary-light);
}
</style>
