import { get, merge, sortBy } from 'lodash';
import { mapState } from 'vuex';
import analytics from '@core/mixins/analytics.js';
import currency from '@core/mixins/currency.js';
import { LOCALE_DEFAULT } from '@model/const/locales.ts';

export default {
    mixins: [analytics, currency],

    computed: {
        ...mapState({
            licensesItems: (state) => state.licenses.data,
            productId: (state) => get(state, 'pages.page.product_id', 1),
        }),

        locale() {
            return get(this, '$route.params.locale', LOCALE_DEFAULT);
        },

        country() {
            return this.locale.split('-').pop().toUpperCase();
        },

        language() {
            return this.locale.split('-').shift();
        },

        listWorkloads() {
            return this.productList('workloads');
        },

        stepActive() {
            return get(this, 'settings.steps.active', 1);
        },

        dictionary() {
            return this.settings.dictionary;
        },

        editionClear() {
            return { time: 0, added: false, selectedPeriod: 0, selectedQuantity: 1 };
        },

        productClear() {
            return {
                time: 0,
                price: 0,
                added: false,
                opened: false,
                selected: false,
                selectedCloud: 0,
                selectedPeriod: 0,
                selectedQuantity: 1,
            };
        },

        isComparisonActive() {
            return get(this, 'settings.comparison.active', false);
        },

        promo() {
            return this.settings.promo;
        },

        microsoft365Added() {
            return this.listWorkloads.find((item) => item.id === 'microsoft-365')?.added;
        },
    },

    methods: {
        listSections() {
            return this.settings.sections || [];
        },

        time() {
            return new Date().getTime();
        },

        setLocale(str) {
            return !str ? 'https://www.acronis.com/' : str.replace(/LOCALE/g, this.locale);
        },

        setTranslate(str) {
            return this.dictionary[str] || str;
        },

        comparisonToggle() {
            const isActive = !this.settings.comparison.active;
            document.body.style.position = isActive ? 'fixed' : '';
            this.settings.comparison.active = isActive;
        },

        changeStep(step) {
            const current = Number(step) || 1;
            this.settings.steps.active = current;

            if (this.$route && current < 3) {
                this.$router?.push({ hash: `#step=${current}` }).catch(() => false);
            }
        },

        changeActiveSection(id) {
            this.settings.sectionsActive = Number(id);
        },

        hasEditions(product) {
            return product.editions;
        },

        isBundleProduct(productID) {
            return /google-workspace|microsoft-365/.test(productID);
        },

        productClose(sectionID) {
            merge(
                this.productList(sectionID),
                this.productList(sectionID).map((p) => ({ ...p, opened: false })),
            );
        },

        productOpen(productID) {
            this.currentProduct(productID).opened = true;
        },

        productOpened(sectionID) {
            return this.productList(sectionID).find((p) => p.opened) || {};
        },

        productList(sectionID) {
            return this.currentSection(sectionID).products || [];
        },

        editionList(productID) {
            return this.currentProduct(productID).editions || [];
        },

        currentSection(sectionID) {
            return this.listSections().find((i) => i.id === sectionID) || {};
        },

        currentProduct(productID) {
            return this.listSections()
                .map((section) => section.products.find((p) => p.id === productID))
                .find((item) => item);
        },

        currentEdition(productID, editionName) {
            return this.editionList(productID).find((p) => p.name === editionName) || [];
        },

        editionAddedList(productID) {
            const result = this.editionList(productID).filter((p) => p.added);
            return sortBy(result, ['time']);
        },

        editionQuantity(edition) {
            return edition?.selectedQuantity || 1;
        },

        editionSelected(edition) {
            return edition?.selectedPeriod || 0;
        },

        editionType(edition) {
            return get(edition, 'advanced.selected', false) ? 'advanced' : 'standard';
        },

        editionTypeSubscriptions(edition) {
            return this.editionPackage(edition) === 1 ? 'subscriptions' : 'subscriptionsCustom';
        },

        editionPackage(edition) {
            const result = edition?.package?.selected || null;
            return result ? edition.package.type[result] : 1;
        },

        editionLicense(edition, selected) {
            const type = this.editionType(edition);
            const active = Number.isInteger(selected) ? selected : this.editionSelected(edition);
            const subscriptions = this.editionTypeSubscriptions(edition);
            return get(edition, `[${subscriptions}][${type}][${active}]`, []);
        },

        editionMailboxes(product, edition, type, editionSelected) {
            let price = 0;
            let selected = edition.selectedQuantity > 5 ? edition.selectedQuantity : 5;

            if (edition.selectedPeriod === editionSelected) {
                this.clearStore(product);
            }

            edition.subscriptionsCustom.mailboxes.forEach((mailbox) => {
                if (mailbox > selected || selected < 5) {
                    return;
                }
                const item = (selected - (selected % mailbox)) / mailbox;
                const quantity = selected % mailbox > 0 ? item : selected / mailbox;
                const current = this.editionLicense(edition, editionSelected).mailboxes.find((m) => m.quantity === mailbox);
                const license = this.licenseData({
                    cleverBridgeCartID: current.cleverBridgeCartID,
                    promo: this.settings.promo,
                });

                if (edition.selectedPeriod === editionSelected) {
                    this.setProductStore(product.id, license.cleverbridge_id, quantity);
                }

                if (type === 'full') {
                    const discount = license.discount_absolute || 0;
                    price += discount * quantity;
                }

                if (type !== 'discount_absolute') {
                    price += license.price * quantity;
                }

                selected -= mailbox * item;
            });

            return price;
        },

        isSubscriptionCustom(edition) {
            return this.editionTypeSubscriptions(edition) === 'subscriptionsCustom';
        },

        editionPrice(product, edition, type) {
            if (this.isSubscriptionCustom(edition)) {
                return this.editionMailboxes(product, edition, type, edition.selectedPeriod);
            }

            const current = this.editionLicense(edition);
            const cleverBridgeCartID = current.cleverBridgeCartID;
            const license = this.licenseData({ cleverBridgeCartID, promo: this.settings.promo });

            const price = license.price || 0;
            const quantity = this.editionQuantity(edition);
            this.setProductStore(product.id, license.cleverbridge_id, quantity);

            if (type === 'discount_absolute') {
                const isActivePromo = this.hasLicensePromo({ cleverBridgeCartID, promo: this.settings.promo });
                const discount = license.discount_absolute * quantity;
                return isActivePromo && discount ? discount : 0;
            }

            return price * quantity;
        },

        editionPeriod(product, edition, type) {
            const period = this.currentEdition(product.id, edition.name).selectedPeriod;
            return this.dictionary.subscriptionTitle[type || 'short'][period]?.title || '';
        },

        editionAdd(product, edition) {
            const param = { added: true, time: this.time() };
            merge(this.currentProduct(product.id), { ...param, selected: true });
            merge(this.currentEdition(product.id, edition.name), { ...param });
            this.productPrice(product);
        },

        editionRemove(product, edition) {
            merge(this.currentEdition(product.id, edition.name), this.editionClear);

            if (!this.editionAddedList(product.id).length) {
                merge(this.currentProduct(product.id), this.productClear);
            }

            this.productPrice(product);
        },

        cloudStoragePeriod(product) {
            const period = product.selectedPeriod;
            return this.dictionary.subscriptionTitle.long[period]?.title || '';
        },

        cloudStorageTitle(product) {
            const cloud = product.selectedCloud || 0;
            const selected = product?.selectedPeriod || 0;
            return product.cloud.standard[selected][cloud].title;
        },

        productPrice(product, priceType = 'price') {
            let price = 0;
            this.clearStore(product);

            if (product.subscriptions) {
                const type = product.subscriptions.isAdvanced ? 'advanced' : 'standard';
                const selected = product?.selectedPeriod || 0;
                const cleverBridgeCartID = product.subscriptions[type][selected].cleverBridgeCartID || 0;
                const license = this.licenseData({ cleverBridgeCartID, promo: this.settings.promo });

                price = license[priceType] || 0;
                this.setProductStore(product.id, license.cleverbridge_id, 1);
            }

            if (product.perpetual) {
                const type = product.perpetual.isAdvanced ? 'advanced' : 'standard';
                const quantity = product?.selectedQuantity || 1;
                const cleverBridgeCartID = product.perpetual[type].cleverBridgeCartID;
                const license = this.licenseData({ cleverBridgeCartID, promo: this.settings.promo });

                price = (license[priceType] || 0) * quantity;
                this.setProductStore(product.id, license.cleverbridge_id, quantity);
            }

            if (product.cloud) {
                const type = product.subscriptions.isAdvanced ? 'advanced' : 'standard';
                const selected = product?.selectedPeriod || 0;
                const selectedCloud = product?.selectedCloud || 0;
                const cleverBridgeCartID = product.cloud[type][selected][selectedCloud].cleverBridgeCartID;
                const license = this.licenseData({ cleverBridgeCartID, promo: this.settings.promo });

                const priceAddons = (product?.addons || [])
                    .filter((addon) => addon.added)
                    .map((addon) => {
                        const licenseProduct = this.licenseData({
                            cleverBridgeCartID: addon.cleverBridgeCartID,
                            promo: this.settings.promo,
                        });
                        this.setProductStore(product.id, licenseProduct.cleverbridge_id, 1);
                        return licenseProduct[priceType] || 0;
                    })
                    .reduce((p, c) => p + c, 0);

                price = (license[priceType] + priceAddons) || 0;
                this.setProductStore(product.id, license.cleverbridge_id, 1);
            }

            if (product.editions) {
                price = product.editions
                    .filter((item) => item.added)
                    .map((edition) => this.editionPrice(product, edition, priceType))
                    .reduce((p, c) => p + c, 0);
            }

            this.setProductPrice(product.id, price, priceType);
            return price;
        },

        productAdd(product) {
            this.currentProduct(product.id).added = true;
            this.currentProduct(product.id).time = this.time();
            this.microsoft365Check();
        },

        productRemove(product) {
            this.setProductPrice(product.id, 0);
            merge(this.currentProduct(product.id), { added: false });
        },

        productLicense(product) {
            const selected = product.selectedQuantity;
            const type = product.subscriptions.isAdvanced ? 'advanced' : 'standard';
            return product.subscriptions[type][selected];
        },

        setProductPrice(productID, price, type) {
            this.currentProduct(productID)[type || price] = price;
        },

        setProductStore(productID, cartID, quantity) {
            if (!cartID) {
                return;
            }
            this.currentProduct(productID).store[cartID] = quantity;
        },

        clearStore(product) {
            this.currentProduct(product.id).store = {};
        },

        setProductAddons(item) {
            this.currentProduct(item.productID).addons[item.index].added = item.added;
        },

        setProductCloud(item) {
            this.currentProduct(item.productID).selectedCloud = item.quantity;
        },

        setProductPeriod(item) {
            this.currentProduct(item.productID).selectedPeriod = item.quantity;
        },

        setProductQuantity(item) {
            this.currentProduct(item.productID).selectedQuantity = item.quantity;
        },

        setEditionPeriod(item) {
            const currentEdition = this.currentEdition(item.productID, item.editionName);
            currentEdition.selectedPeriod = item.period;
        },

        microsoft365Check() {
            if (!this.microsoft365Added) return;
            const cloud = this.productList('addons').find((item) => item.id === 'cloud-storage');
            this.productRemove(cloud);
        },
    },
};
