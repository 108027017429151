<template>
    <div class="header-developer">
        <div class="wrapper a-container">
            <a-dynamic-link v-if="topSubtitle" class="top-subtitle" to="/">
                <h3>{{ topSubtitle }}</h3>
            </a-dynamic-link>

            <h1 v-if="title" class="title">
                {{ title }}
            </h1>

            <div v-if="lead" class="lead">
                {{ lead }}
            </div>

            <div v-if="ctaButtons?.length" class="cta-buttons">
                <a-button
                    v-for="button in ctaButtons"
                    :key="button.to"
                    v-bind="button"
                    class="cta-button"
                />
            </div>

            <div v-if="backLink" class="back-link">
                <a-dynamic-link to="/">
                    <a-glyph name="arrow-left" size="s" color="brand-primary" />
                    <span>{{ backLink.text }}</span>
                </a-dynamic-link>
            </div>
        </div>

        <a-picture
            v-if="cover"
            :link="cover.mobile"
            class="cover-inline cover-gradient"
        />

        <a-picture
            v-if="cover"
            :background="cover"
            fit="cover"
            :is-background="true"
            class="cover-background cover-gradient"
        />

        <div class="sticky-treshold" />
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import ADynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import APicture from '@core/components/picture/picture.vue';

export default {
    name: 'DeveloperHeader',

    components: {
        AButton,
        ADynamicLink,
        AGlyph,
        APicture,
    },

    props: {
        topSubtitle: {
            type: String,
            required: false,
            default: '',
        },
        title: {
            type: String,
            required: false,
            default: '',
        },
        lead: {
            type: String,
            required: false,
            default: '',
        },
        ctaButtons: {
            type: Array,
            required: false,
            default: undefined,
        },
        backLink: {
            type: Object,
            required: false,
            default: undefined,
        },
        cover: {
            type: Object,
            required: false,
            default: undefined,
        },
    },
};
</script>

<style lang="postcss" scoped>
.header-developer {
    min-height: 140px;
    background: var(--av-nav-primary);
    color: var(--av-fixed-white);
    position: relative;
    padding: 12px 0 160px 0;

    @media (--viewport-desktop) {
        padding-bottom: 64px;
    }
}

.wrapper {
    z-index: 2;
}

.top-subtitle {
    display: none;
    width: 320px;
    margin-bottom: 64px;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.3px;
    text-decoration: none;
    font-weight: 300;

    @media (--viewport-tablet) {
        display: block;
    }
}

.title {
    font-size: 32px;
    line-height: 40px;
    padding-top: 60px;
    margin-bottom: 16px;

    @media (--viewport-mobile-wide) {
        font-size: 40px;
        line-height: 48px;
        width: auto;
    }

    @media (--viewport-tablet) {
        padding-top: 0;
        width: 464px;
    }

    @media (--viewport-desktop) {
        font-size: 48px;
        line-height: 56px;
        font-weight: 700;
        width: 624px;
    }
}

.lead {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 40px;

    @media (--viewport-tablet) {
        font-size: 18px;
        line-height: 24px;
    }
}

.cta-buttons {
    display: block;
    margin-bottom: 72px;

    @media (--viewport-mobile-wide) {
        display: flex;
    }
}

.cta-button {
    width: 100%;
    margin: 0 0 16px 0;

    @media (--viewport-mobile-wide) {
        width: auto;
        min-width: 222px;
        margin: 0 16px 0 0;
    }
}

.back-link {
    font-size: 16px;
    line-height: 16px;
    color: var(--av-brand-secondary);
}

.cover-inline {
    position: absolute;
    inset: 160px 0 0 0;
    display: block;

    @media (--viewport-desktop) {
        display: none;
    }

    :deep(.a-picture__img) {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.cover-background {
    display: none;

    @media (--viewport-desktop) {
        display: block;
    }
}

.cover-gradient::after {
    content: "";
    position: absolute;
    bottom: 0;
    inset-inline-start: 0;
    inset-inline-end: 0;
    height: 168px;
    background: linear-gradient(0deg, var(--av-nav-primary), transparent)
}
</style>
