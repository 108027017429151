<template>
    <div v-if="isVisible" id="comparison-acp" class="s-purchasing-acp-comparison">
        <div class="products">
            <div class="products-wrapper">
                <div class="product-item">
                    <p class="product-title" v-html="translation('Compare Acronis Cyber Protect editions')" />
                </div>
                <div class="product-item">
                    <div v-for="(edition, editionIndex) in editionList" :key="`edition-${editionIndex}`" class="edition-item">
                        <div class="edition-title" v-html="edition.title" />
                        <div v-if="!isMobile" class="edition-price" v-html="price(edition)" />
                        <div v-if="!isMobile" class="edition-button">
                            <a-button
                                size="s"
                                type="action"
                                :text="translation('Configure')"
                                :event="{ doNotSendGA: true }"
                                @click.native="scrollTo(`edition-${edition.product}`)"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-for="(row, rowIndex) in comparisonList" :key="`row-${rowIndex}`" :class="{ ...rowClassName(row) }">
            <div v-for="(cell, cellIndex) in row" :key="`cell-${cellIndex}`" :class="{ ...cellClassName(row, cell) }">
                {{ isNubmer(cell) ? '' : cell }}
            </div>
        </div>
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import currency from '@core/mixins/currency.js';
import mixin from '../mixin.js';

export default {
    name: 'SComparison',

    components: {
        AButton,
    },

    mixins: [breakpoint, mixin, currency],

    props: {
        settings: {
            type: Object,
            default: null,
        },
    },

    computed: {
        isVisible() {
            return this.comparisonList.length;
        },

        comparisonList() {
            return this?.settings?.comparison?.list || [];
        },
    },

    methods: {
        price(edition) {
            const text = this.translation('From @price per year');
            return text.replace('@price', this.setCurrency(edition.fromPerYear));
        },

        isNubmer(item) {
            return Number.isInteger(item);
        },

        rowClassName(row) {
            return {
                row,
                'row-title': row.length <= 1,
            };
        },

        cellClassName(row, item) {
            return {
                cell: row,
                [`cell-${item ? 'plus' : 'minus'}`]: this.isNubmer(item),
            };
        },
    },
};
</script>

<style lang="postcss" scoped>
.products {
    top: 0;
    z-index: 1;
    position: sticky;
    background: var(--av-fixed-white);
    box-shadow: 0 15px 20px 0 rgba(36, 49, 67, 0.2);
    border-bottom: 2px solid var(--av-brand-secondary-light);
}

.products-wrapper {
    gap: 0 4px;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 1320px;
    padding: 40px 4px;
    justify-content: center;

    @media (--viewport-desktop) {
        gap: unset;
        flex-wrap: nowrap;
        padding: 40px 32px;
    }

    @media (--viewport-desktop-wide) {
        gap: unset;
        padding: 40px 64px;
    }

    @media (--viewport-desktop-large) {
        padding: 40px 0;
    }
}

.product-item {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;

    @media (--viewport-tablet) {
        width: 25%;
        padding-inline-end: 16px;

        &:first-child {
            width: 100%;
        }
    }

    @media (--viewport-desktop) {
        &:first-child {
            width: 25%;
        }
    }

    &:not(:first-child) {
        gap: 0 4px;
        margin-top: 8px;

        @media (--viewport-tablet) {
            padding: 0;
            width: 100%;
            gap: 0 16px;
            margin: 32px 32px 0;
        }

        @media (--viewport-desktop) {
            width: 75%;
            margin: 0;
        }
    }
}

.product-title {
    @mixin display;
    width: 100%;
    font-weight: 500;
    text-align: center;
    color: var(--av-nav-primary);

    @media (--viewport-desktop) {
        text-align: left;
    }
}

.edition-item {
    width: 33.33%;
    display: flex;
    flex-wrap: wrap;
    padding: 8px;
    border-radius: 8px;
    align-items: stretch;
    flex-direction: column;
    justify-content: center;
    border: 1px solid var(--av-brand-secondary-light);

    @media (--viewport-tablet) {
        padding: 16px;
    }
}

.edition-title {
    @mixin body-accent;
    text-align: center;
    color: var(--av-nav-primary);

    @media (--viewport-tablet) {
        @mixin lead-accent;
    }
}

.edition-price {
    @mixin paragraph;
    width: 100%;
    font-weight: 400;
    margin-top: auto;
    padding-top: 16px;
    text-align: center;
    color: var(--av-fixed-secondary);
}

.edition-button {
    width: 100%;
    padding-top: 16px;

    .a-button {
        @mixin paragraph-accent;
        width: 100%;
        white-space: unset;
        text-align: center;
        background-color: #7c9b1e;

        &:hover {
            background: var(--av-fixed-success-darker);
        }
    }
}

.row {
    @mixin body;
    display: flex;
    padding: 16px;
    flex-wrap: wrap;
    flex-direction: row;
    color: var(--av-fixed-secondary);
    border-top: 1px solid var(--av-brand-secondary-light);

    &:last-child {
        border-bottom: 1px solid var(--av-brand-secondary-light);
    }

    @media (--viewport-desktop) {
        padding: 0 32px;
    }

    @media (--viewport-desktop-wide) {
        margin: 0 auto;
        padding: 0 64px;
        max-width: 1320px;
    }

    @media (--viewport-desktop-large) {
        padding: 0;
    }
}

.cell {
    display: flex;
    width: 33.33%;
    padding: 0 16px;
    font-weight: 700;
    text-align: center;
    align-items: center;
    justify-content: center;

    &:first-child {
        width: 100%;
        font-weight: 400;
    }

    @media (--viewport-desktop) {
        width: 25%;
        padding: 16px 0;
        border-inline-start: 1px solid var(--av-brand-secondary-light);

        &:first-child {
            width: 25%;
            text-align: start;
            padding-inline-end: 16px;
            border-inline-start: none;
            justify-content: flex-start;
        }
    }
}

.row-title {
    @mixin lead-accent;
    border-top: none;
    color: var(--av-fixed-primary);
    background: var(--av-solid-brand-accent);

    @media (--viewport-desktop-wide) {
        padding: 0;
        max-width: none;
    }

    .cell {
        width: 100%;
        font-weight: 700;

        @media (--viewport-desktop-wide) {
            margin: 0 auto;
            padding: 16px 64px;
            width: 1320px;
        }

        @media (--viewport-desktop-large) {
            padding: 16px 0;
        }
    }

    + .row {
        border-top: none;
    }
}

.cell-plus,
.cell-minus {
    vertical-align: middle;

    &:after {
        content: '';
        display: block;
        margin: 0 auto;
    }
}

.cell-plus {
    &:after {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: var(--av-fixed-success);
    }
}

.cell-minus {
    position: relative;

    &:after {
        top: 50%;
        width: 24px;
        height: 2px;
        margin-top: -1px;
        position: absolute;
        inset-inline-start: 50%;
        margin-inline-start: -12px;
        background: var(--av-nav-primary);
    }
}
</style>
