<template>
    <section :id="id" class="s-message-featured">
        <a-picture
            class="s-message-featured__bg"
            :link="image"
            is-background
            fit="cover"
        />
        <div class="a-container">
            <div class="s-message-featured__content" :class="[marginClasses, paddingClasses]">
                <div class="s-message-featured__title">
                    {{ title }}
                </div>
                <a-dangerous-html class="s-message-featured__text" :content="text" />
                <a-button
                    v-if="link"
                    v-bind="link"
                    class="s-message-featured__link"
                    :to="link.path"
                    :type="link.type || 'action'"
                    :size="link.size || 'm'"
                />
            </div>
        </div>
    </section>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import APicture from '@core/components/picture/picture.vue';
import spacingMixin from '@core/mixins/spacing-mixin.js';

export default {
    name: 'SMessageFeatured',

    components: {
        ADangerousHtml,
        AButton,
        APicture,
    },

    mixins: [spacingMixin],

    props: {
        id: {
            type: String,
            required: true,
        },

        image: {
            type: String,
            required: true,
        },

        title: {
            type: String,
            required: true,
        },

        text: {
            type: String,
            required: true,
        },

        link: {
            type: [Object, Boolean],
            default: false,
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-message-featured {
    position: relative;
    color: var(--av-fixed-white);
    text-align: center;
    background-color: var(--av-nav-primary);
    overflow: hidden;

    &__bg {
        position: absolute;
        width: 100%;
        height: 100%;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            inset-inline-end: 0;
            inset-inline-start: 0;
            width: 100%;
            background: radial-gradient(
                50% 67.41% at 50% 100%,
                rgba(0, 32, 77, 0.24) 0%,
                rgba(0, 32, 77, 0.7447) 56.77%,
                #00204d 90.97%
            );
        }
    }

    &__content {
        position: relative;
        margin: 0 auto;
        min-height: 448px;
        padding: 80px 0;
    }

    &__text {
        font-size: 18px;
        line-height: 24px;
    }

    &__title {
        margin-bottom: 24px;
        font-size: 32px;
        font-weight: 30;
        line-height: 40px;
    }

    &__link {
        margin-top: 40px;
        min-width: 178px;
    }

    @media (--viewport-mobile-wide) {
        &__content {
            margin: 0 auto;
            max-width: 83.33%;
            padding: 100px 0;
        }

        &__title {
            @mixin hero;
        }

        &__text {
            font-size: 24px;
            line-height: 32px;
        }
    }

    @media (--viewport-desktop-wide) {
        &__content {
            max-width: 66.66%;
        }
    }
}
</style>
