/* eslint-disable max-len */
const ARG_DEFAULTS = {
    translations: {
        title: 'What is Acronis Disk Director',
        lead: 'Acronis Disk Director 12.5 delivers a set of powerful tools that work together to optimize your disk usage and protect your data. Store different file systems or operating systems on one disk by creating and managing disk partitions.',
        'link.text': 'Optional Link',
    },
    title: 'title',
    lead: 'lead',
};

const ARG_LINK = { text: 'link.text', to: '#' };

export const Figma = 'https://www.figma.com/design/eM4W6JS2MjG1gp5YhKoMp7Dd/Slices?node-id=0-43532&t=1waBN0cV8cXbijjh-0';

export const DowndloadsMode = {
    args: {
        ...ARG_DEFAULTS,
        translations: {
            ...ARG_DEFAULTS.translations,
            'downloadFileArr.0.title': 'White paper',
            'downloadFileArr.0.desc': 'PDF, 0.9 MB',
            'downloadFileArr.1.title': 'What’s New',
            'downloadFileArr.1.desc': 'PDF, 0.9 MB',
            'downloadFileArr.2.title': 'Datasheet',
            'downloadFileArr.2.desc': 'PDF 0.9 MB',
        },
        link: ARG_LINK,
        downloadFileArr: [
            {
                title: 'downloadFileArr.0.title',
                desc: 'downloadFileArr.0.desc',
                link: '#dnl1',
            },
            {
                title: 'downloadFileArr.1.title',
                desc: 'downloadFileArr.1.desc',
                link: '#dnl2',
            },
            {
                title: 'downloadFileArr.2.title',
                desc: 'downloadFileArr.2.desc',
                link: '#dnl3',
            },
        ],
    },
};

export const FactMode = {
    args: {
        ...ARG_DEFAULTS,
        translations: {
            ...ARG_DEFAULTS.translations,
            title: 'Available on Windows OS only',
            text: 'Restoring to dissimilar hardware is not available on Mac ( neither for OS X, nor for the Bootcamp partition).',
        },
        fact: {
            image: '/images/intro/windows.png',
            title: 'title',
            text: 'text',
        },
    },
};

export const QuoteMode = {
    args: {
        ...ARG_DEFAULTS,
        translations: {
            ...ARG_DEFAULTS.translations,
            'quote.text': '“The Acronis Cyber Partner Program helps our partners quickly grow their businesses. With advanced sales and technical support.”',
            'quote.name': 'Gaidar Magdanurov',
            'quote.position': 'Acronis Chief Cyber Strategy Officer',
        },
        quote: {
            text: 'quote.text',
            image: '/images/intro/quote_author',
            name: 'quote.name',
            position: 'quote.position',
        },
    },
};

export const BodyTextMode = {
    args: {
        ...ARG_DEFAULTS,
        translations: {
            ...ARG_DEFAULTS.translations,
            bodyText: `
                <p>Acronis Disk Director 12.5 delivers a set of powerful tools that work together to optimize your disk usage and protect your data. Store different file systems or operating systems on one disk by creating and managing disk partitions. <a href="#">Acronis Disk Director</a> 12.5 delivers a set of powerful tools that work together to optimize your disk usage and protect your data. Store different file systems or operating systems on one disk by creating and managing disk partitions. Store different file systems or operating systems on one disk.</p>
                <p><b>Looks like it's not designed to work together with lead.</b></p>
            `,
        },
        lead: null,
        link: ARG_LINK,
        bodyText: 'bodyText',
    },
};
