<template>
    <div class="modal">
        <div class="content">
            <p v-if="content.title" class="title" v-html="content.title" />
            <p v-if="content.description" class="description" v-html="content.description" />
            <template v-if="content.buttons">
                <div v-for="(button, buttonIndex) in content.buttons" :key="`button-${buttonIndex}`">
                    <a-button
                        v-if="button.isButton"
                        v-bind="button"
                        :type="button.type || 'main'"
                        :event="{ doNotSendGA: true }"
                        @click="$emit('modalChangeProduct')"
                    />
                    <a-link
                        v-else-if="button.isLink"
                        v-bind="button"
                        :size="button.size || 'paragraph'"
                        @click="$emit('modalClose')"
                    />
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import ALink from '@core/components/link/link.vue';

export default {
    name: 'SModal',

    components: {
        ALink,
        AButton,
    },

    props: {
        settings: {
            type: Object,
            default: null,
        },
    },

    emits: ['modalClose', 'modalChangeProduct'],

    data: () => ({
        content: {},
    }),

    mounted() {
        this.content = this.settings.products.find((item) => item.product === 'acps').modal;
    },
};
</script>

<style lang="postcss" scoped>
.modal {
    top: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    text-align: center;
    inset-inline-end: 0;
    align-items: center;
    justify-content: center;
    background: rgba(0, 47, 114, 0.4);
}

.content {
    max-width: 662px;
    border-radius: 4px;
    padding: 32px 48px;
    background: var(--av-fixed-white);
    border: 1px solid var(--av-brand-primary);
    border-top: 6px solid var(--av-fixed-success);
    box-shadow: 0 10px 20px 0 var(--av-fixed-lightest);
}

.title {
    @mixin display-accent;
    color: var(--av-nav-primary);
}

.description {
    @mixin paragraph;
    margin: 24px 0 0;

    &:deep(b) {
        font-weight: 700;
    }
}

.a-button {
    @mixin paragraph-accent;
    width: 100%;
    margin: 24px 0 0;
    color: var(--av-fixed-white);
}

.a-link {
    margin: 24px 0 0;
    @mixin paragraph;
}
</style>
