<template>
    <div v-if="cloud" class="s-dropdown-cloud-storage">
        <div class="s-dropdown-cloud-storage__title">
            {{ cloud.title }}
        </div>
        <div class="s-dropdown-cloud-storage__wrapper" :class="className">
            <div class="s-dropdown-cloud-storage__selector" @click="open()">
                <div class="s-dropdown-cloud-storage__selector-title">
                    {{ optionTitle() }}
                </div>
                <div class="s-dropdown-cloud-storage__suffix">
                    <a-glyph name="chevron-down" />
                </div>
            </div>
            <div class="s-dropdown-cloud-storage__options">
                <div v-for="(option, optionIndex) in cloudList" :key="`option-${optionIndex}`" class="s-dropdown-cloud-storage__option">
                    <label class="s-dropdown-cloud-storage__option-label">
                        <input
                            v-model="selectedOption"
                            class="s-dropdown-cloud-storage__option-input"
                            name="cloud"
                            type="radio"
                            :value="optionIndex"
                            @change="setProductCloud({ productID: product.id, quantity: selectedOption })"
                        />
                        <div class="s-dropdown-cloud-storage__option-title">{{ option.title }}</div>
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AGlyph from '@core/components/glyph/glyph.vue';
import mixin from '../mixin.js';

export default {
    name: 'SDropdownCloudStorage',

    components: {
        AGlyph,
    },

    mixins: [mixin],

    props: {
        /**
         * Settings
         */
        settings: {
            type: Object,
            default: null,
        },

        /**
         * Product
         */
        product: {
            type: Object,
            default: null,
        },
    },

    data: () => ({
        isActive: false,
        selectedOption: 0,
    }),

    computed: {
        className() {
            return {
                's-dropdown-cloud-storage__opened': this.isActive,
            };
        },

        cloud() {
            return this.product.cloud;
        },

        cloudList() {
            const type = this.product.subscriptions.isAdvanced ? 'advanced' : 'standard';
            const selectedPeriod = this.product.selectedPeriod;
            return this.cloud[type][selectedPeriod];
        },
    },

    mounted() {
        this.selectedOption = this.product.selectedCloud;
    },

    methods: {
        open() {
            this.isActive = true;
            window.addEventListener('click', this.close, true);
        },

        close() {
            this.isActive = false;
            window.removeEventListener('click', this.close, true);
        },

        optionTitle() {
            const selectedCloud = this.product.selectedCloud;
            return this.cloudList[selectedCloud].title;
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-dropdown-cloud-storage {
    &__title {
        @mixin body;
        color: var(--av-fixed-secondary);
    }

    &__wrapper {
        height: 40px;
        border-radius: 4px;
        position: relative;
        background: var(--av-fixed-white);
        border: 1px solid var(--av-brand-light);
    }

    &__selector {
        display: flex;
        padding: 7px 0;
        cursor: pointer;
        flex-wrap: nowrap;
        align-items: center;
        color: var(--av-fixed-primary);
        justify-content: space-between;

        &:first-child {
            min-width: 124px;
        }

        &-title {
            @mixin paragraph-accent;
            white-space: nowrap;
            padding: 0 16px;
        }
    }

    &__price {
        width: 100%;
        text-align: end;
    }

    &__suffix {
        @mixin paragraph;
        padding-inline-end: 11px;
        padding-inline-start: 8px;

        .a-glyph {
            margin: 0 0 2px;
            transition: none;
            vertical-align: middle;
            fill: var(--av-brand-primary);
        }
    }

    &__options {
        z-index: 2;
        display: none;
        padding: 8px 0;
        margin: 0 -1px;
        width: calc(100% + 2px);
        border-radius: 0 0 4px 4px;
        border: 1px solid var(--av-brand-primary);
        border-top: 1px solid var(--av-brand-primary);
    }

    &__option {
        position: relative;

        &:hover {
            background: var(--av-brand-secondary-bright-hover);
        }

        &-title {
            @mixin paragraph-accent;
            white-space: nowrap;
            padding-inline-end: 8px;
            padding-inline-start: 16px;
        }

        &-label {
            width: 100%;
            display: flex;
            cursor: pointer;
            padding: 8px 0;
            padding-inline-end: 34px;
            justify-content: space-between;
        }

        &-input {
            top: 0;
            inset-inline-start: 0;
            opacity: 0;
            width: 1px;
            height: 1px;
            border: none;
            position: absolute;
        }
    }

    &__opened {
        border-radius: 4px 4px 0 0;
        border-color: var(--av-brand-primary);

        &:before {
            top: 0;
            inset-inline-start: 0;
            z-index: 1;
            content: '';
            width: 100%;
            height: 100%;
            cursor: default;
            position: absolute;
        }

        .s-dropdown-cloud-storage {
            &__selector {
                cursor: default;
            }

            &__suffix {
                .a-glyph {
                    transform: rotate(180deg);
                }
            }

            &__options {
                inset-inline-start: 0;
                top: 100%;
                display: block;
                position: absolute;
                background: var(--av-fixed-white);
            }
        }
    }
}
</style>
