<template>
    <s-basic-slice class="s-cta-vss" v-bind="$attrs">
        <div class="s-cta-vss__description">
            <div class="s-cta-vss__left">
                <a-logo class="s-cta-vss__logo" type="full" fill="white" />
                <h2 class="s-cta-vss__product-name">
                    {{ productName }}
                </h2>
            </div>
            <div class="s-cta-vss__right">
                <p class="s-cta-vss__text">
                    {{ title }}
                </p>
            </div>
        </div>
        <div class="s-cta-vss__info">
            <div class="s-cta-vss__left">
                <div v-if="mainPoint" class="s-cta-vss__price">
                    {{ mainPoint }}
                </div>
            </div>
            <div v-if="tools.length" class="s-cta-vss__right">
                <div v-for="tool in tools" :key="tool.button.to" class="s-cta-vss-tool">
                    <div v-if="tool.label" class="s-cta-vss__label">
                        {{ tool.label }}
                    </div>
                    <a-button
                        :key="tool.button.main"
                        class="s-cta-vss__button"
                        :class="{ 's-cta-vss__button_with-context': tool.button.context }"
                        v-bind="tool.button"
                        :is-context="Boolean(tool.button.context)"
                        :glyph="tool.button?.glyph || 'download-arrow'"
                        :type="tool.button?.type || 'action'"
                        :event="setEvent(tool.button)"
                    />
                    <div v-if="tool.version" class="s-cta-vss__version">
                        {{ tool.version }}
                    </div>
                </div>
            </div>
        </div>
    </s-basic-slice>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import ALogo from '@core/components/logo/logo.vue';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';

export default {
    name: 'SCtaVss',
    components: {
        AButton,
        ALogo,
        SBasicSlice,
    },
    props: {
        /**
         * CTA Data
         */
        productName: {
            type: String,
            required: true,
        },

        /**
         * CTA Data
         */
        title: {
            type: String,
            default: '',
        },

        /**
         * Price or any custom text
         */
        mainPoint: {
            type: String,
            default: undefined,
        },

        /**
         * Tools
         */
        tools: {
            type: Array,
            default: undefined,
            validator: (tools) => tools.length < 3,
        },
    },
    methods: {
        setEvent(item) {
            return item && item.sendEventGA ? { label: item.to, ...item.sendEventGA } : {};
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-cta-vss {
    background-image: var(--av-gradient-nav-primary);
    color: var(--av-fixed-white);

    &__description,
    &__info {
        @media (--viewport-mobile-wide) {
            display: flex;
        }
    }

    &__description {
        align-items: flex-start;

        .s-cta-vss__left {
            flex-direction: column;
            display: flex;
            align-items: center;
            text-align: center;

            @media (--viewport-mobile-wide) {
                align-items: flex-start;
                text-align: start;
                padding-inline-end: 16px;
            }
        }

        .s-cta-vss__right {
            margin-top: 48px;
            text-align: center;

            @media (--viewport-mobile-wide) {
                text-align: start;
            }
            @media (--viewport-desktop) {
                margin-top: 16px;
            }
        }
    }

    &__info {
        @media (--viewport-mobile-wide) {
            margin-top: 56px;
            align-items: flex-start;
        }

        @media (--viewport-desktop) {
            margin-top: 88px;
            align-items: flex-start;
        }
        @media (--viewport-desktop-wide) {
            margin-top: 32px;
        }

        .s-cta-vss__left {
            @media (--viewport-mobile-wide) {
                height: 48px;
                display: flex;
                align-items: center;
                margin-top: 56px;
            }
        }

        .s-cta-vss__right {
            margin-top: 40px;

            @media (--viewport-mobile-wide) {
                margin-top: 0;
            }

            @media (--viewport-desktop) {
                display: flex;
                align-items: flex-end;
            }
        }
    }

    &__left {
        @media (--viewport-mobile-wide) {
            @mixin colls 5;
            margin-inline-end: 32px;
            flex-shrink: 0;
        }

        @media (--viewport-desktop) {
            @mixin colls 4;
        }

        @media (--viewport-desktop-wide) {
            @mixin colls 5;
        }
    }

    &__right {
        @media (--viewport-tablet) {
            @mixin colls 7;
        }

        @media (--viewport-desktop) {
            @mixin colls 8;
        }

        @media (--viewport-desktop-wide) {
            @mixin colls 7;
        }
    }

    &__logo {
        height: 27px;
        width: 127px;
        @media (--viewport-tablet) {
            width: 150px;
            height: 32px;
        }
    }

    &__product-name {
        @mixin hero-subtle;
        margin-top: 8px;

        @media (--viewport-tablet) {
            margin-top: 16px;
            @mixin large-subtle;
        }

        @media (--viewport-mobile-wide) {
            text-align: start;
        }

        @media (--viewport-desktop-wide) {
            max-width: 85%;
        }
    }

    &__text {
        @mixin lead;
        text-align: start;

        @media (--viewport-mobile-wide) {
            @mixin title;
        }

        @media (--viewport-desktop) {
            @mixin display;
        }
    }

    &__price {
        margin-top: 24px;

        @mixin title;

        @media (--viewport-mobile-wide) {
            margin-top: 0;
        }

        @media (--viewport-desktop) {
            @mixin display;
        }
    }

    &-tool {
        display: flex;
        flex-direction: column;
        text-align: center;

        &:not(:last-of-type) {
            margin: 0 0 40px;

            @media (--viewport-desktop) {
                margin: 0 32px 0 0;
            }
        }

        @media (--viewport-mobile-wide) {
            @mixin colls 7;

            text-align: start;
        }

        @media (--viewport-desktop) {
            @mixin colls 4;
        }

        @media (--viewport-desktop-wide) {
            @mixin colls 5;
        }

        @media (--viewport-desktop-large) {
            @mixin colls 5;
        }
    }

    &__button {
        margin-bottom: 16px;
        align-items: center;
        justify-content: center;

        @media (--viewport-mobile-wide) {
            display: inline-flex;
            justify-content: space-between;
        }

        .a-glyph {
            display: none;

            @media (--viewport-mobile-wide) {
                display: block;
            }
        }

        &_with-context {
            display: block;

            .a-button {
                justify-content: center;

                @media (--viewport-mobile-wide) {
                    justify-content: space-between;
                }
            }
        }
    }

    &__label {
        @mixin paragraph;

        margin-bottom: 16px;
    }

    &__version {
        @mixin caption;
    }
}
</style>
