import { render, staticRenderFns } from "./number-input.vue?vue&type=template&id=d88a6878&scoped=true"
import script from "./number-input.vue?vue&type=script&lang=js"
export * from "./number-input.vue?vue&type=script&lang=js"
import style0 from "./number-input.vue?vue&type=style&index=0&id=d88a6878&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d88a6878",
  null
  
)

export default component.exports