import { SearchActionSchema, VideoObjectSchema } from '@model/seo/schemas';

export const SCHEMA_URL = 'https://schema.org';
export const MAIN_WEBSITE_NAME = 'Acronis';
export const EVENTS_WEBSITE_NAME = 'Acronis Events';
export const BLOG_WEBSITE_NAME = 'Acronis Blog';
export const SITE_ID_EVENTS = 'acronis.events';
export const MAIN_SITE_URL = 'https://www.acronis.com';
export const ROUTE_NAME_BLOG_POST = 'blog-post';
export const ROUTE_NAME_RC_ARTICLE = 'resource-center-resource';
export const ROUTE_NAME_PR_ARTICLE = 'pr-press-release';

export const getSiteUrl = (ctx: any) => `https://${ctx.$store.getters['config/$config'].domain}`;

export const getUrlWithLocale = (ctx: any): string => {
    const config = ctx.$store.getters['config/$config'];

    if (config.siteID === SITE_ID_EVENTS) {
        return getSiteUrl(ctx);
    }

    const locale = ctx.$route?.params?.locale || 'en-us';
    return `${getSiteUrl(ctx)}/${locale}`;
};

export const getBCP47Locale = (locale: string) => locale
    .split('-')
    .map((part, index) => (index ? part.toUpperCase() : part))
    .join('-');

export const formatDate = (timestamp: string, formatString = 'MMMM d, yyyy') => {
    if (!timestamp) return null;
    return format(parseISO(timestamp), formatString);
};

export const getSearchAction = (url: string): SearchActionSchema => ({
    '@type': 'SearchAction',
    target: `${url}/search/?q={query}`,
    name: 'Search Action',
    query: 'required name=query',
    '@id': `${url}/#SearchAction`,
});

export const getAudienceSchema = (url: string, name: string) => ({
    '@type': 'PeopleAudience',
    '@id': `${url}#Article_audience_PeopleAudience`,
    sameAs: 'https://www.wikidata.org/wiki/Q2472587',
    name,
});

export const getShortOrganizationSchema = () => ({
    '@type': 'Organization',
    name: 'Acronis',
    url: 'https://www.acronis.com',
});

export const getPostVideoObject = (body: any): VideoObjectSchema[] => {
    let postBody: any = [];

    try {
        postBody = JSON.parse(body);
        postBody = postBody?.blocks || [];
    } catch (error) {
        postBody = [];
    }

    return postBody.find((item) => item.type === 'embed' && item.data?.service === 'youtube');
};

export const getEventDescription = (event: { seo_description: string; description_body: string; }) => {
    let description = event.seo_description;

    if (!description) {
        try {
            const parsedBody = JSON.parse(event.description_body);
            const header = parsedBody?.blocks.find((block: { type: string; }) => block.type === 'header');
            description = header?.data.text;
        } catch (error) {
            description = '';
        }
    }

    return description;
};
