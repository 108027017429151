<template>
    <div class="s-workload-summary" :class="className">
        <div v-show="price > 0" class="s-workload-summary__item">
            <div class="s-workload-summary__title">
                {{ setTranslate('Summary') }}
            </div>
            <div class="s-workload-summary__tooltip">
                <a-tooltip :text="tooltip" />
            </div>
            <div class="s-workload-summary__editions">
                {{ setTranslate('Editions selected:') }}
                {{ quantity }}
            </div>
            <div class="s-workload-summary__price" v-html="setPrice()" />
        </div>
        <div class="s-workload-summary__compare">
            <a-button
                glyph="arrow"
                :text="setTranslate('Compare editions')"
                :event="{ doNotSendGA: true }"
                @click="comparisonToggle()"
            />
        </div>
        <div v-show="price > 0" class="s-workload-summary__button">
            <a-button
                type="action"
                glyph="shopping-cart-o"
                :text="setTranslate('Proceed to order')"
                :event="{ doNotSendGA: true }"
                @click="proceed()"
            />
        </div>
    </div>
</template>

<script>
import { sortBy } from 'lodash';
import AButton from '@core/components/button/button.vue';
import ATooltip from '@core/components/tooltip/tooltip.vue';
import mixin from '../mixin.js';

export default {
    name: 'SWorkloadSummary',

    components: {
        AButton,
        ATooltip,
    },

    mixins: [mixin],

    props: {
        /**
         * Settings
         */
        settings: {
            type: Object,
            default: null,
        },
    },

    data: () => ({
        price: 0,
    }),

    computed: {
        className() {
            return {
                's-workload-summary__active': this.price > 0,
            };
        },

        quantity() {
            return this.listWorkloads
                .map(({ editions }) => editions.filter((e) => e.added).length)
                .reduce((p, c) => p + c, 0);
        },
        tooltip() {
            return this.listWorkloads
                .map((p) => p.editions.filter((e) => e.added).map((e) => `${e.name} - ${p.name}`))
                .flat()
                .join('<br/>');
        },
    },

    methods: {
        currentPrice(edition) {
            const type = this.editionType(edition);
            let subscription = edition.subscriptions[type][0];

            if (edition.subscriptionsCustom) {
                subscription = edition.subscriptionsCustom[type][0].mailboxes[0];
            }

            const cleverBridgeCartID = subscription.cleverBridgeCartID;
            const license = this.licenseData({ cleverBridgeCartID, promo: this.settings.promo });

            return license.price;
        },

        setPrice() {
            this.price = this.listWorkloads
                .map((p) => p.editions.map((e) => (e.added ? this.currentPrice(e) : 0)))
                .reduce((p, c) => p + c.reduce((sum, price) => sum + price), 0);

            return `${this.setTranslate('From')}  ${this.setCurrency(this.price)}`;
        },

        proceed() {
            const list = this.listWorkloads.filter((p) => p.added);
            const product = sortBy(list, ['time']).slice(-1)[0];
            this.productClose('workloads');
            this.productOpen(product.id);
            this.changeStep(2);
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-workload-summary {
    height: 48px;
    display: flex;
    margin: 16px 0 14px;
    align-items: center;
    justify-content: flex-start;

    &__item {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
    }

    &__title {
        @mixin title-accent;
        white-space: nowrap;
        color: var(--av-nav-primary);
    }

    &__tooltip {
        margin-inline-start: 64px;

        &:deep(.a-glyph) {
            margin: 6px 0 0;
        }
    }

    &__editions {
        @mixin paragraph;
        margin-inline-start: 8px;
        white-space: nowrap;
        color: var(--av-fixed-secondary);
    }

    &__price {
        @mixin paragraph-accent;
        margin-inline-start: 48px;
        white-space: nowrap;
        color: var(--av-fixed-secondary);
    }

    &__compare {
        width: 100%;
        text-align: center;

        .a-button {
            @mixin body-accent;
            margin: 0;
            padding: 0;
            border: none;
            box-shadow: none;
            display: inline-block;
            color: var(--av-brand-secondary);

            &:hover {
                background: transparent;
            }
        }

        &:deep(.a-glyph) {
            margin: 0 0 2px;
            margin-inline-start: 8px;
            vertical-align: middle;
            fill: var(--av-brand-secondary);
        }
    }

    &__button {
        padding-inline-start: 32px;
    }

    &__active {
        .s-workload-summary {
            &__compare {
                text-align: end;
            }
        }
    }
}
</style>
