import { render, staticRenderFns } from "./resource-highlight-card.vue?vue&type=template&id=119785c0&scoped=true"
import script from "./resource-highlight-card.vue?vue&type=script&lang=js"
export * from "./resource-highlight-card.vue?vue&type=script&lang=js"
import style0 from "./resource-highlight-card.vue?vue&type=style&index=0&id=119785c0&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "119785c0",
  null
  
)

export default component.exports