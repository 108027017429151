<template>
    <div v-if="isAvailable" class="s-card-add-edition" :class="className">
        <div class="s-card-add-edition__wrapper">
            <a-button :text="setTranslate('Add Edition')" :event="{ doNotSendGA: true }" @click="open()" />
            <div class="s-card-add-edition__popup">
                <div class="s-card-add-edition__title">
                    {{ setTranslate('Editions') }}
                </div>
                <div
                    v-for="(edition, editionIndex) in editionAvailableList"
                    :key="`product-edition-${editionIndex}`"
                    class="s-card-add-edition__item"
                >
                    <el-checkbox v-model="editions[editionIndex].added">
                        <span class="el-checkbox" v-html="edition.name" />
                    </el-checkbox>
                </div>
                <div class="s-card-add-edition__cta">
                    <a-button :text="setTranslate('Cancel')" :event="{ doNotSendGA: true }" @click="close()" />
                    <a-button
                        type="main"
                        :text="setTranslate('Apply')"
                        :event="{ doNotSendGA: true }"
                        @click="apply()"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { get, merge, cloneDeep, isEmpty } from 'lodash';
import AButton from '@core/components/button/button.vue';
import mixin from '../mixin.js';

export default {
    name: 'SCardAddEdition',

    components: {
        AButton,
        elCheckbox: () => import('@uikit/ui-kit/packages/checkbox'),
    },

    mixins: [mixin],

    props: {
        /**
         * Settings
         */
        settings: {
            type: Object,
            default: null,
        },

        /**
         * Product
         */
        product: {
            type: Object,
            default: null,
        },
    },

    data: () => ({
        editions: [],
        isOpen: false,
    }),

    computed: {
        className() {
            return {
                's-card-add-edition__active': this.isOpen,
            };
        },

        isAvailable() {
            return this.product.editions.find((e) => !e.added && e.subscriptions);
        },

        editionAvailableList() {
            return this.editions.filter((e) => e.subscriptions);
        },
    },

    mounted() {
        this.editions = cloneDeep(this.product.editions);
    },

    methods: {
        open() {
            this.sync();
            window.addEventListener('click', this.close, true);
        },

        close(el) {
            const className = get(el, 'target.className', '');
            if (/popup|el-checkbox|a-button/.test(className)) {
                return;
            }
            this.sync();
            window.removeEventListener('click', this.close, true);
        },

        sync() {
            this.isOpen = !this.isOpen;
            merge(
                this.editions,
                this.product.editions.filter((e) => e.subscriptions),
            );
        },

        apply() {
            merge(this.product, { selected: true });
            merge(
                this.product.editions.filter((e) => e.subscriptions),
                this.editions
                    .filter((e) => e.subscriptions)
                    .map((sections, i) => ({
                        time: this.time() + i,
                        added: this.added,
                        ...sections,
                    })),
            );

            if (isEmpty(this.editions.filter((e) => e.added))) {
                this.productRemove(this.product);
            }

            this.close();
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-card-add-edition {
    position: relative;
    padding: 24px 32px 0;
    border-top: 1px solid var(--av-brand-light);

    &__wrapper {
        position: relative;

        .a-button {
            transition: none;
        }
    }

    &__popup {
        @mixin body;
        top: 100%;
        z-index: 2;
        inset-inline-start: -8px;
        display: none;
        padding: 24px 0;
        margin: 8px 0 0;
        min-width: 214px;
        position: absolute;
        border-radius: 4px;
        white-space: nowrap;
        background: var(--av-fixed-white);
        border: 1px solid var(--av-brand-primary);

        &:before {
            content: '';
            top: -4px;
            inset-inline-start: 30px;
            width: 6px;
            height: 6px;
            margin-inline-end: 16px;
            position: absolute;
            transform: rotate(45deg);
            background: var(--av-fixed-white);
            border-top: 1px solid var(--av-brand-primary);
            border-inline-start: 1px solid var(--av-brand-primary);
        }
    }

    &__title {
        margin: 0 24px 16px;
        color: var(--av-fixed-light);
    }

    &__item {
        color: #243143;
        padding: 0 24px;
        height: 24px;

        &:not(:first-child) {
            margin: 8px 0 0;
        }

        .el-checkbox {
            vertical-align: baseline;
        }
    }

    &__cta {
        margin: 24px 0 0;
        padding: 24px 24px 0;
        border-top: 1px solid var(--av-brand-accent);

        .a-button {
            padding: 0;
            height: 34px;
            font-size: 14px;
            font-weight: 400;
            line-height: 34px;
            vertical-align: middle;

            &:not(:first-child) {
                margin-inline-start: 24px;
            }

            &:deep(.a-button__content) {
                padding: 0 24px;
            }
        }
    }

    &__active {
        &:before {
            top: 0;
            inset-inline-start: 0;
            z-index: 1;
            content: '';
            width: 100%;
            height: 100%;
            cursor: default;
            position: absolute;
        }

        .s-card-add-edition {
            &__popup {
                display: block;
            }
        }
    }
}
</style>
