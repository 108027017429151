<template>
    <div class="s-purchasing-acp__navigation">
        <span
            v-for="(title, navigationIndex) in settings.steps.list"
            :key="`navigation-${navigationIndex}`"
            class="s-purchasing-acp__navigation-item"
            :class="className(navigationIndex)"
            @click="change(navigationIndex)"
        >
            <span :class="classNameItem(navigationIndex)">{{ title }}</span>
        </span>
    </div>
</template>

<script>
import mixin from './mixin.js';

export default {
    name: 'SNavigation',

    mixins: [mixin],

    props: {
        /**
         * Settings
         */
        settings: {
            type: Object,
            default: null,
        },
    },

    methods: {
        change(i) {
            if (i > 1) {
                return;
            }
            this.changeActiveSection(0);
            this.changeStep(i + 1);
        },

        className(i) {
            return { 's-purchasing-acp__navigation-item-active': this.stepActive === i + 1 };
        },

        classNameItem(i) {
            return { 's-purchasing-acp__navigation-item-link': i < 2 };
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-purchasing-acp {
    &__navigation {
        @mixin body-accent;
        color: var(--av-brand-light);
    }

    &__navigation-item {
        user-select: none;
        display: inline-block;

        &:not(:first-child) {
            margin-inline-start: 40px;
        }

        &:before {
            width: 8px;
            height: 8px;
            content: '';
            border-radius: 100%;
            margin-bottom: 1px;
            margin-inline-end: 8px;
            display: inline-block;
            vertical-align: baseline;
            background-color: var(--av-brand-light);
        }
    }

    &__navigation-item-link {
        cursor: pointer;
    }

    &__navigation-item-active {
        color: var(--av-brand-primary);

        &:first-child {
            cursor: default;
        }

        &:before {
            background-color: var(--av-brand-primary);
        }
    }
}
</style>
