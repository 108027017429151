<template>
    <div
        class="a-event-card"
        :class="{
            'a-event-card--shadow': cardShadow,
            'a-event-card--mini': miniCard,
            'a-event-card--accent': accentCard,
        }"
    >
        <div
            v-if="isLoading"
            v-loading="true"
            class="a-event-card__info-loading"
            el-loading-size="48"
        />
        <template v-else>
            <a-picture
                v-if="!miniCard"
                class="a-event-card__img"
                :link="image"
                :alt="imageAlt"
                fit="cover"
                :position="picturePosition"
            />
            <div class="a-event-card__info">
                <div v-if="label && !hasEnded" class="a-event-card__date label-wrapper">
                    <a-glyph class="a-accordion__icon" :name="label.glyph" />
                    <span>{{ label.text }}</span>
                </div>
                <div
                    v-else
                    class="a-event-card__date"
                    :class="{'a-event-card__date--ended': hasEnded}"
                >
                    <a-glyph v-if="hasEnded" class="a-accordion__icon" name="play" />
                    <a-glyph v-else class="a-accordion__icon" name="calendar" />
                    <span>{{ date }}</span>
                </div>
                <div class="a-event-card__title">
                    {{ title }}
                </div>
                <div class="a-event-card__desc">
                    {{ desc }}
                </div>
                <div
                    v-if="language || duration"
                    class="a-event-card__extra-info"
                    :class="{
                        'a-event-card__extra-info--ended': hasEnded
                    }"
                >
                    <div v-if="language">
                        <a-glyph name="quote-icon" />
                        <span>{{ language }}</span>
                    </div>
                    <div v-if="duration">
                        <a-glyph :name="`clock-${hasEnded ? 'blue' : 'green'}`" />
                        <span>{{ duration }}</span>
                    </div>
                </div>
                <div class="a-event-card__bottom">
                    <div v-if="!miniCard && speakers.length" class="a-event-card__speakers">
                        <p class="a-event-card__speakers-title">
                            {{ speakersText }}
                        </p>
                        <ul class="a-event-card__first-speakers">
                            <li
                                v-for="speaker in speakers"
                                :key="speaker.name"
                                class="a-event-card__first-speakers-item"
                            >
                                <span class="a-event-card__first-speakers-name">{{ speaker.name }},</span>
                                <span v-if="speaker.position" class="a-event-card__first-speakers-position">
                                    {{ speaker.position }}
                                </span>
                            </li>
                        </ul>
                    </div>
                    <a-link
                        v-if="miniCard"
                        v-bind="link"
                        :glyph="link.glyph || 'arrow'"
                        :text="link.text || 'Go to event'"
                        :size="link.size || 'paragraph'"
                    />
                    <a-button
                        v-else
                        class="a-event-card__link"
                        v-bind="link"
                        :type="getButtonLinkType(link)"
                        :size="link.size || 's'"
                        :glyph="link.glyph || 'arrow'"
                    />
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import Loading from '@uikit/ui-kit/packages/loading/src/directive.js';
import AButton from '@core/components/button/button.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';
import breakpoint from '@core/mixins/breakpoint.js';

export default {
    name: 'AEventCard',

    directives: { Loading },

    components: {
        AGlyph,
        AButton,
        ALink,
        APicture,
    },

    mixins: [breakpoint],

    props: {
        speakers: {
            type: Array,
            default: () => [],
        },

        speakersText: {
            type: String,
            default: undefined,
        },

        hasEnded: {
            type: Boolean,
            default: false,
        },

        date: {
            type: String,
            default: undefined,
        },

        label: {
            type: Object,
            default: undefined,
        },

        title: {
            type: String,
            required: true,
        },

        desc: {
            type: String,
            required: true,
        },

        link: {
            type: Object,
            default: () => ({}),
        },

        miniCard: {
            type: Boolean,
            default: false,
        },

        image: {
            type: String,
            default: undefined,
        },

        imageAlt: {
            type: String,
            default: undefined,
        },

        imagePosition: {
            type: String,
            // new cards are wide and focused off-center to the right
            default: '67% center',
        },

        imageMobilePosition: {
            type: String,
            default: '74% center',
        },

        cardShadow: {
            type: Boolean,
            default: false,
        },

        loading: {
            type: Boolean,
            default: false,
        },

        accentCard: {
            type: Boolean,
            default: false,
        },

        language: {
            type: String,
            default: '',
        },

        duration: {
            type: String,
            default: '',
        },
    },

    computed: {
        isLoading() {
            return !this.miniCard && this.loading;
        },

        picturePosition() {
            return this.isMobile ? this.imageMobilePosition : this.imagePosition;
        },
    },

    methods: {
        getButtonLinkType(link) {
            return this.hasEnded ? 'main' : link.type || 'action';
        },
    },
};
</script>

<style lang="postcss" scoped>
.a-event-card {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &--shadow {
        width: 100%;
        padding: 16px;
        margin-top: 18px;
        border-radius: 16px;
        border: 1px solid var(--av-brand-light);
        background: var(--av-inversed-primary);
        box-shadow: 0 4px 8px 0 var(--av-fixed-invisible);

        @media (--viewport-desktop) {
            padding: 24px;
        }

        &__info {
            border-inline-end: var(--av-border);
            border-bottom: var(--av-border);
            border-inline-start: var(--av-border);
            border-radius: 0 0 4px 4px;

            @media (--viewport-tablet) {
                border-top: var(--av-border);
                border-inline-end: var(--av-border);
                border-bottom: var(--av-border);
                border-inline-start: none;
                border-radius: 0 4px 4px 0;
            }
        }
    }

    &:not(.a-event-card--mini) {
        .a-event-card__desc {
            margin-bottom: 8px;
            line-height: 24px;
        }
    }

    &--mini:not(&--shadow) {
        .a-event-card__info {
            padding-inline-start: 0;

            @media (--viewport-tablet) {
                padding-top: 24px;
            }
        }
    }

    &--accent {
        border-radius: 16px;
        border: 1px solid var(--av-brand-light);
        background: var(--av-solid-brand-accent);
        box-shadow: 0 4px 8px 0 var(--av-fixed-invisible);
    }

    @media (--viewport-tablet) {
        flex-direction: row;
    }

    @media (--viewport-desktop-wide) {
        width: 85%;
    }

    &__info-loading {
        width: 100%;
        height: 100%;
        min-height: 212px;

        @media (--viewport-tablet) {
            min-height: 400px;
        }

        @media (--viewport-desktop) {
            min-height: 300px;
        }
    }

    &__img {
        min-height: 212px;
        max-height: 300px;
        height: auto;
        border-radius: 8px;
        overflow: hidden;
        display: flex;

        @media (--viewport-tablet) {
            display: block;
            min-height: 400px;
            max-height: none;
            min-width: 276px;
            max-width: 276px;
        }

        @media (--viewport-desktop) {
            min-height: 300px;
            min-width: 272px;
            max-width: 272px;
        }

        :deep(.a-picture__img) {
            min-width: 100%;
            min-height: 100%;
        }
    }

    &__info {
        width: 100%;

        @media (--viewport-tablet) {
            padding-inline-start: 24px;
        }
    }

    &__lang {
        @mixin body;

        margin-bottom: 8px;
        color: var(--av-brand-secondary);
    }

    &__title {
        @mixin title-accent;

        color: var(--av-nav-primary);
        margin-bottom: 8px;
    }

    &__desc {
        @mixin paragraph;

        margin-bottom: 24px;
    }

    &__extra-info {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 16px;
        margin-bottom: 24px;

        div {
            display: flex;
            align-items: center;
            gap: 8px;
        }

        svg {
            fill: var(--av-fixed-success);
            transform: translateY(1px);
        }

        span {
            @mixin descriptor;
            color: var(--av-fixed-secondary);
        }

        &--ended {
            svg {
                fill: var(--av-brand-primary);
            }
        }
    }

    &__date {
        @mixin body-accent;

        margin-bottom: 8px;
        color: var(--av-fixed-success);
        display: flex;
        align-items: center;

        .a-glyph {
            margin-top: 0;
            margin-inline-end: 11px;
            height: 52px;
            width: 18px;
            fill: var(--av-fixed-success);
        }

        &--ended {
            color: var(--av-brand-primary);

            .a-glyph {
                fill: var(--av-brand-primary);
            }
        }

        &.label-wrapper {
            fill: var(--av-brand-secondary);
            color: var(--av-brand-secondary);
        }
    }

    &__bottom {
        display: flex;
        flex-direction: column;

        @media (--viewport-tablet) {
            align-items: flex-start;
        }

        @media (--viewport-desktop) {
            flex-direction: row;
            align-items: flex-end;
            justify-content: space-between;
        }
    }

    &__speakers {
        margin-bottom: 32px;

        @media (--viewport-desktop) {
            margin-bottom: 0;
            margin-inline-end: 24px;
        }
    }

    &__speakers-title {
        @mixin paragraph-accent;

        color: #00204d;
        margin-bottom: 8px;
    }

    &__first-speakers-name {
        @mixin body;
        color: var(--av-fixed-primary);

        @media (--viewport-tablet) {
            margin-inline-end: 4px;
        }
    }

    &__speakers-comma {
        display: none;
    }

    &__first-speakers-position {
        @mixin body;

        color: var(--av-fixed-secondary);
    }

    &__link {
        width: 100%;
        justify-content: center;
        margin-top: 0;
        margin-inline-start: auto;

        svg {
            display: none;
        }

        @media (--viewport-tablet) {
            width: 240px;
            justify-content: space-between;

            svg {
                display: block;
            }
        }
    }

    &:deep(.a-slider__nav) {
        margin-top: 24px;
        margin-bottom: 16px;
    }
}
</style>
