<template>
    <div :class="['list-wrapper', `list-wrapper-${settings.mode}`]">
        <div v-for="(list, listIndex) in addonList" :key="`list-${listIndex}`" class="list">
            <p v-if="settings.mode === 'products'" class="list-title">
                {{ list.name }}
            </p>
            <div
                v-for="(addon, productIndex) in list.products"
                :key="`addon-${productIndex}`"
                :class="['addon', { 'addon-opened': addon.isOpened }]"
            >
                <div v-if="addon.title" class="addon-header">
                    <div class="addon-title">
                        <div>{{ addon.title }}</div>
                        <a-label v-if="addon.label" v-bind="addon.label">
                            {{ addon.label.text }}
                        </a-label>
                    </div>
                    <a-button
                        size="s"
                        type="main"
                        :glyph="addon.isOpened ? 'close' : 'plus'"
                        :text="translation(addon.isOpened ? 'Remove' : 'Add')"
                        :event="{ doNotSendGA: true }"
                        @click="handleClick(addon)"
                    />
                </div>
                <div v-for="(item, itemIndex) in addon.content" :key="`addon-item-${itemIndex}`">
                    <p v-if="item.description" class="addon-description">
                        {{ item.description }}
                    </p>

                    <template v-if="addon.isOpened">
                        <div v-if="item.dropdowns" :class="dropdownClass(item)">
                            <s-dropdown
                                v-for="(dropdown, dropdownIndex) in item.dropdowns"
                                :key="`dropdown-${dropdownIndex}`"
                                :description="dropdown.title"
                                :list="optionList(addon, dropdown)"
                                :placeholder="optionPlaceholder(addon, dropdown)"
                                @changeItem="$emit('changeAddon', $event)"
                            />
                        </div>

                        <div v-else-if="item.counter" class="counter">
                            <div class="counter-quantity">
                                <div>{{ translation('Quantity') }}</div>
                                <s-num-picker
                                    :value-min="1"
                                    :value-max="5"
                                    :value="valueOnboardingService"
                                    @changeQuantity="$emit('changeQuantity', { ...addon, quantity: $event })"
                                />
                            </div>
                            <div class="counter-period">
                                <div>{{ translation('Service period') }}</div>
                                <div class="counter-price" v-html="titleService(addon, item.counter)" />
                            </div>
                        </div>

                        <div v-else-if="item.features && addonFeature(item)" class="addon-features">
                            <p class="feature-title">
                                {{ addonFeatureTitle(item) }}
                            </p>
                            <div :class="['features', `features-${addon.product}`, { 'features-short': item.isShort }]">
                                <div
                                    v-for="(feature, featureIndex) in addonFeatureList(item)"
                                    :key="`feature-${featureIndex}`"
                                    class="feature-item"
                                >
                                    <a-glyph name="check-square" />
                                    <span v-html="feature" />
                                </div>
                            </div>
                        </div>
                        <div v-else-if="item.notifications" class="addon-notifications">
                            <div
                                v-for="(notification, notificationIndex) in item.notifications"
                                :key="`notification-${notificationIndex}`"
                                class="notification"
                            >
                                <a-glyph name="info-circle-o" />
                                <span v-html="notification" />
                            </div>
                        </div>
                        <div v-else-if="item.extension" class="addon-extension">
                            <p class="addon-extension-title">
                                <el-checkbox
                                    :value="valueExtension"
                                    @change="$emit('changeQuantity', { ...addon, quantity: Number($event) })"
                                >
                                    <span v-html="titleExtension(addon, item.extension)" />
                                </el-checkbox>
                            </p>
                            <p class="addon-extension-description" v-html="item.extension.description || ''" />
                        </div>
                        <div v-else-if="item.links" class="addon-links">
                            <div v-for="(link, linkIndex) in item.links" :key="`link-${linkIndex}`" class="link">
                                <a-link
                                    v-bind="link"
                                    :size="link.size || 'paragraph'"
                                />
                            </div>
                        </div>
                    </template>
                </div>
                <div v-if="isVisiblePrice(addon)" :class="['price-container', {'price-container-opened': addon.isOpened}]">
                    <template v-if="addon.isOpened">
                        <div
                            v-if="addon.fromPerYearFull"
                            class="promo"
                            v-html="addonPriceCurrency(addon)"
                        />
                        <p class="price-current" v-html="addonPriceCurrency({ ...addon, type: 'full' })" />
                    </template>
                    <template v-else>
                        <div
                            v-if="addon.fromPerYearFull"
                            class="promo"
                            v-html="addonPriceCurrency({ ...addon, type: 'full', isPerYear: true, hasPromoText: true })"
                        />
                        <p class="price-current" v-html="addonPriceCurrency(addon)" />
                    </template>
                </div>
            </div>
        </div>

        <div v-if="notifications.length" class="card-notifications">
            <div
                v-for="(notification, notificationIndex) in notifications"
                :key="`notification-${notificationIndex}`"
                class="notification"
            >
                <a-glyph :name="notification.glyph || 'info-circle-o'" />
                <div v-html="notification.text || ''" />
            </div>
        </div>
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALabel from '@core/components/label/label.vue';
import ALink from '@core/components/link/link.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import currency from '@core/mixins/currency.js';
import mixin from '../mixin.js';
import SDropdown from './dropdown.vue';
import SNumPicker from './num-picker.vue';

export default {
    name: 'SAddonList',

    components: {
        ALink,
        AButton,
        AGlyph,
        ALabel,
        SDropdown,
        SNumPicker,
        elCheckbox: () => import('@uikit/ui-kit/packages/checkbox'),
    },

    mixins: [breakpoint, mixin, currency],

    props: {
        settings: {
            type: Object,
            default: null,
        },
    },

    emits: ['changeAddon', 'toggleProduct', 'changeQuantity'],

    computed: {
        addonList() {
            const types = {
                addon: 'Extend your order',
                services: 'Extend your order with Acronis services',
            };
            return ['addon', 'services'].map((type) => {
                const comparison = (item) => (item.type === type && (this.hasMicrosoft365ID ? item.product !== 'cloud-storage' : item));
                const products = this.settings.products.filter(comparison);
                return products.length ? { type, products, name: this.translation(types[type]) } : {};
            });
        },

        valueOnboardingService() {
            return this.settings.licenses.find((item) => item.product === 'onboarding-service').quantity;
        },

        notifications() {
            return this.dictionary?.notifications || [];
        },
    },

    methods: {
        isVisiblePrice(license) {
            return license.from;
        },

        dropdownClass(item) {
            const quantity = item.dropdowns.length;
            return {
                'addon-dropdown': quantity,
                [`is-${this.settings.mode}`]: quantity,
                [`addon-dropdown-${quantity}`]: quantity > 1,
            };
        },

        addonFeature(list) {
            const features = list.features[0];
            if (!features.size) return features;
            const comparison = (item) => item.product === 'disaster-recovery' && item.quantity;
            const selected = this.settings.licenses.find(comparison);
            const size = selected?.size || 'small';
            return list.features.find((item) => item.size === size);
        },

        addonFeatureTitle(item) {
            return this.addonFeature(item).title || {};
        },

        addonFeatureList(item) {
            return this.addonFeature(item).list || [];
        },

        addonPrice(addon) {
            if (!addon.isOpened) {
                const price = addon.fromPerYear;
                const priceFull = addon.fromPerYearFull || '';
                const percent = Math.round(100 - (100 * price) / priceFull);
                return {
                    price: addon.type === 'full' ? priceFull : price,
                    promoText: this.translation('Save @percent').replace('@percent', `${percent}%`),
                };
            }
            const comparison = (item) => item.product === addon.product && item.quantity;
            const current = this.settings.licenses.find(comparison);
            const quantity = current?.quantity || 1;
            const period = current?.period || 1;
            const price = current?.store?.price || 0;
            const discount = current?.store?.discount_absolute || 0;
            const result = addon.type === 'full' ? price : (price + discount);
            return { price: ((result * quantity) / period) };
        },

        addonPriceCurrency(addon) {
            const text = this.translation(addon.isOpened || addon.isPerYear ? '@price per year' : 'From @price per year');
            const result = this.addonPrice(addon);
            if (addon.isOneTimePurchase) return this.setCurrency(result.price);
            const price = `<span class="price">${text.replace('@price', this.setCurrency(result.price))}</span>`;
            const promoText = addon.hasPromoText && result.promoText ? `<span class="text">${result.promoText}</span>` : '';
            return `${price}${promoText}`;
        },

        titleExtension(addon, extension) {
            const text = extension.title || '';
            const price = this.settings.licenses.find((item) => item.isExtension).store.price;
            return text.replace('@price', this.setCurrency(price));
        },

        titleService(addon, item) {
            const text = item.price || '';
            const result = this.addonPrice(addon);
            return text.replace('@price', this.setCurrency(result.price));
        },

        handleClick(addon) {
            this.extension = false;
            this.$emit('toggleProduct', addon);
        },
    },
};
</script>

<style lang="postcss" scoped>
.list-title {
    @mixin nav-title-accent;
    color: var(--av-nav-primary);
}

.addon {
    padding: 16px;
    border-radius: 8px;
    background: #f5f9fe;
    border: 1px solid var(--av-brand-light);
}

.addon-opened {
    background: var(--av-fixed-white);
}

.addon-header {
    @mixin lead-accent;
    gap: 16px;
    display: flex;
    align-items: center;
    color: var(--av-nav-primary);
    justify-content: space-between;

    .a-button {
        @mixin paragraph-accent;
        max-height: 32px;
        min-width: 100px;
    }
}

.addon-title {
    @mixin lead-accent;
    gap: 8px;
    display: flex;
    z-index:  1;
}

.addon-description {
    @mixin body;
    margin-top: 16px;
    text-align: start;
    color: var(--av-fixed-primary);
}

.addon-dropdown {
    margin-top: 24px;
}

.addon-dropdown-2 {
    gap: 0 24px;
    display: flex;
}

.counter {
    @mixin body;
    color: var(--av-fixed-secondary);
    display: flex;
    margin: 24px 0 0;
    text-align: center;
    align-items: center;
    flex-direction: column;

    @media (--viewport-tablet) {
        gap: 0 16px;
        flex-direction: row;
        text-align: start;
        align-items: flex-start;
    }

    &:deep(b) {
        font-weight: 700;
    }
}

.counter-quantity {
    &:deep(.el-num-picker__container) {
        max-width: 140px;

        @media (--viewport-tablet) {
            max-width: 112px;
        }
    }
}

.counter-period {
    margin: 16px 0 0;

    @media (--viewport-tablet) {
        margin: 0;

        .counter-price {
            margin: 8px 0 0;
        }
    }
}

.feature-title {
    @mixin body-accent;
    margin-top: 16px;
    color: var(--text-fixed-secondary);
}

.feature-item {
    @mixin body;
    margin-top: 8px;
    position: relative;
    padding-inline-start: 16px;
    color: var(--text-fixed-secondary);

    .a-glyph {
        top: 4px;
        width: 16px;
        height: 16px;
        position: absolute;
        inset-inline-start: 0;
        fill: var(--av-brand-primary);
    }
}

.features-short {
    display: flex;
    flex-wrap: wrap;
    margin-top: 8px;

    .feature-item {
        width: 50%;
        margin-top: 8px;

        &:nth-child(odd) {
            padding-inline-end: 16px;
        }

        @media (--viewport-tablet) {
            width: 33.33%;
            padding: 0 16px;

            &:nth-child(3n + 3) {
                padding-inline-end: 0;
            }
        }
    }
}

.features-disaster-recovery {
    .feature-item {
        @mixin caption;

        &:deep(b) {
            @mixin body-accent;
            display: block;
            color: var(--text-fixed-secondary);
        }
    }
}

.notification {
    @mixin body;
    display: flex;
    padding: 8px;
    margin-top: 16px;
    border-radius: 4px;
    padding-inline-end: 16px;
    background: var(--av-brand-accent);

    &:deep(.a-link) {
        display: inline;
        white-space: nowrap;
    }

    .a-glyph {
        width: 16px;
        height: 16px;
        min-width: 16px;
        margin-top: 4px;
        margin-inline-end: 8px;
        fill: var(--av-brand-primary);
    }
}

.price-container {
    margin: 16px 0 0;
    text-align: start;

    .price-current {
        @mixin lead-accent;
        color: var(--av-nav-primary);
    }

    .promo {
        @mixin paragraph;

        &:deep(.price) {
            @mixin paragraph;
            font-weight: 400;
            color: var(--av-fixed-lighter);
            text-decoration: line-through;
        }

        &:deep(.text) {
            @mixin paragraph;
            font-weight: 700;
            margin-inline-start: 8px;
            color: var(--av-button-action);
        }
    }
}

.price-container-opened {
    padding: 24px 0 0;
    text-align: center;
    margin: 32px -16px 0;
    border-top: 2px solid var(--av-brand-secondary-light);

    .promo {
        &:deep(.price) {
            @mixin body;
            display: block;
            font-weight: 400;
            margin-top: -16px;
        }

    }

    .price-current {
        color: var(--av-brand-primary);
    }
}

.addon-extension {
    @mixin body;
    margin-top: 16px;
    color: var(--av-fixed-secondary);
}

.addon-extension-title {
    display: flex;
    align-items: center;

    .el-checkbox {
        margin-inline-end: 8px;
    }
}

.addon-links {
    text-align: center;
    margin-top: 40px;

    @media (--viewport-tablet) {
        display: flex;
        margin-top: 16px;
        justify-content: space-between;
    }

    .link {
        @mixin lead-accent;
        color: var(--layout-brand-primary);

        &:not(:first-child) {
            margin-top: 16px;

            @media (--viewport-tablet) {
                margin-top: 0;
            }
        }
    }

    &:deep(.a-link__content) {
        span {
            margin: 0;
        }

        .a-glyph {
            margin: 0 0 2px;
            vertical-align: middle;
        }
    }
}

.list-wrapper-products {
    padding: 40px 16px 142px;
    background: var(--av-fixed-white);

    @media (--viewport-tablet) {
        padding: 24px 40px 84px;
    }

    .list-wrapper {
        background: var(--av-fixed-white);
    }

    .list {
        &:not(:first-child) {
            margin: 24px 0 0;
        }
    }

    .addon {
        margin-top: 16px;
    }
}

.list-wrapper-addons {
    .list {
        &:not(:first-child) {
            margin: 16px 0 0;
        }
    }

    .addon {
        padding: 16px 16px 24px;

        @media (--viewport-tablet) {
            width: 464px;
        }

        @media (--viewport-desktop) {
            width: 552px;
        }

        @media (--viewport-desktop-wide) {
            width: 568px;
        }

        &:not(:first-child) {
            margin: 16px 0 0;
        }
    }

    .addon-dropdown-2 {
        flex-wrap: wrap;

        @media (--viewport-tablet) {
            gap: 0 16px;
            flex-wrap: nowrap;
        }
    }

    .dropdown {
        &:not(:first-child) {
            margin: 16px 0 0;

            @media (--viewport-tablet) {
                margin: 0;
            }
        }
    }
}

.card-notifications {
    @mixin body;
    margin: 32px 0 0;
    color: var(--av-fixed-primary);
    background: var(--av-fixed-white);

    .notification {
        padding-inline-end: 16px;

        &:not(:first-child) {
            margin: 16px 0 0;
        }

        .a-glyph {
            width: 16px;
            height: 16px;
            min-width: 16px;
            margin-top: 4px;
            margin-inline-end: 8px;
        }
    }
}
</style>
