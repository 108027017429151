<template>
    <div class="additional-wrapper" :class="{ included }">
        <h6 class="title">
            {{ title }}
        </h6>
        <p class="description">
            {{ description }}
        </p>
        <a-link
            v-if="!included"
            class="link"
            :text="labels.add"
            :has-no-ref="true"
            size="body"
            @click="toggle()"
        />
        <div v-else class="included">
            <a-glyph class="added-icon" :name="labels.addedIcon" />
            <span class="label">{{ labels.added }}</span>
            <a-link
                class="remove"
                :text="labels.remove"
                :has-no-ref="true"
                size="body"
                @click="toggle()"
            />
        </div>
    </div>
</template>

<script>
import AGlyph from '@core/components/glyph/glyph.vue';
import ALink from '@core/components/link/link.vue';

export default {
    name: 'AAdditionalInput',
    components: {
        ALink,
        AGlyph,
    },
    props: {
        labels: {
            type: Object,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: false,
            default: null,
        },
    },
    emits: ['change'],
    data() {
        return {
            included: false,
        };
    },
    methods: {
        toggle() {
            this.included = !this.included;
            this.$emit('change', this.included);
        },
    },
};
</script>

<style lang="postcss" scoped>
.additional-wrapper {
    width: 100%;
    user-select: none;
    .title {
        @mixin paragraph-accent;
        margin-bottom: 8px;
        color: var(--av-nav-primary);
    }
    .description {
        @mixin body;
        color: var(--av-fixed-secondary);
        margin-bottom: 16px;
    }
    .link {
        &:deep(.a-link__content) {
            font-weight: 700;
        }
    }
    .included {
        display: flex;
        align-items: center;
        .added-icon {
            fill: var(--av-brand-primary);
            margin-inline-end: 8px;
        }
        .label {
            @mixin body-accent;
            margin-inline-end: 16px;
        }
        .remove {
            &:deep(.a-link__content) {
                font-weight: 700;
            }
        }
    }
}
</style>
