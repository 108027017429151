<template>
    <div class="blog-featured-post" :class="className">
        <div class="main">
            <blog-card class="main-card" v-bind="{ ...post, layout: cardLayout, theme }" />
            <div
                v-if="isLoading"
                v-loading="true"
                class="loading"
                el-loading-size="48"
            />
        </div>
        <div v-if="hasMenu" class="menu">
            <slot name="menu" />
        </div>
    </div>
</template>

<script>
import Loading from '@uikit/ui-kit/packages/loading/src/directive';
import BlogCard from '../shared-components/blog-card/blog-card.vue';

export default {
    name: 'BlogFeaturedPost',
    components: {
        BlogCard,
    },
    directives: {
        Loading,
    },
    props: {
        post: {
            type: Object,
            required: true,
        },

        isLoading: {
            type: Boolean,
            default: false,
        },

        theme: {
            type: String,
            default: 'light',
        },
    },
    computed: {
        hasMenu() {
            return Boolean(this.$slots.menu);
        },

        className() {
            return {
                dark: this.theme === 'dark',
                'with-menu': Boolean(this.$slots.menu),
            };
        },

        cardLayout() {
            return this.hasMenu ? 'main' : 'main-wide';
        },
    },
};
</script>

<style lang="postcss" scoped>
.blog-featured-post {
    .main {
        position: relative;
    }

    .loading {
        position: absolute;
        inset-inline-start: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.5);
    }

    &.with-menu {
        @media (--viewport-tablet) {
            display: grid;
            column-gap: 16px;
            grid-template-columns: 1fr 1fr 1fr;
        }

        @media (--viewport-desktop) {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }

        .main {
            @media (--viewport-tablet) {
                grid-column-start: 1;
                grid-column-end: 3;
            }

            @media (--viewport-desktop) {
                grid-column-end: 4;
            }
        }
    }

    &.dark {
        color: var(--av-fixed-white);

        .loading {
            background: rgba(4, 28, 62, 0.5);
        }

        .menu-item {
            margin-bottom: 12px;

            &_active {
                color: var(--av-fixed-white);

                &::before {
                    inset-inline-start: -10px;
                }
            }

            &:hover {
                color: var(--av-fixed-white);
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
</style>

<style lang="postcss">
.blog-featured-post {
    .image {
        @media (--viewport-desktop) {
            margin-bottom: 0;
        }
    }
}
</style>
