import { render, staticRenderFns } from "./form-support-confirmation.vue?vue&type=template&id=57992eea&scoped=true"
import script from "./form-support-confirmation.vue?vue&type=script&lang=js"
export * from "./form-support-confirmation.vue?vue&type=script&lang=js"
import style0 from "./form-support-confirmation.vue?vue&type=style&index=0&id=57992eea&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57992eea",
  null
  
)

export default component.exports