<template>
    <s-basic-slice class="s-feature-large-with-files" :class="className" v-bind="$attrs">
        <div class="s-feature-large-with-files__wrapper">
            <div class="s-feature-large-with-files__list">
                <div v-for="(card, i) in cards" :key="i" class="s-feature-large-with-files__item">
                    <a-feature-card
                        v-bind="card"
                        :title-tag="$attrs.title && 'h3'"
                        size="s"
                        icon-small
                    />
                </div>
                <div class="s-feature-large-with-files__link-container s-feature-large-with-files__link-container_desktop">
                    <a-link
                        v-if="link"
                        class="s-feature-large-with-files__link"
                        v-bind="link"
                        :type="link.type || 'direct'"
                        :glyph="link.glyph || 'arrow'"
                    />
                </div>
            </div>
            <ul class="s-feature-large-with-files__download-files-list">
                <li
                    v-for="downloadFile in downloadFileArr"
                    :key="downloadFile.link"
                    class="s-feature-large-with-files__download-files-item"
                >
                    <a-link
                        class="s-feature-large-with-files__download-file"
                        v-bind="downloadFile"
                        :to="downloadFile.link"
                        :type="downloadFile.type || 'download-file'"
                        :glyph="downloadFile.glyph || 'download-arrow'"
                        :glyph-position="downloadFile.glyphPosition || 'left'"
                        :text="downloadFile.title"
                        :desc="downloadFile.desc"
                    />
                </li>
            </ul>
            <div class="s-feature-large-with-files__link-container s-feature-large-with-files__link-container_mobile">
                <a-link
                    v-if="link"
                    class="s-feature-large-with-files__link"
                    v-bind="link"
                    :type="link.type || 'direct'"
                    :glyph="link.glyph || 'arrow'"
                />
            </div>
        </div>
    </s-basic-slice>
</template>

<script>
import AFeatureCard from '@core/components/feature-card/feature-card.vue';
import ALink from '@core/components/link/link.vue';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';

export default {
    name: 'SFeaturesLargeWithFiles',

    components: {
        SBasicSlice,
        AFeatureCard,
        ALink,
    },

    inheritAttrs: false,

    props: {
        cards: {
            type: Array,
            required: true,
            validator: (cards) => cards.length === 3,
        },

        /**
         * Learn more link
         */
        link: {
            type: Object,
            required: true,
        },

        /**
         * Download a links array
         */
        downloadFileArr: {
            type: Array,
            required: true,
            validator: (downloadFileArr) => downloadFileArr.length === 3,
        },
    },

    computed: {
        className() {
            return {
                's-feature-large-with-files_theme_inversed': this.$attrs.background,
            };
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-feature-large-with-files {
    &_theme {
        &_inversed {
            .s-feature-large-with-files {
                &__download-files-list {
                    background-color: var(--av-fixed-white);
                }
            }
        }
    }

    &:deep(.a-container) {
        display: flex;
        flex-direction: column;
    }

    &:deep(.a-slice-header) {
        @media (--viewport-desktop-wide) {
            max-width: 66.66%;
        }
    }

    &:deep(.a-feature-card) {
        &__image {
            @media (--viewport-mobile-wide) {
                width: 64px;
                height: 64px;
            }
        }

        &__label {
            @media (--viewport-mobile-wide) {
                top: 64px;
            }
        }
    }

    &__wrapper {
        @media (--viewport-desktop-wide) {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
        }
    }

    &__list {
        order: 1;
        margin-bottom: 8px;
        display: flex;
        flex-direction: column;

        @media (--viewport-mobile-wide) {
            margin-bottom: 48px;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: space-between;
        }

        @media (--viewport-desktop-wide) {
            width: calc(75% + 8px);
        }
    }

    &__item {
        &:not(:last-of-type) {
            margin-bottom: 40px;

            @media (--viewport-mobile-wide) {
                margin-bottom: 0;
            }
        }

        &:nth-child(1),
        &:nth-child(2) {
            @media (--viewport-mobile-wide) {
                margin-bottom: 40px;
            }
        }

        @media (--viewport-mobile-wide) {
            width: calc(50% - 8px);
            padding-inline-end: 16px;
        }

        @media (--viewport-desktop-wide) {
            width: 33.33%;
            padding-inline-end: 32px;
        }
    }

    &__link-container {
        order: 3;

        @media (--viewport-mobile-wide) {
            order: 2;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            width: 50%;
        }

        @media (--viewport-desktop-wide) {
            margin-top: 8px;
            width: auto;
        }

        &_desktop {
            display: none;

            @media (--viewport-mobile-wide) {
                display: flex;
            }
        }

        &_mobile {
            @media (--viewport-mobile-wide) {
                display: none;
            }
        }
    }

    &__download-files-list {
        order: 2;
        margin-bottom: 56px;
        padding: 24px 16px;
        background-color: var(--av-brand-lightest);

        @media (--viewport-mobile-wide) {
            order: 3;
            margin-bottom: 0;
            display: flex;
            justify-content: space-between;
            padding: 32px 16px;
        }

        @media (--viewport-desktop) {
            padding: 32px 24px;
        }

        @media (--viewport-desktop-wide) {
            display: block;
            width: calc(25% - 8px);
            padding: 24px;
        }
    }

    &__download-files-item {
        @media (--viewport-mobile-wide) {
            width: 33%;
        }

        @media (--viewport-desktop-wide) {
            width: 100%;
        }

        &:not(:last-of-type) {
            margin-bottom: 32px;

            @media (--viewport-mobile-wide) {
                margin-bottom: 0;
                margin-inline-end: 24px;
            }

            @media (--viewport-desktop) {
                margin-inline-end: 56px;
            }

            @media (--viewport-desktop-wide) {
                margin-bottom: 24px;
            }
        }
    }
}
</style>
