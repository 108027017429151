<template>
    <div class="awards-page-wrapper">
        <div v-if="!header.hideTopBar" class="top-bar" />
        <header class="main-header">
            <a-picture
                v-if="header.background"
                :background="header.background"
                is-background
                fit="cover"
                class="background"
            />
            <div class="a-container">
                <h1 v-if="header.title" class="title" v-html="header.title" />
                <div class="cards">
                    <div
                        v-for="(card, index) in header.cards"
                        :key="`header_cards_${index}`"
                        class="card"
                    >
                        <a-picture class="image" :link="card.image" />
                        <div class="content">
                            <div class="number">
                                {{ card.number }}
                            </div>
                            <div class="desc">
                                {{ card.desc }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <section class="filters">
            <div class="a-container">
                <span v-if="labels.filters.title" class="title">{{ labels.filters.title }}</span>
                <div class="selections">
                    <el-select
                        v-model="filters.type"
                        :label="labels.filters.typeFilter"
                        :hide-on-resize="isDesktop"
                        @change="handleFilter()"
                    >
                        <el-option :value="null" :label="labels.filters.allLabel || 'All'" />
                        <el-option v-if="hasCertificates" value="certificates" :label="labels.certificatesLabel" />
                        <el-option v-if="hasRecognitions" value="recognitions" :label="labels.recognitionsLabel" />
                        <el-option value="company" :label="labels.companyLabel" />
                        <el-option value="employee" :label="labels.employeeLabel" />
                        <el-option
                            v-for="(type, i) in typeFilter"
                            :key="`type_filter_${i}`"
                            :value="type.id"
                            :label="`Acronis ${type.name}`"
                        />
                    </el-select>
                    <el-select
                        v-model="filters.awarder"
                        :label="labels.filters.awarderFilter"
                        :hide-on-resize="isDesktop"
                        @change="handleFilter()"
                    >
                        <el-option :value="null" :label="labels.filters.anyLabel || 'Any'" />
                        <el-option
                            v-for="(awarder, i) in awarderFilter"
                            :key="`awarder_filter_${i}`"
                            :value="awarder"
                            :label="awarder"
                        />
                        <el-option value="Other" :label="labels.filters.otherLabel || 'Other'" />
                    </el-select>
                    <el-select
                        v-model="filters.year"
                        :label="labels.filters.yearFilter"
                        :hide-on-resize="isDesktop"
                        @change="handleFilter()"
                    >
                        <el-option :value="null" :label="labels.filters.anyLabel || 'Any'" />
                        <el-option
                            v-for="(year, i) in yearFilter"
                            :key="`year_filter_${i}`"
                            :value="year"
                            :label="year"
                        />
                    </el-select>
                </div>
            </div>
        </section>
        <div v-if="!isFiltering" class="items-wrapper">
            <section class="featured">
                <div class="a-container">
                    <h2 class="title">
                        {{ labels.featuredTitle }}
                    </h2>
                    <div class="grid-container">
                        <div
                            v-for="(award, i) in featuredAwards"
                            :key="`featured_awards_${i}`"
                            class="card"
                            :class="[{ linked: award.link?.to }, `weight_${i}`]"
                            @click="openLink($event, award.link)"
                        >
                            <div class="image">
                                <a-picture :link="award.image" :alt="award.name" />
                            </div>
                            <div class="content">
                                <div v-if="award.type" class="product">
                                    {{ getProductName(award.type) }}
                                </div>
                                <div v-if="award.title" class="title">
                                    {{ award.title }}
                                </div>
                                <div
                                    v-if="award.description && isHighlight(i)"
                                    class="description"
                                >
                                    {{ award.description }}
                                </div>
                                <div class="info">
                                    <div
                                        v-if="award.country && isHighlight(i)"
                                        class="country"
                                    >
                                        <strong>{{ labels.countryOfIssue }}</strong>
                                        {{ award.country }}
                                    </div>
                                    <a-link
                                        class="link"
                                        v-bind="award.link"
                                        :size="award.link.size || 'paragraph'"
                                        accent
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="recent">
                <div class="a-container">
                    <h3 class="title">
                        {{ labels.recentTitle }}
                    </h3>
                    <div class="card-list">
                        <div
                            v-for="(award, i) in recentAwards"
                            :key="`recent_awards_${i}`"
                            class="card"
                            :class="{ linked: award.link?.to }"
                            @click="openLink($event, award.link)"
                        >
                            <div class="image">
                                <a-picture :link="award.image" :alt="award.name" />
                            </div>
                            <div class="content">
                                <div v-if="award.type" class="product">
                                    {{ getProductName(award.type) }}
                                </div>
                                <div v-if="award.title" class="title">
                                    {{ award.title }}
                                </div>
                                <div class="info">
                                    <a-link
                                        class="link"
                                        v-bind="award.link"
                                        :size="award.link.size || 'paragraph'"
                                        accent
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <a-button
                        v-if="showLoadMore"
                        class="load-more"
                        :text="labels.loadMoreTitle"
                        glyph="chevron-down"
                        @click="currentPage++"
                    />
                </div>
            </section>
        </div>
        <div v-else class="filtered-items-wrapper">
            <section class="filtered">
                <div class="a-container">
                    <div class="results">
                        <div class="head">
                            <p class="title">
                                {{ labels.filters.resultsTitle }}
                                <span>({{ filteredResults.length }})</span>
                            </p>
                            <a-link
                                v-bind="labels.filters.resetFilters"
                                size="body"
                                @click="resetFilters"
                            />
                        </div>
                        <div v-if="filteredResults.length" class="wrapper">
                            <div class="card-list">
                                <div
                                    v-for="(award, i) in filteredResultsFormatted"
                                    :key="`filtered_awards_${i}`"
                                    class="card"
                                    :class="{ linked: award.link?.to }"
                                    @click="openLink($event, award.link)"
                                >
                                    <div class="image">
                                        <a-picture :link="award.image" :alt="award.name" />
                                    </div>
                                    <div class="content">
                                        <div v-if="award.type" class="product">
                                            {{ getProductName(award.type) }}
                                        </div>
                                        <div v-if="award.title" class="title">
                                            {{ award.title }}
                                        </div>
                                        <div class="info">
                                            <a-link
                                                class="link"
                                                v-bind="award.link"
                                                :size="award.link.size || 'paragraph'"
                                                accent
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a-button
                                v-if="showFilteredLoadMore"
                                class="load-more"
                                :text="labels.loadMoreTitle"
                                glyph="chevron-down"
                                @click="filteredCurrentPage++"
                            />
                        </div>
                        <div v-else>
                            <span class="title">{{ labels.filters.noResults }}</span>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import { parse } from 'date-fns';
import { flow, uniq } from 'lodash';
import AButton from '@core/components/button/button.vue';
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';
import breakpoint from '@core/mixins/breakpoint.js';

export default {
    name: 'SAwardsPage',
    components: {
        ALink,
        APicture,
        AButton,
        ElSelect: () => import('@uikit/ui-kit/packages/select'),
        ElOption: () => import('@uikit/ui-kit/packages/option'),
    },
    mixins: [breakpoint],
    props: {
        header: {
            type: Object,
            default: null,
        },
        labels: {
            type: Object,
            default: null,
        },
        loadMoreLimit: {
            type: Number,
            default: 15,
        },
        items: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    data() {
        return {
            currentPage: 1,
            filteredCurrentPage: 1,
            filters: {
                type: null,
                awarder: null,
                year: null,
            },
            filteredResults: [],
            hasCertificates: false,
            hasRecognitions: false,
        };
    },
    computed: {
        awards() {
            return this.items.filter((el) => el.date && el.date.length && !el.is_featured);
        },
        featuredAwards() {
            return this.items
                .filter((el) => el.is_featured)
                .sort((a, b) => a.weight - b.weight)
                .slice(0, 4);
        },
        recentAwards() {
            const awards = this.awards;
            const recentAwards = awards.sort(this.dateComparator);

            return recentAwards.slice(0, this.loadMoreLimit * this.currentPage);
        },
        filteredResultsFormatted() {
            const results = this.filteredResults;
            const filteredResults = results.sort(this.dateComparator);

            return filteredResults.slice(0, this.loadMoreLimit * this.filteredCurrentPage);
        },
        products() {
            return Object.values(this.$store.state?.products?.items || []).filter((product) => !product.is_virtual);
        },
        typeFilter() {
            const types = this.items.map((el) => el.type);
            return this.products.filter((p) => types.includes(p.id));
        },
        yearFilter() {
            const years = this.items.map((el) => this.getDateParts(el.date).year);
            return uniq(years.sort((a, b) => b - a));
        },
        awarderFilter() {
            const awarders = this.items
                .filter((el) => el.awarder.toLowerCase() !== 'other')
                .map((el) => el.awarder);

            return uniq(awarders.sort(Intl.Collator().compare));
        },
        showLoadMore() {
            return (this.loadMoreLimit * this.currentPage) < this.awards.length;
        },
        showFilteredLoadMore() {
            return (this.loadMoreLimit * this.filteredCurrentPage) < this.filteredResults.length;
        },
        isFiltering() {
            return Object.keys(this.filters).some((key) => this.filters[key] !== null);
        },
    },
    watch: {
        isFiltering(isFiltering) {
            if (!isFiltering) {
                this.filteredCurrentPage = 1;
            }
        },
    },
    created() {
        this.hasCertificates = this.items.filter((item) => item.type === 'certificates').length > 0;
        this.hasRecognitions = this.items.filter((item) => item.type === 'recognitions').length > 0;
    },
    mounted() {
        const query = this.$route.query || {};
        const isSystemFilter = ['employee', 'company'].includes(query.type);

        this.filters.awarder = query.awarder || null;
        this.filters.type = isSystemFilter ? query.type || null : parseInt(query.type, 10) || null;
        this.filters.year = parseInt(query.year, 10) || null;
        this.handleFilter();
    },
    methods: {
        openLink(e, link) {
            const whiteListed = ['DIV', 'PICTURE', 'IMG'].includes(e.target.tagName);
            if (!whiteListed) return;
            const href = link?.to || null;
            if (!href) return;

            const isAbsoluteLink = href.startsWith('http:') || href.startsWith('https://') || href.startsWith('//');
            const isAcronisLink = href.split('//').pop().toLowerCase().startsWith('www.acronis.com');

            if (!isAbsoluteLink && link?.target !== '_blank') {
                window.location.href = href;
            } else if (link?.traget === '_blank' || !isAcronisLink) {
                window.open(href);
            } else {
                window.location.href = href;
            }
        },
        dateComparator(a, b) {
            const aDate = new Date(this.getDate(a.date));
            const bDate = new Date(this.getDate(b.date));

            return bDate - aDate;
        },
        isHighlight(i) {
            return [0, 1].includes(i);
        },
        handleFilter() {
            const filterIf = (condition, filterFn) => (items) => (condition ? items.filter(filterFn) : items);
            this.filteredResults = flow(
                filterIf(this.filters.year, (el) => this.getDateParts(el.date).year === this.filters.year),
                filterIf(this.filters.awarder, (el) => el.awarder === this.filters.awarder),
                filterIf(this.filters.type, (el) => el.type === this.filters.type),
            )(this.items);

            this.$router?.push({
                path: this.$route.path,
                query: this.filters,
            });
        },
        resetFilters() {
            this.filters.year = null;
            this.filters.type = null;
            this.filters.awarder = null;
            this.$router?.push({ path: this.$route.path, query: {} });
        },
        getProductName(product) {
            if (product === 'certificates') return this.labels.certificatesLabel;
            if (product === 'recognitions') return this.labels.recognitionsLabel;
            if (product === 'company') return this.labels.companyLabel;
            if (product === 'employee') return this.labels.employeeLabel;

            const productName = this.products.find((p) => p.id === product)?.name || null;
            return `Acronis ${productName}`;
        },
        getDate(dateString) {
            // TODO: check with next date-time localization update
            return parse(dateString, 'dd.MM.yyyy', new Date());
        },
        getDateParts(dateString) {
            const date = this.getDate(dateString);
            return {
                day: date.getDate(),
                month: date.getMonth() + 1,
                year: date.getFullYear(),
            };
        },
    },
};
</script>

<style lang="postcss" scoped>
.awards-page-wrapper {
    .top-bar {
        width: 100%;
        height: 80px;
        background: var(--av-nav-primary);
        @media (--viewport-desktop) {
            height: 96px;
        }
    }
    .main-header {
        position: relative;
        padding-bottom: 72px;
        h1 {
            @mixin title-accent;
            @mixin colls 12;
            padding-top: 40px;
            color: var(--av-fixed-white);
            text-align: center;
            margin: 0 auto 24px;
            @media (--viewport-tablet) {
                @mixin display-accent;
                padding-top: 80px;
                margin: 0 auto 64px;
            }
            @media (--viewport-desktop) {
                @mixin hero-accent;
                @mixin colls 8;
                margin: 0 auto 48px;
            }
        }
        .cards {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            gap: 16px;
            @media (--viewport-tablet) {
                gap: 0;
            }
            .card {
                @mixin colls 12;
                display: flex;
                flex-flow: row nowrap;
                padding: 24px;
                color: var(--av-fixed-white);
                background: rgba(0, 32, 77, 0.8);
                border-radius: 16px;
                backdrop-filter: blur(5px);
                border: 2px solid var(--av-brand-secondary-light);
                @media (--viewport-tablet) {
                    @mixin colls 4;
                    padding: 24px 16px 32px;
                    text-align: center;
                    flex-flow: column;
                }
                @media (--viewport-desktop-wide) {
                    padding: 24px 48px 32px;
                }
                .image {
                    width: 72px;
                    height: 72px;
                    margin-inline-end: 24px;
                    @media (--viewport-tablet) {
                        margin-inline-end: 0px;
                        margin: 0 auto 8px;
                    }
                }
                .content {
                    width: 100%;
                    .number {
                        @mixin display-accent;
                    }
                    .desc {
                        @mixin title;
                        font-weight: 500;
                    }
                }
            }
        }
    }
    .filters {
        background: var(--av-fixed-white);
        box-shadow: var(--av-shadow-regular);
        padding: 40px 0 48px;
        @media (--viewport-desktop) {
            padding: 40px 0 32px;
        }
        .title {
            @mixin title;
            display: block;
            color: var(--av-nav-primary);
            margin-bottom: 32px;
            @media (--viewport-tablet) {
                @mixin display;
            }
            @media (--viewport-desktop) {
                @mixin hero;
            }
        }
        .selections {
            display: flex;
            flex-flow: column;
            gap: 16px;
            @media (--viewport-desktop) {
                flex-flow: row nowrap;
            }
            &:deep(.el-select) {
                @media (--viewport-desktop) {
                    @mixin colls 4;
                }
            }
        }
    }
    .items-wrapper {
        .title {
            @mixin hero;
            margin-bottom: 40px;
            color: var(--av-nav-primary);
        }
        .featured {
            @mixin basic-slice-paddings;
            background: var(--av-gradient-to-bottom-brand-lightest);

            .grid-container {
                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: auto;
                grid-column-gap: 16px;
                grid-row-gap: 16px;
                @media (--viewport-tablet) {
                    grid-template-columns: repeat(12, 1fr);
                }
                @media (--viewport-desktop) {
                    grid-template-columns: repeat(12, 1fr);
                    grid-column-gap: 16px;
                    grid-row-gap: 24px;
                }
                .card {
                    padding: 24px 24px 32px;
                    border-radius: 4px;
                    border: 1px solid var(--av-brand-secondary-accent);
                    box-shadow: var(--av-shadow-small);
                    background: var(--av-fixed-white);
                    display:flex;
                    flex-flow: column;
                    &.linked {
                        cursor: pointer;
                        &:hover {
                            box-shadow: none;
                        }
                    }
                    .image {
                        margin-bottom: 8px;
                        &:deep(img) {
                            max-height: 64px;
                            margin: 0;
                        }
                    }
                    .content {
                        .product {
                            @mixin body;
                            color: var(--av-fixed-light);
                        }
                        .title {
                            @mixin lead-accent;
                            color: var(--av-nav-primary);
                            margin-bottom: 24px;
                            @media (--viewport-tablet) {
                                margin-bottom: 16px;
                            }
                            @media (--viewport-desktop-wide) {
                                margin-bottom: 24px;
                            }
                        }
                        .description {
                            @mixin body;
                            color: var(--av-fixed-secondary);
                            margin-bottom: 16px;
                        }
                        .info {
                            display: flex;
                            flex-flow: row wrap;
                            .country {
                                @mixin colls 12;
                                display: block;
                                @mixin body;
                                color: var(--av-fixed-secondary);
                                margin-bottom: 40px;
                            }
                        }
                    }
                    &.weight_0 {
                        padding: 32px;
                        flex-flow: row wrap;
                        @media (--viewport-tablet) {
                            grid-area: 1 / 1 / 2 / 13;
                        }
                        @media (--viewport-desktop) {
                            grid-area: 1 / 1 / 2 / 9;
                        }
                        .image {
                            @mixin colls 12;
                            margin-bottom:24px;
                            @media (--viewport-tablet) {
                                width: 184px;
                                margin-inline-end: 24px;
                                margin-bottom: 0;
                            }
                            @media (--viewport-desktop-wide) {
                                width: 220px;
                            }
                            &:deep(img) {
                                max-height: 120px;
                                @media (--viewport-tablet) {
                                    max-height: 100px;
                                }
                                @media (--viewport-desktop-wide) {
                                    max-height: 120px;
                                }
                            }
                        }
                        .content {
                            @mixin colls 12;
                            @media (--viewport-tablet) {
                                width: calc(100% - 208px);
                            }
                            @media (--viewport-desktop-wide) {
                                width: calc(100% - 244px);
                            }
                            .title {
                                margin-bottom: 16px;
                            }
                            .description {
                                @media (--viewport-tablet) {
                                    margin-bottom: 24px;
                                }
                            }
                            .info {
                                @media (--viewport-tablet) {
                                    flex-flow: row nowrap;
                                    justify-content: space-between;
                                    align-items: center;
                                    .country {
                                        margin: 0;
                                        width: auto;
                                    }
                                }
                            }
                        }
                    }
                    &.weight_1 {
                        @media (--viewport-tablet) {
                            grid-area: 2 / 7 / 4 / 13;
                        }
                        @media (--viewport-desktop) {
                            grid-area: 1 / 9 / 3 / 13;
                        }
                        .image {
                            margin-bottom:24px;
                            &:deep(img) {
                                max-height: 120px;
                            }
                        }
                        .content {
                            @media (--viewport-tablet) {
                                margin-top: auto;
                            }
                            .title {
                                margin-bottom: 24px;
                            }
                            .info {
                                .country {
                                    @media (--viewport-tablet) {
                                        margin-bottom: 48px;
                                    }
                                    @media (--viewport-desktop) {
                                        margin-bottom: 80px;
                                    }
                                    @media (--viewport-desktop-wide) {
                                        margin-bottom: 88px;
                                    }
                                }
                                .link {
                                    margin-top: auto;
                                }
                            }
                        }
                    }
                    &.weight_2 {
                        @media (--viewport-tablet) {
                            grid-area: 2 / 1 / 3 / 7;
                        }
                        @media (--viewport-desktop) {
                            grid-area: 2 / 1 / 3 / 5;
                        }
                        .content {
                            @media (--viewport-tablet) {
                                display: flex;
                                flex-direction: column;
                                justify-content: stretch;
                                flex-grow: 1;
                            }
                            .info {
                                margin-top: auto;
                            }
                        }
                        .title {
                            margin-bottom: 16px;
                            @media (--viewport-desktop-wide) {
                                margin-bottom: 24px;
                            }
                        }
                    }
                    &.weight_3 {
                        @media (--viewport-tablet) {
                            grid-area: 3 / 1 / 4 / 7;
                        }
                        @media (--viewport-desktop) {
                            grid-area: 2 / 5 / 3 / 9;
                        }
                        .title {
                            margin-bottom: 16px;
                            @media (--viewport-desktop-wide) {
                                margin-bottom: 24px;
                            }
                        }
                        .content {
                            @media (--viewport-tablet) {
                                display: flex;
                                flex-direction: column;
                                justify-content: stretch;
                                flex-grow: 1;
                            }
                            .info {
                                margin-top: auto;
                            }
                        }
                    }
                }
            }
        }
        .recent {
            padding-bottom: 48px;
            background: var(--av-fixed-white);

            .card-list {
                display: flex;
                flex-flow: row wrap;
                gap: 16px;
                @media (--viewport-desktop) {
                    gap: 24px 16px;
                }
                .card {
                    @mixin colls 12;
                    padding: 24px;
                    border-radius: 4px;
                    border: 1px solid var(--av-brand-secondary-accent);
                    background: var(--av-fixed-white);
                    box-shadow: var(--av-shadow-small);
                    display:flex;
                    flex-flow: column;
                    &.linked {
                        cursor: pointer;
                        &:hover {
                            box-shadow: none;
                        }
                    }
                    @media (--viewport-tablet) {
                        @mixin colls 6;
                    }
                    @media (--viewport-desktop) {
                        @mixin colls 4;
                        padding: 24px 24px 32px;
                    }
                    .image {
                        margin-bottom: 8px;
                        &:deep(img) {
                            max-height: 64px;
                            margin: 0;
                        }
                    }
                    .content {
                        @media (--viewport-tablet) {
                            display: flex;
                            flex-direction: column;
                            justify-content: stretch;
                            flex-grow: 1;
                        }
                        .product {
                            @mixin body;
                            color: var(--av-fixed-light);
                        }
                        .title {
                            @mixin lead-accent;
                            color: var(--av-nav-primary);
                            margin-bottom: 24px;
                            @media (--viewport-tablet) {
                                margin-bottom: 16px;
                            }
                            @media (--viewport-desktop-wide) {
                                margin-bottom: 24px;
                            }
                        }
                        .description {
                            @mixin body;
                            color: var(--av-fixed-secondary);
                            margin-bottom: 16px;
                        }
                        .info {
                            display: flex;
                            flex-flow: row wrap;
                            @media (--viewport-tablet) {
                                 margin-top: auto;
                            }
                            .country {
                                @mixin colls 12;
                                display: block;
                                @mixin body;
                                color: var(--av-fixed-secondary);
                                margin-bottom: 40px;
                            }
                        }
                    }
                }
            }
            .load-more {
                @mixin colls 12;
                justify-content: center;
                margin-top: 16px;
                font-weight: 500;
                border: 1px solid var(--av-brand-secondary-light);
                box-shadow: none;
                @media (--viewport-desktop) {
                    margin-top:24px;
                }
                &:deep(.a-glyph) {
                    margin-inline-start: 8px;
                    fill: var(--av-brand-secondary);
                }
            }
        }
    }
    .filtered-items-wrapper {
        @mixin basic-slice-paddings;
        background: var(--av-gradient-to-bottom-brand-lightest);

        .filtered {
            .head {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                align-items: center;
                padding-top: 16px;
                padding-bottom: 48px;
                border-top: 1px solid var(--av-brand-secondary-accent);
                @media (--viewport-tablet) {
                    border-top: none;
                    border-bottom: 2px solid var(--av-brand-secondary-accent);
                    padding-top: 0;
                    padding-bottom: 8px;
                    margin-bottom: 40px;
                }
                .title {
                    @mixin lead;
                    font-weight: 500;
                    color: var(--av-nav-primary);
                    margin: 0;
                    @media (--viewport-tablet) {
                        @mixin title;
                    }
                    span {
                        color: var(--av-brand-primary);
                        margin-inline-start: 8px;
                        @media (--viewport-tablet) {
                            margin-inline-start: 16px;
                        }
                    }
                }
            }
            .wrapper {
                .card-list {
                    display: flex;
                    flex-flow: row wrap;
                    gap: 16px;
                    @media (--viewport-desktop) {
                        gap: 24px 16px;
                    }
                    .card {
                        @mixin colls 12;
                        padding: 24px;
                        border-radius: 4px;
                        border: 1px solid var(--av-brand-secondary-accent);
                        background: var(--av-fixed-white);
                        box-shadow: var(--av-shadow-small);
                        display:flex;
                        flex-flow: column;
                        &.linked {
                            cursor: pointer;
                            &:hover {
                                box-shadow: none;
                            }
                        }
                        @media (--viewport-tablet) {
                            @mixin colls 6;
                        }
                        @media (--viewport-desktop) {
                            @mixin colls 4;
                            padding: 24px 24px 32px;
                        }
                        .image {
                            margin-bottom: 8px;
                            &:deep(img) {
                                max-height: 64px;
                                margin: 0;
                            }
                        }
                        .content {
                            @media (--viewport-tablet) {
                                display: flex;
                                flex-direction: column;
                                justify-content: stretch;
                                flex-grow: 1;
                            }
                            .product {
                                @mixin body;
                                color: var(--av-fixed-light);
                            }
                            .title {
                                @mixin lead-accent;
                                color: var(--av-nav-primary);
                                margin-bottom: 24px;
                                @media (--viewport-tablet) {
                                    margin-bottom: 16px;
                                }
                                @media (--viewport-desktop-wide) {
                                    margin-bottom: 24px;
                                }
                            }
                            .description {
                                @mixin body;
                                color: var(--av-fixed-secondary);
                                margin-bottom: 16px;
                            }
                            .info {
                                display: flex;
                                flex-flow: row wrap;
                                @media (--viewport-tablet) {
                                    margin-top: auto
                                }
                                .country {
                                    @mixin colls 12;
                                    display: block;
                                    @mixin body;
                                    color: var(--av-fixed-secondary);
                                    margin-bottom: 40px;
                                }
                            }
                        }
                    }
                }
                .load-more {
                    @mixin colls 12;
                    justify-content: center;
                    margin-top: 16px;
                    font-weight: 500;
                    border: 1px solid var(--av-brand-secondary-light);
                    box-shadow: none;
                    @media (--viewport-desktop) {
                        margin-top:24px;
                    }
                    &:deep(.a-glyph) {
                        margin-inline-start: 8px;
                        fill: var(--av-brand-secondary);
                    }
                }
            }
        }
    }
}
</style>
