<template>
    <dynamic-link class="a-asset-card" :to="link" :title="title">
        <template v-if="imageList && imageList.length > 1">
            <div class="a-asset-card__slider">
                <span v-for="(item, index) in imageList" :key="`a-asset-card-img-${index}`" class="a-asset-card__slide">
                    <a-picture class="a-asset-card__img" :link="item.imagePath" :alt="item.imageAlt" />
                </span>
                <span class="a-asset-card__markers-container">
                    <span
                        v-for="(_, index) in imageList"
                        :key="`a-asset-card-marker-${index}`"
                        class="a-asset-card__marker"
                    />
                </span>
            </div>
        </template>
        <div v-else class="a-asset-card__single">
            <a-picture class="a-asset-card__single__img" :link="imageList[0].imagePath" :alt="imageList[0].imageAlt" />
        </div>
        <div class="a-asset-card__desc">
            <h4 v-if="title" class="a-asset-card__title">
                {{ title }}
            </h4>
            <span v-if="text" class="a-asset-card__text">{{ text }}</span>
            <span class="a-asset-card__file">
                <span class="a-asset-card__file-info">{{ fileInfo }}</span>
                <a-glyph class="a-asset-card__file-icon" name="download-arrow" />
            </span>
        </div>
        <div class="a-asset-card__inner-border" />
    </dynamic-link>
</template>

<script>
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import APicture from '@core/components/picture/picture.vue';

export default {
    name: 'AAssetCard',

    components: {
        AGlyph,
        APicture,
        DynamicLink,
    },

    props: {
        link: {
            type: String,
            required: true,
        },

        imageList: {
            type: Array,
            required: true,
        },

        title: {
            type: String,
            required: true,
        },

        text: {
            type: String,
            required: true,
        },

        fileInfo: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="postcss">
.a-asset-card {
    position: relative;
    display: block;
    border: 1px solid transparent;
    border-radius: 4px;
    text-decoration: none;
    box-shadow: var(--av-shadow-small);
    transition: box-shadow 0.15s ease-in-out;
    width: 100%;
    background: var(--av-fixed-white);
    padding: 0;

    &:hover {
        box-shadow: none;
        transition: box-shadow 0.3s ease-in-out;

        .a-asset-card {
            /*
            &__slide {
                &:nth-child(1) {
                    opacity: 0;
                }

                &:nth-child(2) {
                    opacity: 1;
                }
            }
            */

            &__marker {
                &:nth-child(1) {
                    background-color: var(--av-brand-secondary);
                }

                &:nth-child(2) {
                    background-color: var(--av-fixed-white);
                }
            }
        }
    }

    &__slider,
    &__single {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        overflow: hidden;
        position: relative;
        display: block;
        padding-top: 67%;
        width: 100%;
        margin: 0;
    }

    &__slide,
    &__single__img {
        position: absolute;
        top: 0;
        inset-inline-start: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        transition: opacity 0.25s ease-out;

        /*
        &:nth-child(1) {
            opacity: 1;
        }

        &:nth-child(2) {
            opacity: 0;
        }
        */
    }

    &__img {
        position: relative;
        width: 100%;
        height: 100%;

        .a-picture__img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__markers-container {
        margin: 0 -5px;
        position: absolute;
        inset-inline-start: 23px;
        inset-inline-end: 23px;
        bottom: 10px;
        display: flex;
        align-items: center;
    }

    &__marker {
        margin: 0 5px;
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;

        &:nth-child(1) {
            background-color: var(--av-fixed-white);
        }

        &:nth-child(2) {
            background-color: var(--av-brand-secondary);
        }
    }

    &__desc {
        display: block;
        padding: 16px 24px 24px;
    }

    &__title {
        display: block;
        margin-bottom: 8px;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        color: var(--av-nav-primary);
    }

    &__text {
        display: block;
        margin-bottom: 16px;
        font-size: 14px;
        line-height: 20px;
        color: var(--av-fixed-secondary);
    }

    &__file {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__file-info {
        display: block;
        font-size: 14px;
        line-height: 20px;
        color: var(--av-fixed-light);
    }

    &__file-icon {
        width: 16px;
        height: 16px;
    }

    &__inner-border {
        position: absolute;
        inset: -1px;
        background: transparent;
        border-radius: 4px;
        border: 1px solid var(--av-brand-primary);
        opacity: 0.2;
        pointer-events: none;
    }
}
</style>
