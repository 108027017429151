<template>
    <div class="contact-modal-wrapper">
        <div class="contact-modal">
            <div class="wrapper">
                <h6 class="title">
                    {{ labels.title }}
                </h6>
                <a-picture :link="labels.image" :alt="labels.title" class="image" />
                <a-button :text="labels.button" class="button" @click="$emit('onClose')" />
            </div>
            <button class="close" @click="$emit('onClose');">
                <a-glyph name="close" class="close-icon" />
            </button>
        </div>
        <button class="mobile-close" @click="$emit('onClose');">
            <a-glyph name="close" class="close-icon" />
        </button>
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import APicture from '@core/components/picture/picture.vue';
import commonUtils from '@utils/common';

export default {
    components: {
        AButton,
        APicture,
        AGlyph,
    },
    props: {
        labels: {
            type: Object,
            default: undefined,
        },
    },
    emits: ['onClose'],
    mounted() {
        commonUtils.globalOverflowProperty('hidden');
    },
    beforeDestroy() {
        commonUtils.globalOverflowProperty('auto');
    },
};
</script>

<style lang="postcss" scoped>
.contact-modal-wrapper {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(36, 49, 67, 0.9);
    padding: 64px 0;
    z-index: 900;
    overflow: auto;
    .mobile-close {
        position: absolute;
        top: 24px;
        inset-inline-end: 24px;
        @media (--viewport-tablet) {
            display:none;
        }
            .close-icon {
            fill: #A6CBFF;
            width: 24px;
            height: 24px;
        }
    }
    .contact-modal {
        position: relative;
        max-width: calc(100% - 32px);
        width: 100%;
        height: auto;
        overflow: visible;
        margin: auto;
        background: var(--av-fixed-white);
        border: 1px solid var(--av-brand-light);
        box-shadow: var(--av-shadow-modal);
        border-radius: 4px;
        animation: vroi-popup 0.3s both;
        @media (--viewport-mobile-wide) {
            max-width: 417px;
        }
        @media (--viewport-tablet) {
            max-width: 566px;
        }
        .close {
            display:none;
            position: absolute;
            top: 0px;
            inset-inline-end: -32px;
            z-index: 500;
            cursor: pointer;
            .close-icon {
                fill: #A6CBFF;
                width: 24px;
                height: 24px;
            }
            @media (--viewport-tablet) {
                display:block;
            }
        }
        .wrapper {
            padding: 24px;
            text-align: center;
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            overflow: auto;
            @media (--viewport-mobile-wide) {
                padding: 40px;
            }
            .title {
                @mixin colls 12;
                @mixin lead-accent;
                color: var(--av-nav-primary);
                margin-bottom: 40px;
            }
            .image {
                margin-bottom:24px;
                max-width: 274px;
            }
            .button {
                @mixin colls 12;
                @mixin paragraph;
            }
        }
        @keyframes vroi-popup {
            0% {
                opacity:0;
            }
            100% {
                opacity:1;
            }
        }
    }
}
</style>
