<template>
    <div class="blog-page blog-tag">
        <s-locale-selector-ribbon :is-shown="isLocaleSelectorShown" />
        <div class="main-content">
            <s-main-header v-bind="sliceDataHeader" />
            <blog-container class="intro">
                <blog-head :title="translations.homeTitle" v-bind="sectionLink" />
                <blog-bcrumbs v-bind="bcrumbs" />
                <blog-title-row :title="tagTitle" :social-links="socialLinks" :socials-title="translations.followUsOn" />
                <blog-featured-post :post="topPost" />
            </blog-container>
            <blog-container class="cards">
                <blog-cards :cards="latestCards">
                    <template v-if="listCurrentPage < listTotalPages" #pagination>
                        <blog-load-more
                            class="load-more"
                            :is-loading="isXHRInProgress"
                            :button-text="translations.loadMore"
                            @click="onLoadMoreClicked"
                        />
                    </template>
                </blog-cards>
            </blog-container>
        </div>
        <s-global-footer class="footer" v-bind="sliceDataFooter" />
    </div>
</template>

<script>
import analytics from '@core/mixins/analytics.js';
import blog from '@core/mixins/blog.js';
import localeRibbon from '@core/mixins/locale-ribbon.js';
import BlogBcrumbs from '@core/slices/blog/blog-bcrumbs/blog-bcrumbs.vue';
import BlogCards from '@core/slices/blog/blog-cards/blog-cards.vue';
import BlogFeaturedPost from '@core/slices/blog/blog-featured-post/blog-featured-post.vue';
import BlogHead from '@core/slices/blog/blog-head/blog-head.vue';
import BlogTitleRow from '@core/slices/blog/blog-title-row/blog-title-row.vue';
import BlogContainer from '@core/slices/blog/shared-components/blog-container/blog-container.vue';
import BlogLoadMore from '@core/slices/blog/shared-components/blog-load-more/blog-load-more.vue';
import SGlobalFooter from '@core/slices/pages/global-footer/global-footer.vue';
import SLocaleSelectorRibbon from '@core/slices/pages/locale-selector-ribbon/locale-selector-ribbon.vue';
import SMainHeader from '@core/slices/pages/main-header/main-header.vue';
import { SECTION_ID_BLOG, SECTION_ID_CPC, SECTION_SLUG_BY_ID } from '@model/const/blog.ts';
import socials from '@model/const/socials.ts';
import '@core/styles/sections/blog.pcss';

export default {
    name: 'BlogTag',

    components: {
        SGlobalFooter,
        SMainHeader,
        BlogHead,
        BlogBcrumbs,
        BlogTitleRow,
        BlogContainer,
        BlogCards,
        BlogFeaturedPost,
        BlogLoadMore,
        SLocaleSelectorRibbon,
    },

    mixins: [blog, analytics, localeRibbon],

    props: {
        dispatchLoadMoreCards: {
            type: Function,
            required: true,
        },
    },

    data() {
        return {
            socials,
            listCurrentPage: 1,
            isXHRInProgress: false,
        };
    },

    computed: {
        sliceDataHeader() {
            return {
                ...this.$store.state.slices.items['s-main-header'],
                theme: 'light',
            };
        },

        sliceDataFooter() {
            return {
                ...this.$store.state.slices.items['s-global-footer'],
                type: 'short',
                theme: 'light',
            };
        },

        localeHasCPC() {
            return !!this.$store.state.locales.locale?.is_active_on_blog_cpc;
        },

        sectionLink() {
            if (!this.localeHasCPC) {
                return null;
            }

            return {
                sectionLink: `/${SECTION_SLUG_BY_ID[SECTION_ID_CPC]}/`,
                sectionTitle: this.translations.cpcTitle,
                sectionText: this.translations.cpcLead,
            };
        },

        bcrumbs() {
            const blogItem = {
                to: `/${SECTION_SLUG_BY_ID[SECTION_ID_BLOG]}/`,
                title: this.translations.homeTitle,
            };

            const bcrumbs = [
                blogItem,
                {
                    title: this.tag.title,
                },
            ];

            return {
                theme: 'light',
                bcrumbs,
                mobileLink: blogItem,
            };
        },

        tag() {
            return this.$store.state.blog.tag.items[0];
        },

        tagTitle() {
            return this.translations.tagTitle.replace('@value', this.tag.title);
        },

        topPost() {
            const post = (this.$store.state.blog.topPost.items || [])[0];
            return this.getBlogPostFields(post, this.translations);
        },

        latestCards() {
            const cards = this.$store.state.blog.latestCards.items || [];
            return cards.map((card) => this.getBlogPostFields(card, this.translations));
        },

        listTotalPages() {
            return this.$store.state.blog.latestCards.pagination?.pages_total || 1;
        },

        socialLinks() {
            const linksSource = this.translations.customSocialLinks || socials.links;
            const links = Array.from(linksSource);
            const rss = {
                link: '/blog/feed.xml#',
                name: 'rss',
                title: 'RSS',
            };
            links.push(rss);
            return links;
        },
    },

    methods: {
        async onLoadMoreClicked() {
            this.isXHRInProgress = true;
            const res = await this.dispatchLoadMoreCards(this.tag.id, this.listCurrentPage + 1);
            this.isXHRInProgress = false;

            if (res) {
                this.listCurrentPage += 1;
            }
        },
    },
};
</script>

<style lang="postcss" scoped>
.blog-tag {
    .intro {
        margin-bottom: 40px;

        @media (--viewport-tablet) {
            margin-bottom: 48px;
        }
    }

    .cards {
        padding-bottom: 48px;
    }

    .load-more {
        margin-top: 64px;
    }
}
</style>
