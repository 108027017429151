<template>
    <div class="company-pages">
        <section class="s-company-careers">
            <div class="a-container">
                <client-only>
                    <div v-if="jobs.currentLocations" class="careers-main">
                        <div class="careers-sidebar">
                            <div class="title">
                                {{ sidebarTitle }}
                            </div>
                            <div class="locations">
                                <el-select
                                    v-model="jobs.currentLocations"
                                    v-bind="labelSelect"
                                    filterable
                                    :hide-on-resize="isDesktop"
                                    multiple
                                    size="large"
                                >
                                    <el-option
                                        v-for="(item, index) in jobsByLocation"
                                        :key="`option-${index}`"
                                        :label="index"
                                        :value="index"
                                        :selected="item === jobs.currentLocation"
                                    />
                                </el-select>
                            </div>
                            <div v-if="allOpenJobsCount > 0" class="all-jobs-count">
                                {{ allOpenJobsLabel }}: {{ allOpenJobsCount }}
                            </div>
                            <div class="departments">
                                <el-select
                                    v-model="jobs.currentDepartment"
                                    default="all"
                                    size="large"
                                    :hide-on-resize="isDesktop"
                                >
                                    <el-option key="all" value="all" :label="allDepartmentsLabel" />
                                    <template v-if="jobs.currentLocations.length">
                                        <el-option
                                            v-for="(item, index) in jobsForCurrentLocations"
                                            :key="`option-${index}`"
                                            :label="index"
                                            :value="index"
                                        />
                                    </template>
                                </el-select>
                            </div>
                            <button
                                v-if="jobs.currentLocations.length"
                                class="button"
                                :disabled="jobs.currentDepartment === 'all'"
                                @click="jobs.currentDepartment = 'all'"
                            >
                                {{ allDepartmentsText }}
                            </button>
                            <button
                                v-for="(department, index) in jobsForCurrentLocations"
                                :key="`button-${index}`"
                                class="button"
                                :disabled="jobs.currentDepartment === index"
                                @click="jobs.currentDepartment = index"
                            >
                                {{ index }}
                            </button>
                        </div>
                        <div v-if="jobsToShow.length" class="careers-content">
                            <div v-if="allOpenJobsCount > 0" class="all-jobs-count">
                                {{ allOpenJobsLabel }}: {{ allOpenJobsCount }}
                            </div>
                            <div class="careers-jobs">
                                <template v-for="(job, index) in jobsToShow">
                                    <div :key="job.to" class="careers-job">
                                        <a-link
                                            class="title"
                                            :to="job.to"
                                            :target="job?.target"
                                            :text="cutText(job.title, 60)"
                                        />
                                        <div class="location">
                                            {{ job.location }}
                                        </div>
                                    </div>
                                    <div v-if="shouldDrawJobLine(index, jobsToShow.length)" :key="`line-${job.to}`" class="careers-line" />
                                </template>
                            </div>
                            <a-button
                                v-if="moreButtonShown"
                                class="careers-more"
                                :class="{ active: allItemsShown }"
                                :text="allItemsShown ? showLessLabel : showMoreLabel"
                                glyph="chevron-down"
                                @click="showMore"
                            />
                        </div>
                    </div>
                </client-only>
            </div>
        </section>
    </div>
</template>

<script>
import { get } from 'lodash';
import AButton from '@core/components/button/button.vue';
import ClientOnly from '@core/components/client-only/client-only.vue';
import ALink from '@core/components/link/link.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import form from '@core/mixins/form.js';

export default {
    name: 'SCompanyPagesCareers',
    components: {
        ALink,
        AButton,
        ClientOnly,
        ElSelect: () => import('@uikit/ui-kit/packages/select'),
        ElOption: () => import('@uikit/ui-kit/packages/option'),
    },
    mixins: [breakpoint, form],
    props: {
        allDepartmentsText: {
            type: String,
            default: 'All departments',
        },
        sidebarTitle: {
            type: String,
            default: 'Select your location',
        },
        allDepartmentsLabel: {
            type: String,
            default: 'All departments',
        },
        showMoreLabel: {
            type: String,
            default: 'Show all open positions',
        },
        showLessLabel: {
            type: String,
            default: 'Show less open positions',
        },
        allOpenJobsLabel: {
            type: String,
            default: 'All open jobs',
        },
        jobsLimit: {
            type: Number,
            default: 12,
        },
    },
    data() {
        return {
            jobs: {
                currentLocations: [],
                currentDepartment: 'all',
            },
            country: null,
            allItemsShown: false,
        };
    },
    computed: {
        jobsByLocation() {
            return this.$store.state.job.data;
        },
        jobsForCurrentLocations() {
            if (!this.jobs.currentLocations.length) {
                return {};
            }

            return this.jobs.currentLocations
                .map((location) => this.jobsByLocation[location])
                .reduce((acc, job) => {
                    Object.entries(job).forEach(([key, value]) => {
                        if (acc[key]) {
                            acc[key] = [...acc[key], ...value];
                        } else {
                            acc[key] = value;
                        }
                    });

                    return acc;
                }, {});
        },
        chosenDepartmentJobs() {
            const jobs = this.jobsForCurrentLocations[this.jobs.currentDepartment] || [];

            if (jobs.length) return jobs.slice(0, this.jobsLimit) || [];

            const jobObjEntries = Object.entries(this.jobsForCurrentLocations);

            if (!jobObjEntries.length) return [];

            // return all jobs for selected countries if no department is chosen
            return jobObjEntries.map(([, job]) => job).reduce((a, b) => a.concat(b));
        },
        jobsToShow() {
            return this.allItemsShown
                ? this.chosenDepartmentJobs
                : this.chosenDepartmentJobs.slice(0, this.jobsLimit);
        },
        allOpenJobsCount() {
            return this.chosenDepartmentJobs.length;
        },
        moreButtonShown() {
            return this.chosenDepartmentJobs.length > this.jobsLimit;
        },
    },
    watch: {
        'jobs.currentLocations': {
            handler() {
                this.jobs.currentDepartment = 'all';
            },
            deep: true,
        },
    },
    beforeMount() {
        this.fillGeoData();
    },
    methods: {
        showMore() {
            this.allItemsShown = !this.allItemsShown;
        },
        async fillGeoData() {
            await this.$store.dispatch('geolocation/getGeoLocation');

            const locationsWithJobs = Object.keys(this.jobsByLocation);
            this.country = get(
                this.$store.state,
                'geolocation.location.data.country.name',
                'United States',
            );

            if (!locationsWithJobs.includes(this.country)) {
                this.jobs.currentLocations = ['United States'];
                return;
            }

            this.jobs.currentLocations = [this.country];
        },
        cutText(str, len) {
            if (typeof str !== 'string') {
                return '';
            }

            if (str.length <= len) {
                return str;
            }

            const text = str.split(' ');
            let result = '';
            let currentLength = 0;

            for (let i = 0; i < text.length; i++) {
                if (currentLength + text[i].length > len) {
                    break;
                } else {
                    currentLength += text[i].length;
                    result += `${text[i]} `;
                }
            }

            return `${result.trim()}...`;
        },
        shouldDrawJobLine(index, length) {
            const breakpoints = {
                desktopLarge: 3,
                desktopWide: 3,
                desktop: 3,
                tablet: 2,
                mobileWide: 1,
                mobile: 1,
            };
            const jobsInLine = breakpoints[this.currentBreakpoint] || 1;

            return (index + 1) % jobsInLine === 0 || index + 1 === length;
        },
        requestData() {
            return Promise.all([this.$store.dispatch('job/getAll')]);
        },
    },
    async serverPrefetch() {
        await this.requestData();
    },
};
</script>
<style lang="postcss">
.company-pages .s-company-careers {
    @mixin basic-slice-paddings;

    .careers-header {
        display: block;
        width: 100%;
        max-width: 100%;

        @media (--viewport-tablet) {
            @mixin colls 8;
        }
        > .heading {
            @mixin display-accent;
            color: var(--av-nav-primary);
            margin-bottom: 24px;

            @media (--viewport-mobile-wide) {
                @mixin hero-accent;
            }

            @media (--viewport-desktop) {
                @mixin large-accent;
            }
        }
        > .description {
            @mixin lead;
            color: var(--av-fixed-primary);
            @media (--viewport-tablet) {
                @mixin title;
            }
        }
    }

    .careers-main {
        position: relative;

        @media (--viewport-tablet) {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            width: 100%;
        }
    }

    .all-jobs-count {
        @mixin lead-accent;
        color: var(--av-fixed-primary);
        margin-bottom: 16px;
    }

    .careers-sidebar {
        width: 100%;

        @media (--viewport-tablet) {
            @mixin colls 4;
        }
        @media (--viewport-desktop) {
            @mixin colls 3;
        }

        > .locations {
            margin-bottom: 32px;
            @media (--viewport-desktop) {
                margin-bottom: 40px;
            }
        }
        > .departments {
            display: block;
            @media (--viewport-tablet) {
                display: none;
            }
        }

        > .all-jobs-count {
            @media (--viewport-tablet) {
                display: none;
            }
        }
        > .title {
            @mixin lead-accent;
            color: var(--av-fixed-primary);
            margin-bottom: 16px;
        }
        > .button {
            @mixin paragraph;
            text-align: start;
            display: none;
            border: none;
            outline: none;
            background: transparent;
            padding: 12px 16px;
            color: var(--av-nav-primary);
            cursor: pointer;
            transition: all 0.2s ease;
            width: 100%;

            @media (--viewport-tablet) {
                display: block;
            }
            &:disabled {
                cursor: default;
                border-radius: 4px;
                background: var(--av-brand-lightest);
            }
            &:hover:enabled {
                color: var(--av-brand-secondary);
            }
        }
    }

    .careers-content {
        width: 100%;
        margin-top: 56px;

        @media (--viewport-tablet) {
            @mixin colls 8;
            margin-top: 0;
        }
        @media (--viewport-desktop) {
            @mixin colls 9;
        }

        .all-jobs-count {
            display: none;
            @media (--viewport-tablet) {
                display: block;
            }
        }
    }

    .careers-jobs {
        display: grid;
        column-gap: 16px;
        grid-template-columns: 1fr;
        flex-wrap: wrap;
        justify-content: space-between;
        position: relative;
        width: 100%;

        @media (--viewport-tablet) {
            grid-template-columns: 1fr 1fr;
        }
        @media (--viewport-desktop) {
            grid-template-columns: 1fr 1fr 1fr;
        }
        @media (--viewport-desktop-wide) {
            column-gap: 16px;
        }
    }

    .careers-job {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: 0;
        height: 96px;

        > .title {
            text-decoration: none;
            overflow: hidden;

            .a-link__content {
                @mixin lead-accent;
            }
        }
        > .location {
            @mixin body;
            margin-top: auto;
            color: var(--av-fixed-primary);
        }
    }

    .careers-line {
        width: 100%;
        display: block;
        height: 1px;
        background: var(--av-fixed-lightest);
        margin: 32px 0;

        &:last-of-type {
            display: none;
        }
        @media (--viewport-tablet) {
            grid-column: 1 / 3;
        }
        @media (--viewport-desktop) {
            grid-column: 1 / 4;
        }
    }

    .careers-more {
        @mixin paragraph-accent;
        width: 100%;
        margin-top: 48px;
        height: 42px;
        justify-content: center;
        color: var(--av-brand-primary);
        box-shadow: inset 0 0 0 1px var(--av-brand-secondary-light);

        .a-glyph {
            fill: var(--av-brand-primary);
            margin-inline-start: 8px;
        }
        &.active {
            .a-glyph {
                transform: scaleY(-1);
            }
        }
    }

    .el-input__editor {
        @mixin paragraph;
    }
}

.company-pages+.s-global-footer--locale-selector-visible {
    z-index: 2004;
}
</style>
