export const Figma = 'https://www.figma.com/design/eM4W6JS2MjG1gp5YhKoMp7Dd/Slices?node-id=58202-114712&t=LWcbKICEQRRYVaDf-4';

export const Default = {
    args: {
        translations: {
            title: 'Organize Your Hard Disk and Its Volumes Fast and Easy',
            productName: 'True Image 2020',
            infoFlag: 'Had jet lag and changed the name',
            'buttons.0.text': 'Buy Now',
            'buttons.1.text': 'Try Now',
            'buttons.1.context': 'No credit card required',
            'actionLinks.0.text': '+1 781 782 9000',
            'actionLinks.1.text': 'Find a Reseller',
            'productPageCTA.promo.label': 'Save up to 30%',
            'productPageCTA.promo.description': '<del>@price</del> <b>@priceFull</b>',
        },
        version: 'dark',
        title: 'title',
        productName: 'True Image 2020',
        infoFlag: 'infoFlag',
        buttons: [
            {
                text: 'buttons.0.text',
                to: '#',
            },
            {
                text: 'buttons.1.text',
                context: 'buttons.1.context',
                to: '#',
            },
        ],
        actionLinks: [
            {
                text: 'actionLinks.0.text',
                to: 'tel:+17817829000',
            },
            {
                text: 'actionLinks.1.text',
                to: '#',
            },
        ],
        productPageCTA: {
            productId: 64,
            promo: {
                glyph: null,
                label: 'productPageCTA.promo.label',
                description: 'productPageCTA.promo.description',
                cleverBridgeCartList: [236611],
                coupon: 'basic',
            },
        },
        id: 'cta',
    },
};
