<template>
    <div v-if="ready" class="blog-cpc-bar">
        <div class="title">
            {{ title }}
        </div>
        <div class="categories">
            <el-select
                class="categories-menu"
                :custom-text="placeholder"
                :placeholder="placeholder"
                :hide-on-resize="isDesktop"
                value=""
                popper-class="blog-dropdown categories-dropdown"
                @change="mobileMenuClick"
            >
                <el-option
                    v-for="(item, i) in links"
                    :key="i"
                    :label="item.title"
                    :value="item"
                />
            </el-select>
        </div>
    </div>
</template>

<script>
import breakpoint from '@core/mixins/breakpoint.js';

export default {
    name: 'BlogCpcBar',
    components: {
        ElSelect: () => import('@uikit/ui-kit/packages/select'),
        ElOption: () => import('@uikit/ui-kit/packages/option'),
    },
    mixins: [breakpoint],
    props: {
        links: {
            type: Array,
            required: true,
        },

        title: {
            type: String,
            required: true,
        },

        placeholder: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            ready: false,
        };
    },
    mounted() {
        // to show cpc-bar only when everything is loaded
        this.ready = true;
    },
    methods: {
        mobileMenuClick(item) {
            if (item) {
                document.location.href = item.link;
            }
        },
    },
};
</script>

<style lang="postcss" scoped>
.blog-cpc-bar {
    padding: 16px 0;
    margin-bottom: 40px;

    @media (--viewport-tablet) {
        display: grid;
        column-gap: 16px;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        margin-bottom: 48px;
    }

    .title {
        @mixin display;

        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--av-fixed-white);
        font-weight: bold;
        text-align: center;
        margin-bottom: 11px;

        @media (--viewport-tablet) {
            @mixin title-accent;

            justify-content: flex-start;
            grid-column-start: 1;
            grid-column-end: 4;
            text-align: start;
            margin-bottom: 0;
        }
    }

    .categories {
        text-align: center;

        @media (--viewport-tablet) {
            text-align: start;
            margin: 0;
        }
    }

    .categories-menu {
        max-width: 100px;

        @media (--viewport-tablet) {
            float: right;
        }

        @media (--viewport-desktop) {
            float: none;
        }
    }
}
</style>

<style lang="postcss">
.blog-cpc-bar {
    .categories-menu {
        .el-input {
            &__icon {
                color: var(--av-brand-secondary) !important;
            }

            &__editor--absolute-position {
                @mixin paragraph;

                color: var(--av-brand-secondary);
            }

            &__container {
                padding: 0;
                background: transparent;
                border: 0;
            }

            &__wrapper {
                padding: 13px 0 0;
                margin-inline-end: -20px;
            }

            &__icon {
                margin-inline-end: -20px;
            }
        }
    }
}
.categories-dropdown.el-select-dropdown {
    min-width: 240px !important;
    max-width: none;
    margin-top: -8px !important;

    @media (max-width: 767px) {
        inset-inline-start: 0 !important;
        inset-inline-end: 0 !important;
        margin: auto !important;
    }
}
</style>
