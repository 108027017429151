<template>
    <s-basic-slice
        v-if="products"
        :id="id"
        class="s-documentation"
        :class="{ ...basicClassName }"
    >
        <div v-if="title" class="s-documentation__title" v-html="title" />
        <div v-for="(product, key) in productList" :key="`product-${key}`" class="s-documentation__product">
            <div v-if="product.name" class="s-documentation__product-name" v-html="product.name" />
            <div
                v-for="(license, licenseKey) in product.licenses"
                :key="`license-${licenseKey}`"
                class="s-documentation__licenses"
            >
                <div v-if="license.name" class="s-documentation__license-name" v-html="license.name" />
                <div
                    v-for="(document, docKey) in license.documents"
                    :key="`document-${docKey}`"
                    class="s-documentation__documents"
                >
                    <div v-if="document.name" class="s-documentation__document-name" v-html="document.name" />
                    <div class="s-documentation__document-link">
                        <template v-if="document.link">
                            <a-link
                                glyph-position="left"
                                :to="document.link"
                                :glyph="document.linkGlyph || 'arrow'"
                                :text="document.linkText || setText('view')"
                            />
                        </template>
                        <template v-else>
                            <span class="text" v-html="setText('view')" />
                        </template>
                    </div>
                    <div class="s-documentation__document-file">
                        <template v-if="document.file">
                            <a-link
                                glyph-position="left"
                                :to="document.file"
                                :glyph="document.fileGlyph || 'download-arrow'"
                                :text="document.fileText || setText('download')"
                            />
                        </template>
                        <template v-else>
                            <span class="text" v-html="setText('download')" />
                        </template>
                    </div>
                    <div v-if="hasReleaseNotes(license)" class="s-documentation__document-rn">
                        <template v-if="document.rn">
                            <a-link
                                glyph-position="left"
                                :to="document.rn"
                                :glyph="document.fileGlyph || 'arrow'"
                                :text="document.fileText || setText('releaseNotes')"
                            />
                        </template>
                        <template v-else>
                            <span class="text" v-html="setText('releaseNotes')" />
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </s-basic-slice>
</template>

<script>
import { get } from 'lodash';
import ALink from '@core/components/link/link.vue';
import contentMixin from '@core/mixins/content.js';
import styleMixin from '@core/mixins/style.js';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';

export default {
    name: 'SDocumentation',

    components: {
        ALink,
        SBasicSlice,
    },

    mixins: [contentMixin, styleMixin],

    props: {
        products: {
            type: Array,
            default: undefined,
        },

        title: {
            type: String,
            default: '',
        },

        /**
         * Product selected
         */
        selected: {
            type: String,
            default: '',
        },

        dictionary: {
            type: Object,
            default: undefined,
        },
    },

    computed: {
        productList() {
            if (this.selected) {
                return this.products.filter((p) => p.name === this.selected);
            }
            return this.products;
        },
    },

    methods: {
        setText(name) {
            const text = {
                view: 'View online',
                download: 'Download pdf',
                releaseNotes: 'Release notes',
            };
            return get(this, `dictionary[${name}]`, text[name] || 'Download');
        },

        hasReleaseNotes(license) {
            return license.documents.find((item) => item.rn);
        },
    },
};
</script>

<style lang="postcss">
.s-documentation {
    color: var(--av-nav-primary);

    .a-slice-header {
        margin-bottom: 48px;
    }

    &__title {
        @mixin display;

        @media (--viewport-desktop-wide) {
            @mixin hero;
        }

        padding: 0 0 24px;
    }

    &__product {
        padding: 24px 0 0;
    }

    &__product-name {
        @mixin lead-accent;

        @media (--viewport-desktop-wide) {
            @mixin title-accent;
        }
    }

    &__licenses {
        margin: 24px 0;
        padding: 0 0 24px;
        border-bottom: 1px solid var(--av-brand-secondary-accent);
    }

    &__license-name {
        @mixin paragraph-accent;
    }

    &__documents {
        display: flex;
        flex-wrap: wrap;
        margin: 16px 0 0;
        justify-content: flex-start;

        &:not(:first-child) {
            margin: 16px 0 0;
        }

        @media (--viewport-desktop-wide) {
            flex-wrap: nowrap;
        }
    }

    &__document-name {
        width: 100%;
        color: var(--fixed-secondary);

        @media (--viewport-desktop-wide) {
            padding-inline-start: 8px;
        }
    }

    &__document-rn,
    &__document-file,
    &__document-link {
        width: 100%;

        .a-link {
            margin: 16px 0 0;

            @media (--viewport-desktop-wide) {
                margin: 0;
            }
        }

        .text {
            @mixin body;
            display: none;

            @media (--viewport-desktop-wide) {
                opacity: 0;
                display: block;
                padding-inline-start: 24px;
            }
        }

        @media (--viewport-desktop) {
            width: auto;

            .a-link {
                margin-inline-end: 32px;
            }
        }

        @media (--viewport-desktop-wide) {
            margin-inline-start: 24px;
            white-space: nowrap;

            .a-link {
                margin-inline-end: 0;
            }
        }
    }
}
</style>
