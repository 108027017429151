<template>
    <section class="acpc-success-story">
        <div class="a-container">
            <div class="wrapper">
                <a-picture
                    class="background"
                    :link="background"
                    is-background
                />
                <div class="inner-wrapper">
                    <div class="content">
                        <p v-if="story.description" class="description" v-html="story.description" />
                        <a-link
                            class="link"
                            v-bind="story.link"
                            :size="story.link.size || 'paragraph'"
                            :type="story.link.type || 'light'"
                        />
                        <div class="author">
                            <div class="avatar">
                                <a-picture :link="story.author.image" :alt="story.author.name" />
                            </div>
                            <div class="bio">
                                <span class="name">{{ story.author.name }}</span>
                                <span class="position">{{ story.author.position }}</span>
                            </div>
                        </div>
                    </div>
                    <a-media
                        v-if="media"
                        v-bind="media"
                        :disable-modal="Boolean(media?.modal)"
                        :modal-id="media?.modal?.modalId"
                        :enlarged-image="media?.modal?.enlargedImage"
                        class="image"
                    />
                </div>
            </div>
            <template v-if="media?.modal">
                <a-modal
                    :id="media.modal?.modalId"
                    :is-overlay-close="media.modal?.isOverlayClose || true"
                    :type="media.modal?.type || 'screenshot'"
                >
                    <template #default>
                        <a-picture
                            :link="media.modal?.enlargedImage"
                            position="center"
                            :is-shadow="false"
                            persist-original
                        />
                    </template>
                </a-modal>
            </template>
        </div>
    </section>
</template>

<script>
import ALink from '@core/components/link/link.vue';
import AMedia from '@core/components/media/media.vue';
import AModal from '@core/components/modal/modal.vue';
import APicture from '@core/components/picture/picture.vue';
import Modal from '@core/directives/modal.js';

export default {
    name: 'SProductSuccessStory',
    components: {
        APicture,
        ALink,
        AModal,
        AMedia,
    },
    directives: {
        Modal,
    },
    props: {
        background: {
            type: String,
            default: '',
        },
        media: {
            type: Object,
            default() {
                return {};
            },
        },
        story: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style lang="postcss" scoped>
.acpc-success-story {
    padding-top: 64px;
    padding-bottom: 64px;
    @media (--viewport-desktop-wide) {
        border-top: 1px solid var(--av-brand-accent);
    }
    .wrapper {
        overflow: hidden;
        border-radius: 16px;
        &:has(.is-modal-open) {
            overflow: visible;
        }
        &::before {
            content: '';
            background: rgba(0, 101, 227, 0.30);
            backdrop-filter: blur(15px);
            position: absolute;
            width: 100%;
            top: 0;
            height: 100%;
            inset-inline-start: 0;
            z-index: 1;
        }
        .inner-wrapper {
            position: relative;
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            justify-content: space-between;
            color: var(--av-fixed-white);
            padding: 48px 32px 48px;
            z-index: 1;
            @media (--viewport-tablet) {
                padding: 48px 32px;
            }
            @media (--viewport-desktop-wide) {
                padding: 48px 80px;
            }
            .content {
                width: 100%;
                @media (--viewport-desktop) {
                    max-width: calc(100% - 502px);
                }
                .description {
                    @mixin title;
                    margin-bottom: 16px;
                }
                .link {
                    font-weight: 700;
                }
                .author {
                    display: flex;
                    flex-flow: row wrap;
                    align-items: center;
                    margin-top: 24px;
                    .bio {
                        @mixin colls 12;
                        margin-top: 16px;
                        @media (--viewport-tablet) {
                            width: auto;
                            margin-top: 0;
                            margin-inline-start: 16px;
                        }
                        .name {
                            @mixin paragraph-accent;
                            display: block;
                        }
                        .position {
                            @mixin paragraph;
                        }
                    }
                }
            }
            .image {
                max-width: 438px;
                width: 100%;
                cursor: pointer;
                margin: 24px auto 0;
                @media (--viewport-desktop) {
                    margin: 0;
                }
            }
        }
        .inner-wrapper:has(.is-modal-open) {
            z-index: 9999;
        }
    }
}
</style>
