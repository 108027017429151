<template>
    <s-basic-slice class="s-faq" v-bind="$attrs" :title="computedTitle">
        <div class="s-faq__wrapper">
            <div class="s-faq__wrapper__list" :class="{ 'stand-alone': !computedSidebar }">
                <s-faq-section v-for="(item, index) in faqList" v-bind="item" :key="`faq-section-${index}`" />
            </div>
            <div v-if="computedSidebar" class="s-faq__wrapper__sidebar">
                <div v-if="computedSidebar.title" class="s-faq__wrapper__sidebar__title">
                    {{ computedSidebar.title }}
                </div>
                <ul v-if="computedSidebar.links" class="s-faq__wrapper__sidebar__links">
                    <li v-for="(link, index) in computedSidebar.links" :key="`faq_sidebar_links_${index}`">
                        <a-link v-bind="link" :glyph-position="link.glyphPosition || 'left'" />
                    </li>
                </ul>
                <ul class="s-faq__wrapper__sidebar__awards">
                    <li v-for="award in awards" :key="`faq_sidebar_awards_${award.id}`">
                        <dynamic-link :to="award.link" :title="award.title">
                            <a-picture :link="award.image" />
                        </dynamic-link>
                    </li>
                </ul>
                <div class="s-faq__wrapper__sidebar__text-blocks">
                    <div
                        v-if="computedSidebar.additional && computedSidebar.additional.top"
                        class="s-faq__wrapper__sidebar__text-blocks__item first"
                    >
                        <div class="s-faq__wrapper__sidebar__text-blocks__item__title">
                            {{ computedSidebar.additional.top.title }}
                        </div>
                        <div class="s-faq__wrapper__sidebar__text-blocks__item__content">
                            <a-dangerous-html :content="computedSidebar.additional.top.content" />
                        </div>
                    </div>
                    <div
                        v-if="computedSidebar.additional && computedSidebar.additional.bottom"
                        class="s-faq__wrapper__sidebar__text-blocks__item second"
                    >
                        <div class="s-faq__wrapper__sidebar__text-blocks__item__title">
                            {{ computedSidebar.additional.bottom.title }}
                        </div>
                        <div class="s-faq__wrapper__sidebar__text-blocks__item__content">
                            <a-dangerous-html :content="computedSidebar.additional.bottom.content" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </s-basic-slice>
</template>

<script>
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';
import productMixin from '@core/mixins/product.js';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';
import SFaqSection from './faq-section.vue';

export default {
    name: 'SFaq',
    components: {
        ADangerousHtml,
        DynamicLink,
        ALink,
        APicture,
        SBasicSlice,
        SFaqSection,
    },
    mixins: [productMixin],
    props: {
        title: {
            type: String,
            default: undefined,
        },
        items: {
            type: Array,
            default: () => [],
        },
        sidebar: {
            type: Object,
            default: undefined,
        },
    },
    serverPrefetch() {
        this.requestAwards();
    },
    computed: {
        computedTitle() {
            return this.title || this.productFAQ.title || null;
        },
        computedSidebar() {
            return this.sidebar || this.productFAQ.sidebar || false;
        },
        faqList() {
            return this.items.length ? this.items : this.productFAQ.items;
        },
        awards() {
            return (this.computedSidebar?.awards || [])
                .map((pid) => this.$store.state.awards.items[pid])
                .filter(Boolean);
        },
    },
    methods: {
        requestAwards() {
            const list = this.computedSidebar?.awards || [];
            list.forEach((id) => this.$store.dispatch('awards/getAward', id));
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-faq {
    .a-slice-header {
        &__title {
            @mixin display;
            margin-bottom: 40px;
            color: var(--av-nav-primary);
            @media (--viewport-mobile-wide) {
                @mixin hero;
            }
        }
    }
    &__wrapper {
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        justify-content: space-between;
        &__list {
            @mixin colls 12;
            @media (--viewport-desktop) {
                @mixin colls 7;
            }
            &.stand-alone {
                @media (--viewport-desktop) {
                    @mixin colls 12;
                }
            }
            .s-faq-section {
                &__title {
                    @mixin title;
                    font-weight: 600;
                    margin-bottom: 24px;
                    color: var(--av-nav-primary);
                }
            }
        }
        &__sidebar {
            @mixin colls 12;
            margin-top: 56px;
            @media (--viewport-desktop) {
                @mixin colls 4;
                margin-top: 0;
            }
            &__title {
                @mixin title;
                font-weight: 600;
                margin-bottom: 24px;
                color: var(--av-nav-primary);
            }
            &__links {
                margin-bottom: 56px;
                li {
                    margin-bottom: 16px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    a,
                    button {
                        @mixin paragraph;
                        font-weight: 600;
                    }
                }
            }
            &__awards {
                display: flex;
                flex-flow: row wrap;
                gap: 32px 24px;
                @media (--viewport-desktop) {
                    margin-bottom: 56px;
                }
                li {
                    img {
                        max-height: 32px;
                        max-width: 104px;
                    }
                    a,
                    button {
                        cursor: pointer;
                        background: none;
                        border: none;
                        outline: none;
                    }

                    .a-picture {
                        height: 48px;
                    }
                }
            }
            &__text-blocks {
                display: flex;
                flex-flow: row wrap;
                justify-content: space-between;
                margin-top: 56px;
                @media (--viewport-desktop) {
                    margin-top: 0;
                }
                &__item {
                    @mixin colls 12;
                    @media (--viewport-mobile-wide) {
                        @mixin colls 6;
                        padding-inline-end: 16px;
                    }
                    @media (--viewport-desktop) {
                        @mixin colls 12;
                        padding: 0;
                    }
                    &__title {
                        @mixin lead;
                        color: var(--av-nav-primary);
                        margin-bottom: 8px;
                        font-weight: 600;
                    }
                    &__content {
                        @mixin paragraph;
                        color: var(--av-fixed-secondary);
                    }
                    &.first {
                        margin-bottom: 56px;
                        @media (--viewport-mobile-wide) {
                            margin-bottom: 0;
                        }
                        @media (--viewport-desktop) {
                            margin-bottom: 56px;
                        }
                    }
                }
            }
        }
    }

    &:deep(.a-accordion) {
        &_type_is-simple {
            .a-accordion {
                &__title {
                    @mixin paragraph;
                    color: var(--av-nav-primary);
                    padding: 16px 20px;
                    user-select: none;
                }
                &__icon {
                    margin-inline-end: 12px;
                }
                &__item {
                    &_active {
                        .a-accordion__icon {
                            transform: rotate(180deg);
                        }
                    }
                }
                &__content-wrapper {
                    @mixin body;
                    color: var(--av-fixed-secondary);
                    padding: 8px 0 24px;

                    @media (--viewport-mobile-wide) {
                        padding: 8px 40px 16px;
                    }
                }
            }
        }

        &__content {
            @mixin paragraph;
        }
    }
}
</style>
