<template>
    <div class="resource-search-pagination">
        <div v-if="totalPages > maxVisiblePages && firstVisiblePage > 1" class="resource-first-page page-cell">
            <el-select
                v-if="firstVisiblePage > 2"
                v-model="selectedPage"
                name="page-upper-range"
                popper-class="resource-page-dropdown"
                :popper-max-width="popperSize.w"
                :popper-max-height="popperSize.h"
            >
                <el-option
                    v-for="page in lowerRange"
                    :key="page"
                    class="resource-search-page-range-option"
                    :value="page"
                    :hide-on-resize="isDesktop"
                    :label="page"
                    force-visible
                >
                    <a :href="getPageURL(page)" @click="($event) => setPage(page, $event)">{{ page }}</a>
                </el-option>
            </el-select>
            <div class="page-number">
                <a :href="getPageURL(1)" @click="($event) => setPage(1, $event)">1</a>
            </div>
        </div>

        <div
            v-for="(page, i) in visiblePages"
            :key="page"
            :data-page="page"
            class="resource-page page-cell"
            :class="{
                'resource-page--first': i === 0,
                'resource-page--last': i === (visiblePages.length - 1),
            }"
        >
            <a :href="getPageURL(page)" @click="($event) => setPage(page, $event)">{{ page }}</a>
        </div>

        <div v-if="currentPage !== totalPages && lastVisiblePage !== totalPages" class="resource-last-page page-cell">
            <div class="page-number">
                <a :href="getPageURL(totalPages)" @click="($event) => setPage(totalPages, $event)">{{ totalPages }}</a>
            </div>
            <el-select
                v-if="lastVisiblePage !== totalPages - 1"
                v-model="selectedPage"
                name="page-upper-range"
                popper-class="resource-page-dropdown resource-page-dropdown-last"
                :hide-on-resize="isDesktop"
                :popper-max-width="popperSize.w"
                :popper-max-height="popperSize.h"
            >
                <el-option
                    v-for="page in upperRange"
                    :key="page"
                    class="resource-search-page-range-option"
                    :value="page"
                    :label="page"
                    force-visible
                >
                    <a :href="getPageURL(page)" @click="($event) => setPage(page, $event)">{{ page }}</a>
                </el-option>
            </el-select>
        </div>
    </div>
</template>

<script lang="ts">
import breakpoint from '@core/mixins/breakpoint.js';
import commonUtils from '@utils/common';

const MAX_VISIBLE_PAGES = 5;
const PAGES_OFFSET = 2;

export default {
    name: 'ResourceSearchPagination',

    components: {
        ElSelect: () => import('@uikit/ui-kit/packages/select'),
        ElOption: () => import('@uikit/ui-kit/packages/option'),
    },

    mixins: [breakpoint],

    props: {
        totalPages: {
            type: Number,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        currentPage: {
            type: Number,
            required: true,
        },
    },

    emits: ['change'],

    data: () => ({
        selectedPage: 0,
    }),

    computed: {
        locale() {
            return this.$route?.params.locale || 'en-us';
        },

        popperSize() {
            return { w: '66px', h: '256px' };
        },

        maxVisiblePages() {
            return Math.min(this.totalPages, MAX_VISIBLE_PAGES);
        },

        firstVisiblePage() {
            if (this.totalPages <= this.maxVisiblePages) return 1;

            if (this.currentPage === this.totalPages) return this.currentPage - this.maxVisiblePages + 1;

            return Math.max(this.currentPage - PAGES_OFFSET, 1);
        },

        lastVisiblePage() {
            if (this.currentPage <= this.maxVisiblePages / 2 + 1) return this.maxVisiblePages;

            const res = this.currentPage + PAGES_OFFSET;

            return Math.min(res, this.totalPages);
        },

        visiblePages() {
            const diff = this.lastVisiblePage - this.firstVisiblePage;

            const rangeMoreThanAvailable =
                this.lastVisiblePage > this.maxVisiblePages &&
                diff < (this.maxVisiblePages - 1) &&
                (this.lastVisiblePage - this.maxVisiblePages + 1) > 0;

            // fixing range with last visible page
            if (rangeMoreThanAvailable) {
                return commonUtils.range(this.lastVisiblePage - this.maxVisiblePages + 1, this.lastVisiblePage);
            }

            return commonUtils.range(this.firstVisiblePage, this.lastVisiblePage);
        },

        lowerRange() {
            return commonUtils.range(2, this.firstVisiblePage - 1);
        },

        upperRange() {
            return commonUtils.range(this.lastVisiblePage + 1, this.totalPages - 1).reverse();
        },
    },

    watch: {
        selectedPage(newValue) {
            if (!newValue) return;
            this.setPage(newValue);
        },
        visiblePages: {
            handler() {
                // hack to highlight current page
                this.$nextTick(() => this.setCurrentPageClass());
            },
            immediate: true,
        },
    },

    methods: {
        setCurrentPageClass() {
            if (typeof document === 'undefined') return;

            const cells = document.querySelectorAll('.resource-page');

            cells.forEach((cell: any) => {
                const currentClass = 'resource-page--current';
                const page = parseInt(cell.dataset.page, 10);

                if (page === this.currentPage) {
                    cell.classList.add(currentClass);
                } else {
                    cell.classList.remove(currentClass);
                }
            });
        },

        setPage(pageNumber, event = null) {
            event?.preventDefault();

            if (this.currentPage === pageNumber) return;

            this.$emit('change', pageNumber);
        },

        getPageURL(page) {
            let path = this.$route.path.split('/page/')[0];
            if (path.endsWith('/')) path = path.slice(0, -1);
            return page > 1 ? `${path}/page/${page}/` : `${path}/`;
        },
    },
};
</script>

<style lang="postcss" scoped>
.resource-search-pagination {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 8px;
    margin: 80px 0;
    padding: 0 36px;

    a {
        pointer-events: none;
        font-weight: 500;
        font-size: 14px;
        line-height: 34px;
    }

    @media (--viewport-desktop-wide) {
        margin-top: 104px;
    }

    .el-select {
        width: 32px;
    }

    :deep(.el-input__wrapper) {
        display: none;
    }

    :deep(.el-input__container) {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.page-cell {
    background: rgba(255, 255, 255, 0.0001);
    border: 1px solid rgba(64, 139, 234, 0.15);
    border-radius: 4px;
    height: 32px;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    a {
        pointer-events: all;
        font-weight: 500;
        font-size: 14px;
        line-height: 32px;
        width: 100%;
        color: var(--av-nav-primary);
    }
}

.resource-page {
    width: 32px;
    pointer-events: none;

    &--current {
        background: var(--av-brand-secondary-accent);
        cursor: default;
    }

    &--first {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    &--last {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}

.resource-first-page,
.resource-last-page {
    a {
        text-align: center;
        width: 30px;

        @media (--viewport-tablet) {
            width: 56px;
        }
    }
}

.resource-first-page {
    &:deep(.el-input__container) {
        border: 1px solid rgba(64, 139, 234, 0.15);
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-inline-start: 0;
    }
}

.resource-last-page {
    &:deep(.el-input__container) {
        border: 1px solid rgba(64, 139, 234, 0.15);
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-inline-end: 0;
    }
}

:deep(.el-select-dropdown__item a) {
    flex-shrink: 0;
}
</style>

<style lang="postcss">
.resource-page-dropdown .el-select-dropdown__list {
    overflow-x: hidden;
}

.resource-search-page-range-option {
    justify-content: center;
    width: 66px;
    overflow-x: hidden;
    @mixin paragraph;

    a {
        flex-shrink: 0;
        color: var(--av-fixed-secondary);
    }
}

.page-number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
}

.resource-page-dropdown-last {
    transform: translateX(-33px);
}
</style>
