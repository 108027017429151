import { render, staticRenderFns } from "./sapas.vue?vue&type=template&id=23d23790&scoped=true"
import script from "./sapas.vue?vue&type=script&lang=js"
export * from "./sapas.vue?vue&type=script&lang=js"
import style0 from "./sapas.vue?vue&type=style&index=0&id=23d23790&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23d23790",
  null
  
)

export default component.exports