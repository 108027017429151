/* eslint-disable max-len */
import Vue from 'vue';
import SCardsSmall from '@core/slices/pages/cards-small/cards-small.vue';
import { Icon as CardsSmallIcon, Logo as CardsSmallLogo } from '@core/slices/pages/cards-small/mock.js';

Vue.component('SCardsSmall', SCardsSmall);

export const Figma = 'https://www.figma.com/design/SxYPthnMMnpIFdM8FAy3KT/01-2-UI-Kits---Website-UI?node-id=13361-9847&t=2XkN6La7v9gz0nyg-4';

export const Default = {
    args: {
        translations: {
            title: 'Use Cases',
            'slices.0.label': 'System Builders',
            'slices.1.label': 'HDD/SSD Vendors',
            'slices.2.label': 'System Integrators',
            'slices.3.label': 'Storage Vendors',
            'slices.4.label': 'ISV/ Cloud Partners',
            'slices.5.label': 'Custom Development',
            'slices.6.label': 'Custom Development',
        },
        background: 'light-blue',
        type: 'tile',
        useSmallTabs: false,
        title: 'title',
        sliderOptions: {
            speed: 300,
        },
        slices: [
            {
                label: 'slices.0.label',
                anchorId: 'one',
                name: 's-cards-small',
                data: CardsSmallIcon.args,
            },
            {
                label: 'slices.1.label',
                name: 's-cards-small',
                data: CardsSmallLogo.args,
            },
            {
                label: 'slices.2.label',
                name: 's-cards-small',
                data: CardsSmallIcon.args,
            },
            {
                label: 'slices.3.label',
                name: 's-cards-small',
                data: CardsSmallLogo.args,
            },
            {
                label: 'slices.4.label',
                name: 's-cards-small',
                data: CardsSmallIcon.args,
            },
            {
                label: 'slices.5.label',
                name: 's-cards-small',
                data: CardsSmallLogo.args,
            },
            {
                label: 'slices.6.label',
                name: 's-cards-small',
                data: CardsSmallIcon.args,
            },
        ],
    },
};
