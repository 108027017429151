<template>
    <div class="a-modal-gallery" :class="{'no-caption': !caption, 'no-nav': !isNav}">
        <div v-if="isNav" class="nav" :class="{'no-caption': !caption}">
            <button
                v-if="prevId"
                v-modal="prevId"
                class="button"
                data-close
                type="button prev"
            >
                <a-glyph class="glyph" name="chevron-big-left" />
            </button>
            <button
                v-if="nextId"
                v-modal="nextId"
                class="button next"
                data-close
                type="button"
            >
                <a-glyph class="glyph" name="chevron-big-right" />
            </button>
        </div>
        <p v-if="caption" :class="{'no-nav': !isNav}" class="caption">
            {{ truncateCaption(caption) }}
        </p>
    </div>
</template>

<script>
import { truncate } from 'lodash';
import AGlyph from '@core/components/glyph/glyph.vue';
import Modal from '@core/directives/modal.js';

export default {
    name: 'AModalGallery',

    components: { AGlyph },

    directives: { Modal },

    props: {
        caption: {
            type: String,
            default: undefined,
        },
        prevId: {
            type: String,
            default: undefined,
        },
        nextId: {
            type: String,
            default: undefined,
        },
    },
    computed: {
        isNav() {
            return this.prevId && this.nextId;
        },
    },
    methods: {
        truncateCaption(caption) {
            return truncate(caption, { length: 243 });
        },
    },
};
</script>

<style lang="postcss" scoped>
.a-modal-gallery {
    @mixin colls 12;
    position:fixed;
    inset-inline-end: 0;
    inset-inline-start: 0;
    bottom: 0;
    display: flex;
    flex-flow: row wrap;
    background: var(--av-fixed-secondary);
    padding: 16px;
    color: var(--av-fixed-white);
    user-select: none;
    @media (--viewport-mobile-wide) {
        align-items: center;
        justify-content: center;
        flex-flow: row nowrap;
        gap: 32px;
    }
    @media (--viewport-tablet) {
        @mixin colls 10;
        position: absolute;
        bottom: 24px;
        padding: 0;
        margin: 0 auto;
        min-height: 96px;
        background: none;
    }
    @media (--viewport-desktop-wide) {
        max-width: 960px;
        margin: 24px auto 0;
    }
    .nav {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;
        order: 2;
        margin: 16px auto 0;
        @media (--viewport-mobile-wide) {
            order: 1;
            margin: 0;
        }
        &.no-caption {
            margin: 0 auto;
        }
        .button {
            display: flex;
            border: 1px solid;
            border-radius: 24px;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            cursor: pointer;
            transition: all 0.3s;
            @media (--viewport-tablet) {
                width:48px;
                height:48px;
            }
            &:hover {
                background: var(--av-fixed-secondary);
            }
            :deep(.a-glyph) {
                fill: var(--av-fixed-white);
            }
        }
    }
    .caption {
        @mixin paragraph;
        flex-grow: 1;
        order: 1;
        @media (--viewport-mobile-wide) {
            order: 2;
        }
        &.no-nav {
            @media (--viewport-tablet) {
                width:100%;
                text-align:center;
            }
        }
    }
}
</style>
