<template>
    <div class="s-side-menu-content">
        <template v-if="type === 'tabs' && activeTabSlice">
            <component :is="activeTabSlice.name" v-bind="activeTabSlice.data" />
        </template>
        <template v-else-if="type === 'links'">
            <component
                :is="slice.name"
                v-for="(slice, index) in slices"
                v-bind="slice.data"
                :id="slice.info.id"
                :key="index"
                :ref="slice.info.id"
            />
        </template>
        <template v-else-if="type === 'combined'">
            <div v-for="(item, index) in slices" :key="index">
                <template v-if="activeElement === item.info.id">
                    <component
                        :is="child.name"
                        v-for="(child, childIndex) in item.slices"
                        v-bind="child.data"
                        :id="child.info.id"
                        :key="childIndex"
                    />
                </template>
            </div>
        </template>
    </div>
</template>

<script>
import SDocumentation from '@core/slices/pages/documentation/documentation.vue';

export default {
    name: 'SSideMenuContent',

    components: {
        SDocumentation,
    },

    props: {
        type: {
            type: String,
            default: 'tabs',
            validator: (value) => ['tabs', 'links', 'combined'].includes(value),
        },

        slices: {
            type: Array,
            default: () => [],
        },

        activeElement: {
            type: String,
            default: '',
        },
    },

    computed: {
        activeTabSlice() {
            return this.slices.find((x) => x.info.id === this.activeElement);
        },
    },
};
</script>

<style lang="postcss">
.s-side-menu-content {
    @media (--viewport-desktop) {
        .s-slice:first-child {
            padding-top: 0;
        }
    }

    .a-container {
        padding: 0;
    }
}
</style>
