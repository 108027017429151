/* eslint-disable max-len */
export default {
    title: 'Monthly digest from the Acronis Blog',
    articlesTitle: 'Articles',
    articles: [
        {
            id: 1,
            image_id: '2e51a667cfebc75bbefc2bf62464e0f1',
            title: 'How the New Acronis #CyberFit Academy Empowers Partners asdasd…',
            excerpt: 'As the novel coronavirus/COVID-19 continues to spread, impacting individuals, organizations, and communities across the globe, we want to share how Acronis is responding to the pandemic.',
            link: '#article1',
        },
        {
            id: 2,
            image_id: '2e51a667cfebc75bbefc2bf62464e0f1',
            title: 'New update adds vulnerability assessments to Acronis True …',
            excerpt: 'Working from home has become a critical part of containing the virus, but for small to mid-size businesses tackling remote work for the first time, there are security considerations to keep in mind.',
            link: '#article2',
        },
        {
            id: 3,
            image_id: '2e51a667cfebc75bbefc2bf62464e0f1',
            title: 'New update adds vulnerability assessments to Acronis True …',
            excerpt: 'With the coronavirus on the verge of being declared a global pandemic and thousands dead in its wake, there are sick attempts by criminals to scam unsuspected victims to profit from the illness.',
            link: '#article3',
        },
        {
            id: 4,
            image_id: '2e51a667cfebc75bbefc2bf62464e0f1',
            title: 'Looking Forward to Better Days',
            excerpt: 'Travel may be restricted and conferences canceled, but this crisis will eventually pass. To give us something to look forward to, let’s look at the session tracks for the 2020 Acronis Global Cyber Summit.',
            link: '#article4',
        },
    ],
    insights: {
        title: 'Insights, trends, and analysis',
        buttonLink: '#',
        buttonText: 'Find a story',
    },
    footerMenu: [
        {
            title: 'Customer Service',
            link: '#footer1',
        },
        {
            title: 'Send Feedback',
            link: '#footer2',
        },
        {
            title: 'Manage Subscriptions',
            link: '#footer3',
        },
        {
            title: 'Company Blog',
            link: '#footer4',
        },
    ],
    socialLinks: [
        {
            link: '#facebook',
            name: 'facebook',
        },
        {
            link: '#twitter',
            name: 'twitter',
        },
        {
            link: '#linkedin',
            name: 'linkedin',
        },
        {
            link: '#reddit',
            name: 'reddit',
        },
        {
            link: '#rss',
            name: 'rss',
        },
    ],
    addressText: `© ${new Date().getFullYear()} Acronis International GmbH.<br />
                Rheinweg 9, 8200 Schaffhausen, Switzerland.<br />
                © All rights reserved.`,
    noticeText: `Your information is used in accordance with our <a title="Acronis Privacy Statement">privacy statement</a>.
                You receive this email because you are subscribed for a blog newsletter.`,
};
