<template>
    <div v-if="product.opened" class="s-card-professional-services">
        <product-feature :product="product" />
        <div class="s-card-professional-services__cta">
            <div v-for="(link, linkIndex) in product.links" :key="`link-${linkIndex}`" class="s-card-professional-services__cta-item">
                <a-button
                    v-if="link.type === 'button' && link.text"
                    v-bind="link"
                    :glyph="link.glyph || 'long-arrow'"
                    :to="setLocale(link.url)"
                    :target="link.target || '_blank'"
                    :event="{ doNotSendGA: true }"
                />
                <a-link
                    v-if="link.type !== 'button' && link.text"
                    v-bind="link"
                    :to="setLocale(link.url)"
                    :target="link.target || '_blank'"
                />
            </div>
        </div>
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import ALink from '@core/components/link/link.vue';
import mixin from '../mixin.js';
import productFeature from '../step-2/product-feature.vue';

export default {
    name: 'SCardProfessionalServices',

    components: {
        AButton,
        ALink,
        productFeature,
    },

    mixins: [mixin],

    props: {
        /**
         * Settings
         */
        settings: {
            type: Object,
            default: null,
        },

        /**
         * Product
         */
        product: {
            type: Object,
            default: null,
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-card-professional-services {
    padding: 0 32px;

    &__cta {
        display: flex;
        margin: 24px -32px 0;
        padding: 24px 32px 0;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid var(--av-brand-light);

        &-item {
            @mixin paragraph-accent;
            color: var(--av-fixed-secondary);

            .a-link,
            .a-button {
                min-width: 196px;

                &:deep(.a-glyph) {
                    fill: var(--av-brand-secondary);
                }
            }

            &-item {
                &:first-child {
                    @mixin title;
                    height: 48px;
                    overflow: hidden;
                    padding: 8px 0 0;
                    align-items: center;
                    white-space: nowrap;
                    color: var(--av-fixed-secondary);
                }
            }
        }
    }
}
</style>
