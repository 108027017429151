<template>
    <div class="s-product-info">
        <div v-if="product.name" class="title">
            <div>
                {{ product.name }}
                <a-glyph name="chevron-down" />
            </div>
            <card-action v-if="product.editions" :settings="settings" :product="product" />
        </div>
        <p v-if="isVisible" class="description" v-html="product.description" />

        <template v-if="product.added && !product.opened">
            <div v-if="hasEditions(product)" class="editions">
                <template v-if="['workload-edition', 'workload-package'].includes(product.card)">
                    <p v-for="(edition, editionIndex) in editionAddedList(product.id)" :key="`edition-${editionIndex}`" class="edition">
                        <span>
                            <i>{{ setTranslate('Quantity:') }}</i>
                            <b>{{ editionQuantity(edition) }}</b>
                        </span>
                        <span>
                            <i v-html="edition.name" />
                            <b v-html="editionPeriod(product, edition, 'short')" />
                        </span>
                    </p>
                </template>
            </div>
            <template v-else>
                <div v-if="product.card === 'onboarding-service'" class="items">
                    <p class="item">
                        <span>
                            <i>{{ setTranslate('Quantity:') }}</i>
                            <b>{{ product.selectedQuantity }}</b>
                        </span>
                        <span v-html="product.perpetual.period" />
                    </p>
                </div>
                <div v-if="product.card === 'disaster-recovery'" class="items">
                    <p class="item">
                        <span v-html="product.period" />
                        <span v-for="(item, driIndex) in product.instance.slice(2)" :key="`dri-${driIndex}`">
                            {{ item.name.replace('@value', '') }}&nbsp;
                            <b v-if="item.list">{{ item.list[product.selectedPeriod] }}</b>
                        </span>
                    </p>
                </div>
                <div v-if="product.card === 'cloud-storage'" class="items">
                    <p class="item">
                        <span>
                            <i>{{ setTranslate('License period:') }}</i>
                            <b>{{ cloudStoragePeriod(product) }}</b>
                        </span>
                        <span>
                            <i>{{ setTranslate('Size:') }}</i>
                            <b>{{ cloudStorageTitle(product) }}</b>
                        </span>
                        <span v-for="(addon, addonIndex) in product.addons" :key="`addon-${addonIndex}`">
                            <span v-if="addon.added">{{ addon.title.replace(' + $XX.XX', '') }}</span>
                        </span>
                    </p>
                </div>
            </template>
        </template>
        <div class="toggler" @click="showMoreInformation(section, product)" />
    </div>
</template>

<script>
import AGlyph from '@core/components/glyph/glyph.vue';
import mixin from '../mixin.js';
import cardAction from './card-action.vue';

export default {
    name: 'SProductInfo',

    components: {
        AGlyph,
        cardAction,
    },

    mixins: [mixin],

    props: {
        /**
         * Settings
         */
        settings: {
            type: Object,
            default: null,
        },

        /**
         * Section
         */
        section: {
            type: Object,
            default: null,
        },

        /**
         * Product
         */
        product: {
            type: Object,
            default: null,
        },
    },

    computed: {
        isAvailable() {
            return !this.product.opened && this.product.added;
        },

        isVisible() {
            return this.product.description && (this.product.selected || !this.product.added || this.product.opened);
        },
    },

    methods: {
        showMoreInformation(section, product) {
            const isOpened = product.opened;
            this.productClose(section.id);

            if (!isOpened) {
                this.productOpen(product.id);
            }
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-product-info {
    padding: 24px 32px 0;

    .title {
        @mixin title-accent;
        display: flex;
        justify-content: space-between;
        color: var(--av-fixed-secondary);

        .a-glyph {
            margin-inline-start: 8px;
            vertical-align: baseline;
        }
    }

    .description {
        @mixin paragraph;
        width: 488px;
        margin: 8px 0 0;
        color: var(--av-fixed-secondary);
    }

    .editions {
        @mixin body;
        &:not(:first-child) {
            margin: 16px 0 0;
        }
    }

    .edition {
        span {
            &:not(:first-child) {
                padding-inline-start: 8px;
            }
        }

        b {
            margin-inline-start: 4px;
        }
    }

    .item,
    .items,
    .edition {
        i {
            font-style: normal;
        }
        b {
            font-weight: 600;
        }
    }

    .items {
        @mixin body;
        margin: 16px 0 0;
    }

    .item {
        span {
            padding: 0;
            padding-inline-end: 16px;
            white-space: nowrap;
            display: inline-block;

            b {
                padding-inline-start: 4px;
            }
        }
    }

    .toggler {
        top: 0;
        inset-inline-start: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        position: absolute;
    }
}

.s-purchasing-acp__product-opened {
    .s-product-info {
        position: relative;

        .a-glyph__chevron-down {
            transform: rotate(-180deg);
        }
    }
}
</style>
