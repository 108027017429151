import { render, staticRenderFns } from "./fullscreen-popup.vue?vue&type=template&id=cc007bda&scoped=true"
import script from "./fullscreen-popup.vue?vue&type=script&lang=ts"
export * from "./fullscreen-popup.vue?vue&type=script&lang=ts"
import style0 from "./fullscreen-popup.vue?vue&type=style&index=0&id=cc007bda&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc007bda",
  null
  
)

export default component.exports