<template>
    <div v-if="product.features" class="s-product-feature" :class="{ 's-product-feature__short': product.features.isShortFeature }">
        <p v-if="product.features.title">
            {{ product.features.title }}
        </p>
        <div v-if="product.features.list" class="s-product-feature__list">
            <div
                v-for="(feature, featureIndex) in product.features.list"
                :key="`product-feature-${featureIndex}`"
                class="s-product-feature__item"
            >
                <p v-if="feature.item">
                    <a-glyph name="check-icon" />{{ replaceText(feature.item) }}
                </p>
                <p v-if="feature.description">
                    {{ feature.description }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import AGlyph from '@core/components/glyph/glyph.vue';
import mixin from '../mixin.js';

export default {
    name: 'SProductFeature',

    components: {
        AGlyph,
    },

    mixins: [mixin],

    props: {
        /**
         * Settings
         */
        settings: {
            type: Object,
            default: null,
        },

        /**
         * Product
         */
        product: {
            type: Object,
            default: null,
        },

        /**
         * Edition
         */
        edition: {
            type: Object,
            default: null,
        },
    },

    methods: {
        replaceText(str) {
            const selected = this.edition?.selectedQuantity || 1;
            const seats = selected * 5;
            const cloud = selected * 50;
            return str.replace('@XX', `${cloud}`).replace('@valueSeats', seats);
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-product-feature {
    @mixin descriptor;
    padding: 16px 0 0;

    &__list {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
    }

    &__item {
        width: 90%;
        margin: 8px 0 0;
        padding-inline-start: 18px;

        .a-glyph {
            width: 10px;
            height: 10px;
            margin: 0 8px;
            margin-inline-start: -18px;
            vertical-align: baseline;
            fill: var(--av-fixed-secondary);
        }
    }

    &__short {
        .s-product-feature {
            &__item {
                width: 40%;
            }
        }
    }
}
</style>
