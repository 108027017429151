<template>
    <div class="resource-search-item">
        <div class="publish-date">
            {{ resource.published_at }}
        </div>
        <dynamic-link :to="resource.to" class="cover-image">
            <a-picture :link="resource.cover_image_id" :alt="resource.seo_title" fit="cover" />
        </dynamic-link>

        <dynamic-link
            class="resource-type-wrapper"
            :to="resource.type.to"
        >
            <div class="resource-type" :class="{ 'resource-type--gated': resource.is_gated }">
                {{ resource.type.title }}
            </div>
            <div v-if="resource.is_gated" class="gated-label">
                <a-glyph
                    name="opened-eye"
                    size="m"
                    fill="brand-primary"
                    :is-stroke="false"
                />
                <span class="gated-text">{{ commonTranslations.gatedAsset }}</span>
            </div>
        </dynamic-link>

        <dynamic-link class="title" :to="resource.to">
            {{ resource.title }}
        </dynamic-link>
    </div>
</template>

<script lang="ts">
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import APicture from '@core/components/picture/picture.vue';

export default {
    name: 'ResourceSearchItem',

    components: {
        AGlyph,
        DynamicLink,
        APicture,
    },

    props: {
        resource: {
            type: Object as any,
            required: true,
        },
        commonTranslations: {
            type: Object as any,
            required: true,
        },
    },
};
</script>

<style lang="postcss" scoped>
.resource-search-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    width: 328px;

    @media (--viewport-mobile-wide) {
        width: 224px;
        margin-bottom: 8px;
    }

    @media (--viewport-desktop) {
        width: 228px;
    }

    @media (--viewport-desktop-wide) {
        width: 275px;
    }

    @media (--viewport-desktop-large) {
        width: 296px;
    }
}

.publish-date {
    @mixin caption;
    color: var(--av-nav-primary);
    height: 16px;
}

.cover-image {
    width: 100%;
    height: 148px;
    background: linear-gradient(0deg, rgba(0, 32, 77, 0.2), rgba(0, 32, 77, 0.2));
    border-radius: 6px;
    overflow: hidden;

    @media (--viewport-desktop-large) {
        height: 168px;
    }

    &:deep(.a-picture) {
        height: 100%;
    }

    &:deep(.a-picture__img) {
        width: 100%;
        height: 100%;
    }
}

.resource-type-wrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;
    justify-content: space-between;
    width: 100%;
}

.resource-type {
    @mixin note-heading;
    background: var(--av-brand-secondary-light);
    padding: 0 8px;
    border-radius: 4px;
    text-align: center;
    color: var(--av-nav-secondary);

    &--gated {
        flex-shrink: 0;
        background: var(--av-brand-primary);
        color: var(--av-fixed-white);
    }
}

.gated-label {
    display: flex;
    align-items: center;
    gap: 4px;

    &:deep(svg) {
        width: 16px;
        height: 16px;
    }
}

.gated-text {
    @mixin note-heading;
    color: var(--av-brand-primary);
}

.title {
    @mixin paragraph;
    font-weight: 500;
    color: var(--av-fixed-primary);

    @media (--viewport-tablet) {
        @mixin body;
        font-weight: 500;
    }

    @media (--viewport-desktop) {
        @mixin paragraph;
        font-weight: 500;
    }

    &:hover {
        color: var(--av-brand-primary);
    }
}
</style>
