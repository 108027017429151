<template>
    <div v-if="price" class="s-product-cta">
        <div class="s-product-cta-item" v-html="setCurrency(price)" />
        <div class="s-product-cta-item">
            <template v-if="product.added">
                {{ setTranslate('Added') }}
                <a-glyph class="a-glyph-added" name="protection" />
            </template>
            <template v-else>
                <a-button
                    v-if="product.opened && !product.added"
                    glyph="arrow-long"
                    :text="setTranslate('Add to cart')"
                    :event="{ doNotSendGA: true }"
                    @click="productAdd(product)"
                />
            </template>
        </div>
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import mixin from '../mixin.js';

export default {
    name: 'SProductCTA',

    components: {
        AGlyph,
        AButton,
    },

    mixins: [mixin],

    props: {
        /**
         * Settings
         */
        settings: {
            type: Object,
            default: null,
        },

        /**
         * Product
         */
        product: {
            type: Object,
            default: null,
        },
    },

    computed: {
        price() {
            return this.productPrice(this.product);
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-product-cta {
    @mixin paragraph;
    display: flex;
    padding: 24px 32px 0;
    flex-direction: row;
    justify-content: space-between;

    &:deep(.a-glyph) {
        fill: var(--av-brand-secondary);
    }

    &-item {
        @mixin paragraph-accent;
        color: var(--av-fixed-secondary);

        .a-glyph-added {
            margin: 0 0 2px;
            margin-inline-start: 8px;
            vertical-align: middle;
            fill: var(--av-fixed-success);
        }

        .a-link,
        .a-button {
            min-width: 196px;
        }
    }
}

.s-purchasing-acp__product-opened {
    .s-product-cta {
        display: flex;
        margin: 24px 0 0;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid var(--av-brand-light);

        .a-button {
            .a-glyph {
                fill: var(--av-brand-secondary);
            }
        }

        &-item {
            &:first-child {
                @mixin title;
                height: 48px;
                overflow: hidden;
                padding: 8px 0 0;
                align-items: center;
                white-space: nowrap;
                color: var(--av-fixed-secondary);
            }
        }
    }
}
</style>
