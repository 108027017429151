<template>
    <div v-tabs="{ options }" class="a-tabs" :class="className">
        <div class="a-tabs__header" data-tabs-header :class="{ noBottomSpacing, noTopSpacing }">
            <div class="a-tabs__list-wrapper">
                <ul class="a-tabs__list-wrapper__list">
                    <li
                        v-for="(tab, i) in tabs"
                        :key="i"
                        class="a-tabs__item"
                        @click="handleClick(i)"
                    >
                        <button
                            :id="tab.anchorId"
                            class="a-tabs-item"
                            type="button"
                            data-tab-switcher
                        >
                            <a-link v-if="tab.anchorId" class="a-tabs-item__link" :to="`${url}#${tab.anchorId}`" />
                            <span v-if="tab.labelIcon" class="a-tabs-item__icon-container">
                                <a-icon class="a-tabs-item__icon" :number="tab.labelIcon" :icon-alt="tab.labelIconAlt" />
                            </span>
                            <span class="a-tabs-item__label">{{ tab.label }}</span>
                            <span v-if="tab.translatedLabel" class="a-tabs-item__translated-label">
                                {{ tab.translatedLabel }}
                            </span>
                        </button>
                    </li>
                </ul>
                <div class="a-tabs__underline" data-tabs-underline />
            </div>
        </div>
        <div class="a-tabs__content">
            <slot />
        </div>
    </div>
</template>

<script>
import AIcon from '@core/components/icon/icon.vue';
import ALink from '@core/components/link/link.vue';
import Tabs from '@core/directives/tabs.js';

export default {
    name: 'ATabs',
    components: {
        ALink,
        AIcon,
    },
    directives: {
        Tabs,
    },
    props: {
        noTopSpacing: {
            type: Boolean,
            default: false,
        },
        noBottomSpacing: {
            type: Boolean,
            default: false,
        },
        useSmallTabs: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: undefined,
        },
        activeTab: {
            type: Number,
            default: undefined,
        },
    },
    emits: ['click'],
    data() {
        const children = this.$slots.default.filter((s) => s.tag);
        const tabsList = children.map((tab) => tab.componentOptions.propsData);
        return {
            url: this.$route ? this.$route.params.pathMatch : '',
            tabs: tabsList,
            options: {
                activeIndex: this.activeTab || this.activeIndex(tabsList),
                speed: this.type === 'tile' ? 300 : null,
            },
        };
    },

    computed: {
        className() {
            const children = this.$slots.default.filter((s) => s.tag);

            return {
                'a-tabs_type_with-icons': this.type === 'with-icons',
                'a-tabs_type_tile': this.type === 'tile',
                'a-tabs_tile-layout_more-than-6-items': this.type === 'tile' && children.length > 6,
                'a-tabs_tile-layout_6-items': this.type === 'tile' && children.length === 6,
                'a-tabs_tile-layout_5-items': this.type === 'tile' && children.length === 5,
                'a-tabs_tile-layout_4-items': this.type === 'tile' && children.length === 4,
                'a-tabs_tile-layout_3-items': this.type === 'tile' && children.length === 3,
                'a-tabs_normal-layout_less-than-4-items': this.type !== 'tile' && children.length <= 4 && !this.useSmallTabs,
                'a-tabs_normal-layout_more-than-5-items': this.type !== 'tile' && (children.length >= 5 || this.useSmallTabs),
            };
        },
    },

    updated() {
        if (Number.isInteger(this.activeTab)) {
            this.$root.$emit('tabChangeActive', {
                activeTabIndex: this.activeTab,
                hash: 0,
            });
        }
    },

    mounted() {
        window.addEventListener('hashchange', this.handleHashChange);
    },
    beforeDestroy() {
        window.removeEventListener('hashchange', this.handleHashChange);
    },
    methods: {
        activeIndex(tabsList) {
            let windowHash = '';
            if (typeof window !== 'undefined') {
                windowHash = window.location.hash;
            }
            const hash = this.$route ? this.$route.hash : windowHash;
            return this.hashIndex(tabsList, hash);
        },
        hashIndex(tabsList, hash) {
            const index = tabsList.findIndex(({ anchorId }) => anchorId === hash.slice(1));
            return index >= 0 ? index : 0;
        },
        handleHashChange() {
            const tabId = this.activeIndex(this.tabs);
            this.$root.$emit('tabChangeActive', {
                activeTabIndex: tabId,
                hash: window.location.hash.slice(1),
            });
        },

        handleClick(i) {
            this.$emit('click', i);
        },
    },
};
</script>

<style lang="postcss" scoped>
.a-tabs {
    &__header {
        margin-top: 56px;
        margin-bottom: 40px;
        overflow-x: auto;
        overflow-y: visible;
        transition: transform 0.3s ease-out;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            height: 0;
            background: rgba(255, 255, 255, 0);
        }
        &.noTopSpacing {
            margin-top: 0;
        }
        &.noBottomSpacing {
            margin-bottom: 0;
        }
    }

    &__list-wrapper {
        position: relative;
        padding-bottom: 2px;
        &::before {
            content: '';
            position: absolute;
            inset-inline-start: 0;
            bottom: 0;
            z-index: 1;
            width: 100%;
            height: 2px;
            background-color: var(--av-brand-secondary-accent);
        }

        &__list {
            position: relative;
            display: flex;
            padding-bottom: 2px;
        }
    }

    .a-tabs__item {
        &:not(:last-of-type) {
            margin-inline-end: 24px;

            @media (--viewport-mobile-wide) {
                margin-inline-end: 40px;
            }
        }
    }
    &__underline {
        display: block;
        background-color: var(--av-nav-primary);
        position: absolute;
        bottom: 0;
        height: 2px;
        transition: left ease-out 0.3s, width ease-out 0.3s;
    }

    .a-tabs-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 0 0 8px;
        background-color: rgba(255, 255, 255, 0);
        border: none;
        cursor: pointer;
        position: relative;
        text-align: center;
        .a-tabs-item__label {
            color: var(--av-brand-primary);
            width: 100%;
            white-space: nowrap;
        }
        &:hover {
            .a-tabs-item__label {
                color: var(--av-brand-secondary);
            }
        }
        &:focus {
            .a-tabs-item__label {
                background: var(--av-brand-lightest);
                color: var(--av-brand-primary);
            }
        }
        &_active {
            .a-tabs-item__label {
                color: var(--av-nav-primary) !important;
            }
            &:focus {
                .a-tabs-item__label {
                    background: none;
                }
            }
        }
        &__icon-container {
            margin-bottom: 24px;
            width: 40px;
            height: 40px;
        }
    }
    &__link {
        top: 0;
        inset-inline-start: 0;
        z-index: 1;
        content: '';
        width: 100%;
        height: 100%;
        cursor: pointer;
        position: absolute;
    }

    &_normal-layout {
        &_more-than-5-items {
            .a-tabs__item {
                &:not(:last-of-type) {
                    margin-inline-end: 16px;
                    @media (--viewport-desktop) {
                        margin-inline-end: 24px;
                    }
                }
            }
            .a-tabs-item__label {
                @mixin body;
                font-weight: 600;
                @media (--viewport-desktop) {
                    @mixin lead;
                }
            }
        }
        &_less-than-4-items {
            .a-tabs__item {
                &:not(:last-of-type) {
                    margin-inline-end: 24px;
                    @media (--viewport-mobile-wide) {
                        margin-inline-end: 40px;
                    }
                }
            }
            .a-tabs-item__label {
                @mixin lead;
                @media (--viewport-desktop) {
                    @mixin title;
                }
            }
        }
    }
    &_type {
        &_with-icons {
            .a-tabs {
                &__item {
                    cursor: pointer;

                    &:not(:last-of-type) {
                        margin-inline-end: 0;
                    }
                }
            }

            .a-tabs-item {
                padding: 0 8px 40px;

                @media (--viewport-desktop-large) {
                    padding: 0 24px 40px;
                }

                .a-tabs-item__label {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                    color: var(--av-nav-primary);
                    white-space: normal;
                }
            }
        }

        &_tile {
            .a-tabs {
                &__content {
                    transition-timing-function: ease-in-out;
                }

                &__underline {
                    display: none;
                }

                &__header {
                    margin: 0 -16px 32px;

                    @media (--viewport-mobile-wide) {
                        margin: 0 -32px 32px;
                    }

                    @media (--viewport-desktop-wide) {
                        margin: 0 -64px 48px;
                    }
                }

                &__list-wrapper {
                    width: 100%;
                    padding: 0 16px;

                    @media (--viewport-mobile-wide) {
                        padding: 0 32px;
                    }

                    @media (--viewport-desktop-wide) {
                        padding: 0 64px;
                    }

                    &::before {
                        display: none;
                    }
                }

                &__item {
                    padding-bottom: 8px;

                    &:not(:last-of-type) {
                        margin-inline-end: 0;

                        .a-tabs-item {
                            border-inline-end: none;
                        }
                    }

                    &:first-of-type {
                        .a-tabs-item {
                            border-top-left-radius: 4px;
                            border-bottom-left-radius: 4px;
                        }
                    }

                    &:last-of-type {
                        .a-tabs-item {
                            border-top-right-radius: 4px;
                            border-bottom-right-radius: 4px;
                        }
                    }
                }
            }

            &:deep(.a-tab) {
                .a-container {
                    padding: 0;
                }
            }

            .a-tabs-item {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 96px;
                padding: 24px;
                background-color: var(--av-fixed-white);
                border: 1px solid var(--av-brand-accent);
                box-shadow: var(--av-shadow-small);
                color: var(--av-brand-secondary);
                transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out;
                .a-tabs-item__label {
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 600;
                    white-space: normal;
                    transition: color 0.3s ease-in-out;
                }
                &:hover {
                    background-color: var(--av-solid-brand-accent);
                    box-shadow: none;
                    color: var(--av-nav-primary);

                    .a-tabs-item__label {
                        color: var(--av-nav-primary);
                    }
                }
                &:focus {
                    .a-tabs-item__label {
                        background: none;
                    }
                }

                &_active {
                    background-color: var(--av-solid-brand-accent);
                    box-shadow: none;

                    &:hover {
                        cursor: default;
                    }

                    &__label {
                        color: var(--av-nav-primary);
                    }
                }
            }
        }
    }

    &_tile-layout {
        &_more-than-6-items {
            .a-tabs {
                &__item {
                    flex: 0 0 50%;

                    @media (--viewport-mobile-wide) {
                        flex: 0 0 23%;
                    }
                }
            }
        }

        &_6-items {
            .a-tabs {
                &__item {
                    flex: 0 0 50%;

                    @media (--viewport-mobile-wide) {
                        flex: 0 0 23%;
                    }

                    @media (--viewport-desktop) {
                        flex: 0 0 16.6%;
                    }
                }
            }
        }

        &_5-items {
            .a-tabs {
                &__item {
                    flex: 0 0 50%;

                    @media (--viewport-mobile-wide) {
                        flex: 0 0 23%;
                    }

                    @media (--viewport-desktop) {
                        flex: 0 0 20%;
                    }
                }
            }
        }

        &_4-items {
            .a-tabs {
                &__item {
                    flex: 0 0 50%;

                    @media (--viewport-mobile-wide) {
                        flex: 0 0 25%;
                    }
                }
            }
        }

        &_3-items {
            .a-tabs {
                &__item {
                    flex: 0 0 50%;

                    @media (--viewport-mobile-wide) {
                        flex: 0 0 33%;
                    }
                }
            }
        }
    }
}
</style>
