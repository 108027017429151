<template>
    <div class="g2-review">
        <div class="review-header">
            <div class="stars">
                <g2-review-star
                    v-for="index in 5"
                    :key="index"
                    :star-id="`g2-review-star-${review.id}-${index}`"
                    :fill-percentage="getFillPercentage(index)"
                />
            </div>
            <div class="review-date">
                {{ publishedAt }}
            </div>
        </div>
        <div class="title">
            {{ review.title }}
        </div>
        <v-clamp
            :max-lines="maxLines"
            class="contents"
        >
            {{ review.contents }}
        </v-clamp>
        <a-link v-if="ctaText" :to="reviewUrl" :text="ctaText" />
        <div class="author-block">
            <div class="author-avatar">
                {{ authorInitials }}
            </div>
            <div class="author-info">
                <div class="author-name">
                    {{ formattedAuthorName }}
                </div>
                <div v-if="review.author.position" class="author-position">
                    {{ review.author.position }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { capitalize } from 'lodash';
import VClamp from 'vue-clamp';
import ALink from '@core/components/link/link.vue';
import { LOCALE_DEFAULT } from '@model/const/locales';
import { formatToLocaleDate } from '@utils/locales';
import G2ReviewStar from './g2-review-star.vue';

export default {
    name: 'G2Review',

    components: {
        ALink,
        G2ReviewStar,
        VClamp,
    },

    props: {
        review: {
            type: Object,
            required: true,
        },

        productName: {
            type: String,
            required: true,
        },

        ctaText: {
            type: String,
            default: undefined,
        },
    },

    computed: {
        locale() {
            return this.$route?.params?.locale || LOCALE_DEFAULT;
        },

        authorInitials() {
            const parts = this.review.author.name.split(/\s+/);
            const initials = [parts[0][0], (parts[1]?.[0] || '')];
            return initials.join('').toUpperCase();
        },

        formattedAuthorName() {
            const parts = this.review.author.name.split(/\s+/);
            const firstName = capitalize(parts[0]);
            const lastName = parts[1]?.[0] ? `${parts[1][0].toUpperCase()}.` : '';
            return `${firstName} ${lastName}`;
        },

        publishedAt() {
            return formatToLocaleDate(this.review.submittedAt, this.locale);
        },

        reviewUrl() {
            return `https://www.g2.com/products/${this.productName}/reviews/${this.productName}-review-${this.review.id}`;
        },

        maxLines() {
            return 8;
        },
    },

    methods: {
        getFillPercentage(starIndex) {
            const rating = this.review.rating;
            if (rating >= starIndex) return 100;
            if (starIndex - rating >= 1) return 0;
            return Math.ceil((rating - Math.floor(rating)) * 100);
        },
    },
};
</script>

<style lang="postcss" scoped>
.g2-review {
    position: relative;
}

.review-header {
    display: flex;
    align-items: center;
    gap: 8px;
}

.stars {
    display: flex;
    align-items: center;

    .g2-review-star {
        transform: scale(0.75);
    }
}

.review-date {
    color: var(--av-fixed-light, rgba(36, 49, 67, 0.70));
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.title {
    margin-top: 16px;
    margin-bottom: 16px;
    color: var(--av-nav-primary, #00204d);
    font-family: 'Open Sans', sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
}

.contents {
    display: inline;
    margin-top: 16px;
    color: var(--av-fixed-secondary, rgba(36, 49, 67, 0.90));
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.a-link {
    &:deep(.a-link__content) {
        color: var(--av-brand-primary);
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }
}

.author-block {
    margin-top: 32px;
    display: flex;
    align-items: center;
    gap: 16px;
}

.author-avatar {
    width: 96px;
    height: 96px;
    border-radius: 50%;
    background: #ff492c;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--av-inversed-primary, #fff);
    font-family: 'Open Sans', sans-serif;
    font-size: 32px;
    line-height: 40px;
    font-weight: 600;
}

.author-name {
    color: var(--text-fixed-secondary, rgba(36, 49, 67, 0.90));
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
}

.author-position {
    color: var(--text-fixed-light, rgba(36, 49, 67, 0.70));
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
}
</style>
