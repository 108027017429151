<template>
    <div class="s-purchasing-acp__configure">
        <a-tabs :active-tab="settings.sectionsActive" @click="changeActiveTab">
            <a-tab v-for="(section, sectionIndex) in listSections()" :key="`section-${sectionIndex}`" :label="section.name">
                <div
                    v-for="(product, productIndex) in list(section)"
                    :key="`configure-product-${productIndex}`"
                    class="s-purchasing-acp__product"
                    :class="{ 's-purchasing-acp__product-opened': product.opened }"
                >
                    <product-info :settings="settings" :section="section" :product="product" />
                    <component
                        :is="product.card"
                        v-if="product.card"
                        class="s-purchasing-acp__product-item"
                        :settings="settings"
                        :product="product"
                    />
                    <product-cta :settings="settings" :product="product" />
                </div>
                <card-add-workload v-if="section.id === 'workloads'" :settings="settings" />
            </a-tab>
        </a-tabs>
        <product-order :settings="settings" />
    </div>
</template>

<script>
import { sortBy, isEmpty } from 'lodash';
import ATab from '@core/components/tabs/tab.vue';
import ATabs from '@core/components/tabs/tabs.vue';
import currency from '@core/mixins/currency.js';
import cloudStorage from '../card/cloud-storage.vue';
import disasterRecovery from '../card/disaster-recovery.vue';
import onboardingService from '../card/onboarding-service.vue';
import professionalServices from '../card/professional-services.vue';
import workloadEdition from '../card/workload-edition.vue';
import workloadPackage from '../card/workload-package.vue';
import mixin from '../mixin.js';
import cardAddWorkload from './card-add-workload.vue';
import productCta from './product-cta.vue';
import productInfo from './product-info.vue';
import productOrder from './product-order.vue';

export default {
    name: 'SStep2',

    components: {
        ATab,
        ATabs,
        cardAddWorkload,
        productOrder,
        productInfo,
        productCta,
        cloudStorage,
        disasterRecovery,
        onboardingService,
        professionalServices,
        workloadEdition,
        workloadPackage,
    },

    mixins: [mixin, currency],

    props: {
        /**
         * Settings
         */
        settings: {
            type: Object,
            default: null,
        },
    },

    mounted() {
        this.ecommerceSendImpressions();
    },

    methods: {
        list(section) {
            if (section.id === 'addons') {
                if (this.microsoft365Added) {
                    return this.productList(section.id).filter((item) => item.id !== 'cloud-storage');
                }
                return this.productList(section.id);
            }

            if (section.id !== 'workloads') {
                return this.productList(section.id);
            }

            const result = this.productList(section.id)
                .map((p) => (p.selected || p.editions.find((i) => i.added) ? { ...p } : []))
                .filter((p) => !isEmpty(p));

            return sortBy(result, ['time']).reverse();
        },

        changeActiveTab(item) {
            this.changeActiveSection(item);
            this.ecommerceSendImpressions();
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-purchasing-acp {
    &__configure {
        display: flex;
        padding: 0 0 102px;
        justify-content: space-between;

        &:deep(.a-tabs) {
            width: 49.306%;
            min-width: 564px;

            &__header {
                margin: 40px 0 24px;
            }
        }
    }

    &__product {
        padding: 0 0 32px;
        user-select: none;
        border-radius: 4px;
        position: relative;
        box-shadow: var(--av-shadow-small);
        border: 1px solid var(--av-brand-light);
        background-color: var(--av-fixed-white);

        &:not(:first-child) {
            margin: 16px 0 0;
        }

        &-opened {
            background-color: var(--av-solid-brand-lightest);
        }
    }
}
</style>
