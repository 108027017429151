import { LOCALES_RTL } from '@model/const/locales';

export const ACRONIS_OG_IMAGE_ID = 'f4684ed71de5899f9c3c471850a0ac45';

// TODO: build a universal function for imageprocessor templates formatting
export function getOgImage(id: string, cdn: string): string {
    const normalized = (id || `@${ACRONIS_OG_IMAGE_ID}`).trim();

    return normalized[0] === '@'
        ? `${cdn}/images/content/${normalized.replace('@', '')}.jpg`
        : normalized;
}

export function getHtmlLang(locale: string): string {
    /*
    * We need to switch between Simplified and Traditional Chinese
    * Full list of lang attribute values here: https://www.w3schools.com/tags/ref_language_codes.asp
    */
    if (locale === 'zh-cn') return 'zh-Hans';
    if (locale === 'zh-tw') return 'zh-Hant';

    return locale.split('-')[0];
}

export function getTextDirection(locale: string): string {
    return LOCALES_RTL.has(locale) ? 'rtl' : 'ltr';
}
