<template>
    <section :id="id" class="s-slice s-slapfive" :class="{ ...basicClassName }">
        <div
            v-if="!loaded"
            v-loading="true"
            class="loader"
            el-loading-size="48"
            el-loading-color="brand-primary"
        />
        <div v-else class="a-container">
            <a-slice-header
                v-if="useHeader"
                :title="sliceTitle"
                :lead="lead"
                :body-text="bodyText"
            />
            <div class="s-slapfive-card">
                <div class="s-slapfive-card__container">
                    <div class="s-slapfive-card__header">
                        <div
                            v-if="company.image"
                            class="s-slapfive-card__header-logo"
                        >
                            <img :src="company.image.url" loading="lazy" alt="" />
                        </div>
                        <div class="header-wrapper">
                            <div v-if="board.headline" class="s-slapfive-card__header-title">
                                {{ board.headline }}
                            </div>
                            <div v-if="!useHeader && board.body" class="s-slapfive-card__header-body">
                                {{ board.body }}
                            </div>
                        </div>
                    </div>
                    <div class="s-slapfive-card__content">
                        <div
                            v-if="board.boardImage"
                            class="s-slapfive-card__picture"
                        >
                            <img :src="board.boardImage.url" loading="lazy" alt="" />
                        </div>
                        <div v-if="isSingleCustomer && hasPhoto(0)" class="s-slapfive-card__picture">
                            <img :src="customerPhoto(0)" loading="lazy" alt="" />
                            <div class="s-slapfive-card__customer-name">
                                {{ customerName(0) }}
                            </div>
                            <div class="s-slapfive-card__customer-title">
                                {{ customerTitle(0) }}
                            </div>
                        </div>
                        <div class="s-slapfive-card__info">
                            <ul class="s-slapfive-card__info-list">
                                <li
                                    v-for="(item, index) in companyInfo"
                                    :key="`info-${index}`"
                                    class="s-slapfive-card__info-item"
                                >
                                    <span class="s-slapfive-card__info-label">{{ item.text }}</span>
                                    <span class="s-slapfive-card__info-text">{{ getCompanyInfo(company[item.id]) }}</span>
                                </li>
                            </ul>
                            <ul class="s-slapfive-card__info-list">
                                <li
                                    v-for="(item, index) in companyProducts"
                                    :key="`product-${index}`"
                                    class="s-slapfive-card__info-item"
                                >
                                    <span class="s-slapfive-card__info-label">{{ item.text }}</span>
                                    <span v-if="item.id !== 'productsOwned'" class="s-slapfive-card__info-text">
                                        {{ getCompanyInfo(company[item.id]) }}
                                    </span>
                                    <ul v-else class="s-slapfive-card__product-list">
                                        <li
                                            v-for="(productName, productIndex) in companyProductsName"
                                            :key="`product-${productIndex}`"
                                            class="s-slapfive-card__product-item"
                                        >
                                            {{ productName }}
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <div v-if="isDesktop" class="s-slapfive-card__description">
                                {{ company.description }}
                            </div>
                        </div>
                    </div>
                    <div v-if="!isDesktop" class="s-slapfive-card__description">
                        {{ company.description }}
                    </div>
                </div>
            </div>
            <div v-if="board && board.stories" class="s-slapfive-slider">
                <a-slider
                    class="s-slapfive-slider__cards"
                    :options="sliderOptions"
                    :class="{ 's-slapfive-slider__show-pagination': showPagination }"
                >
                    <a-slide
                        v-for="(card, index) in board.stories"
                        :key="`a-company-card-slide-${index}`"
                        class="s-slapfive-slider__card"
                    >
                        <a-glyph class="s-slapfive-slider__card-glyph" :name="glyphName(card)" />
                        <div v-if="card.prompt && card.prompt.title" class="s-slapfive-slider__card-title">
                            {{ card.prompt.title }}
                        </div>
                        <div v-if="!isSingleCustomer" class="s-slapfive-slider__card-customer">
                            <img
                                v-if="hasPhoto(index)"
                                class="s-slapfive-slider__card-customer-picture"
                                :src="card.customer.avatarImage.url"
                                loading="lazy"
                                alt=""
                            />
                            <div
                                v-if="card.customer.name || card.customer.title"
                                class="s-slapfive-slider__card-customer-info"
                            >
                                <div class="s-slapfive-slider__card-customer-name">
                                    {{ customerName(index) }}
                                </div>
                                <div class="s-slapfive-slider__card-customer-title">
                                    {{ customerTitle(index) }}
                                </div>
                            </div>
                        </div>
                        <a
                            v-if="card.type === 'Audio'"
                            v-modal="`audio-modal-${index}`"
                            class="a-link s-slapfive-slider__modal-link"
                            :data-has-no-href="true"
                        />
                        <a
                            v-if="card.type === 'Video'"
                            v-modal="`video-modal-${index}`"
                            class="a-link s-slapfive-slider__modal-link"
                            :data-has-no-href="true"
                        />
                        <a
                            v-else-if="card.type === 'Text'"
                            v-modal="`text-modal-${index}`"
                            class="a-link s-slapfive-slider__modal-link"
                            :data-has-no-href="true"
                        />
                    </a-slide>
                </a-slider>
                <div class="s-slapfive-slider__modals">
                    <div
                        v-for="(card, index) in board.stories"
                        :key="`a-company-card-modal-${index}`"
                        class="s-slapfive-slider__modal"
                        :class="`s-slapfive-slider__modal-${glyphName(card)}`"
                    >
                        <a-modal v-if="card.type === 'Audio'" :id="`audio-modal-${index}`">
                            <div class="s-slapfive__audio-container">
                                <div v-if="card.prompt?.title" class="s-slapfive__audio-description">
                                    {{ card.prompt.title }}
                                </div>
                                <a-picture
                                    v-if="board.boardImage?.url"
                                    class="s-slapfive__audio-picture"
                                    :link="board.boardImage.url"
                                />
                                <audio class="a-modal__audio-player" :src="card.media.url" controls />
                            </div>
                        </a-modal>
                        <a-modal v-else-if="card.type === 'Video'" :id="`video-modal-${index}`" type="video">
                            <div class="s-slapfive__video-container">
                                <video class="a-modal__video-player" :src="card.media.url" controls />
                            </div>
                        </a-modal>
                        <a-modal v-else-if="card.type === 'Text'" :id="`text-modal-${index}`">
                            <p v-if="card.prompt?.title" class="a-modal__content-title">
                                {{ card.prompt.title }}
                            </p>
                            <p class="a-modal__content-description">
                                {{ card.text }}
                            </p>
                        </a-modal>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Loading from '@uikit/ui-kit/packages/loading/src/directive';
import { get, uniq } from 'lodash';
import { mapActions } from 'vuex';
import AGlyph from '@core/components/glyph/glyph.vue';
import AModal from '@core/components/modal/modal.vue';
import APicture from '@core/components/picture/picture.vue';
import ASliceHeader from '@core/components/slice-header/slice-header.vue';
import ASlide from '@core/components/slider/slide.vue';
import ASlider from '@core/components/slider/slider.vue';
import Modal from '@core/directives/modal.js';
import breakpoint from '@core/mixins/breakpoint.js';
import contentMixin from '@core/mixins/content.js';
import styleMixin from '@core/mixins/style.js';

export default {
    name: 'SSlapfive',

    components: {
        APicture,
        AGlyph,
        AModal,
        ASliceHeader,
        ASlide,
        ASlider,
    },

    directives: {
        Loading,
        Modal,
    },

    mixins: [contentMixin, styleMixin, breakpoint],

    props: {
        /**
         * Slapfive company
         */
        companyBoard: {
            type: Object,
            default: null,
        },

        /**
         * Slapfive board ID
         */
        boardID: {
            type: String,
            default: 'ckfwx6kp4000o3b9go42p7xe9',
        },

        useHeader: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            board: null,
            company: {},
            loaded: false,
            sliderOptions: {
                loop: false,
                spaceBetween: 16,
                slidesPerView: 'auto',
                allowTouchMove: false,
                showCounter: false,
                showNavigation: true,
            },
            info: [
                {
                    id: 'industry',
                    text: 'Industry:',
                },
                {
                    id: 'size',
                    text: 'Company size:',
                },
                {
                    id: 'location',
                    text: 'Location:',
                },
                {
                    id: 'since',
                    text: 'Customer since:',
                },
            ],
            products: [
                {
                    id: 'productsOwned',
                    text: 'Products used:',
                },
                {
                    id: 'competitorsReplaced',
                    text: 'Competitor replaced:',
                },
            ],
            isSingleCustomer: false,
            showPagination: false,
        };
    },

    computed: {
        sliceTitle() {
            return this.title || this.board.tagline || null;
        },

        companyInfo() {
            return this.info.filter((item) => this.company[item.id]);
        },

        companyProducts() {
            return this.products.filter((item) => this.company[item.id]);
        },

        companyProductsName() {
            return this.company.productsOwned.split(',');
        },
    },

    mounted() {
        this.init();
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.isPaginationVisible);
    },

    methods: {
        ...mapActions({
            slapfiveBoard: 'slapfive/board',
        }),

        async init() {
            this.board = this.companyBoard;

            if (!this.board) await this.getSlapfiveBoard();
            if (!this.board) return;

            const customerAvatarImage = uniq(
                this.board.stories.map((story) => story.customer?.avatarImage?.url),
            );

            if (customerAvatarImage.length === 1 && !this.board.boardImage) {
                this.isSingleCustomer = true;
            }

            this.company = get(this.board.stories[0].customer, 'company', {});

            window.addEventListener('resize', this.isPaginationVisible);
            this.isPaginationVisible();
            this.loaded = true;
        },

        async getSlapfiveBoard() {
            const response = await this.slapfiveBoard(this.boardID);
            if (response && response.status === 200) {
                this.board = response.data.data.board;
            }
        },

        hasPhoto(index) {
            return get(this.board.stories[index], 'customer.avatarImage.url', false);
        },

        customerPhoto(index) {
            return this.board.stories[index].customer.avatarImage.url;
        },

        customerName(index) {
            return this.board.stories[index].customer.name;
        },

        customerTitle(index) {
            return this.board.stories[index].customer.title;
        },

        glyphName(card) {
            return card.type && /Video|Audio/.test(card.type)
                ? card.type.toLowerCase()
                : 'document';
        },

        isPaginationVisible() {
            this.showPagination = false;
            if (!this.board) {
                return;
            }
            if (this.board.stories.length === 1) {
                return;
            }
            if (this.board.stories.length === 2 && !this.isMobile) {
                return;
            }
            if (this.board.stories.length === 3 && this.isDesktop) {
                return;
            }
            if (this.board.stories.length === 4 && ['desktopWide', 'desktopLarge'].includes(this.currentBreakpoint)) {
                return;
            }
            this.showPagination = true;
        },

        getCompanyInfo(str = '') {
            return str.trim().split(',').join(', ');
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-slapfive {
    overflow: hidden;

    .loader {
        min-height: 96px;
    }

    &:deep(.a-slider__nav) {
        display: none;
        margin-top: 24px;
        margin-bottom: 16px;
    }

    &-card {
        display: flex;
        border-radius: 4px;
        flex-direction: column;
        border: 1px solid var(--av-brand-light);
        background-color: var(--av-fixed-white);
        box-shadow: 0 4px 8px rgba(36, 49, 67, 0.1);

        &__container {
            padding: 40px 16px;
            flex-grow: 1;
            width: 100%;
            display: flex;
            flex-direction: column;
        }

        &__header {
            display: flex;
            flex-direction: column;
        }

        &__header-logo {
            width: 296px;
            flex: 0 0 auto;
            margin-inline-end: 16px;
            img {
                width: 100%;
                max-width: 176px;
            }
        }

        &__header-title {
            @mixin lead-accent;
            margin: 16px 0 0;
            color: var(--av-nav-primary);
        }

        &__content {
            margin: 16px 0 0;
            min-height: 186px;
        }

        &__picture {
            max-width: 296px;
            border-radius: 4px;
            img {
                height: auto;
                width: 100%;
                border-radius: 4px;
            }
        }

        &__customer-name {
            @mixin caption-accent;
            margin: 8px 0 0;
            color: var(--av-fixed-secondary);
        }

        &__customer-title {
            @mixin caption;
            color: var(--av-fixed-secondary);
        }

        &__info {
            display: flex;
            flex-wrap: wrap;
            margin: 16px 0 0;
            flex-direction: column;
            color: var(--av-nav-primary);
        }

        &__info-list {
            &:nth-child(2) {
                margin: 8px 0 0;
            }
        }

        &__info-item {
            &:not(:first-child) {
                margin: 8px 0 0;
            }
        }

        &__info-label {
            @mixin paragraph-accent;
            margin-inline-end: 8px;
            display: inline-block;
        }

        &__info-text {
            @mixin body;
            vertical-align: top;
            display: inline-block;
        }

        &__product-item {
            @mixin body;
        }

        &__description {
            @mixin body;
            width: 100%;
            margin: 24px 0 0;
            color: var(--av-fixed-secondary);
        }

        @media (--viewport-tablet) {
            &__container {
                padding: 40px 32px;
            }

            &__header-logo {
                width: 312px;
                img {
                    max-width: 204px;
                }
            }

            &__header-title {
                @mixin title-accent;
                margin: 8px 0 0;
            }

            &__content {
                display: flex;
                flex-wrap: wrap;
            }

            &__picture {
                width: 312px;
                margin-inline-end: 16px;
            }

            &__info {
                flex: 1;
                margin: 0;
            }

            &__product-list {
                margin: 8px 0 0;
            }

            &__description {
                margin: 24px 0 0;
            }
        }

        @media (--viewport-desktop) {
            &__header {
                flex-direction: row;
            }

            &__header-logo {
                width: 227px;
            }

            &__header-title {
                margin: 0;
            }

            &__header-body {
                margin: 0;
            }

            &__content {
                margin: 32px 0 0;
            }

            &__picture {
                width: 227px;
            }

            &__info {
                margin: 0;
                flex-direction: row;
            }

            &__info-list {
                &:nth-child(1) {
                    width: 58%;
                }
                &:nth-child(2) {
                    margin: 0;
                    width: 42%;
                    .s-slapfive-card {
                        &__info-item {
                            &:nth-child(2) {
                                margin-top: 16px;
                            }
                        }
                    }
                }
            }
        }

        @media (--viewport-desktop-wide) {
            &__info-list {
                &:nth-child(1) {
                    width: 48%;
                }
                &:nth-child(2) {
                    width: 52%;
                }
            }

            &__product-list {
                display: flex;
                flex-wrap: wrap;
            }

            &__product-item {
                margin-inline-end: 16px;
            }
        }

        @media (--viewport-desktop-large) {
            &__info {
                gap: 8px;
            }

            &__info-list {
                &:nth-child(1) {
                    width: 40%;
                }
                &:nth-child(2) {
                    width: 58%;
                }
            }
            &__product-item {
                width: 42%;
            }
        }
    }

    &-slider {
        margin: 16px 0 0;

        &:deep(.a-slider) {
            overflow: visible;
        }

        &__cards {
            width: calc(95.126% + var(--container-paddings-mobile));
        }

        &:deep(.a-slide) {
            &.s-slapfive-slider {
                &__card {
                    @mixin paragraph-accent;
                    margin: 0;
                    opacity: 1;
                    width: 271px;
                    display: flex;
                    padding: 24px;
                    border-radius: 4px;
                    flex-direction: column;
                    color: var(--av-brand-secondary);
                    background: var(--av-fixed-white);
                    border: 1px solid var(--av-brand-light);
                    box-shadow: 0 4px 8px rgba(36, 49, 67, 0.1);
                }
            }
        }

        &__show-pagination {
            &:deep(.a-slider__nav) {
                display: flex;
            }
        }

        &__card-glyph {
            width: 40px;
            height: 40px;
            display: block;
            margin: 0 0 16px;
        }

        &__card-customer {
            display: flex;
            margin: auto 0 0;
            padding: 16px 0 0;
            align-items: center;
            color: var(--av-fixed-secondary);
        }

        &__card-customer-picture {
            width: 48px;
            height: 48px;
            min-width: 48px;
            overflow: hidden;
            margin-inline-end: 8px;
            text-indent: 100%;
            border-radius: 50%;
            background: var(--av-branded-icon-solid-lightest);
        }

        &__card-customer-name {
            @mixin caption-accent;
        }

        &__card-customer-title {
            @mixin caption;
            font-weight: 400;
        }

        &__modal-link {
            position: absolute;
            top: 0;
            inset-inline-start: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
        }

        &__modal-video {
            &:deep(.a-modal) {
                &__container {
                    padding: 0;
                    width: auto;
                }
                &__content {
                    animation: none !important;
                }
                &__video-container {
                    width: auto;
                }
                video {
                    max-height: 380px;
                    max-width: 100%;
                }
            }
        }

        &__modal-audio {
            &:deep(.a-modal) {
                &__content {
                    animation: none !important;
                }
                &__container {
                    padding: 0;
                    max-width: 723px;
                    border-radius: 0;
                    background: var(--av-fixed-white);
                }
                &__audio-container {
                    padding: 48px 15px;
                    border-radius: 0;
                }
                &__audio-picture {
                    width: 100%;
                    height: auto;
                }
                audio {
                    width: 100%;
                }
            }
        }

        &__modal-document {
            &:deep(.a-modal) {
                &__container {
                    padding: 0;
                    width: 744px;
                    max-width: 100%;
                    background: var(--av-fixed-white);
                }
                &__content {
                    padding: 48px;
                    animation: none !important;

                    &-title {
                        @mixin title;
                        margin: 0 0 24px;
                        color: var(--av-nav-primary);
                    }
                    &-description {
                        @mixin paragraph;
                        color: var(--av-fixed-primary);
                    }
                }
            }
        }

        @media (--viewport-mobile-wide) {
            &__cards {
                width: calc(93.752% + var(--container-paddings-mobile-wide));
            }
        }

        @media (--viewport-tablet) {
            &__cards {
                width: calc(95.456% + var(--container-paddings-mobile-wide));
            }

            &__modal-video {
                &:deep(.a-modal) {
                    video {
                        max-height: 486px;
                    }
                }
            }

            &__modal-audio {
                &:deep(.a-modal) {
                    &__audio-picture {
                        max-width: 420px;
                    }
                }
            }

            &:deep(.a-slide) {
                &.s-slapfive-slider {
                    &__card {
                        width: 284px;
                    }
                }
            }
        }

        @media (--viewport-desktop) {
            &:deep(.a-slider) {
                overflow: hidden;
            }

            &__cards {
                width: 100%;
            }

            &__modal-video {
                &:deep(.a-modal) {
                    video {
                        max-height: 381px;
                    }
                }
            }

            &__modal-audio {
                &:deep(.a-modal) {
                    &__audio-picture {
                        max-width: 552px;
                        max-height: 310px;
                    }
                }
            }

            &:deep(.a-slide) {
                &.s-slapfive-slider {
                    &__card {
                        width: 309px;
                    }
                }
            }
        }

        @media (--viewport-desktop-wide) {
            &__modal-video {
                &:deep(.a-modal) {
                    video {
                        max-height: 477px;
                    }
                }
            }
            &:deep(.a-slide) {
                &.s-slapfive-slider {
                    &__card {
                        width: 276px;
                    }
                }
            }
        }

        @media (--viewport-desktop-large) {
            &__modal-video {
                &:deep(.a-modal) {
                    video {
                        max-height: 536px;
                    }
                }
            }
            &:deep(.a-slide) {
                &.s-slapfive-slider {
                    &__card {
                        width: 316px;
                    }
                }
            }
        }
    }

    &__audio-container {
        padding: 48px 56px;
        background: var(--av-fixed-white);
    }

    &__audio-description {
        margin-bottom: 24px;
        color: var(--av-nav-primary);
    }

    &__audio-picture {
        margin-bottom: 24px;
        text-align: center;
    }

    .header-wrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
}
</style>
