<template>
    <div class="a-breadcrumbs" :class="className">
        <div class="a-container">
            <div class="a-breadcrumbs__desktop">
                <ul ref="listItem" class="a-breadcrumbs__list">
                    <li v-for="(item, index) in links" :key="index" class="a-breadcrumbs__item">
                        <a-link
                            v-if="bigWidth === true && item.to && index < links.length - 1"
                            class="a-breadcrumbs__link"
                            v-bind="item"
                            :to="item.to"
                            :text="item.text"
                            size="caption"
                            accent
                            :type="theme === 'dark' ? 'light' : 'regular'"
                        />
                        <span v-if="item.to && index === links.length - 1" class="a-breadcrumbs__current">
                            {{ item.text }}
                        </span>
                        <a-glyph
                            v-if="bigWidth === true && glyphDesktop && item.to && index < links.length - 1"
                            class="a-breadcrumbs__glyph"
                            :name="glyphDesktop"
                        />
                        <a-link
                            v-if="bigWidth === false && item.to && (index === 0 || index === links.length - 2)"
                            class="a-breadcrumbs__link"
                            v-bind="item"
                            :to="item.to"
                            :text="item.text"
                            size="caption"
                            accent
                            :type="theme === 'dark' ? 'light' : 'regular'"
                        />
                        <a-glyph
                            v-if="bigWidth === false && glyphDesktop && item.to && (index === 0 || index === links.length - 2)"
                            class="a-breadcrumbs__glyph"
                            :name="glyphDesktop"
                        />
                        <div v-if="bigWidth === false && item.sublinks" class="a-breadcrumbs__sublist-container">
                            <button
                                class="a-breadcrumbs__sublist-toggler"
                                :class="{ 'a-breadcrumbs__sublist-toggler--active': sublinkActive }"
                                @click="showSublist"
                            >
                                <a-glyph class="a-breadcrumbs__glyph" name="ellipsis" />
                            </button>
                            <ul ref="links" class="a-breadcrumbs__sublist" @mouseleave="hideSublist">
                                <li v-for="(sublink, subIndex) in links" :key="subIndex" class="a-breadcrumbs__subitem">
                                    <a-link
                                        v-if="sublink.to && subIndex > 1 && subIndex < links.length - 2"
                                        class="a-breadcrumbs__sublink"
                                        v-bind="sublink"
                                        :to="sublink.to"
                                        :text="sublink.text"
                                        size="caption"
                                        accent
                                        :type="theme === 'dark' ? 'light' : 'regular'"
                                    />
                                </li>
                            </ul>
                            <a-glyph class="a-breadcrumbs__glyph" :name="glyphDesktop" />
                        </div>
                    </li>
                </ul>
            </div>
            <div class="a-breadcrumbs__mobile">
                <div v-for="(item, index) in links" :key="index" class="a-breadcrumbs__item">
                    <a-link
                        v-if="item.to && item.text && index === links.length - 2"
                        class="a-breadcrumbs__link"
                        v-bind="item"
                        :to="item.to"
                        :text="item.text"
                        :glyph="glyphMobile"
                        glyph-position="left"
                        size="caption"
                        accent
                        :type="theme === 'dark' ? 'light' : 'regular'"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AGlyph from '@core/components/glyph/glyph.vue';
import ALink from '@core/components/link/link.vue';

const sublistClassContainer = 'a-breadcrumbs__sublist-container';
const sublistClass = 'a-breadcrumbs__sublist-toggler';
const sublistClassActive = `${sublistClass}_active`;

export default {
    name: 'ABreadcrumbs',

    components: {
        ALink,
        AGlyph,
    },

    props: {
        /**
         * Links
         */
        links: {
            type: Array,
            default: () => [],
        },

        glyphDesktop: {
            type: String,
            required: false,
            default: 'breadcrumb-chevron-right',
        },

        glyphMobile: {
            type: String,
            required: false,
            default: 'long-arrow-left',
        },

        sublinks: {
            type: Boolean,
        },

        theme: {
            type: String,
            validator: (prop) => ['light', 'dark'].includes(prop),
            default: 'light',
        },
    },

    data() {
        return {
            sublinkActive: false,
            bigWidth: true,
            listWidth: null,
        };
    },

    computed: {
        className() {
            return {
                'a-breadcrumbs_dark': this.theme === 'dark',
            };
        },
    },
    mounted() {
        this.listWidth = this.$refs.listItem.clientWidth;
        this.calcWidth();
        window.addEventListener('resize', this.calcWidth);
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.calcWidth);
    },

    methods: {
        showSublist(event) {
            if (event.target.classList.contains(sublistClassActive)) {
                event.target.classList.remove(sublistClassActive);
            } else {
                event.target.classList.add(sublistClassActive);
            }
        },

        hideSublist(event) {
            if (event.relatedTarget && event.relatedTarget.className !== sublistClassContainer) {
                event.target
                    .closest(`.${sublistClassContainer}`)
                    .querySelector(`.${sublistClass}`)
                    .classList.remove(sublistClassActive);
            }
        },

        calcWidth() {
            if (!this.$refs.listItem) {
                return false;
            }
            if (window.innerWidth - this.listWidth >= 50) {
                this.bigWidth = true;
            } else {
                this.bigWidth = false;
            }
            return true;
        },
    },
};
</script>

<style lang="postcss" scoped>
.a-breadcrumbs {
    width: 100%;
    position: relative;

    /* WEB-43924 why do we use 120 here ? */
    z-index: 120;
    &__desktop {
        display: none;
        @media (--viewport-tablet) {
            display: block;
        }
    }

    &__mobile {
        display: block;
        @media (--viewport-tablet) {
            display: none;
        }
    }
    &__current {
        color: var(--av-nav-primary);
        padding: 4px 8px;
    }
    &__list {
        display: flex;
        flex-shrink: 0;
        white-space: nowrap;
        width: max-content;
    }

    &__item {
        @mixin caption-accent;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: var(--av-brand-secondary);
        .a-breadcrumbs__glyph {
            margin-inline-end: 4px;
        }
    }

    &__link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        padding: 4px 8px;
        border-radius: 4px;
        @mixin caption-accent;
        color: var(--av-brand-primary) !important;
        &:hover {
            background-color: var(--av-brand-secondary-bright-hover);
        }
        &:active {
            background-color: var(--av-brand-secondary-bright-active);
        }

        &:deep(.a-glyph) {
            fill: var(--av-brand-primary) !important;
            margin: 0 !important;
        }
        &:deep(.hasGlyph) {
            display: flex;
        }
    }

    &__glyph {
        fill: var(--av-brand-primary);
        height: 16px;
        width: 16px;
    }

    &__sublist-container {
        position: relative;
        display: flex;
        align-items: center;
    }

    &__sublist-toggler {
        display: flex;
        align-items: center;
        padding: 4px 8px;
        color: var(--av-brand-secondary);
        background: transparent;
        margin-inline-end: 4px;
        border-radius: 4px;
        border: 1px solid var(--av-brand-secondary-light);
        cursor: pointer;

        .a-breadcrumbs__glyph {
            fill: var(--av-brand-primary) !important;
            margin: 0;
        }

        & > * {
            pointer-events: none;
        }

        &:hover {
            background-color: var(--av-brand-secondary-bright-hover);
        }

        &:active {
            background-color: var(--av-brand-secondary-bright-active);
        }

        &_active {
            background-color: var(--av-brand-secondary-bright-active);
            & + .a-breadcrumbs {
                &__sublist {
                    display: block;
                }
            }
        }

        &:deep(.a-link.nuxt-link-exact-active) {
            color: var(--av-nav-primary);
        }

        &--active {
            color: var(--av-fixed-white);
        }
    }

    &__sublist-glyph {
        margin-inline-start: 8px;
        fill: currentcolor;
        transition: transform 0.15s ease-out;
    }

    &__sublist {
        display: none;
        position: absolute;
        top: calc(100% + 10px);
        inset-inline-start: 0;
        padding: 8px 0;
        background-color: var(--av-fixed-white);
        box-shadow: 0 10px 20px rgba(36, 49, 67, 0.2);
        border-radius: 4px;
        border: 1px solid var(--av-brand-secondary);
    }

    &__subitem {
        margin: 0;
        padding: 0;
    }

    &__sublink {
        color: var(--av-brand-primary) !important;
        cursor: pointer;
        display: block;
        padding: 8px 16px;
        &:deep(.a-glyph) {
            fill: var(--av-brand-primary) !important;
        }
        &:hover {
            background-color: var(--av-brand-secondary-bright-hover);
        }

        &:active {
            background-color: var(--av-brand-secondary-bright-active);
        }

        &.a-link.nuxt-link-exact-active {
            pointer-events: auto;
        }
    }
    &_dark {
        background: var(--av-nav-primary);
    }
    &_dark & {
        &__current {
            color: var(--av-fixed-white);
        }
        &__sublist-toggler {
            color: var(--av-branded-icon-solid-lightest);
            .a-breadcrumbs__glyph {
                fill: var(--av-branded-icon-solid-lightest) !important;
            }
        }
        &__link {
            color: var(--av-branded-icon-solid-lightest) !important;
            &:deep(.a-glyph) {
                fill: var(--av-branded-icon-solid-lightest) !important;
            }
        }
        &__glyph {
            fill: var(--av-branded-icon-solid-lightest);
        }
    }
}
[dir='rtl'] {
    .a-breadcrumbs__item .a-breadcrumbs__glyph {
        transform: rotate(180deg);
    }
}
</style>
