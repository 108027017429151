import { LOCALE_DEFAULT } from '@model/const/locales';
import SchemaHandler from '@model/seo/schemaHandler';
import { FAQPageSchema, QuestionSchema, SchemaConfig } from '@model/seo/schemas';
import { getSiteUrl, MAIN_SITE_URL, SITE_ID_EVENTS } from '../helper';

const FAQ_SLICE_NAME = 's-faq';

export default class FaqPageHandler implements SchemaHandler {
    ctx: any;

    schemasConfig: SchemaConfig[];

    constructor(ctx: any, schemasConfig: SchemaConfig[]) {
        this.ctx = ctx;
        this.schemasConfig = schemasConfig;
    }

    getSchemaData(staticConfig: FAQPageSchema): Promise<FAQPageSchema> {
        return Promise.resolve(this.getRequiredFields(staticConfig));
    }

    getRequiredFields(staticConfig: FAQPageSchema): FAQPageSchema {
        const locale = this.ctx.$route?.params?.locale || LOCALE_DEFAULT;
        const path = this.ctx.$route?.path;
        const siteUrl = getSiteUrl(this.ctx);
        const fullPath = `${siteUrl}${path || '/'}`;
        const siteID = this.ctx.$store.getters['config/$config'].siteID;

        let faqItems;

        if (siteID === SITE_ID_EVENTS) {
            const event = this.ctx.$store.state.events.event.items[0];
            const productId = event?.product_ids?.[0];
            const product = this.ctx.$store.state.products.items?.[productId];

            faqItems = product?.parameters?.faq?.items || [];
        } else {
            const slices = this.ctx.$store.state.pages?.page?.body || [];
            faqItems = slices.find((slice) => slice.name === FAQ_SLICE_NAME);
            faqItems = faqItems?.data?.items || [];

            // products hack
            if (!faqItems.length) {
                if (
                    this.ctx.$route.path.startsWith(`/${locale}/products/`) ||
                    this.ctx.$route.path.startsWith(`/${locale}/solutions/`)
                ) {
                    const productId = this.ctx.$store.state.pages.page?.product_id;
                    const product = this.ctx.$store.state.products.items?.[productId];
                    faqItems = product?.parameters?.faq?.items || [];
                }
            }
        }

        if (!faqItems?.length) return null;

        const data: FAQPageSchema = {
            ...staticConfig,
            '@type': 'FAQPage',
            '@id': `${fullPath}#FAQPage`,
            url: fullPath,
            audience: [{
                '@type': 'PeopleAudience',
                '@id': `${fullPath}#FAQPage_audience_PeopleAudience`,
                sameAs: 'https://www.wikidata.org/wiki/Q2472587',
            }],
            author: {
                '@id': `${MAIN_SITE_URL}/#Organization`,
            },
            publisher: {
                '@id': `${MAIN_SITE_URL}/#Organization`,
            },
        };

        if (faqItems?.length) {
            data.mainEntity = this.extendFAQPageSchema(faqItems, fullPath);
        }

        return data;
    }

    extendFAQPageSchema(faqItems: any, fullPath: string): QuestionSchema[] {
        return faqItems
            .flatMap((item) => item.list)
            .map((item, i) => ({
                '@type': 'Question',
                '@id': `${fullPath}#hasPart_FAQPage_mainEntity${i + 1}`,
                acceptedAnswer: [{
                    '@type': 'Answer',
                    '@id': `${fullPath}#highlight#TagListmainEntityHighlight-${i + 1}_acceptedAnswer_Answer`,
                    text: item.content,
                }],
                name: item.title,
            }));
    }
}
