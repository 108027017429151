<template>
    <section class="s-faq-section">
        <h3 v-if="title" class="s-faq-section__title">
            {{ title }}
        </h3>
        <a-accordion>
            <a-accordion-item
                v-for="(item, index) in list"
                :key="`faq-${index}`"
                :title="item.title"
                :title-tag="item.tagTitle || 'h2'"
            >
                <a-dangerous-html :content="item.content" />
            </a-accordion-item>
        </a-accordion>
    </section>
</template>

<script>
import AAccordionItem from '@core/components/accordion/accordion-item.vue';
import AAccordion from '@core/components/accordion/accordion.vue';
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';

export default {
    name: 'SFaqSection',

    components: {
        AAccordionItem,
        AAccordion,
        ADangerousHtml,
    },

    props: {
        title: {
            type: String,
            default: '',
        },

        /**
         * Questions
         */
        list: {
            type: Array,
            default: () => [],
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-faq-section {
    &__title {
        @mixin title-accent;

        margin-bottom: 24px;
        color: var(--av-nav-primary);
    }
}
</style>
