<template>
    <div v-if="title || description" class="s-notification" :class="className">
        <a-glyph :name="glyph" />
        <p v-if="title" class="s-notification__title" v-html="title" />
        <p v-if="description" class="s-notification__description" v-html="description" />
    </div>
</template>

<script>
import AGlyph from '@core/components/glyph/glyph.vue';

export default {
    name: 'SNotification',

    components: {
        AGlyph,
    },

    props: {
        /**
         * Title
         */
        title: {
            type: String,
            default: null,
        },

        /**
         * Description
         */
        description: {
            type: String,
            default: null,
        },

        /**
         * Glyph
         */
        glyph: {
            type: String,
            default: 'info-circle',
        },

        /**
         * Size
         */
        size: {
            type: String,
            default: '',
        },

        /**
         * Type
         */
        type: {
            type: String,
            default: '',
        },
    },

    computed: {
        className() {
            return {
                [`s-notification__${this.size}`]: this.size,
                [`s-notification__${this.type}`]: this.type,
            };
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-notification {
    margin-top: 24px;
    border-radius: 4px;
    position: relative;
    padding: 16px 24px;
    padding-inline-start: 48px;
    color: var(--av-fixed-secondary);
    background: linear-gradient(0deg, rgba(64, 139, 234, 0.15), rgba(64, 139, 234, 0.15)), #fff;

    &:deep(a) {
        text-decoration: none;
        color: var(--av-brand-secondary);
    }

    .a-glyph {
        top: 20px;
        inset-inline-start: 24px;
        position: absolute;
    }

    &__title {
        @mixin paragraph-accent;
    }

    &__description {
        @mixin paragraph;
    }

    &__small {
        margin: 8px 0 16px;
        padding: 8px;
        padding-inline-start: 32px;
        background: rgba(64, 139, 234, 0.05);

        .a-glyph {
            top: 8px;
            inset-inline-start: 8px;
            position: absolute;
        }

        .s-notification {
            &__title {
                @mixin caption-accent;
            }

            &__description {
                @mixin caption;
            }
        }
    }

    &__medium {
        margin: 16px 0 0;
        padding: 8px 24px;
        padding-inline-start: 32px;

        .a-glyph {
            top: 10px;
            inset-inline-start: 10px;
            position: absolute;
        }

        .s-notification {
            &__title {
                @mixin descriptor-accent;
            }

            &__description {
                @mixin descriptor;
            }
        }
    }

    &__promo {
        background: linear-gradient(0deg, rgba(155, 194, 37, 0.15), rgba(155, 194, 37, 0.15)), #fff;

        .a-glyph {
            fill: var(--av-fixed-success);
        }
    }
}

.s-purchasing-acp-promo {
    .s-notification {
        &__small {
            margin-bottom: 8px;
        }
    }
}
</style>
