<template>
    <div v-if="product.opened" class="card-cloud-storage">
        <div class="card-cloud-storage__selectors">
            <dropdown-cloud-storage :settings="settings" :product="product" />
            <dropdown-subscription :settings="settings" :product="product" />
        </div>
        <div v-if="product.addons" class="card-cloud-storage__addons">
            <div v-for="(addon, addonIndex) in product.addons" :key="`addon-${addonIndex}`" class="card-cloud-storage__addon">
                <el-checkbox v-model="addon.added" @change="setProductAddons({ productID: product.id, index: addonIndex, added: $event })">
                    <span class="el-checkbox-title" v-html="addonTitle(addon)" />
                </el-checkbox>
                <p class="card-cloud-storage__addon-description">
                    {{ addon.description }}
                </p>
            </div>
        </div>
        <notification
            v-for="(item, notificationIndex) in product.notifications"
            :key="`notification-${notificationIndex}`"
            :description="item"
            size="medium"
        />
    </div>
</template>

<script>
import dropdownCloudStorage from '../dropdown/cloud-storage.vue';
import dropdownSubscription from '../dropdown/subscription.vue';
import mixin from '../mixin.js';
import notification from '../notification.vue';

export default {
    name: 'SCardCloudStorage',

    components: {
        notification,
        dropdownCloudStorage,
        dropdownSubscription,
        elCheckbox: () => import('@uikit/ui-kit/packages/checkbox'),
    },

    mixins: [mixin],

    props: {
        /**
         * Settings
         */
        settings: {
            type: Object,
            default: null,
        },

        /**
         * Product
         */
        product: {
            type: Object,
            default: null,
        },
    },

    methods: {
        addonTitle(license) {
            const cleverBridgeCartID = license.cleverBridgeCartID;
            const product = this.licenseData({ cleverBridgeCartID, promo: this.settings.promo });
            return license.title.replace('$XX.XX', this.setCurrency(product.price));
        },
    },
};
</script>

<style lang="postcss" scoped>
.card-cloud-storage {
    padding: 0 32px;
    margin: 16px 0 0;

    &__selectors {
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-start;
        justify-content: space-between;
    }

    &__addons {
        margin: 16px 0 0;
    }

    &__addon {
        padding-inline-start: 24px;

        &-description {
            margin: 8px 0 0;
        }

        &:deep(.el-checkbox) {
            margin-inline-start: -24px;

            &__label {
                padding: 0;
                padding-inline-start: 8px;
            }
        }
    }
}
</style>
