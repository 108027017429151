import { render, staticRenderFns } from "./event-resource.vue?vue&type=template&id=0ec27338&scoped=true"
import script from "./event-resource.vue?vue&type=script&lang=js"
export * from "./event-resource.vue?vue&type=script&lang=js"
import style0 from "./event-resource.vue?vue&type=style&index=0&id=0ec27338&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ec27338",
  null
  
)

export default component.exports