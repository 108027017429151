<template>
    <div v-if="computedType === 'screenshot'" :class="{ 'no-shadow': !isShadow }">
        <div
            v-if="enlargedImage"
            v-modal="computedModalId"
            class="enlarged-image"
            tabindex="0"
        >
            <a-picture
                v-bind="$props"
                :class="className"
                class="full-width"
                :is-shadow="false"
            />
            <div class="zoom-in">
                <a-glyph name="zoom-in" class="icon" />
            </div>
        </div>
        <template v-if="!disableModal">
            <a-modal
                v-if="enlargedImage"
                :id="computedModalId"
                :is-overlay-close="isOverlayClose"
                :caption="caption"
                :type="isGallery ? 'gallery' : 'screenshot'"
            >
                <template #default>
                    <a-picture
                        :link="enlargedImage"
                        position="center"
                        :is-shadow="false"
                        persist-original
                    />
                </template>
                <template v-if="isGallery" #gallery>
                    <a-modal-gallery
                        :caption="caption"
                        :next-id="nextId"
                        :prev-id="prevId"
                    />
                </template>
            </a-modal>
            <a-picture
                v-else
                :class="className"
                :is-shadow="false"
                v-bind="$props"
                class="full-width"
            />
        </template>
    </div>
    <a-picture
        v-else-if="computedType === 'image'"
        :class="className"
        :is-shadow="false"
        class="full-width"
        v-bind="$props"
    />
    <a-video-card
        v-else-if="computedType === 'video'"
        v-bind="$props"
        :class="className"
        @progressChanged="$emit('progressChanged', $event)"
    />
    <span v-else>ERR: Unsupported media type!</span>
</template>

<script>
import AGlyph from '@core/components/glyph/glyph.vue';
import AModalGallery from '@core/components/modal/modal-gallery.vue';
import AModal from '@core/components/modal/modal.vue';
import Modal from '@core/directives/modal.js';
import APicture from '../picture/picture.vue';
import AVideoCard from '../video-card/video-card.vue';
import { TYPES } from './constants.js';

export default {
    name: 'AMedia',
    components: {
        AGlyph,
        APicture,
        AModal,
        AVideoCard,
        AModalGallery,
    },
    directives: { Modal },
    props: {
        type: {
            type: String,
            required: true,
            validator: (type) => TYPES.includes(type),
        },

        /* Picture props ((Required ones defined in video-card component) */

        link: {
            type: String,
            default: '',
        },

        alt: {
            type: String,
            default: 'Acronis',
        },

        /**
         * Image's "object-fit" property.
         *
         * If we want always 'contain' but 'cover' only on desktop
         * e.g. { default: 'contain', desktop: 'cover' }
         */
        fit: {
            type: [String, Object],
            default: undefined,
        },

        /**
         * Image's "object-position" property
         */
        position: {
            type: String,
            default: 'center',
        },

        /**
         * Zoom capabilities
         */
        enlargedImage: {
            type: String,
            default: null,
        },

        persistOriginal: {
            type: Boolean,
            default: false,
        },

        /**
         * Disable Modal in case you need it to be external
         */
        disableModal: {
            type: Boolean,
            default: false,
        },
        /**
         * Modal ID for enlarged image.
         */
        modalId: {
            type: String,
            default: null,
        },
        /**
         * Caption to be used in the gallery modal.
         */
        caption: {
            type: String,
            default: null,
        },

        nextId: {
            type: String,
            default: null,
        },

        prevId: {
            type: String,
            default: null,
        },
        /**
         * Control the overlay close property of the modal
         */
        isOverlayClose: {
            type: Boolean,
            default: true,
        },

        /**
         * Picture shadow
         */
        isShadow: {
            type: Boolean,
            default: true,
        },

        /**
         * Is a picture used as a background
         */
        isBackground: {
            type: Boolean,
            default: false,
        },

        /**
         * Image link if picture is used as a background
         */
        background: {
            type: Object,
            default: undefined,
        },

        /**
         * Image size if picture is not a background
         */
        size: {
            type: String,
            default: undefined,
        },

        /* Video Props (Required ones defined in video-card component) */

        /**
         * Youtube id
         */
        ytId: {
            type: String,
            default: undefined,
        },

        /**
         * Video title
         */
        text: {
            type: String,
            default: undefined,
        },

        /**
         * Video time
         */
        time: {
            type: String,
            default: undefined,
        },

        /**
         * Image URL
         */
        image: {
            type: String,
            default: undefined,
        },

        /**
         * Image alt
         */
        imageAlt: {
            type: String,
            default: undefined,
        },
    },
    emits: ['progressChanged'],
    data() {
        return {
            isFocus: false,
        };
    },
    computed: {
        className() {
            const { type } = this;
            return {
                [`a-media__type__${type}`]: type,
            };
        },
        computedType() {
            if (!this.type) return 'image';
            return this.type;
        },
        computedModalId() {
            if (this.modalId) return this.modalId;
            return `${this.link}-${this.enlargedImage}`;
        },
        isGallery() {
            return this.caption || (this.prevId && this.nextId);
        },
    },
};
</script>

<style lang="postcss" scoped>
.full-width {
    :deep(.a-picture__img) {
        width: 100%;
    }
}
.enlarged-image {
    position: relative;
    cursor: pointer;
    border-radius: 4px;
    .zoom-in {
        display: flex;
        position: absolute;
        bottom: 8px;
        right: 8px;
        background: var(--av-fixed-lighter);
        border-radius: 4px;
        padding: 8px;
        transition: 0.2s all;
        .icon {
            fill: var(--av-fixed-white);
        }
    }
    &:hover {
        .a-media__type__screenshot {
            box-shadow: none;
        }
        .zoom-in {
            background: rgb(0, 91, 204);
        }
    }
    &:focus-visible {
        .a-media__type__screenshot {
            outline: 3px solid var(--av-brand-secondary);
            outline-offset: -3px;
        }
    }
}
.a-media {
    &__type {
        &__screenshot {
            border-radius: 4px;
            overflow: hidden;
            border: 1px solid var(--av-brand-secondary-light);
            box-shadow: var(--av-shadow-regular);
            transition: box-shadow 0.2s ease-in-out;
        }
        &__video {
            border: 1px solid var(--av-brand-light);
            :deep(.a-video-card__wrapper:before) {
                border: none;
            }
        }
    }
}
.no-shadow {
    .a-media__type__screenshot {
        box-shadow: none;
    }
}
</style>
