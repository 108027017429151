<template>
    <div v-if="content" class="sparkle" v-html="content" />
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'InAppComponent',

    computed: {
        ...mapState({
            content: (state) => state.inapp?.translation?.content || '',
        }),
    },
};
</script>

<style lang="postcss">
.inapp {
    .sparkle {
        b {
            font-size: 14px;
        }
        p, li {
            font-size: 13px;
        }
        a {
            text-decoration: underline
        }
    }
}
</style>
