<template>
    <div class="blog-subscribe" :class="view">
        <div class="container">
            <div class="cols">
                <div class="title-wrap">
                    <div class="title" :class="view">
                        {{ title }}
                    </div>
                    <div class="lead" :class="view">
                        {{ lead }}
                    </div>
                </div>
                <div v-if="form" class="form-wrap">
                    <s-form-container :form="form" @submit="mode = 'success'" />
                    <div v-if="mode === 'form'" v-modal="'newsletter-preview-modal'" class="view-newsletter">
                        <a-glyph class="view-newsletter-icon" name="outlook" />
                        <span class="view-newsletter-text">{{ sampleText }}</span>
                    </div>
                </div>
            </div>
            <a-modal id="newsletter-preview-modal">
                <newsletter-sample />
            </a-modal>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import AGlyph from '@core/components/glyph/glyph.vue';
import AModal from '@core/components/modal/modal.vue';
import Modal from '@core/directives/modal.js';
import SFormContainer from '@core/slices/pages/trial-form/components/form/form.vue';
import NewsletterSample from '../shared-components/blog-newsletter-sample/blog-newsletter-sample.vue';

const STORE_SLICE_NAME = 's-preset-form-blog-subscription';

export default {
    name: 'BlogSubscribe',
    components: {
        AGlyph,
        AModal,
        NewsletterSample,
        SFormContainer,
    },
    directives: {
        Modal,
    },
    props: {
        view: {
            type: String,
            default: '',
        },

        title: {
            type: String,
            default: '',
        },

        lead: {
            type: String,
            default: '',
        },

        sampleText: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            form: null,
            mode: 'form',
        };
    },

    computed: {
        ...mapState({
            location: (state) => state.geolocation?.location || null,
            preset: (state) => state.slices.items?.[STORE_SLICE_NAME] || null,
        }),
    },

    mounted() {
        if (!this.preset?.form?.registration?.fields) return;
        this.form = this.preset.form;
        this.setGeolocation();
    },

    methods: {
        async setGeolocation() {
            if (!this.form?.flow?.marketo?.country) return;
            await this.$store.dispatch('geolocation/getGeoLocation');
            const country = this.location?.data?.country?.code || '';
            this.form.flow.marketo.country = country;
        },
    },

    serverPrefetch() {
        const locale = this.$route.params.locale;
        const promises = [
            this.$store.dispatch('slices/getSyncedData', { slice: STORE_SLICE_NAME, locale }),
            this.$store.dispatch('slices/getSyncedData', { slice: 's-list-form-elements', locale }),
        ];
        Promise.all(promises);
    },
};
</script>

<style lang="postcss" scoped>
.blog-subscribe {
    &.page-view {
        .title {
            @media (--viewport-tablet) {
                @mixin hero;
            }
        }
        .lead {
            @media (--viewport-tablet) {
                @mixin title;
            }
        }
    }

    .container {
        margin: 0 auto;
        max-width: 1440px;
    }

    .cols {
        @media (--viewport-desktop) {
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
        }
    }

    .title-wrap {
        text-align: center;
        margin: 0 auto 24px;
        flex-basis: 100%;

        @media (--viewport-desktop) {
            flex-basis: 40%;
            padding-inline-end: 16px;
            margin: 0;
            text-align: start;
        }
    }

    .title {
        @mixin display;
        margin-bottom: 24px;
        font-weight: 700;
        color: var(--av-nav-primary);
    }

    .lead {
        @mixin paragraph;
        color: var(--av-fixed-secondary);
    }

    .form-wrap {
        flex-basis: 100%;
        min-height: 130px;
        border-radius: 4px;
        background: var(--av-brand-secondary-accent);

        @media (--viewport-desktop) {
            flex-basis: 60%;
        }
    }

    .view-newsletter {
        z-index: 1;
        display: flex;
        cursor: pointer;
        user-select: none;
        padding: 0 0 24px;
        position: relative;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;

        @media (--viewport-tablet) {
            padding: 0 24px 24px;
            justify-content: flex-start;
        }

        .view-newsletter-icon {
            width: 14px;
            height: 14px;
            margin-inline-end: 9px;
        }

        .view-newsletter-text {
            @mixin caption;
            color: var(--av-brand-secondary);
            font-weight: 600;
        }
    }

    &:deep(.s-form-container) {
        margin: 0;
        width: 100%;

        .error-holder {
            color: var(--av-fixed-danger);
            min-height: 0;
        }

        .el-input__editor,
        .el-input__label {
            @mixin body;
            font-family: var(--font-family-default);

            &.is-active {
                @mixin caption;
                padding-top: 4px;
            }
        }

        .el-checkbox,
        .el-checkbox:last-child {
            margin: 0;
        }

        .s-form-container__loading {
            background: transparent;

            .el-loading {
                min-height: 100%;
            }
        }

        .s-form-registration {
            border: none;
            margin: 0 auto;
            box-shadow: none;
            position: relative;
            padding: 24px 24px 16px;
            background: transparent;

            @media (--viewport-tablet) {
                padding-bottom: 42px;
            }

            @media (--viewport-desktop) {
                max-width: none;
            }

            .el-form {
                margin: 0;
                display: flex;
                flex-direction: column;

                @media (--viewport-tablet) {
                    display: flex;
                    flex-wrap: nowrap;
                    flex-direction: row;
                    justify-content: space-between;

                    .el-row {
                        width: 100%;
                    }
                }

                @media (--viewport-tablet) {
                    .el-form-item__error {
                        padding: 0;
                    }
                }

                &.is-invalid {
                    .checkbox-error-message {
                        display: none;
                    }
                }
            }
        }

        .s-form-registration__button {
            @mixin body-accent;

            height: 48px;
            width: 100%;
            background: var(--av-nav-secondary);
            margin-top: 9px;

            @media (--viewport-tablet) {
                width: 128px;
                margin-top: 0;
                min-width: 128px;
                margin-inline-start: 24px;
            }

            &:hover {
                background: var(--av-brand-secondary);
            }

            &:active {
                background: var(--av-brand-primary);
            }
        }

        .s-form-registration__checkboxes {
            order: 1;
            display: flex;
            margin-top: 8px;
            justify-content: center;

            @media (--viewport-tablet) {
                top: 90px;
                width: auto;
                position: absolute;
                justify-content: start;
            }

            .checkbox-error-message {
                @mixin caption;

                @media (--viewport-tablet) {
                    top: -24px;
                    position: absolute;
                    white-space: nowrap;
                }
            }

            .el-checkbox__label {
                @mixin caption;
                padding-inline-start: 8px;
                font-family: var(--font-family-default);
            }

            .el-checkbox__control {
                margin: 0;
            }
        }

        .s-form-registration__error {
            @mixin caption;
            margin: 0;

            @media (--viewport-tablet) {
                top: 72px;
                position: absolute;
            }
        }

        .s-form-success {
            border: none;
            display: flex;
            padding: 24px;
            box-shadow: none;
            border-radius: 4px;
            align-items: flex-start;
            background: transparent;

            .title {
                @mixin nav-title-accent;
                margin: 0;
                padding: 0;
                font-family: var(--font-family-default);
            }

            .description {
                margin: 0;
                padding: 0;
                text-align: start;
            }
        }

        .s-form-error {
            z-index: 2;
            position: relative;
        }
    }

    :deep(.a-modal__container) {
        padding: 60px 16px;
        width: auto;

        @media (--viewport-tablet) {
            padding: 0;
        }
    }
}
</style>
