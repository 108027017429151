<template>
    <div v-if="product.opened" class="s-workload-edition-packages">
        <div v-for="(edition, editionIndex) in list" :key="`workload-edition-${editionIndex}`" class="s-workload-edition-packages__item">
            <div class="s-workload-edition-packages__radio">
                <el-radio
                    v-for="(name, packageIndex) in edition.package.items"
                    :key="`workload-package-${packageIndex}`"
                    v-model="edition.package.selected"
                    :label="packageIndex"
                    @change="changeType()"
                >
                    {{ name }}
                </el-radio>
            </div>
            <div v-if="edition.quantity" class="s-workload-edition-packages__selectors">
                <el-num-picker
                    v-model="edition.selectedQuantity"
                    :min="quantityMin(edition)"
                    :max="quantityMax(edition)"
                    :label="quantityTitle(edition)"
                    :step="quantityStep"
                    integer-only
                    step-strictly
                />
                <div v-if="edition.subscriptions" class="s-workload-edition-packages__period">
                    {{ subscriptionTitle(edition) }}
                    <dropdown-workload-edition :settings="settings" :product="product" :edition="edition" />
                </div>
            </div>
            <product-feature v-if="type === 1" :product="product" :edition="edition" />
            <notification
                v-for="(item, notificationIndex) in notificationsList(edition)"
                :key="`edition-notification-${notificationIndex}`"
                :description="item"
                size="medium"
            />
        </div>
        <card-add-edition :settings="settings" :product="product" />
    </div>
</template>

<script>

import dropdownWorkloadEdition from '../dropdown/workload-edition.vue';
import mixin from '../mixin.js';
import notification from '../notification.vue';
import cardAddEdition from '../step-2/card-add-edition.vue';
import productFeature from '../step-2/product-feature.vue';

export default {
    name: 'SWorkloadEditionPackages',

    components: {
        dropdownWorkloadEdition,
        cardAddEdition,
        productFeature,
        notification,
        ElNumPicker: () => import('@uikit/ui-kit/packages/num-picker'),
        ElRadio: () => import('@uikit/ui-kit/packages/radio'),
    },

    mixins: [mixin],

    props: {
        /**
         * Settings
         */
        settings: {
            type: Object,
            default: null,
        },

        /**
         * Product
         */
        product: {
            type: Object,
            default: null,
        },
    },

    computed: {
        list() {
            return this.editionAddedList(this.product.id);
        },

        license() {
            return this.list[0];
        },

        type() {
            return this.license.package.type[this.selectedPackage];
        },

        selectedPackage() {
            return this.license.package.selected;
        },

        quantitySelected() {
            return this.license.selectedQuantity;
        },

        quantityStep() {
            return this.type;
        },
    },

    methods: {
        changeType() {
            const quantity = this.quantitySelected;
            this.license.selectedQuantity = this.type === 1 ? quantity / 5 : quantity * 5;
        },

        quantityMax(edition) {
            return edition.quantity.max * this.quantityStep;
        },

        quantityMin(edition) {
            return edition.quantity.min * this.quantityStep;
        },

        quantityTitle(edition) {
            return this.type === 1 ? edition.quantity.title : this.setTranslate('Seats');
        },

        subscription(edition) {
            return edition[this.type === 1 ? 'subscriptions' : 'subscriptionsCustom'];
        },

        subscriptionTitle(edition) {
            return this.subscription(edition).title;
        },

        notificationsList(edition) {
            return this.subscription(edition).notifications || [];
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-workload-edition-packages {
    padding: 24px 0 0;

    &__item {
        padding: 0 32px;
    }

    &__radio {
        margin: 0 0 16px;

        &:deep(.el-radio) {
            @mixin body;

            &:not(:first-child) {
                margin-inline-start: 32px;
            }
        }

        &:deep(.el-radio__label) {
            padding-inline-start: 8px;
        }
    }

    &__selectors {
        @mixin body;
        display: flex;
        justify-content: space-between;
        color: var(--av-fixed-secondary);
    }

    &__period {
        width: 100%;
        margin-inline-start: 24px;
    }
}
</style>
