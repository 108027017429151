<template>
    <div class="event-video-slider">
        <a-slider class="e-slider" :options="sliderOptions">
            <a-slide v-for="(video, i) in videos" :key="i" class="e-slide">
                <event-video v-bind="video" />
            </a-slide>
        </a-slider>
        <a-modal
            v-for="(video, i) in videos"
            :id="video.ytId"
            :key="`${i}_${video.ytId}`"
            is-youtube
            is-overlay-close
            type="youtube"
        >
            <a-youtube :yt-id="video.ytId" :params="video.ytParams || { autoplay: 1 }" />
        </a-modal>
    </div>
</template>

<script>
import AModal from '@core/components/modal/modal.vue';
import ASlide from '@core/components/slider/slide.vue';
import ASlider from '@core/components/slider/slider.vue';
import AYoutube from '@core/components/youtube/youtube.vue';
import EventVideo from '../shared-components/event-video/event-video.vue';

export default {
    name: 'EventVideoSlider',

    components: {
        ASlider,
        ASlide,
        AModal,
        AYoutube,
        EventVideo,
    },
    props: {
        videos: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            sliderOptions: {
                showNavigation: true,
                showCounter: false,
                loop: false,
                slideToClickedSlide: true,
                spaceBetween: 0,
                slidesPerView: 'auto',
                resistanceRatio: 0,
                observer: true,
                observeParents: true,
                watchOverflow: true,
            },
        };
    },
};
</script>

<style lang="postcss" scoped>
.event-video-slider {
    .e-slider {
        position: relative;
    }

    .e-slide {
        cursor: pointer;
        margin-inline-end: 16px;

        &:last-child {
            margin-inline-end: 0;
        }
    }

    .video {
        aspect-ratio: 16/9;
        position: relative;
        overflow: hidden;
    }

    .video-inner {
        position: absolute;
        top: 0;
        inset-inline-start: 0;
        height: 100%;
        width: 100%;
        border: var(--av-border);
        border-radius: 4px;
        overflow: hidden;
    }

    .video-img {
        position: absolute;
        top: 0;
        inset-inline-start: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        transition: transform 0.3s ease-out;
    }
}

.event-video-slider :deep(.a-slider) {
    overflow: visible;

    &__nav {
        margin-top: 40px;
        margin-bottom: 0;
    }

    &__controls {
        margin: 0;
    }
}

.event-video-slider :deep(.a-slide) {
    opacity: 1;

    /* Next/prev slides should not open video by click */
    .video {
        pointer-events: none;
    }

    &.a-slide_active {
        .video {
            pointer-events: auto;
        }
    }

    @media (--viewport-desktop) {
        &.a-slide_next {
            .video {
                pointer-events: auto;
            }
        }
    }
}

.event-video-slider :deep(.video-play) {
    .a-glyph {
        fill: var(--av-fixed-white);
        margin: auto;
        width: 22px;
        height: 22px;

        @media (--viewport-desktop) {
            width: 32px;
            height: 32px;
        }
    }
}
</style>
