/* eslint-disable max-len */
import { MOCK_SMALL_CARD_COMPANY } from '@core/components/small-card/mocks.js';

const ARG_DEFAULTS = {
    translations: {
        title: 'Partner benefits',
        lead: 'As you grow your partnership with Acronis, you’ll unlock additional perks. Gain access to special benefits and programs designed to delight your customers and help your business thrive.',
        'card.title': 'Backup as a Service',
        'card.text': '#1 hybrid cloud BaaS for any virtual, physical, and cloud environment',
    },
};
export const Figma = 'https://www.figma.com/design/eM4W6JS2MjG1gp5YhKoMp7Dd/Slices?node-id=52463-66130&t=LWcbKICEQRRYVaDf-4';

export const Default = {
    name: 'Default',
    args: {
        translations: {
            ...ARG_DEFAULTS.translations,
            ...MOCK_SMALL_CARD_COMPANY.translations,
            'rate.0.title': 'Financial',
            'rate.1.title': 'Marketing',
            'rate.2.title': 'Enablement',
            'rate.3.title': 'Support',
            imageAlt: 'Image Alt',
            logoAlt: 'Logo Alt',
            iconAlt: 'Icon Title',
            'link.text': 'Learn more',
            'downloadFileArr.0.title': 'Security at Acronis',
            'downloadFileArr.0.desc': 'PDF, 0.9 MB',
        },
        title: 'title',
        lead: 'lead',
        leadIsVisible: true,
        isCompanyLayout: false,
        isRowLayout: false,
        isRateLayout: false,
        cards: Array.from({ length: 12 }).map((_, i) => ({
            ...MOCK_SMALL_CARD_COMPANY,
            title: 'card.title',
            text: 'card.text',
            to: i % 3 === 0 ? '#' : null,
            icon: '149',
            iconAlt: 'iconAlt',
            iconVariant: true,
            logo: '@71003d4ee3c6ac84146db1bf58873776',
            logoAlt: 'logoAlt',
            image: '@3ebd5147cf98e12eea516427eed4aaa0',
            imageAlt: 'imageAlt',
            rate: [
                { points: 1, title: 'rate.0.title' },
                { points: 2, title: 'rate.1.title' },
                { points: 3, title: 'rate.2.title' },
                { points: 4, title: 'rate.3.title' },
            ],
        })),
        link: { text: 'link.text', to: '#' },
        downloadFileArr: [
            {
                title: 'downloadFileArr.0.title',
                desc: 'downloadFileArr.0.desc',
                link: '#',
            },
        ],
    },
};

export const Icon = {
    args: {
        translations: {
            ...ARG_DEFAULTS.translations,
            ...MOCK_SMALL_CARD_COMPANY.translations,
        },
        title: 'title',
        leadIsVisible: false,
        isCompanyLayout: false,
        isRowLayout: false,
        isRateLayout: false,
        cards: Array.from({ length: 8 }).map((_, i) => ({
            title: 'card.title',
            text: 'card.text',
            to: i % 3 === 0 ? '#' : null,
            icon: (76 + i).toString(),
        })),
    },
};

export const Image = {
    args: {
        translations: {
            ...ARG_DEFAULTS.translations,
        },
        title: 'title',
        leadIsVisible: false,
        isCompanyLayout: false,
        isRowLayout: false,
        isRateLayout: false,
        cards: Array.from({ length: 8 }).map((_, i) => ({
            title: 'card.title',
            text: 'card.text',
            to: i % 3 === 0 ? '#' : null,
            image: '/images/small-card/card-01',
            imageAlt: 'Alt',
        })),
    },
};

export const Logo = {
    args: {
        translations: {
            ...ARG_DEFAULTS.translations,
        },
        title: 'title',
        leadIsVisible: false,
        isCompanyLayout: false,
        isRowLayout: false,
        isRateLayout: false,
        cards: Array.from({ length: 8 }).map((_, i) => ({
            title: 'card.title',
            text: 'card.text',
            to: i % 3 === 0 ? '#' : null,
            logo: '/images/logo/microsoft',
        })),
    },
};

export const Rate = {
    args: {
        translations: {
            ...ARG_DEFAULTS.translations,
            'card.0.title': 'Registered',
            'card.0.text': 'Start by unlocking immediate discounts, training, and solution and technical support',
            'card.1.title': 'Authorized',
            'card.1.text': 'All of the benefits of a Registered Partner plus deeper discounts, marketing automation tools, a dedicated partner account manager, and more',
            'card.2.title': 'Gold',
            'card.2.text': 'All of the benefits of an Authorized Partner, with bigger discounts, virtual credits, access to the MDF program, sports benefits, and more',
            'card.3.title': 'Platinum',
            'card.3.text': 'Full-service from top to bottom with the best discounts, enhanced support, and a dedicated team',
            'rate.0.title': 'Financial',
            'rate.1.title': 'Marketing',
            'rate.2.title': 'Enablement',
            'rate.3.title': 'Support',
            'link.text': 'Learn more',
        },
        title: 'title',
        lead: 'lead',
        leadIsVisible: true,
        isCompanyLayout: false,
        isRowLayout: false,
        isRateLayout: true,
        cards: [
            {
                title: 'card.0.title',
                text: 'card.0.text',
                rate: [
                    { points: 2, title: 'rate.0.title' },
                    { points: 2, title: 'rate.1.title' },
                    { points: 2, title: 'rate.2.title' },
                    { points: 2, title: 'rate.3.title' },
                ],
            },
            {
                title: 'card.1.title',
                text: 'card.1.text',
                rate: [
                    { points: 3, title: 'rate.0.title' },
                    { points: 3, title: 'rate.1.title' },
                    { points: 3, title: 'rate.2.title' },
                    { points: 3, title: 'rate.3.title' },
                ],
            },
            {
                title: 'card.2.title',
                text: 'card.2.text',
                rate: [
                    { points: 4, title: 'rate.0.title' },
                    { points: 4, title: 'rate.1.title' },
                    { points: 4, title: 'rate.2.title' },
                    { points: 4, title: 'rate.3.title' },
                ],
            },
            {
                title: 'card.3.title',
                text: 'card.3.text',
                rate: [
                    { points: 5, title: 'rate.0.title' },
                    { points: 5, title: 'rate.1.title' },
                    { points: 5, title: 'rate.2.title' },
                    { points: 5, title: 'rate.3.title' },
                ],
            },
        ],
        link: { text: 'link.text', to: '#' },
    },
};

export const Company = {
    args: {
        translations: {
            ...ARG_DEFAULTS.translations,
            'link.text': 'Learn more',
        },
        title: 'title',
        lead: 'lead',
        leadIsVisible: true,
        isCompanyLayout: true,
        isRowLayout: false,
        isRateLayout: false,
        cards: Array.from({ length: 4 }, () => MOCK_SMALL_CARD_COMPANY),
        link: { text: 'link.text', to: '#' },
    },
};
