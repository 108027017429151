<template>
    <section :id="id" class="s-resource-highlight" :class="{ ...className, ...basicClassName }">
        <div class="a-container">
            <div v-if="hasHeader" class="s-resource-highlight__header">
                <div v-if="label" class="s-resource-highlight__header__label">
                    {{ label }}
                </div>
                <div class="s-resource-highlight__header__content">
                    <div v-if="title" class="s-resource-highlight__header__content__title">
                        {{ title }}
                    </div>
                    <div v-if="lead" class="s-resource-highlight__header__content__lead" v-html="lead" />
                </div>
                <div v-if="link" class="s-resource-highlight__header__link">
                    <a-link
                        v-bind="link"
                        :size="link.size || 'title'"
                        :glyph="link.glyph || 'arrow'"
                        accent
                    />
                </div>
            </div>
            <div v-if="isCardSingle" class="s-resource-highlight__content">
                <a-resource-highlight-card
                    class="s-resource-highlight__card single"
                    v-bind="cards[0]"
                    :is-single="true"
                    :compact-mobile="compactMobile"
                    :title-tag="$attrs.title && 'h3'"
                />
            </div>
            <div v-else class="s-resource-highlight__content">
                <div v-if="isCardsList" class="s-resource-highlight__list">
                    <a-resource-highlight-card
                        v-for="(card, index) in cards"
                        v-show="!visibleCards || visibleCards > index"
                        :key="`list-item-${index}`"
                        class="s-resource-highlight__card"
                        v-bind="card"
                    />
                </div>
                <a-slider v-else class="s-resource-highlight__slider" :options="sliceSliderOptions">
                    <a-slide
                        v-for="(card, index) in sliceCards"
                        :key="`innovation-leadership-slide-${index}`"
                        class="s-resource-highlight__slide"
                    >
                        <a-resource-highlight-card
                            class="s-resource-highlight__card"
                            v-bind="card"
                            :compact-mobile="compactMobile"
                            :title-tag="$attrs.title && 'h3'"
                        />
                    </a-slide>
                </a-slider>
            </div>
            <template v-if="settings && step > 0">
                <div v-if="settings.more && isAvailable" class="s-resource-highlight__show-more">
                    <a-link
                        :text="settings.more"
                        size="title"
                        glyph="drop-down-arrow"
                        accent
                        @click.stop.prevent="handleClick"
                    />
                </div>
                <div v-if="settings.less && !isAvailable" class="s-resource-highlight__show-less">
                    <a-link
                        :text="settings.less"
                        size="title"
                        glyph="drop-down-arrow"
                        accent
                        @click.stop.prevent="handleClick"
                    />
                </div>
            </template>
            <div v-if="link" class="s-resource-highlight__mobile__link">
                <a-link
                    v-bind="link"
                    :size="link.size || 'title'"
                    :glyph="link.glyph || 'arrow'"
                    accent
                />
            </div>
        </div>
    </section>
</template>

<script>
import ALink from '@core/components/link/link.vue';
import AResourceHighlightCard from '@core/components/resource-highlight-card/resource-highlight-card.vue';
import ASlide from '@core/components/slider/slide.vue';
import ASlider from '@core/components/slider/slider.vue';
import contentMixin from '@core/mixins/content.js';
import styleMixin from '@core/mixins/style.js';

export default {
    name: 'SResourceHighlight',

    components: {
        ALink,
        ASlider,
        ASlide,
        AResourceHighlightCard,
    },
    mixins: [contentMixin, styleMixin],
    inheritAttrs: false,

    props: {
        compactMobile: {
            type: Boolean,
            required: false,
            default: true,
        },
        label: {
            type: String,
            required: false,
            default: undefined,
        },
        cards: {
            type: Array,
            required: true,
        },
        link: {
            type: Object,
            required: false,
            default: undefined,
        },
        isCardsList: {
            type: Boolean,
            default: false,
        },
        sliderOptions: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        settings: {
            type: Object,
            required: false,
            default: undefined,
        },
    },

    data() {
        return {
            step: 0,
            visibleCards: 0,
            sliceCards: this.cards.slice(0, 6),
            sliceSliderOptions: {
                slidesPerView: 'auto',
                showNavigation: true,
                // disabled due to Swiper conflict between the Fraction pagination and slidesPerView: auto
                showCounter: false,
                ...this.sliderOptions,
            },
        };
    },

    computed: {
        isCardSingle() {
            return this.cards.length === 1;
        },
        className() {
            return {
                's-resource-highlight_single-card': this.isCardSingle,
                's-resource-highlight_cards-list': this.isCardsList,
            };
        },
        hasHeader() {
            return this.label || this.title || this.lead || this.link;
        },
        isAvailable() {
            return this.visibleCards < this.cards.length;
        },
    },

    mounted() {
        if (this.settings && this.settings.visibleCards) {
            this.visibleCards = this.settings.visibleCards;

            if (this.cards.length > this.visibleCards) {
                this.step = this.settings.step || this.cards.length;
            }
        }
    },

    methods: {
        handleClick() {
            if (this.isAvailable) {
                this.visibleCards += this.step;
                return;
            }
            this.visibleCards = this.settings.visibleCards;
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-resource-highlight {
    @mixin basic-slice-paddings;
    overflow-x: hidden;

    &__header {
        margin-bottom: 40px;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;

        &__label {
            @mixin paragraph;
            color: var(--av-fixed-light);
            flex-basis: 100%;
            margin-bottom: 8px;
            @media (--viewport-desktop) {
                @mixin colls 8;
            }
        }

        &__content {
            @media (--viewport-desktop) {
                @mixin colls 8;
            }

            &__title {
                @mixin display;
                margin-bottom: 24px;
                color: var(--av-nav-primary);
                @media (--viewport-mobile-wide) {
                    @mixin hero;
                }
            }

            &__lead {
                @mixin lead;
                color: var(--av-nav-primary);
                @media (--viewport-mobile-wide) {
                    @mixin title;
                }
            }
        }

        &__link {
            text-align: end;

            .a-link {
                margin-top: 8px;
            }

            display: none;

            @media (--viewport-desktop) {
                display: block;
                margin-inline-start: auto;
                @mixin colls 4;
            }
            @media (--viewport-desktop-wide) {
                @mixin colls 3;
            }
        }
    }

    &_cards-list,
    &_single-card {
        overflow-x: visible;

        .s-resource-highlight__content {
            margin: 0;
        }
    }

    &__list {
        .a-resource-highlight-card {
            &:not(:first-child) {
                margin-top: 16px;
            }
        }
    }

    &__content {
        &:deep(.a-slider__nav) {
            margin-top: 48px;
            margin-bottom: 0;
        }
    }

    &__card.single {
        @mixin colls 12;
        @media (--viewport-tablet) {
            @mixin colls 10;
        }
    }

    &__card {
        height: 100%;
    }

    &:deep(.a-slider) {
        overflow: hidden;

        @media (--viewport-tablet) {
            overflow: visible;
        }
        @media (--viewport-desktop-large) {
            overflow: hidden;

            /*
            .a-slide_next {
                .s-resource-highlight__card {
                    mask-image: linear-gradient(90deg, rgba(0, 0, 0, 0.6), transparent);
                }
            }
            */
        }

        .a-slide {
            @mixin colls 12;
            @media (--viewport-tablet) {
                @mixin colls 10;
                margin-inline-end: 16px;
            }

            &:last-child {
                @mixin colls 12;
                margin-inline-end: 0;

                .s-resource-highlight__card {
                    @mixin colls 12;
                    @media (--viewport-tablet) {
                        @mixin colls 10;
                    }
                }
            }

            &_next {
                opacity: 0.5;
                position: relative;
            }

            &.swiper-slide-prev {
                opacity: 0;
            }
        }

        &__counter {
            &-total,
            &-text,
            &-current {
                @mixin lead;
                color: var(--av-fixed-light);
            }
        }

        &_numbers_off {
            .a-slider__dots {
                display: none;
            }
        }
    }

    &:deep(.a-slider__nav) {
        margin-inline-start: 8px;
    }

    &__mobile__link {
        @media (--viewport-desktop) {
            display: none;
        }
    }

    &__show-more,
    &__show-less,
    &__mobile__link {
        .a-link {
            cursor: pointer;
            margin-top: 48px;

            &:focus {
                background: none;
            }
        }
    }

    &__show-less {
        &:deep(.a-glyph) {
            transform: rotate(180deg);
        }
    }
}

.s-side-menu {
    .a-container {
        .s-resource-highlight {
            padding-bottom: 0;
            padding-top: 56px;
            @media (--viewport-desktop) {
                &:not(&:first-child) {
                    padding: 0;
                    margin: 104px 0 0;
                }
            }

            &:deep(.a-slice-header) {
                margin: 0;
                @media (--viewport-desktop) {
                    margin: 0 0 40px;
                }

                &__title {
                    display: none;
                    @media (--viewport-desktop) {
                        display: block;
                    }
                }
            }
        }

        .a-resource-highlight-card {
            &__footer {
                display: block;
            }

            &__company {
                margin-bottom: 24px;
            }

            @media (--viewport-desktop-large) {
                &__footer {
                    display: flex;
                }

                &__company {
                    margin-bottom: 0;
                }
            }
        }
    }
}

/*
[dir='rtl'] {
    .s-resource-highlight__content {
        @media (--viewport-desktop-large) {
            mask-image: linear-gradient(to left, rgba(0, 0, 0, 1) 83.33%, rgba(0, 0, 0, 0));
        }
    }
}
*/
</style>
