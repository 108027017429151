<template>
    <component
        :is="to ? 'dynamic-link' : 'div'"
        class="a-resource-card"
        :to="image || to ? to : null"
        :class="{ 'a-resource-card_with-image': image, 'a-resource-card_with-glyph': glyph }"
    >
        <template v-if="title && glyph">
            <div class="a-resource-card__title">
                {{ title }}
                <a-glyph :name="glyph" />
            </div>
            <div v-if="text" class="a-resource-card__text">
                {{ text }}
            </div>
        </template>
        <template v-else>
            <a-picture
                v-if="image"
                class="a-resource-card__img"
                :link="image"
                :alt="imageAlt"
            />
            <div class="a-resource-card__title">
                {{ title }}
            </div>
            <a-link
                v-if="image && to"
                class="a-resource-card__link"
                :to="to"
                :text="text"
                :target="target"
            />
            <div v-else class="a-resource-card__text">
                {{ text }}
            </div>
        </template>
    </component>
</template>

<script>
import DynamicLink from '../dynamic-link/dynamic-link.vue';
import AGlyph from '../glyph/glyph.vue';
import ALink from '../link/link.vue';
import APicture from '../picture/picture.vue';

export default {
    name: 'AResourceCard',

    components: {
        APicture,
        AGlyph,
        ALink,
        DynamicLink,
    },

    props: {
        title: {
            type: String,
            required: true,
        },

        text: {
            type: String,
            default: '',
        },

        to: {
            type: String,
            default: undefined,
        },

        target: {
            type: String,
            default: undefined,
        },

        image: {
            type: String,
            default: '',
        },

        imageAlt: {
            type: String,
            default: '',
        },

        glyph: {
            type: String,
            required: false,
            default: undefined,
        },
    },
};
</script>

<style lang="postcss">
.a-resource-card {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    box-shadow: 0 5px 6px 0 rgba(138, 173, 207, 0.19);
    border: var(--av-border);
    background-color: var(--av-fixed-white);
    padding: 24px;
    display: block;
    text-decoration: none;
    transition: all linear 0.3s;

    &:hover {
        box-shadow: none;
    }

    &__title {
        @mixin body;

        padding-bottom: 8px;
        color: var(--av-brand-secondary);
    }

    &__text {
        color: var(--av-fixed-secondary);
    }

    &__img {
        .a-picture__img {
            margin-inline-start: 0;
            height: 64px;
        }
    }

    &_with-image {
        &:hover {
            box-shadow: 0 5px 6px 0 rgba(138, 173, 207, 0.19);
        }

        .a-resource-card {
            &__title {
                @mixin body;

                padding: 24px 0 8px;
                color: var(--av-nav-primary);
            }

            &__text {
                @mixin lead;
                color: var(--av-nav-primary);
                @media (--viewport-mobile-wide) {
                    @mixin title;
                }

            }

            &__link {
                @mixin lead;
                text-decoration: none;

                color: var(--av-brand-secondary);
                &:hover {
                    color: var(--av-brand-secondary-darker);
                }
                &:active {
                    color: var(--av-brand-secondary-darkest);
                }

                @media (--viewport-mobile-wide) {
                    @mixin title;
                }
            }
        }
    }

    &_with-glyph {
        min-height: 96px;
        min-width: 270px;
        .a-resource-card {
            &__title {
                @mixin paragraph;
                font-weight: 600;
                padding-inline-end: 32px;
                color: var(--av-fixed-secondary);
                position: relative;
                .a-glyph {
                    top: 0;
                    inset-inline-end: 0;
                    position: absolute;
                }
            }
            &__text {
                @mixin body;
                color: var(--av-fixed-light);
            }
        }
    }
}
</style>
