<template>
    <div class="a-review-card">
        <div class="a-review-card__content">
            <a-svg-sprite class="a-review-card__quotes" name="quote-mark" />
            <div class="a-review-card__review">
                <h3 v-if="title" class="a-review-card__title">
                    {{ title }}
                </h3>
                <v-clamp class="a-review-card__text" tag="p" :max-lines="5">
                    {{ text }}
                    <template #after="{ clamped, expand, collapse, expanded }">
                        <a-link
                            v-if="cta"
                            :text="cta.text"
                            style="vertical-align: top"
                            @click="handleCta"
                        />
                        <a-link
                            v-else-if="clamped"
                            :text="` ${lm}`"
                            style="vertical-align: top"
                            @click="expand"
                        />
                        <a-link
                            v-else-if="expanded"
                            :text="` ${ll}`"
                            style="vertical-align: top; margin-inline-start: 5px"
                            @click="collapse"
                        />
                    </template>
                </v-clamp>
            </div>
        </div>
        <div class="a-review-card__bottom" :class="{ 'a-review-card__bottom--logo-top': companyLogo }">
            <div v-if="logo && companyLogo" class="a-review-card__company">
                <a-tooltip v-if="companyName" :text="companyName" placement="top">
                    <a-picture class="a-review-card__logo a-review-card__logo--large" :link="logo" />
                </a-tooltip>
                <a-picture v-else class="a-review-card__logo a-review-card__logo--large" :link="logo" />
            </div>
            <div v-else-if="type !== 'company' && !noImage" class="a-review-card__user">
                <a-svg-sprite
                    v-if="!image"
                    class="a-review-card__user profile-svg"
                    name="default-user"
                    :class-name="{ 'a-review-card__default-icon': true }"
                />
                <a-picture v-else class="a-review-card__image" :link="image" />
            </div>
            <div class="a-review-card__author">
                <div class="a-review-card__author__base-info">
                    <div class="a-review-card__author__base-info__name">
                        {{ author }}
                    </div>
                    <a-dangerous-html class="a-review-card__author__base-info__role" :content="userRole" />
                </div>
                <div v-if="rate || personalCompanyLogo" class="a-review-card__author__company-details">
                    <template v-if="logo && personalCompanyLogo">
                        <a-tooltip v-if="companyName" :text="companyName" placement="top">
                            <a-picture class="a-review-card__logo" :link="logo" />
                        </a-tooltip>
                        <a-picture v-else class="a-review-card__logo" :link="logo" />
                    </template>
                    <div v-if="rate" class="a-review-card__rate">
                        <a-rate class="a-review-card__rate__stars" :rate="rate" />
                        <div class="a-review-card__rate__value">
                            {{ rate }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VClamp from 'vue-clamp';
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';
import ARate from '@core/components/rate/rate.vue';
import ASvgSprite from '@core/components/svg-sprite/svg-sprite.vue';
import ATooltip from '@core/components/tooltip/tooltip.vue';

export default {
    name: 'AReviewCard',

    components: {
        ARate,
        ALink,
        ASvgSprite,
        VClamp,
        ADangerousHtml,
        APicture,
        ATooltip,
    },

    props: {
        title: {
            type: String,
            required: false,
            default: undefined,
        },

        text: {
            type: String,
            required: true,
        },

        /**
         * Company type
         */
        type: {
            type: String,
            required: false,
            default: '',
            validator: (val) => ['personal', 'company', ''].includes(val),
        },

        logo: {
            type: String,
            required: false,
            default: undefined,
        },

        image: {
            type: String,
            required: false,
            default: undefined,
        },

        /**
         * Author name
         */
        author: {
            type: String,
            required: false,
            default: undefined,
        },

        companyName: {
            type: String,
            required: false,
            default: undefined,
        },

        /**
         * Author role
         */
        role: {
            type: String,
            required: false,
            default: undefined,
        },

        /**
         * Author rate
         */
        rate: {
            type: [Number, String],
            required: false,
            default: undefined,
            validator: (value) => value <= 5,
        },

        noImage: Boolean,

        lm: {
            type: String,
            default: 'Show more',
        },
        ll: {
            type: String,
            default: 'Show less',
        },

        cta: {
            type: Object,
            default: undefined,
        },
    },

    computed: {
        companyLogo() {
            return this.logo && this.logoPosition === 'top';
        },
        logoPosition() {
            return this.type === 'company' ? 'top' : 'bottom';
        },
        personalCompanyLogo() {
            return this.logo && this.type === 'personal' && this.logoPosition === 'bottom';
        },
        userRole() {
            let role = '';

            if (this.role) {
                role = this.role;
            }

            if (this.companyName) {
                role = this.role ? `${role}, ${this.companyName}` : this.companyName;
            }

            return role || 'Verified Acronis user';
        },
    },

    methods: {
        handleCta() {
            window.open(this.cta.link);
        },
    },
};
</script>

<style lang="postcss">
.a-review-card {
    position: relative;
    display: flex;
    flex-direction: column;
    @media (--viewport-desktop-wide) {
        padding-inline-start: 97px;
    }
    @media (--viewport-desktop-large) {
        padding-inline-start: 111px;
    }

    &__content {
        margin-bottom: 32px;
    }

    &__quotes {
        display: none;
        position: absolute;
        inset-inline-start: 0;

        @media (--viewport-desktop-wide) {
            fill: var(--av-brand-secondary-accent);
            display: block;
            width: 160px;
            height: 131px;
        }
    }

    &__review {
        @mixin colls 12;
    }
    &__text {
        @mixin paragraph;
        color: var(--av-fixed-secondary);
        .a-link {
            .a-link__content {
                font-size: inherit;
                line-height: inherit;
                background: none !important;
                font-weight: 600;
            }
        }
    }
    &__title {
        @mixin title-accent;
        margin-bottom: 16px;
        color: var(--av-nav-primary);
        &:before {
            content: '“';
            @media (--viewport-desktop-wide) {
                display: none;
            }
        }
        &:after {
            content: '”';
            @media (--viewport-desktop-wide) {
                display: none;
            }
        }
    }

    &__clamp {
        cursor: pointer;
        padding-inline-start: 7px;
    }

    &__bottom {
        display: flex;
        flex-direction: column;

        &--logo-top {
            flex-direction: column;
            align-items: flex-start;
        }

        @media (--viewport-mobile-wide) {
            &:not(.a-review-card__bottom--logo-top) {
                flex-direction: row;
                align-items: center;
            }
        }
    }

    &__company {
        margin-bottom: 8px;
        .a-review-card__logo {
            max-width: 227px;
            height: 40px;
        }
    }

    &__logo {
        margin-inline-end: 15px;

        &--large {
            opacity: 1;
        }
    }

    &__user {
        border-radius: 50%;
        overflow: hidden;
        width: 80px;
        height: 80px;
        margin-bottom: 8px;

        @media (--viewport-mobile-wide) {
            width: 96px;
            height: 96px;
            margin-bottom: 0;
            margin-inline-end: 16px;
        }
    }

    &__default-icon {
        width: 80px;
        height: 80px;
        background-color: var(--av-brand-accent);

        @media (--viewport-mobile-wide) {
            width: 96px;
            height: 96px;
        }
    }

    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;

        .a-picture__img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__author {
        @mixin paragraph;
        color: var(--av-fixed-secondary);
        display: flex;
        flex-direction: column;
        justify-content: center;

        &__base-info {
            display: flex;
            flex-direction: column;

            &__name {
                font-weight: 600;
            }
        }

        &__company-details {
            display: flex;
            align-items: center;
            margin-top: 8px;
            .v-popover {
                display: flex;
            }
            .a-review-card__logo {
                max-width: 227px;
                height: 40px;
            }
        }
    }

    &__rate {
        display: flex;
        align-items: center;

        &__stars {
            margin-inline-end: 10px;

            .a-rate__icon {
                width: 24px;
                height: 24px;
            }
        }

        &__value {
            @mixin lead-accent;
            color: var(--av-fixed-secondary);
        }
    }
}
</style>
