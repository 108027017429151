<template>
    <div v-if="isVisible" class="workloads">
        <div class="title">
            {{ translation('Select what you want to protect:') }}
        </div>
        <div class="workload-list">
            <a-tooltip
                v-for="(workload, workloadIndex) in workloadList"
                :key="`workload-${workloadIndex}`"
                :text="workload?.tooltip || ''"
                popper-class="el-tooltip__purchasing-workload"
                @click.native="$emit('changeWorkload', workload.name)"
            >
                <span
                    :class="['workload', { 'workload-active': hasSelected(workload.name) }]"
                    v-html="workload.title"
                />
            </a-tooltip>
        </div>
    </div>
</template>

<script>
import ATooltip from '@core/components/tooltip/tooltip.vue';
import mixin from '../mixin.js';

export default {
    name: 'SWorkloads',

    components: {
        ATooltip,
    },

    mixins: [mixin],

    props: {
        settings: {
            type: Object,
            default: null,
        },
    },

    emits: ['changeWorkload'],

    computed: {
        workloadList() {
            return this.settings.workloads || [];
        },

        isVisible() {
            return this.settings.workloads?.length;
        },
    },

    methods: {
        hasSelected(name) {
            return this.settings.workloads.find((item) => item.name === name).selected;
        },
    },
};
</script>

<style lang="postcss" scoped>
.title {
    @mixin nav-title-accent;
    width: 100%;
    text-align: center;
    color: var(--av-nav-primary);
}

.workload-list {
    gap: 8px;
    display: flex;
    flex-wrap: wrap;
    margin: 16px 0 0;
    justify-content: center;

    @media (--viewport-desktop) {
        gap: 16px;
    }
}

.workload {
    @mixin paragraph;
    display: block;
    cursor: pointer;
    user-select: none;
    padding: 8px 16px;
    border-radius: 24px;
    color: var(--av-brand-primary);
    border: 2px solid var(--av-brand-light);
    text-align: center;
}

.workload-active {
    border-color: var(--av-brand-primary);
    background-color: var(--av-brand-secondary-accent);
}
</style>

<style lang="postcss">
.el-tooltip__purchasing-workload {
    max-width: 230px;
}
</style>
