/* eslint-disable max-len */

export const Figma = 'https://www.figma.com/design/eM4W6JS2MjG1gp5YhKoMp7Dd/Slices?node-id=41593-60313&t=YdHBHy6Bx0MHVIeQ-4';

export const Default = {
    args: {
        translations: {
            title: 'Want to learn more? Join our weekly live demo!',
            lead: 'To learn more about our solutions you can join the weekly Acronis Cyber Cloud demo webinar led by our solution engineers.',
            paragraph: 'To learn more about our solutions you can join the weekly Acronis Cyber Cloud demo webinar led by our solution engineers.',
            'buttons.0.text': 'Try now',
            'buttons.1.text': 'Buy now',
            buttonText: 'Try now',
            actionButtonText: 'Buy now',
            'productPageCTA.description': 'From @price',
            'productPageCTA.promo.label': 'Save up to 30%',
            'productPageCTA.promo.description': 'From <del>@price</del> <b>@priceFull</b>',
        },
        title: 'title',
        lead: 'lead',
        paragraph: 'paragraph',
        buttons: [
            {
                to: '#',
                text: 'buttons.0.text',
                type: 'main',
            },
            {
                text: 'buttons.1.text',
                type: 'action',
                event: {
                    ecommerce: {
                        type: 'click',
                    },
                },
                optionsSURL: {
                    shopId: 882,
                    targetPathname: 'purl-consumer-standard-US',
                    params: {
                        task: 'WEB-42890',
                        cart: 's9033:247538',
                        'x-createmyaccount': 'NO',
                    },
                },
            },
        ],

        buttonTo: '#',
        buttonText: 'buttonText',
        buttonType: 'main',
        actionButtonTo: '#',
        actionButtonText: 'actionButtonText',
        actionButtonType: 'action',
        productPageCTA: {
            productId: 64,
            cleverBridgeCartID: 236611,
            description: 'productPageCTA.description',
            promo: {
                glyph: 'discount-fill',
                label: 'productPageCTA.promo.label',
                description: 'productPageCTA.promo.description',
                cleverBridgeCartList: [236611],
                coupon: 'basic',
            },
        },
    },
};
