import { Mock } from '@core/slices/pages/requirements/mock.js';

const translations = {
    'requirements.0.title': 'Title #1',
    'requirements.0.subtitle.text': 'Title with Body.',
    'requirements.0.subtitle.tooltip': "it's me again",
    'requirements.0.subsection.0.text': 'Microsoft Hyper- V Server 2019, 2016, 2012 / 2012 R2, 2008 / 2008 R2',
    'requirements.0.subsection.0.tooltip': 'hi, my dear friends',
    'requirements.1.title': 'Long Title Long Title #2',
    'requirements.1.sections.0.subsection.0': 'CPU 800 MHz',
    'requirements.1.sections.0.subsection.1': 'RAM 256 MB',
    'requirements.1.sections.0.subsection.2': 'Disk Space 150 MB Disk Space 150 MB',
    'requirements.1.sections.0.subsection.3': 'BIOS- or UEFI-based boot firmware',
    'requirements.1.sections.0.list.0': '<a href="google.com">Google</a>',
    'requirements.1.sections.0.list.1': '<span>Facebook</span>',
    'requirements.1.sections.1.subtitle': 'Title with list',
    'requirements.1.sections.1.subsection.0': 'CPU 800 MHz',
    'requirements.2.title.text': 'Title #3',
    'requirements.2.title.tooltip': 'Hi again, glad to see you!',
    'requirements.2.sections.0.subsection.0.text': 'Office 365',
    'requirements.2.sections.0.subsection.1.text': 'Azure5',
    'requirements.2.sections.0.subsection.2.text': 'Amazon EC2',
    'requirements.3.title': 'Title #4',
    'requirements.3.sections.0.subtitle': 'Title with list',
    'requirements.3.sections.0.subsection.0': 'CPU 800 MH`',
    'requirements.3.sections.0.subsection.1': 'RAM 256 MB`',
    'requirements.3.sections.0.subsection.2': 'Disk Space 150 MB Disk Space 150 MB',
    'requirements.3.sections.0.subsection.3': 'BIOS- or UEFI-based boot firmware',
    'requirements.4.title': 'Title #5',
    'requirements.4.sections.0.subsection.0.text': 'Windows PC',
    'requirements.4.sections.0.subsection.1.text': 'MAC',
    'requirements.4.sections.1.subtitle': 'Title with Body.',
    'requirements.4.sections.1.subsection.0.text': 'Windows PC',
    'requirements.4.sections.1.subsection.1.text': 'MAC',
    'requirements.5.title': 'Title #6',
    'requirements.5.sections.0.subtitle': 'Title with Body.',
    'requirements.5.sections.0.subsection.0': '2016 / 2012 R2 / 2012 / 2008 R2 / 2008 / Small Business Server 2011 Standard / 2003 R2 / 20',
};

export const Figma = 'https://www.figma.com/design/eM4W6JS2MjG1gp5YhKoMp7Dd/Slices?node-id=47482-896&t=JMJ2fbzoLIVoGwai-4';

export const Columnsx2 = {
    name: 'Columns x2',
    args: {
        translations: {
            ...translations,
            title: 'Test title',
            'slices.0.lead': 'On-Premise Management Console #1',
            'slices.1.lead': 'On-Premise Management Console #2',
            'link.text': 'Optional Link',
        },
        title: 'title',
        columnsCount: 2,
        slices: [
            {
                lead: 'slices.0.lead',
                systemInfo: Mock,
            },
            {
                lead: 'slices.1.lead',
                systemInfo: Mock,
            },
        ],
        link: { text: 'link.text', to: '#' },
    },
    argTypes: {
        columnsCount: {
            options: [2],
        },
    },
};

export const Columnsx3 = {
    name: 'Columns x3',
    args: {
        translations: {
            ...translations,
            title: 'Test title',
            'slices.0.lead': 'On-Premise Management Console #1',
            'slices.1.lead': 'On-Premise Management Console #2',
            'slices.2.lead': 'On-Premise Management Console #3',
        },
        title: 'title',
        columnsCount: 3,
        slices: [
            {
                lead: 'slices.0.lead',
                systemInfo: Mock,
            },
            {
                lead: 'slices.1.lead',
                systemInfo: Mock,
            },
            {
                lead: 'slices.2.lead',
                systemInfo: Mock,
            },
        ],
    },
    argTypes: {
        columnsCount: {
            options: [3],
        },
    },
};
