<template>
    <div class="s-header-with-form" :class="className">
        <div class="sticky-bar-wrapper" :class="{'submenu-shown': submenuShown}">
            <div ref="stickyBar" v-sticky="stickyOptions" class="sticky-bar">
                <div class="a-container">
                    <div ref="submenu" class="sticky-content">
                        <div ref="submenuProduct" class="product">
                            <div v-if="submenuShown" class="product-inner">
                                <div class="title">
                                    {{ navigationTitle }}
                                </div>
                                <div v-if="navigationSubtitle" class="subtitle">
                                    {{ navigationSubtitle }}
                                </div>
                            </div>
                            <s-dropdown-navigation
                                v-show="!submenuShown"
                                ref="mobileDropdown"
                                :menu="dropdownMenu"
                                :show-menu-label="showMenuLabel"
                                :menu-label="menuLabel"
                                :title="navigationTitle"
                                :event="event"
                            />
                        </div>
                        <div v-if="submenu.length && submenuShown" ref="submenuLinks" class="product-submenu">
                            <div v-for="(item, i) in submenu" :key="`submenu-${i}`" class="item">
                                <a-link
                                    v-if="item.to"
                                    class="link"
                                    v-bind="item"
                                    :title="item.text"
                                    :class="{ 'link-active': isActiveLink(item.to) }"
                                    :event="getDefaultALinkGAEvent(item)"
                                    :size="item.size || 'paragraph'"
                                />
                                <div v-if="item.sublinks || item.submenu" ref="dropdown">
                                    <div
                                        ref="sublist"
                                        class="sublist-toggler"
                                        :class="{ active: sublistOpened }"
                                        @click="toggleSublist"
                                    >
                                        {{ item.text }}
                                        <a-glyph class="sublist-glyph" name="drop-down" />
                                    </div>
                                    <div v-if="item.submenu" class="submenu">
                                        <div class="columns">
                                            <div v-for="(tab, j) in item.submenu.columns" :key="`${tab.text}-${j}`" class="column">
                                                <div class="column-title">
                                                    {{ tab.text }}
                                                </div>
                                                <template v-for="(link, l) in tab.links">
                                                    <a-link
                                                        v-if="link.to"
                                                        :key="`${link.to}-${l}`"
                                                        class="sublink"
                                                        v-bind="link"
                                                        :size="link.size || 'paragraph'"
                                                        :class="{ 'link-active': isActiveLink(link.to) }"
                                                        :event="getDefaultALinkGAEvent(link)"
                                                    />
                                                </template>
                                            </div>
                                        </div>
                                        <div v-if="item.submenu.bottomLinks" class="submenu-bottom">
                                            <template v-for="(link, l) in item.submenu.bottomLinks">
                                                <a-link
                                                    v-if="link.to"
                                                    :key="`${link.to}-${l}`"
                                                    class="link"
                                                    v-bind="link"
                                                    :size="link.size || 'paragraph'"
                                                    :event="getDefaultALinkGAEvent(link)"
                                                />
                                            </template>
                                        </div>
                                    </div>
                                    <div v-else-if="item.sublinks" class="sublist">
                                        <div class="sublist-inner">
                                            <template v-for="(link, j) in item.sublinks">
                                                <a-link
                                                    v-if="link.to"
                                                    :key="`sublink-${j}`"
                                                    class="sublink"
                                                    v-bind="link"
                                                    :size="link.size || 'paragraph'"
                                                    :class="{ 'link-active': isActiveLink(link.to) }"
                                                    :event="getDefaultALinkGAEvent(link)"
                                                />
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="isDesktop" class="background a-container">
            <a-picture :background="background" is-background fit="contain" />
        </div>

        <div class="main-content a-container">
            <div class="main-content-info">
                <a-label v-if="label?.text" v-bind="label">
                    {{ label.text }}
                </a-label>
                <h1 v-if="title" class="title">
                    {{ title }}
                </h1>
                <p v-if="subtitle" class="subtitle">
                    {{ subtitle }}
                </p>
                <p v-if="description" class="description">
                    {{ description }}
                </p>
                <div class="cta-block">
                    <a-product-cta v-if="ctaSettings" :settings="ctaSettings" />
                    <a-button
                        v-if="button"
                        v-bind="{...button, type: 'main'}"
                        :event="{ ...event, ...getButtonGA(button) }"
                    />
                </div>
                <div v-if="awardItems.length" class="awards">
                    <div v-for="(award, awardIndex) in awardItems" :key="awardIndex" class="award">
                        <p>
                            <a-picture
                                v-if="award.image"
                                :link="award.image"
                                :alt="award.title || ''"
                            />
                        </p>
                        <p v-if="award.description" v-html="award.description" />
                    </div>
                </div>
            </div>
            <div class="main-content-item">
                <s-trial-form :form="form" />
            </div>
        </div>
    </div>
</template>

<script>
import { debounce } from 'lodash';
import AButton from '@core/components/button/button.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALabel from '@core/components/label/label.vue';
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';
import AProductCta from '@core/components/product-cta/product-cta.vue';
import Sticky from '@core/directives/sticky.js';
import breakpoint from '@core/mixins/breakpoint.js';
import STrialForm from '@core/slices/pages/trial-form/trial-form.vue';
import SDropdownNavigation from './dropdown-navigation.vue';

export default {
    name: 'SHeaderWithForm',

    components: {
        AButton,
        AGlyph,
        ALabel,
        ALink,
        APicture,
        AProductCta,
        SDropdownNavigation,
        STrialForm,
    },

    directives: {
        Sticky,
    },

    mixins: [breakpoint],

    props: {
        label: {
            type: Object,
            default: null,
        },

        title: {
            type: String,
            default: '',
        },

        navigationTitle: {
            type: String,
            required: true,
        },

        subtitle: {
            type: String,
            default: null,
        },

        description: {
            type: String,
            default: '',
        },

        awards: {
            type: Array,
            default: null,
        },

        navigationSubtitle: {
            type: String,
            default: null,
        },

        ctaSettings: {
            type: Object,
            default: null,
        },

        overviewLink: {
            type: Object,
            default: null,
        },

        button: {
            type: Object,
            default: null,
        },

        background: {
            type: Object,
            required: true,
        },

        submenu: {
            type: Array,
            default: () => [],
        },

        menuLabel: {
            type: String,
            default: 'Menu',
        },

        overviewLabel: {
            type: String,
            default: 'Overview',
        },

        productId: {
            type: Number,
            default: null,
        },

        event: {
            type: Object,
            default: () => ({
                category: 'Conversions',
                location: 'Top CTA',
            }),
        },

        form: {
            type: Object,
            default: null,
        },
    },

    data() {
        return {
            stickyOptions: { parent: '.sticky-bar-wrapper' },
            SUBMENU_ITEMS_MARGIN: 48,
            productBarLoaded: false,
            sublinkActive: false,
            // hideOneButton: true,
            // hideTwoButtons: false,
            showDropdownMenu: false,
            showMenuLabel: false,
            sublistOpened: false, // should not be a boolean, because more than one sublist is possible
            submenuShown: true,
            calculateDebounced: null,
        };
    },

    computed: {
        className() {
            return {
                'is-loaded': this.productBarLoaded,
            };
        },

        dropdownMenu() {
            return [
                {
                    ...this.overviewLink,
                    text: this.overviewLabel,
                },
                ...this.submenu,
            ];
        },

        awardItems() {
            return this.awards.map((item) => ({ ...this.$store.state.awards.items[item] })).filter(Boolean);
        },
    },

    mounted() {
        if (!this.submenu.length) {
            this.productBarLoaded = true;
        }

        if (document.readyState === 'complete') {
            this.load();
        } else {
            window.addEventListener('load', this.load);
        }

        document.addEventListener('click', this.hideDropdown);
        window.addEventListener('scroll', this.calculate);

        this.calculateDebounced = debounce(this.calculate, 1000);
        window.addEventListener('resize', this.calculateDebounced);
    },

    beforeDestroy() {
        if (!this.submenu.length) {
            return;
        }
        window.removeEventListener('load', this.load);
        window.removeEventListener('scroll', this.calculate);
        window.removeEventListener('resize', this.calculateDebounced);
    },

    methods: {
        async load() {
            const { submenuLinks } = this.$refs;

            this.submenuLinksWidth = submenuLinks?.offsetWidth || 0;

            await this.calculate();

            this.productBarLoaded = true;
        },

        isResizableWidth() {
            const { submenuProduct, submenu } = this.$refs;

            const availableWidth = (submenu?.offsetWidth || 0) - this.SUBMENU_ITEMS_MARGIN;

            const submenuProductWidth = submenuProduct?.offsetWidth || 0;
            const linksWidth = !this.submenuShown ? 0 : (this.submenuLinksWidth || 0);

            return submenuProductWidth + linksWidth >= availableWidth;
        },

        async calculate() {
            this.submenuShown = true;
            await this.$nextTick();

            if (this.isResizableWidth()) {
                this.submenuShown = false;
                await this.$nextTick();
            }
        },

        isActiveLink(link) {
            return this.$route ? this.$route.path === `/${this.locale}${link}` : false;
        },

        getButtonGA(button) {
            return {
                label: button.to,
                action: button.dataLayer?.action || button.text,
                content: this.productName,
                ecommerce: {
                    type: button?.event?.ecommerce?.type,
                    license: button?.event?.ecommerce?.license,
                },
            };
        },

        toggleSublist() {
            this.sublistOpened = !this.sublistOpened;
        },

        getDefaultALinkGAEvent(item) {
            return {
                ...this.event,
                category: 'Interactions',
                label: item.text,
                action: 'top product tab',
            };
        },

        hideDropdown(event) {
            if (!this.sublistOpened || !this.$refs.dropdown) return;

            if (Array.isArray(this.$refs.dropdown)) {
                this.sublistOpened = this.$refs.dropdown.some((el) => el.contains(event.target));
            } else {
                this.sublistOpened = this.$refs.dropdown.contains(event.target);
            }
        },
    },

    serverPrefetch() {
        if (!this.awards) return;
        this.awards.forEach((id) => this.$store.dispatch('awards/getAward', id));
    },
};
</script>

<style lang="postcss" scoped>
.s-header-with-form {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-top: 88px;
    padding-bottom: 64px;
    color: var(--av-fixed-white);
    background: var(--av-nav-primary);

    /* should be over anchor-menu when dropdown is opened */
    z-index: 121;

    @media (--viewport-mobile-wide) {
        min-height: 600px;
        padding-bottom: 80px;
    }

    @media (--viewport-desktop-wide) {
        padding-top: 96px;
    }

    @media (--viewport-desktop-large) {
        padding-top: 88px;
    }

    &.is-loaded {
        .sticky-bar-wrapper {
            visibility: visible;
            opacity: 1;
        }
    }
}

.sticky-bar-wrapper {
    visibility: hidden;
    opacity: 0;
    width: 100%;
    height: 57px;
    margin-bottom: 40px;
    z-index: 11;

    @media (--viewport-mobile-wide) {
        height: 64px;
    }

    @media (--viewport-desktop-wide) {
        height: 65px;
    }

    &:not(.submenu-shown) .sticky-bar {
        height: 58px;

        &::after {
            position: absolute;
            content: '';
            z-index: 10;
            height: 1px;
            inset-inline-start: 0;
            inset-inline-end: 0;
            bottom: -2px;
            background: rgba(0, 101, 227, 0.4);
        }

        @media (--viewport-mobile-wide) {
            height: 66px;
        }
    }
}

.sticky-bar {
    top: 0;
    border-top: 1px solid rgba(0, 101, 227, 0.4);
    height: 57px;
    padding-top: 8px;
    position: relative;

    @media (--viewport-mobile-wide) {
        height: 65px;
        padding-top: 12px;
    }

    &.is-sticky {
        position: fixed;
        top: 0;
        width: 100%;
        box-shadow: 0 10px 20px var(--av-fixed-lightest);
        border-top: 0;
        background: var(--av-nav-primary);
        border-bottom: 1px solid rgba(0, 101, 227, 0.4);
        z-index: 1000;

        &::after {
            display: none;
        }

        .subtitle {
            display: none;
        }
    }
}

.sticky-content {
    display: flex;
    min-height: 40px;
    align-items: center;
    justify-content: flex-start;

    .product {
        white-space: nowrap;
    }

    .title {
        @mixin nav-title-accent;
        font-weight:400;
        color: var(--av-fixed-white);
        text-decoration: none;
        margin-top: -2px;
        margin-bottom: 4px;
    }

    .subtitle {
        position: absolute;
        color: var(--av-branded-icon-solid-lightest);
        font-size: 10px;
        line-height: 16px;
        letter-spacing: 4px;
        text-transform: uppercase;
        padding-inline-end: 48px;
        font-weight: 600;
    }
}

.product-submenu {
    padding-inline-start: 48px;
    display: flex;
    flex-shrink: 0;
    white-space: nowrap;
    align-items: center;

    .item {
        &:not(:last-child) {
            margin-inline-end: 24px;
        }
    }

    .link {
        @mixin paragraph-accent;
        display: block;

        &:deep(.a-link__content) {
            color: var(--av-fixed-white);
        }

        &:hover,
        &:focus {
            &:deep(.a-link__content) {
                background: none;
                color: var(--av-fixed-white-light);
            }
        }

        &.link-active {
            font-weight: 600;
        }

        &::before {
            display: block;
            content: attr(title);
            font-weight: 600;
            height: 0;
            overflow: hidden;
            visibility: hidden;
        }
    }

    .sublist-container {
        position: relative;
        padding-bottom: 12px;
        margin-bottom: -12px;
    }

    .sublist-toggler {
        @mixin paragraph-accent;
        position: relative;
        display: flex;
        align-items: center;
        background: none;
        border: none;
        padding: 4px 8px;
        margin: -4px -8px;
        color: var(--av-fixed-white);
        cursor: pointer;
        border-radius: 8px;

        & > * {
            pointer-events: none;
        }

        &:hover {
            color: var(--av-fixed-white-light);
        }

        &.active {
            background: var(--av-inversed-hover);
            color: var(--av-fixed-white);

            .sublist-glyph {
                transform: rotate(180deg);
                transition: transform 0.3s ease-out;
            }

            & + .sublist,
            & + .submenu  {
                display: block;
            }

            &::before {
                position: absolute;
                content: '';
                display: block;
                bottom: -20px;
                width: 0px;
                height: 0px;
                border-style: solid;
                border-width: 0 9px 7px 9px;
                border-color: transparent transparent rgb(64, 139, 234) transparent;
                inset-inline-end: 6px;
            }

            &::after {
                position: absolute;
                content: '';
                display: block;
                bottom: -21px;
                width: 0px;
                height: 0px;
                border-style: solid;
                border-width: 0 8px 6px 8px;
                border-color: transparent transparent #fff transparent;
                z-index: 1;
                inset-inline-end: 7px;
            }
        }
    }

    .sublist-glyph {
        margin-inline-start: 8px;
        fill: currentcolor;
        transition: transform 0.15s ease-out;
    }

    .sublist-inner,
    .submenu {
        background-color: var(--av-fixed-white);
        box-shadow: 0 10px 20px rgba(36, 49, 67, 0.2);
        border-radius: 6px;
        border: 1px solid var(--av-brand-secondary);
    }

    .sublist {
        display: none;
        position: relative;
        margin-inline-start: 50%;
        top: 24px;
    }

    .sublist-inner {
        position: absolute;
        transform: translateX(-50%);
    }

    .sublist-inner {
        padding: 32px;
        inset-inline: auto;
        margin: auto;
        display: flex;
        flex-direction: column;
        width: fit-content;
    }

    .sublist-inner {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .submenu {
        display: none;
        position: absolute;
        width: 960px;
        top: 56px;
        inset-inline: 0;
        margin: auto;

        .columns {
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            gap: 16px;
            padding: 32px;
        }

        .column {
            display: flex;
            flex-direction: column;
            gap: 8px;
            white-space: normal;
        }
    }

    .submenu-bottom {
        border-top: 1px solid var(--av-inversed-hover);
        background: var(--av-solid-brand-lightest);
        padding: 16px 32px;
        display: flex;
        justify-content: space-between;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;

        .link {
            &:deep(.a-link__content) {
                @mixin body-accent;
                color: var(--av-nav-primary);
            }

            &:deep(.a-glyph) {
                color: var(--av-brand-primary);
            }

            &:hover {
                &:deep(.a-link__content) {
                    color: var(--av-brand-secondary);
                }
            }
        }
    }

    .column-title {
        @mixin lead-accent;
        color: var(--av-nav-primary);
        margin-bottom: 8px;
    }

    .sublink {
        @mixin paragraph;
        position: relative;
        display: block;
        padding: 0;
        color: var(--av-brand-secondary);
        cursor: pointer;

        &::before {
            content: '';
            display: block;
            position: absolute;
            inset: -4px -8px;
            border-radius: 4px;
        }

        &:hover,
        &.link-hover {
            color: var(--av-brand-secondary);

            &::before {
                background: var(--av-brand-secondary-bright-hover);
            }
        }

        &:active {
            background: var(--av-brand-secondary-bright-active);
        }
    }
}

.background {
    &.a-container {
        top: 85px;
        position: absolute;
        inset-inline-start: 0;
        inset-inline-end: 0;
        width: 100%;
        margin: auto;
        height: calc(100% - 85px);

        @media (--viewport-desktop-wide) {
            top: 96px;
            height: calc(100% - 96px);
        }

        @media (--viewport-desktop-large) {
            top: 88px;
            height: calc(100% - 88px);
        }

        .a-picture {
            position: relative;
            width: 100%;
            height: 100%;

            @media (--viewport-desktop) {
                &:deep(.a-picture__img) {
                    object-position: center bottom !important;
                }
            }
        }
    }
}

.main-content {
    gap: 32px;
    width: 100%;
    flex-grow: 1;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: flex-end;

    @media (--viewport-desktop) {
        flex-direction: row;
    }

    .main-content-info {
        max-width: 568px;
        margin-inline-end: auto;
    }

    .main-content-item {
        @media (--viewport-desktop) {
            width: 50%;
        }

        &:deep(.s-form) {
            padding: 0;

            .a-container {
                @media (--viewport-desktop) {
                    padding: 0;
                }
            }

            .s-form-container {
                margin-top: 0;
            }

            .s-form-login,
            .s-form-update,
            .s-form-registration {

                @media (--viewport-desktop) {
                    padding: 32px;
                }

                .el-form {
                    margin: 16px 0 0;
                }
            }

            .s-form-registration__link {
                margin: 0;
            }
        }
    }

    .title {
        @mixin display;
        max-width: 568px;

        @media (--viewport-mobile-wide) {
            @mixin hero;
        }

        @media (--viewport-desktop-wide) {
            font-size: 48px;
            line-height: 56px;
        }

        @media (--viewport-desktop-large) {
            font-size: 56px;
            line-height: 64px;
        }
    }

    .subtitle {
        @mixin title-accent;
        margin-top: 40px;
    }

    .description {
        @mixin lead;
        margin-top: 24px;
    }

    .cta-block {
        margin-top: 40px;

        &:deep(.cta) {
            .cta-description {
                @mixin display;
                margin: 0;
                color: var(--av-fixed-info-light);
                b {
                    font-weight: 400;
                }
            }
        }

        &:deep(.a-button) {
            margin-top: 24px;
            min-width: 114px;
        }
    }

    .awards {
        @mixin paragraph;
        margin-top: 48px;

        .award {
            gap: 16px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            @media (--viewport-desktop-large) {
                gap: 32px;
                flex-direction: row;
            }

            &:not(:first-child) {
                margin-top: 24px;
            }
        }

        &:deep(.a-picture) {
            height: 48px;
            width: 126px;
            display: flex;
            align-items: center;

            .a-picture__img {
                margin: 0;
            }
        }
    }
}

</style>
