<template>
    <div class="a-header-card">
        <div class="a-header-card__content">
            <h2 class="a-header-card__content__title">
                {{ titleCard }}
            </h2>
            <p class="a-header-card__content__description">
                {{ description }}
            </p>
        </div>
        <div class="a-header-card__buttons">
            <a-button
                v-for="(btn, i) in buttons"
                :key="i"
                class="a-header-card__buttons__button"
                v-bind="btn"
                :event="setEvent(btn)"
            />
        </div>
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import contentMixin from '@core/mixins/content.js';
import form from '@core/mixins/form.js';
import styleMixin from '@core/mixins/style.js';

export default {
    name: 'AHeaderCard',

    components: {
        AButton,
    },

    mixins: [contentMixin, styleMixin, form],

    props: {
        titleCard: {
            type: String,
            default: '',
        },

        description: {
            type: String,
            default: '',
        },

        buttons: {
            type: Array,
            default: () => [],
            validator: (buttons) => buttons.length < 3,
        },
    },
};
</script>

<style lang="postcss">
.a-header-card {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: 24px;
    background: var(--av-fixed-white);
    box-shadow: var(--av-shadow-small);
    border: 1px solid var(--av-brand-secondary-light);
    border-radius: 4px;
    @media (--viewport-tablet) {
        flex-flow: row nowrap;
        align-items: center;
    }
    @media (--viewport-desktop) {
        flex-flow: row wrap;
    }
    &__content {
        @mixin colls 12;
        @media (--viewport-tablet) {
            width: calc(100% - 200px);
            padding-inline-end: 40px;
        }
        @media (--viewport-desktop) {
            @mixin colls 12;
            padding: 0;
        }
        @media (--viewport-desktop-wide) {
            width: calc(100% - 200px);
            padding-inline-end: 40px;
        }
        &__title {
            @mixin title;
            color: var(--av-nav-primary);
            font-weight: 500;
            margin-bottom: 8px;
        }
        &__description {
            @mixin body;
            color: var(--av-fixed-secondary);
            margin-bottom: 24px;
            @media (--viewport-tablet) {
                margin: 0;
            }
            @media (--viewport-desktop) {
                margin-bottom: 24px;
            }
            @media (--viewport-desktop-wide) {
                margin: 0;
            }
        }
    }
    &__buttons {
        @mixin colls 12;
        display: flex;
        flex-flow: row wrap;
        gap: 16px;
        justify-content: space-between;
        @media (--viewport-tablet) {
            width: 200px;
        }
        @media (--viewport-desktop) {
            @mixin colls 12;
        }
        @media (--viewport-desktop-wide) {
            width: 200px;
        }
        &__button {
            @mixin colls 12;
            padding: 8px 16px;
            @media (--viewport-mobile-wide) {
                @mixin colls 6;
            }
            @media (--viewport-tablet) {
                @mixin colls 12;
            }
            @media (--viewport-desktop) {
                @mixin colls 6;
            }
            @media (--viewport-desktop-wide) {
                @mixin colls 12;
            }
        }
    }
}
</style>
