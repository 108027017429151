export const MOCK_SMALL_CARD_COMPANY = {
    translations: {
        'card.title': 'IBM Japan Ltd.',
        'card.text': `Company info. The great benefit of using Acronis is securing the data, our IP (intellectual property).
            Data is king in our business.`,
        'labels.0.text': 'Success',
        'labels.1.text': 'Info',
        'labels.2.text': 'Neutral',
        'company.address': '19-21 Nihonbashi Hakozakicho, Chuo-ku, Tokyo 103-8510',
        'company.phone.text': '+359875328030',
        'company.website.text': 'Visit website',
    },
    title: 'card.title',
    text: 'card.text',
    labels: [
        { variant: 'success', text: 'labels.0.text' },
        { variant: 'info', text: 'labels.1.text' },
        { variant: 'neutral', text: 'labels.2.text' },
    ],
    company: {
        address: 'company.address',
        phone: { text: 'company.phone.text', to: 'tel:+359875328030' },
        website: { text: 'company.website.text', glyph: 'arrow', to: '#' },
    },
};
