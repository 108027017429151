<script lang="ts">
import { StatusCodes } from 'http-status-codes';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { ProductAPIQueryBuilder } from '@api/builders/product';
import { CARD_FIELDS_SUBSET } from '@model/const/blog';
import { assertHttpErrors } from '@utils/api-response';
import { ACRONIS_OG_IMAGE_ID, getOgImage, getTextDirection, getHtmlLang } from '@utils/html-meta';
import BlogAuthorPostsComponent from './component.vue';

const TOP_CARDS_COUNT = 1;
const CARDS_NUMBER_ON_PAGE = 12;

export default Vue.extend({
    name: 'BlogAuthorPostsContainer',

    async serverPrefetch(): Promise<void> {
        // ==== Init
        const locale = this.$route.params.locale;
        const slug = this.$route.params.author;

        // ==== Get an author and determine if we should proceed
        const authorRequest = new ProductAPIQueryBuilder('author')
            .setEntityPath('/api/blog/authors/')
            .setLocales([locale])
            .addMatchesAll('slug', '=', slug)
            .toObject();

        await this.$store.dispatch('blog/getEntity', { request: authorRequest });

        const authorState = this.$store.state.blog.author;

        assertHttpErrors([{ entity: authorState, throwIfEmpty: true }]);

        const author = authorState.items[0];

        const topPostsRequest = new ProductAPIQueryBuilder('topPost')
            .setEntityPath('/api/blog/posts/')
            .setLocales([locale])
            .addMatchesAll('author_id', '=', author.id)
            .setOutputOnly(CARD_FIELDS_SUBSET)
            .addSort('translation.published_at', 'desc')
            .addSort('id', 'desc')
            .setCustomParam('process-macros', '1')
            .setPaginate(1, TOP_CARDS_COUNT)
            .toObject();

        const latestCardsRequest = new ProductAPIQueryBuilder('latestCards')
            .setEntityPath('/api/blog/posts/')
            .setLocales([locale])
            .addMatchesAll('author_id', '=', author.id)
            .setOutputOnly(CARD_FIELDS_SUBSET)
            .setCustomParam('process-macros', '1')
            .addSort('translation.published_at', 'desc')
            .addSort('id', 'desc')
            .setPaginate(1, CARDS_NUMBER_ON_PAGE, TOP_CARDS_COUNT)
            .toObject();

        const translationsListRequest = new ProductAPIQueryBuilder('authorTranslationsList')
            .setEntityPath('/api/blog/authors/translations/')
            .addMatchesAll('author_id', '=', author.id)
            .setOutputOnly(['locale'])
            .setPaginate(1, 20)
            .toObject();

        await Promise.all([
            this.$store.dispatch('locales/getLocaleData', { locale }),
            this.$store.dispatch('slices/getSyncedData', { slice: 's-main-header', locale }),
            this.$store.dispatch('slices/getSyncedData', { slice: 's-global-footer', locale }),
            this.$store.dispatch('slices/getSyncedData', { slice: 'blog', locale }),
            this.$store.dispatch('blog/getEntity', { request: topPostsRequest }),
            this.$store.dispatch('blog/getEntity', { request: latestCardsRequest }),
            this.$store.dispatch('blog/getEntity', { request: translationsListRequest }),
        ]);

        assertHttpErrors([
            { entity: this.$store.state.blog.topPost },
            { entity: this.$store.state.blog.latestCards },
            { entity: this.$store.state.blog.authorTranslationsList },
        ]);

        // Building page meta
        this.$ssrContext.res.meta = this.getMeta(author);
    },

    computed: {
        ...mapGetters('config', ['$config']),
    },

    methods: {
        getMeta(author: Record<string, any>): any {
            const locale = this.$route.params.locale;
            const uiStrings: any = this.$store.state.slices.items.blog || {};
            const canonical = `https://${this.$config.domain}${this.$route.path}`;
            const imageId = `@${author.image_id || ACRONIS_OG_IMAGE_ID}`;
            const ogImage = getOgImage(imageId, this.$config.env.HEAD_SITE_MAIN_PUBLIC_BASE_URL_STORAGE);
            const title = `${author.name} | Acronis`;
            const description = uiStrings.homeMetaDescription;

            return {
                title,
                head: [
                    { tag: 'meta', name: 'title', content: title },
                    { tag: 'meta', name: 'description', content: description },
                    { tag: 'meta', property: 'og:title', content: title },
                    { tag: 'meta', property: 'og:description', content: description },
                    { tag: 'meta', property: 'og:image', content: ogImage },
                    { tag: 'meta', property: 'og:url', content: canonical },
                    { tag: 'meta', name: 'twitter:title', content: title },
                    { tag: 'meta', name: 'twitter:description', content: description },
                    { tag: 'meta', name: 'twitter:image', content: ogImage },
                    { tag: 'meta', name: 'twitter:url', content: canonical },
                    { tag: 'link', rel: 'image_src', href: ogImage },
                    { tag: 'link', rel: 'canonical', href: canonical },
                ],
                htmlAttrs: {
                    dir: getTextDirection(locale),
                    lang: getHtmlLang(locale),
                },
            };
        },

        async loadMoreCards(authorID: number, page: number): Promise<boolean> {
            const request = new ProductAPIQueryBuilder('latestCards')
                .setEntityPath('/api/blog/posts/')
                .setLocales([this.$route.params.locale])
                .addMatchesAll('author_id', '=', authorID.toString())
                .setOutputOnly(CARD_FIELDS_SUBSET)
                .addSort('translation.published_at', 'desc')
                .addSort('id', 'desc')
                .setPaginate(page, CARDS_NUMBER_ON_PAGE, TOP_CARDS_COUNT)
                .setAddMoreFlag(true)
                .toObject();

            await this.$store.dispatch('blog/getEntity', { request });

            return this.$store.state.blog.latestCards.httpStatus === StatusCodes.OK;
        },
    },

    render(h) {
        const props = { dispatchLoadMoreCards: this.loadMoreCards };
        return h(BlogAuthorPostsComponent, { props });
    },
});
</script>
