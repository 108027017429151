<template>
    <div class="event-video" :class="className" @click="handleClick">
        <div v-if="!locked" v-modal="ytId" class="video-content">
            <img class="video-img" :src="image" loading="lazy" />
            <div v-if="time" class="time">
                {{ time }}
            </div>
            <button
                ref="playButton"
                class="video-play"
                type="button"
                aria-label="Open video"
            >
                <a-glyph name="play" />
            </button>
        </div>
        <div v-else class="video-locked">
            <img class="video-img" :src="image" loading="lazy" />
            <div class="controls">
                <div v-if="time" class="time">
                    {{ time }}
                </div>
                <button class="video-play" type="button" aria-label="Open video">
                    <a-glyph name="play" />
                </button>
            </div>
            <div class="locked">
                <div class="title">
                    <template v-if="isDesktop">
                        {{ lockedTitleDesktop }}
                    </template>
                    <template v-else>
                        {{ lockedTitleMobile }}
                    </template>
                    <a-glyph
                        v-if="isFeatured"
                        class="locked-arrow"
                        name="arrow-long"
                        size="s"
                    />
                </div>
                <a-button
                    v-if="!isFeatured"
                    class="form-button"
                    :text="formButtonTitle"
                    type="main"
                    @click.stop="$emit('scrollToFormClick')"
                />
            </div>
        </div>
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import Modal from '@core/directives/modal.js';
import breakpoint from '@core/mixins/breakpoint.js';

export default {
    name: 'EventVideo',

    components: {
        AGlyph,
        AButton,
    },

    directives: {
        Modal,
    },

    mixins: [breakpoint],

    props: {
        ytId: {
            type: String,
            required: true,
        },

        time: {
            type: String,
            required: true,
        },

        locked: {
            type: Boolean,
            default: false,
        },

        isFeatured: {
            type: Boolean,
            default: false,
        },

        lockedTitleMobile: {
            type: String,
            required: true,
        },

        lockedTitleDesktop: {
            type: String,
            required: true,
        },

        formButtonTitle: {
            type: String,
            required: true,
        },
    },

    emits: ['scrollToFormClick'],

    data() {
        return {
            isMobileClicked: false,
        };
    },

    computed: {
        className() {
            return {
                feature: this.isFeatured,
                active: this.isMobileClicked,
            };
        },
        image() {
            return `//i3.ytimg.com/vi/${this.ytId}/0.jpg`;
        },
    },

    methods: {
        // used in parent component to trigger video playback
        triggerClick() {
            this.$refs.playButton?.click();
        },

        handleClick() {
            if (this.isDesktop) return;

            this.isMobileClicked = !this.isMobileClicked;
        },
    },
};
</script>

<style lang="postcss" scoped>
.event-video {
    cursor: pointer;
    border-radius: 4px;
    aspect-ratio: 16/9;
    position: relative;
    overflow: hidden;

    &.featured {
        .locked .title {
            @mixin paragraph;
        }
    }

    &.active {
        .video-locked {
            .locked {
                display: flex;
            }
            .controls {
                display: none;
            }
        }
    }

    @media (--viewport-desktop) {
        .video-locked:hover {
            .locked {
                display: flex;
            }
            .controls {
                display: none;
            }
        }
    }

    &:hover {
        .video-play {
            background-color: var(--av-nav-secondary);
            transition: background-color ease-out 0.1s;
        }
    }

    .video-inner {
        position: absolute;
        top: 0;
        inset-inline-start: 0;
        height: 100%;
        width: 100%;
        border: var(--av-border);
        border-radius: 4px;
        overflow: hidden;
    }

    .video-img {
        position: absolute;
        top: 0;
        inset-inline-start: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        transition: transform 0.3s ease-out;
    }

    .video-play {
        position: absolute;
        top: 50%;
        inset-inline-start: 50%;
        z-index: 1;
        transform: translate(-50%, -50%);
        display: flex;
        width: 48px;
        height: 48px;
        border: none;
        border-radius: 50%;
        background-color: var(--av-brand-secondary);
        transition: background-color ease-out 0.3s;
        cursor: pointer;

        @media (--viewport-desktop) {
            width: 72px;
            height: 72px;
        }

        .a-glyph {
            fill: var(--av-fixed-white);
            margin: auto;
            width: 22px;
            height: 22px;

            @media (--viewport-desktop) {
                width: 32px;
                height: 32px;
            }
        }
    }

    .time {
        @mixin paragraph;
        position: absolute;
        bottom: 16px;
        inset-inline-end: 16px;
        background: var(--av-fixed-secondary);
        padding: 0 8px;
        text-align: center;
        color: var(--av-fixed-white);
        border-radius: 4px;
    }

    .locked {
        display: none;
        position: absolute;
        inset-inline-start: 0;
        top: 0;
        inset-inline-end: 0;
        bottom: 0;
        background: rgba(0, 32, 77, 0.3);
        backdrop-filter: blur(10px);
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: var(--av-fixed-white);
        cursor: auto;

        .title {
            display: flex;
            align-items: center;
            flex-direction: column;
            font-weight: 500;
            text-align: center;
            padding: 0 16px;
        }

        @media (--viewport-tablet) {
            .title {
                @mixin caption;
            }
        }

        @media (--viewport-desktop) {
            .title {
                @mixin paragraph;
                flex-direction: row;
            }
        }
    }

    .locked-arrow {
        fill: var(--av-fixed-white);
        width: 19px;
        height: 19px;
        margin-top: 16px;
        margin-inline: auto;
        transform: rotate(90deg);

        @media (--viewport-desktop) {
            transform: none;
            margin-top: 0;
            margin-inline-start: 16px;
        }
    }

    .form-button {
        text-align: center;
        color: var(--av-fixed-white);
        background: var(--av-brand-secondary);
        min-width: 169px;
        margin: 16px auto 0;
    }
}
</style>
