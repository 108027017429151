<template>
    <div class="a-dropdown">
        <div v-dropdown="id" class="a-dropdown__link">
            <a-link
                :to="`#${id}`"
                :text="linkText"
                :target="target"
                glyph="drop-down"
                size="paragraph"
            />
        </div>
        <div :id="id" class="a-dropdown__body">
            <div class="a-dropdown__content">
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
import ALink from '@core/components/link/link.vue';
import Dropdown from '@core/directives/dropdown.js';

export default {
    name: 'ADropdown',

    components: {
        ALink,
    },

    directives: {
        Dropdown,
    },

    props: {
        id: {
            type: String,
            required: true,
        },
        linkText: {
            type: String,
            required: true,
        },
        target: {
            type: String,
            default: undefined,
        },
    },
};
</script>

<style lang="postcss">
.a-dropdown {
    position: relative;

    &__link {
        cursor: pointer;
        display: block;
    }

    &__content {
        padding: 0 16px;
        transition: all linear 0.3s;
    }

    &__body {
        background: var(--av-fixed-white);
        box-shadow: 0 6px 18px rgba(0, 32, 77, 0.2);
        border-radius: 2px;
        position: absolute;
        top: calc(100% + 16px);
        inset-inline-start: 0;
        transition: all linear 0.3s;
        height: 0;
        overflow: hidden;
        max-height: calc(100vh - 72px);
        transform: translateY(-15px);
    }

    .a-dropdown__link {
        .a-link__icon {
            transition: all linear 0.3s;
        }
    }

    &.is-dropdown-animate,
    &.is-dropdown {
        .a-dropdown__content {
            padding: 16px;
        }

        .a-dropdown__link {
            .a-link__icon {
                transform: rotate(180deg);
            }
        }
    }

    &.is-dropdown {
        .a-dropdown__body {
            overflow-y: auto;
        }
    }
}
</style>
