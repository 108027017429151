<template>
    <section class="acpc-side-menu">
        <div class="a-container">
            <div class="wrapper">
                <nav class="navigation">
                    <h4 class="title">
                        {{ navigation.title }}
                    </h4>
                    <template v-if="!isMobile && !isTablet">
                        <ul class="list">
                            <li
                                v-for="(item, i) in navigation.items"
                                :key="`side-menu-nav-item_${i}`"
                                class="item"
                                :class="{'active': section === item.section }"
                                @click="section = item.section"
                            >
                                {{ item.title }}
                            </li>
                        </ul>
                    </template>
                    <template v-else>
                        <el-select
                            v-model="section"
                            class="dropdown"
                            :hide-on-resize="isDesktop"
                        >
                            <el-option
                                v-for="(item,i) in navigation.items"
                                :key="`side-menu-mobile-item_${i}`"
                                class="item"
                                :label="item.title"
                                :value="item.section"
                            />
                        </el-select>
                    </template>
                    <a-link
                        v-if="navigation.link && !isMobile && !isTablet"
                        class="link"
                        v-bind="navigation.link"
                        :size="navigation.link.size || 'paragraph'"
                        accent
                    />
                </nav>
                <div v-if="currentContent" class="content">
                    <h5 v-if="currentContent.title" class="title">
                        {{ currentContent.title }}
                    </h5>
                    <p
                        v-if="currentContent.description"
                        class="description"
                        v-html="currentContent.description"
                    />
                    <div class="actions">
                        <a-button
                            v-if="currentContent.button"
                            class="button"
                            v-bind="currentContent.button"
                            :type="currentContent.button.type || 'main'"
                        />
                        <a-link
                            v-if="currentContent.link"
                            class="link"
                            v-bind="currentContent.link"
                            :size="currentContent.link.size || 'paragraph'"
                            accent
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import ALink from '@core/components/link/link.vue';
import breakpoint from '@core/mixins/breakpoint.js';

export default {
    name: 'SProductServiceMenu',
    components: {
        AButton,
        ALink,
        ElSelect: () => import('@uikit/ui-kit/packages/select'),
        ElOption: () => import('@uikit/ui-kit/packages/option'),
    },
    mixins: [breakpoint],
    props: {
        navigation: {
            type: Object,
            required: true,
        },
        sections: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            section: null,
        };
    },
    computed: {
        current() {
            return this.sections.find((el) => el.id === this.section);
        },
        currentContent() {
            return this.current?.content || null;
        },
    },
    created() {
        this.selectFirstSection();
    },
    methods: {
        selectFirstSection() {
            const firstItem = this.navigation.items[0];
            this.section = firstItem?.section || null;
        },
    },
};
</script>

<style lang="postcss" scoped>
.acpc-side-menu {
    padding-top: 40px;
    padding-bottom: 64px;
    border-top: 1px solid var(--av-brand-accent);
    .wrapper {
        display:flex;
        flex-flow: row wrap;
        align-items: flex-start;
        @media (--viewport-desktop) {
            gap: 113px;
        }
    }
    .navigation {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;
        @mixin colls 12;
        margin-bottom: 32px;
        @media (--viewport-desktop) {
            display: block;
            width: 276px;
            margin-bottom: 0;
        }
        .title {
            @mixin colls 12;
            @mixin lead-accent;
            text-align: center;
            color: var(--av-nav-primary);
            margin-bottom: 16px;
            @media (--viewport-tablet) {
                margin-bottom: 0px;
                text-align: start;
                @mixin colls 6;
            }
            @media (--viewport-desktop) {
                @mixin colls 12;
                margin-bottom: 24px;
            }
        }
        .dropdown {
            @mixin colls 12;
            @media (--viewport-tablet) {
                @mixin colls 6;
            }
        }
        .list {
            width: 256px;
            .item {
                @mixin colls 12;
                @mixin paragraph;
                color: var(--av-brand-primary);
                display: inline-block;
                padding: 12px 16px;
                border-radius: 4px;
                cursor: pointer;
                &.active {
                    background: var(--av-brand-secondary-light);
                    color: var(--av-nav-primary);
                }
                &:hover {
                    color: var(--av-nav-primary);
                }
            }
        }
        .link {
            margin-top: 24px;
        }
    }
    .content {
        @mixin colls 12;
        @media (--viewport-desktop) {
            @mixin colls 7;
        }
        .title {
            @mixin title-accent;
            color: var(--av-nav-primary);
            margin-bottom: 16px;
        }
        .description {
            @mixin paragraph;
            color: var(--av-fixed-primary);
            margin-bottom: 40px;
            @media (--viewport-tablet) {
                margin-bottom: 24px;
            }
        }
        .actions {
            text-align: center;
            @media (--viewport-tablet) {
                text-align: start;
            }
            .button {
                @mixin colls 12;
                min-width: 200px;
                margin-bottom: 16px;
                border-radius: 8px;
                justify-content: center;
                @media (--viewport-tablet) {
                    width: auto;
                    margin-bottom: 0px;
                    margin-inline-end: 16px;
                }
            }
            .link {
                @mixin colls 12;
                min-width: 200px;
                @media (--viewport-tablet) {
                    width: auto;
                }
            }
        }
    }
}
</style>
