<template>
    <div v-if="product.opened" class="s-purchasing-acp__card-disaster-recovery">
        <dropdown-disaster-recovery :settings="settings" :product="product" :price="productPrice(product)" />
        <div v-if="product.instance" class="s-purchasing-acp__card-disaster-recovery-feature">
            <div
                v-for="(instance, instanceIndex) in instanceList"
                :key="`instance-${instanceIndex}`"
                class="s-purchasing-acp__card-disaster-recovery-feature-item"
            >
                <div v-if="instanceIndex > 0" class="s-purchasing-acp__card-disaster-recovery-feature-item-title">
                    <a-glyph name="check-icon" />
                    {{ instanceTextName(instance) }}
                </div>
                <div class="s-purchasing-acp__card-disaster-recovery-feature-item-description">
                    {{ instanceTextDescription(instance, instanceIndex) }}
                </div>
            </div>
        </div>
        <notification
            v-for="(notification, notificationIndex) in product.notifications"
            :key="`notification-${notificationIndex}`"
            :description="notification"
            size="medium"
        />
    </div>
</template>

<script>
import AGlyph from '@core/components/glyph/glyph.vue';
import dropdownDisasterRecovery from '../dropdown/disaster-recovery.vue';
import mixin from '../mixin.js';
import notification from '../notification.vue';

export default {
    name: 'SCardDisasterRecovery',

    components: {
        AGlyph,
        dropdownDisasterRecovery,
        notification,
    },

    mixins: [mixin],

    props: {
        /**
         * Settings
         */
        settings: {
            type: Object,
            default: null,
        },

        /**
         * Product
         */
        product: {
            type: Object,
            default: null,
        },
    },

    computed: {
        instanceList() {
            return this.product.instance.filter((i) => i.description);
        },

        selected() {
            return this.product.selectedPeriod;
        },
    },

    methods: {
        instanceTextName(instance) {
            return instance.name.replace('@value', instance.list[this.selected]);
        },

        instanceTextDescription(instance, i) {
            const type = i ? 'value' : 'list';
            const str = instance[type][this.selected];
            return instance?.description.replace('@value', i ? str : str.toLowerCase());
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-purchasing-acp {
    &__card-disaster-recovery {
        margin: 16px 0 0;
        padding: 0 32px;
    }

    &__card-disaster-recovery-feature {
        @mixin descriptor;
        padding: 16px 0 0;
    }

    &__card-disaster-recovery-feature-item {
        margin: 8px 0 0;
        padding-inline-start: 18px;

        &:first-child {
            margin: 0;
            padding: 0;
        }

        .a-glyph {
            width: 10px;
            height: 10px;
            margin-inline-start: -18px;
            margin-inline-end: 8px;
            vertical-align: baseline;
            fill: var(--av-fixed-secondary);
        }
    }
}
</style>
