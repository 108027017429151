<template>
    <div v-if="isOpened" class="ribbon" :class="styleName">
        <div class="content">
            <div v-if="labelPrimary" class="label primary">
                {{ labelPrimary }}
            </div>
            <div v-if="labelAuxiliary" class="label secondary">
                {{ labelAuxiliary }}
            </div>
            <a-glyph
                v-if="iconName"
                :class="iconPosition"
                :name="iconName"
                size="m"
                class="icon"
            />
            <div v-if="labelCta" :class="{ 'before-icon': iconName }" class="cta">
                {{ labelCta }}
            </div>
        </div>

        <div class="close" @click="close">
            <a-glyph class="close-icon" name="times" />
        </div>
    </div>
</template>

<script>
import AGlyph from '@core/components/glyph/glyph.vue';
import breakpoint from '@core/mixins/breakpoint.js';

export default {
    name: 'SRibbon',
    components: {
        AGlyph,
    },
    mixins: [breakpoint],
    props: {
        linkUrl: {
            type: String,
            required: true,
        },
        labelPrimary: {
            type: String,
            required: true,
        },
        labelAuxiliary: {
            type: String,
            default: null,
        },
        labelCta: {
            type: String,
            default: null,
        },
        iconPosition: {
            type: String,
            default: 'start',
        },
        iconName: {
            type: String,
            default: null,
        },
        styleName: {
            type: String,
            default: 'yellow',
        },
    },
    data() {
        return {
            isOpened: true,
        };
    },
    methods: {
        close() {
            this.isOpened = false;
        },
    },
};
</script>

<style lang="postcss" scoped>
.ribbon {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: background-color linear 0.2s;

    @media (--viewport-tablet) {
        height: 56px;
    }

    .content {
        @mixin caption-accent;

        .label.primary,
        .cta {
            display: inline;
        }

        @media(--viewport-tablet) {
            font-size: 14px;
        }

        justify-content: center;
        align-items: flex-start;
        width: 100%;
        padding: 16px;
        text-decoration: none;
        color: var(--av-nav-primary);
        transition: color linear 0.2s;

        @media (--viewport-mobile-wide) {
            flex-direction: row;
            justify-content: left;
            align-items: center;
            display: flex;
        }

        @media (--viewport-tablet) {
            justify-content: center;
        }
    }

    .label {
        margin-inline-end: 2px;

        &.primary {
            order: 2;
            margin-inline-start: 0;
        }

        &.secondary {
            order: 4;
        }
    }

    .cta {
        @mixin caption-accent;
        transition: color linear 0.2s;
        align-items: center;
        flex-shrink: 0;
        order: 6;

        @media(--viewport-tablet) {
            font-size: 14px;
        }

        @media (--viewport-mobile-wide) {
            display: flex;
        }

        &.before-icon {
            @media(--viewport-tablet) {
                color: unset;
                fill: unset;
            }
        }
    }

    .cta-icon {
        margin-inline-start: 8px;
    }

    .cta,
    .cta-icon,
    .close-icon {
        color: var(--av-brand-primary);
        fill: var(--av-brand-primary);
    }

    .close {
        position: absolute;
        inset-inline-end: 24px;
        top: 0;
        bottom: 0;
        margin: auto;
        align-items: center;

        /* No close button for now
        display: flex; */
        display: none;
    }

    .icon {
        fill: var(--av-nav-primary);
        display: none;
        flex-shrink: 0;
        margin: 0 16px;

        @media (--viewport-tablet) {
            display: block;
        }

        &.start {
            order: 1;
            margin-inline-end: 16px;
        }

        &.divider {
            margin: 0 16px;
            order: 3;
        }

        &.end {
            margin-inline-end: 0;
            order: 5;

            @media (--viewport-mobile-wide) {
                margin-inline-end: 8px;
            }
        }
    }

    &.yellow {
        background: var(--av-fixed-warning-light);

        .content {
            color: var(--av-nav-primary);
        }

        &:hover {
            background: var(--av-nav-primary);

            .content {
                color: var(--av-fixed-white);
            }

            .cta,
            .cta-icon,
            .close-icon {
                color: var(--av-brand-icon-solid-lighter);
                fill: var(--av-brand-icon-solid-lighter);
            }

            .icon {
                fill: var(--av-fixed-white);
            }
        }
    }

    &.blue {
        background: var(--av-nav-secondary);

        .content {
            color: var(--av-fixed-white);
        }

        .cta,
        .cta-icon,
        .close-icon {
            color: var(--av-brand-icon-solid-lighter);
            fill: var(--av-brand-icon-solid-lighter);
        }

        .icon {
            fill: var(--av-fixed-white);
        }

        &:hover {
            background: var(--av-nav-primary);
        }
    }

    &.green {
        background: var(--av-fixed-success-light);

        .content {
            color: var(--av-nav-primary);
        }

        &:hover {
            background: var(--av-web-fixed-success-dark);

            .content {
                color: var(--av-fixed-white);
            }

            .cta,
            .cta-icon,
            .close-icon {
                color: var(--av-fixed-white);
                fill: var(--av-fixed-white);
            }

            .icon {
                fill: var(--av-fixed-white);
            }
        }
    }
}
</style>
