<template>
    <main class="main-page-new" :class="{ 'main-page-new--new-version': isNewVersion }">
        <div class="hero-wrapper" :class="{ 'has-case-study': casestudy }">
            <template v-if="hasHero">
                <div class="video-wrapper" :class="{'is-desktop': !isMobile}">
                    <video
                        id="heroVideo"
                        ref="heroVideo"
                        muted
                        preload
                    >
                        <source :src="`${baseURL}/videos/homepage-hero-v2.webm`" type="video/webm" />
                        <source :src="`${baseURL}/videos/homepage-hero-v2.mp4`" type="video/mp4" />
                    </video>
                </div>
                <div class="hero-background-mobile" :class="{'is-mobile': isMobile}" />
                <div class="shader" :class="{ 'videoLoaded': videoLoaded }" />
                <s-hero :content="hero" :show-control="showControl" :show-complexity="showComplexity" />
            </template>

            <div v-if="header" class="header-content">
                <a-picture class="header-content-background" :background="header.background" fit="cover" />
                <div class="header-content-wrapper">
                    <a-dangerous-html class="header-content-title" :content="header.title" />
                    <a-dangerous-html class="header-content-description" :content="header.description" tag="h1" />
                </div>
            </div>
            <s-products :content="products" />
            <s-interface v-if="discover && !countdown" :content="discover" />
            <s-message-highlights v-if="countdown" v-bind="countdown" />
            <s-integration :content="integration" />
        </div>
        <s-case-study v-if="casestudy" :content="casestudy" />
        <div class="content-wrapper">
            <s-stats :content="stats" />
            <s-spotlight :content="spotlight" />
        </div>
        <s-subscription :content="subscription" />
        <s-footer v-bind="footer" />
    </main>
</template>

<script>
import { mapGetters } from 'vuex';
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import APicture from '@core/components/picture/picture.vue';
import breakpoint from '@core/mixins/breakpoint';
import SFooter from '@core/slices/pages/global-footer/global-footer.vue';
import SMessageHighlights from '@core/slices/pages/message-highlights/message-highlights.vue';
import SCaseStudy from './slices/casestudy.vue';
import SHero from './slices/hero.vue';
import SIntegration from './slices/integration.vue';
import SInterface from './slices/interface.vue';
import SProducts from './slices/products.vue';
import SSpotlight from './slices/spotlight.vue';
import SStats from './slices/stats.vue';
import SSubscription from './slices/subscription.vue';

export default {
    name: 'SMainPageNew',
    components: {
        SMessageHighlights,
        ADangerousHtml,
        APicture,
        SHero,
        SProducts,
        SInterface,
        SIntegration,
        SCaseStudy,
        SStats,
        SSpotlight,
        SSubscription,
        SFooter,
    },
    mixins: [breakpoint],
    props: {
        hero: {
            type: Object,
            default: null,
        },
        header: {
            type: Object,
            default: null,
        },
        products: {
            type: Array,
            default: null,
        },

        discover: {
            type: Object,
            default: null,
        },
        integration: {
            type: Object,
            default: null,
        },
        casestudy: {
            type: Object,
            default: null,
        },
        stats: {
            type: Object,
            default: null,
        },
        spotlight: {
            type: Object,
            default: null,
        },
        subscription: {
            type: Object,
            default: null,
        },
        footer: {
            type: Object,
            default: null,
        },
        countdown: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            showComplexity: false,
            showControl: false,
            videoLoaded: false,
        };
    },
    computed: {
        ...mapGetters('config', ['$config']),

        baseURL() {
            return this.$config.env.HEAD_SITE_MAIN_PUBLIC_BASE_URL_STORAGE;
        },

        hasHero() {
            return Boolean(this.hero);
        },

        isNewVersion() {
            return Boolean(!this.hasHero && this.header);
        },
    },
    beforeDestroy() {
        if (!this.hasHero) return;
        const video = this.$refs.heroVideo;
        video?.removeEventListener('error', this.showSection);
    },
    mounted() {
        if (!this.hasHero || !this.$refs.heroVideo) return;
        this.handleVideo();
    },
    methods: {
        handleVideo() {
            const video = this.$refs.heroVideo;

            video.onstalled = () => this.showSection();
            video.onsuspend = () => this.showSection();
            video.onabort = () => this.showSection();
            video.addEventListener('error', () => this.showSection(), true);

            video.oncanplaythrough = () => {
                this.videoLoaded = true;
                this.showComplexity = true;
                video.play();
            };
            video.ontimeupdate = () => {
                this.showControl = video.currentTime > 3.2;
            };
            video.onended = () => {
                video.currentTime = 5.967;
                video.play();
            };
        },

        showSection() {
            setTimeout(() => {
                this.showComplexity = true;
                this.showControl = true;
            }, 3300);
        },
    },
    serverPrefetch() {
        if (!this.hasHero) return;

        this.$ssrContext.res.meta.head.push({
            tag: 'link',
            rel: 'preload',
            as: 'image',
            href: '/public/assets/images/home-hero-mobile.jpg',
        });
    },
};
</script>

<style lang="postcss" scoped>
.main-page-new {
    padding: 0;

    --hero-bg-color: #060F28;
    --hero-mobile-width: 768px;
    --hero-mobile-height: 811px;
    --hero-desktop-width: 1920px;
    --hero-desktop-height: 530px;

    @media all and (max-height: 750px) {
        --hero-desktop-height: 465px;
    }

    &--new-version {
        --hero-bg-color: #01124a;
    }

    .content-wrapper {
        background: var(--av-fixed-white);
    }

    .hero-wrapper {
        position: relative;
        overflow: hidden;
        background: var(--hero-bg-color);
        @media (--viewport-desktop) {
            padding-bottom: 40px;
        }
        &.has-case-study {
            @media (--viewport-desktop) {
                padding-bottom: 208px;
            }
            @media (--viewport-desktop-wide) {
                padding-bottom: 232px;
            }
            @media (--viewport-desktop-large) {
                padding-bottom: 256px;
            }
        }
        .video-wrapper {
            opacity: 0;
            position: absolute;
            inset-inline-start: 50%;
            transform: translateX(-50%);
            width: var(--hero-desktop-width);
            height: var(--hero-desktop-height);
            .is-desktop {
                opacity: 1;
            }
            @media (--viewport-desktop) {
                opacity:1;
            }
            &:after {
                content: '';
                position: absolute;
                width: 100%;
                height:149px;
                bottom: 0;
                background: linear-gradient(360deg, var(--hero-bg-color) 0%, rgba(10, 20, 44, 0) 100%);
                z-index: 3;
                @media (--viewport-tablet) {
                    height: 134px;
                }
                @media (--viewport-desktop) {
                    height: 152px;
                }
                @media all and (min-width:1921px) {
                    opacity: 0;
                }
            }
            video {
                position:absolute;
                min-height: var(--hero-desktop-height);
                width:100%;
                height:100%;
            }
        }

        .shader {
            opacity: 0;
            position: absolute;
            inset-inline-start: 50%;
            transform: translateX(-50%);
            width: var(--hero-mobile-width);
            height: var(--hero-mobile-height);
            max-width: 100%;
            transition: all 0.15s;

            @media (--viewport-desktop) {
                opacity: 1;
                box-shadow: none;
                width: var(--hero-desktop-width);
                height: var(--hero-desktop-height);
                background: var(--hero-bg-color);
            }
            @media all and (min-width: 1921px) {
                box-shadow: inset 0 -30px 40px 45px var(--hero-bg-color);
                height: calc(1px + var(--hero-desktop-height));
            }
            &.videoLoaded {
                background: transparent;
            }
        }
        .hero-background-mobile {
            position: absolute;
            inset-inline-start: 50%;
            transform: translateX(-50%);
            min-width: 100%;
            opacity: 1;
            width: var(--hero-mobile-width);
            height: var(--hero-mobile-height);
            background-image: url('/public/assets/images/home-hero-mobile.jpg');
            background-size: cover;
            background-position: center -32px;
            background-repeat: no-repeat;
            .is-mobile {
                opacity:1;
            }
            @media (--viewport-desktop) {
                opacity:0;
            }
        }
    }

    .header-content {
        position: relative;
    }

    .header-content-background {
        position: absolute;
        width: 960px;
        height: 633px;

        /* bottom fade */
        &:before {
            content: '';
            position: absolute;
            bottom: -20px;
            width: 100%;
            height: 100%;
            background: linear-gradient(0deg, var(--hero-bg-color) 10.56%, rgba(7, 27, 91, 0.00) 42.2%);
            z-index: 1;
        }
        &:after {
            content: '';
            position: absolute;
            height: 100%;
            bottom: 0;
            width: 100%;
            backdrop-filter: blur(2px);
        }

        @media (--viewport-desktop) {
            width: 2560px;
            height: 633px;
        }

        @media all and (min-width: 2560px) {
            inset-inline-start: 50%;
            transform: translateX(-50%);
            &:after {
                content: '';
                z-index: 1;
                position: absolute;
                width: 100%;
                height: 100%;
                box-shadow: 0px -90px 95px 70px var(--hero-bg-color) inset;
            }
        }

        &:deep(.a-picture__img) {
            position: absolute;
            bottom: 0;
            inset-inline-start: 50%;
            transform: translateX(-50%);
            width: 100%;
            height: 100%;
        }
    }

    .header-content-wrapper {
        position: relative;
        z-index: 2;
        inset: 0;
        display: flex;
        flex-direction: column;
        text-align: center;
        padding-top: 136px;
        padding-bottom: 64px;

        @media (--viewport-tablet) {
            padding-bottom: 104px;
        }
    }

    .header-content-title {
        @mixin display-accent;
        color: var(--av-inversed-primary, #fff);
        text-align: center;
        margin-bottom: 16px;
        padding-inline-start: 16px;
        padding-inline-end: 16px;

        @media (--viewport-mobile-wide) {
            @mixin hero-accent;
        }

        @media (--viewport-tablet) {
            padding-inline-start: 32px;
            padding-inline-end: 32px;
        }

        @media (--viewport-desktop) {
            @mixin large-accent;
            font-size: 64px;
            line-height: 72px;
            padding-inline-start: 64px;
            padding-inline-end: 64px;
        }
    }

    .header-content-description {
        @mixin title;
        color: var(--av-inversed-primary, #fff);
        padding-inline-start: 16px;
        padding-inline-end: 16px;

        @media (--viewport-tablet) {
            @mixin display;
            padding-inline-start: 32px;
            padding-inline-end: 32px;
        }

        @media (--viewport-desktop) {
            padding-inline-start: 64px;
            padding-inline-end: 64px;
        }
    }
}

[dir='rtl'] {
    .main-page-new .hero-wrapper {
        .video-wrapper {
            inset-inline-start: auto;
            inset-inline-end: 50%;
            transform: rotateY(180deg) translateX(50%);
        }
        .hero-background-mobile {
            transform: rotateY(180deg) translateX(50%);
        }
        .hero-background-mobile, .shader {
            inset-inline-start: auto;
            inset-inline-end: 50%;
        }
    }
}
</style>
