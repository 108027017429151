<template>
    <div class="header-sem-ebook">
        <div class="header-sem-ebook__wrapper">
            <div v-if="content.hasLogo" class="header-sem-ebook__logo">
                <dynamic-link to="/">
                    <a-logo to="/" type="full" fill="white" />
                </dynamic-link>
            </div>
            <div v-if="content.label" class="header-sem-ebook__label">
                {{ content.label }}
            </div>
            <div v-if="content.title" class="header-sem-ebook__title" v-html="content.title" />
        </div>
    </div>
</template>

<script>
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import ALogo from '@core/components/logo/logo.vue';

export default {
    name: 'SemEbookHeader',

    components: {
        DynamicLink,
        ALogo,
    },

    props: {
        content: {
            type: Object,
            default: null,
        },
    },
};
</script>

<style lang="postcss" scoped>
.header-sem-ebook {
    width: 100%;
    padding: 32px 16px 24px;
    color: var(--av-fixed-white);
    background: linear-gradient(180deg, #193a83 0%, #00204d 100%);

    &__logo {
        margin: 0 0 104px;
    }

    &__label {
        @mixin body;
    }

    &__title {
        @mixin display;
        margin: 8px 0 0;
        color: var(--av-fixed-white);
    }
}

@media (--viewport-tablet) {
    .header-sem-ebook {
        min-height: 344px;
        padding: 64px 32px 24px;

        &__logo {
            margin: 0 0 96px;
        }

        &__title {
            @mixin hero;
        }
    }
}

@media (--viewport-desktop) {
    .header-sem-ebook {
        &__wrapper {
            width: 635px;
        }
    }
}

@media (--viewport-desktop-wide) {
    .header-sem-ebook {
        padding: 64px 64px 24px;

        &__wrapper {
            width: 665px;
        }
    }
}

@media (--viewport-desktop-large) {
    .header-sem-ebook {
        &__wrapper {
            margin: 0 auto;
            width: 1312px;
            padding-inline-end: 553px;
        }
    }
}
</style>
