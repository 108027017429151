<template>
    <div v-sticky="stickyOptions" class="s-company-anchor-menu">
        <div class="s-company-anchor-menu__inner">
            <div
                class="s-company-anchor-menu__wrapper"
                :class="{ 's-company-anchor-menu__wrapper--dark': theme === 'dark' }"
            >
                <div class="a-container">
                    <ul class="s-company-anchor-menu__list">
                        <li
                            v-for="(item, index) in anchorMenuData.list"
                            :key="index"
                            class="s-company-anchor-menu__item"
                        >
                            <a-link
                                class="s-company-anchor-menu__link"
                                v-bind="item"
                                :class="getActiveLinkClass(item)"
                                :size="item.size || 'paragraph'"
                            />
                        </li>
                        <li v-if="anchorMenuData.cta" class="s-company-anchor-menu__item s-company-anchor-menu__cta">
                            <div
                                v-for="(item, index) in anchorMenuData.cta"
                                :key="index"
                                class="s-company-anchor-menu__cta-item"
                            >
                                <a-link
                                    class="s-company-anchor-menu__link"
                                    v-bind="item"
                                    :glyph="item.glyph || 'arrow'"
                                />
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { get } from 'lodash';
import ALink from '@core/components/link/link.vue';
import Sticky from '@core/directives/sticky.js';

export default {
    name: 'SCompanyAnchorMenu',

    components: {
        ALink,
    },

    directives: {
        Sticky,
    },

    props: {
        theme: {
            type: String,
            default: 'light',
        },
        anchorMenu: {
            type: Object,
            default: () => undefined,
        },
    },
    data() {
        return {
            active: false,
            stickyOptions: {
                parent: '.s-company-anchor-menu',
            },
        };
    },
    computed: {
        anchorMenuData() {
            if (this.anchorMenu) return this.anchorMenu;

            return get(this.$store.state.slices.items, 's-company-header', {});
        },
    },

    methods: {
        getActiveLinkClass(link) {
            const dark = this.theme === 'dark';
            const routePath = get(this.$route, 'path', '');
            const active = new RegExp(`${link.to}$`, 'ig').test(routePath);

            return {
                's-company-anchor-menu__link_active': active,
                's-company-anchor-menu__link_active--dark': active && dark,
            };
        },
    },
};
</script>

<style lang="postcss">
.s-sub-menu-product + .s-company-anchor-menu.is-sticky,
.s-header-product-new + .s-anchor-menu.is-sticky {
    .s-company-anchor-menu__inner {
        top: 64px;
    }
}

.s-company-anchor-menu {
    height: 64px;
    &.is-sticky {
        transition: 0.3s all;
        .s-company-anchor-menu__inner {
            position: fixed;
            z-index: 600;
            top: 0;
            width: 100%;
        }
    }

    &__top {
        top: 0;
        position: relative;
        z-index: 0;
    }

    &__wrapper {
        position: relative;
        top: 0;
        inset-inline-start: 0;
        width: 100%;
        padding: 16px 0;
        display: flex;
        background-color: var(--av-fixed-white);
        box-shadow: 0px 4px 8px rgba(36, 49, 67, 0.1);
        z-index: 0;

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            z-index: 1;
            width: 16px;
            background: no-repeat 50% 50% / cover;
        }

        &::before {
            inset-inline-start: 0;
            background-image: linear-gradient(
                270deg,
                rgba(255, 255, 255, 0) 0%,
                var(--av-fixed-white) 100%
            );
        }

        &::after {
            inset-inline-end: 0;
            background-image: linear-gradient(
                270deg,
                var(--av-fixed-white) 0%,
                rgba(255, 255, 255, 0) 100%
            );
        }

        &--dark {
            color: var(--av-brand-secondary);
            background: var(--av-nav-primary);
            box-shadow: 0 4px 8px rgba(36, 49, 67, 0.1);

            &::before,
            &::after {
                background: var(--av-nav-primary);
            }
        }
    }

    &__list {
        margin: 0 -16px;
        display: flex;
        align-items: center;
        width: calc(100% + 16px * 2);
        padding: 0 16px;
        overflow-x: auto;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            height: 0;
            background: rgba(255, 255, 255, 0);
        }
    }

    &__item {
        display: block;

        .a-link.nuxt-link-exact-active {
            color: var(--av-fixed-primary);
            .a-link__content {
                color: currentcolor;
            }
        }

        &:not(:last-of-type) {
            margin-inline-end: 24px;
        }
    }

    &__cta {
        @mixin descriptor;
        display: flex;
        font-weight: 600;
        flex-wrap: nowrap;
        margin-inline-start: auto;
        color: var(--av-brand-secondary);
    }

    &__cta-item {
        margin-inline-start: 16px;

        &:first-child {
            margin-inline-start: 56px;
        }

        .a-link {
            &__glyph_position_right {
                margin-inline-start: 10px;
            }
        }
    }

    &__link {
        display: block;
        white-space: nowrap;
        transition: all 0.3s;
        @mixin lead-strong;
        font-weight: 600;
        &:hover {
            color: var(--av-brand-primary-hover);
            .a-link__content {
                color: currentcolor;
            }
        }

        &_active {
            color: var(--av-fixed-primary);
            .a-link__content {
                color: currentcolor;
            }
            &:hover {
                color: var(--av-brand-primary-hover);
                .a-link__content {
                    color: currentcolor;
                }
            }
            &--dark {
                color: var(--av-brand-secondary);
                .a-link__content {
                    color: currentcolor;
                }
                &:hover {
                    color: var(--av-brand-primary);
                    .a-link__content {
                        color: currentcolor;
                    }
                }
            }
        }
    }
}
</style>
