<template>
    <div class="press-releases-media-contact">
        <h2 class="title">
            {{ title }}
        </h2>
        <div
            v-for="(contact, i) in contacts"
            :key="i"
            class="contact-info"
            :class="`contact-info-${i + 1}`"
        >
            <div class="info">
                <div class="name" v-html="contact.name" />
                <dynamic-link
                    v-if="contact.address"
                    :to="`${googleMapsLink}${contact.address}`"
                    class="address"
                >
                    <span v-html="contact.address" />
                </dynamic-link>
            </div>
            <div class="contacts">
                <dynamic-link
                    v-if="contact.email"
                    class="email"
                    :to="`mailto:${contact.email}`"
                >
                    {{ contact.email }}
                </dynamic-link>
                <dynamic-link
                    v-if="contact.phone"
                    :to="getPhoneLink(contact.phone)"
                    class="phone"
                >
                    {{ commonTranslations.phone }}: {{ contact.phone }}
                </dynamic-link>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import commonUtils from '@utils/common';

export default {
    name: 'PressReleasesMediaContact',

    components: { DynamicLink },

    props: {
        commonTranslations: {
            type: Object as any,
            required: true,
        },

        title: {
            type: String,
            required: true,
        },
        contacts: {
            type: Array,
            required: true,
        },
    },

    computed: {
        googleMapsLink() {
            return 'https://maps.google.com/maps?q=';
        },
    },

    methods: {
        getPhoneLink(phone) {
            return commonUtils.getTelLink(phone);
        },
    },
};
</script>

<style lang="postcss" scoped>
.press-releases-media-contact {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 24px 24px 42px;
    border: 1px solid rgba(64, 139, 234, 0.3);
    box-shadow: 0 4px 8px 0 rgba(36, 49, 67, 0.1);
    width: 100%;
    height: auto;
    min-height: 234px;

    @media (--viewport-tablet) {
        max-width: 344px;
    }

    @media (--viewport-desktop) {
        max-width: 309px;
        min-height: 264px;
    }

    @media (--viewport-desktop-wide) {
        max-width: 373px;
        min-height: 234px;
    }

    @media (--viewport-desktop-wide) {
        max-width: 426px;
        min-height: 234px;
    }
}

.title {
    @mixin title;
    color: var(--av-nav-primary);
    margin-bottom: 16px;
}

.name {
    @mixin paragraph-accent;
    color: var(--av-nav-primary);
}

.email {
    @mixin paragraph;
    color: var(--av-brand-primary);
}

.phone {
    display: block;
    @mixin paragraph;
    color: var(--av-brand-primary);
}

.address {
    display: block;
    font-family: var(--font-family-cyber);
    font-size: 16px;
    line-height: 24px;
    margin-top: 4px;
    margin-bottom: 4px;
    color: var(--av-fixed-secondary);
}

.contact-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
}

.contact-info:not(.contact-info-1) {
    margin-top: -40px;
}

.contacts {
    margin-top: 16px;
}
</style>
