<template>
    <main class="page-container">
        <div class="ribbon-placeholder" :class="{ 'crit-hidden': isMounted || !showRibbon }">
            <div v-loading="true" class="loading" el-loading-size="24" />
        </div>

        <s-locale-selector-ribbon :is-shown="isLocaleSelectorShown" />

        <s-ribbon-container :class="{ 'crit-hidden': !isMounted || isLocaleSelectorShown || !showRibbon }" :ribbons="ribbons" />

        <div class="page">
            <component
                v-bind="slice.data"
                :is="slice.name"
                v-for="(slice, position) in slices"
                :key="slice.id || `${slice.name}-${position}`"
            />
        </div>

        <template v-if="showPopup && isMounted">
            <s-popup-container :popups="popups" />
        </template>

        <s-chat v-if="!page.settings?.hide_chat" :locale="locale" />

        <s-unsupported-browsers />
    </main>
</template>

<script>
import Loading from '@uikit/ui-kit/packages/loading/src/directive';
import { merge } from 'lodash';
import Vue from 'vue';
import analytics from '@core/mixins/analytics.js';
import localeRibbon from '@core/mixins/locale-ribbon.js';
import SLocaleSelectorRibbon from '@core/slices/pages/locale-selector-ribbon/locale-selector-ribbon.vue';
import SUnsupportedBrowsers from '@core/slices/pages/unsupported-browsers/unsupported-browsers.vue';
import SChat from '@core/slices/shared/chat/chat.vue';
import SPopupContainer from '@core/slices/shared/popup/popup-container.vue';
import SRibbonContainer from '@core/slices/shared/ribbon/ribbon-container.vue';

import '@core/styles/sections/pages.pcss';

export default {
    name: 'PagesComponent',

    components: {
        SChat,
        SPopupContainer,
        SRibbonContainer,
        SUnsupportedBrowsers,
        SLocaleSelectorRibbon,
    },

    directives: {
        Loading,
    },

    mixins: [analytics, localeRibbon],

    computed: {
        page() {
            return this.$store.state.pages.page;
        },
        slices() {
            return this.page.body;
        },
        ribbons() {
            return this.$store.state.ribbon.data;
        },
        showRibbon() {
            return Boolean(this.ribbons.length);
        },
        popups() {
            return this.$store.state.popup.data;
        },
        showPopup() {
            return Boolean(this.popups.length);
        },
    },

    beforeCreate() {
        // TODO: make s-all-products use the same format as wrapper, tabs, side-menu
        // TODO: when it's done, `resolveNestedSlices` can be converted to oneliner or just be inlined in caller functions
        const resolveNestedSlices = (node) => {
            const nested = node.name === 's-all-products'
                ? (node.data?.slicesData || []).flatMap((x) => x.slices)
                : (node.data?.slices || []);

            return nested.filter(Boolean);
        };

        // Recursive routine for dynamic slices resolution (including `.data.slices[*].name`)
        const names = new Set();
        const doNameResolverRound = (node) => {
            names.add(node.name);
            resolveNestedSlices(node).forEach(doNameResolverRound);
        };

        this.$store.state.pages.page.body.forEach(doNameResolverRound);

        Array.from(names).filter(Boolean).forEach((fullname) => {
            const sectionName = fullname.startsWith('s-trueimage') ? 'trueimage' : 'pages';
            const sliceName = fullname.replace(/^s-/, '');
            Vue.component(fullname, () => import(`@core/slices/${sectionName}/${sliceName}/${sliceName}.vue`));
        });

        // Recursive routine for dynamic merging of .dataFrom
        const doDataFromResolverRound = (node) => {
            if (node.dataFrom) {
                if (process.env.VUE_ENV === 'server') {
                    // on the client it will be in store already
                    this.$store.dispatch('slices/getSyncedData', { slice: node.dataFrom, locale: this.locale });
                }

                // WARN: We mutate the state outside of mutation here, which is actually discouraged,
                // but in this particular case it should be safe because:
                // 1) Synced always returns a deep copy of the dataset
                // 2) Components/Slices MUST NOT mutate store.pages.page.body anyway
                // 3) We merge sliceData with syncedData below anyway
                // The only downside of this approach is we can't set `strict` flag for store
                const syncedData = this.$store.state.slices.items[node.dataFrom];
                const sliceData = node.data || {};
                node.data = merge({}, syncedData, sliceData); // eslint-disable-line
            }

            resolveNestedSlices(node).forEach(doDataFromResolverRound);
        };

        this.$store.state.pages.page.body.forEach(doDataFromResolverRound);
    },
};
</script>

<style lang="postcss" scoped>
.ribbon-placeholder {
    min-height: 80px;

    @media (--viewport-tablet) {
        min-height: 56px;
    }
}

.ribbon-placeholder .loading {
    height: 100%;
}

.page {
    position: relative;
}
</style>
