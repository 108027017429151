<template>
    <dynamic-link
        class="a-link"
        :to="to"
        :event="event"
        :class="className"
        :title="title"
        native-type="a"
        :has-no-ref="hasNoRef"
        :do-not-change-location="doNotChangeLocation"
        :target="target"
        :rel="rel"
        @click="handleClick"
    >
        <span v-if="tag" class="a-link__tag">{{ tag }}</span>
        <span
            class="a-link__content"
            :class="{ hasGlyph: !!glyph, glyphLeft: glyph && glyphPosition === 'left', glyphRight: glyph && glyphPosition === 'right' }"
        >
            <template v-if="text && glyph && hasNonBreakingGlyph">
                <!-- we need to have the last word attached to the glyph, but &nbsp; only binds to text so we have to have two of them -->
                <!-- eslint-disable-next-line  -->
                <a-dangerous-html tag="span" :content="text" />&nbsp;<nobr class="nowrap">&nbsp;
                    <a-glyph
                        class="a-link__content__glyph"
                        :name="glyph"
                        :class="{ left: glyphPosition === 'left', right: glyphPosition === 'right' }"
                        :size="glyphSize || undefined"
                    />
                </nobr>
            </template>
            <template v-else>
                <a-dangerous-html v-if="text" tag="span" :content="text" />
                <a-glyph
                    v-if="glyph"
                    class="a-link__content__glyph"
                    :name="glyph"
                    :class="{ left: glyphPosition === 'left', right: glyphPosition === 'right' }"
                    :size="glyphSize || undefined"
                />
            </template>
        </span>
        <span v-if="desc" class="a-link__desc">{{ desc }}</span>
    </dynamic-link>
</template>

<script>
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import { SIZES as GLYPH_SIZES } from '@core/components/glyph/constants.js';
import AGlyph from '@core/components/glyph/glyph.vue';
import { TYPES, SIZES, GLYPH_POSITIONS } from './constants.js';

export default {
    name: 'ALink',
    components: {
        AGlyph,
        ADangerousHtml,
        DynamicLink,
    },
    props: {
        /**
         * Link text
         */
        text: {
            type: String,
            default: undefined,
        },
        /**
         * Link URL
         */
        to: {
            type: String,
            default: undefined,
        },
        /**
         * Link Title
         */
        title: {
            type: String,
            default: undefined,
        },
        /**
         * Link type
         */
        type: {
            type: String,
            default: 'regular',
            validator: (value) => TYPES.includes(value),
        },
        /**
         * Link size
         */
        size: {
            type: String,
            default: 'body',
            validator: (value) => value === undefined || SIZES.includes(value),
        },
        /**
         * Glyph name
         */
        glyph: {
            type: String,
            default: undefined,
        },
        /**
         * Glyph position
         */
        glyphPosition: {
            type: String,
            validator: (value) => value === undefined || GLYPH_POSITIONS.includes(value),
            default: 'right',
        },
        glyphSize: {
            type: String,
            validator: (value) => value === undefined || GLYPH_SIZES.includes(value),
            default: undefined,
        },
        desc: {
            type: String,
            default: undefined,
        },
        tag: {
            type: String,
            default: undefined,
        },
        accent: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        hasNoRef: {
            type: Boolean,
            default: false,
        },
        target: {
            type: String,
            default: undefined,
        },
        rel: {
            type: String,
            default: undefined,
        },
        event: {
            type: Object,
            default: undefined,
        },
        doNotChangeLocation: {
            type: Boolean,
            default: false,
        },
        hasNonBreakingGlyph: {
            type: Boolean,
            default: false,
        },
    },

    emits: ['click'],

    computed: {
        className() {
            const { type, size, glyphPosition, accent, disabled, text } = this;
            return {
                [`a-link_type_${type}`]: type || 'regular',
                [`a-link_size_${size}`]: size,
                [`a-link_glyph_${glyphPosition}`]: glyphPosition,
                'a-link_noText': !text,
                'a-link_accent': accent,
                'a-link_disabled': disabled,
            };
        },
    },

    methods: {
        handleClick(event) {
            this.$emit('click', event);
        },
    },
};
</script>

<style lang="postcss">
.a-link {
    display: inline-block;
    transition: color linear 0.12s;
    text-decoration: none;
    background: none;
    padding: 0;
    border: 0;
    outline: 0;
    cursor: pointer;
    color: var(--av-brand-primary);
    &:hover {
        color: var(--av-brand-secondary);
        .a-link__content {
            color: var(--av-brand-secondary);
            &__glyph {
                fill: var(--av-brand-secondary);
            }
        }
    }
    &__desc {
        @mixin caption;
        margin-top: 8px;
        display: block;
        color: var(--av-fixed-light);
    }
    &__tag {
        @mixin caption;
        margin-bottom: 8px;
        display: block;
        color: var(--av-fixed-secondary);
    }
    &__content {
        color: var(--av-brand-primary);
        display: inline-block;
        transition: color linear 0.2s;
        &.hasGlyph {
            position: relative;
        }
        &.glyphLeft {
            padding-inline-start: 24px;
            .a-glyph {
                position: absolute;
            }
            span {
                display: inline-block;
            }
            .nowrap {
                position: absolute;
                top: 0;
                inset-inline-start: 0;
            }
        }
        &.glyphRight {
            .a-glyph {
                margin-bottom: 4px;
            }
            span {
                margin-inline-end: 6px;
            }
        }
        &__glyph {
            fill: var(--av-brand-primary);
            transition: fill linear 0.2s;

            &.left {
                inset-inline-start: 0;
            }
            &.right {
                inset-inline-end: 0;
            }
        }
    }

    /* Different font sizes / Default body */
    &_size {
        &_caption {
            .a-link__content {
                @mixin caption;
            }
        }
        &_body {
            .a-link__content {
                @mixin body;
                .a-glyph {
                    top: 4px;
                }
            }
        }
        &_paragraph {
            .a-link__content {
                @mixin paragraph;
                .a-glyph {
                    top: 4px;
                }
            }
        }
        &_lead {
            .a-link__content {
                @mixin lead;
                .a-glyph {
                    top: 5px;
                }
            }
        }
        &_title {
            .a-link__content {
                @mixin title;
                &.glyphLeft {
                    padding-inline-start: 32px;
                }
                .a-glyph {
                    margin-bottom: 7px;
                    top: 10px;
                }
            }
        }
    }
    &_accent {
        font-weight: 600;
    }
    &_disabled {
        pointer-events: none;
        opacity: 0.3;
    }

    /* Fix for usages with no text only glyph */
    &_noText {
        .a-link__content {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            .a-glyph {
                position: unset;
            }
        }
    }

    /* Support for download-file and direct links alongside new ones */
    &_type {
        &_regular {
            .a-link__content {
                color: var(--av-brand-primary);
                &__glyph {
                    fill: var(--av-brand-primary);
                }
            }
            &:hover {
                .a-link__content {
                    color: var(--av-brand-secondary);
                    &__glyph {
                        fill: var(--av-brand-secondary);
                    }
                }
            }
            &:focus {
                .a-link__content {
                    background: var(--av-brand-secondary-light);
                    color: var(--av-brand-primary);
                    &__glyph {
                        fill: var(--av-brand-primary);
                    }
                }
            }
            &:active,
            &.nuxt-link-active,
            &.nuxt-link-exact-active {
                .a-link__content {
                    background: none;
                    color: var(--av-brand-primary);
                    &__glyph {
                        fill: var(--av-brand-primary);
                    }
                }
            }
        }
        &_dark {
            .a-link__content {
                color: var(--av-nav-primary);
                &__glyph {
                    fill: var(--av-nav-primary);
                }
            }
            &:hover {
                .a-link__content {
                    color: var(--av-brand-primary);
                    &__glyph {
                        fill: var(--av-brand-primary);
                    }
                }
            }
            &:focus {
                .a-link__content {
                    background: var(--av-brand-secondary-light);
                    color: var(--av-nav-primary);
                    &__glyph {
                        fill: var(--av-nav-primary);
                    }
                }
            }
            &:active,
            &.nuxt-link-active,
            &.nuxt-link-exact-active {
                .a-link__content {
                    background: none;
                    color: var(--av-nav-primary);
                    &__glyph {
                        fill: var(--av-nav-primary);
                    }
                }
            }
        }
        &_light {
            .a-link__content {
                color: var(--av-branded-icon-solid-lightest);
                &__glyph {
                    fill: var(--av-branded-icon-solid-lightest);
                }
            }
            &:hover {
                .a-link__content {
                    color: var(--av-brandless-icon-solid-light);
                    &__glyph {
                        fill: var(--av-brandless-icon-solid-light);
                    }
                }
            }
            &:focus {
                .a-link__content {
                    background: var(--av-brand-secondary-light);
                    color: var(--av-branded-icon-solid-lightest);
                    &__glyph {
                        fill: var(--av-branded-icon-solid-lightest);
                    }
                }
            }
            &:active,
            &.nuxt-link-active,
            &.nuxt-link-exact-active {
                .a-link__content {
                    background: none;
                    color: var(--av-branded-icon-solid-lightest);
                    &__glyph {
                        fill: var(--av-branded-icon-solid-lightest);
                    }
                }
            }
        }
        &_direct {
            .a-link__content {
                @mixin title-accent;
                color: var(--av-brand-primary);
                .a-glyph {
                    fill: var(--av-brand-primary);
                    margin-bottom: 7px;
                }
            }
            &:hover {
                .a-link__content {
                    color: var(--av-brand-secondary);
                    &__glyph {
                        fill: var(--av-brand-secondary);
                    }
                }
            }
            &:focus {
                .a-link__content {
                    background: var(--av-brand-secondary-light);
                    color: var(--av-brand-primary);
                    &__glyph {
                        fill: var(--av-brand-primary);
                    }
                }
            }
            &:active,
            &.nuxt-link-active,
            &.nuxt-link-exact-active {
                .a-link__content {
                    background: none;
                    color: var(--av-brand-primary);
                    &__glyph {
                        fill: var(--av-brand-primary);
                    }
                }
            }
            .a-link__content {
                &.glyphLeft {
                    padding-inline-start: 32px;
                }
                .a-glyph {
                    top: 10px;
                }
            }
        }
        &_download-file {
            .a-link__content {
                @mixin lead-accent;
                color: var(--av-brand-primary);
                &__glyph {
                    fill: var(--av-brand-primary);
                }
            }
            &:hover {
                .a-link__content {
                    color: var(--av-brand-secondary);
                    &__glyph {
                        fill: var(--av-brand-secondary);
                    }
                }
            }
            &:active {
                .a-link__content {
                    color: var(--av-brand-primary);
                    &__glyph {
                        fill: var(--av-brand-primary);
                    }
                }
            }
            .a-link__tag,
            .a-link__desc {
                padding-inline-start: 24px;
            }
            .a-link__content__glyph {
                top: 6px !important;
                width: 14px;
                height: 14px;
            }
        }
    }

    /* Fix for usages with no text only glyph */
    &_noText {
        .a-link__content {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            &__glyph {
                position: relative;
            }
        }
    }

    /* Support for download-file and direct links alongside new ones */
    &_type {
        &_regular {
            .a-link__content {
                color: var(--av-brand-primary);
                &__glyph {
                    fill: var(--av-brand-primary);
                }
            }
            &:hover {
                .a-link__content {
                    color: var(--av-brand-secondary);
                    &__glyph {
                        fill: var(--av-brand-secondary);
                    }
                }
            }
            &:focus {
                .a-link__content {
                    background: var(--av-brand-secondary-light);
                    color: var(--av-brand-primary);
                    &__glyph {
                        fill: var(--av-brand-primary);
                    }
                }
            }
            &:active,
            &.nuxt-link-active,
            &.nuxt-link-exact-active {
                .a-link__content {
                    background: none;
                    color: var(--av-brand-primary);
                    &__glyph {
                        fill: var(--av-brand-primary);
                    }
                }
            }
        }
        &_secondary {
            .a-link__content {
                color: var(--av-brand-secondary);
                &__glyph {
                    fill: var(--av-brand-secondary);
                }
            }
            &:hover {
                .a-link__content {
                    color: var(--av-brand-primary);
                    &__glyph {
                        fill: var(--av-brand-primary);
                    }
                }
            }
            &:focus {
                .a-link__content {
                    background: var(--av-brand-secondary-light);
                    color: var(--av-brand-secondary);
                    &__glyph {
                        fill: var(--av-brand-secondary);
                    }
                }
            }
            &:active,
            &.nuxt-link-active,
            &.nuxt-link-exact-active {
                .a-link__content {
                    background: none;
                    color: var(--av-brand-secondary);
                    &__glyph {
                        fill: var(--av-brand-secondary);
                    }
                }
            }
        }
        &_dark {
            .a-link__content {
                color: var(--av-nav-primary);
                &__glyph {
                    fill: var(--av-nav-primary);
                }
            }
            &:hover {
                .a-link__content {
                    color: var(--av-brand-primary);
                    &__glyph {
                        fill: var(--av-brand-primary);
                    }
                }
            }
            &:focus {
                .a-link__content {
                    background: var(--av-brand-secondary-light);
                    color: var(--av-nav-primary);
                    &__glyph {
                        fill: var(--av-nav-primary);
                    }
                }
            }
            &:active,
            &.nuxt-link-active,
            &.nuxt-link-exact-active {
                .a-link__content {
                    background: none;
                    color: var(--av-nav-primary);
                    &__glyph {
                        fill: var(--av-nav-primary);
                    }
                }
            }
        }
        &_light {
            .a-link__content {
                color: var(--av-branded-icon-solid-lightest);
                &__glyph {
                    fill: var(--av-branded-icon-solid-lightest);
                }
            }
            &:hover {
                .a-link__content {
                    color: var(--av-brandless-icon-solid-light);
                    &__glyph {
                        fill: var(--av-brandless-icon-solid-light);
                    }
                }
            }
            &:focus {
                .a-link__content {
                    background: var(--av-brand-secondary-light);
                    color: var(--av-branded-icon-solid-lightest);
                    &__glyph {
                        fill: var(--av-branded-icon-solid-lightest);
                    }
                }
            }
            &:active,
            &.nuxt-link-active,
            &.nuxt-link-exact-active {
                .a-link__content {
                    background: none;
                    color: var(--av-branded-icon-solid-lightest);
                    &__glyph {
                        fill: var(--av-branded-icon-solid-lightest);
                    }
                }
            }
        }
        &_direct {
            .a-link__content {
                @mixin title-accent;
                color: var(--av-brand-primary);
                &__glyph {
                    fill: var(--av-brand-primary);
                }
            }
            &:hover {
                .a-link__content {
                    color: var(--av-brand-secondary);
                    &__glyph {
                        fill: var(--av-brand-secondary);
                    }
                }
            }
            &:focus {
                .a-link__content {
                    background: var(--av-brand-secondary-light);
                    color: var(--av-brand-primary);
                    &__glyph {
                        fill: var(--av-brand-primary);
                    }
                }
            }
            &:active,
            &.nuxt-link-active,
            &.nuxt-link-exact-active {
                .a-link__content {
                    background: none;
                    color: var(--av-brand-primary);
                    &__glyph {
                        fill: var(--av-brand-primary);
                    }
                }
            }
            .a-link__content {
                &.glyphLeft {
                    padding-inline-start: 32px;
                }
                .a-glyph {
                    top: 10px;
                }
            }
        }
        &_download-file {
            .a-link__content {
                @mixin lead-accent;
                color: var(--av-brand-primary);
                &__glyph {
                    fill: var(--av-brand-primary);
                }
            }
            &:hover {
                .a-link__content {
                    color: var(--av-brand-secondary);
                    &__glyph {
                        fill: var(--av-brand-secondary);
                    }
                }
            }
            &:active {
                .a-link__content {
                    color: var(--av-brand-primary);
                    &__glyph {
                        fill: var(--av-brand-primary);
                    }
                }
            }
            .a-link__tag,
            .a-link__desc {
                padding-inline-start: 24px;
            }
            .a-link__content__glyph {
                top: 6px !important;
                width: 14px;
                height: 14px;
            }
        }
    }

    .nowrap {
        white-space: nowrap;
        margin-inline-start: -0.5em;
    }
}
</style>
