<template>
    <div v-if="content && !content.isEbook" class="s-info-content" :class="{ ...className }">
        <div v-if="hasLabelBeforeTitle" class="s-info-content__tag">
            <a-label :variant="labelVariant">
                {{ content.label.text }}
            </a-label>
        </div>

        <template v-if="content.hasLogo">
            <div v-if="content.productLink" class="s-info-content__logo">
                <a class="a-link" :href="replaceLocale(content.productLink)">
                    <a-logo type="full" />
                    <span v-if="content.title" class="s-info-content__logo-title">{{ content.title }}</span>
                </a>
            </div>
        </template>
        <template v-else>
            <p v-if="content.title" class="s-info-content__title">
                {{ content.title }}
            </p>
        </template>

        <div v-if="hasLabelAfterTitle" class="s-info-content__label">
            <a-label :variant="labelVariant">
                {{ content.label.text }}
            </a-label>
        </div>

        <div class="s-info-content__media" hasMedia>
            <a-video-card
                v-if="content.video"
                :text="content.video.text"
                :yt-id="content.video.ytId"
                :time="content.video.time || ''"
                :image="content.video.image || 'b36bb46966f0d898925950e1b294aeec'"
            />
            <a-picture v-if="content.productImage || content.picture" :link="content.productImage || content.picture" />
        </div>

        <div v-if="content.videoPopup" class="s-info-content__video-popup">
            <div v-modal="content.videoPopup.ytId" class="s-info-content__video-popup-button">
                <a-glyph name="file-video" />{{ content.videoPopup.title }}
            </div>
            <a-modal
                :id="content.videoPopup.ytId"
                type="youtube"
                is-youtube
                :use-layout="false"
            >
                <a-youtube :yt-id="content.videoPopup.ytId" :params="content.videoPopup.ytParams || { autoplay: 1 }" />
            </a-modal>
        </div>

        <div v-if="content.subtitle" class="s-info-content__subtitle" v-html="content.subtitle" />

        <div v-if="content.description" class="s-info-content__description">
            <p
                v-for="(item, i) in content.description"
                :key="i"
                class="s-info-content__description-item"
                v-html="item"
            />
        </div>

        <div v-if="content.listGlyph" class="s-info-content__list">
            <ul class="s-info-content__list-glyph">
                <li v-for="(item, i) in content.listGlyph.items" :key="i" class="s-info-content__list-glyph-item">
                    <a-glyph name="check-icon" />{{ item }}
                </li>
            </ul>
        </div>

        <div v-if="content.listBullet" class="s-info-content__list">
            <div v-if="content.listBullet.title" class="s-info-content__list-bullet-title" v-html="content.listBullet.title" />
            <div v-if="content.listBullet.descriptions" class="s-info-content__list-bullet-description">
                <div
                    v-for="(item, i) in content.listBullet.descriptions"
                    :key="i"
                    class="s-info-content__list-bullet-description-item"
                    v-html="item"
                />
            </div>
            <ul class="s-info-content__list-bullet">
                <li
                    v-for="(item, i) in content.listBullet.items"
                    :key="i"
                    class="s-info-content__list-bullet-item"
                    v-html="item"
                />
            </ul>
        </div>

        <div v-if="content.listIcon" class="s-info-content__list">
            <div v-if="content.listIcon.title" class="s-info-content__list-icon-title">
                {{ content.listIcon.title }}
            </div>
            <ul class="s-info-content__list-icon">
                <li v-for="(item, i) in content.listIcon.items" :key="i" class="s-info-content__list-icon-item">
                    <a-icon :number="item.icon || 1" icon-variant />
                    <span class="s-info-content__list-icon-item-title">{{ item.title }}</span>
                    <span v-if="item.description" class="s-info-content__list-icon-item-description" v-html="item.description" />
                </li>
            </ul>
        </div>

        <div v-if="content.features" class="s-info-content__list">
            <div v-if="content.features.title" class="s-info-content__list-features-title">
                {{ content.features.title }}
            </div>
            <ul class="s-info-content__list-features">
                <li v-for="(item, i) in content.features.items" :key="i" class="s-info-content__list-features-item">
                    <p class="s-info-content__list-features-item-title">
                        {{ item.title }}
                    </p>
                    <p v-if="item.description" class="s-info-content__list-features-item-description" v-html="item.description" />
                </li>
            </ul>
        </div>

        <div v-if="content.text" class="s-info-content__text">
            <p
                v-for="(item, i) in content.text"
                :key="i"
                class="s-info-content__text-item"
                v-html="item"
            />
        </div>

        <div v-if="content.links" class="s-info-content__links">
            <p
                v-for="(item, i) in content.links"
                :key="i"
                class="s-info-content__links-item"
                v-html="item"
            />
        </div>

        <div v-if="content.account" class="s-info-content__account">
            <p class="s-info-content__account-title">
                {{ content.account.title }}
            </p>
            <div class="s-info-content__account-links">
                <p v-for="(link, i) in content.account.links" :key="i" class="s-info-content__account-links-item">
                    <a-popup
                        v-if="link.tooltip"
                        class="s-info-content__account-tooltip"
                        type="popover"
                        :text="link.tooltip"
                    />
                    <a-link v-bind="link" :size="link.size || 'paragraph'" />
                </p>
            </div>
            <p class="s-info-content__contact" v-html="content.account.contact" />
        </div>

        <s-info-content-awards v-if="!isMobile" :content="content" />

        <div v-if="content.textBottom" class="s-info-content__text-bottom">
            <p
                v-for="(item, i) in content.textBottom"
                :key="i"
                class="s-info-content__text-item"
                v-html="item"
            />
        </div>
    </div>
</template>

<script>
import AGlyph from '@core/components/glyph/glyph.vue';
import AIcon from '@core/components/icon/icon.vue';
import ALabel from '@core/components/label/label.vue';
import ALink from '@core/components/link/link.vue';
import ALogo from '@core/components/logo/logo.vue';
import AModal from '@core/components/modal/modal.vue';
import APicture from '@core/components/picture/picture.vue';
import AVideoCard from '@core/components/video-card/video-card.vue';
import AYoutube from '@core/components/youtube/youtube.vue';
import Modal from '@core/directives/modal.js';
import breakpoint from '@core/mixins/breakpoint.js';
import form from '@core/mixins/form.js';
import SInfoContentAwards from './info-content-awards.vue';

export default {
    name: 'SInfoContent',

    components: {
        AGlyph,
        AIcon,
        ALink,
        ALogo,
        APicture,
        ALabel,
        AVideoCard,
        AModal,
        AYoutube,
        SInfoContentAwards,
    },

    directives: {
        Modal,
    },

    mixins: [form, breakpoint],

    props: {
        /**
         * Content
         */
        content: {
            type: Object,
            default: null,
        },
    },

    computed: {
        className() {
            return {
                [`s-info-content-theme-${this.content.theme}`]: this.content.theme,
                [`s-info-content-version-${this.content.version}`]: this.content.version,
            };
        },

        hasMedia() {
            return this.content.video || this.content.productImage || this.content.picture;
        },

        hasLabelBeforeTitle() {
            return this.content.label && this.content.label?.position === 'beforeTitle';
        },

        hasLabelAfterTitle() {
            return this.content.label && !this.hasLabelBeforeTitle;
        },

        labelVariant() {
            return this.content.label.type || 'success';
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-info-content {
    color: var(--av-fixed-secondary);

    &:deep(a) {
        color: var(--av-brand-primary);

        &:hover {
            color: var(--av-brand-secondary);
        }
    }

    &__logo {
        text-align: start;

        &:deep(.a-logo) {
            margin: 0 0 8px;
            height: 24px;
            width: 118px;
        }
    }

    &__logo-title {
        @mixin display;
        font-weight: 400;
        margin-bottom: 8px;
        color: var(--av-nav-primary);
        display: block;
    }

    &__tag {
        display: flex;
        margin-bottom: 8px;
        justify-content: flex-start;
    }

    &__title {
        @mixin display;
        color: var(--av-nav-primary);
    }

    &__subtitle {
        @mixin title;
        margin: 24px 0 0;
        color: var(--av-nav-primary);
    }

    &__label {
        @mixin body-accent;
        text-transform: initial;
        &:deep(.a-text-label_variant_action) {
            color: var(--av-fixed-success-dark);
        }
    }

    &__media {
        margin: 40px auto 32px;

        &:deep(.a-picture__img) {
            max-width: 100%;
        }
    }

    &__video-popup-button {
        @mixin lead-accent;
        color: var(--av-brand-primary);
        display: flex;
        width: fit-content;
        align-items: center;
        cursor: pointer;

        .a-glyph {
            fill: var(--av-brand-primary);
            margin-inline-end: 8px;
        }
    }

    &__description {
        @mixin paragraph;
        margin: 40px 0 0;

        &-item {
            &:not(:first-child) {
                margin-top: 24px;
            }
        }
    }

    &__list {
        @mixin paragraph;
        display: none;
        text-align: start;
        margin: 24px 0 0;
        list-style-type: none;
    }

    &__list-icon {
        padding-inline-end: 64px;

        &-title {
            @mixin lead;
            font-weight: 600;
            margin: 16px 0 40px;
        }

        &-item {
            position: relative;
            padding-bottom: 40px;
            padding-inline-start: 72px;

            .a-icon {
                top: 0;
                inset-inline-start: 0;
                width: 48px;
                height: 48px;
                position: absolute;
            }
        }

        &-item-title {
            font-weight: 600;
            display: block;
            margin: 0 0 8px;
        }
    }

    &__list-bullet {
        &-title {
            @mixin paragraph-accent;
            font-weight: 600;
            margin: 16px 0 32px;
        }

        &-item {
            padding-inline-start: 16px;
            position: relative;

            &:not(:first-child) {
                margin: 16px 0 0;
            }

            &::before {
                inset-inline-start: 0;
                top: 10px;
                content: '';
                width: 4px;
                height: 4px;
                display: block;
                border-radius: 2px;
                position: absolute;
                background: var(--av-fixed-secondary);
            }
        }
    }

    &__list-glyph {
        margin: 24px 0 0;
        &-item {
            width: 50%;
            padding-inline-start: 16px;

            &:not(:first-child) {
                margin: 8px 0 0;
            }

            &:nth-child(odd) {
                padding-inline-end: 32px;
            }

            .a-glyph {
                vertical-align: baseline;
                margin-bottom: 8px;
                margin-inline-start: -24px;
            }
        }
    }

    &__list-features {
        &-title {
            @mixin lead;
            font-weight: 600;
        }

        &-item-title {
            margin: 16px 0 0;
            font-weight: 600;
        }
    }

    &__text,
    &__links {
        @mixin paragraph;
        margin: 24px 0 0;

        &-item {
            &:not(:first-child) {
                margin-top: 24px;
            }
        }
    }

    &__text-bottom {
        margin-top: 56px;
    }

    &__text-item {
        &:not(:first-child) {
            margin-top: 24px;
        }
    }

    &__links-item {
        &:not(:first-child) {
            margin-top: 8px;
        }
    }

    &__account {
        display: none;
    }

    &__contact {
        margin: 24px 0;
        padding: 24px 0 0;
        position: relative;

        &:deep(.a-link) {
            vertical-align: baseline;
        }

        &::before {
            top: 0;
            inset-inline-start: 0;
            content: '';
            height: 1px;
            width: 100%;
            display: block;
            max-width: 80px;
            position: absolute;
            background: var(--av-brand-light);
        }
    }
}

.s-form-beta {
    .s-form-trial-registration {
        &__subtitle-item {
            @mixin paragraph;
        }
    }

    .s-info-content {
        &__media {
            margin: 24px auto 0;

            &:deep(.a-picture__img) {
                max-width: 328px;
            }
        }

        &__description {
            margin: 24px 0 0;
        }

        &__list {
            display: block;
            margin: 24px 0 0;
            color: var(--av-fixed-secondary);
        }
    }
}

.s-info-content.s-info-content-theme-dark {
    color: var(--av-fixed-white);

    &:deep(a) {
        color: var(--av-branded-icon-solid-lightest);
    }

    .s-info-content {
        &__title,
        &__subtitle  {
            color: var(--av-fixed-white);
        }

        &__list-bullet-item {
            &::before {
                background: var(--av-fixed-white);
            }
        }
    }
}

.s-info-content.s-info-content-version-42019 {
    text-align: start;

    .s-info-content {
        &__title {
            @mixin title;

            @media (--viewport-mobile-wide) {
                @mixin display;
            }
        }

        &__subtitle {
            @mixin lead;

            @media (--viewport-mobile-wide) {
                @mixin title;
            }
        }

        &__description {
            margin-top: 24px;
        }

        &__list {
            display: block;
        }

        &__media {
            margin: 24px auto;

            &:deep(.a-picture__img) {
                max-width: 100%;
            }
        }
    }
}

@media (--viewport-mobile-wide) {
    .s-info-content {
        &__media {
            &:deep(.a-picture__img) {
                max-width: 386px;
            }
        }
    }
}

@media (--viewport-tablet) {
    .s-info-content {
        &__description {
            margin: 24px 0 0;
        }

        &__list {
            display: block;
        }

        &__list-glyph {
            display: flex;
            flex-wrap: wrap;
        }
    }

    .s-form-beta {
        .s-info-content {
            &__media {
                &:deep(.a-picture__img) {
                    max-width: 344px;
                }
            }
        }
    }
}

@media (--viewport-desktop) {
    .s-info-content {
        &__title {
            @mixin hero;
        }

        &__media {
            &:deep(.a-picture__img) {
                margin: 40px 0 32px;
            }
        }

        &__list-glyph {
            margin: 24px 0 0;
            flex-direction: column;

            &-item {
                margin: 0;
                width: 100%;

                &:not(:first-child) {
                    margin-top: 16px;
                }
            }
        }

        &__account {
            display: block;
            margin: 24px 0 0;
        }

        &__account-links {
            margin: 16px 0 0;
        }

        &__account-links-item {
            &:not(:first-child) {
                margin: 8px 0 0;
            }
        }

        &__account-tooltip {
            margin-inline-start: 14px;

            .trigger {
                bottom: 0;
                position: relative;
                vertical-align: middle;
            }
        }

        &__list {
            padding-inline-end: 64px;
        }
    }

    .s-form-beta {
        .s-info-content {
            &__title {
                @mixin display;
            }

            &__form {
                width: auto;
            }

            &__media {
                margin: 24px 0 0;

                &:deep(.a-picture__img) {
                    max-width: 471px;
                }
            }
        }
    }
}

@media (--viewport-desktop-wide) {
    .s-info-content {
        &__account {
            margin: 72px 0 0;
        }
    }
}

@media (--viewport-desktop-large) {
    .s-form-beta {
        .s-info-content {
            &__media {
                &:deep(.a-picture__img) {
                    width: 537px;
                    max-width: none;
                }
            }
        }
    }
}

[dir='rtl'] {
    .s-info-content {
        .form-error__icon {
            margin-right: 0;
            margin-inline-end: 8px;
        }
        &:deep(.el-checkbox__content-label) {
            padding-inline-start: 8px;
        }
    }
}
</style>
