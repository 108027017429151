/**
 * Button types
 *
 * @return {string[]}
 */
export const TYPES = ['main', 'action', 'promo', 'inverse'];

export const SIZES = ['m', 's'];

export default {};
