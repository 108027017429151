<template>
    <div class="event-countdown">
        <div class="bg-wrapper">
            <a-picture :background="background" />
        </div>
        <div v-if="eventStarted" class="started-text take-full-space">
            <span>
                {{ translations.eventStartsNowText }}
            </span>
        </div>
        <div v-else class="timer-block take-full-space">
            <div class="timer-text">
                {{ translations.topText }}
            </div>
            <div class="timer-values">
                <div
                    v-for="(item, i) in timerData"
                    :key="i"
                    :class="item.class"
                >
                    <template v-if="item.separator">
                        :
                    </template>
                    <template v-else>
                        <div class="time-digit">
                            {{ item.value }}
                        </div>
                        <div class="time-unit">
                            {{ item.unit }}
                        </div>
                    </template>
                </div>
            </div>
            <div class="timer-text">
                {{ translations.bottomText }}
            </div>
        </div>
    </div>
</template>

<script>
import APicture from '@core/components/picture/picture.vue';
import breakpoint from '@core/mixins/breakpoint.js';

export default {
    name: 'EventCountdown',

    components: { APicture },

    mixins: [breakpoint],

    props: {
        startDate: {
            type: String,
            required: true,
        },
        background: {
            type: Object,
            required: true,
        },
        translations: {
            type: Object,
            required: true,
        },
    },

    emits: ['countdown-over'],

    data() {
        const utcOffset = (new Date()).getTimezoneOffset() / 60;
        const utcOffsetDate = new Date(this.startDate);
        utcOffsetDate.setHours(utcOffsetDate.getHours() - utcOffset);

        return {
            startTs: utcOffsetDate.getTime(),
            eventStarted: false,
            timerId: 0,
            timerData: [
                { value: '00', unit: this.translations.days, class: 'timer-value' },
                { separator: true, class: 'timer-separator' },
                { value: '00', unit: this.translations.hours, class: 'timer-value' },
                { separator: true, class: 'timer-separator' },
                { value: '00', unit: this.translations.minutes, class: 'timer-value' },
                { separator: true, class: 'timer-separator' },
                { value: '00', unit: this.translations.seconds, class: 'timer-value' },
            ],
        };
    },

    mounted() {
        this.timerTick();
    },

    methods: {
        timerTick() {
            if (this.eventStarted) return;

            const msLeft = this.startTs - new Date().getTime();

            this.timerData = this.toDhmsFormat(msLeft);

            if (msLeft > 0) {
                if (this.timerId) clearTimeout(this.timerId);
                this.timerId = setTimeout(this.timerTick, 1000);
            } else {
                this.eventStarted = true;
                this.$emit('countdown-over');
            }
        },

        toDhmsFormat(ms) {
            const days = Math.floor(ms / (24 * 60 * 60 * 1000));
            const daysms = ms % (24 * 60 * 60 * 1000);
            const hours = Math.floor(daysms / (60 * 60 * 1000));
            const hoursms = ms % (60 * 60 * 1000);
            const minutes = Math.floor(hoursms / (60 * 1000));
            const minutesms = ms % (60 * 1000);
            const sec = Math.floor(minutesms / 1000);

            const res = [];

            // days
            res.push(
                { value: days >= 10 ? days : `0${days}`, unit: this.translations.days, class: 'timer-value' },
                { separator: true, class: 'timer-separator' },
            );

            // hours
            res.push(
                { value: hours >= 10 ? hours : `0${hours}`, unit: this.translations.hours, class: 'timer-value' },
                { separator: true, class: 'timer-separator' },
            );

            // minutes
            res.push(
                { value: minutes >= 10 ? minutes : `0${minutes}`, unit: this.translations.minutes, class: 'timer-value' },
                { separator: true, class: 'timer-separator' },
            );

            // seconds
            res.push({ value: sec >= 10 ? sec : `0${sec}`, unit: this.translations.seconds, class: 'timer-value' });

            return res;
        },
    },
};
</script>

<style lang="postcss" scoped>
@font-face {
    font-family: 'DIN Condensed';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('/public/assets/fonts/temp/DinCondensed/DinCondensed-Bold.woff2') format('woff2');
}

@font-face {
    font-family: 'DIN Condensed';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('/public/assets/fonts/temp/DinCondensed/DinCondensed-Bold.otf') format('otf');
}

@keyframes bg-rotation {
    100% { transform: rotate(360deg); }
}

.take-full-space {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.event-countdown {
    position: relative;
    overflow: hidden;
    width: 328px;
    height: 220px;
    padding: 0;
    margin: 0 auto;
    border: 1px solid var(--av-nav-secondary, #0065E3);
    border-radius: 4px;
    box-shadow: var(--av-shadow-small);
    transition: box-shadow 0.3s ease-out;
    cursor: default;

    @media (--viewport-mobile-wide) {
        width: 464px;
        height: 275px;
    }

    @media (--viewport-desktop) {
        width: 456px;
        height: 255px;
        margin: 0;
    }

    @media (--viewport-desktop-wide) {
        width: 537px;
        height: 300px;
    }

    .bg-wrapper {
        position: absolute;
        background: #060F28;
        overflow: hidden;
    }

    .a-picture {
        position: relative;
        animation: bg-rotation 30s linear infinite;
        top: -105px;
        left: -50px;
        width: 420px;
        height: 420px;

        @media (--viewport-mobile-wide) {
            top: -163px;
            left: -69px;
            width: 601px;
            height: 601px;
        }

        @media (--viewport-desktop) {
            top: -152px;
            left: -52px;
            width: 560px;
            height: 560px;
        }

        @media (--viewport-desktop-wide) {
            top: -151px;
            left: -36px;
            width: 601px;
            height: 601px;
        }

        &:deep(.a-picture__img) {
            max-width: initial;
            max-height: initial;
            width: 100%;
            height: 100%;
        }
    }

    .started-text {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: var(--av-inversed-primary, #FFF);
        text-shadow: 0 0 16px #F00;
        -webkit-text-stroke-width: 0.5px;
        -webkit-text-stroke-color: #FF005C;
        font-family: 'DIN Condensed', sans-serif;
        font-weight: 700;
        line-height: 120%;
        font-size: 40px;

        @media (--viewport-desktop) {
            font-size: 56px;
        }
    }

    .timer-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 32px 0;

        @media (--viewport-tablet) {
            padding: 42px 0;
        }

        @media (--viewport-desktop) {
            padding: 32px 0;
        }

        @media (--viewport-desktop-wide) {
            padding: 56px 0;
        }
    }

    .timer-text {
        @mixin paragraph-accent;
        color: var(--av-inversed-primary, #FFF);
        text-align: center;
    }

    .timer-values {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        gap: 12px;
        padding: 34px 0;
        margin-bottom: -24px;

        @media (--viewport-tablet) {
            padding: 36px 0;
        }
    }

    .timer-value {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .time-digit {
        color: var(--av-inversed-primary, #FFF);
        text-align: center;
        text-shadow: 0 0 16px #F00;
        -webkit-text-stroke-width: 0.5px;
        -webkit-text-stroke-color: #FF005C;
        font-family: 'DIN Condensed', sans-serif;
        font-size: 56px;
        font-weight: 700;
        line-height: 1;

        @media (--viewport-tablet) {
            font-size: 80px;
        }
    }

    .time-unit {
        color: var(--av-inversed-primary, #FFF);
        text-align: center;
        font-family: 'DIN Condensed', sans-serif;
        font-size: 18px;
        font-weight: 700;
        line-height: 120%;
        text-transform: uppercase;
        transform: translateY(-4px);

        @media (--viewport-tablet) {
            transform: translateY(-10px);
        }
    }

    .timer-separator {
        color: var(--av-inversed-primary, #FFF);
        font-family: 'DIN Condensed', sans-serif;
        font-size: 56px;
        line-height: 66px;
        transform: translateY(-14px);

        @media (--viewport-tablet) {
            font-size: 80px;
            line-height: 66px;
            transform: translateY(-6px);
        }
    }
}
</style>
