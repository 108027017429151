<template>
    <div class="error-form">
        <a-glyph class="glyph" name="attention2" />
        <div class="title">
            {{ title }}
        </div>
        <div v-if="code" class="code">
            [{{ code }}]
        </div>
        <a-dangerous-html v-if="text" :content="text" class="text" />
        <a-button
            class="button"
            type="main"
            :text="backToFormTitle"
            :event="{ doNotSendGA: true }"
            @click="click"
        />
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import AGlyph from '@core/components/glyph/glyph.vue';

export default {
    name: 'ErrorForm',

    components: {
        AButton,
        AGlyph,
        ADangerousHtml,
    },

    props: {
        title: {
            type: String,
            default: null,
        },
        code: {
            type: String,
            default: null,
        },
        text: {
            type: String,
            default: null,
        },
        backToFormTitle: {
            type: String,
            default: 'Try again',
        },
    },

    emits: ['click'],

    methods: {
        click() {
            this.$emit('click');
        },
    },
};
</script>

<style lang="postcss" scoped>
.error-form {
    color: var(--av-nav-primary);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 16px 0;
    margin: 0 auto;

    .title {
        @mixin title-accent;
        padding: 20px 0 0;
        text-align: center;
        color: var(--av-nav-primary);
    }

    .glyph {
        width: 26px;
        height: 26px;
        fill: var(--av-orange-bright);
    }

    .code {
        @mixin paragraph;
        margin: 16px 0 0;
        text-align: center;
        color: var(--av-fixed-light);
    }

    .button {
        position: relative;
        color: var(--av-fixed-white);
        font-weight: 600;
        min-width: 200px;
        margin: 40px auto 0;
        padding: 8px 16px;
    }
}
</style>
