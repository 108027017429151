<template>
    <div v-if="settings.order" class="s-product-order">
        <p class="s-product-order__title">
            {{ setTranslate('Order Summary') }}
        </p>
        <div v-if="!hasAddedProducts && orderDescriptionTitle" class="s-product-order__description" v-html="orderDescriptionTitle" />
        <template v-if="hasAddedProducts">
            <template v-for="(section, sectionIndex) in list">
                <div
                    v-for="(product, productIndex) in section.products"
                    :key="`order-product${sectionIndex}-${productIndex}`"
                    class="s-product-order__products"
                >
                    <div v-if="!product.editions" class="s-product-order__product">
                        <div class="s-product-order__item">
                            <p class="s-product-order__name" v-html="product.name" />
                            <template v-if="product.card === 'onboarding-service'">
                                <p class="s-product-order__period" v-html="product.perpetual.period" />
                                <p class="s-product-order__quantity">
                                    {{ setTranslate('Quantity:') }} {{ product.selectedQuantity }}
                                </p>
                            </template>
                            <template v-if="product.card === 'disaster-recovery'">
                                <div
                                    v-for="(item, instanceIndex) in product.instance"
                                    :key="`disaster-recovery-instance-${instanceIndex}`"
                                    class="s-product-order__instance"
                                >
                                    <p class="s-product-order__item">
                                        {{ item.name.replace('@value', '') }}&nbsp;
                                        <span v-if="item.list">{{ item.list[product.selectedPeriod] }}</span>
                                    </p>
                                </div>
                                <p class="s-product-order__quantity">
                                    {{ setTranslate('Quantity:') }} 1
                                </p>
                            </template>
                            <template v-if="product.card === 'cloud-storage'">
                                <p class="s-product-order__period">
                                    {{ cloudStoragePeriod(product) }}
                                </p>
                                <p class="s-product-order__size">
                                    {{ setTranslate('Size:') }} {{ cloudStorageTitle(product) }}
                                </p>
                                <div
                                    v-for="(item, addonIndex) in product.addons"
                                    :key="`cloud-storage-addon-${addonIndex}`"
                                    class="s-product-order__addons"
                                >
                                    <p v-if="item.added" class="s-product-order__product-addon">
                                        {{ item.title.replace(' + $XX.XX', '') }}
                                    </p>
                                </div>
                                <p class="s-product-order__quantity">
                                    {{ setTranslate('Quantity:') }} 1
                                </p>
                            </template>
                        </div>
                        <div class="s-product-order__item">
                            <p
                                v-if="hasDiscount(product)"
                                class="s-product-order__item-price s-product-order__item-price-promo"
                                v-html="setCurrency(productPrice(product) + productPrice(product, 'discount_absolute'))"
                            />
                            <p :class="itemClassName(product)" v-html="setCurrency(productPrice(product))" />
                        </div>
                        <div class="s-product-order__remove">
                            <div class="s-product-order__remove-btn" @click="productRemove(product)">
                                <a-glyph name="trash-o" />
                            </div>
                        </div>
                    </div>
                    <div
                        v-for="(edition, editionIndex) in editionAddedList(product.id)"
                        :key="`order-edition-${editionIndex}`"
                        class="s-product-order__product"
                    >
                        <div class="s-product-order__item">
                            <p class="s-product-order__name" v-html="productName(product, edition)" />
                            <p class="s-product-order__period">
                                {{ editionPeriod(product, edition, 'long') }}
                            </p>
                            <p class="s-product-order__quantity">
                                {{ setTranslate('Quantity:') }} {{ editionQuantity(edition) }}
                            </p>
                        </div>
                        <div class="s-product-order__item">
                            <div class="s-product-order__wrapper s-product-order__item-price">
                                <p
                                    :class="{'s-product-order__item-price-promo': hasDiscount(product, edition)}"
                                    v-html="editionLicensePrice(product, edition, 'full')"
                                />
                                <p v-if="hasDiscount(product, edition)" v-html="editionLicensePrice(product, edition, 'price')" />
                            </div>
                        </div>
                        <div class="s-product-order__remove">
                            <div class="s-product-order__remove-btn" @click="editionRemove(product, edition)">
                                <a-glyph name="trash-o" />
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <div class="s-product-order__price" :class="classOrderPrice">
                <div class="s-product-order__price-item">
                    <p class="s-product-order__price-text">
                        {{ settings.order.prices.total }}
                    </p>
                    <p class="s-product-order__price-tax">
                        {{ settings.order.prices.vat }}
                    </p>
                </div>
                <div class="s-product-order__price-wrapper">
                    <div class="s-product-order__price-total" v-html="productPriceTotal()" />
                    <template v-if="hasOrderDiscount">
                        <div class="s-product-order__price-total-save" v-html="productPriceSave" />
                        <div class="s-product-order__price-total-discount" v-html="setCurrency(totalPrice)" />
                    </template>
                </div>
            </div>
            <div class="s-product-order__button">
                <a-button
                    v-bind="settings.order.button"
                    :event="{ doNotSendGA: true }"
                    @click="onClick()"
                />
            </div>
        </template>
        <div class="s-product-order__links">
            <p v-for="(item, linksIndex) in settings.order.links" :key="`order-link-${linksIndex}`" class="s-product-order__links-item">
                <template v-if="item.to">
                    <a-link v-bind="item" :to="setLocale(item.to)" :text="setTranslate(item.text)" />
                </template><span v-else v-html="item.text" />
            </p>
        </div>
        <notification
            v-for="(item, notificationIndex) in notifications"
            :key="`order-notification-${notificationIndex}`"
            :title="item.title"
            :type="item.type || ''"
            :glyph="item.glyph || ''"
            :description="setLocale(item.description)"
        />
        <a-loader :is-visible="isVisibleSURL" />
    </div>
</template>

<script>
import { get, forEach, isEmpty, isEqual } from 'lodash';
import AButton from '@core/components/button/button.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALink from '@core/components/link/link.vue';
import ALoader from '@core/components/loader/loader.vue';
import analytics from '@core/mixins/analytics.js';
import cleverbridge from '@core/mixins/cleverbridge.js';
import currency from '@core/mixins/currency.js';
import purchasing from '@core/mixins/purchasing.js';
import mixin from '../mixin.js';
import notification from '../notification.vue';

export default {
    name: 'SProductOrder',

    components: {
        AGlyph,
        ALink,
        ALoader,
        AButton,
        notification,
    },

    mixins: [mixin, purchasing, analytics, cleverbridge, currency],

    props: {
        /**
         * Settings
         */
        settings: {
            type: Object,
            default: null,
        },
    },

    data: () => ({
        orderList: {},
        totalPrice: 0,
        totalPriceFull: 0,
    }),

    computed: {
        hasAddedProducts() {
            return !isEmpty(this.listSections().filter((s) => s.products.some((p) => p.added)));
        },

        hasOrderDiscount() {
            return this.settings?.promo?.isPromoActive && this.totalPriceFull > this.totalPrice;
        },

        classOrderPrice() {
            return {
                's-product-order__price-promo': this.hasOrderDiscount,
            };
        },

        list() {
            const result = this.listSections().map(({ products, ...sections }) => ({
                ...sections,
                products: products.filter((p) => p.added),
            }));

            return result.filter((section) => !isEmpty(section.products));
        },

        activeTab() {
            const licenseType = ['workloads', 'addons', 'services'];
            return licenseType[this.settings.sectionsActive];
        },

        orderDescriptionTitle() {
            return get(this, `settings.order[${this.activeTab}].descriptions`, '');
        },

        notifications() {
            const promo = this.settings?.promo?.isPromoActive ? get(this, 'settings.notifications', []) : [];
            const result = get(this, `settings.order[${this.activeTab}].notifications`, []);
            return promo.concat(result);
        },

        productPriceSave() {
            const result = this.setCurrency(this.totalPriceFull - this.totalPrice);
            return `${this.setTranslate('Save XX').replace('XX', result)}`;
        },
    },

    created() {
        this.orderList = this.productsList();
    },

    updated() {
        if (isEqual(this.orderList, this.productsList())) {
            return;
        }

        forEach(this.orderList, (quantity, cleverBridgeCartID) => {
            const list = this.productsList();
            const license = this.licenseData({ cleverBridgeCartID, promo: this.settings.promo });
            if (!list[cleverBridgeCartID] || list[cleverBridgeCartID] !== quantity) {
                const event = {
                    quantity,
                    eventAction: 'Remove from cart',
                    eventLabel: license.name || '',
                    ecommerce: { type: 'remove', license: cleverBridgeCartID },
                };
                this.ecommerceSendEvent({ event, license });
            }
        });

        forEach(this.productsList(), (quantity, cleverBridgeCartID) => {
            const list = this.orderList;
            const license = this.licenseData({ cleverBridgeCartID, promo: this.settings.promo });
            if (!list[cleverBridgeCartID] || list[cleverBridgeCartID] !== quantity) {
                const event = {
                    quantity,
                    eventAction: 'Add to cart',
                    eventLabel: license.name || '',
                    ecommerce: { type: 'click', license: cleverBridgeCartID },
                };
                this.ecommerceSendEvent({ event, license });
            }
        });

        this.orderList = this.productsList();
    },

    methods: {
        itemClassName(product) {
            return `s-product-order__item-${this.hasDiscount(product) ? 'discount' : 'price'}`;
        },

        productsList() {
            return this.listSections()
                .map((s) => s.products.map((p) => (p.added ? p.store : 0)))
                .reduce((p, c) => p.concat(c), [])
                .reduce((p, c) => ({ ...p, ...c }), {});
        },

        isActivePromo(cleverBridgeCartID) {
            return this.hasLicensePromo({ cleverBridgeCartID, promo: this.settings.promo });
        },

        hasDiscount(product, edition) {
            if (edition && this.isSubscriptionCustom(edition)) {
                const price = this.editionPrice(product, edition, 'price');
                const priceFull = this.editionPrice(product, edition, 'full');
                return priceFull > price;
            }
            return product.discount_absolute && product.discount_absolute !== product.price;
        },

        productName(product, edition) {
            return `${edition.name}  ${product.name}`;
        },

        editionLicensePrice(currentProduct, edition, type) {
            if (this.isSubscriptionCustom(edition)) {
                return this.setCurrency(this.editionMailboxes(currentProduct, edition, type));
            }

            const quantity = this.editionQuantity(edition);
            const cleverBridgeCartID = this.editionLicense(edition).cleverBridgeCartID;
            const license = this.licenseData({ cleverBridgeCartID, promo: this.settings.promo });

            const price = type === 'full' ? license.price + license.discount_absolute : license.price;
            return this.setCurrency(price * quantity);
        },

        setProductPriceTotal(type) {
            return this.listSections()
                .map((s) => s.products.map((p) => (p.added ? this.productPrice(p, type) : 0)))
                .reduce((p, c) => p + c.reduce((pv, cv) => pv + cv), 0);
        },

        productPriceTotal() {
            const price = this.setProductPriceTotal('price');
            const discount = this.setProductPriceTotal('discount_absolute');

            this.totalPrice = price;
            this.totalPriceFull = price !== discount ? price + discount : price;

            return this.setCurrency(this.totalPriceFull);
        },

        openStore(url) {
            window.location.assign(url);
        },

        onClick() {
            const productsID = Object.keys(this.orderList).toString();
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'AcronisEcommerce',
                eventLabel: productsID,
                eventAction: 'Proceed to checkout',
                eventContext: 'add',
                eventCategory: 'Conversions',
                eventContent: this.brandName,
                eventLocation: `${this.pageCategory}`,
                eventProductName: '',
                eventProductPrice: this.totalPrice,
                eventProductId: productsID,
                eventPosition: 1,
                ecommerce: {
                    currencyCode: this.productCurrency,
                    add: { products: this.ecommerceProducts(this.orderList)[0] },
                },
            });

            this.getSURL();
        },

        getSURL() {
            const cart = Object.keys(this.orderList);
            const quantity = cart.map((item) => [`quantity_${item}`, this.orderList[item]]);

            const options = this.storeOptionsSURL({
                shopId: 837,
                segment: 'corporate',
                params: {
                    scope: 'checkout',
                    cart: cart.toString(),
                    ...Object.fromEntries(quantity),
                    'x-source': 'expert_mode',
                },
            });
            const coupon = this.settings?.promo?.coupon || '';

            if (coupon && this.settings?.promo?.isPromoActive) {
                options.params.coupon = coupon;
            }

            this.redirectToCleverbridge(options);
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-product-order {
    width: 470px;
    margin: 106px 0 0;

    &__title {
        @mixin title-accent;
        margin: 0 0 24px;
        color: var(--av-nav-primary);
    }

    &__description {
        margin: 0 0 64px;
        text-align: center;
        padding: 64px 32px;
        border-top: 1px solid var(--av-brand-secondary-light);
        border-bottom: 1px solid var(--av-brand-secondary-light);

        &:deep(i) {
            cursor: pointer;
            color: var(--av-brand-secondary);
        }
    }

    &__product {
        @mixin body;
        display: flex;
        padding: 24px 0;
        line-height: 16px;
        color: var(--av-fixed-secondary);
        justify-content: space-between;
        border-top: 1px solid var(--av-brand-secondary-light);

        &:not(:first-child) {
            margin: 8px 0 0;
        }
    }

    &__item {
        &:first-child {
            width: 100%;
        }
    }

    &__wrapper {
        text-align: end;
        margin-inline-start: 32px;
    }

    &__remove {
        padding-inline-start: 3px;

        &-btn {
            padding: 8px;
            cursor: pointer;
            margin: -5px 0 0;

            &:hover {
                border-radius: 4px;
                background: var(--av-brand-secondary-bright-hover);
            }

            &:active {
                background: var(--av-brand-secondary-bright-active);
            }
        }
    }

    &__item-discount {
        text-align: end;
    }

    &__item-price-promo {
        @mixin lead;
        font-weight: 400;
        text-decoration: line-through;
    }

    &__instance {
        margin: 8px 0 0;
    }

    &__name {
        @mixin lead-accent;
        max-width: 345px;
        color: var(--av-nav-primary);
    }

    &__quantity {
        @mixin body;
        margin: 8px 0 0;
        color: var(--av-fixed-secondary);
    }

    &__size,
    &__period,
    &__addons {
        margin: 8px 0 0;
    }

    &__item-price {
        @mixin lead-strong;
        color: var(--av-nav-primary);
    }

    &__links {
        margin: 48px 0 0;
    }

    &__links-item {
        &:not(:first-child) {
            margin: 8px 0 0;
        }

        .a-glyph {
            margin: 0 0 2px;
            margin-inline-start: 8px;
        }

        &:deep(a) {
            white-space: nowrap;
            text-decoration: none;
            color: var(--av-brand-secondary);
        }

        .a-link {
            font-weight: 600;
        }
    }

    &__price {
        display: flex;
        padding: 24px 0 0;
        justify-content: space-between;
        border-top: 1px solid var(--av-brand-secondary-light);
    }

    &__price-item {
        color: var(--av-nav-primary);
    }

    &__price-text {
        @mixin title;
    }

    &__price-tax {
        @mixin caption;
        margin: 8px 0 0;
        color: var(--av-fixed-secondary);
    }

    &__price-wrapper {
        text-align: end;
    }

    &__price-total {
        @mixin display-strong;
        padding: 8px 0 0;
        color: var(--av-nav-primary);
    }

    &__price-promo {
        .s-product-order {
            &__price-total {
                @mixin title;
                font-weight: 400;
                text-decoration: line-through;
                color: var(--av-nav-primary);
            }

            &__price-total-save {
                @mixin title;
                color: var(--av-fixed-success);
            }

            &__price-total-discount {
                @mixin display-strong;
                color: var(--av-fixed-success);
            }
        }
    }

    &__button {
        @mixin paragraph-accent;
        margin: 24px 0 0;
        color: var(--av-fixed-white);

        .a-button {
            width: 100%;
            user-select: none;
        }
    }

    .s-notification {
        margin: 24px 0 0;
    }
}
</style>
