<template>
    <div class="gated-form-wrapper">
        <div class="gated-form">
            <s-form-container :form="form" @submit="onSubmit" />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import phoneInput from '@core/mixins/phone-input.js';
import SFormContainer from '@core/slices/pages/trial-form/components/form/form.vue';
import commonUtils from '@utils/common';

export default {
    name: 'SGatedForm',
    components: {
        SFormContainer,
    },
    mixins: [phoneInput],
    emits: ['gatedFormSubmit'],
    data() {
        return {
            form: null,
        };
    },
    computed: {
        ...mapState({
            preset: (state) => state.slices.items?.['s-vroi-calculator-gated-form'] || null,
        }),
    },
    beforeMount() {
        commonUtils.globalOverflowProperty('hidden');
        const fields = this.preset?.form?.registration?.fields;
        if (!fields) return;
        this.form = this.preset.form;
    },
    beforeDestroy() {
        commonUtils.globalOverflowProperty('auto');
    },
    methods: {
        onSubmit(form) {
            const userData = form?.userData || {};
            this.$emit('gatedFormSubmit', {
                data: {
                    company: userData?.company || '',
                    country: userData?.country || '',
                    email: userData?.email || '',
                    first_name: userData?.first_name || '',
                    gdpr_opt_in: userData?.gdpr_opt_in || false,
                    last_name: userData?.last_name || '',
                    phone: userData?.phone || '',
                    postal_code: userData?.postal_code || '',
                    state: userData?.state || '',
                    terms: userData?.terms || false,
                },
                gated: false,
                osauid: form?.response?.data?.osauid || '',
            });
        },
    },
};
</script>

<style lang="postcss" scoped>
.gated-form-wrapper {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    width: 100vw;
    height: 100vh;
    padding: 64px 0;
    z-index: 900;
    background: rgba(36, 49, 67, 0.9);
    overflow: auto;

    .gated-form {
        max-width: calc(100% - 32px);
        width: 100%;
        margin: auto;
        @media (--viewport-mobile-wide) {
            max-width: 486px;
        }
        @media (--viewport-tablet) {
            max-width: 566px;
        }
    }
    &:deep(.s-form-container) {
        margin: 0;
        width: 100%;
        .s-form-registration__title {
            font-weight: 600;
        }
        .s-form-registration__error {
            @mixin caption;
            margin: 0;
        }
        .el-input__label {
            @mixin body;
        }
        .el-input__label.is-active {
            font-size: 12px;
            padding-top: 1px;
        }
        .el-input__editor {
            @mixin body;
            color: var(--av-fixed-secondary);
        }
        .el-input__label,
        .el-input__label.is-active,
        .el-input__placeholder {
            color: var(--av-fixed-light);
        }
    }
}
</style>

<style lang="postcss">
.el-select-dropdown {
    &.el-select-vroi-calculator {
        .el-input__editor,
        .el-select-dropdown__item {
            @mixin body;
        }
    }
}
</style>
