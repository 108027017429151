/* eslint-disable max-len */
const USER_AVATAR = '/images/intro/quote_author';
const LOGO = '/images/partner_logos/mono/heg';
const LOGO_2 = '/images/partner_logos/mono/aws';
const LOGO_3 = '/images/partner_logos/mono/cdw';
const LOGO_4 = '/images/partner_logos/mono/comparex';
const REVIEW = {
    title: 'review.title',
    text: 'review.text',
    author: 'review.author',
};
const ROLE = 'review.role';
const CTA = {
    text: 'review.cta.text',
    link: 'https://acronis.com',
};
const COMPANY_NAME = 'review.company.name';

const ARG_DEFAULTS = {
    translations: {
        title: 'Product reviews',
        subheader: 'Acronis solutions are trusted by thousands of service providers worldwide. See what they have to say.',
        'average.rating.text': 'Average rating, out of 5',
        'review.company.name': 'HEG GmbH',
        'review.cta.text': 'Custom CTA text',
        'review.role': 'IT security director',
        'review.title': 'Finally, data security I can trust and afford',
        'review.text': 'Acronis is used primarily as a backup solution. However, from a single console, we are also able to offer disaster recovery and Microsoft 365 backup to new and existing clients. In additional to protecting numerous platforms with a single product and single agent, there were several other key benefits of working with Acronis. First, we were able to recover machines remotely straight from the console. Acronis is used primarily as a backup solution. However, from a single console, we are also able to offer disaster recovery and Microsoft 365 backup to new and existing clients. In additional to protecting numerous platforms with a single product and single agent, there were several other key benefits of working with Acronis. First, we were able to recover machines remotely straight from the console. Acronis is used primarily as a backup solution. However, from a single console, we are also able to offer disaster recovery and Microsoft 365 backup to new and existing clients. In additional to protecting numerous platforms with a single product and single agent, there were several other key benefits of working with Acronis. First, we were able to recover machines remotely straight from the console. Acronis is used primarily as a backup solution. However, from a single console, we are also able to offer disaster recovery and Microsoft 365 backup to new and existing clients. In additional to protecting numerous platforms with a single product and single agent, there were several other key benefits of working with Acronis. First, we were able to recover machines remotely straight from the console.',
        'review.author': 'Andrés F. Ambrosi Chica',
    },
    background: 'white',
    type: 'personal',
    title: 'title',
    subheader: 'subheader',
    infinite: false,
    averageRatingText: 'average.rating.text',
};

export const Figma = 'https://www.figma.com/file/eM4W6JS2MjG1gp5YhKoMp7Dd/Slices?type=design&node-id=27959-11100';

export const PersonalCustomizeCta = {
    name: 'Personal: customize CTA',
    args: {
        ...ARG_DEFAULTS,
        translations: {
            ...ARG_DEFAULTS.translations,
            'review.0.role': 'Head o IT, Scuderia Torro Rosso',
            'review.1.role': 'Head o IT, Scuderia Ferrari',
            'review.2.role': 'Head o IT, Alpine',
            'review.3.role': 'Head o IT, AMG Mercedes Petronas',
            'custom.read.more': 'Custom Read More text',
            'custom.read.less': 'Custom Read Less text',
        },
        type: 'personal',
        showMoreText: 'custom.read.more',
        showLessText: 'custom.read.less',
        reviews: [
            {
                ...REVIEW,
                role: 'review.0.role',
                image: USER_AVATAR,
                rate: 3.5,
                cta: CTA,
            },
            {
                ...REVIEW,
                role: 'review.1.role',
                image: USER_AVATAR,
                rate: 4,
            },
            {
                ...REVIEW,
                role: 'review.2.role',
                image: USER_AVATAR,
                rate: 1,
                cta: CTA,
            },
            {
                ...REVIEW,
                role: 'review.3.role',
                image: USER_AVATAR,
                rate: 2.7,
            },
        ],
    },
};

export const PersonalPhotoLogoRating = {
    name: 'Personal: photo + logo + rating',
    args: {
        ...ARG_DEFAULTS,
        translations: {
            ...ARG_DEFAULTS.translations,
            'allReviews.link.text': 'Read all reviews',
        },
        type: 'personal',
        allReviewsLink: { text: 'allReviews.link.text', to: '#' },
        reviews: [
            {
                ...REVIEW,
                image: USER_AVATAR,
                rate: 3.5,
                logo: LOGO,
                role: ROLE,
                companyName: COMPANY_NAME,
            },
            {
                ...REVIEW,
                image: USER_AVATAR,
                rate: 4.8,
                logo: LOGO_2,
                role: ROLE,
                companyName: COMPANY_NAME,
            },
            {
                ...REVIEW,
                image: USER_AVATAR,
                rate: 1.2,
                logo: LOGO_3,
                role: ROLE,
                companyName: COMPANY_NAME,
            },
            {
                ...REVIEW,
                image: USER_AVATAR,
                rate: 3,
                logo: LOGO_4,
                role: ROLE,
                companyName: COMPANY_NAME,
            },
        ],
    },
};

export const PersonalPhotoLogo = {
    name: 'Personal: photo + logo',
    args: {
        ...ARG_DEFAULTS,
        translations: {
            ...ARG_DEFAULTS.translations,
            'allReviews.link.text': 'Read all reviews',
        },
        allReviewsLink: { text: 'allReviews.link.text', to: '#' },
        type: 'personal',
        reviews: [
            {
                ...REVIEW,
                image: USER_AVATAR,
                logo: LOGO,
                role: ROLE,
            },
            {
                ...REVIEW,
                image: USER_AVATAR,
                logo: LOGO,
                role: ROLE,
            },
            {
                ...REVIEW,
                image: USER_AVATAR,
                logo: LOGO,
                role: ROLE,
            },
            {
                ...REVIEW,
                image: USER_AVATAR,
                logo: LOGO,
                role: ROLE,
            },
        ],
    },
};

export const PersonalPhoto = {
    name: 'Personal: photo',
    args: {
        ...ARG_DEFAULTS,
        type: 'personal',
        reviews: [
            {
                ...REVIEW,
                image: USER_AVATAR,
                role: ROLE,
                companyName: COMPANY_NAME,
            },
            {
                ...REVIEW,
                role: ROLE,
                companyName: COMPANY_NAME,
            },
            {
                ...REVIEW,
                image: USER_AVATAR,
                role: ROLE,
                companyName: COMPANY_NAME,
            },
            {
                ...REVIEW,
                image: USER_AVATAR,
                role: ROLE,
                companyName: COMPANY_NAME,
            },
        ],
    },
};

export const CompanyLogo = {
    name: 'Company: logo',
    args: {
        ...ARG_DEFAULTS,
        translations: {
            ...ARG_DEFAULTS.translations,
            'review.0.role': 'Head o IT, Scuderia Torro Rosso',
            'review.1.role': 'Head o IT, Scuderia Ferrari',
            'review.2.role': 'Head o IT, Alpine',
            'review.3.role': 'Head o IT, AMG Mercedes Petronas',
        },
        type: 'company',
        reviews: [
            {
                ...REVIEW,
                role: 'review.0.role',
                logo: LOGO,
            },
            {
                ...REVIEW,
                role: 'review.1.role',
                logo: LOGO,
            },
            {
                ...REVIEW,
                role: 'review.2.role',
                logo: LOGO,
            },
            {
                ...REVIEW,
                role: 'review.3.role',
                logo: LOGO,
            },
        ],
    },
};

export const CompanyRating = {
    name: 'Company: logo + rating',
    args: {
        ...ARG_DEFAULTS,
        type: 'company',
        translations: {
            ...ARG_DEFAULTS.translations,
            'review.0.role': 'Head o IT, Scuderia Torro Rosso',
            'review.1.role': 'Head o IT, Scuderia Ferrari',
            'review.2.role': 'Head o IT, Alpine',
            'review.3.role': 'Head o IT, AMG Mercedes Petronas',
        },
        reviews: [
            {
                ...REVIEW,
                role: 'review.0.role',
                logo: LOGO,
                rate: 3.5,
            },
            {
                ...REVIEW,
                role: 'review.1.role',
                logo: LOGO,
                rate: 4,
            },
            {
                ...REVIEW,
                role: 'review.2.role',
                logo: LOGO,
                rate: 1,
            },
            {
                ...REVIEW,
                role: 'review.3.role',
                logo: LOGO,
                rate: 2.7,
            },
        ],
    },
};
