<template>
    <section :id="id" class="s-slice s-demo-videos" :class="basicClassName">
        <div class="a-container">
            <a-slice-header :title="title" :lead="lead" :body-text="bodyText" />
            <div v-if="links">
                <ul class="s-demo-videos__list">
                    <li
                        v-for="(item, index) in listLinks"
                        v-show="visibleLinks > index"
                        :key="index"
                        class="s-demo-videos__list-item"
                    >
                        <button v-modal="`video-${index}`" class="s-demo-videos__link" type="button">
                            <span class="s-demo-videos__glyph">
                                <a-glyph :name="item.glyph || 'play'" :fill="item.glyphFill || 'white'" />
                            </span>
                            <span class="s-demo-videos__title">{{ item.title }}</span>
                            <span v-if="item.duration" class="s-demo-videos__duration">{{ item.duration }}</span>
                        </button>
                    </li>
                </ul>
                <div v-for="(item, index) in listLinks" :key="index" class="s-demo-videos__modals">
                    <a-modal
                        :id="`video-${index}`"
                        type="playlist"
                        is-youtube
                        :use-layout="false"
                    >
                        <template #default>
                            <a-youtube
                                :yt-id="item.ytId"
                                :params="item.ytParams || { autoplay: 1 }"
                                :title="`#${index + 1} ${item.title}`"
                            />
                        </template>

                        <template #playlist>
                            <a-modal-playlist
                                :title="`#${index + 1} ${item.title}`"
                                :prev-id="`video-${getPrevId(index)}`"
                                :prev-title="links[getPrevId(index)].title"
                                :next-id="`video-${getNextId(index)}`"
                                :next-title="links[getNextId(index)].title"
                            />
                        </template>
                    </a-modal>
                </div>
            </div>
            <template v-if="settings && step > 0">
                <div v-if="settings.more && isAvailable" class="s-demo-videos__show-more">
                    <a-link
                        :text="settings.more"
                        size="title"
                        glyph="drop-down-arrow"
                        accent
                        @click.stop.prevent="handleClick"
                    />
                </div>
                <div v-if="settings.less && !isAvailable" class="s-demo-videos__show-less">
                    <a-link
                        :text="settings.less"
                        size="title"
                        glyph="drop-down-arrow"
                        accent
                        @click.stop.prevent="handleClick"
                    />
                </div>
            </template>
        </div>
    </section>
</template>

<script>
import AGlyph from '@core/components/glyph/glyph.vue';
import ALink from '@core/components/link/link.vue';
import AModalPlaylist from '@core/components/modal/modal-playlist.vue';
import AModal from '@core/components/modal/modal.vue';
import ASliceHeader from '@core/components/slice-header/slice-header.vue';
import AYoutube from '@core/components/youtube/youtube.vue';
import Modal from '@core/directives/modal.js';
import contentMixin from '@core/mixins/content.js';
import styleMixin from '@core/mixins/style.js';

export default {
    name: 'SDemoVideos',

    directives: {
        Modal,
    },

    components: {
        AYoutube,
        ALink,
        AGlyph,
        AModal,
        AModalPlaylist,
        ASliceHeader,
    },

    mixins: [contentMixin, styleMixin],

    props: {
        /**
         * Links
         */
        links: {
            type: Array,
            required: true,
        },

        /**
         * Settings List
         */
        settings: {
            type: Object,
            required: false,
            default: undefined,
        },
    },

    data() {
        return {
            step: 0,
            visibleLinks: 0,
        };
    },

    computed: {
        listLinks() {
            return this.links.filter((link) => link.ytId);
        },
        isAvailable() {
            return this.visibleLinks < this.links.length;
        },
    },

    mounted() {
        if (this.settings && this.settings.visibleLinks) {
            this.visibleLinks = this.settings.visibleLinks;
            if (this.links.length > this.visibleLinks) {
                this.step = this.visibleLinks;
            }
        }
    },

    methods: {
        handleClick() {
            this.visibleLinks = this.step + (this.isAvailable ? this.visibleLinks : 0);
        },
        getPrevId(index) {
            return index === 0 ? this.links.length - 1 : index - 1;
        },
        getNextId(index) {
            return index === this.links.length - 1 ? '0' : index + 1;
        },
    },
};
</script>

<style lang="postcss">
.s-demo-videos {
    .a-slice-header {
        margin-bottom: 48px;
    }

    &__list-item {
        &:not(:first-child) {
            margin-top: 16px;
        }
    }

    &__link {
        @mixin lead;
        width: 100%;
        display: flex;
        padding: 16px;
        cursor: pointer;
        font-weight: 600;
        border-radius: 4px;
        align-items: center;
        text-decoration: none;
        color: var(--av-brand-secondary);
        background-color: var(--av-solid-brand-accent);
        border: 1px solid var(--av-brand-accent);
        box-shadow: 0 4px 8px var(--av-fixed-lightest);

        &:hover {
            color: var(--av-nav-secondary);
            box-shadow: 0 10px 20px var(--av-fixed-lightest);

            .s-demo-videos {
                &__glyph {
                    background-color: var(--av-nav-secondary);
                }
            }
        }
    }

    &__glyph {
        width: 40px;
        height: 40px;
        display: flex;
        min-width: 40px;
        border-radius: 50%;
        margin-inline-end: 16px;
        align-items: center;
        justify-content: center;
        background: var(--av-brand-secondary);
    }

    &__title {
        margin-inline-end: 16px;
    }

    &__duration {
        @mixin body;

        color: var(--av-fixed-lighter);
        margin-inline-start: auto;
        font-weight: 400;
    }

    &__show-more,
    &__show-less {
        .a-link {
            margin-top: 48px;
        }
    }

    &__show-less {
        .a-glyph {
            transform: rotate(180deg);
        }
    }

    @media (--viewport-tablet) {
        .s-demo-videos {
            &__list {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                margin: -16px -8px 0;
            }

            &__list-item {
                margin: 16px 8px 0;
                width: calc(50% - 8px * 2);
                &:first-child {
                    margin-top: 16px;
                }
            }

            &__link {
                height: 100%;
            }
        }
    }

    @media (--viewport-desktop) {
        .s-demo-videos {
            .a-slice-header {
                margin-bottom: 40px;
            }

            &__list {
                max-width: 70%;
            }

            &__list-item {
                max-width: 309px;
            }
        }
    }

    @media (--viewport-desktop-wide) {
        .s-demo-videos {
            &__list-item {
                max-width: 373px;
            }
        }
    }

    @media (--viewport-desktop-large) {
        .s-demo-videos {
            &__list-item {
                max-width: 426px;
            }
        }
    }
}
.s-side-menu {
    .s-demo-videos {
        padding-bottom: 0;

        &__list,
        &__show-more,
        &__show-less {
            max-width: 778px;
            @media (--viewport-desktop) {
                margin-inline-start: auto;
            }
            @media (--viewport-desktop-large) {
                max-width: 884px;
            }
        }

        &__show-more,
        &__show-less {
            @media (--viewport-desktop) {
                padding-inline-start: 16px;
            }
        }
    }
}
</style>
